// import physicalExamLocationActiveLogo from '../../../../../assets/images/DriverServiceRequest/a-physical-exam-location.svg';
// import physicalExamLocationLogo from '../../../../../assets/images/DriverServiceRequest/n-physical-exam-location.svg';
// import { PrivateRoutes } from '../../../../../config';
// import { customNavTabs } from '../../../../../types';

import physicalExamLocationActiveLogo from '../../../../../../assets/images/DriverServiceRequest/a-physical-exam-location.svg';
import physicalExamLocationLogo from '../../../../../../assets/images/DriverServiceRequest/n-physical-exam-location.svg';
import { PrivateRoutes } from '../../../../../../config';
import { customNavTabs } from '../../../../../../types';

const {
  DRIVERSERVICEREQUEST: { PHYSICALEXAMLOCATION },
} = PrivateRoutes;

export const NavTabsTPTS: customNavTabs[] = [
  {
    activeIcon: physicalExamLocationActiveLogo,
    filter: true,
    icon: physicalExamLocationLogo,
    index: '1',
    isDisabled: false,
    route: PHYSICALEXAMLOCATION,
    shortkey: 'physical-exam-location',
    tab: true,
    title: 'Physical Exam Location',
  },
];
