import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
// import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EyeViewIcon from '../../../assets/images/eye-grey.svg';
import ValueCheck from '../../../assets/images/value-check.svg';
import ValueX from '../../../assets/images/value-x.svg';
import NavRoutes from '../../../config/routes/NavRoutes';
import { AuthStore } from '../../../store/auth';
// import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import useStyles from './fleetStyle';

type ListPropsTypes = {
  fleetLists: any;
  onTerminate: (fleet: any) => void;
  fleetStore: any;
  authStore: AuthStore;
  onClickViewTodos: (unitNumber: string) => unknown;
};

const FleetLists = (props: ListPropsTypes): React.ReactElement => {
  const { authStore, fleetLists, onTerminate, fleetStore, onClickViewTodos } =
    props;
  const classes = useStyles();
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleFleetOption = (event: any, unitId: string) => {
    const obj: any = {};

    if (tooltipIsOpen[unitId]) {
      obj[unitId] = false;
      setAnchorEl(null);
    } else {
      obj[unitId] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const showDataOfContent = useCallback(
    (text: string | null, textColor = '#241A2E', showMissingLabel = false) => {
      return text ? (
        <Typography
          noWrap
          sx={{
            color: textColor,
            fontFamily: 'FiraSans-Regular',
            fontSize: '16px',
            height: '19px',
            letterSpacing: 0,
            lineHeight: '19px',
          }}>
          {text}
        </Typography>
      ) : showMissingLabel ? (
        <Typography
          noWrap
          sx={{
            color: '#A70D0D !important',
            fontFamily: 'FiraSans-Regular',
            fontSize: '16px',
            height: '19px',
            letterSpacing: 0,
            lineHeight: '19px',
          }}>
          MISSING
        </Typography>
      ) : (
        <Typography
          noWrap
          sx={{
            color: '#241A2E',
            fontFamily: 'FiraSans-Regular',
            fontSize: '16px',
            height: '19px',
            letterSpacing: 0,
            lineHeight: '19px',
            textAlign: 'center',
          }}>
          -
        </Typography>
      );
    },
    [],
  );

  return fleetLists.map((fleet: any, index: number) => (
    <TableRow
      key={index}
      className={classes.rowHeight}
      style={{ minHeight: '80px' }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Semibold',
              fontSize: '16px',
              fontWeight: 600,
              height: '23px',
              letterSpacing: 0,
              lineHeight: '23px',
              textTransform: 'capitalize',
            }}>
            {showDataOfContent(fleet.equipmentType)}
          </Typography>

          {showDataOfContent(fleet.unitType)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
              height: '19px',
              letterSpacing: 0,
              lineHeight: '19px',
            }}>
            {showDataOfContent(fleet.unitNumber)}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
              height: '19px',
              letterSpacing: 0,
              lineHeight: '19px',
            }}>
            {showDataOfContent(fleet.simplexId)}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
              height: '19px',
              letterSpacing: 0,
              lineHeight: '19px',
            }}>
            {showDataOfContent(fleet.vinNumber)}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(fleet.ownershipType)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(fleet.primaryDriver)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        {fleet.isPermanent && (
          <Grid item xs={12} md={12} lg={12}>
            -
          </Grid>
        )}
        {!fleet.isPermanent && (
          <Grid item xs={12} md={12} lg={12}>
            {showDataOfContent(
              fleet.registrationExpiryDate
                ? moment(fleet.registrationExpiryDate).format('M/DD/YYYY')
                : null,
              moment(fleet.registrationExpiryDate) < moment()
                ? '#A70D0D'
                : '#241A2E',
              true,
            )}
          </Grid>
        )}
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(
            fleet.annualInspectionExpiryDate
              ? moment(fleet.annualInspectionExpiryDate).format('M/DD/YYYY')
              : null,
            moment(fleet.annualInspectionExpiryDate) < moment()
              ? '#A70D0D'
              : '#241A2E',
            true,
          )}
        </Grid>
      </TableCell>

      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {fleet.vqStatus ? (
            fleet.vqStatus == 'COMPLIANT' ? (
              <img src={ValueCheck} alt="success" />
            ) : (
              // <Typography
              //   noWrap
              //   className={fleet.vqStatus !== '' ? classes.compliant : ''}>
              //   {fleet.vqStatus}
              // </Typography>
              <img src={ValueX} alt="success" />
              // <Typography
              //   noWrap
              //   className={fleet.vqStatus !== '' ? classes.compliantNot : ''}>
              //   {fleet.vqStatus}
              // </Typography>
            )
          ) : (
            <Typography
              noWrap
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                height: '19px',
                letterSpacing: 0,
                lineHeight: '19px',
                textAlign: 'center',
              }}>
              -
            </Typography>
          )}
        </Grid>
      </TableCell>

      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <img
            src={EyeViewIcon}
            alt="view"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}/${fleet.unitId}?name=${fleet.equipmentType}`,
              )
            }
          />
          {/* <Typography
            className={classes.view}
            noWrap
            onClick={() =>
              navigate(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}/${fleet.unitId}?name=${fleet.equipmentType}`,
              )
            }>
            View
          </Typography> */}
        </Grid>
      </TableCell>
      {fleetStore.Status !== 'Terminated' && (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Grid item>
            <IconButton
              onClick={(event) => handleFleetOption(event, fleet.unitId)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              className="popclass"
              open={tooltipIsOpen[fleet.unitId]}
              onClose={(event) => handleFleetOption(event, fleet.unitId)}
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}>
              <Box
                sx={{
                  backgroundColor: '#241A2E',
                  borderRadius: '8px',
                  boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                  minHeight: '70.9px',
                  padding: '0.5rem 0',
                  position: 'relative',
                  width: '207px',
                }}>
                <div className="arrow">.</div>
                <Typography
                  component={'div'}
                  className={'n-pop-btn '.concat(
                    authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                      ? 'disable'
                      : '',
                  )}
                  {...(authStore.getTokenDetail &&
                  authStore.getTokenDetail?.adminId
                    ? {}
                    : {
                        onClick: () =>
                          navigate(
                            // eslint-disable-next-line
                            `/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}/${NavRoutes.PRIVATE.FLEET.UPDATE}/${fleet.unitId}`,
                          ),
                      })}>
                  Edit/View Profile
                </Typography>
                <Typography
                  component={'div'}
                  className={'n-pop-btn '.concat(
                    authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                      ? 'disable'
                      : '',
                  )}
                  {...(authStore.getTokenDetail &&
                  authStore.getTokenDetail?.adminId
                    ? {}
                    : { onClick: () => onTerminate(fleet) })}>
                  Terminate
                </Typography>
                <Typography
                  component={'div'}
                  className={'n-pop-btn '.concat(
                    authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                      ? 'disable'
                      : '',
                  )}
                  {...(authStore.getTokenDetail &&
                  authStore.getTokenDetail?.adminId
                    ? {}
                    : {
                        onClick: () => onClickViewTodos(fleet.unitId),
                      })}>
                  To-Dos
                </Typography>
              </Box>
            </Popover>
          </Grid>
        </TableCell>
      )}
    </TableRow>
  ));
};

export default observer(FleetLists);
