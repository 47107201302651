import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';

import uploadIcon from '../../assets/images/upload.svg';
import { AllowedUploadMimeTypes } from '../../config/Constants';
import useFileStyles from '../../screens/private/ServiceRequest/components/FilesList/FilesListStyles';
import useStyles from '../../screens/private/ServiceRequest/TaxPermits/DotUpdate/DotUpdateStyles';
import { fileSize } from '../../utils/helper';

type Props = {
  fileLabel: string;
  file: any;
  setFile: any;
};
const FileUpload: React.FC<Props> = ({ setFile, file, fileLabel }) => {
  const classes = useStyles();
  const fileClasses = useFileStyles();
  const hiddenFileInput: any = React.useRef(null);

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    setFile(event.currentTarget.files[0]);
  };
  return (
    <>
      <Grid item lg={12} sm={12} xs={12}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Box className={classes.formLabelClass}>{fileLabel}</Box>
          </Grid>
          <Grid item>
            <Button onClick={handleClick}>
              <img src={uploadIcon} alt="icon" />
              <span className="profile-upload link-text">Upload</span>
            </Button>
            <input
              type="file"
              accept={AllowedUploadMimeTypes}
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
            />
          </Grid>
        </Grid>
      </Grid>
      {file ? (
        <React.Fragment>
          <Grid item md={7} sm={5} xs={5} alignSelf={'center'}>
            <div className={fileClasses.fileName}>{file.name}</div>
          </Grid>
          <Grid item md={5} sm={7} xs={7} alignSelf={'center'}>
            <div className={fileClasses.fileInfo}>
              <span
                style={{
                  width: '150px',
                  wordBreak: 'break-all',
                }}>
                {file.type}
              </span>

              <span>{fileSize(String(file.size))}</span>

              <ClearIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setFile(null)}
              />
            </div>
          </Grid>
        </React.Fragment>
      ) : null}
    </>
  );
};

export default FileUpload;
