import moment from 'moment';

type NameArgs =
  | 'annualInspectionExpiryDate'
  | 'collectionDate'
  | 'unitNumber'
  | 'period'
  | 'issueDate'
  | 'expirationDate'
  | 'licenseExpirationDate'
  | 'year'
  | 'quarter'
  | 'yearQuarter'
  | 'make'
  | 'vin'
  | 'type'
  | 'applicationDate'
  | 'signedDate'
  | 'pspAuthSignedDate'
  | 'pspRunDate'
  | 'terminationDate'
  | 'state'
  | 'serialNumber'
  | 'isPermanantRegistration'
  | 'dateRan'
  | 'mvrDateRan'
  | 'clearinghouseQuerydateRan'
  | 'licenseType'
  | 'licenseYear'
  | 'filingPeriod'
  | 'inspectionDate'
  | 'receivedDate'
  | 'registrationType'
  | 'driverType'
  | 'nySeries';

type NamingConvention = Record<
  FileType,
  {
    name: (args: Partial<Record<NameArgs, string>>) => string;
    category: 'account' | 'unit' | 'driver';
  }
>;

export const fieldsForFileType: Record<FileType, NameArgs[]> = {
  'Annual MVR': ['mvrDateRan', 'dateRan'],
  'Annual Query Result': ['clearinghouseQuerydateRan', 'dateRan'],
  'Background Check Authorization Form': ['signedDate'],
  'Background Check Result': ['receivedDate'],
  'CAB Card': [
    'unitNumber',
    'state',
    'expirationDate',
    'isPermanantRegistration',
  ],
  'Certificate Of Insurance': ['expirationDate'],
  'Clearinghouse Query': [
    'clearinghouseQuerydateRan',
    'dateRan',
    'expirationDate',
    'type',
  ],
  'Driver Application': ['applicationDate', 'driverType'],
  "Driver's License": ['licenseType', 'state', 'licenseExpirationDate'],
  'Drivers Application': ['applicationDate'],
  'ELD Reports': ['year', 'quarter'],
  'Equipment Lease': ['unitNumber', 'expirationDate'],
  'Finance Agreement': ['unitNumber', 'expirationDate'],
  'Fuel Card Reports': ['year', 'quarter'],
  'Fuel Receipts': ['yearQuarter', 'unitNumber'],
  'HazMat Permit': ['expirationDate'],
  'IFTA Letter': ['licenseYear'],
  'IFTA Return': ['yearQuarter'],
  'IRP Renewal': ['expirationDate', 'state'],
  'Lease Termination': ['terminationDate'],
  'Local Vehicle Registration': [
    'unitNumber',
    'state',
    'expirationDate',
    'registrationType',
  ],
  MVR: ['mvrDateRan', 'dateRan', 'type'],
  'MVR Authorization Form': ['signedDate'],
  'Medical Card & National Registry': ['issueDate', 'expirationDate'],
  'NM Permit': ['unitNumber', 'year'],
  'NY Permit': ['unitNumber', 'nySeries'],
  'OR Permit-Annual': ['unitNumber', 'annualInspectionExpiryDate'],
  'PSP Authorization Form': ['pspAuthSignedDate'],
  'PSP Result': ['pspRunDate', 'dateRan'],
  'Pre-employment Drug Test Result': ['collectionDate'],
  'Pre-employment MVR': ['mvrDateRan', 'dateRan'],
  'Pre-employment Query Result': ['clearinghouseQuerydateRan', 'dateRan'],
  'SCAC Code': ['expirationDate'],
  'Safety Lease': ['unitNumber', 'expirationDate'],
  'Schedule 1': ['unitNumber', 'filingPeriod'],
  'Trailer Annual Inspection': ['inspectionDate'],
  'Trailer Registration': [
    'licenseType',
    'state',
    'expirationDate',
    'isPermanantRegistration',
    'registrationType',
  ],
  'Trailer Title': ['make', 'year', 'vin'],
  'Trip Sheets': ['year', 'quarter', 'unitNumber'],
  'Vehicle Annual Inspection': ['inspectionDate'],
  'Vehicle Title': ['unitNumber'],
};

export const todoVariantToFileType: Partial<Record<TodoFormVariant, FileType>> =
  {
    'ar-update': 'ELD Reports',
    clearinghouse: 'Annual Query Result',
    'driver-application': 'Driver Application',
    'drivers-license': "Driver's License",
    fuel: 'IFTA Return',
    'hazmat-update': 'HazMat Permit',
    ifta: 'IFTA Letter',
    irp: 'CAB Card',
    'irp-permit': 'IRP Renewal',
    local: 'Local Vehicle Registration',
    local_registration: 'Local Vehicle Registration',
    'medical-card': 'Medical Card & National Registry',
    mvr: 'Annual MVR',
    nm: 'NM Permit',
    ny: 'NY Permit',
    or: 'OR Permit-Annual',
    'or-monthly-update': 'ELD Reports',
    'pre-emp-mvr': 'MVR Authorization Form',
    road: 'Schedule 1',
    'scac-update': 'SCAC Code',
    trailer: 'Trailer Registration',
    'trailer-annual-inspection': 'Trailer Annual Inspection',
    'vehicle-annual-inspection': 'Vehicle Annual Inspection',
  };

const formatDateForFileName = (date = '') => date.replace(/[^0-9]/g, '');

const formatYearQuarterText = (yearQuarter = '') => {
  const yearQuarterArr = yearQuarter.split('-');
  const quarter = yearQuarterArr[1]?.trim()?.toUpperCase() || '';
  const year = yearQuarterArr[0]?.trim() || '';
  return {
    quarter,
    year,
  };
};

export const getStateNameByStateId = (
  statesListOption: { id: string; fullName: string }[],
  stateId: string | undefined,
) => {
  if (!stateId) return '';
  const state = statesListOption.find((state) => state.id === stateId);
  return state?.fullName || '';
};
export const getStateNameByStateCode = (
  statesListOption: { code: string; fullName: string }[],
  stateCode: string | undefined,
) => {
  if (!stateCode) return '';
  const state = statesListOption.find((state) => state.code === stateCode);
  return state?.fullName || '';
};
export const getFileNamingConvention: NamingConvention = {
  'Annual MVR': {
    category: 'driver',
    name: (
      { dateRan = '' }: { dateRan?: string }, // 'Pre-employment' | 'Annual'
    ) => {
      const expirationDate = moment(dateRan)
        .add(1, 'year')
        .format('MM/DD/YYYY');
      return `Annual MVR-${formatDateForFileName(expirationDate)}`;
    },
  },
  'Annual Query Result': {
    category: 'driver',
    name: ({
      clearinghouseQuerydateRan = '',
      dateRan = '',
    }: {
      clearinghouseQuerydateRan?: string;
      dateRan?: string;
    }) => {
      const expirationDate = moment(
        clearinghouseQuerydateRan &&
          clearinghouseQuerydateRan !== 'Invalid Date'
          ? clearinghouseQuerydateRan
          : dateRan,
      )
        .add(12, 'months')
        .format('MM/DD/YYYY');

      return `${formatDateForFileName(
        clearinghouseQuerydateRan &&
          clearinghouseQuerydateRan !== 'Invalid Date'
          ? clearinghouseQuerydateRan
          : dateRan,
      )}-Annual Query-${formatDateForFileName(expirationDate)}`;
    },
  },
  'Background Check Authorization Form': {
    category: 'driver',
    name: ({ signedDate = '' }: { signedDate?: string }) =>
      `${formatDateForFileName(signedDate)}-Background Authorization`,
  },
  'Background Check Result': {
    category: 'driver',
    name: ({ receivedDate = '' }: { receivedDate?: string }) =>
      `${formatDateForFileName(receivedDate)}-Background Check Result`,
  },
  'CAB Card': {
    category: 'unit',
    name: ({
      unitNumber = '',
      state = '',
      expirationDate = '',
      isPermanantRegistration = '',
    }: {
      unitNumber?: string;
      state?: string;
      expirationDate?: string;
      isPermanantRegistration?: string;
    }) =>
      `Cab Card-${state}-${unitNumber}-${
        isPermanantRegistration === 'true'
          ? 'Permanent'
          : formatDateForFileName(expirationDate)
      }`,
  },
  'Certificate Of Insurance': {
    category: 'account',
    name: ({ expirationDate = '' }: { expirationDate?: string }) =>
      `COI-${formatDateForFileName(expirationDate)}`,
  },
  'Clearinghouse Query': {
    category: 'driver',
    name: ({
      clearinghouseQuerydateRan = '',
      dateRan = '',
      expirationDate = '',
      type = '',
    }: {
      clearinghouseQuerydateRan?: string;
      dateRan?: string;
      expirationDate?: string;
      type?: string; // 'Pre-employment' | 'Annual'
    }) =>
      `${formatDateForFileName(
        clearinghouseQuerydateRan &&
          clearinghouseQuerydateRan !== 'Invalid Date'
          ? clearinghouseQuerydateRan
          : dateRan,
      )}-${type} Query-${formatDateForFileName(expirationDate)}`,
  },
  'Driver Application': {
    category: 'driver',
    name: ({
      applicationDate = '',
      driverType = '',
    }: {
      applicationDate?: string;
      driverType?: string;
    }) =>
      `Driver Application-${
        driverType === 'Owner Operator' ? driverType : 'House Driver'
      }-${formatDateForFileName(applicationDate)}`,
  },
  "Driver's License": {
    category: 'driver',
    name: ({
      licenseType = '',
      state = '',
      licenseExpirationDate = '',
    }: {
      licenseType?: string;
      state?: string;
      licenseExpirationDate?: string;
    }) =>
      `${licenseType}-${state}-${formatDateForFileName(licenseExpirationDate)}`,
  },
  'Drivers Application': {
    category: 'driver',
    name: ({ applicationDate = '' }: { applicationDate?: string }) =>
      `${formatDateForFileName(applicationDate)}-Driver Application`,
  },
  'ELD Reports': {
    category: 'account',
    name: ({
      year = '',
      quarter = '',
    }: {
      year?: string;
      quarter?: string;
    }) => {
      return `ELD-${quarter?.toUpperCase()}${year}`;
    },
  },
  'Equipment Lease': {
    category: 'unit',
    name: ({
      expirationDate = '',
      unitNumber = '',
    }: {
      expirationDate?: string;
      unitNumber?: string;
    }) =>
      `Equipment Lease-${unitNumber}-${formatDateForFileName(expirationDate)}`,
  },
  'Finance Agreement': {
    category: 'unit',
    name: ({
      expirationDate = '',
      unitNumber = '',
    }: {
      expirationDate?: string;
      unitNumber?: string;
    }) =>
      `Finance Agreement-${unitNumber}-${formatDateForFileName(
        expirationDate,
      )}`,
  },
  'Fuel Card Reports': {
    category: 'account',
    name: ({ year = '', quarter = '' }: { year?: string; quarter?: string }) =>
      `Fuel Card-${quarter?.toUpperCase()}${year}`,
  },
  'Fuel Receipts': {
    category: 'account',
    name: ({
      yearQuarter = '',
      unitNumber = '',
    }: {
      yearQuarter?: string;
      unitNumber?: string;
    }) => {
      const { year, quarter } = formatYearQuarterText(yearQuarter);
      return `Fuel Receipts-${quarter}${year}-${unitNumber}`;
    },
  },
  'HazMat Permit': {
    category: 'account',
    name: ({ expirationDate = '' }: { expirationDate?: string }) =>
      `HazMat Permit-${formatDateForFileName(expirationDate)}`,
  },
  'IFTA Letter': {
    category: 'account',
    name: ({ licenseYear = '' }: { licenseYear?: string }) =>
      `IFTA Letter-${licenseYear}`,
  },
  'IFTA Return': {
    category: 'account',
    name: ({ yearQuarter = '' }: { yearQuarter?: string }) => {
      const { year, quarter } = formatYearQuarterText(yearQuarter);
      return `IFTA Return-${quarter}-${year}`;
    },
  },
  'IRP Renewal': {
    category: 'account',
    name: ({
      expirationDate = '',
      state = '',
    }: {
      expirationDate?: string;
      state?: string;
    }) =>
      `IRP Renewal-Cab Card Copy-${state}-${formatDateForFileName(
        expirationDate,
      )}`,
  },
  'Lease Termination': {
    category: 'driver',
    name: ({ terminationDate = '' }: { terminationDate?: string }) =>
      `Lease Termination-${formatDateForFileName(terminationDate)}`,
  },
  'Local Vehicle Registration': {
    category: 'unit',
    name: ({
      isPermanantRegistration = '',
      unitNumber = '',
      state = '',
      expirationDate = '',
      registrationType = '',
    }: {
      isPermanantRegistration?: string;
      unitNumber?: string;
      state?: string;
      expirationDate?: string;
      registrationType?: string;
    }) =>
      `${
        registrationType === 'local' ? 'Local Registration' : registrationType
      }-${unitNumber}-${state}-${
        isPermanantRegistration === 'true'
          ? 'Permanent'
          : formatDateForFileName(moment(expirationDate).format('MM/DD/YYYY'))
      }`,
  },
  MVR: {
    category: 'driver',
    name: (
      {
        mvrDateRan = '',
        dateRan = '',
        type = '',
      }: { mvrDateRan?: string; dateRan?: string; type?: string }, // 'Pre-employment' | 'Annual'
    ) => {
      const expirationDate = moment(
        mvrDateRan && mvrDateRan !== 'Invalid Date' ? mvrDateRan : dateRan,
      )
        .add(1, 'year')
        .format('MM/DD/YYYY');
      return `${type} Query-${formatDateForFileName(expirationDate)}`;
    },
  },
  'MVR Authorization Form': {
    category: 'driver',
    name: ({ signedDate = '' }: { signedDate?: string }) => {
      const expirationDate = moment(signedDate)
        .add(1, 'month')
        .format('MM/DD/YYYY');
      return `${formatDateForFileName(
        signedDate,
      )}-Background Authorization-${formatDateForFileName(expirationDate)}`;
    },
  },
  'Medical Card & National Registry': {
    category: 'driver',
    name: ({
      issueDate = '',
      expirationDate = '',
    }: {
      issueDate?: string;
      expirationDate?: string;
    }) =>
      `${formatDateForFileName(
        issueDate,
      )}-Medical Card National Registry-${formatDateForFileName(
        expirationDate,
      )}`,
  },
  'NM Permit': {
    category: 'driver',
    name: ({
      expirationDate = '',
      unitNumber = '',
    }: {
      expirationDate?: string;
      unitNumber?: string;
    }) => {
      const year = moment(expirationDate).year();
      return `NM Permit-${unitNumber}-${year}`;
    },
  },
  'NY Permit': {
    category: 'driver',
    name: ({
      nySeries = '',
      unitNumber = '',
    }: {
      nySeries?: string;
      unitNumber?: string;
    }) => `NY Permit-${unitNumber}-${nySeries}`,
  },
  'OR Permit-Annual': {
    category: 'driver',
    name: ({
      expirationDate = '',
      unitNumber = '',
    }: {
      expirationDate?: string;
      unitNumber?: string;
    }) => {
      const newExpirationDate = moment(expirationDate).add(1, 'year');
      return `OR Permit-${unitNumber}-${formatDateForFileName(
        newExpirationDate.format('MM/DD/YYYY'),
      )}`;
    },
  },
  'PSP Authorization Form': {
    category: 'driver',
    name: ({
      pspAuthSignedDate = '',
      pspRunDate = '',
    }: {
      pspAuthSignedDate?: string;
      pspRunDate?: string;
    }) =>
      `${formatDateForFileName(
        pspAuthSignedDate && pspAuthSignedDate !== 'Invalid Date'
          ? pspAuthSignedDate
          : pspRunDate,
      )}-PSP Authorization`,
  },
  'PSP Result': {
    category: 'driver',
    name: ({
      pspRunDate = '',
      dateRan = '',
    }: {
      pspRunDate?: string;
      dateRan?: string;
    }) =>
      `${formatDateForFileName(
        pspRunDate && pspRunDate !== 'Invalid Date' ? pspRunDate : dateRan,
      )}-PSP Result`,
  },
  'Pre-employment Drug Test Result': {
    category: 'driver',
    name: ({
      collectionDate = '',
      i3CaseNo = '',
    }: {
      collectionDate?: string;
      i3CaseNo?: string;
    }) => `${formatDateForFileName(collectionDate)}-PEDT-${i3CaseNo}`,
  },
  'Pre-employment MVR': {
    category: 'driver',
    name: (
      {
        mvrDateRan = '',
        dateRan = '',
      }: { mvrDateRan?: string; dateRan?: string }, // 'Pre-employment' | 'Annual'
    ) => {
      const expirationDate = moment(
        mvrDateRan && mvrDateRan !== 'Invalid Date' ? mvrDateRan : dateRan,
      )
        .add(1, 'year')
        .format('MM/DD/YYYY');
      return `Pre-employment MVR-${formatDateForFileName(expirationDate)}`;
    },
  },
  'Pre-employment Query Result': {
    category: 'driver',
    name: ({
      clearinghouseQuerydateRan = '',
      dateRan = '',
    }: {
      clearinghouseQuerydateRan?: string;
      dateRan?: string;
    }) => {
      const expirationDate = moment(
        clearinghouseQuerydateRan &&
          clearinghouseQuerydateRan !== 'Invalid Date'
          ? clearinghouseQuerydateRan
          : dateRan,
      )
        .add(12, 'months')
        .format('MM/DD/YYYY');

      return `${formatDateForFileName(
        clearinghouseQuerydateRan &&
          clearinghouseQuerydateRan !== 'Invalid Date'
          ? clearinghouseQuerydateRan
          : dateRan,
      )}-Pre-employment Query-${formatDateForFileName(expirationDate)}`;
    },
  },
  'SCAC Code': {
    category: 'account',
    name: ({ expirationDate = '' }: { expirationDate?: string }) => {
      return `SCAC-${formatDateForFileName(expirationDate)}`;
    },
  },
  'Safety Lease': {
    category: 'unit',
    name: ({
      expirationDate = '',
      unitNumber = '',
    }: {
      expirationDate?: string;
      unitNumber?: string;
    }) => `Safety Lease-${unitNumber}-${formatDateForFileName(expirationDate)}`,
  },
  'Schedule 1': {
    category: 'account',
    name: ({
      unitNumber = '',
      filingPeriod = '',
    }: {
      unitNumber?: string;
      filingPeriod?: string;
    }) =>
      `Schedule 1-${unitNumber}-${filingPeriod}${parseInt(filingPeriod) + 1}`,
  },
  'Trailer Annual Inspection': {
    category: 'unit',
    name: ({ inspectionDate = '' }: { inspectionDate?: string }) => {
      const expirationDate = moment(inspectionDate)
        .add(1, 'year')
        .format('MM/DD/YYYY');
      return `Annual Inspection-${formatDateForFileName(expirationDate)}`;
    },
  },
  'Trailer Registration': {
    category: 'unit',
    name: ({
      isPermanantRegistration = '',
      unitNumber = '',
      state = '',
      expirationDate = '',
      registrationType = '',
    }: {
      isPermanantRegistration?: string;
      unitNumber?: string;
      state?: string;
      expirationDate?: string;
      registrationType?: string;
    }) =>
      `${
        registrationType === 'local' ? 'Local Registration' : registrationType
      }-${unitNumber}-${state}-${
        isPermanantRegistration === 'true'
          ? 'Permanent'
          : formatDateForFileName(expirationDate)
      }`,
  },
  'Trailer Title': {
    category: 'unit',
    name: ({
      make = '',
      year = '',
      vin = '',
    }: {
      make?: string;
      year?: string;
      vin?: string;
    }) => `Trailer Title-${make}-${year}-${vin?.slice(-4)}`,
  },
  'Trip Sheets': {
    category: 'account',
    name: ({
      year = '',
      quarter = '',
      unitNumber = '',
    }: {
      year?: string;
      quarter?: string;
      unitNumber?: string;
    }) => `Trip Sheets-${quarter?.toUpperCase()}${year}-${unitNumber}`,
  },
  'Vehicle Annual Inspection': {
    category: 'unit',
    name: ({ inspectionDate = '' }: { inspectionDate?: string }) => {
      const expirationDate = moment(inspectionDate)
        .add(1, 'year')
        .format('MM/DD/YYYY');
      return `Annual Inspection-${formatDateForFileName(expirationDate)}`;
    },
  },
  'Vehicle Title': {
    category: 'unit',
    name: ({
      unitNumber = '',
    }: {
      make?: string;
      year?: string;
      vin?: string;
      unitNumber?: string;
    }) => `Vehicle Title-${unitNumber}`,
  },
};
