import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    height: '44px',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  cancelLink: {
    color: '#979797',
    cursor: 'pointer',
    fontSize: '1rem',
    letterSpacing: '0',
  },
  center: {
    textAlign: 'center',
  },
  customCheck: {
    '& .MuiSvgIcon-MuiPaper-elevation': {
      marginLeft: '0.5rem !important',
    },
    height: '35px',
    paddingLeft: 'unset !important',
    width: '35px',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      // height: 'auto',
    },
  },
  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    width: '100%',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  hIcon: {
    height: '16px',
    width: '16px',
  },
  headerActionBtn: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  headerContainer: {
    flex: 'auto',
  },
  headerWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 15px !important',
    // width: '95%',
  },
  inputF: {
    '& .MuiFilledInput-root': {
      color: '#000000 !important',
      fontFamily: 'FiraSans-Medium !important',
    },
    '& Mui-error': {
      height: 0,
      paddingLeft: '5px',
    },
    '& input': {
      color: '#000000',
      fontFamily: 'FiraSans-Medium',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      padding: '11px',
    },
    '&:focusVisible': {
      outline: 'unset !important',
    },
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    marginBottom: '6px',
    paddingLeft: '10px !important',
    width: '100%',
  },
  jcenter: {
    justifyContent: 'flex-start !important',
  },
  jxy: {
    padding: '0 1.5rem !important',
  },
  loader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '80px',
    marginTop: '80px',
    width: '100%',
  },
  noRecordsFound: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    paddingTop: 20,
    textAlign: 'center',
    width: '100%',
  },
  optionsClassName: {
    backgroundColor: '#241A2E !important',
    color: '#FFFFFF',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  searchBtn: {
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    height: '44px',
  },
  searchText: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.9375rem',
    fontWeight: 500,
    height: '22px',
    letterSpacing: '1px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  selectBtn: {
    border: '1px solid #CBCBCB',
    borderRadius: '8px',
    boxSizing: 'border-box',
    height: '45px',
    // width: '116px',
  },
  selectClassName: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },
  selectClassNameHeader: {
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    height: '46px !important',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },
  selectErrorClass: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    backgroundColor: '#F7EDED !important',
    border: '1px solid #A70D0D !important',
    borderRadius: '7px',
    boxShadow: '0 0 4px 0 rgb(167 13 13 / 40%) !important',
    boxSizing: 'border-box',
    marginBottom: '6px',
  },
  selectedBtn: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    height: '45px',
    // width: '115px',
  },
});
export default useStyles;
