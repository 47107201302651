import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';

import { AuthStore } from '../../../../store/auth';
import { TrainingStore } from '../../../../store/training';
import { AccessTypes } from '../../../../types';
import exportIcon from './../../../../assets/images/export-green.svg';
import viewIcon from './../../../../assets/images/eye-grey.svg';
import useStyles from './CompletedStyles';

type ListPropsTypes = {
  trainingStore: TrainingStore;
  authStore: AuthStore;
  onDownloadCertificate: (assignment: any) => void;
  onViewCertificate: (assignment: any) => void;
  onReAssignAssignment: (data: any, reAssignType: 'current' | 'other') => void;
  onVideoStream: (assignment: any) => void;
};

const CompletedList = (props: ListPropsTypes): React.ReactElement => {
  const {
    trainingStore,
    authStore,
    onDownloadCertificate,
    onReAssignAssignment,
    onVideoStream,
    onViewCertificate,
  } = props;
  const classes = useStyles();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  enum AssignmentStatus {
    COMPLETED = 'Completed',
    FAILED = 'Failed',
    DISMISSED = 'Dismissed',
    RECOMMENDATION_DISMISSED = 'Recommendation Dismissed',
    NO_ACTION_ON_TODO = 'No Action on To-Do',
    NO_ACTION_ON_RECOMMENDATION = 'No Action on Recommendation',
  }

  const handlePopoverOption = (event: any, id: string) => {
    const obj: any = {};

    if (tooltipIsOpen[id]) {
      obj[id] = false;
      setAnchorEl(null);
    } else {
      obj[id] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  // Video Assigned Column
  const renderVideoAssignedColumn = useCallback((assignment: any) => {
    const {
      training,
      reason,
      inspectionDetails: {
        inspectionId,
        inspectionReportNumber,
        inspectionDate,
        inspectionState,
        violationCode,
        violationDescription,
      },
      accidentDetails: {
        accidentId,
        accidentNumber,
        accidentDate,
        accidentState,
      },
    } = assignment;
    return (
      <div className="video-assigned-cell">
        <Link
          className="video-assigned"
          color="inherit"
          underline="always"
          onClick={() => {
            onVideoStream(assignment);
          }}>
          {training?.title || '-'}
        </Link>
        {reason && (
          <div className="sub-text">
            <span className="sub-header">Reason:&nbsp;</span>
            <span>{reason}</span>
          </div>
        )}
        {inspectionId ? (
          <>
            {inspectionReportNumber && (
              <div className="sub-text">
                <span className="sub-header">Report Number:&nbsp;</span>
                <span>{inspectionReportNumber}</span>
              </div>
            )}
            {inspectionDate && (
              <div className="sub-text">
                <span className="sub-header">Inspection Date:&nbsp;</span>
                <span>{inspectionDate}</span>
              </div>
            )}
            {inspectionState && (
              <div className="sub-text">
                <span className="sub-header">Inspection State:&nbsp;</span>
                <span>{inspectionState}</span>
              </div>
            )}
            {violationCode && (
              <div className="sub-text">
                <span className="sub-header">Violation Code:&nbsp;</span>
                <span>{violationCode}</span>
              </div>
            )}
            {violationDescription && (
              <div className="sub-text">
                <span className="sub-header">Violation Description:&nbsp;</span>
                <span>{violationDescription}</span>
              </div>
            )}
          </>
        ) : (
          ''
        )}
        {accidentId ? (
          <>
            {accidentNumber && (
              <div className="sub-text">
                <span className="sub-header">Accident Number:&nbsp;</span>
                <span>{accidentNumber}</span>
              </div>
            )}
            {accidentDate && (
              <div className="sub-text">
                <span className="sub-header">Accident Date:&nbsp;</span>
                <span>{accidentDate}</span>
              </div>
            )}
            {accidentState && (
              <div className="sub-text">
                <span className="sub-header">Accident State:&nbsp;</span>
                <span>{accidentState}</span>
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCertificate = (assignment: any) => {
    const { isCertificateAwarded } = assignment;
    if (
      isCertificateAwarded &&
      !trainingStore.DownloadTrainingCertificate.loader
    ) {
      onDownloadCertificate(assignment);
    }
  };

  const viewCertificate = (assignment: any) => {
    const { isCertificateAwarded } = assignment;
    if (isCertificateAwarded) onViewCertificate(assignment);
  };

  // Status Column
  const renderStatusColumn = useCallback((assignment: any) => {
    const { status, assignedBy, isCertificateAwarded, id } = assignment;
    if (status === AssignmentStatus.COMPLETED) {
      return (
        <Box
          className="passed"
          style={{
            cursor:
              isCertificateAwarded &&
              !trainingStore.DownloadTrainingCertificate.loader
                ? 'pointer'
                : 'unset',
          }}
          onClick={() => getCertificate(assignment)}>
          <span> Passed </span>
          {isCertificateAwarded &&
          trainingStore.DownloadTrainingCertificate.loader &&
          trainingStore.DownloadTrainingCertificate.id == id ? (
            <CircularProgress
              size={20}
              sx={{ color: '#0DA728', marginLeft: '0.4rem' }}
              title="Downloading..."
            />
          ) : isCertificateAwarded ? (
            <>
              <img
                src={viewIcon}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  viewCertificate(assignment);
                }}
                alt="view certificate"
              />
              <img src={exportIcon} alt="download certificate" />
            </>
          ) : (
            <span />
          )}
        </Box>
      );
    } else if (status === AssignmentStatus.DISMISSED) {
      return (
        <Box className="dismissed">
          <span> Dismissed </span>
          <span className="sub-text"> By: {assignedBy?.name || '-'}</span>
        </Box>
      );
    } else if (status === AssignmentStatus.RECOMMENDATION_DISMISSED) {
      return (
        <Box className="dismissed">
          <span> Recommendation Dismissed </span>
          <span className="sub-text"> By: {assignedBy?.name || '-'}</span>
        </Box>
      );
    } else if (status === AssignmentStatus.FAILED) {
      return (
        <Box className="failed">
          <span> Failed </span>
        </Box>
      );
    } else if (status === AssignmentStatus.NO_ACTION_ON_TODO) {
      return (
        <Box className="no-action">
          <span> No Action on To-Do </span>
        </Box>
      );
    }
    return (
      <Box>
        <span>{status || '-'}</span>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Action Column in Completed tab
  const renderActionColumn = (assignment: any): React.ReactElement => {
    const { isReassigned, id, status } = assignment;
    const statusForReassign = [
      AssignmentStatus.FAILED,
      AssignmentStatus.NO_ACTION_ON_RECOMMENDATION,
      AssignmentStatus.NO_ACTION_ON_TODO,
    ];
    const anchorId = `re-assign-${String(id)}`;
    if (!isReassigned && statusForReassign.includes(status)) {
      return (
        <>
          <IconButton onClick={(event) => handlePopoverOption(event, anchorId)}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            className="popclass"
            open={tooltipIsOpen[anchorId] || false}
            onClose={(event) => handlePopoverOption(event, anchorId)}
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}>
            <Box className={classes.trainingActions}>
              <div className="arrow">.</div>
              {/* Commented below on Simplex request */}
              {/* {isSystemReason ? (
                <>
                  <Typography
                    component={'div'}
                    className={'custom-pop-btn'}
                    onClick={() => onReAssignAssignment(assignment, 'current')}>
                    Re-Assign-Current Driver
                  </Typography>
                  <Typography
                    component={'div'}
                    className={'custom-pop-btn'}
                    onClick={() => onReAssignAssignment(assignment, 'other')}>
                    Re-Assign-Other Driver
                  </Typography>
                </>
              ) : (
                <Typography
                  component={'div'}
                  className={'custom-pop-btn'}
                  onClick={() => onReAssignAssignment(assignment, 'current')}>
                  Re-Assign
                </Typography>
              )} */}
              <Typography
                component={'div'}
                className={'custom-pop-btn'}
                onClick={() => onReAssignAssignment(assignment, 'current')}>
                Re-Assign
              </Typography>
            </Box>
          </Popover>
        </>
      );
    } else if (isReassigned) {
      return (
        <Box className="re-assigned">
          <span> Re-Assigned </span>
        </Box>
      );
    }
    return <span />;
  };

  // Driver name column styles based on status
  const getDriverNameCellStyle = (training: any): any => {
    const { status } = training;
    const style = {
      borderLeft: 'unset',
    };
    if (status === AssignmentStatus.COMPLETED) {
      style.borderLeft = '6px #0DA728 solid';
    } else if (
      [
        AssignmentStatus.FAILED,
        AssignmentStatus.NO_ACTION_ON_TODO,
        AssignmentStatus.NO_ACTION_ON_RECOMMENDATION,
      ].includes(status)
    ) {
      style.borderLeft = '6px #A70D0D solid';
    } else if (
      [
        AssignmentStatus.DISMISSED,
        AssignmentStatus.RECOMMENDATION_DISMISSED,
      ].includes(status)
    ) {
      style.borderLeft = '6px #C9C9C9 solid';
    }
    return style;
  };

  // Row styles based on status
  const getRowStyle = (training: any): any => {
    const { status } = training;
    const style = {
      backgroundColor: '#FFFFFF',
      height: '80px',
      minHeight: '80px',
      paddingBottom: '20px',
    };
    if (
      [
        AssignmentStatus.DISMISSED,
        AssignmentStatus.RECOMMENDATION_DISMISSED,
      ].includes(status)
    ) {
      style.backgroundColor = '#f2efef';
    } else if (
      [
        AssignmentStatus.FAILED,
        AssignmentStatus.NO_ACTION_ON_TODO,
        AssignmentStatus.NO_ACTION_ON_RECOMMENDATION,
      ].includes(status)
    ) {
      style.backgroundColor = '#f2efef';
    }
    return style;
  };

  return (
    <>
      {trainingStore.TrainingCompletedData.map((c: any, index: number) => (
        <TableRow key={index} sx={getRowStyle(c)}>
          {/* Driver Name */}
          {authStore.UserAccessType === AccessTypes.CompanyAdmin && (
            <TableCell
              className={classes.tableCell}
              style={getDriverNameCellStyle(c)}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  noWrap
                  style={{
                    fontFamily: 'FiraSans-Bold',
                    fontSize: '0.8rem',
                  }}>
                  {c?.assignedContact?.name || '-'}
                </Typography>
              </Grid>
            </TableCell>
          )}
          {/* Video Assigned and Reason */}
          <TableCell
            className={classes.tableCell}
            style={{
              ...(authStore.UserAccessType !== AccessTypes.CompanyAdmin
                ? getDriverNameCellStyle(c)
                : {}),
            }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className={classes.videoAssignedCell}>
              {renderVideoAssignedColumn(c)}
            </Grid>
          </TableCell>
          {/* Score */}
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {c?.hasQuiz ? c?.score || '0%' : '-'}
            </Grid>
          </TableCell>
          {/* Status */}
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12} className={classes.statusCell}>
              {renderStatusColumn(c)}
            </Grid>
          </TableCell>
          {/* Assigned By */}
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {c?.assignedBy?.name || '-'}
            </Grid>
          </TableCell>
          {/* Start Date & End Date */}
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {c?.startDate || '-'}
            </Grid>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {c?.endDate || '-'}
            </Grid>
          </TableCell>
          {/* Action */}
          {authStore.UserAccessType !== AccessTypes.Driver && (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Grid item className={classes.gridActions}>
                {renderActionColumn(c)}
              </Grid>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default observer(CompletedList);
