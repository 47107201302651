import { CircularProgress, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import newToDoLogo from '../../../../../assets/images/notification/new-todo.svg';
import simplexLogo from '../../../../../assets/images/notification/simplex.svg';
import trainingLogo from '../../../../../assets/images/notification/training.svg';
import weeklyLogo from '../../../../../assets/images/notification/weekly.svg';
import { useCustomStore } from '../../../../../hooks';
import { userNotificationDataAccess } from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import useStyles from './AdminNotificationsStyle';

const AdminNotifications: React.FC = () => {
  const classes = useStyles();
  const { notificationStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = React.useState(false);
  // const [mainLoader, setMainLoader] = React.useState(false);
  // const [notifications, setNotifications] = React.useState<any>([]);
  const [msgLastSeenLog, setMsgLastSeenLog] = React.useState<any>({
    date: '',
    entry: true,
  });
  // const [totalCount, setTotalCount] = React.useState<number>(0);

  React.useEffect(() => {
    notificationStore.resetParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    GetNotificationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   const scrollFns: any = () => {
  //     const scrollable: number = document.documentElement.scrollHeight;
  //     const InnerHeight: number = window.innerHeight;
  //     const ScrollY: number = window.scrollY;

  //     if (scrollable - InnerHeight === ScrollY) {
  //       notificationStore.setNotificationCountIncrement();
  //     }
  //   };
  //   window.addEventListener('scroll', scrollFns);

  //   return () => {
  //     window.removeEventListener('scroll', scrollFns);
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getMoreNotifications = React.useCallback(async () => {
    setLoader(true);
    const initialNotifications = await notificationStore.getInitialData();
    setLoader(false);

    if (initialNotifications.isOk()) {
      let lastMsgReadlog: any = null;

      if (msgLastSeenLog.entry) {
        lastMsgReadlog = userNotificationDataAccess(
          'lastMsgReadlog',
          authStore.getTokenDetail?.id,
        );
        const datevalueStatus: any = moment(lastMsgReadlog).isValid();
        setMsgLastSeenLog((p: any) => {
          if (p.entry) {
            return {
              date: datevalueStatus ? lastMsgReadlog : '',
              entry: false,
            };
          } else {
            return {
              ...p,
            };
          }
        });
      } else {
        lastMsgReadlog = msgLastSeenLog.date;
      }

      if (lastMsgReadlog) {
        lastMsgReadlog = moment(new Date(lastMsgReadlog));
      }

      const oldDatas: any = [...notificationStore.notifications];
      let existedFindedElem: any = [];
      const respresponse = initialNotifications.value.response
        .map((responseData: any) => {
          const findedElem: any = oldDatas.find(
            (e: any) => e.templateId === responseData.templateId,
          );
          if (!findedElem) {
            return responseData;
          } else {
            existedFindedElem.push(findedElem);
          }
          return null;
        })
        .filter((e: any) => e);

      const companyDetails = [...respresponse].map((company: any) => {
        const returnData: any = {
          ...company,
          date: company.startTime
            ? moment(company.startTime).format('MM/DD/YYYY')
            : '',
          readStatus: true,
        };

        if (lastMsgReadlog) {
          const status: any = lastMsgReadlog.isBefore(
            moment(company.startTime),
          );
          returnData.readStatus = status;
        }

        if (returnData.description) {
          const replacevalue: string = (returnData.description || '').replace(
            /\n/gim,
            '<br>',
          );
          returnData.description = replacevalue;
        }

        return returnData;
      });

      existedFindedElem = [...existedFindedElem, ...companyDetails];
      const newArray: any = [...oldDatas, ...companyDetails]
        .map((existed: any) => {
          if (!existedFindedElem.length) {
            return existed;
          }
          const findStatusValue: any = existedFindedElem.find(
            (e: any) => e.templateId === existed.templateId,
          );
          if (findStatusValue) {
            return findStatusValue;
          }
          return null;
        })
        .filter((e: any) => e);

      // setNotifications((p: any) => [...p, ...companyDetails]);
      notificationStore.setNotifications([
        ...notificationStore.notifications,
        ...newArray,
      ]);
    }
    if (initialNotifications.isErr()) {
      const {
        error: { message = null },
      } = initialNotifications;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
  }, [
    authStore.getTokenDetail?.id,
    enqueueSnackbar,
    msgLastSeenLog.date,
    msgLastSeenLog.entry,
    notificationStore,
  ]);
  const GetNotificationDetails = React.useCallback(async () => {
    notificationStore.setNotifications([]);
    notificationStore.setMainLoader(true);
    const initialNotifications: any = await notificationStore.getInitialData();
    notificationStore.setMainLoader(false);

    if (initialNotifications.isOk()) {
      const {
        value: { response },
      }: any = initialNotifications;

      // setTotalCount(count || 0);

      if (!response.length) {
        notificationStore.setNotifications([]);
        return;
      }

      let lastMsgReadlog: any = null;

      if (msgLastSeenLog.entry) {
        lastMsgReadlog = userNotificationDataAccess(
          'lastMsgReadlog',
          authStore.getTokenDetail?.id,
          response[0].startTime,
        );
        const datevalueStatus: any = moment(lastMsgReadlog).isValid();
        setMsgLastSeenLog((p: any) => {
          if (p.entry) {
            return {
              date: datevalueStatus ? lastMsgReadlog : '',
              entry: false,
            };
          } else {
            return {
              ...p,
            };
          }
        });
      } else {
        lastMsgReadlog = msgLastSeenLog.date;
      }

      if (lastMsgReadlog) {
        // lastMsgReadlog = moment(new Date(lastMsgReadlog));
      }
      const oldDatas: any = [...notificationStore.notifications];
      let existedFindedElem: any = [];
      const respresponse = response
        .map((responseData: any) => {
          const findedElem: any = oldDatas.find(
            (e: any) => e.templateId === responseData.templateId,
          );
          if (!findedElem) {
            return responseData;
          } else {
            existedFindedElem.push(findedElem);
          }
          return null;
        })
        .filter((e: any) => e);

      const companyDetails = [...respresponse].map((company: any) => {
        const returnData: any = {
          ...company,
          date: company.startTime
            ? moment(company.startTime).format('MM/DD/YYYY')
            : '',
          readStatus: true,
        };

        if (lastMsgReadlog) {
          const status: any = moment(new Date(lastMsgReadlog)).isBefore(
            moment(company.startTime),
          );
          returnData.readStatus = status;
        }

        if (returnData.description) {
          const replacevalue: string = (returnData.description || '').replace(
            /\n/gim,
            '<br>',
          );
          returnData.description = replacevalue;
        }

        return returnData;
      });

      existedFindedElem = [...existedFindedElem, ...companyDetails];
      const newArray: any = [...oldDatas, ...companyDetails]
        .map((existed: any) => {
          if (!existedFindedElem.length) {
            return existed;
          }
          const findStatusValue: any = existedFindedElem.find(
            (e: any) => e.templateId === existed.templateId,
          );
          if (findStatusValue) {
            return findStatusValue;
          }
          return null;
        })
        .filter((e: any) => e);

      // setNotifications((p: any) => [...p, ...companyDetails]);
      notificationStore.setNotifications([...newArray]);
    }

    if (initialNotifications.isErr()) {
      notificationStore.setNotifications([]);
      const {
        error: { message = null },
      } = initialNotifications;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    notificationStore.getNotificationHeaderParams,
    // notificationStore.getNotificationTriggerStatus,
  ]);

  const NavigateHyperLink = (notification: any) => {
    if (notification.hyperLink) {
      window.open(notification.hyperLink, '_blank');
    }
  };

  const HasMoreResults = () => {
    getMoreNotifications();
  };

  React.useEffect(() => {
    if (
      // notificationStore.getNotificationHeaderParams.description ||
      // notificationStore.getNotificationHeaderParams.title
      msgLastSeenLog.date
    ) {
      notificationStore.setNextLink('');
      GetNotificationDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationStore.getNotificationHeaderParams]);

  if (notificationStore.MainLoader) {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress size={80} sx={{ color: '#DEC330', marginTop: 20 }} />
      </div>
    );
  }

  const getIcon = (type: string) => {
    let icon = '';
    switch (type) {
      case 'ad-hoc':
        icon = simplexLogo;
        break;
      case 'new':
        icon = newToDoLogo;
        break;
      case 'weekly':
        icon = weeklyLogo;
        break;
      case 'Training':
        icon = trainingLogo;
        break;
    }
    return icon;
  };

  const getTitlePrefix = (type: string) => {
    let prefix = '';
    switch (type) {
      case 'ad-hoc':
        prefix = 'Simplex Notice -';
        break;
      case 'new':
        prefix = 'New To-Do - ';
        break;
      case 'weekly':
        prefix = 'Weekly Summary - ';
        break;
    }
    return prefix;
  };

  return (
    <InfiniteScroll
      dataLength={notificationStore.notifications.length}
      // height={windowHeight}
      style={{ overflow: 'unset' }}
      next={HasMoreResults}
      hasMore={notificationStore.HasMoreResults}
      // scrollableTarget={'scroll'}
      loader={
        loader && (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <CircularProgress
              size={30}
              sx={{ color: '#DEC330', marginTop: 20 }}
            />
          </div>
        )
      }
      endMessage>
      <Grid container style={{ padding: '2rem 1.3rem' }}>
        <Grid item md={12} lg={12} sm={12}>
          <Typography
            component={'div'}
            style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography component={'div'} style={{ width: '630px' }} id="self">
              {notificationStore.notifications.length ? (
                notificationStore.notifications.map(
                  (ele: any, index: number) => {
                    let classVar: any = classes.NotBox;
                    if (!ele.readStatus) {
                      classVar = clsx(classes.NotBox, classes.nonActive);
                    }
                    return (
                      <Typography
                        component={'div'}
                        className={classVar}
                        key={index}>
                        <Grid container>
                          <Grid item md={10}>
                            <Typography
                              component={'div'}
                              className={classes.flexA}>
                              <Typography
                                component={'img'}
                                src={getIcon(ele.type)}
                                alt={'img'}
                                sx={{
                                  marginRight: '3px',
                                  width: '30px',
                                }}
                              />
                              <Typography
                                component={'div'}
                                className={classes.notTitle}>
                                {getTitlePrefix(ele.type)}
                                {ele.title}
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            className={classes.flexA}
                            style={{ justifyContent: 'flex-end' }}>
                            <Typography
                              component={'div'}
                              className={classes.notDate}>
                              {ele.date}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography
                          component={'div'}
                          className={classes.notDis}
                          dangerouslySetInnerHTML={{
                            __html: ele.description,
                          }}
                        />
                        {ele.hyperLink && ele.hyperLinkText && (
                          <Typography
                            component={'a'}
                            className={classes.Link}
                            onClick={() => NavigateHyperLink(ele)}>
                            {ele.hyperLinkText}
                          </Typography>
                        )}
                      </Typography>
                    );
                  },
                )
              ) : (
                <div style={{ padding: '2rem 0', textAlign: 'center' }}>
                  No Records Found
                </div>
              )}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </InfiniteScroll>
  );
};

export default observer(AdminNotifications);
