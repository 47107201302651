import {
  Checkbox,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';

import SortingIcon from '../SortingIcon/SortingIcon';

type GridCell = {
  checkbox?: boolean;
  highlight?: boolean;
  label?: string;
  name?: string;
  noSorting?: boolean;
  spacing?: number;
  noHeader?: boolean;
};

type BodyProps = {
  id: string;
  data: any[];
  cells: GridCell[];
  children: (info: any, index: number) => any;
};

const Body: React.FC<BodyProps> = ({
  id = '',
  data = [],
  cells = [],
  children,
}) => {
  return (
    <>
      {data.map((el: any, index: number) => (
        <TableRow key={`grid-body-${id}_${index}`}>
          {children(el, index)}
        </TableRow>
      ))}
    </>
  );
};

type HeaderProps = {
  height?: number;
  top?: number;
  id: string;
  cells: GridCell[];
  sortConfig?: {
    store: any;
    handleSort: any;
  };
  checkBoxConfig?: {
    handleCheck: any;
    tooltip?: string;
  };
  showRowCheck?: boolean;
  checkboxPaddingBottom?: string;
  gridHeaderStackPaddingTop?: string;
  gridHeaderStackPaddingBottom?: string;
  isRowChecked?: boolean;
  tableCellSx?: any;
};
const Header: React.FC<HeaderProps> = ({
  cells = [],
  height = 100,
  id = '',
  sortConfig,
  checkBoxConfig,
  showRowCheck = false,
  isRowChecked = false,
  top = 0,
  checkboxPaddingBottom = '0px',
  gridHeaderStackPaddingTop = '0px',
  gridHeaderStackPaddingBottom = '0px',
  tableCellSx = {},
}) => (
  <TableRow
    // top={top}
    // height={height}
    id={`grid-header-${id}`}>
    {showRowCheck && (
      <TableCell
        key={`${id}_checkbox_header`}
        sx={
          tableCellSx &&
          typeof tableCellSx === 'object' &&
          Object.keys(tableCellSx).length > 0
            ? { ...tableCellSx, minWidth: '80px', width: '80px' }
            : {
                backgroundColor: '#f5f5f5',
                minWidth: '80px',
                width: '80px',
              }
        }
        width={80}>
        <Tooltip
          title={
            <Typography
              sx={{
                color: '#fff',
                fontFamily: 'FiraSans-Regular',
                fontSize: '12px',
                fontStyle: 'italic',
                lineHeight: '32px',
              }}>
              {checkBoxConfig?.tooltip ? checkBoxConfig.tooltip : 'Select All'}
            </Typography>
          }
          arrow
          placement="top">
          <Checkbox
            style={{
              backgroundColor: 'transparent',
              border: '1px solid transparent',
              borderRadius: '4px',
              paddingBottom: checkboxPaddingBottom,
            }}
            onChange={(event: any) => {
              showRowCheck && checkBoxConfig?.handleCheck(event);
            }}
            checked={isRowChecked}
            color="success"
            tabIndex={0}
          />
        </Tooltip>
      </TableCell>
    )}
    {cells.map(
      (
        {
          label = '',
          name: sortByName = '',
          noSorting = false,
          spacing = 0,
          checkbox = false,
          // noHeader = false,
        },
        index: number,
      ) => (
        <TableCell
          key={index}
          sx={
            tableCellSx &&
            typeof tableCellSx === 'object' &&
            Object.keys(tableCellSx).length > 0
              ? {
                  ...tableCellSx,
                  minWidth: `${spacing}px`,
                  width: `${spacing}px`,
                }
              : {
                  backgroundColor: '#f5f5f5',
                  minWidth: `${spacing}px`,
                  width: `${spacing}px`,
                }
          }
          width={spacing}>
          <Grid item xs={12}>
            <Typography
              component={'div'}
              noWrap
              style={{
                alignItems: 'center',
                display: 'flex',
                whiteSpace: 'unset',
              }}>
              <Typography
                component={'div'}
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                }}>
                {label}
              </Typography>

              {sortConfig && !noSorting && (
                <SortingIcon
                  {...{
                    sortBY: sortConfig.handleSort,
                    sortByName,
                    store: sortConfig.store,
                  }}
                />
              )}
            </Typography>
          </Grid>
        </TableCell>
      ),
    )}
  </TableRow>
);

const Wrap: React.FC<{ children: any }> = ({ children }) => children;

export default {
  Body,
  Header,
  Wrap,
};
