import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import {
  CreateKentuckyPermitsInput,
  CreateNYAndNMStatePermitsInput,
  CreateOregonPermitsInput,
  RequestType,
  StatesPermits,
} from '../../types';
import { RootStore } from '..';

export class StatePermitsApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getStatePermits = (state: StatesPermits) => {
    const {
      GetStates: { Endpoint, Method },
    } = ApiRoutes.StatePermits;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: `${Endpoint}/${state}`,
    });
  };
  createNYAndNMStatePermits = (data: CreateNYAndNMStatePermitsInput) => {
    const {
      CreatePermit: { Endpoint, Method },
    } = ApiRoutes.StatePermits;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: `${Endpoint}/${data.state}`,
    });
  };

  createKentuckyStatePermits = (data: CreateKentuckyPermitsInput) => {
    const {
      CreatePermit: { Endpoint, Method },
    } = ApiRoutes.StatePermits;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: `${Endpoint}/${data.state}`,
    });
  };

  createOregonStatePermits = (
    data:
      | CreateOregonPermitsInput
      | { requestType: RequestType; state: StatesPermits },
  ) => {
    const {
      CreatePermit: { Endpoint, Method },
    } = ApiRoutes.StatePermits;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: `${Endpoint}/${data.state}`,
    });
  };
}
