import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import { PrivateRoutes } from '../../../../config';
import useStyles from '../settingsStyle';

type SettingsHeaderProps = {
  heading?: string;
  subHeading?: string;
};
const SettingsHeader: React.FC<SettingsHeaderProps> = (
  props: SettingsHeaderProps,
) => {
  const { heading, subHeading } = props;

  const classes = useStyles();
  return (
    <>
      <Typography
        component={Link}
        className={classes.backBtnContainer}
        to={PrivateRoutes.SETTINGSROUTE.SETTINGS}>
        <div className={classes.backBtn}>Back to settings</div>
      </Typography>
      {heading ? (
        <Typography component={'div'} className={classes.headerHeading}>
          {heading}
        </Typography>
      ) : (
        ''
      )}
      {subHeading ? (
        <Typography component={'div'} className={classes.headerSubHeading}>
          {subHeading}
        </Typography>
      ) : (
        ''
      )}
    </>
  );
};
export default SettingsHeader;
