import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  '& .MuiGrid-root': {
    padding: '0 16px !important',
  },
  '& .MuiPaper-root': {
    '.MuiGrid-container': {
      padding: '0px 16px !important',
    },
    padding: '16px 0px !important',
  },
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },
  grid: {
    padding: '0 2rem',
    paddingTop: '2rem',
  },
  gridActions: {
    '& .complete-button': {
      border: '1px solid #CBCBCB',
      borderRadius: '0.5rem',
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
      marginRight: '0.8rem',
      padding: '0.8rem 2.5rem',
    },
    '& .re-assigned': {
      color: '#A70D0D',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
      fontStyle: 'italic',
    },
    float: 'right',
  },
  rectangleTooltip: {
    backgroundColor: '#37414F',
    height: '32px',
    width: '207px',
  },
  rowHeight: {
    height: '80px',
  },
  scroll: {
    overflow: 'hidden',
    padding: '2rem 2rem 0 3rem',
    position: 'relative',
  },
  statusCell: {
    '& .dismissed': {
      '& .sub-text': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Light',
        fontSize: '0.6rem',
        lineHeight: '1.6rem',
      },
      color: '#241A2E',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .failed': {
      color: '#A70D0D',
    },
    '& .no-action': {
      color: '#A70D0D',
    },
    '& .passed': {
      '& img': {
        paddingLeft: '0.3rem',
        width: '1.3rem',
      },
      alignItems: 'center',
      color: '#0DA728',
      display: 'flex',
    },
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.8rem',
    opacity: '0.9',
  },
  table: {
    borderSpacing: '0px 5px',
    minWidth: 700,
  },
  tableCell: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Light',
    fontSize: '0.8rem !important',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  tableHeaderBg: {
    backgroundColor: '#f5f5f5 !important',
  },
  trainingActions: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.22)',
    minHeight: '50px',
    padding: '0.5rem 0',
    position: 'relative',
    width: '207px',
  },
  videoAssignedCell: {
    '& .sub-text': {
      '& .sub-header': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Regular',
      },
      display: 'flex',
      fontFamily: 'FiraSans-Light',
      fontSize: '0.75rem',
      lineHeight: '1.2rem',
      opacity: 0.7,
    },
    '& .video-assigned': {
      color: '#0061FF',
      cursor: 'pointer',
      fontFamily: 'FiraSans-Bold',
      fontSize: '0.8rem',
    },
    '& .video-assigned-cell': {
      padding: '0.6rem 0',
    },
  },
}));

export default useStyles;
