import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import NavRoutes from '../../../../config/routes/NavRoutes';
import Accidents from './Accidents/Accidents';
import Inspections from './Inspections/Inspections';

const RecommendedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <Navigate to={NavRoutes.PRIVATE.TRAINING.RECOMMENDED.INSPECTIONS} />
        }
      />
      <Route
        path={NavRoutes.PRIVATE.TRAINING.RECOMMENDED.ACCIDENTS}
        element={<Accidents />}
      />
      <Route
        path={NavRoutes.PRIVATE.TRAINING.RECOMMENDED.INSPECTIONS}
        element={<Inspections />}
      />
    </Routes>
  );
};

export default observer(RecommendedRoutes);
