import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useCustomStore } from '../../../hooks';
import AlternateDashboard from './AlternateDashboard';
import Highlights from './Highlights';
import Trends from './Trends';

const Dashboard: React.FC = () => {
  const {
    userStore: { UserData },
    authStore: { NavbarAccess: access },
  } = useCustomStore();

  const { accessType } = UserData;
  if (!accessType) return <div> &nbsp; </div>;
  if (accessType === 'companyAdmin') {
    return (
      <Routes>
        <Route path="" element={<Navigate to="highlights" />} />
        <Route path="highlights" element={<Highlights />} />
        {access?.trends && <Route path="trends" element={<Trends />} />}
      </Routes>
    );
  }
  if (accessType === 'driver') {
    return <AlternateDashboard role="driver" />;
  }
  if (accessType === 'ownerOperator') {
    return <AlternateDashboard role="ownerOperator" />;
  }
  return <h3>Not a valid user(accessType) type</h3>;
};

export default observer(Dashboard);
