import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { TrainingStore } from '../../../../../store/training';
import useStyles from './InspectionsStyles';

type InspectionsListProps = {
  onAssignInspection: (inspection: any) => void;
  trainingStore: TrainingStore;
};
const InspectionsList: React.FC<InspectionsListProps> = ({
  onAssignInspection,
  trainingStore,
}) => {
  const classes = useStyles();

  // Action Column
  const renderActionColumn = (inspection: any): React.ReactElement => {
    return (
      <Button
        variant="outlined"
        size="large"
        onClick={() => onAssignInspection(inspection)}>
        Assign
      </Button>
    );
  };

  return (
    <>
      {trainingStore.TrainingInspectionsData.map(
        (inspection: any, index: number) => (
          <TableRow
            key={index}
            sx={{
              backgroundColor: '#FFFFFF',
              height: '80px',
              minHeight: '80px',
              paddingBottom: '20px',
            }}>
            {/* Driver Name */}
            <TableCell
              style={{
                borderLeft: '8px #D8BF57 solid',
                paddingBottom: 0,
                paddingTop: 0,
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  noWrap
                  style={{
                    fontFamily: 'FiraSans-Bold',
                    fontSize: '0.8rem',
                  }}>
                  {inspection?.driver?.name || '-'}
                </Typography>
              </Grid>
            </TableCell>
            {/* Driver Simplex ID */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {inspection?.driver?.simplexId || '-'}
              </Grid>
            </TableCell>
            {/* Inspection Number */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {inspection?.reportNumber || '-'}
              </Grid>
            </TableCell>
            {/* Inspection Date */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {inspection?.inspectionDate || '-'}
              </Grid>
            </TableCell>
            {/* Inspection State */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {inspection?.inspectionState || '-'}
              </Grid>
            </TableCell>
            {/* # of Violations */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {inspection?.noOfViolations || '-'}
              </Grid>
            </TableCell>
            {/* Recommendation Created On */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {inspection?.recommendationCreatedOn || ''}
              </Grid>
            </TableCell>
            {/* Recommendation Expiration Date */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {inspection?.dueDate || '-'}
              </Grid>
            </TableCell>
            {/* Action */}
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Grid item className={classes.gridActions}>
                {renderActionColumn(inspection)}
              </Grid>
            </TableCell>
          </TableRow>
        ),
      )}
    </>
  );
};

export default observer(InspectionsList);
