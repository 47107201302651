import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Collapse,
  Divider,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import Exclamation from '../../../assets/images/Exclamation.svg';
import GreenCheck from '../../../assets/images/GreenCheck.svg';
import X from '../../../assets/images/X.svg';
import useStyles from './DataQChallengeStyles';

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&:nth-of-type(odd)': {
    backgroundColor: '#E8E8E8',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#979598',
    fontFamily: 'FiraSans-Regular',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Medium',
  },
  '&.title': {
    fontFamily: 'FiraSans-Regular',
  },
  fontSize: '0.8rem',
  padding: '0.3rem !important',
}));

type DataQChallengeDetailsPropsType = {
  data: any;
  basicInfo: any;
  from: string;
};

const DataQChallengeDetails = (
  props: DataQChallengeDetailsPropsType,
): React.ReactElement => {
  const classes = useStyles();

  const { basicInfo, from, data } = props;

  const fromInspections = from === 'inspections';
  const [showBasicInfo, setShowBasicInfo] = useState<boolean>(true);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

  const getIconByStatus = (status: string) => {
    switch (status) {
      case 'No match':
        return <img src={X} style={{ paddingRight: '4px' }} />;
      case 'Auto-match':
        return <img src={Exclamation} style={{ paddingRight: '4px' }} />;
      case 'Confirmed':
        return <img src={GreenCheck} style={{ paddingRight: '4px' }} />;
      default:
        return;
    }
  };

  return (
    <div className={classes.dataQChallengeDetails}>
      {/* Inspection or Accident Information Header */}
      <Grid container mb={1} className={classes.infoHeader}>
        <Grid item xs={8} className="title">
          {from === 'inspections' ? 'INSPECTION' : 'ACCIDENT'} INFORMATION
        </Grid>
        <Grid item xs={4}>
          <div
            className={classes.infoShowHide}
            onClick={() => setShowBasicInfo(!showBasicInfo)}>
            <span className="title">
              {showBasicInfo ? 'Show Less' : 'Show More'}
            </span>
            {showBasicInfo ? (
              <KeyboardArrowDownIcon className="icon" />
            ) : (
              <KeyboardArrowUpIcon className="icon" />
            )}
          </div>
        </Grid>
      </Grid>
      {/* Inspection or Accident Information Details */}
      <Collapse in={showBasicInfo}>
        {fromInspections ? (
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <div className={clsx([classes.gridItem, 'bg'])}>
                <span className="title">Inspection Date</span>
                <span className="value">
                  {basicInfo?.inspectionDate || '-'}
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={clsx([classes.gridItem, 'bg'])}>
                <span className="title">
                  Total # of Violations in Inspections
                </span>
                <span className="value">
                  {basicInfo?.noOfViolations || '-'}
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.gridItem}>
                <span className="title">Inspection State</span>
                <span className="value">
                  {basicInfo?.inspectionState || '-'}
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.gridItem}>
                <span className="title">Out of Service</span>
                <span className="value">{basicInfo?.outOfService || '-'}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={clsx([classes.gridItem, 'bg'])}>
                <span className="title">Report Number</span>
                <span className="value">
                  {basicInfo?.reportNumber?.primaryText || '-'}
                </span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={clsx([classes.gridItem, 'bg'])}>
                <span className="title">
                  Total Weight of Inspection Violations
                </span>
                <span className="value">{basicInfo?.totalWeight || '-'}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.gridItem}>
                <span className="title">Inspection Level</span>
                <span className="value">
                  {basicInfo?.inspectionLevel?.primaryText || '-'}
                </span>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" width="50%">
            <Grid item xs={6} md={6} lg={6}>
              <div className={clsx([classes.gridItem, 'bg'])}>
                <span className="title">Accident Date</span>
                <span className="value">{basicInfo?.accidentDate || '-'}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.gridItem}>
                <span className="title">Accident State</span>
                <span className="value">{basicInfo?.state || '-'}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={clsx([classes.gridItem, 'bg'])}>
                <span className="title">Accident Number</span>
                <span className="value">
                  {basicInfo?.accidentNumber?.primaryText || '-'}
                </span>
              </div>
            </Grid>
          </Grid>
        )}
      </Collapse>
      {/* Driver and Unit Information Header */}
      <Grid container className={classes.infoHeader} mt={3}>
        <Grid item xs={8} className="title">
          DRIVER AND UNIT INFORMATION
        </Grid>
        <Grid item xs={4}>
          <div
            className={classes.infoShowHide}
            onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
            <span className="title">
              {showAdditionalInfo ? 'Show Less' : 'Show More'}
            </span>
            {showAdditionalInfo ? (
              <KeyboardArrowDownIcon className="icon" />
            ) : (
              <KeyboardArrowUpIcon className="icon" />
            )}
          </div>
        </Grid>
      </Grid>
      {/* Driver and Unit Information Details */}
      <Collapse in={showAdditionalInfo}>
        <div className={classes.infoSubHeader}>PRIMARY DRIVER</div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>FMCSA Reported</StyledTableCell>
                <StyledTableCell> Matched Driver</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell className="title">Name</StyledTableCell>
                <StyledTableCell>
                  {data?.primaryDriver?.fullName || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.matchedPrimaryDriver?.fullName || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">
                  License State
                </StyledTableCell>
                <StyledTableCell>
                  {fromInspections
                    ? data?.primaryDriver?.licenseStateName || '-'
                    : data?.primaryDriver?.licenseState || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {fromInspections
                    ? data?.matchedPrimaryDriver?.licenseStateName || '-'
                    : data?.matchedPrimaryDriver?.licenseState || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">DOB</StyledTableCell>
                <StyledTableCell>
                  {data?.primaryDriver?.dob || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.matchedPrimaryDriver?.dob || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">Age</StyledTableCell>
                <StyledTableCell>
                  {data?.primaryDriver?.age || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.matchedPrimaryDriver?.age || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">
                  Matched Type
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell>
                  {getIconByStatus(
                    data?.matchedPrimaryDriver?.matchedStatus || '-',
                  )}
                  {data?.matchedPrimaryDriver?.matchedStatus || '-'}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {fromInspections && (
          <>
            <div className={classes.infoSubHeader}>SECONDARY DRIVER</div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                    <StyledTableCell>FMCSA Reported</StyledTableCell>
                    <StyledTableCell> Matched Driver</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell className="title">Name</StyledTableCell>
                    <StyledTableCell>
                      {data?.secondaryDriver?.fullName || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedSecondaryDriver?.fullName || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">
                      License State
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.secondaryDriver?.licenseStateName || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedSecondaryDriver?.licenseStateName || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">DOB</StyledTableCell>
                    <StyledTableCell>
                      {data?.secondaryDriver?.dob || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedSecondaryDriver?.dob || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">Age</StyledTableCell>
                    <StyledTableCell>
                      {data?.secondaryDriver?.age || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedSecondaryDriver?.age || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">
                      Matched Type
                    </StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell>
                      {getIconByStatus(
                        data?.matchedSecondaryDriver?.matchedStatus || '-',
                      )}
                      {data?.matchedSecondaryDriver?.matchedStatus || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <div className={classes.infoSubHeader}>POWER UNIT</div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>FMCSA Reported</StyledTableCell>
                <StyledTableCell> Matched Driver</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell className="title">
                  License State
                </StyledTableCell>
                <StyledTableCell>
                  {fromInspections
                    ? data?.truckInfo?.licenseStateName || '-'
                    : data?.powerUnit?.licenseState || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {fromInspections
                    ? data?.matchedTruckInfo?.licenseStateName || '-'
                    : data?.matchedTruckInfo?.licenseState || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">Make</StyledTableCell>
                <StyledTableCell>
                  {fromInspections
                    ? data?.truckInfo?.make || '-'
                    : data?.powerUnit?.make || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.matchedTruckInfo?.make || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">License</StyledTableCell>
                <StyledTableCell>
                  {fromInspections
                    ? data?.truckInfo?.licenseNumber || '-'
                    : data?.powerUnit?.licenseNumber || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.matchedTruckInfo?.licenseNumber || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">VIN</StyledTableCell>
                <StyledTableCell>
                  {fromInspections
                    ? data?.truckInfo?.vinNumber || '-'
                    : data?.powerUnit?.vin || '-'}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.matchedTruckInfo?.vinNumber || '-'}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="title">
                  Matched Type
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell>
                  {getIconByStatus(
                    data?.matchedTruckInfo?.matchedStatus || '-',
                  )}
                  {data?.matchedTruckInfo?.matchedStatus || '-'}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {fromInspections && (
          <>
            <div className={classes.infoSubHeader}>TRAILER</div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                    <StyledTableCell>FMCSA Reported</StyledTableCell>
                    <StyledTableCell> Matched Driver</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell className="title">
                      License State
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.trailerInfo?.licenseStateName || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedTrailerInfo?.licenseStateName || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">Make</StyledTableCell>
                    <StyledTableCell>
                      {data?.trailerInfo?.make || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedTrailerInfo?.make || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">License</StyledTableCell>
                    <StyledTableCell>
                      {data?.trailerInfo?.licenseNumber || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedTrailerInfo?.licenseNumber || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">VIN</StyledTableCell>
                    <StyledTableCell>
                      {data?.trailerInfo?.vinNumber || '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data?.matchedTrailerInfo?.vinNumber || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell className="title">
                      Matched Type
                    </StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell>
                      {getIconByStatus(
                        data?.matchedTrailerInfo?.matchedStatus || '-',
                      )}
                      {data?.matchedTrailerInfo?.matchedStatus || '-'}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Collapse>
      <Divider className={classes.divider} />
    </div>
  );
};

export default observer(DataQChallengeDetails);
