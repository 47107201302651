import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomSortingIcon from '../../../../../components/SortingIcon/CustomSortingIcon';
import { TrainingStore } from '../../../../../store/training';
import useStyles from './AccidentsStyles';

const AccidentsHeader: React.FC<{
  trainingStore: TrainingStore;
  trainingAccidentsSortBy: (columnName: string) => void;
}> = ({ trainingStore, trainingAccidentsSortBy }) => {
  const classes = useStyles();
  // Grid Header
  const completedHeader: any = [
    { label: 'Driver Name', name: 'driverName' },
    { label: 'Accident Date', name: 'accidentDate' },
    { label: 'Accident State', name: 'accidentState' },
    { label: 'Accident Number', name: 'accidentNumber' },
    { label: 'VIN', name: 'powerUnitVin' },
    { label: 'Recommendation Created On', name: 'recommendationCreatedOn' },
    {
      label: 'Recommendation Expiration Date',
      name: 'dueDate',
    },
    { label: 'Action', name: '' },
  ];
  // Sorting enable columns
  const sortColumns = [
    'driverName',
    'accidentDate',
    'accidentState',
    'accidentNumber',
    'powerUnitVin',
    'recommendationCreatedOn',
    'dueDate',
  ];

  return (
    <TableRow>
      {completedHeader.map((header: any, index: number) => (
        <TableCell
          key={index}
          className={classes.tableHeaderBg}
          sx={header.styles}>
          <Grid item xs={12}>
            <Typography
              component={'div'}
              noWrap
              style={{
                alignItems: 'center',
                display: 'flex',
                whiteSpace: 'unset',
              }}>
              <Typography
                component={'div'}
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                }}>
                {header.label}
              </Typography>

              {sortColumns.includes(header.name) && (
                <CustomSortingIcon
                  {...{
                    filterSortBy: trainingStore.TrainingAccidentFilters.sortBy,
                    filterSortDirection:
                      trainingStore.TrainingAccidentFilters.sortDirection,
                    sortBY: trainingAccidentsSortBy,
                    sortByName: header.name,
                  }}
                />
              )}
            </Typography>
          </Grid>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default observer(AccidentsHeader);
