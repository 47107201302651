import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import EquipmentDialog from '../EquipmentDialog/EquipmentDialog';
import useStyles from './EquipmentCardHeaderStyles';

const TaxPermitsEquipmentHeader: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const [copyEquipmetVal, setCopyEquipmetVal] = React.useState<Array<any>>([]);

  useEffect(() => {
    setCopyEquipmetVal([]);
    setCopyEquipmetVal(
      props &&
        props.selecetedEquipment &&
        Array.isArray(props.selecetedEquipment)
        ? props.selecetedEquipment.map((val: any) => val)
        : [],
    );
  }, [props]);
  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          className={classes.renewalFormheadingFlex}>
          <Typography component={'h5'} className={classes.renewalFormHeading}>
            Search & Select Equipment
          </Typography>
          <Typography
            onClick={() => setIsOpen(true)}
            component={'button'}
            type={'button'}
            disabled={props.disabled}
            variant={'h5'}
            className={classes.renewalFormHeadingSearchLink}>
            Search & Select
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        {isOpen && (
          <EquipmentDialog
            isOpen={isOpen}
            closeDialog={closeDialog}
            selecetedEquipmentList={
              copyEquipmetVal && copyEquipmetVal.length
                ? copyEquipmetVal.map((val) => val)
                : []
            }
            onSelect={props.onSelectEquipment}
            requestType={props.requestType}
          />
        )}
      </Grid>
    </>
  );
};

export default observer(TaxPermitsEquipmentHeader);
