import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import React from 'react';

import useStyles from '../ProfileStyles';

type props = {
  label: string;
  value: any;
};
const FallbackText = styled(Typography)(() => ({
  color: '#DB2426',
  fontSize: 12,
  fontWeight: 'bold',
}));
const DriverListItem: React.FC<props> = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Grid item md={6}>
      <Typography component={'div'} className={classes.infoTitle}>
        {label}
      </Typography>
      <Typography component={'div'} className={classes.infodis}>
        {value ? value : <FallbackText>missing</FallbackText>}
      </Typography>
    </Grid>
  );
};

export default DriverListItem;
