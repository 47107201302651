import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

type GetListResponse = {
  nextLink: string;
  response: Inspection[];
  count: number;
};
type MoreDetailsResponse = {
  inspectionId?: string;
  primaryDriver?: any;
  secondaryDriver?: any;
  trailerInfo?: any;
  truckInfo?: any;
  violations?: any[];
  matchedPrimaryDriver?: any;
  matchedSecondaryDriver?: any;
  matchedTrailerInfo?: any;
  matchedTruckInfo?: any;
};
type GetParams = {
  type?: 'all' | 'manage-all' | 'manage-completed';
  limit?: number;
  nextLink?: string;
  sortDirection?: string;
  sortBy?: string;
  sortChanged?: boolean;
  primaryDriverMatchStatus?: string;
  secondaryDriverMatchStatus?: '' | 'No match' | 'Auto-match' | 'Confirmed';
  truckMatchStatus?: '' | 'No match' | 'Auto-match' | 'Confirmed';
  trailerMatchStatus?: '' | 'No match' | 'Auto-match' | 'Confirmed';
  primaryDriverName?: string;
  matchedPrimaryDriverName?: string;
  secondaryDriverName?: string;
  matchedSecondaryDriverName?: string;
  powerUnitVin?: string;
  matchedPowerUnitVin?: string;
  trailerVin?: string;
  matchedTrailerVin?: string;
  reportNumber?: string;
  inspectionState?: string;
  inspectionDateStart?: string;
  inspectionDateEnd?: string;
};
type getEquipmentListParams = {
  type?: 'power unit' | 'trailer';
  vin?: string;
  nextLink?: string | number;
  limit?: number;
  unitId?: string;
};
type getDriverListParams = {
  name?: string;
  nextLink?: string | number;
  limit?: number;
  driverId?: string;
};
type submitDataQParams = {
  challengeReason: string;
  challengeType: string;
  reasonType: string;
  supportDocuments?: File[];
  explanation?: string;
  violationCodes?: string;
  source: string;
};
export class InspectionsAndViolationsApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getData = async (params: GetParams) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.InspectionsAndViolations;

    const response = await this.rootStore.apiStore.call<GetListResponse>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (response.isOk()) {
      return {
        count: response.value.count,
        list: response.value.response,
        nextLink: response.value.nextLink,
      };
    }
    return {
      count: 0,
      list: [],
      nextLink: '',
    };
  };

  getMoreDetails = async (id: string): Promise<MoreDetailsResponse> => {
    const {
      GetList: { Endpoint = '', Method },
    } = ApiRoutes.InspectionsAndViolations;

    const url = `${Endpoint}/${id}`;
    const response = await this.rootStore.apiStore.call<MoreDetailsResponse>({
      method: Method,
      url,
    });

    if (response.isOk()) {
      // response.value.matchedPrimaryDriver =  {
      //   fullName: 'John Doe',
      //   dob: '4/1/1998',
      //   simplexId: '209456701498571',
      // };
      // response.value.matchedSecondaryDriver= {
      //   fullName: 'John Doe',
      //   dob: '4/1/1998',
      //   simplexId: '209456701498571',
      // };
      // response.value.matchedTruckInfo= {
      //   vinNumber: '10948571038945',
      //   unitNumber: '813459713',
      //   simplexUnitNumber: '187340589143743',
      // };
      // response.value.matchedTrailerInfo= {
      //   vinNumber: '10948571038945',
      //   unitNumber: '813459713',
      //   simplexUnitNumber: '187340589143743',
      // };
      return response.value;
    }
    return {};
  };

  getInspectionDriverViolationMatch = async (
    inspectionIds: string[],
  ): Promise<any> => {
    const {
      GetMatch: { Endpoint, Method },
    } = ApiRoutes.InspectionsAndViolations;
    let url;
    if (inspectionIds.length === 1) {
      url = `${Endpoint}${inspectionIds[0]}` as string;
    } else {
      const remainingInspectionIds = [...inspectionIds];
      remainingInspectionIds.splice(0, 1);
      let inspectionIdString = '';
      remainingInspectionIds.map((riid: string) => {
        inspectionIdString += `&inspectionIds=${riid}`;
        return true;
      });
      url = `${Endpoint}${inspectionIds[0]}${inspectionIdString}` as string;
    }
    const response = await this.rootStore.apiStore.call<MoreDetailsResponse>({
      method: Method,
      url,
    });

    if (response.isOk()) {
      return response.value;
    }
    return response;
  };

  matchDriversForInspectionsAndViolations = async (data: any): Promise<any> => {
    const {
      Match: { Endpoint, Method },
    } = ApiRoutes.InspectionsAndViolations;
    const url = `${Endpoint}`;
    const response = await this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url,
    });
    return response;
  };

  export = async (params: GetParams) => {
    const {
      Export: { Endpoint, Method },
    } = ApiRoutes.InspectionsAndViolations;

    const response = await this.rootStore.apiStore.call<GetListResponse>({
      method: Method,
      params,
      responseType: 'blob',
      url: Endpoint,
    });
    return response;
  };

  getAllDrivers = async (params: getDriverListParams) => {
    const {
      GetAllDrivers: { Endpoint, Method },
    } = ApiRoutes.InspectionsAndViolations;

    const response = await this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (response.isOk()) {
      return {
        list: response.value.response,
        nextLink: response.value.nextLink,
      };
    }
    return {
      count: 0,
      list: [],
      nextLink: '',
    };
  };

  getAllEquipments = async (params: getEquipmentListParams) => {
    const {
      GetAllEquipments: { Endpoint, Method },
    } = ApiRoutes.InspectionsAndViolations;

    const response = await this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (response.isOk()) {
      return {
        count: response.value.count || 0,
        list: response.value.response,
        nextLink: response.value.nextLink || '',
      };
    }
    return {
      count: 0,
      list: [],
      nextLink: '',
    };
  };

  submitDataQChallenge = async (payload: submitDataQParams): Promise<any> => {
    const {
      submitDataQChallenge: { Endpoint, Method },
    } = ApiRoutes.InspectionsAndViolations;
    const url = `${Endpoint}`;
    const response = await this.rootStore.apiStore.call<any>({
      data: payload,
      method: Method,
      url,
    });
    return response;
  };
}
