import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Skeleton, styled, Tab } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import useTrends from '../useTrends';

type IProps = {
  type?: Top10Trending;
  name: TrendType;
  onChangeRange?: (range: string) => unknown;
  renderchart: (data: any[], label: string) => React.ReactElement;
  tabs?: Top10Trending[];
};

const Section = styled('section')(() => ({
  backgroundColor: '#FFF',
  boxShadow: '0px 0px 8px 4px #e9e9e9',
  height: '100%',
  padding: '10px 20px',
}));

const Heading1 = styled('p')(() => ({
  fontSize: '15pt',
  fontWeight: 'bolder',
  wordSpacing: '-1px',
}));

const Heading2 = styled('p')(() => ({
  fontSize: '15pt',
  fontWeight: 'bold',
  marginLeft: 5,
}));

const Heading3 = styled('p')(() => ({
  color: '#9c9c9c',
  fontSize: '13pt',
  fontWeight: 'bolder',
  marginLeft: 10,
}));

const Header = styled('div')(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 0',
}));

const TabX = styled(Tab)(() => ({
  marginRight: 10,
  maxWidth: 'none',
  minWidth: 'auto',
  padding: '10px 0',
  width: 'auto',
}));

const SelectX = styled('select')(() => ({
  border: 'solid 2px #d5d5d5',
  borderRadius: 5,
  cursor: 'pointer',
  fontSize: 13,
  padding: '6px 3px',
}));

const OptionX = styled('option')(() => ({
  cursor: 'pointer',
  fontSize: 14,
  margin: '5px 0',
  padding: '10px 3px',
}));

const Trend: React.FC<IProps> = ({
  name,
  onChangeRange,
  tabs = [],
  renderchart,
}) => {
  const [range, setRange] = useState<string>();
  const [, setValue] = useState('0');
  const [trendType, setTrendType] = useState<Top10Trending | undefined>();

  const { data, isLoading, topCount, totalCount, history } = useTrends(
    name,
    trendType,
    range || '',
  ) as TrendCardData & {
    isLoading: boolean;
    history: any;
  };

  const handleChange = (e: any, v: number) => {
    setTrendType(tabs[v]);
    setValue(`${v}`);
  };

  const currentTabIndex = trendType
    ? tabs.findIndex((el) => el === trendType)
    : 0;

  useEffect(() => {
    if (!range && !trendType) {
      setRange(history?.range || 'matched');
      setTrendType(history?.trendType || tabs[0]);
    }
  }, [history, tabs, range, trendType]);

  return (
    <Section id={`dashboard-trend-${name.replace(' ', '-') as string}`}>
      <Header className="flex-row">
        <div className="flex-row-align-center">
          <Heading1>
            {name === 'Top 10 Trending' ? '24 Months' : 'Top 10'}
          </Heading1>
          <Heading2>{name}</Heading2>
          {name !== 'Top 10 Trending' && (
            <Heading3>
              ({topCount} of {totalCount})
            </Heading3>
          )}
        </div>

        {trendType !== 'ToDos' && (
          <SelectX
            defaultValue={history?.range || 'matched'}
            value={range}
            onChange={(event: SyntheticEvent<HTMLSelectElement, Event>) => {
              setRange(event.currentTarget.value);
              onChangeRange && onChangeRange(event.currentTarget.value);
            }}>
            <OptionX value="matched">24 Months Matched {name}</OptionX>
            <OptionX value="unknown">24 Months Unknown {name}</OptionX>
          </SelectX>
        )}
      </Header>
      {trendType && (
        <TabContext value={`${currentTabIndex}`}>
          <Box>
            <TabList onChange={handleChange} aria-label="Top Trending charts">
              {tabs.map((tab, index) => (
                <TabX
                  style={{ fontWeight: 'bold', textTransform: 'none' }}
                  label={tab === 'ToDos' ? 'To-Dos' : tab}
                  key={index}
                  value={`${index}`}
                />
              ))}
            </TabList>
          </Box>
          {tabs.map((tab, index) => (
            <TabPanel
              key={index}
              style={{
                height: 450,
                padding: 5,
              }}
              value={`${index}`}>
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  animation="wave"
                />
              ) : Array.isArray(data) && data.length ? (
                renderchart(data, trendType)
              ) : (
                <Box>
                  <br />
                  <br />
                  <Heading2>No Data</Heading2>
                </Box>
              )}
            </TabPanel>
          ))}
        </TabContext>
      )}
    </Section>
  );
};

export default Trend;
