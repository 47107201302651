import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { InputText } from '../../Forms/InputText';
import search from './../../../assets/images/h-search.svg';

const ViolationsSearchForm: React.FC<any> = (props) => {
  const { classes, searchPage, onClose } = props;

  const [input1Ref, setInput1Focus] = UseFocus();
  const { violationsStore: store } = useCustomStore();
  const { control, handleSubmit, setValue } = useForm<{ description: string }>({
    defaultValues: {
      description: store.SearchParams || '',
    },
  });

  //   const formValue = useMemo(() => getValues(), [getValues]);

  useMountEffect(setInput1Focus);

  const initSearch = useCallback(
    (data) => {
      if (!data.description) {
        return;
      }
      (async () => {
        store.setShowLoader(true);
        store.resetSortParams();
        store.resetParams();
        store.setSearchParams(data.description);
        await store.getList(data);

        onClose();
        store.setShowLoader(false);
      })();
    },
    [store, onClose],
  );

  const resetFilter = useCallback(() => {
    store.resetParams();
    store.resetSearchParams();
    store.resetSortParams();
    setValue('description', '');
    store.getList();
  }, [store, setValue]);
  return (
    <form onSubmit={handleSubmit(initSearch)} id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search Violation Description
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="description"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              // disabled
              type="button"
              onClick={resetFilter}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            // disabled={!formValue.description}
            className={classes.filterBtn}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(ViolationsSearchForm);
