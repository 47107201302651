import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { TrainingStore } from '../../../../../store/training';
import useStyles from './AccidentsStyles';

type AccidentsListProps = {
  onAssignAccident: (accident: any) => void;
  trainingStore: TrainingStore;
};
const AccidentsList = (props: AccidentsListProps): React.ReactElement => {
  const classes = useStyles();
  const { onAssignAccident, trainingStore } = props;

  // Action Column
  const renderActionColumn = (accident: any): React.ReactElement => {
    return (
      <Button
        variant="outlined"
        size="large"
        onClick={() => onAssignAccident(accident)}>
        Assign
      </Button>
    );
  };

  return (
    <>
      {trainingStore.TrainingAccidentsData.map(
        (accident: any, index: number) => (
          <TableRow
            key={index}
            sx={{
              backgroundColor: '#FFFFFF',
              height: '80px',
              minHeight: '80px',
              paddingBottom: '20px',
            }}>
            {/* Driver Name */}
            <TableCell
              style={{
                borderLeft: '8px #D8BF57 solid',
                paddingBottom: 0,
                paddingTop: 0,
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  noWrap
                  style={{
                    fontFamily: 'FiraSans-Bold',
                    fontSize: '0.8rem',
                  }}>
                  {accident?.driver?.name || '-'}
                </Typography>
              </Grid>
            </TableCell>
            {/* Accident Date */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {accident.accidentDate || '-'}
              </Grid>
            </TableCell>
            {/* Accident State # */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {accident.accidentState || '-'}
              </Grid>
            </TableCell>
            {/* Accident Number */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {accident.accidentNumber || '-'}
              </Grid>
            </TableCell>
            {/* VIN */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {accident.powerUnitVin || '-'}
              </Grid>
            </TableCell>
            {/* Recommendation Created On */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {accident.recommendationCreatedOn || '-'}
              </Grid>
            </TableCell>
            {/* Recommendation Expiration Date */}
            <TableCell className={classes.tableCell}>
              <Grid item xs={12} md={12} lg={12}>
                {accident.recommendationExpirationDate || '-'}
              </Grid>
            </TableCell>
            {/* Actions */}
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Grid item className={classes.gridActions}>
                {renderActionColumn(accident)}
              </Grid>
            </TableCell>
          </TableRow>
        ),
      )}
    </>
  );
};

export default observer(AccidentsList);
