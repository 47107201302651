import InfoIcon from '@mui/icons-material/Info';
import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';

import ValueCheck from '../../../../assets/images/value-check.svg';
import ValueX from '../../../../assets/images/value-x.svg';
import { formatPhoneNumber, isExpired } from '../../../../utils/helper';
import useStyles from '../ProfileStyles';
import DriverListItem from './DriverListItem';

const DriverInfo: React.FC<{
  UserData: any;
}> = ({ UserData }) => {
  const classes = useStyles();
  const [complianceStatus, setComplianceStatus] = useState<string>('');
  const userAddress: { [key: string]: string } = {
    address: UserData.address || '',
    state: UserData.state || '',
    zip: UserData.zip || '',
  };
  useEffect(() => {
    if (UserData.complianceStatus === 'NOT COMPLIANT')
      setComplianceStatus('Not Compliant');
    else setComplianceStatus('Compliant');
  }, [UserData]);

  const getFormattedSSN = (ssn = '') => {
    return `*** ** ${ssn.slice(ssn.length - 4, ssn.length)}`;
  };
  return (
    <>
      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem label="Driver Name" value={UserData.name} />
        <DriverListItem label="Email Address" value={UserData.email} />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Phone Number"
          value={
            UserData.phoneNumber && formatPhoneNumber(UserData.phoneNumber)
          }
        />
        <DriverListItem
          label="SSN"
          value={UserData.ssn && getFormattedSSN(UserData.ssn)}
        />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Address"
          value={
            UserData.address &&
            `${userAddress.address}, ${userAddress.state}, ${userAddress.zip}`
          }
        />
        <DriverListItem label="DOB" value={UserData.dob} />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem label="Hire Date" value={UserData.hireDate} />
      </Grid>

      <Divider className={classes.divider} style={{ margin: '1rem 0rem' }} />

      <Grid item md={8}>
        <Typography
          component={'div'}
          className={classes.profileTitle}
          style={{ alignItems: 'center', display: 'flex' }}>
          Driver&apos;s License
          <Typography
            component={'div'}
            style={{ alignItems: 'center', display: 'flex' }}>
            {isExpired(UserData.licenseExpirationDate) ? (
              <InfoIcon className={classes.expiredIcon} />
            ) : null}
            <Typography component={'span'} className={classes.expired}>
              {isExpired(UserData.licenseExpirationDate)}
            </Typography>
          </Typography>
        </Typography>
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Driver's License #"
          value={UserData.licenseNumber}
        />
        <DriverListItem
          label="Driver's License State"
          value={UserData.licenseState}
        />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Driver's License Type"
          value={UserData.licenseType}
        />
        <DriverListItem
          label="Driver's License Endorsement"
          value={
            UserData.licenseEndorsements ? UserData.licenseEndorsements : 'None'
          }
        />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem label="Driver Type" value={UserData.driverType} />
        <DriverListItem
          label="Driver's License Expiration"
          value={UserData.licenseExpirationDate}
        />
      </Grid>

      <Grid item md={6} sm={12} xs={12}>
        <Box className={classes.formLabelClass}>Compliance Status</Box>
        <Box>
          {complianceStatus === 'Compliant' ? (
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <img src={ValueCheck} alt="Compliant" height={12} width={12} />{' '}
              <span
                style={{
                  color: '#0DA728',
                  fontSize: '14px',
                  lineHeight: '17px',
                  paddingLeft: '5px',
                }}>
                {complianceStatus}
              </span>
            </div>
          ) : (
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <img src={ValueX} alt="Not Compliant" height={12} width={12} />{' '}
              <span
                style={{
                  color: '#A70D0D',
                  fontSize: '14px',
                  lineHeight: '17px',
                  paddingLeft: '5px',
                }}>
                {complianceStatus}
              </span>
            </div>
          )}
        </Box>
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Driver's License on File"
          value={UserData.licenseOnFile ? 'Yes' : 'No'}
        />
      </Grid>
    </>
  );
};

export default DriverInfo;
