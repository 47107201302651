import styled from '@mui/material/styles/styled';

const ContentDiv = styled('div')({
  display: 'flex',
  lineHeight: '24px',
  padding: '1px 0',
  wordBreak: 'break-word',
});
const Value = styled('div')({
  color: '#241a2e',
  fontSize: '14px',
  fontWeight: '600',
});

const Label = styled('div')({
  color: '#241a2e',
  fontSize: '14px',
  paddingRight: '10px',
});
const SpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});
export { ContentDiv, Label, Value, SpaceBetween };
