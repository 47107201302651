import { makeAutoObservable, toJS } from 'mobx';

import { Address, AnnualReport, AnnualReportFormInputs } from '../types';
import { RootStore } from './';

const InitialData: AnnualReport = {
  // confirmOwner: '',
  mailAddress: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
  physicalAddress: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
  physicalMailAddressSameStatus: false,
  // registeredAgent: '',
};

export class AnnualReportStore {
  private _annualReport: AnnualReport = { ...InitialData };
  private _initialApiRecord: AnnualReport = { ...InitialData };
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get annualReport(): AnnualReport {
    return { ...toJS(this._annualReport) };
  }

  setPhysicalAddressDetail(addressDetail: Address) {
    this._annualReport.physicalAddress = {
      ...this._annualReport.physicalAddress,
      ...addressDetail,
    };
  }

  setMailAddressDetail(addressDetail: Address) {
    this._annualReport.mailAddress = {
      ...this._annualReport.mailAddress,
      ...addressDetail,
    };
  }

  setAnnualDetail(key: string, value: string) {
    this._annualReport = {
      ...this._annualReport,
      [key]: value,
    };
  }

  resetAnnualReport() {
    this._annualReport = { ...this._initialApiRecord };
  }

  async setInitialData(): Promise<
    { formData: AnnualReportFormInputs; sameAddressStatus: boolean } | undefined
  > {
    const annualInitialData: any =
      await this.rootStore.apiStore.annualReportApi.getInitialData({});
    if (annualInitialData.isOk()) {
      const {
        value: {
          physicalAddress: {
            address: phyAddr,
            city: phyCity,
            state: phyState,
            zip: phyZip,
          },
          mailingAddress: {
            address: mailAddr,
            city: mailCity,
            state: mailState,
            zip: mailZip,
          },
          sameAsPhysicalAddress,
          // confirmOwner,
          // registeredAgent,
        },
      } = annualInitialData;

      this._annualReport = {
        // confirmOwner: confirmOwner || '',
        mailAddress: {
          address: mailAddr || '',
          city: mailCity || '',
          state: mailState || '',
          zipcode: mailZip || 0,
        },
        physicalAddress: {
          address: phyAddr || '',
          city: phyCity || '',
          state: phyState || '',
          zipcode: phyZip || 0,
        },
        physicalMailAddressSameStatus: sameAsPhysicalAddress || false,
        // registeredAgent: registeredAgent || '',
      };

      this._initialApiRecord = { ...this._annualReport };

      return {
        formData: {
          // confirmOwner: confirmOwner || '',
          mailingAddress: mailAddr || '',
          mailingCity: mailCity || '',
          mailingState: mailState || '',
          mailingZip: mailZip || '',
          physicalAddress: phyAddr || '',
          physicalCity: phyCity || '',
          physicalState: phyState || '',
          physicalZip: phyZip || '',
          // registeredAgent: registeredAgent || '',
        },
        sameAddressStatus: sameAsPhysicalAddress || false,
      };
    }
    return undefined;
  }

  async addAnualReportData(todoId?: string) {
    const {
      // confirmOwner,
      mailAddress: {
        address: mailAddr,
        city: mailCity,
        state: mailState,
        zipcode: mailZip,
      },
      physicalAddress: {
        address: physicalAddr,
        city: physicalCity,
        state: physicalState,
        zipcode: physicalZip,
      },
      physicalMailAddressSameStatus,
      // registeredAgent,
    } = this.annualReport;

    const apiData = {
      // confirmOwner: confirmOwner,
      mailingAddress: {
        address: mailAddr,
        city: mailCity,
        state: mailState,
        zip: mailZip,
      },
      physicalAddress: {
        address: physicalAddr,
        city: physicalCity,
        state: physicalState,
        zip: physicalZip,
      },
      // registeredAgent: registeredAgent,
      sameAsPhysicalAddress: physicalMailAddressSameStatus,
      todoId: todoId != '' ? todoId : '',
    };

    const response: any =
      await this.rootStore.apiStore.annualReportApi.addAnualReport(apiData);
    if (response.isOk()) {
      this._annualReport = { ...this._initialApiRecord };
    }
    return response;
  }
}
