import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

// import search from '../../../assets/images/h-search.svg';
import DialogMigrate from '../../../components/Dialog/DialogMigrate';
import { InputText } from '../../../components/Forms/InputText';
import SelectDriverFromAll from '../../../components/SelectDriverFromAll';
import SelectEquipmentFromAll from '../../../components/SelectEquipmentFromAll';
import { useCustomStore } from '../../../hooks';
import { SingleDriverVinMatch } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { useStyles } from './styles';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

const MatchDriversAndVins: React.FC<any> = (props: any) => {
  const { authStore } = useCustomStore();
  const { isOpen, closeDialog, selectedInspectionsViolations } = props;
  const {
    control,
    // handleSubmit,
    formState: { errors },
    // reset,
    getValues,
    setValue,
  } = useForm<SingleDriverVinMatch>({
    criteriaMode: 'all',
    defaultValues: {
      // matchedPowerUnitVin: selectedInspectionsViolations[0].powerUnit.matchedVin
      //   ? selectedInspectionsViolations[0].powerUnit.matchedVin
      //   : '',
      // matchedPrimaryName: selectedInspectionsViolations[0].primaryDriver
      //   .matchedName
      //   ? selectedInspectionsViolations[0].primaryDriver.matchedName
      //   : '',
      // matchedSecondaryName: selectedInspectionsViolations[0].secondaryDriver
      //   .matchedName
      //   ? selectedInspectionsViolations[0].secondaryDriver.matchedName
      //   : '',
      // matchedTrailerVin: selectedInspectionsViolations[0].trailer.matchedVin
      //   ? selectedInspectionsViolations[0].trailer.matchedVin
      //   : '',
      powerUnitVINIncludeCheck: false,
      powerUnitVINMessage: '',
      powerUnitVin: selectedInspectionsViolations[0].powerUnit.vin || '',
      powerUnitVinMatchedId: selectedInspectionsViolations[0].powerUnit
        .matchedId
        ? selectedInspectionsViolations[0].powerUnit.matchedId
        : '',
      primaryDriverIncludeCheck: false,
      primaryDriverMatchedId: selectedInspectionsViolations[0].primaryDriver
        .matchedId
        ? selectedInspectionsViolations[0].primaryDriver.matchedId
        : '',

      primaryDriverMessage: selectedInspectionsViolations[0].primaryDriver
        .isTrainingAssigned
        ? 'Training has been assigned to this driver.'
        : '',
      primaryDriverName:
        selectedInspectionsViolations[0].primaryDriver.name || '',
      secondaryDriverIncludeCheck: false,
      secondaryDriverMatchedId: selectedInspectionsViolations[0].secondaryDriver
        .matchedName
        ? selectedInspectionsViolations[0].secondaryDriver.matchedName
        : '',
      secondaryDriverMessage: selectedInspectionsViolations[0].secondaryDriver
        .isTrainingAssigned
        ? 'Training has been assigned to this driver.'
        : '',

      secondaryDriverName:
        selectedInspectionsViolations[0].secondaryDriver.name || '',
      trailerVINIncludeCheck: false,
      trailerVINMessage: '',
      trailerVin: selectedInspectionsViolations[0].trailer.vin || '',
      trailerVinMatchedId: selectedInspectionsViolations[0].trailer.matchedId
        ? selectedInspectionsViolations[0].trailer.matchedId
        : '',
    },
    reValidateMode: 'onChange',
  });
  const [inspectionDriverViolation, setInspectionDriverViolation] = useState<
    Array<any>
  >([]);
  const [includedFieldList, setIncludedFieldList] = useState<Array<any>>([]);
  const [showPrimaryDriverMessage, setShowPrimaryDriverMessage] =
    useState(false);
  const [showSecondaryDriverMessage, setShowSecondaryDriverMessage] =
    useState(false);
  const [showPowerUnitVINMessage, setShowPowerUnitVINMessage] = useState(false);
  const [showTrailerUnitVINMessage, setShowTrailerUnitVINMessage] =
    useState(false);

  const classes = useStyles();
  const { inspectionsAndViolationsStore: store } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const closeDialogWindow = useCallback(
    ({ reload = true }) => {
      closeDialog && closeDialog({ reload });
    },
    [closeDialog],
  );

  const confirmDriverAndVINMatch = useCallback(async () => {
    if (includedFieldList.length === 0) {
      return;
    }
    const payload: any = {
      inspectionId: selectedInspectionsViolations[0].inspectionId,
    };
    if (includedFieldList.includes('primaryDriverId')) {
      payload.primaryDriverId = getValues('primaryDriverMatchedId');
      payload.isPrimaryDriverConfirmed = true;
      if (!payload.primaryDriverId || payload.primaryDriverId === '') {
        enqueueSnackbar(
          String('Please select matching Primary Driver'),
          SnackBarConfig('e'),
        );
        return;
      }
    }
    if (includedFieldList.includes('secondaryDriverId')) {
      payload.secondaryDriverId = getValues('secondaryDriverMatchedId');
      payload.isSecondaryDriverConfirmed = true;
      if (!payload.secondaryDriverId || payload.secondaryDriverId === '') {
        enqueueSnackbar(
          String('Please select matching Secondary Driver'),
          SnackBarConfig('e'),
        );
        return;
      }
    }
    if (includedFieldList.includes('truckUnitId')) {
      payload.truckUnitId = getValues('powerUnitVinMatchedId');
      payload.isTruckConfirmed = true;
      if (!payload.truckUnitId || payload.truckUnitId === '') {
        enqueueSnackbar(
          String('Please select matching Truck Unit'),
          SnackBarConfig('e'),
        );
        return;
      }
    }
    if (includedFieldList.includes('trailerUnitId')) {
      payload.trailerUnitId = getValues('trailerVinMatchedId');
      payload.isTrailerConfirmed = true;
      if (!payload.trailerUnitId || payload.trailerUnitId === '') {
        enqueueSnackbar(
          String('Please select matching Trailer Unit'),
          SnackBarConfig('e'),
        );
        return;
      }
    }
    payload.hasPrimaryDriverViolation =
      inspectionDriverViolation[0].hasPrimaryDriverViolation;
    payload.hasSecondaryDriverViolation =
      inspectionDriverViolation[0].hasSecondaryDriverViolation;
    // setBtnDisable(true);
    store.setShowLoader(true);
    const resp = await store.confirmMatch(payload);
    if (resp.isErr()) {
      store.setShowLoader(false);
      enqueueSnackbar(String(resp.error.message), SnackBarConfig('e'));
    } else {
      store.setShowLoader(false);
      enqueueSnackbar(
        'Your Match Confirmation has been Submitted',
        SnackBarConfig('s'),
      );
    }
    closeDialogWindow({ reload: true });
  }, [
    store,
    closeDialogWindow,
    includedFieldList,
    enqueueSnackbar,
    getValues,
    selectedInspectionsViolations,
    inspectionDriverViolation,
  ]);

  const setMatchedPrimaryDriverMessage = useCallback(
    (val: string | null) => {
      if (val) {
        if (val !== '') {
          if (
            selectedInspectionsViolations[0].primaryDriver.isTrainingAssigned
          ) {
            setValue(
              'primaryDriverMessage',
              'Training has been assigned to this driver.',
            );
            setShowPrimaryDriverMessage(true);
          } else {
            setValue('primaryDriverMessage', '');
            setShowPrimaryDriverMessage(false);
          }
        }
      } else {
        setValue('primaryDriverMessage', '');
        setShowPrimaryDriverMessage(false);
      }
    },
    [setValue, selectedInspectionsViolations],
  );

  const setMatchedSecondaryDriverMessage = (val: string | null) => {
    if (val) {
      if (val !== '') {
        if (
          selectedInspectionsViolations[0].secondaryDriver.isTrainingAssigned
        ) {
          setValue(
            'secondaryDriverMessage',
            'Training has been assigned to this driver.',
          );
          setShowSecondaryDriverMessage(true);
        } else {
          setValue('secondaryDriverMessage', '');
          setShowSecondaryDriverMessage(false);
        }
      }
    } else {
      setValue('secondaryDriverMessage', '');
      setShowSecondaryDriverMessage(false);
    }
  };

  const setMatchedPowerUnitVINMessage = (val: string | null) => {
    if (val) {
      if (val !== '') {
        setValue('powerUnitVINMessage', '');
        setShowPowerUnitVINMessage(false);
      }
    } else {
      setValue('powerUnitVINMessage', '');
      setShowPowerUnitVINMessage(false);
    }
  };

  const setMatchedTrailerVINMessage = (val: string | null) => {
    if (val) {
      if (val !== '') {
        setValue('trailerVINMessage', '');
        setShowTrailerUnitVINMessage(false);
      }
    } else {
      setValue('trailerVINMessage', '');
      setShowTrailerUnitVINMessage(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIncludedFieldList([]);
      const getInspectionDriverViolationMatchResp =
        await store.getInspectionDriverViolationMatch([
          selectedInspectionsViolations[0].inspectionId,
        ]);
      setInspectionDriverViolation(getInspectionDriverViolationMatchResp);
    })();
  }, [store, selectedInspectionsViolations]);

  return (
    <DialogMigrate
      aria-labelledby="admin-users-select"
      className={classes.dialogSingle}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {
        closeDialogWindow({ reload: true });
      }}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Medium',
          fontSize: '18px',
          letterSpacing: 0,
          lineHeight: '26px',
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>MATCH DRIVERS & VINS</div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialogWindow({ reload: false });
            }}>
            CLOSE
          </Link>
        </div>
      </DialogTitle>
      <form>
        <DialogContent id="scroll" className={classes.bodyWrapper}>
          <Box
            sx={{
              marginBottom: '15px',
            }}>
            <Divider className={classes.divider} />
          </Box>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: '#FFFFFF',
              marginBottom: '10px',
            }}>
            <Grid
              container
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                paddingBottom: '15px',
                paddingTop: '15px',
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <p
                  style={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '14px',
                    lineHeight: '17px',
                    opacity: '43%',
                    paddingLeft: '14px',
                  }}>
                  Review And confirm Primary Driver matching name.
                </p>
              </Grid>
              <Grid item xs={1} md={1} lg={1} style={{ maxWidth: '1%' }}>
                <Checkbox
                  className={classes.customCheck}
                  onChange={(event: any) => {
                    setValue(
                      'primaryDriverIncludeCheck',
                      event.currentTarget.checked,
                    );
                    if (event.currentTarget.checked) {
                      setIncludedFieldList([
                        ...includedFieldList,
                        'primaryDriverId',
                      ]);
                      if (
                        getValues('primaryDriverMatchedId') === '' &&
                        getValues('primaryDriverMessage') === ''
                      ) {
                        const text =
                          'No matching Primary Driver assigned. Please select one to confirm.';
                        setValue('primaryDriverMessage', text);
                        setShowPrimaryDriverMessage(true);
                      }
                    } else {
                      const _includedFieldList = includedFieldList;
                      const index =
                        _includedFieldList.indexOf('primaryDriverId');
                      if (index > -1) {
                        _includedFieldList.splice(index, 1);
                        setIncludedFieldList([..._includedFieldList]);
                        setShowPrimaryDriverMessage(false);
                      }
                    }
                  }}
                  checked={getValues('primaryDriverIncludeCheck')}
                  color="success"
                  tabIndex={0}
                />
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Primary Driver Name
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="primaryDriverName"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <InputText
                          name={name}
                          inputRefObj={ref}
                          placeholder="Primary Driver Name"
                          errors={errors.primaryDriverName?.message || ''}
                          isRefreshValue={true}
                          isEditable={false}
                          isDisableTransparent={true}
                          type={'text'}
                          variant="standard"
                          value={value}
                          // initIcon={
                          //   <InputAdornment position="start">
                          //     <img className={classes.hIcon} src={search} />
                          //   </InputAdornment>
                          // }
                          className={classes.selectClassName}
                          onChangeText={(val) => {
                            onChange(val);
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Matched Name
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="primaryDriverMatchedId"
                    render={({
                      field: { onChange, value, name, ref, onBlur, ...rest },
                      formState,
                    }) => {
                      return (
                        <SelectDriverFromAll
                          onChange={(val: string | null): any => {
                            onChange(val);
                            setMatchedPrimaryDriverMessage(val);
                          }}
                          name={name}
                          value={value}
                          _ref={ref}
                          disable={!getValues('primaryDriverIncludeCheck')}
                          _key="primary_driver_list"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              {getValues('primaryDriverIncludeCheck') &&
                getValues('primaryDriverMessage') &&
                getValues('primaryDriverMessage') !== '' &&
                getValues('primaryDriverMatchedId') == '' &&
                showPrimaryDriverMessage && (
                  <Grid item xs={12} md={12} lg={12}>
                    <p
                      style={{
                        color: '#A70D0D',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                      }}>
                      {getValues('primaryDriverMessage')}
                    </p>
                  </Grid>
                )}
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                paddingBottom: '15px',
                paddingTop: '15px',
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <p
                  style={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '14px',
                    lineHeight: '17px',
                    opacity: '43%',
                    paddingLeft: '14px',
                  }}>
                  Review And confirm Secondary Driver matching name.
                </p>
              </Grid>
              <Grid item xs={1} md={1} lg={1} style={{ maxWidth: '1%' }}>
                <Checkbox
                  className={classes.customCheck}
                  onChange={(event: any) => {
                    setValue(
                      'secondaryDriverIncludeCheck',
                      event.currentTarget.checked,
                    );
                    if (event.currentTarget.checked) {
                      setIncludedFieldList([
                        ...includedFieldList,
                        'secondaryDriverId',
                      ]);
                      if (
                        getValues('secondaryDriverMatchedId') === '' &&
                        getValues('secondaryDriverMessage') === ''
                      ) {
                        const text =
                          'No matching Secondary Driver assigned. Please select one to confirm.';
                        setValue('secondaryDriverMessage', text);
                        setShowSecondaryDriverMessage(true);
                      }
                    } else {
                      const _includedFieldList = includedFieldList;
                      const index =
                        _includedFieldList.indexOf('secondaryDriverId');
                      if (index > -1) {
                        _includedFieldList.splice(index, 1);
                        setIncludedFieldList([..._includedFieldList]);
                        setShowSecondaryDriverMessage(false);
                      }
                    }
                  }}
                  checked={getValues('secondaryDriverIncludeCheck')}
                  color="success"
                  tabIndex={0}
                />
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Secondary Driver Name
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="secondaryDriverName"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <InputText
                          name={name}
                          inputRefObj={ref}
                          placeholder="Secondary Driver Name"
                          errors={errors.secondaryDriverName?.message || ''}
                          isRefreshValue={true}
                          isEditable={false}
                          isDisableTransparent={true}
                          type={'text'}
                          variant="standard"
                          value={value}
                          // initIcon={
                          //   <InputAdornment position="start">
                          //     <img className={classes.hIcon} src={search} />
                          //   </InputAdornment>
                          // }
                          className={classes.selectClassName}
                          onChangeText={(val) => {
                            onChange(val);
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Matched Name
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="secondaryDriverMatchedId"
                    render={({
                      field: { onChange, value, name, ref, onBlur, ...rest },
                      formState,
                    }) => {
                      return (
                        <SelectDriverFromAll
                          onChange={(val: string | null): any => {
                            onChange(val);
                            setMatchedSecondaryDriverMessage(val);
                          }}
                          name={name}
                          value={value}
                          _ref={ref}
                          disable={!getValues('secondaryDriverIncludeCheck')}
                          _key="secondary_driver_list"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              {getValues('secondaryDriverIncludeCheck') &&
                getValues('secondaryDriverMessage') &&
                getValues('secondaryDriverMessage') !== '' &&
                getValues('secondaryDriverMatchedId') === '' &&
                showSecondaryDriverMessage && (
                  <Grid item xs={12} md={12} lg={12}>
                    <p
                      style={{
                        color: '#A70D0D',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                      }}>
                      {getValues('secondaryDriverMessage')}
                    </p>
                  </Grid>
                )}
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                paddingBottom: '15px',
                paddingTop: '15px',
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <p
                  style={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '14px',
                    lineHeight: '17px',
                    opacity: '43%',
                    paddingLeft: '14px',
                  }}>
                  Review And confirm Power Unit VIN.
                </p>
              </Grid>
              <Grid item xs={1} md={1} lg={1} style={{ maxWidth: '1%' }}>
                <Checkbox
                  className={classes.customCheck}
                  onChange={(event: any) => {
                    setValue(
                      'powerUnitVINIncludeCheck',
                      event.currentTarget.checked,
                    );
                    if (event.currentTarget.checked) {
                      setIncludedFieldList([
                        ...includedFieldList,
                        'truckUnitId',
                      ]);
                      if (getValues('powerUnitVinMatchedId') === '') {
                        const text =
                          'No matching VIN selected. Please select one to confirm.';
                        setValue('powerUnitVINMessage', text);
                        setShowPowerUnitVINMessage(true);
                      }
                    } else {
                      const _includedFieldList = includedFieldList;
                      const index = _includedFieldList.indexOf('truckUnitId');
                      if (index > -1) {
                        _includedFieldList.splice(index, 1);
                        setIncludedFieldList([..._includedFieldList]);
                        setShowPowerUnitVINMessage(false);
                      }
                    }
                  }}
                  checked={getValues('powerUnitVINIncludeCheck')}
                  color="success"
                  tabIndex={0}
                />
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Power Unit VIN
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="powerUnitVin"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <InputText
                          name={name}
                          inputRefObj={ref}
                          placeholder="Power Unit VIN"
                          errors={errors.powerUnitVin?.message || ''}
                          isRefreshValue={true}
                          isEditable={false}
                          isDisableTransparent={true}
                          type={'text'}
                          variant="standard"
                          value={value}
                          // initIcon={
                          //   <InputAdornment position="start">
                          //     <img className={classes.hIcon} src={search} />
                          //   </InputAdornment>
                          // }
                          className={classes.selectClassName}
                          onChangeText={(val) => {
                            onChange(val);
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Matched VIN
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="powerUnitVinMatchedId"
                    render={({
                      field: { onChange, value, name, ref, onBlur },
                      formState,
                    }) => {
                      return (
                        <SelectEquipmentFromAll
                          name={name}
                          onChange={(val: string | null): any => {
                            onChange(val);
                            setMatchedPowerUnitVINMessage(val);
                          }}
                          disable={!getValues('powerUnitVINIncludeCheck')}
                          value={value}
                          _ref={ref}
                          type="power unit"
                          _key="power_unit"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              {getValues('powerUnitVINIncludeCheck') &&
                getValues('powerUnitVINMessage') &&
                getValues('powerUnitVINMessage') !== '' &&
                getValues('powerUnitVinMatchedId') === '' &&
                showPowerUnitVINMessage && (
                  <Grid item xs={12} md={12} lg={12}>
                    <p
                      style={{
                        color: '#A70D0D',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                      }}>
                      {getValues('powerUnitVINMessage')}
                    </p>
                  </Grid>
                )}
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                paddingBottom: '15px',
                paddingTop: '15px',
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <p
                  style={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '14px',
                    lineHeight: '17px',
                    opacity: '43%',
                    paddingLeft: '14px',
                  }}>
                  Review And confirm Trailer VIN.
                </p>
              </Grid>
              <Grid item xs={1} md={1} lg={1} style={{ maxWidth: '1%' }}>
                <Checkbox
                  className={classes.customCheck}
                  onChange={(event: any) => {
                    setValue(
                      'trailerVINIncludeCheck',
                      event.currentTarget.checked,
                    );
                    if (event.currentTarget.checked) {
                      setIncludedFieldList([
                        ...includedFieldList,
                        'trailerUnitId',
                      ]);
                      if (getValues('trailerVinMatchedId') === '') {
                        const text =
                          'No matching VIN selected. Please select one to confirm.';
                        setValue('trailerVINMessage', text);
                        setShowTrailerUnitVINMessage(true);
                      }
                    } else {
                      const _includedFieldList = includedFieldList;
                      const index = _includedFieldList.indexOf('trailerUnitId');
                      if (index > -1) {
                        _includedFieldList.splice(index, 1);
                        setIncludedFieldList([..._includedFieldList]);
                        setShowTrailerUnitVINMessage(false);
                      }
                    }
                  }}
                  checked={getValues('trailerVINIncludeCheck')}
                  color="success"
                  tabIndex={0}
                />
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Trailer VIN
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="trailerVin"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <InputText
                          name={name}
                          inputRefObj={ref}
                          placeholder="Trailer VIN"
                          errors={errors.trailerVin?.message || ''}
                          isRefreshValue={true}
                          isEditable={false}
                          isDisableTransparent={true}
                          type={'text'}
                          variant="standard"
                          value={value}
                          // initIcon={
                          //   <InputAdornment position="start">
                          //     <img className={classes.hIcon} src={search} />
                          //   </InputAdornment>
                          // }
                          className={classes.selectClassName}
                          onChangeText={(val) => {
                            onChange(val);
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5} md={5} lg={5}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                  }}>
                  Matched VIN
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="trailerVinMatchedId"
                    render={({
                      field: { onChange, value, name, ref, onBlur, ...rest },
                      formState,
                    }) => {
                      return (
                        <SelectEquipmentFromAll
                          onChange={(val: string | null): any => {
                            onChange(val);
                            setMatchedTrailerVINMessage(val);
                          }}
                          name={name}
                          value={value}
                          _ref={ref}
                          disable={!getValues('trailerVINIncludeCheck')}
                          type="trailer"
                          _key="trailer"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              {getValues('trailerVINIncludeCheck') &&
                getValues('trailerVINMessage') &&
                getValues('trailerVINMessage') !== '' &&
                getValues('trailerVinMatchedId') === '' &&
                showTrailerUnitVINMessage && (
                  <Grid item xs={12} md={12} lg={12}>
                    <p
                      style={{
                        color: '#A70D0D',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                      }}>
                      {getValues('trailerVINMessage')}
                    </p>
                  </Grid>
                )}
            </Grid>
            <Divider className={classes.divider} />
          </Grid>
        </DialogContent>
        <DialogActions className={classes.jxy}>
          <BottomButton
            disabled={
              includedFieldList.length <= 0 || authStore.IsImpersonatingMode
            }
            type={'button'}
            variant="contained"
            sx={{ width: '100%' }}
            onClick={async () =>
              includedFieldList.length && (await confirmDriverAndVINMatch())
            }>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontFamily: 'FiraSans-Medium',
                fontSize: '0.9375rem',
                fontWeight: '500',
                height: '22px',
                letterSpacing: '1px',
                lineHeight: '22px',
                textAlign: 'center',
              }}>
              CONFIRM MATCHES
            </Typography>
          </BottomButton>
        </DialogActions>
      </form>
    </DialogMigrate>
  );
};

export default observer(MatchDriversAndVins);
