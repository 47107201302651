import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Popover,
  TableCell,
  Typography,
} from '@mui/material';
import styled from '@mui/system/styled';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Exclamation from '../../../assets/images/Exclamation.svg';
import GreenCheck from '../../../assets/images/GreenCheck.svg';
import X from '../../../assets/images/X.svg';
import NavRoutes from '../../../config/routes/NavRoutes';

type DataItem = {
  highlight: boolean;
  name: keyof any;
  spacing: number;
};

const CustomPaper = styled(Paper)<{ isactive: boolean }>(({ isactive }) => ({
  ...((isactive && { backgroundColor: '#F1F4F9 !important' }) || {}),
  padding: '10px',
}));

const Text = styled('p')(({ theme }) => ({
  color: '#241A2E',
  fontSize: 14,
}));

const SecondaryText = styled(Text)(() => ({
  margin: '5px 0',
  whiteSpace: 'nowrap',
}));

const Caption = styled(SecondaryText)(() => ({
  color: '#7a7878',
  fontSize: 11,
  margin: 0,
}));

const PaperWrap = styled('div')(() => ({
  display: 'flex',
}));

const getIconByStatus = (status: string) => {
  switch (status) {
    case 'No match':
      return <img src={X} style={{ paddingRight: '4px' }} />;
    case 'Auto-match':
      return <img src={Exclamation} style={{ paddingRight: '4px' }} />;
    case 'Confirmed':
      return <img src={GreenCheck} style={{ paddingRight: '4px' }} />;
    default:
      return;
  }
};

const showIcon = (status: string, primaryText: string) => {
  let show = false;
  switch (status) {
    case 'No match':
      show = primaryText && primaryText !== '-' ? true : false;
      break;
    case 'Auto-match':
      show = true;
      break;
    case 'Confirmed':
      show = true;
      break;
    default:
      show = false;
  }
  return show;
};

const DataCell: React.FC<{
  status?: string;
  primaryText?: string;
  secondaryText?: string;
  caption?: string;
  boldText: boolean;
}> = ({
  boldText = false,
  status = '',
  primaryText = '-',
  secondaryText = '',
  caption = '',
}) => {
  return (
    <div className="flex-row">
      {showIcon(status, primaryText) && (
        <div className="flex-column">{getIconByStatus(status)}</div>
      )}
      <div className="flex-column">
        {boldText && <h4> {primaryText} </h4>}
        {!boldText && secondaryText && (
          <Text>
            <b>{primaryText}</b>
          </Text>
        )}
        {!boldText && !secondaryText && <Text>{primaryText}</Text>}
        {secondaryText && (
          <SecondaryText>
            <i>{secondaryText}</i>
          </SecondaryText>
        )}
        {caption && (
          <Caption>
            <b>
              <i>{caption}</i>
            </b>
          </Caption>
        )}
      </div>
    </div>
  );
};

type Props = {
  data: any;
  cells: DataItem[];
  isManage?: boolean;
  handleMatchInfo?: (id: string) => unknown;
  renderChild?: (id: string) => React.ReactElement;
};

const Accident: React.FC<Props> = ({
  data,
  cells = [],
  renderChild,
  isManage,
  handleMatchInfo = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const navigate = useNavigate();
  useEffect(() => {
    setIsExpanded(false);
  }, [data]);

  const handleFleetOption = (event: any, unitId: string) => {
    const obj: any = {};
    if (tooltipIsOpen[unitId]) {
      obj[unitId] = false;
      setAnchorEl(null);
    } else {
      obj[unitId] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const onNavigateToDataQChallenge = (accidentInfo: any) => {
    if (!accidentInfo || !accidentInfo.accidentId) {
      return;
    }
    navigate(
      `/${NavRoutes.PRIVATE.DATA_Q_CHALLENGE}/accidents/${
        accidentInfo.accidentId as string
      }`,
      {
        state: {
          basicInfo: accidentInfo,
        },
      },
    );
  };

  return (
    <TableCell
      colSpan={
        ((cells.length as number) +
          (renderChild ? 1 : 0) +
          (isManage ? 1 : 0)) as number
      }
      padding={'none'}>
      <CustomPaper
        isactive={isExpanded}
        elevation={(isExpanded && 4) || 0}
        style={{ margin: 0, padding: 0 }}>
        <PaperWrap>
          {cells.map((item, index_: number) =>
            item.name && data[item.name] !== undefined ? (
              <TableCell
                width={item.spacing}
                key={index_}
                sx={{
                  border: '0 !important',
                  borderBottom: '0 !important',
                  margin: 'auto',
                  minWidth: `${item.spacing.toString()}px` as string,
                  width: `${item.spacing.toString()}px` as string,
                }}>
                {typeof data[item.name] === 'object' ? (
                  <DataCell boldText={item.highlight} {...data[item.name]} />
                ) : (
                  <DataCell
                    boldText={item.highlight}
                    primaryText={data[item.name]}
                  />
                )}
              </TableCell>
            ) : (
              <TableCell
                width={item.spacing}
                key={index_}
                sx={{
                  border: '0 !important',
                  borderBottom: '0 !important',
                  margin: 'auto',
                  minWidth: `${item.spacing.toString()}px` as string,
                  width: `${item.spacing.toString()}px` as string,
                }}>
                -
              </TableCell>
            ),
          )}
          {isManage && (
            <TableCell
              width={80}
              sx={{
                border: '0 !important',
                borderBottom: '0 !important',
                margin: 'auto',
                minWidth: '80px',
                width: '80px',
              }}>
              <IconButton
                onClick={(event) => handleFleetOption(event, data.accidentId)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                className="popclass"
                open={tooltipIsOpen[data.accidentId]}
                onClose={(event) => handleFleetOption(event, data.accidentId)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'bottom',
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}>
                <Box
                  sx={{
                    backgroundColor: '#241A2E',
                    borderRadius: '8px',
                    boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                    minHeight: '70.9px',
                    padding: '0.5rem 0',
                    position: 'relative',
                    width: '207px',
                  }}>
                  <div className="arrow">.</div>
                  <Typography
                    component={'div'}
                    className={'n-pop-btn'}
                    onClick={() => handleMatchInfo(data.accidentId)}>
                    Match Info
                  </Typography>
                  <Typography
                    component={'div'}
                    className={'n-pop-btn'}
                    onClick={() => onNavigateToDataQChallenge(data)}>
                    Data Q Challenge
                  </Typography>
                  {data.primaryDriver.status === 'No match' &&
                    data.primaryDriver.primaryText && (
                      <Typography
                        component={'div'}
                        className={'n-pop-btn'}
                        onClick={() => {
                          navigate(
                            // eslint-disable-next-line
                            `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.NEWDRIVERSETUP}`,
                            {
                              state: {
                                details: {
                                  driverName: data.primaryDriver.primaryText,
                                  matchDriverType: 'primaryDriver',
                                },
                                from: 'accidents',
                                id: data.accidentId,
                              },
                            },
                          );
                        }}>
                        New Driver Onboarding
                      </Typography>
                    )}
                  {data.powerUnitVin.status === 'No match' &&
                    data.powerUnitVin.primaryText && (
                      <Typography
                        component={'div'}
                        className={'n-pop-btn'}
                        onClick={() =>
                          navigate(
                            // eslint-disable-next-line
                            `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ADDTOFLEET}`,
                            {
                              state: {
                                details: {
                                  equipmentType: 'power unit',
                                  vinNumber: data.powerUnitVin.primaryText,
                                },
                                from: 'accidents',
                                id: data.accidentId,
                              },
                            },
                          )
                        }>
                        Add to Fleet
                      </Typography>
                    )}
                </Box>
              </Popover>
            </TableCell>
          )}
          {renderChild && (
            <TableCell
              width={80}
              sx={{
                border: '0 !important',
                borderBottom: '0 !important',
                margin: 'auto',
                minWidth: '80px',
                width: '80px',
              }}>
              <IconButton
                size="large"
                onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableCell>
          )}
        </PaperWrap>
        {renderChild && (
          <Collapse in={isExpanded}>
            {isExpanded && renderChild(data.accidentId)}
          </Collapse>
        )}
      </CustomPaper>
    </TableCell>
  );
};

export default observer(Accident);
