import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  CompanyMenuItem: {
    // '&:hover': {
    //   '& div': {
    //     color: '#ffffff !important',
    //   },
    // },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    padding: '0em 1rem',
  },
  // '@global': {
  //   '*::-webkit-scrollbar': {
  //     width: '0em',
  //   },
  // },
  activeItem: {
    '& div': {
      color: '#ffffff !important',
    },

    background:
      'linear-gradient(90deg,#52442e 0%,#52442e 68.69%, #40332e 100%)',
    borderRadius: '8px 0 0 8px',
    // borderRadius: '8px 0 0 8px',
    // alignItems: 'center',
    // cursor: 'pointer',
    // display: 'flex',
    // height: '48px',
    // padding: '0em 1rem',
  },
  company: {
    // color: '#dec330',
    fontSize: '0.875rem',
    lineHeight: '17px',
  },
  companyActiveItem: {
    '& div': {
      color: '#241a2e !important',
    },
    background:
      'linear-gradient(90deg,#dec330 0%,#dec330 68.69%, rgba(222,195,48,0.3) 100%)',
    borderRadius: '8px 0 0 8px',
    boxShadow: '-1px 3px 2px rgb(0 0 0 / 11%)',
    // borderRadius: '8px 0 0 8px',
    // alignItems: 'center',
    // cursor: 'pointer',
    // display: 'flex',
    // height: '48px',
    // padding: '0em 1rem',
  },
  companyMenuText: {
    color: '#241a2e !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.9375rem',
    paddingLeft: '0.5rem',
  },
  companyPortal: {
    color: '#241a2e !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.625rem',
    letterSpacing: '3.33px',
    padding: ' 0.3rem 0 0 1.5rem',
    textTransform: 'uppercase',
  },
  logo: {
    padding: '0 0 0 1.5rem',
  },
  menuBox: {
    height: 'calc(100vh - 200px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: '0.3rem',
    paddingTop: '1rem',
  },
  menuItem: {
    '&:hover': {
      '& div': {
        color: '#ffffff !important',
      },
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    padding: '0em 1rem',
  },
  menuText: {
    color: '#979598',
    fontSize: '0.9375rem',
    fontWeight: '500',
    paddingLeft: '0.5rem',
  },
  name: {
    // color: '#FFFFFF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '1rem',
    lineHeight: '23px',
  },
  portal: {
    color: '#979598',
    fontSize: '0.625rem',
    fontWeight: '500',
    letterSpacing: '3.33px',
    padding: ' 0.3rem 0 0 1.5rem',
    textTransform: 'uppercase',
  },
  profileBox: {
    [theme.breakpoints.down('md')]: {
      position: 'unset',
    },
    alignItems: 'center',
    // borderTop: '1px solid #3E3548',
    bottom: '0',
    cursor: 'pointer',
    display: 'flex',
    left: '0',
    padding: '1rem 0 1rem 1.2rem',
    position: 'fixed',
    width: '245px',
    zIndex: '101',
  },
  profileImg: {
    transform: 'rotate(270deg)',
  },
  sidebar: {
    '& ::-webkit-scrollbar': {
      width: '0em',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '0',
      left: '0',
      top: '5px',
    },
    // backgroundColor: '#241a2e',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    // bottom: '0',
    height: '100vh',
    left: '0',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '1rem 0',
    position: 'fixed',
    scrollbarWidth: 'none',
    // top: '0',
    width: '245px',
    zIndex: '10',
  },
  w10: { width: '10%' },
  w90: { width: '90%' },
}));

export default useStyles;
