import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button, { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API_LIMIT, PrivateRoutes } from '../../config';
import { TopAppBarTitle } from '../../config/Constants';
import NavRoutes from '../../config/routes/NavRoutes';
import { useCustomStore } from '../../hooks';
import {
  AccessTypes,
  AdminNotificationSearchParam,
  DriverSearchFilters,
  FleetSearchFilters,
  TodoSearchFilters,
  UsersSearchFilters,
} from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import AdminDialog from '../AdminDialog/AdminDialog';
import AssignTrainingDialog from '../AssignTrainingDialog/AssignTrainingDialog';
import SearchFilter from '../SearchFilter/SearchFilter';
import eport from './../../assets/images/export.svg';
import filterIcon from './../../assets/images/filter.svg';
import search from './../../assets/images/h-search.svg';
import useStyles from './TopAppBarStyles';
import TrendInfo from './TrendInfo';

const screensWithExportBTN = [
  'Inspections & Violations',
  'Accidents',
  'Violations',
];

type AppBarTypes = {
  backButton: boolean;
  headerTitle?: string;
  globalSearch: boolean | any;
  globalFilter: boolean | any;
  documentsFilter?: boolean | any;
  showInDocumentStoreHeaderName?: boolean | any;
  accessType?: string;
  showTabs?: boolean;
};
const TopAppBar: React.FC<AppBarTypes> = (props: AppBarTypes) => {
  const classes = useStyles();
  const { accessType = '', showTabs = true } = props;
  const {
    authStore,
    accidentsStore,
    globalStore,
    driverStore,
    fleetStore,
    inspectionsAndViolationsStore,
    todoStore,
    adminUsersStore,
    notificationStore,
    documentsStore,
    violationsStore,
    trainingStore,
  } = useCustomStore();

  const { enqueueSnackbar } = useSnackbar();
  const {
    backButton,
    headerTitle,
    globalSearch,
    globalFilter,
    documentsFilter,
    showInDocumentStoreHeaderName,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showAssignTraining, setShowAssignTraining] = useState(false);
  const [disableTodosTabSwitch, setDisableTodosTabSwitch] = useState(false);
  const [disableDriversTabSwitch, setDisableDriversTabSwitch] = useState(false);
  const [disableFleetTabSwitch, setDisableFleetTabSwitch] = useState(false);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();

  const [driverFilter, setDriverFilters] = useState<DriverSearchFilters>({
    driverType: '',
    limit: API_LIMIT,
    name: '',
    nextLink: '',
    simplexId: '',
  });

  const [fleetFilter, setFleetFilters] = useState<FleetSearchFilters>({
    limit: API_LIMIT,
    nextLink: '',
    simplexId: '',
    unitNumber: '',
    unitType: '',
    vinNumber: '',
  });

  const [adminFilter, setAdminFilters] = useState<UsersSearchFilters>({
    limit: API_LIMIT,
    name: '',
    nextLink: '',
  });

  const [todoFilter, setTodoFilters] = useState<TodoSearchFilters>({
    categories: [],
    dueIn: '',
    firstName: '',
    lastName: '',
    limit: API_LIMIT,
    make: '',
    nextLink: '',
    simplexId: '',
    todoTrainingAssignedTo: '',
    todoTrainingReason: '',
    trainingAssignedTo: '',
    trainingReason: '',
    type: '',
    unitNumber: '',
    vinNumber: '',
    year: '',
  });

  const [notificationSearchParam, setNotificationSearchParam] =
    useState<AdminNotificationSearchParam>({
      description: '',
      // nextLink: '',
      title: '',
      type: '',
    });

  const handleClick = (event: any) => {
    if (documentsFilter) {
      documentsStore.setAnchorEl(event.currentTarget);
      documentsStore.setFilterShowOrHide(true);
      return;
    }
    setAnchorEl(event.currentTarget);
    globalStore.setMenu(true);
    if (headerTitle === 'Drivers') {
      setDriverFilters((pre) => ({
        ...pre,
        ...driverStore.Filters,
      }));
    }

    if (headerTitle === 'Fleet') {
      setFleetFilters((pre) => ({
        ...pre,
        ...fleetStore.Filters,
      }));
    }

    if (headerTitle === 'To-Dos') {
      setTodoFilters((pre) => ({
        ...pre,
        ...todoStore.Filters,
      }));
    }

    if (headerTitle === 'Notifications') {
      setNotificationSearchParam((pre) => ({
        ...pre,
        ...notificationStore.getNotificationHeaderParams,
      }));
    }

    if (headerTitle === 'Users') {
      setAdminFilters((pre) => ({
        ...pre,
        ...adminUsersStore.Filters,
      }));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    globalStore.setMenu(false);
  };

  const getDataWithStatus = useCallback(
    async (status) => {
      if (headerTitle === 'Drivers') {
        setDisableDriversTabSwitch(true);
        driverStore.setStatus(status);
        driverStore.setDriverApiReset(false);
        driverStore.setFilters({
          driverType: '',
          limit: API_LIMIT,
          name: '',
          nextLink: '',
          simplexId: '',
        });
        driverStore.setSortBy('');
        driverStore.setShowLoader(true);
        const getDriversResp = await driverStore.fetchDrivers();

        if (getDriversResp.isErr()) {
          enqueueSnackbar(
            String(getDriversResp.error.message),
            SnackBarConfig('e'),
          );
        }
        driverStore.setShowLoader(false);
        setDisableDriversTabSwitch(false);
      }

      if (headerTitle === 'Fleet') {
        setDisableFleetTabSwitch(true);
        fleetStore.setStatus(status);
        fleetStore.setFleetApiReset(false);
        fleetStore.setShowLoader(true);
        const getFleetsResp = await fleetStore.fetchFleets();
        if (getFleetsResp.isOk()) {
          fleetStore.setSortInfo(getFleetsResp.value?.sortInfo || '');
          fleetStore.setShowLoader(false);
        }
        if (getFleetsResp.isErr()) {
          enqueueSnackbar(
            String(getFleetsResp.error.message),
            SnackBarConfig('e'),
          );
        }
        fleetStore.setShowLoader(false);
        setDisableFleetTabSwitch(false);
      }

      if (headerTitle === 'To-Dos') {
        setDisableTodosTabSwitch(true);
        todoStore.setStatus(status);
        todoStore.setTodoApiReset(false);
        if (!backButton) {
          todoStore.setFilters({
            categories: [],
            dueIn: '',
            firstName: '',
            lastName: '',
            limit: API_LIMIT,
            make: '',
            nextLink: '',
            simplexId: '',
            todoTrainingAssignedTo: '',
            todoTrainingReason: '',
            trainingAssignedTo: '',
            trainingReason: '',
            type: '',
            unitNumber: '',
            vinNumber: '',
            year: '',
          });
        }
        todoStore.setShowLoader(true);
        const getTodosResp = await todoStore.fetchTodos();

        if (getTodosResp.isErr()) {
          enqueueSnackbar(
            String(getTodosResp.error.message),
            SnackBarConfig('e'),
          );
        }
        todoStore.setShowLoader(false);
        setDisableTodosTabSwitch(false);
      }

      if (headerTitle === 'Users') {
        adminUsersStore.setStatus(status);
        adminUsersStore.setAdminApiReset();
        adminUsersStore.setShowLoader(true);
        const getAdminsResp = await adminUsersStore.fetchAdmins();

        if (getAdminsResp.isErr()) {
          enqueueSnackbar(
            String(getAdminsResp.error.message),
            SnackBarConfig('e'),
          );
        }
        adminUsersStore.setShowLoader(false);
      }
      if (headerTitle === 'Inspections & Violations') {
        inspectionsAndViolationsStore.setStatus(status);
        inspectionsAndViolationsStore.resetParams();
        inspectionsAndViolationsStore.resetSearchParams();
        inspectionsAndViolationsStore.setShowLoader(true);
      }
      if (headerTitle === 'Accidents') {
        accidentsStore.setStatus(status);
        accidentsStore.resetParams();
        accidentsStore.resetSearchParams();
        accidentsStore.setShowLoader(true);
      }
    },
    [
      backButton,
      driverStore,
      todoStore,
      enqueueSnackbar,
      fleetStore,
      adminUsersStore,
      inspectionsAndViolationsStore,
      headerTitle,
      accidentsStore,
    ],
  );

  const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    '&.Mui-disabled': {
      backgroundColor: '#241A2E !important',
    },
    '&:hover': {
      backgroundColor: '#241A2E',
    },
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    color: theme.palette.getContrastText('#241A2E'),
    height: '48px',
  }));

  const exportFileAsData = async () => {
    if (headerTitle === 'Fleet') {
      if (fleetStore.FleetList.length) {
        const params: any = {};
        fleetStore.Filters.vinNumber != ''
          ? (params['vinNumber'] = fleetStore.Filters.vinNumber)
          : null;
        fleetStore.Filters.unitNumber != ''
          ? (params['unitNumber'] = fleetStore.Filters.unitNumber)
          : null;
        fleetStore.Filters.simplexId != ''
          ? (params['simplexId'] = fleetStore.Filters.simplexId)
          : null;
        fleetStore.Filters.unitType != ''
          ? (params['unitType'] = fleetStore.Filters.unitType)
          : null;

        const getResp: any = await fleetStore.exportFleets(params);
        if (getResp.isOk()) {
          saveAs(
            getResp.value,
            `fleets_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`,
          );
        } else {
          if (getResp.isErr()) {
            enqueueSnackbar('No fleets found', SnackBarConfig('e'));
          }
        }
      } else {
        enqueueSnackbar('No fleets to export', SnackBarConfig('w'));
      }
    }
    if (headerTitle === 'Drivers') {
      if (driverStore.DriverList.length) {
        const params: any = {};
        driverStore.Filters.name != ''
          ? (params['name'] = driverStore.Filters.name)
          : null;
        driverStore.Filters.simplexId != ''
          ? (params['simplexId'] = driverStore.Filters.simplexId)
          : null;
        driverStore.Filters.driverType != ''
          ? (params['driverType'] = driverStore.Filters.driverType)
          : null;

        const getResp: any = await driverStore.exportDrivers(params);
        if (getResp.isOk()) {
          saveAs(
            getResp.value,
            `drivers_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`,
          );
        } else {
          if (getResp.isErr()) {
            enqueueSnackbar('No drivers found', SnackBarConfig('e'));
          }
        }
      } else {
        enqueueSnackbar('No drivers to export', SnackBarConfig('w'));
      }
    }
    if (headerTitle === 'To-Dos') {
      if (todoStore.TodoList.length) {
        const params: any = {};
        todoStore.Filters.firstName != ''
          ? (params['firstName'] = todoStore.Filters.firstName)
          : null;
        todoStore.Filters.lastName != ''
          ? (params['lastName'] = todoStore.Filters.lastName)
          : null;
        todoStore.Filters.simplexId != ''
          ? (params['simplexId'] = todoStore.Filters.simplexId)
          : null;
        todoStore.Filters.year != ''
          ? (params['year'] = todoStore.Filters.year)
          : null;
        todoStore.Filters.make != ''
          ? (params['make'] = todoStore.Filters.make)
          : null;
        todoStore.Filters.vinNumber != ''
          ? (params['vinNumber'] = todoStore.Filters.vinNumber)
          : null;
        todoStore.Filters.unitNumber != ''
          ? (params['unitNumber'] = todoStore.Filters.unitNumber)
          : null;
        todoStore.Filters.dueIn != ''
          ? (params['dueIn'] = todoStore.Filters.dueIn)
          : null;
        todoStore.Filters.type != ''
          ? (params['type'] = todoStore.Filters.type)
          : null;

        const getResp: any = await todoStore.exportTodos(params);
        if (getResp.isOk()) {
          saveAs(
            getResp.value,
            `todos_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`,
          );
        } else {
          if (getResp.isErr()) {
            enqueueSnackbar('No todos found', SnackBarConfig('e'));
          }
        }
      } else {
        enqueueSnackbar('No todos to export', SnackBarConfig('w'));
      }
    }

    if (headerTitle === 'Inspections & Violations') {
      if (inspectionsAndViolationsStore.Data.length) {
        const getResp: any = await inspectionsAndViolationsStore.exportList();
        if (getResp.isOk()) {
          saveAs(
            getResp.value,
            `inspections_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`,
          );
        } else {
          if (getResp.isErr()) {
            enqueueSnackbar('No Inspections found', SnackBarConfig('e'));
          }
        }
      } else {
        enqueueSnackbar('No Inspections to export', SnackBarConfig('w'));
      }
    }
    if (headerTitle === 'Violations') {
      if (violationsStore.Data.length) {
        const getResp: any = await violationsStore.exportList();
        if (getResp.isOk()) {
          saveAs(
            getResp.value,
            `violations_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`,
          );
        } else {
          if (getResp.isErr()) {
            enqueueSnackbar('No Violations found', SnackBarConfig('e'));
          }
        }
      } else {
        enqueueSnackbar('No Violations to export', SnackBarConfig('w'));
      }
    }
    if (headerTitle === 'Accidents') {
      if (accidentsStore.Data.length) {
        const getResp: any = await accidentsStore.exportList();
        if (getResp.isOk()) {
          saveAs(
            getResp.value,
            `accidents_${moment().format('YYYY-MM-DD hh:mm')}.xlsx`,
          );
        } else {
          if (getResp.isErr()) {
            enqueueSnackbar('No Accidents found', SnackBarConfig('e'));
          }
        }
      } else {
        enqueueSnackbar('No Accidents to export', SnackBarConfig('w'));
      }
    }
  };

  const filterDesider = () => {
    if (headerTitle === 'Drivers') {
      return driverFilter;
    }
    if (headerTitle === 'Fleet') {
      return fleetFilter;
    }
    if (headerTitle === 'Notifications') {
      return notificationSearchParam;
    }
    if (headerTitle === 'Users') {
      return adminFilter;
    }

    return todoFilter;
  };

  const setFilterDesider = () => {
    if (headerTitle === 'Drivers') {
      return setDriverFilters;
    }
    if (headerTitle === 'Fleet') {
      return setFleetFilters;
    }

    if (headerTitle === 'Notifications') {
      return setNotificationSearchParam;
    }

    if (headerTitle === 'Users') {
      return setAdminFilters;
    }

    return setTodoFilters;
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={classes.header}>
        <div className="flex-row">
          {backButton && (
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <div className={classes.hTitle}>
            {showInDocumentStoreHeaderName
              ? documentsStore.HeaderName
              : headerTitle === 'ToDos'
              ? 'To-Dos'
              : headerTitle}
          </div>
          {backButton &&
            !headerTitle?.includes(TopAppBarTitle.DATA_Q_CHALLENGE) && (
              <TrendInfo />
            )}
        </div>
        {headerTitle === 'Users' && (
          <Typography component={'div'} className={classes.dflexJA}>
            <Typography
              component={'div'}
              className={
                adminUsersStore.Status === 'Users'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              onClick={() => getDataWithStatus('Users')}>
              {'Users'}
            </Typography>
            <Typography
              component={'div'}
              className={
                adminUsersStore.Status === 'Roles'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
              onClick={() => getDataWithStatus('Roles')}>
              {'Roles'}
            </Typography>
          </Typography>
        )}

        {headerTitle === 'To-Dos' && (
          <Typography component={'div'} className={classes.dflexJA}>
            {/* {authStore.UserAccessType === AccessTypes.CompanyAdmin && ( */}
            <Typography
              component={'div'}
              className={
                todoStore.Status === 'Open' ? classes.Adriver : classes.Ndriver
              }
              style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
              onClick={() => {
                if (
                  todoStore.Status === 'Open' ||
                  (todoStore.Status === 'Completed' && disableTodosTabSwitch)
                ) {
                  return;
                } else {
                  getDataWithStatus('Open');
                }
              }}>
              Active
            </Typography>
            {/* )} */}
            <Typography
              component={'div'}
              className={
                todoStore.Status === 'Completed'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              onClick={() => {
                if (
                  todoStore.Status === 'Completed' ||
                  (todoStore.Status === 'Open' && disableTodosTabSwitch)
                ) {
                  return;
                } else {
                  getDataWithStatus('Completed');
                }
              }}>
              Archived
            </Typography>
          </Typography>
        )}

        {headerTitle === 'Drivers' && (
          <Typography component={'div'} className={classes.dflexJA}>
            {/* {authStore.UserAccessType === AccessTypes.CompanyAdmin && ( */}
            <Typography
              component={'div'}
              className={
                driverStore.Status === 'Active'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
              onClick={() => {
                if (
                  driverStore.Status === 'Active' ||
                  (driverStore.Status === 'Terminated' &&
                    disableDriversTabSwitch)
                ) {
                  return;
                } else {
                  getDataWithStatus('Active');
                }
              }}>
              Active
            </Typography>
            {/* )} */}
            <Typography
              component={'div'}
              className={
                driverStore.Status === 'Terminated'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              onClick={() => {
                if (
                  driverStore.Status === 'Terminated' ||
                  (driverStore.Status === 'Active' && disableDriversTabSwitch)
                ) {
                  return;
                } else {
                  getDataWithStatus('Terminated');
                }
              }}>
              Terminated
            </Typography>
          </Typography>
        )}

        {headerTitle === 'Fleet' && (
          <Typography component={'div'} className={classes.dflexJA}>
            {/* {authStore.UserAccessType === AccessTypes.CompanyAdmin && ( */}
            <Typography
              component={'div'}
              className={
                fleetStore.Status === 'Active'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
              onClick={() => {
                if (
                  fleetStore.Status === 'Active' ||
                  (fleetStore.Status === 'Terminated' && disableFleetTabSwitch)
                ) {
                  return;
                } else {
                  getDataWithStatus('Active');
                }
              }}>
              Active
            </Typography>
            {/* )} */}
            <Typography
              component={'div'}
              className={
                fleetStore.Status === 'Terminated'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              onClick={() => {
                if (
                  fleetStore.Status === 'Terminated' ||
                  (fleetStore.Status === 'Active' && disableFleetTabSwitch)
                ) {
                  return;
                } else {
                  getDataWithStatus('Terminated');
                }
              }}>
              Terminated
            </Typography>
          </Typography>
        )}

        {headerTitle === 'Dashboard' && (
          <Typography component={'div'} className={classes.dflexJA}>
            <Typography
              component={'div'}
              className={
                (location.pathname === '/dashboard/highlights' &&
                  classes.Adriver) ||
                classes.Ndriver
              }
              onClick={() => navigate('./highlights')}>
              HIGHLIGHTS
            </Typography>
            {((accessType === 'companyAdmin' &&
              authStore?.NavbarAccess?.trends) ||
              authStore?.NavbarAccess?.trends) && (
              <Typography
                component={'div'}
                className={
                  (location.pathname === '/dashboard/trends' &&
                    classes.Adriver) ||
                  classes.Ndriver
                }
                style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
                onClick={() => {
                  navigate('./trends');
                }}>
                TRENDS
              </Typography>
            )}
          </Typography>
        )}

        {headerTitle === 'Training' && (
          <Typography component={'div'} className={classes.dflexJA}>
            {authStore.UserAccessType === AccessTypes.CompanyAdmin &&
              authStore.UserRoleAcess !== 'Driver' &&
              authStore.UserRoleAcess !== 'Owner Operator' && (
                <Typography
                  component={'div'}
                  className={
                    location.pathname.includes(
                      `${
                        PrivateRoutes.TRAINING.RECOMMENDED.BASE_ROUTE as string
                      }`,
                    )
                      ? classes.Adriver
                      : classes.Ndriver
                  }
                  style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
                  onClick={() => {
                    navigate(
                      `./${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.BASE_ROUTE}`,
                    );
                  }}>
                  RECOMMENDED
                </Typography>
              )}
            <Typography
              component={'div'}
              className={
                (location.pathname ===
                  `${PrivateRoutes.TRAINING.ASSIGNED as string}` &&
                  classes.Adriver) ||
                classes.Ndriver
              }
              onClick={() => {
                trainingStore.resetTrainingAssignedFilters();
                navigate(`./${NavRoutes.PRIVATE.TRAINING.ASSIGNED}`);
              }}>
              ASSIGNED
            </Typography>
            <Typography
              component={'div'}
              className={
                (location.pathname ===
                  `${PrivateRoutes.TRAINING.COMPLETED as string}` &&
                  classes.Adriver) ||
                classes.Ndriver
              }
              style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
              onClick={() => {
                trainingStore.resetTrainingCompletedFilters();
                navigate(`./${NavRoutes.PRIVATE.TRAINING.COMPLETED}`);
              }}>
              COMPLETED
            </Typography>
          </Typography>
        )}

        {headerTitle === 'Inspections & Violations' && showTabs && (
          <Typography component={'div'} className={classes.dflexJA}>
            {/* {authStore.UserAccessType === AccessTypes.CompanyAdmin && ( */}
            <Typography
              component={'div'}
              className={
                inspectionsAndViolationsStore.Status === 'All'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
              onClick={() => {
                if (inspectionsAndViolationsStore.Status === 'All') {
                  return;
                } else {
                  getDataWithStatus('All');
                }
              }}>
              ALL
            </Typography>
            {/* )} */}
            <Typography
              component={'div'}
              className={
                inspectionsAndViolationsStore.Status === 'Manage'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              onClick={() => {
                if (inspectionsAndViolationsStore.Status === 'Manage') {
                  return;
                } else {
                  getDataWithStatus('Manage');
                }
              }}>
              MANAGE
            </Typography>
          </Typography>
        )}

        {headerTitle === 'Accidents' && showTabs && (
          <Typography component={'div'} className={classes.dflexJA}>
            {authStore.UserAccessType === AccessTypes.CompanyAdmin && (
              <Typography
                component={'div'}
                className={
                  accidentsStore.Status === 'All'
                    ? classes.Adriver
                    : classes.Ndriver
                }
                style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
                onClick={() => {
                  if (accidentsStore.Status === 'All') {
                    return;
                  } else {
                    getDataWithStatus('All');
                  }
                }}>
                ALL
              </Typography>
            )}
            <Typography
              component={'div'}
              className={
                accidentsStore.Status === 'Manage'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              onClick={() => {
                if (accidentsStore.Status === 'Manage') {
                  return;
                } else {
                  getDataWithStatus('Manage');
                }
              }}>
              MANAGE
            </Typography>
          </Typography>
        )}

        <Typography component={'div'} gutterBottom className={classes.dflexA}>
          {globalSearch && adminUsersStore.Status !== 'Roles' && (
            <Typography
              component={'div'}
              className={classes.hIcon}
              aria-controls="true"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={(event: any) => handleClick(event)}>
              <img className={classes.hIcon} src={search} alt="search" />
            </Typography>
          )}
          {globalFilter && (
            <Typography
              className={classes.fIcon}
              aria-controls="true"
              aria-haspopup="true"
              aria-expanded="true"
              onClick={(event: any) => handleClick(event)}>
              <img className={classes.ficonz} src={filterIcon} alt="filter" />
            </Typography>
          )}
          {headerTitle === 'Users' &&
            adminUsersStore.Status === 'Users' &&
            authStore.UserRoleAcess === 'Super Admin' && (
              <Typography component={'div'} style={{ padding: '1.5rem' }}>
                <Typography
                  component={'div'}
                  className={classes.createBtn.concat(
                    authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                      ? ' disable headerAddButtonDisable'
                      : '',
                  )}
                  {...(authStore.getTokenDetail &&
                  authStore.getTokenDetail?.adminId
                    ? {}
                    : { onClick: () => setIsOpen(true) })}>
                  Add User
                </Typography>
              </Typography>
            )}
          {headerTitle === 'Training' &&
            authStore.UserAccessType === AccessTypes.CompanyAdmin &&
            authStore.UserRoleAcess !== 'Driver' &&
            authStore.UserRoleAcess !== 'Owner Operator' &&
            (location.pathname.includes(
              `${PrivateRoutes.TRAINING.RECOMMENDED.BASE_ROUTE as string}`,
            ) ||
              location.pathname ===
                `${PrivateRoutes.TRAINING.ASSIGNED as string}`) && (
              <Typography
                component={'div'}
                style={{ padding: '1.5rem', textAlign: 'center' }}>
                <Typography
                  component={'div'}
                  className={classes.createBtn.concat(
                    authStore.IsImpersonatingMode
                      ? ' disable headerAddButtonDisable'
                      : '',
                  )}
                  {...(authStore.IsImpersonatingMode
                    ? {}
                    : { onClick: () => setShowAssignTraining(true) })}
                  sx={{ width: '191px' }}>
                  ASSIGN NEW TRAINING
                </Typography>
              </Typography>
            )}
          {['To-Dos', 'Drivers', 'Fleet', ...screensWithExportBTN].includes(
            String(headerTitle),
          ) && (
            <DarkColorButton
              style={{ marginLeft: '1rem' }}
              type="button"
              // disabled={
              //   headerTitle === 'Drivers'
              //     ? !driverStore.DriverList.length
              //     : headerTitle === 'Fleet'
              //     ? !fleetStore.FleetList.length
              //     : headerTitle === 'To-Dos'
              //     ? !todoStore.TodoList.length
              //     : false
              // }
              variant="contained"
              onClick={exportFileAsData}>
              <img src={eport} alt="export" />
              <Typography
                component={'div'}
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'FiraSans-Medium',
                  fontSize: '15px',
                  fontWeight: '500',
                  height: '22px',
                  letterSpacing: '1px',
                  lineHeight: '22px',
                  paddingLeft: '0.2rem',
                  textAlign: 'center',
                }}>
                EXPORT
              </Typography>
            </DarkColorButton>
          )}

          <SearchFilter
            {...{
              anchorEl,
              filter: filterDesider(),
              handleClose,
              open,
              searchPage: headerTitle,
              setFilters: setFilterDesider(),
            }}
          />
        </Typography>
      </div>
      <Grid>
        {isOpen && <AdminDialog isOpen={isOpen} closeDialog={closeDialog} />}
        {showAssignTraining && (
          <AssignTrainingDialog
            isOpen={showAssignTraining}
            closeDialog={() => {
              setShowAssignTraining(false);
            }}
          />
        )}
      </Grid>
    </>
  );
};

export default observer(TopAppBar);
