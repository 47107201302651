import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import {
  AdminNotificationSearchParam,
  DisplayBoardSearchParam,
} from '../../types';
import { RootStore } from '..';

export class NotificationApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  getInitialData(
    params: AdminNotificationSearchParam | DisplayBoardSearchParam,
  ) {
    const {
      Get: { Endpoint, Method },
    } = ApiRoutes.Notification;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: { ...params },
      url: Endpoint,
    });
  }
}
