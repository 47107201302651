import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  activeDriversInfo: {
    '& .drivers-count': {
      cursor: 'pointer',
    },
    '& .icon-info-container': {
      '& .icon': {
        marginRight: '1rem',
      },
      '& .power-unit-count-container': {
        '& .title': {
          wordBreak: 'break-all',
        },
        display: 'flex',
        flexDirection: 'column',
        width: '105px',
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
    },
    '& .no-wrap': {
      whiteSpace: 'nowrap',
    },
    '& .separator': {
      height: '4rem',
      padding: '0 1rem',
      width: '3rem',
    },
    '& .title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1.15rem',
    },
    '& .value': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
    },
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%',
  },
  basicCategoryChart: {
    '& .header': {
      '& .btn-group': {
        '& .active-btn': {
          backgroundColor: '#FFFFFF',
          color: '#241A2E',
        },
        '& .left-btn': {
          borderBottomLeftRadius: '0.4rem',
          borderRightWidth: '0',
          borderTopLeftRadius: '0.4rem',
        },
        '& .right-btn': {
          borderBottomRightRadius: '0.4rem',
          borderTopRightRadius: '0.4rem',
        },
        '& button': {
          backgroundColor: '#FFFFFF',
          border: '1px solid #d1d1d1',
          borderRadius: '0',
          color: '#b1b4be',
          cursor: 'pointer',
          fontFamily: 'FiraSans-Regular',
          fontSize: '0.8rem',
          padding: '0.4rem',
        },
      },
      '& .title': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Medium',
        fontSize: '1.1rem',
      },
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '1.2rem',
    },
  },
  card: {
    backgroundColor: '#FFF',
    borderRadius: 3,
    boxShadow: '0 4px 12px 0 rgba(0,0,0,0.06)',
    height: '100%',
    padding: '1.2rem',
  },
  highlightsDashboard: {
    padding: '3rem',
    paddingTop: '2rem',
    width: '100%',
  },
  inspectionsInfo: {
    '& .label': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1.3rem',
    },
    '& .last-updated': {
      '&.mt': {
        marginTop: '0.8rem',
      },
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
      fontStyle: 'italic',
      opacity: '70%',
    },
    '& .value': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: '3rem',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '1.2rem',
  },
  issScoreInfo: {
    '& .label': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1.5rem',
    },
    '& .last-updated': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
      fontStyle: 'italic',
      opacity: '70%',
    },
    '& .value': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: '3rem',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '1.2rem',
  },
  todoInfo: {
    '& .header': {
      '& .link': {
        color: '#0061ff',
        cursor: 'pointer',
        fontFamily: 'FiraSans-Bold',
        fontSize: '0.8rem',
        textDecoration: 'underline',
      },
      '& .title': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Medium',
        fontSize: '1.1rem',
      },
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1.2rem',
      paddingBottom: '0',
    },
  },
  updatesInfo: {
    '& .header': {
      '& img': {
        margin: '-0.5rem 0 0 -0.5rem',
      },
      alignItems: 'center',
      display: 'flex',
    },
    '& .note': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.65rem',
      fontStyle: 'italic',
      opacity: '70%',
    },
    '& .sub-title': {
      '& .date': {
        opacity: '75%',
      },
      '& .label': {
        opacity: '60%',
      },
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
      fontStyle: 'italic',
      lineHeight: '2rem',
    },
    '& .title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1.15rem',
    },
    '& .updates-list-item': {
      '& .label': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Medium',
        fontSize: '0.9rem',
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      margin: ' 1rem 0',
      marginRight: '1rem',
    },
  },
}));

export default useStyles;
