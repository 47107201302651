import React from 'react';

const InspectionTooltip: React.FC = () => {
  return (
    <div style={{ padding: '5px 0' }}>
      <h4 style={{ textAlign: 'center' }}>General Recommendation</h4>
      <br />
      <p>
        Commercial drivers while in operation of a CMV, must at all time operate
        such CMV in a safely manner complying with federal and state
        regulations. Motor carriers have the responsibility to periodically
        review driver’s qualifications and skills to operate the CMV to which
        he/she is assign to operate.
      </p>
      <p /> It’s highly recommended for all motor carriers to periodically:
      <ol>
        <li>
          Review MVRs with the purpose of identifying driver’s violations, while
          conducting CMVs or private auto. Checking an MVR is crucial to
          determine driver’s qualifications, safety behaviors, driving skills
          and such.
        </li>
        <li>
          Conduct safety trainings, safety-oriented talks, safety reviews with
          its commercial drivers. These training should be guided towards the
          identification of human and or engineering risk that could conduct to
          an accident and/loss. It is the carrier’s responsibility to conduct
          these training sessions, to maintain its drivers engage in a
          compliance safety culture.
        </li>
        <li>
          When a driver is found in a moving violation, identify after a
          Roadside Inspection or after a review of an MVR must be consulted by
          the motor carrier, who is responsible to discussing the occurrence,
          and for taking all necessary steps with driver to correct cause of
          violation.
        </li>
      </ol>
      Please contact your Simplex assigned Service Account Manager to obtain
      additional information, and loss control aids to implement and/or enhance
      this safety aspect of your compliance program.
    </div>
  );
};
export default InspectionTooltip;
