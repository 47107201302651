import { makeAutoObservable } from 'mobx';

import { API_LIMIT } from '../config';
import { DocumentsListReponseDTO } from '../types';
import { RootStore } from '.';

export class DocumentsStore {
  private _showFilter = false;
  private _anchorEl: any | null = null;
  private _headerName: string | null = null;
  private _documentsList: Array<DocumentsListReponseDTO> | null = null;
  private _hasMoreResults = true;
  // private _nextLink = '';
  private _count = 0;
  private _filter: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
    tags: Array<any>;
    fileName: string;
  } = {
    fileName: '',
    limit: API_LIMIT,
    nextLink: '',
    sortBy: 'fileName',
    sortDirection: 'asc',
    tags: [],
  };
  private _apiCallType = {
    docType: '',
    id: '',
  };
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setFilterShowOrHide(show: boolean) {
    this._showFilter = show;
  }
  setAnchorEl(e: any) {
    this._anchorEl = e;
  }
  setHeaderName(name: string | null) {
    this._headerName = name ? name + ' Documents' : '';
  }
  setDocumentReponse(data: Array<DocumentsListReponseDTO> | null) {
    this._documentsList = data;
  }
  resetDocumentsList() {
    this._documentsList = null;
    this.setTotalCount(0);
    this.setHasMoreResults(false);
  }
  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }
  setNextLink(link: string) {
    this._filter = { ...this._filter, nextLink: link };
  }
  setFilters(data: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
    tags: Array<any>;
    fileName: string;
  }) {
    this._filter = data;
  }
  setTotalCount(count: number) {
    this._count = count;
  }
  resetFilters() {
    this.setFilters({ ...this._filter, fileName: '', nextLink: '', tags: [] });
    this.setHasMoreResults(false);
  }
  setApiCallType(obj: any) {
    this._apiCallType = obj;
  }

  get ShowFilter() {
    return this._showFilter;
  }
  get AnchorEl() {
    return this._anchorEl;
  }
  get HeaderName() {
    return this._headerName;
  }
  get DocumentsList() {
    return this._documentsList;
  }
  // get NextLink() {
  //   return this._nextLink;
  // }
  get HasMoreResults() {
    return this._hasMoreResults;
  }
  get Filters() {
    return this._filter;
  }
  get DocumentsTotalCount() {
    return this._count;
  }
  get ApiCallType() {
    return this._apiCallType;
  }
  async getDocuments(param: { docType: string; id: string }) {
    if (param.docType === 'driver') {
      return this.getDriverDocuments(param.id);
    } else if (param.docType === 'unit') {
      return this.getUnitDocuments(param.id);
    } else {
      return this.getAdminDocuments();
    }
  }
  async getUnitDocuments(id: string) {
    const response =
      await this.rootStore.apiStore.documentsApiStore.getUnitDocumentsList(
        this.Filters,
        id,
      );
    if (response.isOk()) {
      this.mappingResponse(response);
    }
    return response;
  }
  async getDriverDocuments(id: string) {
    const response =
      await this.rootStore.apiStore.documentsApiStore.getDriverDocumentsList(
        this.Filters,
        id,
      );
    if (response.isOk()) {
      this.mappingResponse(response);
    }
    return response;
  }
  async getAdminDocuments() {
    const response =
      await this.rootStore.apiStore.documentsApiStore.getAdminDocumentsList(
        this.Filters,
      );
    if (response.isOk()) {
      this.mappingResponse(response);
    }
    return response;
  }

  async downloadDocument(id: string) {
    const response =
      await this.rootStore.apiStore.documentsApiStore.downloadDocument({
        id,
      });
    return response;
  }
  mappingResponse(response: any) {
    const { nextLink, response: result, count } = response.value;
    if (!this.Filters.nextLink) {
      this.setDocumentReponse(result);
    } else {
      const totalResponse =
        this.DocumentsList && this.DocumentsList.concat(result);
      this.setDocumentReponse(totalResponse);
    }
    this.setTotalCount(count);
    this.setNextLink(nextLink);
    this.setHasMoreResults(nextLink ? true : false);
  }
}
