import { TableContainer } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import { observer } from 'mobx-react-lite';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DocumentsListReponseDTO } from '../../../types';
import TableBodyContent from './Table/body';
import TableHeader from './Table/header';

type DocumentListProps = {
  records: Array<DocumentsListReponseDTO> | null;
  HasMoreResults: boolean | any;
  getMoreLoader: boolean | any;
  getMoreList: () => void;
  sortBY: (src: any) => void;
  storeFilter: any;
  enqueueSnackbar: any;
};

const DocumentsList: React.FC<DocumentListProps> = (
  props: DocumentListProps,
) => {
  const {
    enqueueSnackbar,
    records,
    HasMoreResults,
    getMoreList,
    getMoreLoader,
    sortBY,
    storeFilter,
  } = props;
  return (
    <TableContainer sx={{ maxHeight: '82vh' }} id="documents-table">
      <InfiniteScroll
        dataLength={records && records.length ? records.length : 0}
        style={{
          overflow: 'unset',
        }}
        next={getMoreList}
        hasMore={HasMoreResults}
        scrollableTarget={'documents-table'}
        loader={
          getMoreLoader && (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <CircularProgress
                size={30}
                sx={{ color: '#DEC330', marginBottom: 5, marginTop: 5 }}
              />
            </div>
          )
        }
        endMessage>
        <Table
          sx={{ marginTop: '1rem !important', minWidth: 650 }}
          stickyHeader
          aria-label="sticky table">
          <TableHeader {...{ sortBY, storeFilter }} />
          <TableBodyContent
            {...{
              HasMoreResults,
              enqueueSnackbar,
              getMoreList,
              getMoreLoader,
              records,
            }}
          />
        </Table>
      </InfiniteScroll>
    </TableContainer>
  );
};
export default observer(DocumentsList);
