import { Button, Paper, TableCell, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@mui/system/styled';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useCustomStore } from '../../../hooks';

const useStyles = makeStyles(() => ({
  companyBg: {
    alignItems: 'center',
    backgroundColor: '#E8932E',
    borderRadius: '17px',
    color: '#FFFFFF !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '13px !important',
    justifyContent: 'center',
    letterSpacing: '0.88px !important',
    lineHeight: '17px',
    padding: '5px 10px',
    textTransform: 'uppercase',
  },
  danger: {
    borderLeft: '8px solid #A70D0D',
  },
  dangerdot: {
    backgroundColor: '#A70D0D',
    borderRadius: '50%',
    height: '12px',
    marginRight: '.5rem !important',
    width: '12px',
  },

  driverBg: {
    alignItems: 'center',
    backgroundColor: '#2E1633',
    borderRadius: '17px',
    color: '#FFFFFF !important',

    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '13px !important',
    justifyContent: 'center',
    letterSpacing: '0.88px !important',
    lineHeight: '17px',
    padding: '5px 10px',
    textTransform: 'uppercase',
  },
  fleetBg: {
    alignItems: 'center',
    backgroundColor: '#0DA728',
    borderRadius: '17px',
    color: '#FFFFFF !important',

    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '13px !important',
    justifyContent: 'center',
    letterSpacing: '0.88px !important',
    lineHeight: '17px',
    padding: '5px 10px',
    textTransform: 'uppercase',
  },
  normal: {
    borderLeft: '8px solid #E8932E',
  },
  normaldot: {
    backgroundColor: '#E8932E',
    borderRadius: '50%',
    height: '12px',
    marginRight: '.5rem !important',
    width: '12px',
  },

  success: {
    borderLeft: '8px solid #DEC330',
  },
  successdot: {
    backgroundColor: '#DEC330',
    borderRadius: '50%',
    height: '12px',
    marginRight: '.5rem !important',
    width: '12px',
  },
}));

const CustomPaper = styled(Paper)<{ isactive?: boolean }>(({ isactive }) => ({
  ...((isactive && { backgroundColor: '#F1F4F9 !important' }) || {}),
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 10px',
}));

const DriverName = styled('h4')(() => ({
  fontSize: 14,
  margin: '5px 0',
}));

const Label = styled('p')<{ hasBorder?: boolean }>(({ hasBorder = false }) => ({
  borderRight: hasBorder ? '1px solid #b3a9a9' : '',
  fontSize: 13,
  margin: '5px 0',
  marginRight: hasBorder ? 15 : 0,
  paddingRight: hasBorder ? 30 : 15,
}));

const PaperWrap = styled('div')(() => ({
  display: 'flex',
}));

// const PaperItem = styled('div')<{ width: number; center?: boolean }>(
//   ({ width, center }) => ({
//     alignItems: 'center',
//     display: 'flex',
//     justifyContent: center ? 'center' : 'flex-start',
//     marginLeft: 5,
//     marginRight: 5,
//     width,
//   }),
// );

const ButtonX = styled(Button)(() => ({
  borderColor: '#e5e1e1',
  color: '#000',
  fontWeight: 'bolder',
}));

const Status = styled('span')<{ status: string }>(({ status }) => ({
  color: status === 'Incomplete' ? '#a70d0d' : '#000',
}));

// const Details = styled('div')(() => ({
//   marginTop: 10,
// }));

const DetailsItem = styled('div')(() => ({
  marginTop: 5,
}));

type Props = {
  data: TrendTodo;
  onClickView: (id: string) => unknown;
};

const GridActionButton: React.FC<{
  text: string;
  onClick: () => unknown;
}> = ({ text = '', onClick }) => {
  return (
    <ButtonX onClick={onClick} variant="outlined">
      {text}
    </ButtonX>
  );
};

const TodoCard: React.FC<Props> = ({ data, onClickView }) => {
  const {
    todoStore: { getTrainingTodoById },
  } = useCustomStore();
  const [details, setDetails] = useState<TodoDetails>();
  const classes = useStyles();

  useEffect(() => {
    if (data.todoCategoryType === 'Training' && !details) {
      (async () => {
        const [res, err] = await getTrainingTodoById(data.id);
        if (!err && res) {
          setDetails(res);
        }
      })();
    }
  }, [data, details, getTrainingTodoById]);

  return (
    <TableCell colSpan={5} padding={'none'}>
      <CustomPaper
        sx={{
          padding: '0 !important',
        }}
        elevation={0}
        isactive={false}
        className={
          data.duein >= 0 && data.duein <= 10
            ? classes.danger
            : data.duein > 10 && data.duein <= 30
            ? classes.normal
            : data.duein > 30
            ? classes.success
            : classes.danger
        }>
        <PaperWrap>
          <TableCell
            sx={{
              border: '0 !important',
              borderBottom: '0 !important',
              margin: 'auto',
              minWidth: '480px',
            }}
            width={480}>
            <div className="flex-column">
              <div className="flex-row">
                <DriverName>{data.text || '-'}</DriverName>
              </div>
              <div className="flex-row">
                <Label>
                  <b>Driver Name:</b>
                </Label>
                <Label hasBorder>{data?.driver?.fullname || '-'}</Label>
                <Label>
                  <b>Simplex Id:</b>
                </Label>
                <Label>{data?.driver?.simplexId || '-'}</Label>
              </div>
            </div>
          </TableCell>

          <TableCell
            width={100}
            sx={{
              border: '0 !important',
              borderBottom: '0 !important',
              margin: 'auto',
              minWidth: '100px',
            }}>
            <Typography
              noWrap
              className={
                data.category === 'Fleet'
                  ? classes.fleetBg
                  : data.category === 'Driver'
                  ? classes.driverBg
                  : classes.companyBg
              }>
              {data.category}
            </Typography>
          </TableCell>
          <TableCell
            width={100}
            sx={{
              border: '0 !important',
              borderBottom: '0 !important',
              margin: 'auto',
              minWidth: '100px',
            }}>
            <Label>
              {data.expirationDate
                ? moment(data.expirationDate).format('MM/DD/YYYY')
                : '-'}
            </Label>
          </TableCell>
          <TableCell
            width={150}
            sx={{
              border: '0 !important',
              borderBottom: '0 !important',
              margin: 'auto',
              minWidth: '150px',
            }}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              {data.duein >= 0 && data.duein <= 10 ? (
                <Typography className={classes.dangerdot} />
              ) : data.duein > 10 && data.duein <= 30 ? (
                <Typography className={classes.normaldot} />
              ) : data.duein > 30 ? (
                <Typography className={classes.successdot} />
              ) : (
                <Typography className={classes.dangerdot} />
              )}
              <Label>
                {typeof data.duein === 'string'
                  ? data.duein
                  : String(data.duein) === '0'
                  ? 'Today'
                  : String(data.duein).concat(' Days')}
              </Label>
            </div>
          </TableCell>
          <TableCell
            width={200}
            sx={{
              border: '0 !important',
              borderBottom: '0 !important',
              margin: 'auto',
              minWidth: '200px',
            }}>
            <GridActionButton
              text="VIEW TO-DO"
              onClick={() => onClickView(data.id)}
            />
          </TableCell>
        </PaperWrap>
        {data.category === 'Training' && (
          <PaperWrap>
            <TableCell
              sx={{
                border: '0 !important',
                borderBottom: '0 !important',
                paddingTop: '0 !important',
              }}>
              <div className="flex-column">
                <DetailsItem>
                  <h6>
                    1) Watch Video: &nbsp;&nbsp;{' '}
                    <Status status={details?.videoStatus || ''}>
                      {details?.videoStatus}
                    </Status>
                  </h6>
                </DetailsItem>
                <DetailsItem>
                  {details?.quiz && (
                    <h6>
                      2) Take Quiz: &nbsp; &nbsp;{' '}
                      {details?.quiz?.failedAttempts} of{' '}
                      {details?.quiz?.totalAttempts} attempts
                    </h6>
                  )}
                </DetailsItem>
              </div>
            </TableCell>
          </PaperWrap>
        )}
      </CustomPaper>
    </TableCell>
  );
};

export default observer(TodoCard);
