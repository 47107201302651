import { makeAutoObservable, toJS } from 'mobx';
import moment from 'moment';

import { API_LIMIT } from '../config';
import { AdminNotificationSearchParam } from '../types';
// import { userNotificationDataAccess } from '../utils/helper';
import { RootStore } from './';

export class NotificationStore {
  private _adminNotification: any = [];
  private _selectedNotification: any = null;
  private _notificationSearchParams: {
    limit: number;
    searchParam: AdminNotificationSearchParam;
    triggerNotification: boolean;
  } = {
    limit: API_LIMIT,
    searchParam: {
      description: '',
      // nextLink: '',
      title: '',
      type: '',
    },
    triggerNotification: true,
  };
  private _hasMoreResults = false;
  private _nextLink = '';
  private _loader = false;
  private _notificationList: Array<any> = [];

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get getNotificationDetails() {
    return [...toJS(this._adminNotification)];
  }

  get getSelectedNotification(): any {
    const existedSelectedNotification = { ...this._selectedNotification };
    this.setSelectedNotification();
    return existedSelectedNotification;
  }

  get getNotificationHeaderParams() {
    const search: any = { ...toJS(this._notificationSearchParams.searchParam) };
    return {
      description: search.description,
      // nextLink: search.nextLink,
      title: search.title,
      type: search.type,
    };
  }

  get getNotificationTriggerStatus(): any {
    return this._notificationSearchParams.triggerNotification;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get NextLink() {
    return this._nextLink;
  }
  get MainLoader() {
    return this._loader;
  }
  get notifications() {
    return this._notificationList;
  }

  setNotifications(listArr: Array<any>) {
    this._notificationList = listArr;
  }

  setMainLoader(loader: boolean) {
    this._loader = loader;
  }

  setNotificationCountIncrement() {
    if (this.getNotificationTriggerStatus) {
      const existedDetails: any = { ...toJS(this._notificationSearchParams) };
      // existedDetails.limit += 10;
      // existedDetails.triggerNotification = !existedDetails.triggerNotification;
      this._notificationSearchParams = { ...existedDetails };
    }
  }

  setNotificationHeaderSearchParams(param: any) {
    const existedDatas: any = {
      ...toJS(this._notificationSearchParams.searchParam),
      ...param,
    };
    this._notificationSearchParams.searchParam = {
      ...existedDatas,
    };
    if (!param.description && !param.title) {
      this.setNotifications([]);
      this.setNotificationDetails([]);
      // this.setMainLoader(true);
      this.setNextLink('');
      // this.resetNotificationData();
    }
  }

  setSelectedNotification(data: any = []) {
    this._selectedNotification = data;
  }

  setNotificationDetails(data: any = []) {
    const previousData: any = [...toJS(this._adminNotification), ...data];
    // if (
    //   previousData.length === data.length &&
    //   this._notificationSearchParams.limit > 10
    // ) {
    //   this._notificationSearchParams.limit -= 10;
    // }
    // if (
    //   this._notificationSearchParams.limit !== data.length ||
    //   previousData.length === data.length
    // ) {
    //   this._notificationSearchParams.triggerNotification = false;
    // }

    this._adminNotification = previousData;
    this.setNotifications(previousData);
  }
  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  resetParams() {
    // this.setNotificationHeaderSearchParams({
    //   description: '',
    //   onlySplash: false,
    //   title: '',
    // });
    const existedDatas: any = {
      description: '',
      onlySplash: false,
      title: '',
      type: '',
    };
    this._notificationSearchParams.searchParam = {
      ...existedDatas,
    };
    this.setNextLink('');
    this.setNotificationDetails([]);
  }
  async getInitialData() {
    const initialData =
      await this.rootStore.apiStore.notificationApiStore.getInitialData({
        ...this.getNotificationHeaderParams,
        limit: API_LIMIT,
        nextLink: this.NextLink,
        onlySplash: false,
      });

    if (initialData.isOk()) {
      // this.setNotificationDetails([...initialData.value.response]);
      this.setNextLink(initialData.value.nextLink);
      this.setHasMoreResults(initialData.value.nextLink ? true : false);
    }

    if (initialData.isErr()) {
      this.setNotificationDetails([]);
    }

    return initialData;
  }

  async resetNotificationData() {
    const initialData =
      await this.rootStore.apiStore.notificationApiStore.getInitialData({
        ...this.getNotificationHeaderParams,
        limit: API_LIMIT,
        nextLink: this.NextLink,
        onlySplash: false,
      });
    this.setMainLoader(false);
    if (initialData.isOk()) {
      // this.setNotificationDetails([...initialData.value.response]);
      this.setNextLink(initialData.value.nextLink);
      this.setHasMoreResults(initialData.value.nextLink ? true : false);
      const {
        value: { response },
      } = initialData;

      // setTotalCount(count || 0);

      if (!response.length) {
        this.setNotifications([]);
        return;
      }

      let lastMsgReadlog: any = null;

      // if (msgLastSeenLog.entry) {
      //   lastMsgReadlog = userNotificationDataAccess(
      //     'lastMsgReadlog',
      //     this.rootStore.authStore.getTokenDetail?.id,
      //   );
      //   const datevalueStatus: any = moment(lastMsgReadlog).isValid();
      //   // setMsgLastSeenLog({
      //   //   date: datevalueStatus ? lastMsgReadlog : '',
      //   //   entry: false,
      //   // });
      // } else {
      //   lastMsgReadlog = msgLastSeenLog.date;
      // }

      if (lastMsgReadlog) {
        lastMsgReadlog = moment(new Date(lastMsgReadlog));
      }

      const oldDatas: any = [...this.notifications];
      let existedFindedElem: any = [];
      const respresponse = response
        .map((responseData: any) => {
          const findedElem: any = oldDatas.find(
            (e: any) => e.templateId === responseData.templateId,
          );
          if (!findedElem) {
            return responseData;
          } else {
            existedFindedElem.push(findedElem);
          }
          return null;
        })
        .filter((e: any) => e);

      const companyDetails = [...respresponse].map((company: any) => {
        const returnData: any = {
          ...company,
          date: company.startTime
            ? moment(company.startTime).format('MM/DD/YYYY')
            : '',
          readStatus: true,
        };

        if (lastMsgReadlog) {
          const status: any = lastMsgReadlog.isBefore(
            moment(company.startTime),
          );
          returnData.readStatus = status;
        }

        if (returnData.description) {
          const replacevalue: string = (returnData.description || '').replace(
            /\n/gim,
            '<br>',
          );
          returnData.description = replacevalue;
        }

        return returnData;
      });

      existedFindedElem = [...existedFindedElem, ...companyDetails];
      const newArray: any = [...oldDatas, ...companyDetails]
        .map((existed: any) => {
          if (!existedFindedElem.length) {
            return existed;
          }
          const findStatusValue: any = existedFindedElem.find(
            (e: any) => e.templateId === existed.templateId,
          );
          if (findStatusValue) {
            return findStatusValue;
          }
          return null;
        })
        .filter((e: any) => e);

      // setNotifications((p: any) => [...p, ...companyDetails]);
      this.setNotifications([...newArray]);
    }
  }
}
