import { styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import IdleAlert from '../../components/IdleAlert';
// import { Route, Routes } from 'react-router-dom';
import { useCustomStore } from '../../hooks';
import UpdateCredentialsModal from '../../screens/private/Global/UpdateCredentialsModal';
// import Home from '../../screens/private/Home/Home';
// import TaxPermitsAnnualReport from '../../screens/private/ServiceRequest/TaxPermits/AnnualReports/AnnualReport';
// import TaxPermitsIFTA from '../../screens/private/ServiceRequest/TaxPermits/IFTA/IFTA';
// import TaxPermitsRoadTaxes from '../../screens/private/ServiceRequest/TaxPermits/RoadTaxes/RoadTaxes';
// import TaxPermitsStatePermits from '../../screens/private/ServiceRequest/TaxPermits/StatePermits/StatePermits';
// import Taxsandpermits from '../../screens/private/ServiceRequest/TaxPermits/Taxsandpermits';
// import TaxPermitsUcr from '../../screens/private/ServiceRequest/TaxPermits/UCR/Ucr';
// import { PrivateRoutes, PublicRoutes } from '../index';
import { AccessTypes } from '../../types';
import CompanyAdminRoutes from './CompanyAdminRoutes';
import DriverRoutes from './DriversRoutes';
import OwnerOperatorRoutes from './OwnerOperatorRoutes';

const Banner = styled('div')(() => ({
  backgroundColor: '#a10e24',
  color: '#FFF',
  fontSize: 16,
  height: 40,
  position: 'fixed',
  width: '100%',
  zIndex: 20,
}));

const Shell = styled('div')<{ hasBanner: boolean }>(({ hasBanner }) => ({
  height: hasBanner ? 'calc(100% - 40px)' : '100%',
  position: hasBanner ? 'absolute' : 'inherit',
  top: hasBanner ? 40 : 'auto',
  width: '100%',
}));

const A = styled('a')(() => ({
  cursor: 'pointer',
  textDecoration: 'underline',
}));

const PrivateRoute = (): React.ReactElement => {
  const { authStore, userStore } = useCustomStore();
  const {
    UserData,
    getCredentials,
    DOTPin,
    FMCSA,
    ShowDOTForm,
    ShowFMCSAForm,
  } = userStore;

  const { NavbarAccess: access } = authStore;

  const accessType = UserData?.accessType;

  const isDotMissingOrInvalid = (DOTPin && DOTPin?.status !== 'Valid') || false;
  const isFMCSAMissingOrInvalid = (FMCSA && FMCSA?.status !== 'Valid') || false;
  const tempShowDot = false;

  useEffect(() => {
    if (accessType === 'companyAdmin') {
      getCredentials();
    }
  }, [getCredentials, accessType]);

  // const handleAppEntry = useCallback(() => {
  //   authStore.fetchTokens();
  // }, [authStore]);

  // useEffect(() => {
  //   handleAppEntry();
  // }, [handleAppEntry]);

  const hasTokenAndUserId = !!authStore.tokens;
  const handleContinue = () => {
    authStore.setIdleForLongtimeWarning(false);
    authStore.setIdleTime(0);
  };

  const showBanner = () => {
    const showDOTBanner =
      (access?.dashboard?.dotPin && isDotMissingOrInvalid && tempShowDot) ||
      false;
    const showFMCSABanner =
      (access?.dashboard?.fmcsa && isFMCSAMissingOrInvalid) || false;
    return showDOTBanner || showFMCSABanner;
  };

  const handleLogout = () => {
    authStore.setIdleForLongtimeWarning(false);
    authStore.logout();
  };

  const handleClickOnDOTPIN = () => {
    userStore.setShowDOTForm(true);
  };

  const handleClickOnFMCSA = () => {
    userStore.setShowFMCSAForm(true);
  };

  return (
    <>
      {showBanner() &&
        authStore.getTokenDetail?.accessType === AccessTypes.CompanyAdmin && (
          <Banner id="dot-fmcsa" className="flex-row-center">
            {isDotMissingOrInvalid && !isFMCSAMissingOrInvalid && tempShowDot && (
              <p>
                <A onClick={handleClickOnDOTPIN}>DOT PIN</A> Credentials are NOT
                valid
              </p>
            )}
            {isFMCSAMissingOrInvalid && !isDotMissingOrInvalid && (
              <p>
                <A onClick={handleClickOnFMCSA}>FMCSA Portal Credentials</A> are
                NOT valid
              </p>
            )}
            {isFMCSAMissingOrInvalid && isDotMissingOrInvalid && tempShowDot && (
              <p>
                <A onClick={handleClickOnDOTPIN}>DOT PIN</A> and{' '}
                <A onClick={handleClickOnFMCSA}>FMCSA Portal Credentials</A> are
                NOT valid
              </p>
            )}
          </Banner>
        )}
      <Shell
        hasBanner={
          showBanner() &&
          authStore.getTokenDetail?.accessType === AccessTypes.CompanyAdmin
        }>
        {authStore.idleForLongtimeWarning && (
          <IdleAlert onLogout={handleLogout} onContinue={handleContinue} />
        )}
        {hasTokenAndUserId &&
          authStore.getTokenDetail?.accessType === AccessTypes.CompanyAdmin && (
            <CompanyAdminRoutes />
          )}
        {hasTokenAndUserId &&
          authStore.getTokenDetail?.accessType === AccessTypes.Driver && (
            <DriverRoutes />
          )}
        {hasTokenAndUserId &&
          authStore.getTokenDetail?.accessType ===
            AccessTypes.OwnerOperator && <OwnerOperatorRoutes />}
      </Shell>
      {(ShowDOTForm || ShowFMCSAForm) && <UpdateCredentialsModal />}
    </>
    // <CompanyAdminRoutes />
    // <DriverRoutes />
    // <Routes>
    //   {hasTokenAndUserId ? (
    //     <>
    //       {/* <Route
    //         path={PrivateRoutes.SERVICEREQUEST.UCR}
    //         element={
    //           // <Home>
    //           <Taxsandpermits
    //             tabValue={'1'}
    //             childComponent={<TaxPermitsUcr />}
    //           />
    //           // </Home>
    //         }
    //       />
    //       <Route
    //         path={PrivateRoutes.SERVICEREQUEST.ROADTAX}
    //         element={
    //           // <Home>
    //           <Taxsandpermits
    //             tabValue={'2'}
    //             childComponent={<TaxPermitsRoadTaxes />}
    //           />
    //           // </Home>
    //         }
    //       />
    //       <Route
    //         path={PrivateRoutes.SERVICEREQUEST.ANNUALREPORT}
    //         element={
    //           // <Home>
    //           <Taxsandpermits
    //             tabValue={'3'}
    //             childComponent={<TaxPermitsAnnualReport />}
    //           />
    //           // </Home>
    //         }
    //       />
    //       <Route
    //         path={PrivateRoutes.SERVICEREQUEST.IFTA}
    //         element={
    //           // <Home>
    //           <Taxsandpermits
    //             tabValue={'4'}
    //             childComponent={<TaxPermitsIFTA />}
    //           />
    //           // </Home>
    //         }
    //       />
    //       <Route
    //         path={PrivateRoutes.SERVICEREQUEST.STATEPERMITS}
    //         element={
    //           // <Home>
    //           <Taxsandpermits
    //             tabValue={'5'}
    //             childComponent={<TaxPermitsStatePermits />}
    //           />
    //           // </Home>
    //         }
    //       /> */}
    //       <Route path={'/'} element={<Home />} />
    //       <Route path="*" element={<Navigate to="/" />} />
    //     </>
    //   ) : (
    //     <Navigate
    //       to={PublicRoutes.LOGIN}
    //       replace={true}
    //       state={{ from: location }}
    //     />
    //   )}
    // </Routes>
  );
};

export default observer(PrivateRoute);
