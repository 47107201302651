import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

export class DashboardApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getTodosList = () => {
    const {
      GetTodos: { Endpoint, Method },
    } = ApiRoutes.Dashboard;

    return this.rootStore.apiStore.call({
      method: Method,
      params: {},
      url: Endpoint,
    });
  };
  getDataList = () => {
    const {
      GetData: { Endpoint, Method },
    } = ApiRoutes.Dashboard;

    return this.rootStore.apiStore.call({
      method: Method,
      params: {},
      url: Endpoint,
    });
  };
  getInspectionDataList = () => {
    const {
      GetInspectionData: { Endpoint, Method },
    } = ApiRoutes.Dashboard;

    return this.rootStore.apiStore.call({
      method: Method,
      params: {},
      url: Endpoint,
    });
  };
  getBasicCategoriesDataList = () => {
    const {
      GetBasicCategories: { Endpoint, Method },
    } = ApiRoutes.Dashboard;

    return this.rootStore.apiStore.call({
      method: Method,
      params: {},
      url: Endpoint,
    });
  };
  getTrendingItemsDataList = () => {
    const {
      GetTrendingItems: { Endpoint, Method },
    } = ApiRoutes.Dashboard;

    return this.rootStore.apiStore.call({
      method: Method,
      params: {},
      url: Endpoint,
    });
  };
}
