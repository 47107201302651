import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { CircularProgress, Grid, TextField } from '@mui/material';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { API_LIMIT } from '../../../../config/Constants';
import { useCustomStore } from '../../../../hooks';
import {
  AccessTypes,
  customNavTabs,
  DotActivityListFilters,
} from '../../../../types';
import { refreshActivityListByStore } from '../../../../utils/helper';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import DotActivityList from '../components/CardsList/DotActivityList';
import DOTNavBarList from '../components/NavTabs/DotTabs';
import { DotTabsList } from '../components/NavTabs/DotTabsList';
import useStyles from '../TaxPermits/TaxsandpermitsStyles';
import filterIcon from './../../../../assets/images/filter.svg';
import search from './../../../../assets/images/h-search.svg';

type DOTTypes = {
  tabValue: string;
  childComponent?: any;
};
const DOT: React.FC<DOTTypes> = (Props: DOTTypes) => {
  const { tabValue } = Props;
  const classes = useStyles();
  const location = useLocation();

  const { dotActivityListStore, authStore }: any = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const { ShowLoader, ActivityList, HasMoreResults, NextLink } =
    dotActivityListStore;
  const { Filters } = dotActivityListStore;
  const [filter, setFilters] = useState<DotActivityListFilters>({
    categories: [],
    dateSort: 'desc',
    driver: '',
    lastSubmittedBy: '',
    limit: API_LIMIT,
    nextLink: '',
    submittedBy: '',
  });
  const [value, setValue] = useState<string>('');
  const [getMoreLoader, setGetMoreLoader] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let navTabs: customNavTabs[] = React.useMemo(() => [], []);

  if (authStore.tokens) {
    // if (!authStore.tokens) {
    const role = authStore.getTokenDetail?.accessType || '';
    if (role === AccessTypes.CompanyAdmin) {
      navTabs = DotTabsList;
    } else if (role === AccessTypes.OwnerOperator) {
      navTabs = DotTabsList;
    }
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllActivityList = useCallback(async () => {
    const getEquipmentsResp = await refreshActivityListByStore(
      dotActivityListStore,
    );
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
  }, [dotActivityListStore, enqueueSnackbar]);
  const getActivityList = useCallback(async () => {
    dotActivityListStore.setShowLoader(true);
    const getEquipmentsResp = await dotActivityListStore.fetchActivityLists();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    dotActivityListStore.setShowLoader(false);
  }, [dotActivityListStore, enqueueSnackbar]);

  useEffect(() => {
    getAllActivityList();
  }, [getAllActivityList]);
  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);
  const getMoreActivityList = useCallback(async () => {
    dotActivityListStore.setFilters({ ...filter, ...{ nextLink: NextLink } });
    setGetMoreLoader(true);
    const getEquipmentsResp =
      await dotActivityListStore.fetchActivityListsBySearchBased();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [NextLink, dotActivityListStore, enqueueSnackbar, filter]);

  const setlastSubmited = useCallback(
    (key: string, value: string) => {
      setFilters((pre) => ({
        ...pre,
        [key]: filter.lastSubmittedBy === value ? '' : value,
      }));
    },
    [filter],
  );
  const setCategories = useCallback(
    (value: string) => {
      const arr = [...filter.categories];

      if (arr.some((e: any) => e === value)) {
        const index = arr.findIndex((e) => e === value);
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
      setFilters((pre) => ({
        ...pre,
        categories: arr,
      }));
    },
    [filter],
  );
  const applyFilters = () => {
    dotActivityListStore.setFilters({ ...filter, nextLink: '' });
    // getAllActivityList();
    getActivityList();
    handleClose();
  };
  const resetFilter = () => {
    setFilters((pre) => ({
      ...pre,
      categories: [],
      dateSort: 'desc',
      driver: '',
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: '',
      submittedBy: '',
    }));
    dotActivityListStore.setFilters({
      categories: [],
      dateSort: filter.dateSort,
      driver: '',
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: '',
      submittedBy: '',
    });
    getActivityList();
    // handleClose();
  };
  const removeFilter = (key: string, value: string) => {
    const arr = [...filter.categories];
    if (key === 'categories') {
      arr.splice(Number(value), 1);
    }
    setFilters((pre) => ({
      ...pre,
      [key]: key === 'categories' ? arr : value,
    }));

    dotActivityListStore.setFilters({
      ...filter,
      ...{ [key]: key === 'categories' ? arr : value },
      NextLink: '',
    });
    getActivityList();
    handleClose();
  };
  const formatCatergories = (
    shortKey: string,
  ): { key: string; value: string } => {
    let key = { key: '', value: '' };
    switch (shortKey) {
      case 'Motor Vehicle Record':
        key = { key: shortKey, value: 'MVR' };
        break;
      case 'Add to Fleet':
        key = { key: shortKey, value: 'Add to Fleet' };
        break;
      case 'Driver Onboarding':
        key = { key: shortKey, value: 'New Driver Setup' };
        break;
      case 'Data Q Challenge':
        key = { key: shortKey, value: 'Data Q Challenge' };
        break;
      case 'Vehicle Inspection':
        key = { key: shortKey, value: 'Equipment Inspection' };
        break;
      case 'Driver Termination':
        key = { key: shortKey, value: 'Terminate Driver' };
        break;
      case 'Equipment Termination':
        key = { key: shortKey, value: 'Terminate Equipment' };
        break;

      default:
        key = { key: '', value: '' };
        break;
    }
    return key;
  };
  return (
    <Grid container spacing={0.5} minHeight={'100%'} bgcolor={'#FFFFFF'}>
      {/* left Side content */}
      <Grid
        item
        xs={12}
        lg={8}
        md={12}
        sm={12}
        minHeight={'calc(100vh - 80px)'}
        borderRight={'1px solid #d8d8d8'}
        paddingLeft={'0px !important'}>
        <TabContext value={value}>
          <DOTNavBarList setValue={setValue} value={value} />
          <TabPanel
            value={value}
            style={
              value === '1' && location.pathname === '/service-request/mvr'
                ? { maxHeight: 'unset' }
                : {}
            }
            className={classes.tabPanelForDOT}>
            {Props.childComponent}
          </TabPanel>
        </TabContext>
      </Grid>

      {/* Right Side content */}
      <Grid
        item
        xs={12}
        lg={4}
        md={12}
        sm={12}
        className={classes.padcss}
        style={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}>
        <Typography
          className={classes.actFilter}
          component={'div'}
          display={'inline-flex'}
          flexWrap={'wrap'}
          width={'100%'}
          justifyContent={'space-between'}
          style={{ backgroundColor: 'rgb(234 234 234 / 38%)' }}>
          <Typography
            component={'span'}
            gutterBottom
            className={classes.cardMainHeading}>
            Activity List
          </Typography>
          <Typography
            component={'div'}
            gutterBottom
            className={classes.cardHeading}>
            <Typography className={classes.asend} component={'div'}>
              {filter.dateSort === 'asc' ? 'Date Ascending' : 'Date Descending'}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '5px',
                }}
                onClick={() =>
                  removeFilter(
                    'dateSort',
                    filter.dateSort === 'asc' ? 'desc' : 'asc',
                  )
                }>
                <KeyboardArrowUpIcon
                  fontSize="small"
                  {...(filter.dateSort === 'desc' ? { color: 'disabled' } : {})}
                />
                <KeyboardArrowDownIcon
                  fontSize="small"
                  {...(filter.dateSort === 'asc' ? { color: 'disabled' } : {})}
                />{' '}
              </div>
            </Typography>
            <Typography
              className={classes.fIcon}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <img className={classes.fimg} src={filterIcon} alt="filter" />
            </Typography>
            <Menu
              id="basic-menu"
              className="filter"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <Grid style={{ padding: '1rem' }}>
                <Typography component={'h3'}>CATEGORY</Typography>
                <Typography
                  component={'div'}
                  className={classes.borderBottom}
                  style={{ padding: '1rem 0' }}>
                  {[
                    ...navTabs
                      .filter((tabs) => tabs.filter)
                      .filter(
                        (tabs) =>
                          tabs.activeTabKey &&
                          authStore.NavbarAccess &&
                          authStore.NavbarAccess.dot &&
                          authStore.NavbarAccess.dot[tabs.activeTabKey],
                      )
                      .map((tab, index) => (
                        <Grid
                          key={tab.title}
                          item
                          className={classes.dflexA}
                          onClick={() => setCategories(tab.shortkey)}>
                          <input
                            type="checkbox"
                            tabIndex={0}
                            checked={filter.categories.some(
                              (val) => val === tab.shortkey,
                            )}
                          />
                          <Typography
                            component={'div'}
                            style={{ cursor: 'pointer' }}>
                            {tab.title}
                          </Typography>
                        </Grid>
                      )),
                    <Grid
                      key={new Date().getMilliseconds()}
                      item
                      className={classes.dflexA}
                      onClick={() => setCategories('Data Q Challenge')}>
                      <input
                        type="checkbox"
                        tabIndex={0}
                        checked={filter.categories.some(
                          (val) => val === 'Data Q Challenge',
                        )}
                      />
                      <Typography
                        component={'div'}
                        style={{ cursor: 'pointer' }}>
                        {'Data Q Challenge'}
                      </Typography>
                    </Grid>,
                  ]}
                  {/* <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('PSP')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some((val) => val === 'PSP')}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      PSP
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Drug and Alcohol Test')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Drug and Alcohol Test',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      Drug Test
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Background Check')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Background Check',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      Criminal Background
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Driver Onboarding')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Driver Onboarding',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      New Driver Setup
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Add to Fleet')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Add to Fleet',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      Add to Fleet
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Vehicle Inspection')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Vehicle Inspection',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      Equipment Inspection
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Data Q')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Data Q',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      Data Q Challenge
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Driver Termination')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Driver Termination',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      Terminate Driver
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories('Equipment Termination')}>
                    <input
                      type="checkbox"
                      checked={filter.categories.some(
                        (val) => val === 'Equipment Termination',
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      Terminate Equipment
                    </Typography>
                  </Grid> */}
                </Typography>
                <Typography component={'h3'}>SUBMITTED IN LAST</Typography>
                <Typography component={'div'} className={classes.borderBottom}>
                  <Grid
                    className={classes.dflexA}
                    style={{ padding: '1rem 0' }}>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox} ` +
                          `${
                            filter.lastSubmittedBy == '15'
                              ? classes.activeBox
                              : ''
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '15')
                        }>
                        15 DAYS
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox} ` +
                          `${
                            filter.lastSubmittedBy == '30'
                              ? classes.activeBox
                              : ''
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '30')
                        }>
                        30 DAYS
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox} ` +
                          `${
                            filter.lastSubmittedBy == '60'
                              ? classes.activeBox
                              : ''
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '60')
                        }>
                        60 DAYS
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography component={'h3'}>Driver Name</Typography>
                <Grid className={classes.borderBottom}>
                  <Grid container className={classes.searcBox}>
                    <Grid item md={2} className={classes.dflexJA}>
                      <img
                        className={classes.filteIcon}
                        src={search}
                        alt="search"
                      />
                    </Grid>
                    <Grid item md={10}>
                      <TextField
                        type={'text'}
                        value={filter.driver}
                        sx={{
                          input: { backgroundColor: 'unset', color: '#fff' },
                        }}
                        className={classes.inputF}
                        placeholder="Search"
                        onChange={(e: any) =>
                          setFilters((pre) => ({
                            ...pre,
                            driver: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Typography component={'h3'}>SUBMITTED BY</Typography>
                <Grid className={classes.borderBottom}>
                  <Grid container className={classes.searcBox}>
                    <Grid item md={2} className={classes.dflexJA}>
                      <img
                        className={classes.filteIcon}
                        src={search}
                        alt="search"
                      />
                    </Grid>
                    <Grid item md={10}>
                      <TextField
                        type={'text'}
                        value={filter.submittedBy}
                        sx={{
                          input: { backgroundColor: 'unset', color: '#fff' },
                        }}
                        className={classes.inputF}
                        placeholder="Search"
                        onChange={(event: any) =>
                          setFilters((pre) => ({
                            ...pre,
                            submittedBy: event.target.value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={6} className={classes.resetF}>
                    <Typography
                      component={'div'}
                      className={classes.resetF}
                      onClick={resetFilter}>
                      RESET FILTERS
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      component={'div'}
                      className={classes.filterBtn}
                      onClick={applyFilters}>
                      APPLY FILTER
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Menu>
          </Typography>
          <Typography component={'div'} className={classes.filterList}>
            {Filters.lastSubmittedBy ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('lastSubmittedBy', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.lastSubmittedBy} Days
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.driver ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('driver', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.driver}
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.submittedBy ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('submittedBy', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.submittedBy}
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.categories && Filters.categories.length
              ? Filters.categories.map((val: any, index: any) => (
                  <Typography
                    component={'div'}
                    className={classes.filterItem}
                    key={index}
                    onClick={() => removeFilter('categories', String(index))}>
                    <Typography
                      component={'div'}
                      className={classes.filterText}>
                      {formatCatergories(val)?.value}
                    </Typography>
                    <Typography className={classes.close} component={'span'}>
                      X
                    </Typography>
                  </Typography>
                ))
              : ''}
          </Typography>
        </Typography>
        {ShowLoader ? (
          <div className={classes.loader}>
            <CircularProgress size={30} sx={{ color: '#DEC330' }} />
          </div>
        ) : (
          <DotActivityList
            ActivityList={
              ActivityList && ActivityList.length ? ActivityList : []
            }
            getMoreActivityList={getMoreActivityList}
            getMoreLoader={getMoreLoader}
            HasMoreResults={HasMoreResults}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default observer(DOT);
