import { Close } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePickerView } from '@mui/lab/DatePicker/shared';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';

const CloseX = styled(Close)(() => ({
  cursor: 'pointer',
}));

const OutlinedInputX = styled(OutlinedInput)(() => ({
  border: '#e8e8e8 solid 1px',
}));

type FormInputTextProps = {
  errors?: string;
  defaultValue?: string;
  value?: string;
  tabIndexVal?: number;
  onChangeDate?: (src: string) => void;
  onBlurText?: () => void;
  isSecureTextEntry?: boolean;
  disabled?: boolean;
  name: string;
  isRefreshValue?: boolean;
  isFormSubmittedOnce?: boolean;
  inputRefObj?: React.Ref<any>;
  className?: string;
  label?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  initIcon?: any;
  minDate?: Date;
  maxDate?: Date;
  openTo?: DatePickerView;
  shouldClear?: boolean;
  placeholder?: string;
};

export const SelectDatePicker = observer(
  (props: FormInputTextProps): ReactElement => {
    const {
      errors,
      value,
      isRefreshValue,
      isFormSubmittedOnce,
      onChangeDate,
      inputRefObj,
      disabled = false,
      minDate,
      maxDate,
      openTo,
      label,
      shouldClear = false,
      placeholder,
    } = props;
    const classes = useStyles();
    const [inputVal, setInputVal] = React.useState(value);
    const [showLocalError, setShowLocalError] = React.useState(true);

    const onValueChange = React.useCallback(
      (val: string) => {
        setInputVal(val);
        setShowLocalError(!val);
        onChangeDate && onChangeDate(val);
      },
      [onChangeDate],
    );

    //   const onBlurChange = React.useCallback(() => {
    //     setShowLocalError(true);
    //     onBlurText && onBlurText();
    //   }, [onBlurText]);

    React.useEffect(() => {
      if (isRefreshValue && inputVal !== value) {
        setInputVal(value);
        setShowLocalError(!value);
      }
    }, [inputVal, value, isRefreshValue, inputRefObj]);

    const showError = isFormSubmittedOnce || showLocalError;

    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label={label}
            className={classes.dateRange}
            inputRef={inputRefObj}
            disabled={disabled}
            defaultCalendarMonth={maxDate}
            openTo={openTo}
            clearable
            value={inputVal}
            renderInput={(params) =>
              shouldClear && inputVal ? (
                <OutlinedInputX
                  {...(params as OutlinedInputProps)}
                  placeholder={placeholder}
                  endAdornment={
                    <InputAdornment position="end">
                      <CloseX
                        onClick={() => {
                          setInputVal('');
                          onChangeDate && onChangeDate('');
                        }}
                      />
                    </InputAdornment>
                  }
                />
              ) : (
                <TextField {...params} />
              )
            }
            disableOpenPicker={disabled}
            // inputProps={{
            //   tabIndex: flatworksStore.EditPourFormTabsObject[rowIndex]?.pourDate,
            // }}
            {...(maxDate ? { maxDate } : {})}
            minDate={minDate ? minDate : undefined}
            // maxDate={currentDate}
            onChange={(date: any) => {
              onValueChange(date);
            }}
          />
        </LocalizationProvider>
        {(errors || showError) && (
          <FormHelperText className={classes.helperText}>
            {errors}
          </FormHelperText>
        )}
      </>
    );
  },
);
const useStyles = makeStyles((theme) => ({
  dateRange: {
    '& .MuiPaper-root': {
      backgroundColor: '#fff !important',
    },
    '& .MuiPickersDay-root': {
      backgroundColor: '#fff !important',
    },
  },
  helperText: {
    color: `${theme.palette.warning.main} !important`,
    marginLeft: 'unset !important',
    position: 'relative',
  },
}));
