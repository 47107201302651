import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
  },
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: '10px',
  },
  draft: {
    boxShadow: 'unset',
  },
  rightSidebtn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  submited: {
    boxShadow: 'unset',
  },
}));

export default useStyles;
