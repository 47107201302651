import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TrainingDismissDialog from '../../../../components/TrainingDismissDialog/TrainingDismissDialog';
import TrainingDueDateDialog from '../../../../components/TrainingDueDateDialog/TrainingDueDateDialog';
import { useCustomStore } from '../../../../hooks';
import { TrainingAssignedSortOptions } from '../../../../types';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import AssignedHeader from './AssignedHeader';
import AssignedList from './AssignedList';
import useStyles from './AssignedStyles';

const Assigned: React.FC = () => {
  const { trainingStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isDismissOpen, setIsDismissOpen] = useState<boolean>(false);
  const [dismissLoader, setDismissLoader] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] = useState<any>(null);
  const [moreAssignedData, setMoreAssignedData] = useState<boolean>(false);
  const [showDueDateDialog, setShowDueDateDialog] = useState<boolean>(false);

  const getTrainingAssignedData = useCallback(async () => {
    trainingStore.setTrainingAssignedLoader(true);
    const resp = await trainingStore.getInitialTrainingAssigned();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Assigned Assignments',
        SnackBarConfig('e'),
      );
    }
    trainingStore.setTrainingAssignedLoader(false);
  }, [enqueueSnackbar, trainingStore]);

  const getMoreTrainingAssignedData = useCallback(async () => {
    setMoreAssignedData(true);
    const resp = await trainingStore.getTrainingAssigned();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Assigned Assignments',
        SnackBarConfig('e'),
      );
    }
    setMoreAssignedData(false);
  }, [enqueueSnackbar, trainingStore]);

  const trainingAssignedSortBy = (columnName: TrainingAssignedSortOptions) => {
    trainingStore.setTrainingAssignedFilters({
      sortBy: columnName,
      sortChanged: true,
      sortDirection:
        trainingStore.TrainingAssignedFilters.sortDirection === 'asc'
          ? 'desc'
          : 'asc',
    });
    getTrainingAssignedData();
  };

  useEffect(() => {
    getTrainingAssignedData();
  }, [getTrainingAssignedData]);

  useEffect(() => {
    if (trainingStore.RefreshTrainingAssignedData) {
      getTrainingAssignedData();
    }
  }, [getTrainingAssignedData, trainingStore.RefreshTrainingAssignedData]);

  const onDismissConfirm = (data: any) => {
    if (!data) return;
    setSelectedAssignment(data);
    setIsDismissOpen(true);
  };

  const onDismissTraining = async (data: any) => {
    setDismissLoader(true);
    const resp = await trainingStore.dismissAssignment(data.id);
    if (resp.isErr()) {
      enqueueSnackbar('Unable to dismiss assignment', SnackBarConfig('e'));
    }
    setDismissLoader(false);
    setIsDismissOpen(false);
    setSelectedAssignment(null);
    getTrainingAssignedData();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {trainingStore.TrainingAssignedLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{ maxHeight: 'calc(100vh - 125px)' }}
            id="training-assigned-table">
            <InfiniteScroll
              dataLength={trainingStore.TrainingAssignedData.length}
              style={{ overflow: 'unset' }}
              next={getMoreTrainingAssignedData}
              hasMore={trainingStore.HasMoreTrainingAssigned}
              scrollableTarget={'training-assigned-table'}
              loader={
                moreAssignedData && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <AssignedHeader
                    trainingStore={trainingStore}
                    authStore={authStore}
                    trainingAssignedSortBy={trainingAssignedSortBy}
                  />
                </TableHead>
                <TableBody>
                  {trainingStore.TrainingAssignedData &&
                  trainingStore.TrainingAssignedData.length > 0 ? (
                    <AssignedList
                      trainingStore={trainingStore}
                      authStore={authStore}
                      onDismissConfirm={onDismissConfirm}
                      onDueDateUpdate={(data: any) => {
                        if (!data) return;
                        setSelectedAssignment(data);
                        setShowDueDateDialog(true);
                      }}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={8}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
      {isDismissOpen && (
        <TrainingDismissDialog
          isOpen={isDismissOpen}
          loader={dismissLoader}
          assignment={selectedAssignment}
          onDismissTraining={onDismissTraining}
          closeDialog={() => {
            setIsDismissOpen(false);
            setSelectedAssignment(null);
          }}
        />
      )}
      {showDueDateDialog && (
        <TrainingDueDateDialog
          isOpen={showDueDateDialog}
          assignment={selectedAssignment}
          closeDialog={(reload: boolean) => {
            setShowDueDateDialog(false);
            setSelectedAssignment(null);
            reload && getTrainingAssignedData();
          }}
        />
      )}
    </Box>
  );
};

export default observer(Assigned);
