import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import {
  // PageChangeEvent,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import clsx from 'clsx';
// import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';

import packageJson from '../../../package.json';
// import { ApiRoutes } from '../../config/ApiRoutes';
// import { Config } from '../../config/Constants';
// import { useCustomStore } from '../../hooks';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './TrainingDialogStyles';

interface TrainingQuizDialogProps {
  url: string;
  showDialog: boolean;
  closeDialog: (refresh: boolean) => void;
}

const TrainingCertificateViewDialog: React.FC<TrainingQuizDialogProps> = (
  props: TrainingQuizDialogProps,
) => {
  const classes = useStyles();
  //   const { authStore } = useCustomStore();
  //   const { enqueueSnackbar } = useSnackbar();
  const { showDialog, closeDialog, url } = props;
  const pdfJsVersion: string = packageJson.dependencies['pdfjs-dist'];

  const renderCertifcateViewer = (): ReactElement => {
    return (
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfJsVersion}/build/pdf.worker.min.js`}>
        <div className={classes.documentViewer}>
          <Viewer fileUrl={url} defaultScale={SpecialZoomLevel.PageWidth} />
        </div>
      </Worker>
    );
  };

  return (
    <DialogMigrate
      aria-labelledby="certificate-document-dialog"
      className={clsx({
        [classes.documentDialog]: true,
        [classes.dialogWidth80]: true,
      })}
      maxWidth="lg"
      open={showDialog}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {
        closeDialog(true);
      }}>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
        <Link
          className={classes.cancelLink}
          color="inherit"
          underline="always"
          onClick={() => {
            closeDialog(true);
          }}>
          CLOSE
        </Link>
      </DialogActions>
      <DialogContent
        className={clsx({
          [classes.videoOrDocDialogContent]: true,
        })}>
        {renderCertifcateViewer()}
      </DialogContent>
    </DialogMigrate>
  );
};

export default observer(TrainingCertificateViewDialog);
