import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { SelectDatePicker } from '../../Forms/datePicker';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

const AccidentsSearchForm: React.FC<any> = (props) => {
  const { classes, searchPage, onClose } = props;

  const [input1Ref, setInput1Focus] = UseFocus();
  const { accidentsStore: store, authStore } = useCustomStore();
  const { control, handleSubmit, watch } = useForm<{
    primaryDriverMatchStatus:
      | ''
      | 'No match'
      | 'Auto-match'
      | 'Confirmed'
      | null;
    truckMatchStatus: '' | 'No match' | 'Auto-match' | 'Confirmed' | null;
    driverName: string;
    matchedDriverId: string;
    vin: string;
    accidentNumber: string;
    accidentState: string;
    accidentDateStart: string;
    accidentDateEnd: string;
  }>({
    defaultValues: {
      accidentDateEnd: store.SearchParams.accidentDateEnd,
      accidentDateStart: store.SearchParams.accidentDateStart,
      accidentNumber: store.SearchParams.accidentNumber,
      accidentState: store.SearchParams.accidentState,
      driverName: store.SearchParams.driverName,
      matchedDriverId: store.SearchParams.matchedDriverId,
      primaryDriverMatchStatus: store.SearchParams.primaryDriverMatchStatus,
      truckMatchStatus: store.SearchParams.truckMatchStatus,
      vin: store.SearchParams.vin,
    },
  });

  const matchStatus = ['', 'No match', 'Auto-match', 'Confirmed'];

  //   const formValue = useMemo(() => getValues(), [getValues]);

  useMountEffect(setInput1Focus);

  const initSearch = useCallback(
    (data) => {
      (async () => {
        store.setShowLoader(true);
        store.resetSearchParams();
        store.resetParams();
        store.setSearchParams(data);
        await store.getList();
        store.setShowLoader(false);
        onClose();
      })();
    },
    [store, onClose],
  );

  const resetFilter = useCallback(async () => {
    store.setShowLoader(true);
    store.resetParams();
    store.resetSearchParams();
    store.resetSortParams();
    await store.getList();
    store.setShowLoader(false);
    onClose();
  }, [store, onClose]);
  return (
    <form onSubmit={handleSubmit(initSearch)} id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Primary Driver Match Status
        </Typography>
        <Grid className={classes.borderBottom} style={{ marginTop: '15px' }}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="primaryDriverMatchStatus"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={matchStatus.map((status: string) => {
                        return {
                          label: status,
                          optionClassName: classes.optionsClassName,
                          value: status,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Truck Match Status
        </Typography>
        <Grid className={classes.borderBottom} style={{ marginTop: '15px' }}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="truckMatchStatus"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={matchStatus.map((status: string) => {
                        return {
                          label: status,
                          optionClassName: classes.optionsClassName,
                          value: status,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search Driver Name
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="driverName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search Matched Simplex Driver Id
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="matchedDriverId"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search VIN
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="vin"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search Accident Number
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="accidentNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search Accident State
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="accidentState"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={authStore.statesListOption.map((state) => {
                        return {
                          label: state.fullName,
                          optionClassName: classes.optionsClassName,
                          value: state.id,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <Typography component={'h3'} className={classes.filterLabel}>
        Accident Date
      </Typography>
      <div className={clsx(classes.borderBottom, classes.dateFilter)}>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            From:
          </Typography>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10} className="date-picker">
              <Controller
                control={control}
                defaultValue=""
                name="accidentDateStart"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      value={value}
                      isRefreshValue
                      inputRefObj={ref}
                      name={name}
                      onChangeDate={(d) => {
                        const formattedValue = moment(d).format('MM/DD/YYYY');
                        onChange(formattedValue);
                      }}
                      className={classes.selectClassName}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            To:
          </Typography>

          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10} className="date-picker">
              <Controller
                control={control}
                defaultValue=""
                name="accidentDateEnd"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      value={value}
                      isRefreshValue
                      inputRefObj={ref}
                      name={name}
                      onChangeDate={(d) => {
                        const formattedValue = moment(d).format('MM/DD/YYYY');
                        onChange(formattedValue);
                      }}
                      className={classes.selectClassName}
                      minDate={
                        watch('accidentDateStart')
                          ? new Date(watch('accidentDateStart'))
                          : undefined
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
      </div>

      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              // disabled
              type="button"
              onClick={resetFilter}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            // disabled={!formValue.accidentNumber}
            className={classes.filterBtn}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(AccidentsSearchForm);
