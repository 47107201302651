import { toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useCustomStore } from '../../../../hooks';

const titleObj: Record<string, string> = {
  '/Crashes-of': 'Accidents',
  '/Inspections-of': 'Inspections',
  '/ToDos-of': 'ToDos',
  '/Violations-of': 'Violations',
};

function useTrendDetails(selectedType: string) {
  const [title, setTitle] = useState('');
  const [types, setTypes] = useState<Record<string, string>[]>();
  const [trendInfo, setTrendInfo] = useState<Record<string, string>>();

  const [gridData, setGridData] = useState<TrendGrid>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const { trendsStore: store } = useCustomStore();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const [page, setPage] = useState(0);

  const vin = searchParams.get('vin') || '';
  const driver = searchParams.get('driver') || '';
  const driverId = searchParams.get('driverId') || '';
  const unitId = searchParams.get('unitId') || '';
  const range = searchParams.get('range') || '';
  const selectedTop10Type = searchParams.get('type') || '';

  const trendType = titleObj[pathname];

  useEffect(() => {
    setTitle(
      `${titleObj[pathname]} - ` +
        (vin && `Vin #${vin}`) +
        (driver && `Driver ${driver}`) +
        (range && ` - ${range}`),
    );
  }, [driver, pathname, range, searchParams, vin]);

  useEffect(() => {
    if (unitId) {
      (async () => {
        const data = await store.getEquipmentTypesByVin(
          unitId,
          range,
          trendType === 'Accidents' ? 'Crashes' : trendType,
        );
        setTypes(toJS(data));
        setTrendInfo(toJS(store.trendInfo));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  useEffect(() => {
    if (driverId) {
      (async () => {
        const data = await store.getEquipmentTypesByDriver(
          driverId,
          range,
          trendType === 'Accidents' ? 'Crashes' : trendType,
        );
        setTypes(toJS(data));
        setTrendInfo(toJS(store.trendInfo));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId]);

  useEffect(() => {
    (async () => {
      if (selectedTop10Type && trendType) {
        const { data } = toJS(store.top10TrendingTypes);
        if (!data.length) {
          await store.top10Trendingx(
            trendType === 'Accidents'
              ? 'Crashes'
              : (trendType as Top10Trending),
          );
        }
        const actualTypes = data.map((el) => ({
          key: el.x,
          value: el.y,
          ...el,
        }));
        setTypes(actualTypes as any);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTop10Type, store.top10TrendingTypes]);

  useEffect(() => {
    (async () => {
      if (selectedType && unitId && trendType) {
        store.setShowLoader(true);
        await store.getEquipmentDataX(
          selectedType,
          range,
          {
            driver,
            unitId,
          },
          trendType === 'Accidents' ? 'Crashes' : trendType,
        );
        store.setShowLoader(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(() => {
    (async () => {
      if (selectedType && driverId && trendType) {
        const driver = driverId;
        store.setShowLoader(true);
        await store.getDriverDataX(
          selectedType,
          range,
          {
            driver,
            vin,
          },
          trendType === 'Accidents' ? 'Crashes' : trendType,
        );
        store.setShowLoader(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(() => {
    (async () => {
      if (
        trendType &&
        selectedType &&
        selectedType !== 'All' &&
        !driverId &&
        !unitId
      ) {
        store.setShowLoader(true);
        await store.getTop10TrendingDataX(
          selectedType,
          range,
          trendType === 'Accidents' ? 'Crashes' : trendType,
        );
        store.setShowLoader(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(
    () => () => {
      store.resetParams();
      store.resetSortParams();
      store.resetGridData();
      store.resetTrendsTodoFilters();
    },
    [store],
  );

  useEffect(() => {
    setGridData({
      columns: store.gridData.columns,
      rows: store.gridData.data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.GridData]);

  useEffect(() => {
    setIsLoading(store.ShowLoader);
  }, [store.ShowLoader]);

  return {
    fromDriver: !!driver,
    fromEquipment: !!vin,
    fromTop10: !vin && !driver,
    gridData,
    isLoading,
    page,
    setPage,
    store,
    title,
    trendInfo,
    types,
  };
}

export default useTrendDetails;
