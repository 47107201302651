import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Controller } from 'react-hook-form';

import { UseFocus, useMountEffect } from '../../../hooks';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type driverFormTypes = {
  classes: any;
  driverController: any;
  driverSubmit: any;
  filter: any;
  resetFilter: any;
  searchPage: any;
  setFilters: (src: any) => void;
  submitDriverFilter: any;
  driverStore: any;
};

const DriverSearchForm: React.FC<driverFormTypes> = (
  props: driverFormTypes,
) => {
  const {
    classes,
    driverController,
    driverSubmit,
    filter,
    resetFilter,
    searchPage,
    setFilters,
    submitDriverFilter,
    driverStore,
  } = props;

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  return (
    <form onSubmit={driverSubmit(submitDriverFilter)} id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Driver Name
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={driverController}
                defaultValue=""
                name="name"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={filter.name}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          name: e,
                        }));
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Simplex Driver ID
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={driverController}
                defaultValue=""
                name="simplexId"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.simplexId}
                      value={filter.simplexId}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          simplexId: e,
                        }));
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Driver Type
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={driverController}
                defaultValue=""
                name="driverType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={filter.driverType}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          driverType: e,
                        }));
                      }}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={[
                        {
                          label: 'House',
                          optionClassName: classes.optionsClassName,
                          value: 'House',
                        },
                        {
                          label: 'Owner Operator',
                          optionClassName: classes.optionsClassName,
                          value: 'Owner Operator',
                        },
                        {
                          label: 'Third Party',
                          optionClassName: classes.optionsClassName,
                          value: 'Third Party',
                        },
                      ]}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              disabled={
                !(
                  driverStore.Filters.simplexId ||
                  driverStore.Filters.name ||
                  driverStore.Filters.driverType
                )
              }
              type="button"
              onClick={resetFilter}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            disabled={!(filter.simplexId || filter.name || filter.driverType)}
            className={classes.filterBtn}
            style={{
              backgroundColor: !(
                filter.simplexId ||
                filter.name ||
                filter.driverType
              )
                ? '#d3d3d3'
                : '#DEC330',
            }}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(DriverSearchForm);
