import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { AddToFleetFormInputs } from '../../types';
import { RootStore } from '..';

export class AddToFleetApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getAddToFleet = () => {
    const {
      Get: { Endpoint, Method },
    } = ApiRoutes.AddToFleet;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint,
    });
  };
  create = (data: {
    equipment: AddToFleetFormInputs;
    accidentId?: string;
    inspectionId?: string;
    matchUnitType?: string;
  }) => {
    const {
      Create: { Endpoint, Method },
    } = ApiRoutes.AddToFleet;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: Endpoint,
    });
  };
  update = (data: any, id: any) => {
    const {
      Update: { Endpoint, Method },
    } = ApiRoutes.AddToFleet;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: Endpoint(id),
    });
  };
  getById = (id: any) => {
    const {
      GetById: { Endpoint, Method },
    } = ApiRoutes.AddToFleet;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint(id),
    });
  };
  updateFile = (data: any) => {
    const {
      UpdateFiles: { Endpoint, Method },
    } = ApiRoutes.AddToFleet;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: Endpoint,
    });
  };
}
