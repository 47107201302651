import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import TodoForm from '../../../../../components/ToDoForm';
import { useCustomStore } from '../../../../../hooks';
import { fileSize, isJsonStringify } from '../../../../../utils/helper';
import { servicesWithExpDate } from '../../../../../utils/irpServicesForm.config';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import uploadIcon from './../../../../../assets/images/upload.svg';
import useStyles from './FilesListStyles';

type FileTypes = {
  fileTypeOption?: Array<{ display?: string; name: string; value?: string }>;
  acceptMimeType?: string;
  disabled?: boolean | any;
  fields?: Array<any>;
  index?: number;
  unitId?: string;
  headingTitle?: string | any;
  uploadLabelName?: string;
  keyName?: string;
  files: Array<{
    name: string;
    size: number;
    type?: string;
    date?: string;
  }>;
  onClearFile?: (
    file: any,
    index: number,
    unitId?: string,
    keyName?: string,
    docType?: string,
  ) => void;
  onFileChange?: (src: any, docType: string) => void;
  fileSizeConvert?: boolean | any;
  selectedDocTypes?: string[];
};
const FilesList: React.FC<FileTypes> = (props: FileTypes) => {
  const {
    disabled,
    files,
    fileTypeOption,
    headingTitle = '',
    uploadLabelName = '',
    unitId,
    onFileChange,
    keyName,
    acceptMimeType = '*',
    fileSizeConvert = false,
    selectedDocTypes = [],
  } = props;
  const hiddenFileInput = React.useRef<any | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileType, setFileType] = useState<string>('');
  const [fileDisplay, setFileDisplay] = useState<string>('');
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const { equipmentStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    if (!disabled) {
      hiddenFileInput.current.click();
    }
  };
  const menuHandleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selectFileType: string, displayName?: string) => {
    if (selectFileType && !servicesWithExpDate.includes(selectFileType)) {
      hiddenFileInput.current.click();
    }
    setFileType(selectFileType);
    displayName && setFileDisplay(displayName);
    setAnchorEl(null);
  };
  const fileOnChange = (
    event: any,
    payload: { expirationDate?: string } = {},
  ) => {
    if (!disabled) {
      let file = 0;
      const fileList: Array<any> = [];
      const formData = new FormData();
      for (let index = 0; index < event.target.files.length; index++) {
        const element =
          event.target.files?.item?.(index) || event.target.files[index];
        const fsize = element.size;
        file += Math.round(fsize / 1024);
        fileList.push(element);
        formData.append(
          'f',
          element,
          JSON.stringify({
            display: fileDisplay,
            fileType: fileType ? fileType : element.type,
            name: element.name,
            ...payload,
          }),
        );
      }
      if (file > 15360) {
        enqueueSnackbar(
          'You cannot upload more than 15MB files',
          SnackBarConfig('e'),
        );
        return;
      }
      if (unitId) {
        const arr = equipmentStore.SelectedEquipments;
        const findIndex = arr.findIndex((val) => val.unitId === unitId);
        if (findIndex !== -1 && keyName) {
          const fetchName: any = keyName ? keyName : '';
          const existingFiles: Array<any> =
            arr[findIndex][fetchName] && arr[findIndex][fetchName].length
              ? arr[findIndex][fetchName]
              : [];
          arr[findIndex][fetchName] = existingFiles.concat(
            fileType ? formData.getAll('f') : fileList,
          );
          equipmentStore.selectedEquipmentRefetch(arr);
        }
      }
      onFileChange && onFileChange(event.target.files, fileType);
    }
    hiddenFileInput.current.value = '';
  };
  const clearFile = (
    file: any,
    index: number,
    unitId?: string,
    keyName?: string,
  ) => {
    if (unitId && !disabled) {
      const arr = equipmentStore.SelectedEquipments;
      const findEquip = arr.find((val: any) => val.unitId === unitId);
      if (findEquip && keyName) {
        const filesArr = findEquip[keyName];
        filesArr.splice(index, 1);
        equipmentStore.selectedEquipmentRefetch(arr);
      }
    }
  };

  return (
    <Grid container spacing={3} minHeight={'100%'}>
      {headingTitle || uploadLabelName ? (
        <Grid item md={12} sm={12} xs={12}>
          <div className={classes.gridheadingFlex}>
            <Box className={classes.formLabelClass}>{headingTitle}</Box>
            {uploadLabelName ? (
              <Box
                className={classes.uploadDoc}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={
                  fileTypeOption && fileTypeOption.length
                    ? menuHandleClick
                    : handleClick
                }>
                <img src={uploadIcon} alt="icon" />
                <span> {uploadLabelName} </span>
                {open}
              </Box>
            ) : (
              ''
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => handleClose('', '')}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              {fileTypeOption?.map((ele, index) => (
                <MenuItem
                  disabled={
                    Array.isArray(selectedDocTypes) &&
                    selectedDocTypes?.includes(ele.value as string)
                  }
                  key={index}
                  onClick={() => {
                    const nameOfFile = ele.value || ele.name;
                    handleClose(nameOfFile, ele.display);
                  }}>
                  {ele.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Grid>
      ) : (
        ''
      )}
      {files &&
        !!files.length &&
        files.map((val, index) => (
          <React.Fragment key={index}>
            <Grid item md={6} sm={5} xs={5} alignSelf={'center'}>
              <div className={classes.fileName}>
                {isJsonStringify(val.name)
                  ? JSON.parse(val.name).name
                  : val.name}
              </div>
            </Grid>
            <Grid item md={6} sm={7} xs={7} alignSelf={'center'}>
              <div className={classes.fileInfo}>
                {val.type && (
                  <span style={{ wordBreak: 'normal' }}>
                    {isJsonStringify(val.name)
                      ? JSON.parse(val.name).display ||
                        JSON.parse(val.name).fileType
                      : ''}
                  </span>
                )}
                <div>
                  <span
                    style={{
                      bottom: 6,
                      paddingRight: 4,
                      position: 'relative',
                    }}>
                    {unitId || fileSizeConvert
                      ? fileSize(String(val.size))
                      : val?.type}
                  </span>
                  {/* {val.date && <span> {val.date} </span>} */}
                  <span style={{ cursor: 'pointer' }}>
                    <ClearIcon
                      onClick={() => {
                        if (props.onClearFile && !disabled) {
                          props.onClearFile(
                            val,
                            index,
                            unitId,
                            keyName,
                            JSON.parse(val.name).fileType,
                          );
                        }
                        clearFile(val, index, unitId, keyName);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Grid>
          </React.Fragment>
        ))}
      <input
        multiple
        accept={acceptMimeType}
        type="file"
        ref={hiddenFileInput}
        name={unitId ? unitId : 'file'}
        id={unitId ? unitId : 'file'}
        style={{ display: 'none' }}
        onChange={fileOnChange}
      />
      {fileType && servicesWithExpDate.includes(fileType) && (
        <TodoForm
          preventSubmit
          onClose={({ attachment, payload }) => {
            setFileType('');
            if (attachment && payload)
              fileOnChange(
                {
                  target: {
                    files: [attachment],
                  },
                },
                payload,
              );
          }}
          variant={fileType as TodoFormVariant}
        />
      )}
    </Grid>
  );
};
export default observer(FilesList);
