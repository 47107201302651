import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, IconButton, Paper, TableCell } from '@mui/material';
import styled from '@mui/system/styled';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const getData = (dataItem = '', key_ = '') => {
  const regex = /Date$/;

  if (regex.test(key_)) {
    return moment(dataItem).format('MM/DD/YYYY');
  }
  return dataItem;
};

const CustomPaper = styled(Paper)<{ isactive: boolean }>(({ isactive }) => ({
  justifyContent: 'center',
  ...((isactive && { backgroundColor: '#F1F4F9 !important' }) || {}),
  display: 'flex',
  flexDirection: 'column',
  minHeight: 70,
  padding: 10,
}));

const Text = styled('p')(({ theme }) => ({
  color: '#241A2E',
  fontSize: 14,
}));

const PaperWrap = styled('div')(() => ({
  display: 'flex',
}));

const Violations: React.FC<any> = ({
  data,
  cells = [],
  renderChild,
  isActive = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(isActive);

  useEffect(() => {
    setIsExpanded(false);
  }, [data]);

  return (
    <TableCell
      colSpan={((cells.length as number) + (renderChild ? 1 : 0)) as number}
      padding={'none'}>
      <CustomPaper
        isactive={isExpanded}
        elevation={(isExpanded && 4) || 0}
        style={{ margin: 0, padding: 0 }}>
        <PaperWrap>
          {cells.map((item: any, index_: number) => (
            <TableCell
              key={index_}
              width={item.spacing}
              sx={{
                border: '0 !important',
                borderBottom: '0 !important',
                margin: 'auto',
                minWidth: `${item.spacing as string}px` as string,
                width: `${item.spacing as string}px` as string,
              }}>
              {item.highlight ? (
                <h4>
                  {item.name && (data[item.name] || data[item.name] === 0)
                    ? data[item.name]
                    : '-'}
                </h4>
              ) : (
                <Text>
                  {item.name && (data[item.name] || data[item.name] === 0)
                    ? getData(data[item.name], item.name)
                    : '-'}
                </Text>
              )}
            </TableCell>
          ))}
          {renderChild && (
            <TableCell
              key={cells.length}
              width={80}
              sx={{
                border: '0 !important',
                borderBottom: '0 !important',
                margin: 'auto',
                minWidth: '80px',
                width: '80px',
              }}>
              <IconButton
                size="large"
                onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableCell>
          )}
        </PaperWrap>
        {renderChild && (
          <Collapse in={isExpanded}>
            <br />
            {isExpanded && renderChild(data.violationId, data)}
          </Collapse>
        )}
      </CustomPaper>
    </TableCell>
  );
};

export default observer(Violations);
