import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';

type Props = {
  isOpen: boolean;
  body?: any;
  maxWidth?: any;
};
const LoadingModal: React.FC<Props> = ({ isOpen, body, maxWidth }) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth={maxWidth ? maxWidth : 'xs'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent sx={{ padding: '20px 16px' }}>
          <DialogContentText id="alert-dialog-description">
            <div className="loading-modal-body">
              {body ? (
                body
              ) : (
                <>
                  <p className="modal-body-loading-text">Loading...</p>
                  <p className="modal-body-loading-message">
                    Please wait till the request completes
                  </p>
                </>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default LoadingModal;
