import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import SimplexGrid from '../../../components/SimplexGrid';
import { useCustomStore } from '../../../hooks';
import MoreDetails from './MoreDetails';
import Violation from './Violation';

const headerKeys = [
  {
    label: 'Violation Unit',
    name: 'violationUnit',
  },
  {
    label: 'Violation Date',
    name: 'violationDate',
  },
  {
    label: 'Violation State',
    name: 'violationState',
  },
  {
    label: 'Report Number',
    name: 'reportNumber',
  },
  {
    label: 'BASIC Category',
    name: 'basic',
  },
  {
    label: 'Violation Code',
    name: 'violationCode',
  },
  {
    label: 'Description',
    name: 'description',
  },
  {
    label: 'Out of Service',
    name: 'outOfService',
  },
  {
    label: 'Violation Severity Weight',
    name: 'violationSeverityWeight',
  },
  {
    label: 'Weight Added for being Out of Service',
    name: 'outOfServiceWeight',
  },
  {
    label: 'Time Weight',
    name: 'timeWeight',
  },
  {
    label: 'Total Weight',
    name: 'totalWeight',
  },
  {
    label: 'Total Time Weighted Violation Weight',
    name: 'totalTimeViolationWeight',
  },
];

const applySpacing = (el: any, index: number) => {
  // const spacing = [0.9, 0.9, 1.2, 0.9, 0.9, 1.2, 0.9, 0.9, 0.9, 0.9, 0.9, 0.8];
  const spacing = [
    100, 100, 100, 150, 100, 100, 250, 100, 100, 100, 100, 100, 110, 80,
  ];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const headerKeysWithSpacing = headerKeys.map(applySpacing);
const Violations: React.FC = () => {
  const { violationsStore: store } = useCustomStore();
  const [prevSortBy, setPrevSortBy] = useState('');
  useEffect(() => {
    (async () => {
      store.setShowLoader(true);
      await store.getList();
      store.setShowLoader(false);
    })();

    return () => {
      store.resetParams();
      store.resetSearchParams();
      store.resetSortParams();
    };
  }, [store]);

  const getNextList = useCallback(() => {
    (async () => {
      store.setShowLoader(true);
      await store.getList();
      store.setShowLoader(false);
    })();
  }, [store]);

  const handleSort = useCallback(
    (e) => {
      (async () => {
        store.resetParams();
        store.setSortBy(e);
        setPrevSortBy(e);
        if (e === prevSortBy) {
          store.setSortDirection(
            (store.SortDirection &&
              (store.SortDirection === 'asc' ? 'desc' : 'asc')) ||
              'desc',
          );
        } else {
          store.setSortDirection('desc');
        }
        store.setShowLoader(true);
        await store.getList();
        store.setShowLoader(false);
      })();
    },
    [prevSortBy, store],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {store.Data.length === 0 && store.ShowLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          style={{
            overflow: 'hidden',
            padding: '2rem 2rem 0 3rem',
            position: 'relative',
          }}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{ maxHeight: 'calc(100vh - 155px)' }}
            id="violations-table">
            <InfiniteScroll
              dataLength={store.Data.length}
              style={{ overflow: 'unset' }}
              next={getNextList}
              hasMore={!!store.NextLink}
              scrollableTarget={'violations-table'}
              loader={
                store.ShowLoader && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                style={{
                  borderSpacing: '0px 5px',
                  minWidth: 700,
                  tableLayout: 'fixed',
                }}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <SimplexGrid.Header
                    height={115}
                    id="violations"
                    cells={[
                      ...headerKeysWithSpacing,
                      { noHeader: true, noSorting: true, spacing: 80 },
                    ]}
                    sortConfig={{ handleSort, store }}
                    gridHeaderStackPaddingTop="40px"
                    gridHeaderStackPaddingBottom="40px"
                  />
                </TableHead>
                <TableBody>
                  {store.Data.length > 0 ? (
                    <SimplexGrid.Body
                      id="violations"
                      data={store.Data}
                      cells={headerKeysWithSpacing}>
                      {(el: any, index: number) => (
                        <Violation
                          cells={headerKeysWithSpacing}
                          key={index}
                          data={el}
                          renderChild={(id: string) => (
                            <MoreDetails
                              showDriverInfo={true}
                              showEquipmentInfo={true}
                              id={id}
                            />
                          )}
                        />
                      )}
                    </SimplexGrid.Body>
                  ) : (
                    <TableRow style={{ height: '80px', minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={headerKeysWithSpacing.length}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
    </Box>
  );
};

export default observer(Violations);
