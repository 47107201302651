import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Popover,
  TableCell,
  Typography,
} from '@mui/material';
import styled from '@mui/system/styled';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Exclamation from '../../../assets/images/Exclamation.svg';
import GreenCheck from '../../../assets/images/GreenCheck.svg';
import X from '../../../assets/images/X.svg';
import NavRoutes from '../../../config/routes/NavRoutes';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import SelectDriver from './selectDriver';
import SelectEquipment from './selectEquipment';

type DataItem = {
  highlight: boolean;
  name: keyof InspectionData;
  spacing: number;
};

const CustomPaper = styled(Paper)<{ isactive: boolean }>(({ isactive }) => ({
  ...((isactive && { backgroundColor: '#F1F4F9 !important' }) || {}),
  padding: '10px',
}));

const Text = styled('p')(({ theme }) => ({
  color: '#241A2E',
  fontSize: 14,
}));

const SecondaryText = styled(Text)(() => ({
  margin: '5px 0',
  whiteSpace: 'nowrap',
}));

const Caption = styled(SecondaryText)(() => ({
  color: '#7a7878',
  fontSize: 11,
  margin: 0,
}));

const PaperWrap = styled('div')(() => ({
  display: 'flex',
}));

const getData = (dataItem = '', key_ = '') => {
  const regex = /Date$/;
  if (regex.test(key_)) {
    return moment(dataItem).format('MM/DD/YYYY');
  }
  return dataItem;
};

const getIconByStatus = (status: string) => {
  switch (status) {
    case 'No match':
      return <img src={X} style={{ paddingRight: '4px' }} />;
    case 'Auto-match':
      return <img src={Exclamation} style={{ paddingRight: '4px' }} />;
    case 'Confirmed':
      return <img src={GreenCheck} style={{ paddingRight: '4px' }} />;
    default:
      return;
  }
};

const showIcon = (status: string, primaryText: string) => {
  let show = false;
  switch (status) {
    case 'No match':
      show = primaryText && primaryText !== '-' ? true : false;
      break;
    case 'Auto-match':
      show = true;
      break;
    case 'Confirmed':
      show = true;
      break;
    default:
      show = false;
  }
  return show;
};

const DataCell: React.FC<{
  keyName: string;
  status?: string;
  primaryText?: string;
  secondaryText?: string;
  caption?: string;
  boldText: boolean;
}> = ({
  keyName = '',
  boldText = false,
  status = '',
  primaryText = '-',
  secondaryText = '',
  caption = '',
}) => {
  return (
    <div className="flex-row">
      {showIcon(status, primaryText) && (
        <div className="flex-column">{getIconByStatus(status)}</div>
      )}
      <div className="flex-column">
        {boldText && <h4> {getData(primaryText, keyName)} </h4>}
        {!boldText && secondaryText && (
          <Text>
            <b>{getData(primaryText, keyName)}</b>
          </Text>
        )}
        {!boldText && !secondaryText && (
          <Text>{getData(primaryText, keyName)}</Text>
        )}
        {secondaryText && (
          <SecondaryText>
            <i>{getData(secondaryText, keyName)}</i>
          </SecondaryText>
        )}
        {caption && (
          <Caption>
            <b>
              <i>{caption}</i>
            </b>
          </Caption>
        )}
      </div>
    </div>
  );
};

type Props = {
  data: InspectionData;
  cells: DataItem[];
  isManage?: boolean;
  handleMatchInfo?: (id: string) => unknown;
  renderChild?: (
    id: string,
    data: Record<string, unknown>,
  ) => React.ReactElement;
};

const Inspections: React.FC<Props> = ({
  data,
  cells = [],
  renderChild,
  isManage,
  handleMatchInfo = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDataQChallengeOption, setShowDataQChallengeOption] =
    useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isSelectEquipmentDialogOpen, setIsSelectEquipmentDialogOpen] =
    useState<boolean>(false);
  const [isSelectDriverDialogOpen, setIsSelectDriverDialogOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setShowDataQChallengeOption(checkDataQChallengeOption(data));
    setIsExpanded(false);
  }, [data]);

  const handleMenuOption = (event: any, unitId: string) => {
    const obj: any = {};
    if (tooltipIsOpen[unitId]) {
      obj[unitId] = false;
      setAnchorEl(null);
    } else {
      obj[unitId] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const checkDataQChallengeOption = (data: any) => {
    let showDataQChallenge = false;
    if (data?.violations && data?.violations.length > 0) {
      data?.violations.map((violation: any) => {
        if (violation.totalTimeViolationWeight > 0) {
          showDataQChallenge = true;
        }
      });
    }
    return showDataQChallenge;
  };

  const onNavigateToDataQChallenge = (inspectionInfo: any) => {
    if (!inspectionInfo || !inspectionInfo.inspectionId) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { violations, ...rest } = data;
    const basicInfo = { ...rest };
    navigate(
      `/${NavRoutes.PRIVATE.DATA_Q_CHALLENGE}/inspections/${
        inspectionInfo.inspectionId as string
      }`,
      {
        state: {
          basicInfo: basicInfo,
        },
      },
    );
  };

  return (
    <TableCell
      colSpan={
        ((cells.length as number) +
          (renderChild ? 1 : 0) +
          (isManage ? 1 : 0)) as number
      }
      padding={'none'}>
      <CustomPaper
        isactive={isExpanded}
        elevation={(isExpanded && 4) || 0}
        style={{ margin: 0, padding: 0 }}>
        <PaperWrap>
          {cells.map((item, index_: number) =>
            item.name && data[item.name] !== undefined ? (
              <TableCell
                width={item.spacing}
                key={index_}
                sx={{
                  border: '0 !important',
                  borderBottom: '0 !important',
                  margin: 'auto',
                  minWidth: `${item.spacing.toString()}px` as string,
                  width: `${item.spacing.toString()}px` as string,
                }}>
                {typeof data[item.name] === 'object' ? (
                  <DataCell
                    keyName={item.name}
                    boldText={item.highlight}
                    {...data[item.name]}
                  />
                ) : (
                  <DataCell
                    keyName={item.name}
                    boldText={item.highlight}
                    primaryText={data[item.name]}
                  />
                )}
              </TableCell>
            ) : (
              <TableCell
                width={item.spacing}
                key={index_}
                sx={{
                  border: '0 !important',
                  borderBottom: '0 !important',
                  margin: 'auto',
                  minWidth: `${item.spacing.toString()}px` as string,
                  width: `${item.spacing.toString()}px` as string,
                }}>
                -
              </TableCell>
            ),
          )}
          {isManage && (
            <TableCell
              width={80}
              sx={{
                border: '0 !important',
                borderBottom: '0 !important',
                margin: 'auto',
                minWidth: '80px',
                width: '80px',
              }}>
              <IconButton
                onClick={(event) => handleMenuOption(event, data.inspectionId)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                className="popclass"
                open={tooltipIsOpen[data.inspectionId]}
                onClose={(event) => handleMenuOption(event, data.inspectionId)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'bottom',
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}>
                <Box
                  sx={{
                    backgroundColor: '#241A2E',
                    borderRadius: '8px',
                    boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                    minHeight: '70.9px',
                    padding: '0.5rem 0',
                    position: 'relative',
                    width: '207px',
                  }}>
                  <div className="arrow">.</div>
                  <Typography
                    component={'div'}
                    className={'n-pop-btn'}
                    onClick={() => handleMatchInfo(data.inspectionId)}>
                    Match Info
                  </Typography>
                  {showDataQChallengeOption && (
                    <Typography
                      component={'div'}
                      className={'n-pop-btn'}
                      onClick={() => onNavigateToDataQChallenge(data)}>
                      Data Q Challenge
                    </Typography>
                  )}
                  {((data.primaryDriver.status === 'No match' &&
                    data.primaryDriver.primaryText) ||
                    (data.secondaryDriver.status === 'No match' &&
                      data.secondaryDriver.primaryText)) && (
                    <Typography
                      component={'div'}
                      className={'n-pop-btn'}
                      onClick={() => {
                        if (
                          data.primaryDriver.status === 'No match' &&
                          data.secondaryDriver.status === 'No match'
                        ) {
                          if (
                            data.primaryDriver.primaryText &&
                            data.secondaryDriver.primaryText
                          ) {
                            setIsSelectDriverDialogOpen(true);
                          } else if (data.primaryDriver.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.NEWDRIVERSETUP}`,
                              {
                                state: {
                                  details: {
                                    driverName: data.primaryDriver.primaryText,
                                    matchDriverType: 'primaryDriver',
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else if (data.secondaryDriver.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.NEWDRIVERSETUP}`,
                              {
                                state: {
                                  details: {
                                    driverName:
                                      data.secondaryDriver.primaryText,
                                    matchDriverType: 'secondaryDriver',
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else {
                            enqueueSnackbar(
                              String(
                                'Either Primary or Secondary Driver Name is required',
                              ),
                              SnackBarConfig('e'),
                            );
                          }
                        } else if (data.primaryDriver.status === 'No match') {
                          if (data.primaryDriver.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.NEWDRIVERSETUP}`,
                              {
                                state: {
                                  details: {
                                    driverName: data.primaryDriver.primaryText,
                                    matchDriverType: 'primaryDriver',
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else {
                            enqueueSnackbar(
                              String('Primary Driver Setup requires a name'),
                              SnackBarConfig('e'),
                            );
                          }
                        } else if (data.secondaryDriver.status === 'No match') {
                          if (data.secondaryDriver.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.NEWDRIVERSETUP}`,
                              {
                                state: {
                                  details: {
                                    driverName:
                                      data.secondaryDriver.primaryText,
                                    matchDriverType: 'secondaryDriver',
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else {
                            enqueueSnackbar(
                              String('Secondary Driver Setup requires a name'),
                              SnackBarConfig('e'),
                            );
                          }
                        }
                      }}>
                      New Driver Onboarding
                    </Typography>
                  )}
                  {(data.powerUnitVin.status === 'No match' ||
                    data.trailerVin.status === 'No match') && (
                    <Typography
                      component={'div'}
                      className={'n-pop-btn'}
                      onClick={() => {
                        if (
                          data.powerUnitVin.status === 'No match' &&
                          data.trailerVin.status === 'No match'
                        ) {
                          if (
                            data.powerUnitVin.primaryText &&
                            data.trailerVin.primaryText
                          ) {
                            setIsSelectEquipmentDialogOpen(true);
                          } else if (data.powerUnitVin.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ADDTOFLEET}`,
                              {
                                state: {
                                  details: {
                                    equipmentType: 'power unit',
                                    vinNumber: data.powerUnitVin.primaryText,
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else if (data.trailerVin.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ADDTOFLEET}`,
                              {
                                state: {
                                  details: {
                                    equipmentType: 'trailer',
                                    vinNumber: data.trailerVin.primaryText,
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else {
                            enqueueSnackbar(
                              String(
                                'Either Power Unit or Trailer VIN is required',
                              ),
                              SnackBarConfig('e'),
                            );
                          }
                        } else if (data.powerUnitVin.status === 'No match') {
                          if (data.powerUnitVin.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ADDTOFLEET}`,
                              {
                                state: {
                                  details: {
                                    equipmentType: 'power unit',
                                    vinNumber: data.powerUnitVin.primaryText,
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else {
                            enqueueSnackbar(
                              String('Adding Power Unit to fleet requires VIN'),
                              SnackBarConfig('e'),
                            );
                          }
                        } else if (data.trailerVin.status === 'No match') {
                          if (data.trailerVin.primaryText) {
                            navigate(
                              // eslint-disable-next-line
                              `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ADDTOFLEET}`,
                              {
                                state: {
                                  details: {
                                    equipmentType: 'trailer',
                                    vinNumber: data.trailerVin.primaryText,
                                  },
                                  from: 'inspections',
                                  id: data.inspectionId,
                                },
                              },
                            );
                          } else {
                            enqueueSnackbar(
                              String('Adding trailer to fleet requires VIN'),
                              SnackBarConfig('e'),
                            );
                          }
                        }
                      }}>
                      Add to Fleet
                    </Typography>
                  )}
                </Box>
              </Popover>
            </TableCell>
          )}
          {renderChild && (
            <TableCell
              width={80}
              sx={{
                border: '0 !important',
                borderBottom: '0 !important',
                margin: 'auto',
                minWidth: '80px',
                width: '80px',
              }}>
              <IconButton
                size="large"
                onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableCell>
          )}
        </PaperWrap>
        {renderChild && (
          <Collapse in={isExpanded}>
            {isExpanded && renderChild(data.inspectionId, data)}
          </Collapse>
        )}
        {isSelectEquipmentDialogOpen && (
          <SelectEquipment
            isOpen={isSelectEquipmentDialogOpen}
            closeDialog={({ equipmentType }: { equipmentType?: string }) => {
              setIsSelectEquipmentDialogOpen(false);
              if (equipmentType) {
                navigate(
                  // eslint-disable-next-line
                  `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ADDTOFLEET}`,
                  {
                    state: {
                      details: {
                        equipmentType: equipmentType,
                        vinNumber:
                          equipmentType === 'power unit'
                            ? data.powerUnitVin.primaryText
                            : data.trailerVin.primaryText,
                      },
                      from: 'inspections',
                      id: data.inspectionId,
                    },
                  },
                );
              }
            }}
            powerUnitVin={data.powerUnitVin.primaryText}
            trailerVin={data.trailerVin.primaryText}
          />
        )}
        {isSelectDriverDialogOpen && (
          <SelectDriver
            isOpen={isSelectDriverDialogOpen}
            closeDialog={({ driverType }: { driverType?: string }) => {
              setIsSelectDriverDialogOpen(false);
              if (driverType) {
                navigate(
                  // eslint-disable-next-line
                  `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.NEWDRIVERSETUP}`,
                  {
                    state: {
                      details: {
                        driverName:
                          driverType === 'primaryDriver'
                            ? data.primaryDriver.primaryText
                            : data.secondaryDriver.primaryText,
                        matchDriverType: driverType,
                      },
                      from: 'inspections',
                      id: data.inspectionId,
                    },
                  },
                );
              }
            }}
            primaryDriverName={data.primaryDriver.primaryText}
            secondaryDriverName={data.secondaryDriver.primaryText}
          />
        )}
      </CustomPaper>
    </TableCell>
  );
};

export default observer(Inspections);
