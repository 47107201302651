import { makeAutoObservable } from 'mobx';

import { AddToFleetFormInputs } from '../types';
import { RootStore } from '.';

export class AddToFleetStore {
  private _AddToFleetFetchValues: {
    dotFtBundleStatus: boolean;
    drivers: Array<{ fullName: string; contactId: string }>;
  } | null = null;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  get AddToFleetInfo() {
    return this._AddToFleetFetchValues;
  }

  setAddTOFleetInfo(value: any) {
    this._AddToFleetFetchValues = value;
  }

  async getPredefinedAddToFleetInfo() {
    this.setAddTOFleetInfo(null);
    const response =
      await this.rootStore.apiStore.addToFleetApiStore.getAddToFleet();
    if (response.isOk()) {
      this.setAddTOFleetInfo(response.value);
    }
    return response;
  }

  async createAddToFleet(data: {
    equipment: AddToFleetFormInputs;
    accidentId?: string;
    inspectionId?: string;
    matchUnitType?: string;
  }) {
    const response = await this.rootStore.apiStore.addToFleetApiStore.create(
      data,
    );
    return response;
  }
  async updateFleet(data: any, id: any) {
    const response = await this.rootStore.apiStore.addToFleetApiStore.update(
      data,
      id,
    );
    return response;
  }
  async getFleetById(id: any) {
    const response = await this.rootStore.apiStore.addToFleetApiStore.getById(
      id,
    );
    return response;
  }
  async updateAttachFile(data: any) {
    const response =
      await this.rootStore.apiStore.addToFleetApiStore.updateFile(data);
    return response;
  }
}
