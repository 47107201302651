import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  Link: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontSize: '15px !important',
    textDecoration: 'underline solid #0061FF',
  },
  NotBox: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.12)',
    margin: '0 0 .5rem .7rem !important',
    minHeight: '98px',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
  },
  flexA: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '0.5rem !important',
  },
  nicon: {
    height: '40px',
  },
  nonActive: {
    backgroundColor: '#F1F1F5 !important',
  },
  notDate: {
    color: '#979598 !important',
    fontFamily: 'FiraSans-Light !important',
    fontSize: '0.93rem !important',
  },
  notDis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Light !important',
    fontSize: '0.93rem !important',
    lineHeight: '18px !important',
    padding: '.7rem 0 .5rem 0',
  },
  notTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1.125rem !important',
  },
  notTopic: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1.25rem !important',
    marginBottom: '0.5rem !important',
    paddingLeft: '0.5rem !important',
  },
  padLeft: {
    paddingLeft: '0.5rem !important',
  },
  padRight: {
    paddingRight: '0.5rem !important',
  },
  padX: {
    paddingLeft: '0.5rem !important',
    paddingRight: '0.5rem !important',
  },
  requestBtn: {
    alignItems: 'center',
    border: '1px solid #CBCBCB',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '45px',
    justifyContent: 'center',
    letterSpacing: '1px !important',
    margin: '1rem 0.2rem 0 0.5rem !important',
    textTransform: 'uppercase',
    transition: '0.5s',
  },
  splitBtn: {
    display: 'flex',
  },
  updateBtn: {
    '&:hover': {
      backgroundColor: '#DEC330',
      border: '1px solid #DEC330 !important',
      color: '#ffffff !important',
    },
    alignItems: 'center',
    border: '1px solid #CBCBCB',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '45px',
    justifyContent: 'center',
    letterSpacing: '1px !important',
    margin: '1rem 0.5rem 0 0.2rem !important',
    textTransform: 'uppercase',
    transition: '0.5s',
  },
}));

export default useStyles;
