import { makeAutoObservable, toJS } from 'mobx';

import { UcrInput } from '../types';
import { RootStore } from './';

export class UcrStore {
  private _ucrValue: UcrInput = {
    permitYear: '',
    units: '',
  };
  private _initialApiData: any = {};
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  // setUcrUnit(value: string) {
  //   this._ucrValue.units = value;
  // }

  setUcrDetail(key: string, value: any) {
    this._ucrValue = {
      ...this._ucrValue,
      [key]: value,
    };
  }

  get getUcrUnit() {
    return toJS(this._ucrValue.units);
  }

  get getInitialApiReponseData() {
    return { ...toJS(this._initialApiData) };
  }

  resetUcr() {
    this._ucrValue = {
      permitYear: '',
      units: '',
    };
  }

  async addUcrRecord(todoId?: string) {
    const response = await this.rootStore.apiStore.ucrApi.addUcr({
      permitYear: this._ucrValue.permitYear,
      todoId: todoId != '' ? todoId : '',
      units: this._ucrValue.units,
    });
    return response;
  }

  async getInitialData() {
    const initialResponse = await this.rootStore.apiStore.ucrApi.getUcr();
    if (initialResponse.isOk()) {
      const { value } = initialResponse;
      this._initialApiData = value;
    }
    return initialResponse;
  }
}
