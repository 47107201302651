import NavRoutes from './routes/NavRoutes';

export const Config = {
  API_URL: process.env.REACT_APP_BASE_URL,
  APP_DECRYPT_SECRET: 'kYp3s6v9y$B&E)H@McQfThWmZq4t7w!z',
  APP_ENV: process.env.REACT_APP_ENV,
  BASE_URL: process.env.REACT_APP_BASE_URL!,
};

export const API_LIMIT = 20;
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DASHBOARD_DATE_FORMAT = 'MMM D';

export const PublicRoutes = {
  LOGIN: `/${NavRoutes.PUBLIC.LOGIN}`,
};

export const PrivateRoutes: any = {
  ACCIDENTS: `/${NavRoutes.PRIVATE.LEFT_NAV.ACCIDENTS}`,
  CRASHES_OF: `/${NavRoutes.PRIVATE.APP_NAV.CRASHES_OF}`,
  DASHBOARD: `/${NavRoutes.PRIVATE.LEFT_NAV.DASHBOARD}`,
  DATA_Q_CHALLENGE: `/${
    NavRoutes.PRIVATE.DATA_Q_CHALLENGE as string
  }/:from/:id`,
  DOCUMENTS: `/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}`,

  DOT: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.LEFT_NAV.DOT}`,

  // @ts-ignore
  DRIVERPROFILE: `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVER}/${NavRoutes.PRIVATE.DRIVERS.PROFILE}/:driverid`,
  DRIVERS: `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVERS}`,
  DRIVERSDOCUMENT: `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVERS}/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}/:id`,

  DRIVERSERVICEREQUEST: {
    PHYSICALEXAMLOCATION: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.DRIVERSERVICEREQUEST.PHYSICALEXAMLOCATION}`,
  },

  // @ts-ignore
  FLEET: `/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}`,
  FLEETDOCUMENTS: `/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}/:id`,
  INSPECTIONS_AND_VIOLATIONS: `/${NavRoutes.PRIVATE.LEFT_NAV.INSPECTIONS_AND_VIOLATIONS}`,
  INSPECTIONS_OF: `/${NavRoutes.PRIVATE.APP_NAV.INSPECTIONS_OF}`,
  NOTIFICATIONS: `/${NavRoutes.PRIVATE.LEFT_NAV.NOTIFICATIONS}`,

  PROFILE: `/${NavRoutes.PRIVATE.LEFT_NAV.PROFILE}`,

  REPORTS: `/${NavRoutes.PRIVATE.LEFT_NAV.REPORTS}`,
  SERVICEREQUEST: {
    ADDTOFLEET: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ADDTOFLEET}`,
    ANNUALREPORT: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ANNUALREPORT}`,
    BASEROUTE: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}`,
    DATAQCHALLENGE: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.DATAQCHALLENGE}`,
    DATAQCHALLENGE_BY_ID: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.DATAQCHALLENGE}/:id`,
    DOTUPDATE: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.DOTUPDATE}`,
    DRIVER: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.DRIVER}`,
    DRUGTEST_BY_ID: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.DRUGTEST}/:id`,
    EQUIPMENTINSPECTION: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.EQUIPMENTINSPECTION}`,
    FTQUARTERFILLING: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.FTQUARTERFILLING}`,
    IFTA: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.IFTA}`,
    IRPSERVICE: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.IRPSERVICE}`,
    MVR: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.MVR}`,
    MVR_BY_ID: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.MVR}/:id`,
    NEWDRIVERSETUP: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.NEWDRIVERSETUP}`,
    PSP: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.PSP}`,
    ROADTAX: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ROADTAX}`,
    STATEPERMITS: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.STATEPERMITS}`,
    TERMINATEDRIVER: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.TERMINATEDRIVER}`,
    TERMINATEDRIVER_BY_ID: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.TERMINATEDRIVER}/:id`,
    TERMINATEEQUIPMENT: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.TERMINATEEQUIPMENT}`,
    TERMINATEEQUIPMENT_BY_ID: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.TERMINATEEQUIPMENT}/:id`,
    TODO: {
      // From Todo Request
      ANNUALREPORT: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ANNUALREPORT}`,
      DOT: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.LEFT_NAV.DOT}`,
      DOTUPDATE: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.DOTUPDATE}`,
      FTQUARTERFILLING: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.FTQUARTERFILLING}`,
      IFTA: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.IFTA}`,
      IRPSERVICE: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.IRPSERVICE}`,
      MVR: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.MVR}`,
      ROADTAX: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.ROADTAX}`,
      STATEPERMITS: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.STATEPERMITS}`,
      UCR: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.UCR}`,
    },
    UCR: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.UCR}`,
  },

  // SERVICEREQUEST: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}`,
  SETTINGSROUTE: {
    COMPANYINFO: `/${NavRoutes.PRIVATE.SETTINGSROUTE.SETTINGS}/${NavRoutes.PRIVATE.SETTINGSROUTE.COMPANYINFO}`,
    NOTIFICATION: `/${NavRoutes.PRIVATE.SETTINGSROUTE.SETTINGS}/${NavRoutes.PRIVATE.SETTINGSROUTE.NOTIFICATION}`,
    SETTINGS: `/${NavRoutes.PRIVATE.SETTINGSROUTE.SETTINGS}`,
  },
  TAXPERMITS: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.LEFT_NAV.TAXPERMITS}`,
  TODOS: `/${NavRoutes.PRIVATE.LEFT_NAV.TODOS}`,
  TODOS_OF: `/${NavRoutes.PRIVATE.APP_NAV.TODOS_OF}`,
  TRAINING: {
    ASSIGNED: `/${NavRoutes.PRIVATE.LEFT_NAV.TRAINING}/${NavRoutes.PRIVATE.TRAINING.ASSIGNED}`,
    BASE_ROUTE: `/${NavRoutes.PRIVATE.LEFT_NAV.TRAINING}`,
    COMPLETED: `/${NavRoutes.PRIVATE.LEFT_NAV.TRAINING}/${NavRoutes.PRIVATE.TRAINING.COMPLETED}`,
    RECOMMENDED: {
      ACCIDENTS: `/${NavRoutes.PRIVATE.LEFT_NAV.TRAINING}/${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.BASE_ROUTE}/${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.ACCIDENTS}`,
      BASE_ROUTE: `/${NavRoutes.PRIVATE.LEFT_NAV.TRAINING}/${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.BASE_ROUTE}`,
      INSPECTIONS: `/${NavRoutes.PRIVATE.LEFT_NAV.TRAINING}/${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.BASE_ROUTE}/${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.INSPECTIONS}`,
    },
  },

  // @ts-ignore
  UPDATEFLEET: `${NavRoutes.PRIVATE.LEFT_NAV.FLEET}/${NavRoutes.PRIVATE.FLEET.UPDATE}/:fleetid`,

  USERS: `/${NavRoutes.PRIVATE.LEFT_NAV.USERS}`,
  VIOLATIONS: `/${NavRoutes.PRIVATE.LEFT_NAV.VIOLATIONS}`,
  VIOLATIONS_OF: `/${NavRoutes.PRIVATE.APP_NAV.VIOLATIONS_OF}`,
};
