import { CircularProgress, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import SimplexCard from '../../../components/SimplexCard';
import { useCustomStore } from '../../../hooks';
import { Div } from '../InspectionsAndVoilations/styles';

type Details = {
  row1: any;
  row2: any;
};

type Props = {
  id: string;
};

type Field = {
  label: string;
  value: any;
};
// const getItemTextFromArrayByCode = (
//   code: string,
//   itemsArray: any[],
//   key_ = '',
// ) => (code && itemsArray.find((item) => item.code === code)[key_]) || '';

const row1Labels = [
  'Driver DOB',
  'Driver Age',
  'Driver License State',
  'VIN',
  'License State',
  'License Number',
];
const row1Keys: string[] = [
  'driverDob',
  'driverAge',
  'driverLicenseState',
  'vinNumber',
  'licenseState',
  'licenseNumber',
];
const row2Labels = [
  'Roadway Trafficway',
  'Road Access Control',
  'Road Surface Condition',
  'Weather Condition',
  'Light Condition',
];
const row2Keys: string[] = [
  'roadwayTrafficway',
  'roadAccessControl',
  'roadSurfaceCondition',
  'weatherCondition',
  'lightCondition',
];

const convertObjectToArray = (obj: any) => {
  const values: Field[] = Object.values(obj);
  const keys = Object.keys(obj);
  return keys.map((key, index) => ({
    label: values[index].label,
    value: values[index].value || '-',
  }));
};

const CrashesDetails: React.FC<Props> = ({ id }) => {
  const {
    accidentsStore: { getMoreDetailsByAccidentId },
  } = useCustomStore();
  const [showLoader, setShowLoader] = useState<boolean>();

  const [details, setDetails] = useState<Details>();
  const getValueByKeys = (keys: string[], labels: string[], data: any) =>
    keys.reduce(
      (acc: any, key_, index: number) => ({
        ...acc,
        [key_]: { label: labels[index], value: data[key_] },
      }),
      {},
    );
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const data = await getMoreDetailsByAccidentId(id);
      const newData = {
        ...data,
        driverAge: data?.primaryDriver?.age,
        driverDob: data?.primaryDriver?.dob,
        driverLicenseState: data?.primaryDriver?.licenseState,
        licenseNumber: data?.powerUnit?.licenseNumber,
        licenseState: data?.primaryDriver?.licenseState,
        vinNumber: data?.powerUnit?.vin,
      };
      const details_ = {
        row1: getValueByKeys(row1Keys, row1Labels, newData),
        row2: getValueByKeys(row2Keys, row2Labels, data),
      };
      setShowLoader(false);
      setDetails(details_);
    })();
  }, [id, getMoreDetailsByAccidentId]);
  return (
    <Stack spacing={0.5}>
      {!showLoader && details?.row1 && (
        <SimplexCard data={convertObjectToArray(details.row1)} />
      )}
      {!showLoader && details?.row2 && (
        <SimplexCard data={convertObjectToArray(details.row2)} />
      )}

      {showLoader && (
        <Div>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </Div>
      )}
    </Stack>
  );
};

export default observer(CrashesDetails);
