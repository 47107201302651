import { makeStyles, styled } from '@mui/styles';

const useStyles = makeStyles({
  tabImageHighlight: {
    backgroundColor: '#dec330 !important',
  },
  tabs: {
    '& .MuiTab-root': {
      color: ' #979598',
      fontSize: '0.75rem',
      minWidth: 'unset',
      padding: '12px 5px',
      textAlign: 'center',
    },
    '& .MuiTab-root.Mui-selected': {
      color: ' #241a2e !important',
      fontSize: '0.75rem',
      textAlign: 'center',
    },
    // '& .MuiTabs-flexContainer': {
    //   alignItems: 'baseline',
    // },
    '& .MuiTabs-indicator': {
      backgroundColor: '#dec330',
      height: 4,
    },
    paddingLeft: '24px',
  },
  tabsHeader: {
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.75rem',
    fontWeight: 500,
    height: '40px',
    paddingTop: '0.5rem',
    textAlign: 'center',
    textTransform: 'none',
    width: '70px',
  },
  tabsImage: {
    alignItems: 'center',
    backgroundColor: '#e8e8e8',
    borderRadius: '12px',
    display: 'flex',
    height: '56px',
    justifyContent: 'center',
    width: '56px',
  },
});

const Img = styled('img')({
  display: 'block',
  // margin: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
});
export { Img, useStyles };
