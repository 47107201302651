import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import TaxPermitsNavBarList from './components/NavTabs/NavTabs';
// import { NavTabsTPTS } from '../ServiceRequest/components/NavTabs/NavTabsConstent';
import useStyles from './driverServiceRequestStyles';

type TaxPermitsTypes = {
  tabValue: string;
  childComponent?: any;
};
const DriverServiceRequest: React.FC<TaxPermitsTypes> = (
  Props: TaxPermitsTypes,
) => {
  const { tabValue } = Props;
  const classes = useStyles();

  const [value, setValue] = useState<string>('1');

  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);

  return (
    <Grid container spacing={0.5} minHeight={'100%'}>
      {/* left Side content */}
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        className={classes.padcss}
        style={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}
        minHeight={'calc(100vh - 80px)'}>
        <TabContext value={value}>
          <TaxPermitsNavBarList setValue={setValue} value={value} />
          <TabPanel value={value} className={classes.tabPanel}>
            {Props.childComponent}
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default observer(DriverServiceRequest);
