import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  checkboxLabels: {
    '&.MuiFormControlLabel-root': {
      alignItems: 'center !important',
    },
  },
  divider: {
    border: '1px solid #E8E8E8',
    marginTop: '31px !important',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  labels: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  paddingRight: {
    paddingRight: '4rem',
  },
  selectClassName: {
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    textTransform: 'capitalize',
    // opacity: '0.43 !important',
  },
}));

export default useStyles;
