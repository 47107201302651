import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import GetTheme from './GetTheme';
import typography from './Typography';

const currentTheme = window.localStorage.getItem('currentTheme') || 'light';
const palette = GetTheme(currentTheme);
let theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
  },
  palette,
  typography,
});
theme = responsiveFontSizes(theme);

export default theme;
