import { Box, Divider, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { SelectDatePicker } from '../../../../../../components/Forms/datePicker';
import { InputText } from '../../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../../components/Forms/selectInput';
import { SelectStateInput } from '../../../../../../components/Forms/selectStateDropdown';
import { UseFocus, useMountEffect } from '../../../../../../hooks';
import { RequestType } from '../../../../../../types';
import TaxPermitsEquipmentCard from '../../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
import TaxPermitsEquipmentHeader from '../../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';

type statesFormTypes = {
  classes: any;
  control: any;
  errors: any;
  formType: any;
  onSubmit: any;
  handleSubmit: any;
  reset: any;
  equipment: Array<any>;
  onselect: (src: any) => void;
  remove: (src: any) => void;
  btnDisable: any;
  fields: any;
  setValue: any;
  updateMethod: any;
  OregonStateInfo: { dotNumber: string } | null;
  getValues: any;
  dateOfEntryError: any;
  setDateOfEntryError: any;
  dateOfExistEntry: any;
  setDateOfExistEntry: any;
  dateWarning: any;
  setDateWarning: any;
};
// const isBefore = (date: any) => {
//   if (!date) {
//     return false;
//   }
//   const today = new Date();
//   today.setHours(0, 0, 0, 0);
//   return date > today;
// };
const StatePermitsOregonForm: React.FC<statesFormTypes> = (
  props: statesFormTypes,
) => {
  const {
    btnDisable,
    classes,
    control,
    errors,
    formType,
    handleSubmit,
    onSubmit,
    equipment,
    onselect,
    remove,
    reset,
    fields,
    setValue,
    updateMethod,
    OregonStateInfo,
    getValues,
    dateOfEntryError,
    setDateOfEntryError,
    dateOfExistEntry,
    setDateOfExistEntry,
    // dateWarning,
    setDateWarning,
  } = props;

  const [input1Ref, setInput1Focus] = UseFocus();
  const [input2Ref, setInput2Focus] = UseFocus();
  const [formFieldType, setFormFieldType] = useState<string>('');

  const onChangeChecking = (evnt: any) => {
    reset && reset();
    setDateWarning((pre: any) => ({
      ...pre,
      ...{ dateOfEntryWarning: '', dateOfExistWarning: '' },
    }));
    setDateOfEntryError('');
    setDateOfExistEntry('');
    setFormFieldType(evnt);
    setInput2Focus();
    setValue && setValue('requestType', evnt);
    setValue &&
      setValue(
        'dotNumber',
        OregonStateInfo && OregonStateInfo.dotNumber
          ? OregonStateInfo.dotNumber
          : '',
      );
  };

  useEffect(() => {
    setFormFieldType('');
    setValue &&
      setValue(
        'dotNumber',
        OregonStateInfo && OregonStateInfo.dotNumber
          ? OregonStateInfo.dotNumber
          : '',
      );
  }, [updateMethod, OregonStateInfo, setValue]);
  const validation = () => {
    setDateOfEntryError(
      getValues('dateOfExit') &&
        moment(moment(getValues('dateOfEntry'))).isAfter(
          moment(getValues('dateOfExit')),
        )
        ? ' Date is provided in before the date of exist'
        : '',
    );
    setDateOfExistEntry(
      getValues('dateOfEntry') &&
        moment(moment(getValues('dateOfExit'))).isBefore(
          moment(getValues('dateOfEntry')),
        )
        ? ' Date is provided in after the date of entry'
        : '',
    );
    setDateWarning((pre: any) => ({
      ...pre,
      dateOfEntryWarning:
        getValues('dateOfEntry') &&
        moment(getValues('dateOfEntry')).diff(moment(), 'hours') < 72
          ? 'Oregon Temporary Permit cannot be obtained more than 72 hours before date of entry.'
          : '',
      dateOfExistWarning:
        getValues('dateOfExit') &&
        moment(getValues('dateOfExit')).diff(
          moment(getValues('dateOfEntry')).add(1, 'day'),
          'days',
        ) < 14
          ? 'Exit date cannot be 14 days beyond date of entry'
          : '',
    }));
  };

  useMountEffect(setInput1Focus);

  return (
    <form
      style={{
        width: '100%',
      }}
      id={formType}
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
        <Grid item md={12} sm={12} xs={12}>
          <Box className={classes.labels}>New or Temporary *</Box>
          <Controller
            control={control}
            defaultValue={formFieldType}
            name="requestType"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  inputRefObj={input1Ref}
                  size={'small'}
                  errors={errors.requestType?.message || ''}
                  onChangeText={(e) => {
                    onChange(e), onChangeChecking(e);
                  }}
                  isEditable={btnDisable}
                  selectClassName={classes.selectClassName}
                  placeHolder={'Select'}
                  sortByOptionDisable={true}
                  optionsList={
                    [
                      { label: 'New', value: RequestType.New },
                      { label: 'Temporary', value: RequestType.Temporary },
                    ]
                    // <>
                    //   <option
                    //     value={RequestType.Empty}
                    //     disabled
                    //     className={classes.optionsClassName}>
                    //     Select
                    //   </option>
                    //   <option
                    //     value={RequestType.New}
                    //     className={classes.optionsClassName}>
                    //     New
                    //   </option>
                    //   <option
                    //     value={RequestType.Temporary}
                    //     className={classes.optionsClassName}>
                    //     Temporary
                    //   </option>
                    // </>
                  }
                />
              );
            }}
          />
        </Grid>
        {formFieldType === RequestType.Temporary ? (
          <>
            <Grid item md={12} sm={12} xs={12}>
              <Box className={classes.labels}>
                OR Temporary Permits are valid for 14 days and cannot be
                obtained more than 72 hours before the date of entry
              </Box>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.labels}>Route Point of Entry *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="pointOfEntry"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={input2Ref}
                      errors={errors.pointOfEntry?.message || ''}
                      name={name}
                      placeholder="City Name"
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.hideLabels}>Route</Box>
              <Controller
                control={control}
                defaultValue={''}
                name="routeEntry"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.routeEntry?.message || ''}
                      name={name}
                      placeholder="Highway"
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.labels}>Date of Entry *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="dateOfEntry"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.dateOfEntry?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      minDate={new Date()}
                      value={value}
                      onChangeDate={(e) => {
                        onChange(e);
                        validation();
                      }}
                      disabled={btnDisable}
                    />
                  );
                }}
              />
              <div className="error-msg"> {dateOfEntryError} </div>
              {/* <div
                className="error-msg"
                style={{ color: '#FFCC00', marginTop: '5px' }}>
                {dateWarning.dateOfEntryWarning}
              </div> */}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.labels}>Delivery Address *</Box>
              <Controller
                control={control}
                defaultValue={''}
                name="deliveryAddress"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.deliveryAddress?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Box className={classes.labels}>City *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="deliveryCity"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.deliveryCity?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Box className={classes.labels}>State *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="deliveryState"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectStateInput
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.deliveryState?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      size={'small'}
                      isEditable={btnDisable}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Box className={classes.labels}>ZIP *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="deliveryZip"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.deliveryZip?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      maxLengthVal="5"
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.labels}>Point of Exit *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="pointOfExit"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.pointOfExit?.message || ''}
                      name={name}
                      placeholder="City Name"
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.hideLabels}>Route</Box>
              <Controller
                control={control}
                defaultValue={''}
                name="routeExit"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.routeExit?.message || ''}
                      name={name}
                      placeholder="Highway"
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.labels}>Date of Exit *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="dateOfExit"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.dateOfExit?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      minDate={new Date()}
                      value={value}
                      onChangeDate={(e) => {
                        onChange(e);
                        validation();
                      }}
                      disabled={btnDisable}
                    />
                  );
                }}
              />
              <div className="error-msg"> {dateOfExistEntry}</div>
              {/* <div
                className="error-msg"
                style={{ color: '#FFCC00', marginTop: '5px' }}>
                {dateWarning.dateOfExistWarning}
              </div> */}
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.labels}>Miles Traveled in Oregon *</Box>
              <Controller
                control={control}
                defaultValue={undefined}
                name="milesTraveled"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.milesTraveled?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(e) =>
                        onChange(String(e).trim() ? e : null)
                      }
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'number'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.labels}>DOT *</Box>
              <Controller
                control={control}
                defaultValue={
                  OregonStateInfo && OregonStateInfo.dotNumber
                    ? OregonStateInfo.dotNumber
                    : ''
                }
                name="dotNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.dotNumber?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={
                        (OregonStateInfo && OregonStateInfo.dotNumber
                          ? true
                          : false) || btnDisable
                      }
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Box className={classes.labels}>Load Description *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="loadDescription"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.loadDescription?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TaxPermitsEquipmentHeader
                selecetedEquipment={equipment}
                onSelectEquipment={onselect}
                disabled={btnDisable}
                requestType="TPTS"
              />
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={
                !equipment || !equipment.length ? { textAlign: 'center' } : {}
              }>
              {fields.map((ele: any, index: number) => (
                <TaxPermitsEquipmentCard
                  key={index}
                  ele={ele}
                  removedCard={remove}
                  grossWeightShow={true}>
                  {ele.equipmentType === 'power unit' ? (
                    <>
                      <Divider className={classes.cardDivider} />
                      <Grid item md={6} sm={12} xs={12}>
                        <Box className={classes.labels}>Odometer *</Box>
                        <Controller
                          control={control}
                          defaultValue={undefined}
                          name={`units[${index}][odometer]`}
                          render={({
                            field: { onChange, value, name, ref },
                            formState,
                          }) => {
                            return (
                              <InputText
                                isFormSubmittedOnce={!!formState.submitCount}
                                inputRefObj={ref}
                                errors={
                                  errors.units?.[index]?.odometer?.message || ''
                                }
                                name={name}
                                isRefreshValue={true}
                                value={value}
                                onChangeText={(e) =>
                                  onChange(String(e).trim() ? e : null)
                                }
                                isEditable={btnDisable}
                                className={classes.selectClassName}
                                type={'number'}
                                variant="standard"
                              />
                            );
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  )}
                </TaxPermitsEquipmentCard>
              ))}
              {!equipment || !equipment.length ? (
                <div className={'error-msg'}>Please Select Equipment</div>
              ) : (
                ''
              )}
            </Grid>
          </>
        ) : (
          ''
        )}
        {formFieldType === RequestType.New ? (
          <Grid item md={12} sm={12} xs={12}>
            <Box className={classes.labels}>
              A Simplex Representative will contact you to discuss the process
              of obtaining the annual Oregon permit.
            </Box>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </form>
  );
};

export default observer(StatePermitsOregonForm);
