import { makeAutoObservable } from 'mobx';

import {
  // AccessTypes,
  SettingsCompanyInfo,
  SettingsNotificationInfo,
} from '../types';
import { RootStore } from '.';

export class SettingsStore {
  private _companyDetails: SettingsCompanyInfo | null = null;
  private _notifiationDetails: Array<{
    heading: string;
    subList: Array<SettingsNotificationInfo>;
  }> | null = null;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setCompanyInformation(data: SettingsCompanyInfo) {
    this._companyDetails = data;
  }

  setNotificationInformation(data: any) {
    this._notifiationDetails = data;
  }

  resetCompanyInfo() {
    this._companyDetails = null;
  }

  resetNotificationInfo() {
    this._notifiationDetails = null;
  }

  get CompanyDetails(): SettingsCompanyInfo | null {
    return this._companyDetails;
  }

  get NotificationDetails(): Array<{
    heading: string;
    subList: Array<SettingsNotificationInfo>;
  }> | null {
    return this._notifiationDetails;
  }

  async updateNotificationStatus(data: SettingsNotificationInfo) {
    const response =
      await this.rootStore.apiStore.settingsApiStore.updateNotificationStatus({
        status: data.status,
        ...(data.id ? { id: data.id } : {}),
        ...(data.subtypeId ? { subtypeId: data.subtypeId } : {}),
      });
    return response;
  }

  async companyInfo() {
    const response =
      await this.rootStore.apiStore.settingsApiStore.getCompanyInfo();
    if (response.isOk()) {
      this.setCompanyInformation(response.value);
    }
    return response;
  }

  async notificationInfo() {
    const response =
      await this.rootStore.apiStore.settingsApiStore.getNotificationInfo();
    if (response.isOk()) {
      const notificationTypes: Array<string> = [];
      const notifications: {
        heading: string;
        subList: Array<SettingsNotificationInfo>;
      }[] = [];
      if (response.value && response.value.length) {
        response.value.map((ns: SettingsNotificationInfo) => {
          if (notificationTypes.includes(ns.type)) {
            notifications[notificationTypes.indexOf(ns.type)].subList.push(ns);
          } else {
            notificationTypes.push(ns.type);
            notifications.push({
              heading: ns.type || '',
              subList: [ns],
            });
          }
        });
      }
      this.setNotificationInformation(notifications);
    }
    return response;
  }
}
