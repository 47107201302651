import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  checkBoxFlex: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
  },
  divider: {
    border: '1px solid #E8E8E8',
    // marginBottom: '15px !important',
    // marginTop: '31px !important',
  },
  fontBold: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: '500',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  infobox: {
    backgroundColor: '#F8F8FA !important',
    borderRadius: '7px',
    color: '#da5a30 !important',
    display: 'flex',
    fontSize: '0.85rem !important',
    justifyContent: 'space-between',
    lineHeight: '15px',
    marginTop: '20px',
    minHeight: '10px',
    padding: '0.5rem',
    position: 'relative',
  },
  loader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: 'calc(100vh - 200px)',
  },
  medicalCardLoader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '200px',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  primaryText: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontSize: '16px !important',
    fontWeight: 500,
    textDecoration: 'underline solid #0061ff !important',
  },
  selectClassName: {
    backgroundColor: '#f8f8fa !important',
    border: 'unset !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    letterSpacing: '0 !important',
    minHeight: '43px !important',
    width: '100%',
    // opacity: '0.43 !important',
  },
  uploadDoc: {
    '& span': {
      marginLeft: '3px',
    },
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
