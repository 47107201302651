import { makeAutoObservable } from 'mobx';

import { RootStore } from '.';

export class PrimaryDriversStore {
  private _driversList: Array<any> = [];
  private _showLoader = false;
  private _hasMoreResults = true;
  private _name = '';
  private _nextLink = '';
  private _primaryDriverName = '';

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: true });
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get DriversList() {
    return this._driversList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get PrimaryDriverName() {
    return this._primaryDriverName;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setName(value: string) {
    this._name = value;
  }

  setPrimaryDriverName(value = '') {
    this._primaryDriverName = value;
  }

  resetParamsAndData() {
    this._nextLink = '';
    this._name = '';
    this._driversList = [];
    this._primaryDriverName = '';
  }

  getList = async () => {
    const params = {
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._name ? { name: this._name } : {}),
    };
    const { response, nextLink } =
      await this.rootStore.apiStore.primaryDriversApi.getList(params);
    this._driversList = [...this._driversList, ...response];
    this._nextLink = nextLink;
  };
}
