import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { DocumentsDTO } from '../../types';
import { RootStore } from '..';

export class DocumentsApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getAdminDocumentsList = (params: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
    tags: Array<any>;
  }) => {
    const {
      getAdminList: { Endpoint, Method },
    } = ApiRoutes.Documents;
    const restParams = this.objClean({ ...params });
    return this.rootStore.apiStore.call<DocumentsDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };
  downloadDocument = (params: { id: string }) => {
    const {
      downloadDocument: { Endpoint, Method },
    } = ApiRoutes.Documents;
    return this.rootStore.apiStore.call({
      method: Method,
      params: {
        id: params.id,
      },
      url: Endpoint,
    });
  };
  getDriverDocumentsList = (
    params: {
      limit: number;
      nextLink: string;
      sortBy: string;
      sortDirection: string;
      tags: Array<any>;
    },
    id: string,
  ) => {
    const {
      getDriverList: { Endpoint, Method },
    } = ApiRoutes.Documents;
    const restParams = this.objClean({ ...params });
    return this.rootStore.apiStore.call<DocumentsDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint.concat(id),
    });
  };
  getUnitDocumentsList = (
    params: {
      limit: number;
      nextLink: string;
      sortBy: string;
      sortDirection: string;
      tags: Array<any>;
    },
    id: string,
  ) => {
    const {
      getUnitList: { Endpoint, Method },
    } = ApiRoutes.Documents;
    const restParams = this.objClean({ ...params });
    return this.rootStore.apiStore.call<DocumentsDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint.concat(id),
    });
  };
  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }
}
