import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  center: {
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      padding: 'unset !important',
    },
  },
  dialogContent: {
    '& .video': {
      width: '100%',
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '15rem',
  },
  dialogHeader: {
    '& .title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '1.1rem',
      fontWeight: 600,
      letterSpacing: '1.25px',
      lineHeight: '26px',
      textTransform: 'uppercase',
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  documentViewer: {
    height: '70vh',
    width: '100%',
  },
});
export default useStyles;
