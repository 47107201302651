import Cancel from '@mui/icons-material/Cancel';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import theme from './materialtheme';
import reportWebVitals from './reportWebVitals';
import { MAX_SNACKBARS } from './utils/SnackBarConfig';
import TokenManager from './utils/TokenManager';

const Index = (): React.ReactElement => {
  const notistackRef = React.createRef<SnackbarProvider>();

  const onClickDismiss = (key: SnackbarKey) => () => {
    if (notistackRef?.current?.closeSnackbar) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  window.localStorage.setItem('currentTheme', 'light');

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => {
            return (
              <IconButton
                style={{ color: theme.palette.common.white }}
                onClick={onClickDismiss(key)}>
                <Cancel />
              </IconButton>
            );
          }}
          maxSnack={MAX_SNACKBARS}
          hideIconVariant
          preventDuplicate>
          <TokenManager>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </TokenManager>
        </SnackbarProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
ReactDOM.render(<Index />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
