import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

type Props = {
  label: string;
  data: DataPoint[];
  onClick: (data: any, index: number) => unknown;
  xAxislabelPrefix?: string;
};

const ColumnChart: React.FC<Props> = ({
  label,
  data,
  onClick,
  xAxislabelPrefix,
}) => {
  const maximum = useMemo(() => {
    const allValues = data.map((el) => el.y);
    const sortedValues = allValues.sort((a, b) => a - b);
    return sortedValues[sortedValues.length - 1];
  }, [data]);

  return (
    <Chart
      options={{
        chart: {
          events: {
            dataPointSelection: (e, chart?, options?) => {
              const {
                data: { twoDSeriesX },
              } = chart;
              const { dataPointIndex } = options;
              onClick(twoDSeriesX[dataPointIndex], dataPointIndex);
            },
          },
          // height: '100%',
          redrawOnWindowResize: true,

          toolbar: {
            show: false,
          },
          type: 'bar',
          width: '100%',
        },
        colors: ['#a70d0d'],
        plotOptions: {
          bar: {
            columnWidth: '90%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          labels: {
            formatter: (value) => {
              const newValue =
                value.length > 15 ? `${value.substring(0, 12)} ...` : value;
              return xAxislabelPrefix && value !== ''
                ? `${xAxislabelPrefix}${newValue}`
                : newValue;
            },
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: '10px',
              fontWeight: 'bolder',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          crosshairs: {
            show: true,
          },
          ...(maximum < 10 ? { max: 10 } : {}),
          labels: {
            // formatter: (val) => {
            //   return Math.floor(val).toString();
            // },
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
          tickAmount: 6,
          title: {
            style: {
              color: '#B1B4BE',
              fontFamily: 'FiraSans-Regular',
              fontSize: '14px',
            },
            text: `Total ${label}`,
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
      series={[
        {
          data,
        },
      ]}
      type="bar"
      height="100%"
    />
  );
};

export default ColumnChart;
