import physicalExamLocationActiveLogo from '../../../../../assets/images/DriverServiceRequest/a-physical-exam-location.svg';
import physicalExamLocationLogo from '../../../../../assets/images/DriverServiceRequest/n-physical-exam-location.svg';
import { PrivateRoutes } from '../../../../../config';
import { customNavTabs } from '../../../../../types';
import addtofleet from './../../../../../assets/images/dot/add-to-fleet.svg';
import addtofleetactive from './../../../../../assets/images/dot/add-to-fleet-active.svg';
import dataq from './../../../../../assets/images/dot/data-q.svg';
import dataqactive from './../../../../../assets/images/dot/data-q-active.svg';
import equipmentinspection from './../../../../../assets/images/dot/equipment-inspection.svg';
import equipmentinspectionactive from './../../../../../assets/images/dot/equipment-inspection-active.svg';
import mvr from './../../../../../assets/images/dot/mvr.svg';
import mvractive from './../../../../../assets/images/dot/mvr-active.svg';
import newdriver from './../../../../../assets/images/dot/new-driver.svg';
import newdriveractive from './../../../../../assets/images/dot/new-driver-active.svg';
import terminatedriver from './../../../../../assets/images/dot/terminate-driver.svg';
import terminatedriveractive from './../../../../../assets/images/dot/terminate-driver-active.svg';
import terminateequipment from './../../../../../assets/images/dot/terminate-equipment.svg';
import terminateequipmentactive from './../../../../../assets/images/dot/terminate-equipment-active.svg';

const {
  MVR,
  NEWDRIVERSETUP,
  ADDTOFLEET,
  EQUIPMENTINSPECTION,
  DATAQCHALLENGE,
  TERMINATEDRIVER,
  TERMINATEEQUIPMENT,
} = PrivateRoutes.SERVICEREQUEST;

const {
  DRIVERSERVICEREQUEST: { PHYSICALEXAMLOCATION },
} = PrivateRoutes;

export const DotTabsList: customNavTabs[] = [
  {
    activeIcon: physicalExamLocationActiveLogo,
    activeTabKey: 'physicalExamLocator',
    filter: false,
    icon: physicalExamLocationLogo,
    index: '11',
    isDisabled: false,
    route: PHYSICALEXAMLOCATION,
    shortkey: 'physical-exam-location',
    tab: true,
    title: 'Physical Exam Location',
  },
  {
    activeIcon: mvractive,
    activeTabKey: 'mvr',
    filter: true,
    icon: mvr,
    index: '1',
    isDisabled: false,
    route: MVR,
    shortkey: 'Motor Vehicle Record',
    tab: true,
    title: 'MVR',
  },
  {
    activeIcon: newdriveractive,
    activeTabKey: 'newDriverSetup',
    filter: true,
    icon: newdriver,
    index: '5',
    isDisabled: false,
    route: NEWDRIVERSETUP,
    shortkey: 'Driver Onboarding',
    tab: true,
    title: 'New Driver Setup',
  },
  {
    activeIcon: addtofleetactive,
    activeTabKey: 'addToFleet',
    filter: true,
    icon: addtofleet,
    index: '6',
    isDisabled: false,
    route: ADDTOFLEET,
    shortkey: 'Add to Fleet',
    tab: true,
    title: 'Add to Fleet',
  },
  {
    activeIcon: equipmentinspectionactive,
    activeTabKey: 'equipmentInspection',
    filter: false,
    icon: equipmentinspection,
    index: '7',
    isDisabled: false,
    route: EQUIPMENTINSPECTION,
    shortkey: 'Vehicle Inspection',
    tab: true,
    title: 'Equipment Inspection',
  },
  {
    activeIcon: dataqactive,
    activeTabKey: 'dataQ',
    filter: true,
    icon: dataq,
    index: '8',
    isDisabled: false,
    route: DATAQCHALLENGE,
    shortkey: 'Data Q Challenge',
    tab: true,
    title: 'Data Q Challenge',
  },
  {
    activeIcon: terminatedriveractive,
    activeTabKey: 'terminateDriver',
    filter: true,
    icon: terminatedriver,
    index: '9',
    isDisabled: false,
    route: TERMINATEDRIVER,
    shortkey: 'Driver Termination',
    tab: true,
    title: 'Terminate Driver',
  },
  {
    activeIcon: terminateequipmentactive,
    activeTabKey: 'terminateEquipment',
    filter: true,
    icon: terminateequipment,
    index: '10',
    isDisabled: false,
    route: TERMINATEEQUIPMENT,
    shortkey: 'Equipment Termination',
    tab: true,
    title: 'Terminate Equipment',
  },
];

// export const OwnerOperatortabList: customNavTabs[] = [
//   {
//     activeIcon: addtofleetactive,
//     activeTabKey: 'addToFleet',
//     filter: true,
//     icon: addtofleet,
//     index: '2',
//     route: ADDTOFLEET,
//     shortkey: 'Add to Fleet',
//     tab: true,
//     title: 'Add to Fleet',
//   },
//   {
//     activeIcon: equipmentinspectionactive,
//     activeTabKey: 'equipmentInspection',
//     filter: false,
//     icon: equipmentinspection,
//     index: '3',
//     route: EQUIPMENTINSPECTION,
//     shortkey: 'Vehicle Inspection',
//     tab: true,
//     title: 'Equipment Inspection',
//   },
//   {
//     activeIcon: terminateequipmentactive,
//     activeTabKey: 'terminateEquipment',
//     filter: true,
//     icon: terminateequipment,
//     index: '4',
//     route: TERMINATEEQUIPMENT,
//     shortkey: 'Equipment Termination',
//     tab: true,
//     title: 'Terminate Equipment',
//   },
// ];
