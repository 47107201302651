import { makeAutoObservable } from 'mobx';
import Queue from 'promise-queue';

import { GetFileInput } from '../types';
import { RootStore } from '.';

Queue.configure(Promise);
const maxPendingPromises = 10; // 10 concurrent files to load at once
const maxQueuedPromises = Infinity;

export class FileStore {
  private _filePublicUrlListItem: { [id: string]: string[] } = {};
  private _showLoader = false;
  private _queue = new Queue(maxPendingPromises, maxQueuedPromises);
  private _filesEquipments: Array<{ unitId: string; files: Array<File> }> = [];

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setFilePublicUrlListById(data: string[], id: string) {
    this._filePublicUrlListItem[id] = data;
  }

  setFilePublicUrlListItem(img: string, id: string) {
    if (typeof this._filePublicUrlListItem[id] === 'undefined') {
      this._filePublicUrlListItem[id] = [img];
    } else {
      this._filePublicUrlListItem[id] = [
        ...this._filePublicUrlListItem[id],
        img,
      ];
    }
  }

  setFileEquipment(files: Array<any>, unitId: string) {
    const arr = [...this._filesEquipments];
    const index = arr.findIndex((e: any) => e.unitId === unitId);
    if (index !== -1) {
      arr[index].files = arr[index].files.concat(files);
    } else {
      arr.push({ files, unitId });
    }
    this._filesEquipments = arr;
  }
  get ShowLoader() {
    return this._showLoader;
  }

  get FilePublicUrlListItem() {
    return this._filePublicUrlListItem;
  }

  get filesEquipment() {
    return this._filesEquipments;
  }

  getImage(urlPath: string, options: GetFileInput) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return this._queue.add(async function () {
      const response = await that.rootStore.apiStore.fileApi.getFile(
        urlPath,
        options,
      );
      return response;
    });
  }

  async getAllImageUrls(urlPath: string, id: string) {
    // get public image url
    const response = await this.getImage(urlPath, {
      noRedirect: true,
      size: 'xlarge',
    });
    if (response.isOk()) {
      this.setFilePublicUrlListItem(response.value.url, id);
    }
    return response;
  }

  async getAllJobsFilePublicUrls(urlPath: string) {
    // get public image url
    const response = await this.getImage(urlPath, {
      noRedirect: true,
    });
    if (response.isOk()) {
      return response.value.url;
    }
    return;
  }
  async postFile(formData: FormData) {
    const response = await this.rootStore.apiStore.fileApi.uploadFile(formData);
    return response;
  }
}
