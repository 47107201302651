import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomStore } from '../../hooks';
import TerminationContracts from '../../screens/private/Todos/TerminationContracts';
import { consentAcknowledgmentTermination } from '../../utils/config';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from '../SelectEquipmentModal/EquipmentDialogStyles';

const useFormStyles = makeStyles(() => ({
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 2,
  },
}));

// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
//   '&:hover': {
//     backgroundColor: '#241A2E',
//   },
//   backgroundColor: '#241A2E',
//   borderRadius: '8px',
//   color: theme.palette.getContrastText('#241A2E'),
//   height: '44px',
// }));

const LightColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #CBCBCB',
  borderRadius: '8px',
  boxSizing: 'border-box',
  color: theme.palette.getContrastText('#FFFFFF'),
  height: '45px',
  width: '116px',
}));

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '2px 0',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  maxWidth: 400,
  padding: theme.spacing(2),
}));

const TerminationAcknowledgment: React.FC<any> = (props: any) => {
  const { onClose, todoId, requestType, caseId, todoCategoryType } = props;

  const classes = useStyles();
  const formStyles = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [todoComplete, setTodoComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const { todoStore } = useCustomStore();
  const [showContract, setShowContract] = useState<boolean>(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   enqueueSnackbar(
  //     'Todo Acknowledgment Updated Successfully',
  //     SnackBarConfig('s'),
  //   );
  // }, [todoId, requestType, enqueueSnackbar]);

  const closeContractDialogWindow = () => {
    setShowContract(false);
  };

  const closeDialogWindow = () => {
    onClose({});
  };

  const handleSubmitForAcknowledgmentForm = async () => {
    setIsLoading(true);
    try {
      if (todoComplete && !!name && !!jobTitle) {
        const payload: acknowledgmentFormPayload = {
          caseId: caseId,
          consent: true,
          name: name,
          position: jobTitle,
          terminationType:
            todoCategoryType === 'Unit Termination Acknowledgment Form'
              ? 'UnitTermination'
              : 'DriverTermination',
          todoId: todoId,
        };
        const response = await todoStore.submitTodoAcknowledgmentForm(payload);
        if (response)
          enqueueSnackbar('To-Do Completed Successfully!', SnackBarConfig('s'));
        else enqueueSnackbar('To-Do Failed update', SnackBarConfig('e'));
        setIsLoading(false);
        closeDialogWindow();
        navigate('/');
      }
    } catch (error) {
      closeDialogWindow();
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <DialogMigrate
      aria-labelledby=""
      className={classes.dialog}
      maxWidth="md"
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={closeDialogWindow}>
      <DialogTitle>
        <Typography>Termination Acknowledgment Form</Typography>
      </DialogTitle>
      {isLoading && (
        <div className={formStyles.overlay}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      )}
      <>
        <DialogContent id="scroll">
          <Box
            sx={{
              borderRadius: 2,
              marginBottom: '8px',
              width: '100%',
            }}>
            <TerminationContracts
              todoType={
                todoCategoryType === 'Unit Termination Acknowledgment Form'
                  ? 'Unit'
                  : 'Driver'
              }
              showDialog={showContract}
              handleShowDialog={closeContractDialogWindow}
            />
            <StyledPaper
              sx={{
                background: '#eee',
                borderColor: '#a6a6a6',
                margin: '2px',
                maxWidth: '85%',
                mx: 'auto',
                my: 1,
                p: 2,
              }}>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                sx={{
                  borderBottom: '2px',
                  borderBottomWidth: '2px',
                  marginBottom: '2px',
                }}>
                <Grid item>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setShowContract(true);
                    }}>
                    Termination Acknowledgment Form
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                sx={{ marginBottom: '20px' }}>
                <Grid item>
                  <Checkbox
                    checked={todoComplete}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 35 },
                      '&.Mui-checked': {
                        color: green[500],
                      },
                    }}
                    onChange={(event) => {
                      setTodoComplete(event.target.checked);
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography align="justify">
                    I,<b> {name}</b>, hereby Acknowledgment that i have read and
                    understand the terms outlined in the
                    {requestType === ''
                      ? consentAcknowledgmentTermination.DTAF
                      : consentAcknowledgmentTermination.UTAF}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs>
                  <TextField
                    required
                    disabled={!todoComplete}
                    id="nameConsent"
                    label="Name"
                    value={name}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    required
                    disabled={!todoComplete}
                    id="jobTitleConsent"
                    label="Job Title"
                    value={jobTitle}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setJobTitle(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                style={{ paddingTop: '4px' }}>
                <Grid item xs>
                  {!todoComplete && (
                    <p style={{ color: red[500], fontSize: '9pt' }}>
                      *Please indicate that you have read and agree to the
                      Acknowledgment Form
                    </p>
                  )}
                  {(!name || !jobTitle) && (
                    <p style={{ color: red[500], fontSize: '9pt' }}>
                      *Please enter Name and Job title
                    </p>
                  )}
                </Grid>
              </Grid>
            </StyledPaper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid sm={6} lg={6}>
              <LightColorButton
                type={'button'}
                variant="contained"
                sx={{ alignItems: 'center', width: '90%' }}
                onClick={() => closeDialogWindow()}>
                <Typography
                  sx={{
                    color: '#000000',
                    fontFamily: 'FiraSans-Medium',
                    fontSize: '0.9375rem',
                    fontWeight: '500',
                    height: '22px',
                    letterSpacing: '1px',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  Cancel
                </Typography>
              </LightColorButton>
            </Grid>
            <Grid sm={6} lg={6}>
              <BottomButton
                type={'button'}
                variant="contained"
                sx={{ alignItems: 'center', width: '90%' }}
                onClick={() => handleSubmitForAcknowledgmentForm()}>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontFamily: 'FiraSans-Medium',
                    fontSize: '0.9375rem',
                    fontWeight: '500',
                    height: '22px',
                    letterSpacing: '1px',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  Submit Todo
                </Typography>
              </BottomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    </DialogMigrate>
  );
};

export default observer(TerminationAcknowledgment);
