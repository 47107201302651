import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './TrainingDismissDialogStyles';

const DismissButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#A70D0D',
  },
  backgroundColor: '#A70D0D',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#A70D0D'),
  height: '48px',
  width: '170px',
}));

type TrainingDismissDialogProps = {
  isOpen: boolean;
  loader: boolean;
  assignment: any;
  onDismissTraining: (id: string) => void;
  closeDialog: () => void;
};

const TrainingDismissDialog: React.FC<any> = (
  props: TrainingDismissDialogProps,
) => {
  const classes = useStyles();
  const { isOpen, closeDialog, loader, onDismissTraining, assignment } = props;

  return (
    <DialogMigrate
      aria-labelledby="training-dismiss-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={closeDialog}>
      <DialogTitle>
        <div className={classes.title}>
          Are you sure you want to dismiss this assignment?
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.subTitle}>
          This will be removed from the Drivers To-Do items. You will not be
          able to undo this once submitted.
        </div>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.dialogAction}>
        <Link
          className={classes.cancelLink}
          color="inherit"
          underline="always"
          onClick={() => {
            if (loader) return;
            closeDialog();
          }}>
          NO, CANCEL
        </Link>
        <DismissButton
          type={'button'}
          variant="contained"
          disabled={loader}
          onClick={() => onDismissTraining(assignment)}>
          <Typography className={classes.dismissBtn}>
            {loader ? (
              <CircularProgress size={20} sx={{ color: '#A70D0D' }} />
            ) : (
              'YES, DISMISS'
            )}
          </Typography>
        </DismissButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(TrainingDismissDialog);
