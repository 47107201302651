import { makeAutoObservable, toJS } from 'mobx';

import { TrendTodoSearchFilters } from '../types';
import { RootStore } from '.';

const violationColumns = [
  {
    label: 'Violation Unit',
    name: 'violationUnit',
  },
  {
    label: 'Violation Date',
    name: 'violationDate',
  },
  {
    label: 'Violation State',
    name: 'violationState',
  },
  {
    label: 'Report Number',
    name: 'reportNumber',
  },
  {
    label: 'Primary Driver',
    name: 'primaryDriverFullName',
  },
  {
    label: 'Secondary Driver',
    name: 'secondaryDriverFullName',
  },
  {
    label: 'BASIC Category',
    name: 'basic',
  },
  {
    label: 'Violation Code',
    name: 'violationCode',
  },
  {
    label: 'Description',
    name: 'description',
  },
  {
    label: 'Out of Service',
    name: 'outOfService',
  },
  {
    label: 'Violation Severity Weight',
    name: 'violationSeverityWeight',
  },
  {
    label: 'Weight Added for being Out of Service',
    name: 'outOfServiceWeight',
  },
  {
    label: 'Time Weight',
    name: 'timeWeight',
  },
  {
    label: 'Total Weight',
    name: 'totalWeight',
  },
  {
    label: 'Total Time Weighted Violation Weight',
    name: 'totalTimeViolationWeight',
  },
];

const todoColumns = [
  {
    label: 'To-Do',
    name: 'title',
  },
  {
    label: 'Category',
    name: 'category',
  },
  {
    label: 'Due Date',
    name: 'expirationDate',
  },
  {
    label: 'Due In',
    name: 'dueIn',
  },
  {
    label: 'Actions',
  },
];

const inspectionColumns = [
  {
    label: 'Inspection Date',
    name: 'inspectionDate',
  },
  {
    label: 'Inspection State',
    name: 'inspectionState',
  },
  {
    label: 'Report Number',
    name: 'reportNumber',
  },
  {
    label: 'Inspection Level',
    name: 'inspectionLevel',
  },
  {
    label: 'Primary Driver',
    name: 'primaryDriver',
  },
  {
    label: 'Secondary Driver',
    name: 'secondaryDriver',
  },
  {
    label: 'Power Unit VIN',
    name: 'powerUnitVin',
  },
  {
    label: 'Trailer VIN',
    name: 'trailerVin',
  },
  {
    label: 'Total # of Violations in inspection',
    name: 'noOfViolations',
  },
  {
    label: 'Out Of Service',
    name: 'outOfService',
  },
  {
    label: 'Total Weight of Inspection Violation',
    name: 'totalWeight',
  },
];

const crashesColumnsX = [
  {
    label: 'State',
    name: 'state',
  },
  {
    label: 'Accident Date',
    name: 'accidentDate',
  },
  { label: 'Accident Number', name: 'accidentNumber' },
  { label: 'Fatalities', name: 'fatalities' },
  { label: 'Injuries', name: 'injuries' },
  { label: 'TowAway', name: 'towAway' },
  { label: 'Hm Released', name: 'hmReleased' },
  { label: 'Citation Released', name: 'citationReleased' },
  { label: 'Non Preventable Flag', name: 'notPreventableFlag' },
  { label: 'Severity Weight', name: 'severityWeight' },
  { label: 'Time Weight', name: 'timeWeight' },
  { label: 'Time Severity Weight', name: 'timeSeverityWeight' },
  { label: 'Primary Driver', name: 'primaryDriver' },
  { label: 'Power Unit Vin', name: 'powerUnitVin' },
];

function transformResponse(
  res: any,
  type: Top10Trending,
  skipMapping = false,
): TrendCardData {
  if (res.isOk()) {
    const { value } = res;
    const trends = (value?.data as Trend[]) || [];
    const temp = trends?.map((el) => ({
      x: el.key,
      y: el.value,
      ...(el?.keyDescription ? { keyDescription: el.keyDescription } : {}),
      ...(el?.keyCode !== undefined ? { keyCode: `${el.keyCode}` } : {}),
    })) as DataPoint[];

    if (temp.length && temp.length < 10 && !skipMapping) {
      const arr: DataPoint[] = [];
      let i = 0;
      while (i < 10) {
        if (temp[i]) arr.push(temp[i]);
        else arr.push({ x: '', y: 0 });
        i++;
      }
      return {
        data: arr,
        topCount: value?.top10Count || 0,
        totalCount: value?.totalCount || 0,
      };
    } else
      return {
        data: temp,
        topCount: value?.top10Count || 0,
        totalCount: value?.totalCount || 0,
      };
  } else return { data: [], topCount: 0, totalCount: 0 };
}
export class TrendsStore {
  private _limit = 20;
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';
  private _sortBy = '';
  private _sortDirection = '';
  private _description = '';

  private _totalCount = 0;

  private _typeId = '';
  private _range = '';
  private _trendType = '';
  private _data: Record<string, string> = {};

  private _rows: Record<string, string>[] = [];

  private _trendsTodoFilters: TrendTodoSearchFilters = {
    firstName: '',
    lastName: '',
    make: '',
    searchType: '',
    simplexId: '',
    todoCategory: [],
    todoDueIn: '',
    todoTrainingAssignedTo: '',
    todoTrainingReason: '',
    todoType: '',
    unitNumber: '',
    vinNumber: '',
    year: '',
  };

  searchParams: Record<string, string> = {};

  trendInfo: Record<string, string> = {};

  trendHistory: Record<string, Record<string, string>> = {};

  equipmentTrends: Partial<Record<Top10Trending, TrendCardData>> = {};
  driverTrends: Partial<Record<Top10Trending, TrendCardData>> = {};
  top10Trends: Partial<Record<Top10Trending, TrendCardData>> = {};

  range = '';

  topTenDrivers: any[] = [];
  topTenUnits: any[] = [];

  top10TrendingTypes: TrendCardData = { data: [], topCount: 0, totalCount: 0 };

  gridData: {
    columns: Record<string, string>[];
    data: Record<string, any>[];
  } = { columns: [], data: [] };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  setSearchParams(params: Record<string, string>) {
    this.searchParams = params;
  }

  setTrendsTodoFilters(filtersData: TrendTodoSearchFilters) {
    this._trendsTodoFilters = {
      ...this._trendsTodoFilters,
      ...filtersData,
    };
  }
  resetTrendsTodoFilters() {
    this.setTrendsTodoFilters({
      firstName: '',
      lastName: '',
      make: '',
      searchType: '',
      simplexId: '',
      todoCategory: [],
      todoDueIn: '',
      todoTrainingAssignedTo: '',
      todoTrainingReason: '',
      todoType: '',
      unitNumber: '',
      vinNumber: '',
      year: '',
    });
  }
  get TrendsTodoFilters() {
    return this._trendsTodoFilters;
  }

  setTrendHistory = (trendTab: string, history: Record<string, string>) => {
    this.trendHistory = {
      ...toJS(this.trendHistory),
      [trendTab]: history,
    };
  };

  setRows(data: any[]) {
    this._rows = data;
  }

  resetSortParams() {
    this._sortBy = '';
    this._sortDirection = '';
  }

  resetGridData() {
    this.gridData = {
      columns: [],
      data: [],
    };
    this._rows = [];
  }

  get NextLink() {
    return this._nextLink;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get GridData() {
    return this.gridData;
  }

  get TotalCount() {
    return this._totalCount;
  }

  resetParams() {
    this._nextLink = '';
    this.searchParams = {};
  }

  top10Equipment = async (
    type: Top10Trending,
    range: string,
  ): Promise<TrendCardData> => {
    if (this.equipmentTrends[type]) {
      return this.equipmentTrends[type] as TrendCardData;
    }
    const res = await this.rootStore.apiStore.trendsApi.getTop10Trending(
      'Equipment',
      type,
      range,
    );
    if (res.isOk()) {
      this.topTenUnits = res.value.data;
    }
    return transformResponse(res, type);
  };

  top10Driver = async (
    type: Top10Trending,
    range: string,
  ): Promise<TrendCardData> => {
    if (this.driverTrends[type]) {
      return this.driverTrends[type] as TrendCardData;
    }
    const res = await this.rootStore.apiStore.trendsApi.getTop10Trending(
      'Driver',
      type,
      range,
    );
    if (res.isOk()) {
      this.topTenDrivers = res.value.data;
    }
    return transformResponse(res, type);
  };

  top10Trendingx = async (
    type: Top10Trending,
    dataType = '',
  ): Promise<TrendCardData> => {
    if (this.top10Trends[type]) {
      return this.top10Trends[type] as TrendCardData;
    }
    const res = await this.rootStore.apiStore.trendsApi.getTop10Trending(
      'Top 10 Trending',
      type,
      dataType,
    );

    if (res.isOk()) {
      this.top10TrendingTypes = transformResponse(res, type, true);
      return this.top10TrendingTypes;
    }
    return { data: [], topCount: 0, totalCount: 0 };
  };

  top10Trending: Partial<Record<TrendType, any>> = {
    Driver: this.top10Driver,
    Equipment: this.top10Equipment,
    'Top 10 Trending': this.top10Trendingx,
  };

  getEquipmentTypesByVin = async (vin: string, range: string, type: string) => {
    const res = await this.rootStore.apiStore.trendsApi.getEquipmentTypesByVin(
      vin,
      range,
      type,
    );
    if (res.isOk()) {
      const { response, unit = {} } = res?.value || {};
      this.trendInfo = unit || {};
      return (response as Record<string, string>[]) || [];
    } else return [];
  };

  getEquipmentTypesByDriver = async (
    driver: string,
    range: string,
    type: string,
  ) => {
    const res =
      await this.rootStore.apiStore.trendsApi.getEquipmentTypesByDriver(
        driver,
        range,
        type,
      );
    if (res.isOk()) {
      const { response, driver = {} } = res?.value || {};
      this.trendInfo = driver || {};

      return (response as Record<string, string>[]) || [];
    } else return [];
  };

  getEquipmentDataX = async (
    typeId = this._typeId,
    range = this._range,
    data = this._data,
    trendType = this._trendType,
  ) => {
    let columns: any[] = [];
    if (trendType === 'Violations') {
      columns = violationColumns;
    }
    if (trendType === 'Crashes') {
      columns = crashesColumnsX;
    }
    if (trendType === 'ToDos') {
      columns = todoColumns;
    }
    if (trendType === 'Inspections') {
      columns = inspectionColumns;
    }
    this.gridData = { columns, data: [] };

    this._data = data;
    this._typeId = typeId;
    this._trendType = trendType;
    this._range = range;

    const queryParams = {
      dataType: range,
      type: trendType,
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._description ? { description: this._description } : {}),
      ...this.searchParams,
    };
    const res = await this.rootStore.apiStore.trendsApi.getEquipmentRecords(
      data.unitId,
      typeId,
      queryParams,
    );
    if (res.isOk()) {
      this._nextLink = res?.value?.nextLink;
      this._totalCount = res?.value?.count;
      this.gridData = {
        columns,
        data: [...this.gridData.data, ...res.value.response],
      };
      this._rows = [...this._rows, ...res.value.response];
    } else this.gridData = { columns: [], data: [] };
  };

  getDriverDataX = async (
    typeId = this._typeId,
    range = this._range,
    data = this._data,
    trendType = this._trendType,
  ) => {
    let columns: any[] = [];
    if (trendType === 'Violations') {
      columns = violationColumns;
    }
    if (trendType === 'Crashes') {
      columns = crashesColumnsX;
    }
    if (trendType === 'ToDos') {
      columns = todoColumns;
    }
    if (trendType === 'Inspections') {
      columns = inspectionColumns;
    }
    this.gridData = { columns, data: [] };

    this._data = data;
    this._typeId = typeId;
    this._trendType = trendType;
    this._range = range;

    const queryParams = {
      dataType: range,
      type: trendType,
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._description ? { description: this._description } : {}),
      ...this.searchParams,
    };
    const res = await this.rootStore.apiStore.trendsApi.getDriverRecords(
      data.driver,
      typeId,
      queryParams,
    );
    if (res.isOk()) {
      this._nextLink = res?.value?.nextLink;
      this._totalCount = res?.value?.count;
      this.gridData = {
        columns,
        data: [...this.gridData.data, ...res.value.response],
      };
      this._rows = [...this._rows, ...res.value.response];
    } else this.gridData = { columns: [], data: [] };
  };

  getTop10TrendingDataX = async (
    typeId = this._typeId,
    dataType = this._range,
    trendType = this._trendType,
  ) => {
    let columns: any[] = [];
    if (trendType === 'Violations') {
      columns = violationColumns;
    }
    if (trendType === 'Crashes') {
      columns = crashesColumnsX;
    }
    if (trendType === 'ToDos') {
      columns = todoColumns;
    }
    if (trendType === 'Inspections') {
      columns = inspectionColumns;
    }
    this.gridData = { columns, data: [] };

    this._typeId = typeId;
    this._trendType = trendType;
    this._range = dataType;

    const queryParams = {
      dataType,
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._description ? { description: this._description } : {}),
      ...this.searchParams,
    };
    const res = await this.rootStore.apiStore.trendsApi.getTo10TrendingRecords(
      typeId,
      trendType,
      queryParams,
    );
    if (res.isOk()) {
      this._nextLink = res?.value?.nextLink;
      this.gridData = {
        columns,
        data: [...this.gridData.data, ...res.value.response],
      };
      this._rows = [...this._rows, ...res.value.response];
      this._totalCount = res?.value?.count;
    } else this.gridData = { columns: [], data: [] };
  };

  get List() {
    return this._rows;
  }
}
