import React, { useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import Accidents from '../../screens/private/Accidents';
import Dashboard from '../../screens/private/Dashboard';
import DataQChallengeV2 from '../../screens/private/DataQChallenge/DataQChallenge';
import Documents from '../../screens/private/Documents/documents';
import Drivers from '../../screens/private/Drivers/Drivers';
import DriverProfile from '../../screens/private/Drivers/DriverUpdate';
import Fleet from '../../screens/private/Fleet/Fleets';
import FleetUpdate from '../../screens/private/Fleet/FleetUpdate/FleetUpdate';
import Home from '../../screens/private/Home/Home';
import InspectionsAndVoilations from '../../screens/private/InspectionsAndVoilations';
import Notifications from '../../screens/private/Notifications/notifications';
import Profile from '../../screens/private/Profile/Profile';
import Reports from '../../screens/private/Reports/reports';
import SelectedTrendDetails from '../../screens/private/SelectedTrendDetails';
import Dot from '../../screens/private/ServiceRequest/Dot';
import AddToFleet from '../../screens/private/ServiceRequest/Dot/AddToFleet';
import DataQChallenge from '../../screens/private/ServiceRequest/Dot/DataQChallenge';
import DriverActions from '../../screens/private/ServiceRequest/Dot/DriverActions';
import DriverSearch from '../../screens/private/ServiceRequest/Dot/DriverSearch';
import EquipmentInspection from '../../screens/private/ServiceRequest/Dot/EquipmentInspection/EquipmentInspection';
import NewDriverSetup from '../../screens/private/ServiceRequest/Dot/NewDriverSetup';
import TerminateEquipment from '../../screens/private/ServiceRequest/Dot/TerminateEquipment/TerminateEquipment';
import TaxPermitsAnnualReport from '../../screens/private/ServiceRequest/TaxPermits/AnnualReports/AnnualReport';
import TaxPermitsDotUpdate from '../../screens/private/ServiceRequest/TaxPermits/DotUpdate/DotUpdate';
import TaxPermitsFTQuarterFilling from '../../screens/private/ServiceRequest/TaxPermits/FTQuarterFilling/FTQuarterFilling';
import TaxPermitsIFTA from '../../screens/private/ServiceRequest/TaxPermits/IFTA/IFTA';
import TaxPermitsIRPService from '../../screens/private/ServiceRequest/TaxPermits/IRPService/IRPService';
import TaxPermitsRoadTaxes from '../../screens/private/ServiceRequest/TaxPermits/RoadTaxes/RoadTaxes';
import TaxPermitsStatePermits from '../../screens/private/ServiceRequest/TaxPermits/StatePermits/StatePermits';
import Taxsandpermits from '../../screens/private/ServiceRequest/TaxPermits/Taxsandpermits';
import TaxPermitsUcr from '../../screens/private/ServiceRequest/TaxPermits/UCR/Ucr';
import CompanyInfo from '../../screens/private/Settings/CompanyInfo/CompanyInfo';
import NotifiationSettings from '../../screens/private/Settings/Notification/notificationSettings';
import Settings from '../../screens/private/Settings/settings';
// import TaxAndPermitsTemp from '../../screens/private/TaxAndPermitsTemp/taxpermitstemp';
import Todos from '../../screens/private/Todos/Todos';
import Training from '../../screens/private/Training';
import Users from '../../screens/private/Users/Users';
import Violations from '../../screens/private/Violations';
import LoginAsImpersonate from '../../screens/public/LoginAsImpersonate/LoginAsImpersonate';
import { PublicRoute } from '../Constants';
// import { PrivateRoute } from '../Constants';
import { PrivateRoutes } from '../index';
// PublicRoutes

const CompanyAdminRoutes: React.FC = (): React.ReactElement => {
  const {
    ACCIDENTS,
    CRASHES_OF,
    DASHBOARD,
    NOTIFICATIONS,
    TODOS,
    TODOS_OF,
    DRIVERS,
    DRIVERSDOCUMENT,
    FLEET,
    FLEETDOCUMENTS,
    DOCUMENTS,
    REPORTS,
    SERVICEREQUEST,
    SETTINGSROUTE,
    PROFILE,
    USERS,
    DRIVERPROFILE,
    UPDATEFLEET,
    INSPECTIONS_AND_VIOLATIONS,
    INSPECTIONS_OF,
    VIOLATIONS,
    VIOLATIONS_OF,
    TRAINING,
    DATA_Q_CHALLENGE,
  } = PrivateRoutes;

  const [title, setTitle] = useState('');

  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { id: addToFleetId } = state ? (state as { id: string }) : { id: '' };
  const todoId = searchParams.get('id') || '';

  const { UCR } = SERVICEREQUEST;
  return (
    <Routes>
      <Route
        path={'/'}
        element={<Navigate to={`${DASHBOARD as string}/highlights`} />}
      />
      <Route
        path={PublicRoute.LOGINASIMPERSONATE}
        element={<LoginAsImpersonate />}
      />
      <Route
        path={`${DASHBOARD as string}/*`}
        element={
          <Home>
            <Dashboard />
          </Home>
        }
      />
      <Route
        path={NOTIFICATIONS}
        element={
          <Home
            title={'Notifications'}
            globalSearch={true}
            globalFilter={false}>
            <Notifications />
          </Home>
        }
      />

      <Route
        path={TODOS}
        element={
          <Home
            {...(state || todoId ? { backButton: true } : {})}
            title={'To-Dos'}
            globalSearch={false}
            globalFilter={true}>
            <Todos />
          </Home>
        }
      />

      {/* <Route
        path={UCR}
        element={
          <Home>
            <TaxAndPermitsTemp />
          </Home>
        }
      /> */}

      <Route
        path={UCR}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits tabValue={'1'} childComponent={<TaxPermitsUcr />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.ROADTAX}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'2'}
              childComponent={<TaxPermitsRoadTaxes />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.ANNUALREPORT}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'3'}
              childComponent={<TaxPermitsAnnualReport />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.IFTA}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'4'}
              childComponent={<TaxPermitsIFTA />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.STATEPERMITS}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'5'}
              childComponent={<TaxPermitsStatePermits />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.IRPSERVICE}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'6'}
              childComponent={<TaxPermitsIRPService />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.DOTUPDATE}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'7'}
              childComponent={<TaxPermitsDotUpdate />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.FTQUARTERFILLING}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'8'}
              childComponent={<TaxPermitsFTQuarterFilling />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.MVR}
        element={
          <Home title={'Service Request'}>
            <Dot
              tabValue={'1'}
              childComponent={
                <div>
                  <DriverSearch />
                </div>
              }
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.MVR_BY_ID}
        element={
          <Home title={'Service Request'} backButton>
            <Dot tabValue={'1'} childComponent={<DriverActions />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.NEWDRIVERSETUP}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'5'} childComponent={<NewDriverSetup />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.ADDTOFLEET}
        element={
          <Home
            {...(addToFleetId ? { backButton: true } : {})}
            title={'Service Request'}>
            <Dot tabValue={'6'} childComponent={<AddToFleet />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.EQUIPMENTINSPECTION}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'7'} childComponent={<EquipmentInspection />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.DATAQCHALLENGE}
        element={
          <Home title={'Service Request'}>
            <Dot
              tabValue={'8'}
              childComponent={
                <div>
                  <DriverSearch />
                </div>
              }
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.DATAQCHALLENGE_BY_ID}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'8'} childComponent={<DataQChallenge />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.TERMINATEDRIVER}
        element={
          <Home title={'Service Request'}>
            <Dot
              tabValue={'9'}
              childComponent={
                <div>
                  <DriverSearch />
                </div>
              }
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.TERMINATEDRIVER_BY_ID}
        element={
          <Home backButton title={'Service Request'}>
            <Dot tabValue={'9'} childComponent={<DriverActions />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.TERMINATEEQUIPMENT}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'10'} childComponent={<TerminateEquipment />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.TERMINATEEQUIPMENT_BY_ID}
        element={
          <Home backButton title={'Service Request'}>
            <Dot tabValue={'10'} childComponent={<TerminateEquipment />} />
          </Home>
        }
      />

      <Route
        path={DRIVERS}
        element={
          <Home
            title={'Drivers'}
            globalSearch={true}
            globalFilter={false}
            {...(state &&
            typeof state === 'object' &&
            Object.keys(state).includes('showBackBtn')
              ? { backButton: true }
              : {})}>
            <Drivers />
          </Home>
        }
      />

      <Route
        path={DRIVERSDOCUMENT}
        element={
          <Home
            title={'Documents'}
            globalSearch={false}
            globalFilter={true}
            documentsFilter={true}
            showInDocumentStoreHeaderName={true}>
            <Documents
              {...{ backButton: true, backUrl: DRIVERS, type: 'driver' }}
            />
          </Home>
        }
      />

      <Route
        path={DRIVERPROFILE}
        element={
          <Home backButton title="Driver Profile">
            <DriverProfile />
          </Home>
        }
      />
      <Route
        path={FLEET}
        element={
          <Home
            title={'Fleet'}
            globalSearch={true}
            globalFilter={false}
            {...(state &&
            typeof state === 'object' &&
            Object.keys(state).includes('showBackBtn')
              ? { backButton: true }
              : {})}>
            <Fleet />
          </Home>
        }
      />

      <Route
        path={FLEETDOCUMENTS}
        element={
          <Home
            title={'Documents'}
            globalSearch={false}
            globalFilter={true}
            documentsFilter={true}
            showInDocumentStoreHeaderName={true}>
            <Documents
              {...{ backButton: true, backUrl: FLEET, type: 'unit' }}
            />
          </Home>
        }
      />

      <Route
        path={DOCUMENTS}
        element={
          <Home
            title={'Documents'}
            globalSearch={false}
            globalFilter={true}
            documentsFilter={true}>
            <Documents />
          </Home>
        }
      />

      <Route
        path={REPORTS}
        element={
          <Home title={'Reports'} globalSearch={true} globalFilter={false}>
            <Reports />
          </Home>
        }
      />

      <Route
        path={SETTINGSROUTE.SETTINGS}
        element={
          <Home title={'Settings'}>
            <Settings />
          </Home>
        }
      />

      <Route
        path={USERS}
        element={
          <Home title={'Users'} globalSearch={true} globalFilter={false}>
            <Users />
          </Home>
        }
      />

      <Route
        path={PROFILE}
        element={
          <Home title="Profile">
            <Profile />
          </Home>
        }
      />
      <Route
        path={UPDATEFLEET}
        element={
          <Home backButton title="UpdateFleet">
            <FleetUpdate />
          </Home>
        }
      />

      <Route
        path={SETTINGSROUTE.COMPANYINFO}
        element={
          <Home title={'Settings'}>
            <CompanyInfo />
          </Home>
        }
      />

      <Route
        path={SETTINGSROUTE.NOTIFICATION}
        element={
          <Home title={'Settings'}>
            <NotifiationSettings {...{ headerName: 'Company' }} />
          </Home>
        }
      />
      <Route
        path={INSPECTIONS_AND_VIOLATIONS}
        element={
          <Home
            globalSearch={true}
            globalFilter={false}
            title={'Inspections & Violations'}>
            <InspectionsAndVoilations />
          </Home>
        }
      />
      <Route
        path={VIOLATIONS}
        element={
          <Home globalSearch={true} title={'Violations'}>
            <Violations />
          </Home>
        }
      />
      <Route
        path={VIOLATIONS_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={CRASHES_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={INSPECTIONS_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={TODOS_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={ACCIDENTS}
        element={
          <Home globalSearch={true} title={'Accidents'}>
            <Accidents />
          </Home>
        }
      />
      <Route
        path={`${TRAINING.BASE_ROUTE as string}/*`}
        element={
          <Home globalSearch={true} globalFilter={false} title={'Training'}>
            <Training />
          </Home>
        }
      />
      {/* Data Q Challenge */}
      <Route
        path={DATA_Q_CHALLENGE}
        element={
          <Home
            globalSearch={false}
            globalFilter={false}
            backButton={true}
            title={title}>
            <DataQChallengeV2 setAppBarTitle={setTitle} />
          </Home>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default CompanyAdminRoutes;
