import { CircularProgress, Paper, Stack } from '@mui/material';
import styled from '@mui/system/styled';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import SimplexCard from '../../../components/SimplexCard';
import SimplexGrid from '../../../components/SimplexGrid';
import { useCustomStore } from '../../../hooks';
import { Div } from './styles';
import ViolationCard from './ViolationCard';

const CustomPaper = styled(Paper)(() => ({
  alignItems: 'center',
  // '.inspectionViloationAccordionRoot': {
  //   minWidth: '50%',
  // },
  backgroundColor: '#E5E9EF !important',
  display: 'grid',
  gridTemplateColumns: '60% 1% 39%',
}));

const Seperator = styled('div')(() => ({
  backgroundColor: '#D1D1D1',
  height: 40,
  marginBottom: 5,
  marginTop: 5,
  width: 1,
}));

type Details = {
  primaryDriver?: any;
  secondaryDriver?: any;
  truckInfo?: any;
  trailerInfo?: any;
  violations?: Violation[];
  matchedPrimaryDriver?: any;
  matchedSecondaryDriver?: any;
  matchedTruckInfo?: any;
  matchedTrailerInfo?: any;
};

type Props = {
  id: string;
  showDriverInfo: boolean;
  showEquipmentInfo: boolean;
};
const headerKeys = [
  {
    label: 'Violation Unit',
    name: 'violationUnit',
    noSorting: true,
  },
  {
    label: 'BASIC Category',
    name: 'basic',
    noSorting: true,
  },
  {
    label: 'Violation Code',
    name: 'violationCode',
    noSorting: true,
  },
  {
    label: 'Description',
    name: 'description',
    noSorting: true,
  },
  {
    label: 'Out of Service',
    name: 'outOfService',
    noSorting: true,
  },
  {
    label: 'Violation Severity Weight',
    name: 'violationSeverityWeight',
    noSorting: true,
  },
  {
    label: 'Weight Added for being Out of Service',
    name: 'outOfServiceWeight',
    noSorting: true,
  },
  {
    label: 'Time Weight',
    name: 'timeWeight',
    noSorting: true,
  },
  {
    label: 'Total Weight',
    name: 'totalWeight',
    noSorting: true,
  },
  {
    label: 'Total Time Weighted Violation Weight',
    name: 'totalTimeViolationWeight',
    noSorting: true,
  },
];

const applySpacing = (el: any, index: number) => {
  const spacing = [
    100, 100, 100, 600, 100, 100, 100, 100, 100, 100, 50, 50, 60, 80,
  ];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const headerKeysWithSpacing = headerKeys.map(applySpacing);

const primaryDriverLabels = ['Full Name', 'License State', 'DOB', 'Age'];
const primaryDriverKeys: string[] = [
  'fullName',
  'licenseStateName',
  'dob',
  'age',
];
const truckInfoLabels = [
  'Power Unit Type',
  'Make',
  'License',
  'License State',
  'VIN',
];
const truckInfoKeys: string[] = [
  'unitType',
  'make',
  'licenseNumber',
  'licenseStateName',
  'vinNumber',
];
const trailerInfoLabels = [
  'Trailer Type',
  'Make',
  'License',
  'License State',
  'VIN',
];
const trailerInfoKeys: string[] = [
  'unitType',
  'make',
  'licenseNumber',
  'licenseStateName',
  'vinNumber',
];

const matchedDriverLabels = ['Full Name', 'DOB', 'Simplex ID'];
const matchedDriverKeys: string[] = ['fullName', 'dob', 'simplexId'];
const matchedTruckTrailerLabels = ['VIN', 'Unit#', 'Simplex ID'];
const matchedTruckTrailerKeys: string[] = [
  'vinNumber',
  'unitNumber',
  'simplexUnitNumber',
];

const convertObjectToArray = (obj: any) => {
  const values: any[] = Object.values(obj);
  const keys = Object.keys(obj);
  return keys.map((key, index) => ({
    label: values[index].label,
    value: values[index].value || '-',
  }));
};
// const getItemTextFromArrayById = (id: string, itemsArray: any[], key_ = '') => {
//   const el = (id && itemsArray.find((item) => item.id === id)) || {};
//   return el[key_] || '-';
// };

const MoreDetails: React.FC<Props> = ({
  id = '',
  showDriverInfo = true,
  showEquipmentInfo = true,
}) => {
  const { inspectionsAndViolationsStore: store, authStore } = useCustomStore();
  const { statesListOption } = authStore;

  const [details, setDetails] = useState<Details>();
  const [showLoader, setShowLoader] = useState<boolean>();

  const getValueByKeys = (keys: any[], labels: string[], data: any) =>
    keys.reduce(
      (acc: any, key_, index: number) => ({
        ...acc,
        [key_]: { label: labels[index], value: data[key_] || '-' },
      }),
      {},
    );
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const {
        primaryDriver = {},
        secondaryDriver = {},
        trailerInfo = {},
        truckInfo = {},
        violations = [],
        matchedPrimaryDriver = {},
        matchedSecondaryDriver = {},
        matchedTrailerInfo = {},
        matchedTruckInfo = {},
      } = await store.getMoreDetailsByInspectionId(id);
      setShowLoader(false);
      const primaryDriver_ = getValueByKeys(
        primaryDriverKeys,
        primaryDriverLabels,
        primaryDriver,
      );
      const secondaryDriver_ = getValueByKeys(
        primaryDriverKeys,
        primaryDriverLabels,
        secondaryDriver,
      );

      const truckInfo_ = getValueByKeys(
        truckInfoKeys,
        truckInfoLabels,
        truckInfo,
      );
      const trailerInfo_ = getValueByKeys(
        trailerInfoKeys,
        trailerInfoLabels,
        trailerInfo,
      );

      const matchedPrimaryDriver_ = getValueByKeys(
        matchedDriverKeys,
        matchedDriverLabels,
        matchedPrimaryDriver,
      );

      const matchedSecondaryDriver_ = getValueByKeys(
        matchedDriverKeys,
        matchedDriverLabels,
        matchedSecondaryDriver,
      );

      const matchedTruckInfo_ = getValueByKeys(
        matchedTruckTrailerKeys,
        matchedTruckTrailerLabels,
        matchedTruckInfo,
      );
      const matchedTrailerInfo_ = getValueByKeys(
        matchedTruckTrailerKeys,
        matchedTruckTrailerLabels,
        matchedTrailerInfo,
      );

      setDetails({
        matchedPrimaryDriver: matchedPrimaryDriver_,
        matchedSecondaryDriver: matchedSecondaryDriver_,
        matchedTrailerInfo: matchedTrailerInfo_,
        matchedTruckInfo: matchedTruckInfo_,
        primaryDriver: primaryDriver_,
        secondaryDriver: secondaryDriver_,
        trailerInfo: trailerInfo_,
        truckInfo: truckInfo_,
        violations,
      });
    })();
  }, [id, store, statesListOption]);

  // const handleSort = (e: any)=> {
  //   details?.violations.sort()
  // }

  return (
    <Stack spacing={0.5}>
      {!showLoader && (
        <>
          {showDriverInfo && (
            <>
              <CustomPaper elevation={0}>
                {details?.primaryDriver && (
                  <SimplexCard
                    title="Primary Driver"
                    data={convertObjectToArray(details.primaryDriver)}
                    rootClassName="inspectionViloationAccordionRoot"
                    mainGridClassName="inspectionViloationAccordionGrid"
                    spacing={0}
                    headerLg={2}
                    bodyLg={1.7}
                  />
                )}
                <Seperator />
                {details?.matchedPrimaryDriver && (
                  <SimplexCard
                    title="Matched Driver"
                    rootClassName="inspectionViloationAccordionRoot"
                    data={convertObjectToArray(details.matchedPrimaryDriver)}
                    spacing={0}
                    headerLg={3}
                    bodyLg={3}
                  />
                )}
              </CustomPaper>
              <CustomPaper elevation={0}>
                {details?.secondaryDriver && (
                  <SimplexCard
                    title="Secondary Driver"
                    rootClassName="inspectionViloationAccordionRoot"
                    data={convertObjectToArray(details.secondaryDriver)}
                    spacing={0}
                    headerLg={2}
                    bodyLg={1.7}
                  />
                )}
                <Seperator />
                {details?.matchedSecondaryDriver && (
                  <SimplexCard
                    title="Matched Driver"
                    rootClassName="inspectionViloationAccordionRoot"
                    data={convertObjectToArray(details.matchedSecondaryDriver)}
                    spacing={0}
                    headerLg={3}
                    bodyLg={3}
                  />
                )}
              </CustomPaper>
            </>
          )}
          {showEquipmentInfo && (
            <>
              <CustomPaper elevation={0}>
                {details?.truckInfo && (
                  <SimplexCard
                    title="Truck Info"
                    rootClassName="inspectionViloationAccordionRoot"
                    data={convertObjectToArray(details.truckInfo)}
                    spacing={0}
                    headerLg={2}
                    bodyLg={1.7}
                  />
                )}
                <Seperator />
                {details?.matchedTruckInfo && (
                  <SimplexCard
                    title="Matched Truck"
                    rootClassName="inspectionViloationAccordionRoot"
                    data={convertObjectToArray(details.matchedTruckInfo)}
                    spacing={0}
                    headerLg={3}
                    bodyLg={3}
                  />
                )}
              </CustomPaper>
              <CustomPaper elevation={0}>
                {details?.trailerInfo && (
                  <SimplexCard
                    title="Trailer Info"
                    rootClassName="inspectionViloationAccordionRoot"
                    data={convertObjectToArray(details.trailerInfo)}
                    spacing={0}
                    headerLg={2}
                    bodyLg={1.7}
                  />
                )}
                <Seperator />
                {details?.matchedTrailerInfo && (
                  <SimplexCard
                    title="Matched Trailer"
                    rootClassName="inspectionViloationAccordionRoot"
                    data={convertObjectToArray(details.matchedTrailerInfo)}
                    spacing={0}
                    headerLg={3}
                    bodyLg={3}
                  />
                )}
              </CustomPaper>
            </>
          )}
          {details?.violations && details.violations.length ? (
            <SimplexGrid.Wrap>
              <SimplexGrid.Header
                id="inspections-and-violations"
                cells={[...headerKeysWithSpacing]}
                tableCellSx={{
                  backgroundColor: '#F1F4F9',
                  border: '0 !important',
                }}
              />
              <SimplexGrid.Body
                id="inspections-and-violations"
                data={details.violations}
                cells={headerKeysWithSpacing}>
                {(el: any, index: number) => (
                  <ViolationCard
                    cells={headerKeysWithSpacing}
                    data={el}
                    key={index}
                  />
                )}
              </SimplexGrid.Body>
            </SimplexGrid.Wrap>
          ) : null}
        </>
      )}
      {showLoader && (
        <Div>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </Div>
      )}
    </Stack>
  );
};

export default observer(MoreDetails);
