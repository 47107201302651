import { makeAutoObservable, toJS } from 'mobx';

import { getFileNamingConvention } from '../utils/fileName.config';
import { RootStore } from './';

const fileTypesToNames: Record<string, string> = {
  'ELD Report': 'ELD Reports',
  'Fuel Card Report': 'Fuel Card Reports',
};

export class FtQuarterFillingStore {
  private _initialApiStatePermitsData: any = {};
  private _ftQuarterFilling: any = {
    equipments: [],
    fuelCardReports: [],
    reporting: {
      quarter: '',
      year: '',
    },
    statePermits: [],
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get ftQuarterFillingDetails(): any {
    return { ...toJS(this._ftQuarterFilling) };
  }

  setReportingDetail(key: string, value: any) {
    this._ftQuarterFilling.reporting = {
      ...this._ftQuarterFilling.reporting,
      [key]: value,
    };
  }

  addEquipmentDetail(
    equipmentNewDatas: any[],
    equipmentOldTerminateDatas: any[],
  ) {
    // this._ftQuarterFilling.equipments.push(...equipment);
    const modifyEquipmentDetails: any[] = [
      ...this._ftQuarterFilling.equipments,
    ];
    // OldTerminateList.forEach()
    if (equipmentOldTerminateDatas.length) {
      equipmentOldTerminateDatas.forEach((equipment: any) => {
        const findRemoveIndex: number = modifyEquipmentDetails.findIndex(
          (e: any) =>
            e.unitId === equipment.unitId &&
            e.vinNumber === equipment.vinNumber &&
            e.simplexId === equipment.simplexId,
        );
        if (findRemoveIndex !== -1) {
          modifyEquipmentDetails.splice(findRemoveIndex, 1);
        }
      });
    }

    if (equipmentNewDatas.length) {
      modifyEquipmentDetails.push(...equipmentNewDatas);
    }

    this._ftQuarterFilling.equipments = modifyEquipmentDetails;
  }

  removeEquipmentDetail(index: number) {
    const modifiedData: any = [...this._ftQuarterFilling.equipments].filter(
      (_: any, i: any) => i !== index,
    );
    this._ftQuarterFilling.equipments = modifiedData;
  }

  addFileToEquipment(files: any, index: number) {
    if (this._ftQuarterFilling.equipments[index]) {
      this._ftQuarterFilling.equipments[index].files.push(...files);
    }
  }

  removeFileFromEquipment(equipmentIndex: number, index: number) {
    const existedFileArray = [
      ...toJS(this._ftQuarterFilling.equipments[equipmentIndex].files),
    ].filter((_: any, i: number) => i !== index);
    const existedEquipmentDetails = {
      ...this._ftQuarterFilling.equipments[equipmentIndex],
      files: [...existedFileArray],
    };
    this._ftQuarterFilling.equipments[equipmentIndex] = existedEquipmentDetails;
  }

  getFileFroEquipmentIndex(index: number) {
    if (this._ftQuarterFilling.equipments[index]) {
      return this._ftQuarterFilling.equipments[index].files;
    }
    return [];
  }

  addFuelCardDetail(files: any[]) {
    this._ftQuarterFilling.fuelCardReports.push(...files);
  }

  removeFuelCardDetail(index: number) {
    this._ftQuarterFilling.fuelCardReports.splice(index, 1);
  }

  resetFTQuarterFillingStore() {
    this._ftQuarterFilling = {
      equipments: [],
      fuelCardReports: [],
      reporting: {
        quarter: '',
        year: '',
      },
      statePermits: [...this._initialApiStatePermitsData],
    };
  }

  setStatePermitCheckToggle(index: number, checkstatus: boolean) {
    this._ftQuarterFilling.statePermits[index] = {
      ...this._ftQuarterFilling.statePermits[index],
      selected: checkstatus,
    };
  }

  async getInitialData() {
    const initialResponse =
      await this.rootStore.apiStore.ftQuarterFillingApiStore.getInitialData();
    if (initialResponse.isOk()) {
      let {
        value: { statePermits },
      } = initialResponse;
      if (statePermits && Array.isArray(statePermits)) {
        statePermits = statePermits.map((e) => ({
          ...e,
          disablestatus: e.selected,
        }));

        this._initialApiStatePermitsData = statePermits;
        // this._ftQuarterFilling.statePermits = statePermits;
        this._ftQuarterFilling = {
          equipments: [],
          fuelCardReports: [],
          reporting: {
            quarter: '',
            year: '',
          },
          statePermits,
        };
      }
    }
    return initialResponse;
  }

  async addFTQuarterFilling(todoId?: string) {
    const {
      equipments,
      fuelCardReports,
      reporting: { quarter, year },
      statePermits,
    }: any = this._ftQuarterFilling;

    const ApiStatePermits: any = statePermits.map((e: any) => ({
      registeredName: e.registrantName || '',
      selected: e.selected || false,
      state: e.permitType || '',
      status: e.status || '',
    }));

    const fileArray: any = [];
    [...toJS(equipments)].forEach((equipment: any) => {
      if (equipment.files && equipment.files.length) {
        equipment.files.forEach((file: any) => {
          fileArray.push({
            apiRes: null,
            equipment,
            file: file,
            resId: fileArray.length,
            unitId: equipment.unitId,
            unitNumber: equipment.unitNumber,
          });
        });
      }
    });

    if (fileArray.length) {
      const fd: any = new FormData();
      const fileInformationDetail: any = [];
      fileArray.forEach((fileData: any) => {
        fd.append('files', fileData.file);
        // const findExistedUnitIdDetail:any = fileInformationDetail.find((e:any) => {
        //   if(!e.unit || !e.unit.unitId){
        //     return false
        //   }
        //   return e.unit.unitId === fileData.unitId
        // });
        // if(!findExistedUnitIdDetail){

        const fileName = getFileNamingConvention['Fuel Receipts'].name({
          unitNumber: fileData.unitNumber,
          yearQuarter: `${year as string}-${quarter as string}`,
        });
        fileInformationDetail.push({
          fileName,
          fileType: 'Fuel Receipts',
          service: 'ftQuarterly',
          type: 'unit',
          unit: {
            unitId: fileData.unitId,
          },
        });
        // }
      });

      fd.append('data', JSON.stringify(fileInformationDetail));
      const equipmentImageRes: any =
        await this.rootStore.apiStore.fileApi.uploadFile(fd);

      if (equipmentImageRes.isOk()) {
        const { value } = equipmentImageRes;
        if (value && Array.isArray(value)) {
          value.forEach((resVal: any, index: number) => {
            if (fileArray[index].resId === resVal.index) {
              fileArray[index].apiRes = resVal;
            }
          });
        }
      }

      if (equipmentImageRes.isErr()) {
        return equipmentImageRes;
      }
    }

    let ApiEquipmentDetails: any = [];

    if ([...toJS(equipments)].length) {
      ApiEquipmentDetails = [...toJS(equipments)].map((eq: any) => {
        const returnobj = {
          documents: [],
          equipmentType: eq.equipmentType,
          grossVehicleWeight: eq.grossVehicleWeight,
          simplexId: eq.simplexId,
          status: eq.status,
          unitId: eq.unitId,
          unitNumber: eq.unitNumber,
          unitType: eq.unitType,
          vinNumber: eq.vinNumber,
        };
        const filterFile: any = fileArray.filter(
          (ef: any) => ef.unitId === eq.unitId,
        );
        if (filterFile.length) {
          const apiFilterFileResponses: any = [];
          filterFile.forEach((efm: any) => {
            if (efm.apiRes) {
              apiFilterFileResponses.push({
                fileName: efm.apiRes.fileName,
                fileSize: efm.apiRes.fileSize,
                fileType: efm.apiRes.fileType,
                location: efm.apiRes.location,
              });
            }
          });
          returnobj.documents = apiFilterFileResponses;
        }

        return returnobj;
      });
    }

    const fileDatas: any = [...toJS(fuelCardReports)];
    let fileApiRes: any = [];

    if (fileDatas.length) {
      const fd: any = new FormData();
      const individualFileInformationDetail: any = [];
      fileDatas.forEach((file: any) => {
        const reportType = fileTypesToNames[file.type] as FileType;
        const fileName = getFileNamingConvention[reportType].name({
          quarter,
          year,
        });
        fd.append('files', file.file);
        individualFileInformationDetail.push({
          fileName,
          fileType: reportType,
          service: 'ftQuarterly',
          type: 'account',
        });
      });

      fd.append('data', JSON.stringify(individualFileInformationDetail));
      const imageRes: any = await this.rootStore.apiStore.fileApi.uploadFile(
        fd,
      );

      if (imageRes.isOk()) {
        fileApiRes = imageRes.value.map((e: any) => ({
          fileName: e.fileName,
          fileSize: e.fileSize,
          fileType: e.fileType,
          location: e.location,
        }));
      }

      if (imageRes.isErr()) {
        return imageRes;
      }
    }

    const apiData: any = {
      equipment: ApiEquipmentDetails,
      fuelCardReports: fileApiRes,
      reportingQuarter: {
        quarter,
        year,
      },
      statePermits: ApiStatePermits,
      todoId: todoId != '' ? todoId : '',
    };
    const addResponse =
      await this.rootStore.apiStore.ftQuarterFillingApiStore.addFTQuarterFilling(
        apiData,
      );
    return addResponse;
  }
}
