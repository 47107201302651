import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

// import search from '../../../assets/images/h-search.svg';
import DialogMigrate from '../../../components/Dialog/DialogMigrate';
import SelectDriverFromAll from '../../../components/SelectDriverFromAll';
import SelectEquipmentFromAll from '../../../components/SelectEquipmentFromAll';
import { useCustomStore } from '../../../hooks';
import { SingleDriverVinMatch } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { useStyles } from './styles';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

const primaryHeaderKeys = [
  {
    label: 'Date',
    name: 'inspectionDate',
  },
  {
    label: 'Report #',
    name: 'reportNumber',
  },
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'State',
    name: 'inspectionState',
  },
];

const secondaryHeaderKeys = [
  {
    label: 'Date',
    name: 'inspectionDate',
  },
  {
    label: 'Report #',
    name: 'reportNumber',
  },
  {
    label: 'Name',
    name: 'name',
  },
  {
    label: 'State',
    name: 'inspectionState',
  },
];

const applySpacing = (el: any, index: number) => {
  const spacing = [150, 150, 150, 150, 150];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const MatchDriversAndVinsMultiple: React.FC<any> = (props: any) => {
  const { authStore } = useCustomStore();
  const primaryHeaderKeysWithSpacing = primaryHeaderKeys.map(applySpacing);
  const secondaryHeaderKeysWithSpacing = secondaryHeaderKeys.map(applySpacing);

  const { isOpen, closeDialog, selectedInspectionsViolations = [] } = props;

  const primaryDriverAssignedTrainingData =
    selectedInspectionsViolations.filter((siv: any) => {
      return siv.primaryDriver.isTrainingAssigned;
    });

  const secondaryDriverAssignedTrainingData =
    selectedInspectionsViolations.filter((siv: any) => {
      return siv.secondaryDriver.isTrainingAssigned;
    });

  const [inspectionDriverViolation, setInspectionDriverViolation] = useState<
    Array<any>
  >([]);

  const {
    control,
    // handleSubmit,
    // formState: { errors },
    // reset,
    getValues,
    setValue,
  } = useForm<SingleDriverVinMatch>({
    criteriaMode: 'all',
    defaultValues: {
      matchedPowerUnitVin: '',
      matchedPrimaryName: '',
      matchedSecondaryName: '',
      matchedTrailerVin: '',
      powerUnitVINIncludeCheck: false,
      powerUnitVINMessage: '',
      powerUnitVinMatchedId: '',
      primaryDriverIncludeCheck: false,
      primaryDriverMatchedId: '',
      primaryDriverMessage:
        primaryDriverAssignedTrainingData.length > 0
          ? (`${primaryDriverAssignedTrainingData.length as string} out of ${
              selectedInspectionsViolations.length as string
            } inspections have training assigned to primary driver. ` as string)
          : '',
      secondaryDriverIncludeCheck: false,
      secondaryDriverMatchedId: '',
      secondaryDriverMessage:
        secondaryDriverAssignedTrainingData.length > 0
          ? (`${secondaryDriverAssignedTrainingData.length as string} out of ${
              selectedInspectionsViolations.length as string
            } inspections have training assigned to secondary driver.` as string)
          : '',
      trailerVINIncludeCheck: false,
      trailerVINMessage: '',
      trailerVinMatchedId: '',
    },
    reValidateMode: 'onChange',
  });
  const [includedFieldList, setIncludedFieldList] = useState<Array<any>>([]);
  const classes = useStyles();
  const { inspectionsAndViolationsStore: store } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [showPrimaryIV, setShowPrimaryIV] = useState(false);
  const [showSecondaryIV, setShowSecondaryIV] = useState(false);
  const [showPowerUnitVINMessage, setShowPowerUnitVINMessage] = useState(false);
  const [showTrailerUnitVINMessage, setShowTrailerUnitVINMessage] =
    useState(false);

  const closeDialogWindow = useCallback(
    ({ reload }) => {
      closeDialog && closeDialog({ reload });
    },
    [closeDialog],
  );

  const confirmDriverAndVINMatch = useCallback(async () => {
    if (includedFieldList.length === 0) {
      return;
    }
    let isError = false;
    const confirmMatchData: any = selectedInspectionsViolations.map(
      (sIV: any) => {
        const payload: any = {
          inspectionId: sIV.inspectionId,
        };
        if (includedFieldList.includes('primaryDriverId')) {
          payload.primaryDriverId = getValues('primaryDriverMatchedId');
          payload.isPrimaryDriverConfirmed = true;
          if (!payload.primaryDriverId || payload.primaryDriverId === '') {
            enqueueSnackbar(
              String('Please select matching Primary Driver'),
              SnackBarConfig('e'),
            );
            isError = true;
            return;
          }
        }
        if (includedFieldList.includes('secondaryDriverId')) {
          payload.secondaryDriverId = getValues('secondaryDriverMatchedId');
          payload.isSecondaryDriverConfirmed = true;
          if (!payload.secondaryDriverId || payload.secondaryDriverId === '') {
            enqueueSnackbar(
              String('Please select matching Secondary Driver'),
              SnackBarConfig('e'),
            );
            isError = true;
            return;
          }
        }
        if (includedFieldList.includes('truckUnitId')) {
          payload.truckUnitId = getValues('powerUnitVinMatchedId');
          payload.isTruckConfirmed = true;
          if (!payload.truckUnitId || payload.truckUnitId === '') {
            enqueueSnackbar(
              String('Please select matching Truck Unit'),
              SnackBarConfig('e'),
            );
            isError = true;
            return;
          }
        }
        if (includedFieldList.includes('trailerUnitId')) {
          payload.trailerUnitId = getValues('trailerVinMatchedId');
          payload.isTrailerConfirmed = true;
          if (!payload.trailerUnitId || payload.trailerUnitId === '') {
            enqueueSnackbar(
              String('Please select matching Trailer Unit'),
              SnackBarConfig('e'),
            );
            isError = true;
            return;
          }
        }
        payload.hasPrimaryDriverViolation = inspectionDriverViolation.filter(
          (idv) => {
            return idv.inspectionId === sIV.inspectionId;
          },
        )[0].hasPrimaryDriverViolation;
        payload.hasSecondaryDriverViolation = inspectionDriverViolation.filter(
          (idv) => {
            return idv.inspectionId === sIV.inspectionId;
          },
        )[0].hasSecondaryDriverViolation;
        return payload;
      },
    );
    if (isError) {
      return;
    }
    // setBtnDisable(true);
    store.setShowLoader(true);
    const resp = await store.confirmMatch(confirmMatchData);
    if (resp.isErr()) {
      enqueueSnackbar(String(resp.error.message), SnackBarConfig('e'));
    } else {
      store.setShowLoader(false);
      enqueueSnackbar(
        'Your Match Confirmation has been Submitted',
        SnackBarConfig('s'),
      );
    }
    store.setShowLoader(false);
    closeDialogWindow({ reload: true });
  }, [
    store,
    closeDialogWindow,
    includedFieldList,
    enqueueSnackbar,
    getValues,
    selectedInspectionsViolations,
    inspectionDriverViolation,
  ]);

  const setMatchedPrimaryDriverNameInForm = (val: string | null) => {
    if (val) {
      setValue('primaryDriverMatchedId', val);
    }
  };

  const setMatchedSecondaryDriverNameInForm = (val: string | null) => {
    if (val) {
      setValue('secondaryDriverMatchedId', val);
    }
  };

  const setMatchedPowerUnitVINInForm = (val: string | null) => {
    if (val) {
      setValue('powerUnitVinMatchedId', val);
      if (val !== '') {
        setValue('powerUnitVINMessage', '');
        setShowPowerUnitVINMessage(false);
      }
    } else {
      setValue('powerUnitVINMessage', '');
      setShowPowerUnitVINMessage(false);
    }
  };

  const setMatchedTrailerVINInForm = (val: string | null) => {
    if (val) {
      setValue('trailerVinMatchedId', val);
      if (val !== '') {
        setValue('trailerVINMessage', '');
        setShowTrailerUnitVINMessage(false);
      }
    } else {
      setValue('trailerVINMessage', '');
      setShowTrailerUnitVINMessage(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIncludedFieldList([]);
      const inspectionIdArray = selectedInspectionsViolations.map(
        (siv: any) => {
          return siv.inspectionId;
        },
      );
      const getInspectionDriverViolationMatchResp =
        await store.getInspectionDriverViolationMatch(inspectionIdArray);
      setInspectionDriverViolation(getInspectionDriverViolationMatchResp);
    })();
  }, [store, selectedInspectionsViolations]);

  return (
    <DialogMigrate
      aria-labelledby="admin-users-select"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {
        closeDialogWindow({ reload: true });
      }}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Medium',
          fontSize: '18px',
          letterSpacing: 0,
          lineHeight: '26px',
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>MATCH DRIVERS & VINS</div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialogWindow({ reload: false });
            }}>
            CLOSE
          </Link>
        </div>
      </DialogTitle>
      <DialogContent id="scroll" className={classes.bodyWrapper}>
        <Box
          sx={{
            marginBottom: '15px',
          }}>
          <Divider className={classes.divider} />
        </Box>
        <Grid
          container
          spacing={2}
          style={{
            backgroundColor: '#FFFFFF',
            marginBottom: '10px',
          }}>
          <Grid
            container
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              paddingBottom: '15px',
              paddingTop: '15px',
            }}>
            <Grid item xs={12} md={12} lg={12}>
              <p
                style={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '14px',
                  lineHeight: '17px',
                  opacity: '43%',
                  paddingLeft: '14px',
                }}>
                Select and Apply all to Primary Driver matching name.
              </p>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <Checkbox
                className={classes.customCheck}
                onChange={(event: any) => {
                  setValue(
                    'primaryDriverIncludeCheck',
                    event.currentTarget.checked,
                  );
                  if (event.currentTarget.checked)
                    setIncludedFieldList([
                      ...includedFieldList,
                      'primaryDriverId',
                    ]);
                  else {
                    const _includedFieldList = includedFieldList;
                    const index = _includedFieldList.indexOf('primaryDriverId');
                    if (index > -1) {
                      _includedFieldList.splice(index, 1);
                      setIncludedFieldList([..._includedFieldList]);
                    }
                  }
                }}
                checked={getValues('primaryDriverIncludeCheck')}
                color="success"
                tabIndex={0}
              />
            </Grid>
            <Grid item xs={10} md={10} lg={10}>
              <Typography
                component={'div'}
                sx={{
                  color: '#241A2E',
                  display: 'flex',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '24px',
                  paddingBottom: '4px',
                }}>
                Matched Name
              </Typography>
              <FormControl style={{ width: '100%' }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="matchedPrimaryName"
                  render={({
                    field: { onChange, value, name, ref, onBlur, ...rest },
                    formState,
                  }) => {
                    return (
                      <SelectDriverFromAll
                        onChange={setMatchedPrimaryDriverNameInForm}
                        name={name}
                        value={value}
                        _ref={ref}
                        disable={!getValues('primaryDriverIncludeCheck')}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            {getValues('primaryDriverMessage') &&
              getValues('primaryDriverMessage') !== '' && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingBottom: '8px',
                      paddingTop: '8px',
                    }}>
                    <p
                      style={{
                        color: '#A70D0D',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                      }}>
                      {getValues('primaryDriverMessage')}
                    </p>
                    <p
                      style={{
                        color: '#A70D0D',
                        cursor: 'pointer',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                        textDecoration: 'underline',
                      }}
                      onClick={(e: any) => {
                        setShowPrimaryIV(!showPrimaryIV);
                      }}>
                      {showPrimaryIV ? 'View Less' : 'View All'}
                    </p>
                  </Grid>

                  {showPrimaryIV && selectedInspectionsViolations.length ? (
                    <div
                      style={{
                        paddingLeft: '14px',
                        width: '100%',
                      }}>
                      <div
                        style={{
                          alignItems: 'center',
                          color: '#241A2E',
                          display: 'flex',
                          fontSize: '12px',
                          justifyContent: 'left',
                          opacity: '43%',
                          paddingBottom: '8px',
                        }}>
                        {primaryHeaderKeysWithSpacing.map((phk, i) => {
                          return (
                            <div
                              style={{
                                flexGrow: 1,
                                width: `${phk.spacing as string}px`,
                              }}
                              key={`phk_header_${i}`}>
                              {phk.label}
                            </div>
                          );
                        })}
                      </div>
                      {primaryDriverAssignedTrainingData.map(
                        (siv: any, i: number) => {
                          return (
                            <div
                              style={{
                                alignItems: 'center',
                                color: '#241A2E',
                                display: 'flex',
                                fontSize: '14px',
                                justifyContent: 'left',
                                lineHeight: '17px',
                              }}
                              key={`phk_data_${i}`}>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    primaryHeaderKeysWithSpacing[0]
                                      .spacing as string
                                  }px`,
                                }}>
                                {siv[primaryHeaderKeysWithSpacing[0].name]}
                              </div>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    primaryHeaderKeysWithSpacing[1]
                                      .spacing as string
                                  }px`,
                                }}>
                                {siv[primaryHeaderKeysWithSpacing[1].name]}
                              </div>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    primaryHeaderKeysWithSpacing[2]
                                      .spacing as string
                                  }px`,
                                }}>
                                {
                                  siv.primaryDriver[
                                    primaryHeaderKeysWithSpacing[2].name
                                  ]
                                }
                              </div>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    primaryHeaderKeysWithSpacing[3]
                                      .spacing as string
                                  }px`,
                                }}>
                                {siv[primaryHeaderKeysWithSpacing[3].name]}
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  ) : null}
                </>
              )}
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              paddingBottom: '15px',
              paddingTop: '15px',
            }}>
            <Grid item xs={12} md={12} lg={12}>
              <p
                style={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '14px',
                  lineHeight: '17px',
                  opacity: '43%',
                  paddingLeft: '14px',
                }}>
                Select and Apply all to Secondary Driver matching name.
              </p>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <Checkbox
                className={classes.customCheck}
                onChange={(event: any) => {
                  setValue(
                    'secondaryDriverIncludeCheck',
                    event.currentTarget.checked,
                  );
                  if (event.currentTarget.checked) {
                    setIncludedFieldList([
                      ...includedFieldList,
                      'secondaryDriverId',
                    ]);
                  } else {
                    const _includedFieldList = includedFieldList;
                    const index =
                      _includedFieldList.indexOf('secondaryDriverId');
                    if (index > -1) {
                      _includedFieldList.splice(index, 1);
                      setIncludedFieldList([..._includedFieldList]);
                    }
                  }
                }}
                checked={getValues('secondaryDriverIncludeCheck')}
                color="success"
                tabIndex={0}
              />
            </Grid>
            <Grid item xs={10} md={10} lg={10}>
              <Typography
                component={'div'}
                sx={{
                  color: '#241A2E',
                  display: 'flex',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '24px',
                  paddingBottom: '4px',
                }}>
                Matched Name
              </Typography>
              <FormControl style={{ width: '100%' }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="matchedSecondaryName"
                  render={({
                    field: { onChange, value, name, ref, onBlur, ...rest },
                    formState,
                  }) => {
                    return (
                      <SelectDriverFromAll
                        onChange={setMatchedSecondaryDriverNameInForm}
                        name={name}
                        value={value}
                        _ref={ref}
                        disable={!getValues('secondaryDriverIncludeCheck')}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            {getValues('secondaryDriverMessage') &&
              getValues('secondaryDriverMessage') !== '' && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingBottom: '8px',
                      paddingTop: '8px',
                    }}>
                    <p
                      style={{
                        color: '#A70D0D',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                      }}>
                      {getValues('secondaryDriverMessage')}
                    </p>
                    <p
                      style={{
                        color: '#A70D0D',
                        cursor: 'pointer',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '13px',
                        fontStyle: 'italic',
                        lineHeight: '15px',
                        paddingLeft: '14px',
                        textDecoration: 'underline',
                      }}
                      onClick={(e: any) => {
                        setShowSecondaryIV(!showSecondaryIV);
                      }}>
                      {showSecondaryIV ? 'View Less' : 'View All'}
                    </p>
                  </Grid>
                  {showSecondaryIV && selectedInspectionsViolations.length ? (
                    <div
                      style={{
                        paddingLeft: '14px',
                        width: '100%',
                      }}>
                      <div
                        style={{
                          alignItems: 'center',
                          color: '#241A2E',
                          display: 'flex',
                          fontSize: '12px',
                          justifyContent: 'left',
                          opacity: '43%',
                          paddingBottom: '8px',
                        }}>
                        {secondaryHeaderKeysWithSpacing.map((shk, i) => {
                          return (
                            <div
                              style={{
                                flexGrow: 1,
                                width: `${shk.spacing as string}px`,
                              }}
                              key={`shk_header_${i}`}>
                              {shk.label}
                            </div>
                          );
                        })}
                      </div>
                      {secondaryDriverAssignedTrainingData.map(
                        (siv: any, i: number) => {
                          return (
                            <div
                              style={{
                                alignItems: 'center',
                                color: '#241A2E',
                                display: 'flex',
                                fontSize: '14px',
                                justifyContent: 'left',
                                lineHeight: '17px',
                              }}
                              key={`shk_data_${i}`}>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    secondaryHeaderKeysWithSpacing[0]
                                      .spacing as string
                                  }px`,
                                }}>
                                {siv[secondaryHeaderKeysWithSpacing[0].name]}
                              </div>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    secondaryHeaderKeysWithSpacing[1]
                                      .spacing as string
                                  }px`,
                                }}>
                                {siv[secondaryHeaderKeysWithSpacing[1].name]}
                              </div>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    secondaryHeaderKeysWithSpacing[2]
                                      .spacing as string
                                  }px`,
                                }}>
                                {
                                  siv.secondaryDriver[
                                    secondaryHeaderKeysWithSpacing[2].name
                                  ]
                                }
                              </div>
                              <div
                                style={{
                                  flexGrow: 1,
                                  width: `${
                                    secondaryHeaderKeysWithSpacing[3]
                                      .spacing as string
                                  }px`,
                                }}>
                                {siv[secondaryHeaderKeysWithSpacing[3].name]}
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  ) : null}
                </>
              )}
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              paddingBottom: '15px',
              paddingTop: '15px',
            }}>
            <Grid item xs={12} md={12} lg={12}>
              <p
                style={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '14px',
                  lineHeight: '17px',
                  opacity: '43%',
                  paddingLeft: '14px',
                }}>
                Select and Apply all to Power Unit VIN.
              </p>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <Checkbox
                className={classes.customCheck}
                onChange={(event: any) => {
                  setValue(
                    'powerUnitVINIncludeCheck',
                    event.currentTarget.checked,
                  );
                  if (event.currentTarget.checked) {
                    setIncludedFieldList([...includedFieldList, 'truckUnitId']);
                    if (getValues('powerUnitVinMatchedId') === '') {
                      const text =
                        'No matching VIN selected. Please select one to confirm.';
                      setValue('powerUnitVINMessage', text);
                      setShowPowerUnitVINMessage(true);
                    }
                  } else {
                    const _includedFieldList = includedFieldList;
                    const index = _includedFieldList.indexOf('truckUnitId');
                    if (index > -1) {
                      _includedFieldList.splice(index, 1);
                      setIncludedFieldList([..._includedFieldList]);
                      setShowPowerUnitVINMessage(false);
                    }
                  }
                }}
                checked={getValues('powerUnitVINIncludeCheck')}
                color="success"
                tabIndex={0}
              />
            </Grid>
            <Grid item xs={10} md={10} lg={10}>
              <Typography
                component={'div'}
                sx={{
                  color: '#241A2E',
                  display: 'flex',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '24px',
                  paddingBottom: '4px',
                }}>
                Matched VIN
              </Typography>
              <FormControl style={{ width: '100%' }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="matchedPowerUnitVin"
                  render={({
                    field: { onChange, value, name, ref, onBlur, ...rest },
                    formState,
                  }) => {
                    return (
                      <SelectEquipmentFromAll
                        onChange={setMatchedPowerUnitVINInForm}
                        name={name}
                        value={value}
                        _ref={ref}
                        disable={!getValues('powerUnitVINIncludeCheck')}
                        type={'power unit'}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            {getValues('powerUnitVINMessage') &&
              getValues('powerUnitVINMessage') !== '' &&
              getValues('powerUnitVinMatchedId') === '' &&
              showPowerUnitVINMessage && (
                <Grid item xs={12} md={12} lg={12}>
                  <p
                    style={{
                      color: '#A70D0D',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '13px',
                      fontStyle: 'italic',
                      lineHeight: '15px',
                      paddingLeft: '14px',
                    }}>
                    {getValues('powerUnitVINMessage')}
                  </p>
                </Grid>
              )}
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              paddingBottom: '15px',
              paddingTop: '15px',
            }}>
            <Grid item xs={12} md={12} lg={12}>
              <p
                style={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '14px',
                  lineHeight: '17px',
                  opacity: '43%',
                  paddingLeft: '14px',
                }}>
                Select and Apply all to Trailer VIN.
              </p>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <Checkbox
                className={classes.customCheck}
                onChange={(event: any) => {
                  setValue(
                    'trailerVINIncludeCheck',
                    event.currentTarget.checked,
                  );
                  if (event.currentTarget.checked) {
                    setIncludedFieldList([
                      ...includedFieldList,
                      'trailerUnitId',
                    ]);
                    if (getValues('trailerVinMatchedId') === '') {
                      const text =
                        'No matching VIN selected. Please select one to confirm.';
                      setValue('trailerVINMessage', text);
                      setShowTrailerUnitVINMessage(true);
                    }
                  } else {
                    const _includedFieldList = includedFieldList;
                    const index = _includedFieldList.indexOf('trailerUnitId');
                    if (index > -1) {
                      _includedFieldList.splice(index, 1);
                      setIncludedFieldList([..._includedFieldList]);
                      setShowTrailerUnitVINMessage(false);
                    }
                  }
                }}
                checked={getValues('trailerVINIncludeCheck')}
                color="success"
                tabIndex={0}
              />
            </Grid>
            <Grid item xs={10} md={10} lg={10}>
              <Typography
                component={'div'}
                sx={{
                  color: '#241A2E',
                  display: 'flex',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '24px',
                  paddingBottom: '4px',
                }}>
                Matched VIN
              </Typography>
              <FormControl style={{ width: '100%' }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="matchedTrailerVin"
                  render={({
                    field: { onChange, value, name, ref, onBlur, ...rest },
                    formState,
                  }) => {
                    return (
                      <SelectEquipmentFromAll
                        onChange={setMatchedTrailerVINInForm}
                        name={name}
                        value={value}
                        _ref={ref}
                        disable={!getValues('trailerVINIncludeCheck')}
                        type={'trailer'}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            {getValues('trailerVINMessage') &&
              getValues('trailerVINMessage') !== '' &&
              getValues('trailerVinMatchedId') === '' &&
              showTrailerUnitVINMessage && (
                <Grid item xs={12} md={12} lg={12}>
                  <p
                    style={{
                      color: '#A70D0D',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '13px',
                      fontStyle: 'italic',
                      lineHeight: '15px',
                      paddingLeft: '14px',
                    }}>
                    {getValues('trailerVINMessage')}
                  </p>
                </Grid>
              )}
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.jxy}>
        <BottomButton
          disabled={
            includedFieldList.length <= 0 || authStore.IsImpersonatingMode
          }
          type={'button'}
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() =>
            includedFieldList.length && confirmDriverAndVINMatch()
          }>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontFamily: 'FiraSans-Medium',
              fontSize: '0.9375rem',
              fontWeight: '500',
              height: '22px',
              letterSpacing: '1px',
              lineHeight: '22px',
              textAlign: 'center',
            }}>
            CONFIRM MATCHES
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(MatchDriversAndVinsMultiple);
