import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import {
  ChangePasswordInput,
  CreatePasswordFormInputs,
  GetUsersProfileInfoDTO,
  ResetPasswordFormInputs,
  UpdateUserInput,
} from '../../types';
import { RootStore } from '..';

export type UserApiInstance = InstanceType<typeof UserApiStore>;

export class UserApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getUsersProfilelInfo = () => {
    const {
      ProfileInfo: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<GetUsersProfileInfoDTO>({
      method: Method,
      url: Endpoint,
    });
  };

  changePassword = (values: ChangePasswordInput) => {
    const {
      ChangePassword: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<void>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  updateProfileInfo = (values: UpdateUserInput) => {
    const {
      UpdateProfileInfo: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<void>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };
  updateMedicalRequest = (values: any) => {
    const {
      UpdateMedicalInfo: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<void>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };
  resetPassword = (values: ResetPasswordFormInputs) => {
    const {
      ResetPassword: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<any>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  forgotPassword = (values: any) => {
    const {
      ForgotPassword: { Endpoint, Method },
    } = ApiRoutes.User;

    return this.rootStore.apiStore.call<any>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  createPassword = (values: CreatePasswordFormInputs) => {
    const {
      CreatePassword: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<any>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  getDOTpin = () => {
    const {
      Credentials: {
        DOTpin: {
          GET: { Endpoint: url, Method: method },
        },
      },
    } = ApiRoutes;
    return this.rootStore.apiStore.call<{ response: DOTPin }>({
      method,
      url,
    });
  };

  updateDOTpin = (data: DotPinPutPayload) => {
    const {
      Credentials: {
        DOTpin: {
          PUT: { Endpoint: url, Method: method },
        },
      },
    } = ApiRoutes;
    return this.rootStore.apiStore.call<{ response: DOTPin }>({
      data,
      method,
      url,
    });
  };

  updateFMCSA = (data: FMCSAPutPayload) => {
    const {
      Credentials: {
        FMCSA: {
          PUT: { Endpoint: url, Method: method },
        },
      },
    } = ApiRoutes;
    return this.rootStore.apiStore.call<{ response: FMCSA }>({
      data,
      method,
      url,
    });
  };

  updateFMCSALoginGov = (data: FMCSAPutPayload) => {
    const {
      Credentials: {
        FMCSA: {
          POST: { Endpoint: url, Method: method },
        },
      },
    } = ApiRoutes;
    return this.rootStore.apiStore.call<{ response: CredentialLoginResponse }>({
      data,
      method,
      url,
    });
  };

  postFMCSAAuthenticationConfirm = (data: FmcsaAuthentication) => {
    const {
      Credentials: {
        FMCSA: {
          AUTH: { Endpoint: url, Method: method },
        },
      },
    } = ApiRoutes;
    return this.rootStore.apiStore.call<{
      response: FmcsaAuthenticationResponse;
    }>({
      data,
      method,
      url,
    });
  };

  getFMCSA = () => {
    const {
      Credentials: {
        FMCSA: {
          GET: { Endpoint: url, Method: method },
        },
      },
    } = ApiRoutes;
    return this.rootStore.apiStore.call<{ response: FMCSA }>({
      method,
      url,
    });
  };
}
