import { ArrowForwardIos, Search } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Button,
  InputAdornment,
  Tooltip,
  // List,
  // ListItemButton,
  // ListItemText,
  // Typography,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/styles';
import React, { useEffect, useMemo, useState } from 'react';

const Article = styled('article')(() => ({
  height: '100%',
  marginRight: '20px',
  position: 'fixed',
  zIndex: 9,
}));

const Filter = styled('section')(() => ({
  backgroundColor: '#FFF',
  boxShadow: '#ebe6e6 1px 5px 11px 6px',
  height: '100%',
  paddingBottom: 150,
  width: 250,
}));

const ToggleButton = styled('button')(() => ({
  backgroundColor: '#c9c8c8',
  borderRadius: 5,
  borderWidth: 0,
  cursor: 'pointer',
  margin: '10px 0',
  padding: '5px 10px',
}));

const SearchType = styled('div')(() => ({
  padding: 10,
}));

const Input = styled(OutlinedInput)(() => ({
  border: '1px solid #cecccc',
}));

const ListX = styled('ul')(() => ({
  height: '100%',
  overflowY: 'scroll',
}));

const ListI = styled('li')(() => ({
  cursor: 'pointer',
  display: 'flex',
  fontSize: 14,
  fontWeight: 'bold',
  justifyContent: 'space-between',
  padding: 0,
}));

const ListButton = styled(Button)(() => ({
  color: '#000',
  display: 'flex',
  fontWeight: 'bolder',
  justifyContent: 'space-between',
  padding: '10px',
  width: '100%',
}));

type Props = {
  data: any[];
  showFilter: boolean;
  onToggle: () => void;
  onSelect: (selectedType?: string) => void;
  title: string;
  defaultSelectedType: string;
};

const SearchTypes: React.FC<Props> = ({
  data,
  onToggle,
  onSelect,
  showFilter,
  title = '',
  defaultSelectedType,
}) => {
  const [searchString, setSearchString] = useState('');
  const [selectedType, setSelectedType] = useState<{
    key: string;
    keyCode: string;
  }>();
  const [options, setOptions] = useState<any[]>();

  const totalSum = useMemo(
    () =>
      data.reduce(
        (prev: number, current: { value: number }) => prev + current.value,
        0,
      ),
    [data],
  );

  useEffect(() => {
    setOptions([...data]);
  }, [data]);

  useEffect(() => {
    if (!selectedType) {
      if (defaultSelectedType) {
        const selected = data.find((el) => el.keyCode === defaultSelectedType);
        if (selected) {
          setSelectedType({
            key: selected.key,
            keyCode: selected.keyCode,
          });
        }
      } else {
        setSelectedType({
          key: 'All',
          keyCode: 'All',
        });
      }
    }
  }, [defaultSelectedType, data, selectedType]);

  const handleSearch = (event: any) => {
    const text = event.target.value;
    setSearchString(text);
    if (text) {
      const temps = data.filter((el: any) => {
        const term = el.key.toLocaleLowerCase() || '';
        return term.includes(text.toLocaleLowerCase());
      });
      setOptions(temps);
    } else {
      setOptions([...data]);
    }
  };

  return (
    <Article id="trend-details-search-types" className="flex-row">
      {showFilter && (
        <Filter>
          <SearchType>
            <Input
              fullWidth
              value={searchString}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              placeholder={`Search ${title}`}
              inputProps={{
                'aria-label': 'weight',
              }}
            />
          </SearchType>
          <ListX id="list">
            {!searchString && !defaultSelectedType && (
              <ListI
                className={
                  selectedType?.keyCode === 'All' ? 'selected-search-type' : ''
                }
                key={-1}
                onClick={() => {
                  setSelectedType({ key: 'All', keyCode: 'All' });
                  onSelect('All');
                }}>
                <ListButton fullWidth>
                  <p>All</p>
                  <p>{totalSum}</p>
                </ListButton>
              </ListI>
            )}
            {options?.map((el, index) => (
              <ListI
                className={
                  selectedType?.keyCode === el?.keyCode
                    ? 'selected-search-type'
                    : ''
                }
                key={index}
                onClick={() => {
                  setSelectedType(el);
                  onSelect(el.keyCode === null ? 'null' : el.keyCode);
                }}>
                {(el.keyDescription && (
                  <Tooltip title={el.keyDescription} placement="right">
                    <ListButton fullWidth>
                      <p>{el.key}</p>
                      <p>{el.value}</p>
                    </ListButton>
                  </Tooltip>
                )) || (
                  <ListButton fullWidth>
                    <p>{el.key}</p>
                    <p>{el.value}</p>
                  </ListButton>
                )}
              </ListI>
            ))}
          </ListX>
        </Filter>
      )}
      <section>
        <ToggleButton onClick={onToggle}>
          {showFilter ? (
            <ArrowBackIosIcon fontSize="small" />
          ) : (
            <ArrowForwardIos fontSize="small" />
          )}
        </ToggleButton>
      </section>
    </Article>
  );
};

export default SearchTypes;
