import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { SelectDatePicker } from '../../../../components/Forms/datePicker';
import { InputText } from '../../../../components/Forms/InputText';
import { UseFocus, useMountEffect } from '../../../../hooks';
import useStyles from '../ProfileStyles';

const OwnerFormInfo: React.FC<{
  control: any;
  errors: any;
  setValue: any;
  UserData: any;
}> = ({ control, errors, setValue, UserData }) => {
  const classes = useStyles();

  const [input1Ref, setInput1Focus] = UseFocus();

  const getMaxDate = () => {
    const todayDate = new Date();
    const dateToPrint = new Date(todayDate);
    dateToPrint.setMonth(dateToPrint.getMonth() - 12 * 18);
    return dateToPrint;
  };
  const handleGetInitialValues = useCallback(
    (userdata) => {
      if (userdata) {
        const {
          address,
          city,
          dob,
          email,
          firstName,
          lastName,
          phoneNumber,
          state,
          zip,
        } = userdata;
        setValue('address', address);
        setValue('city', city);
        setValue('dob', dob);
        setValue('email', email);
        setValue('firstName', firstName);
        setValue('lastName', lastName);
        setValue('phoneNumber', phoneNumber);
        setValue('state', state);
        setValue('zip', zip);
      }
    },
    [setValue],
  );

  useEffect(() => {
    handleGetInitialValues(UserData);
  }, [handleGetInitialValues, UserData]);

  useMountEffect(setInput1Focus);

  return (
    <>
      <Grid container style={{ padding: '0.5rem 0' }}>
        <Grid item md={6} style={{ paddingRight: '0.5rem' }}>
          <Box className={classes.labels}>First Name *</Box>
          <Controller
            control={control}
            name="firstName"
            defaultValue=""
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={input1Ref}
                  errors={errors.firstName?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} style={{ paddingLeft: '0.5rem' }}>
          <Box className={classes.labels}>Last Name *</Box>
          <Controller
            control={control}
            name="lastName"
            defaultValue=""
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.lastName?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <Grid item md={6} style={{ paddingRight: '0.5rem' }}>
          <Box className={classes.labels}>Email *</Box>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.email?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'email'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid
          item
          md={3}
          style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
          <Box className={classes.labels}>Phone Number *</Box>
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue=""
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.phoneNumber?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item md={3} style={{ paddingLeft: '0.5rem' }}>
          <Box className={classes.labels}>DOB *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="dob"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectDatePicker
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.dob?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  maxDate={getMaxDate()}
                  value={value}
                  onChangeDate={(e) => {
                    onChange(e);
                  }}
                  disabled={false}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <Grid item md={5} style={{ paddingRight: '0.5rem' }}>
          <Box className={classes.labels}>Address *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="address"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.address?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid
          item
          md={3}
          style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
          <Box className={classes.labels}>City *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="city"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.city?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid
          item
          md={2}
          style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
          <Box className={classes.labels}>State *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="state"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.state?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item md={2} style={{ paddingLeft: '0.5rem' }}>
          <Box className={classes.labels}>ZIP *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="zip"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.zip?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OwnerFormInfo;
