import '@react-pdf-viewer/core/lib/styles/index.css';

import ClearIcon from '@mui/icons-material/Clear';
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ProgressBar, RenderPageProps, Viewer } from '@react-pdf-viewer/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useState } from 'react';

import { ApiRoutes } from '../../../../config/ApiRoutes';
import {
  AllowedImageFileTypes,
  AllowedPdfFileTypes,
  Config,
} from '../../../../config/Constants';
import { useCustomStore } from '../../../../hooks';
import { DocumentsListReponseDTO } from '../../../../types';
import useStyles from '../documentsStyle';
import uploadIcon from './../../../../assets/images/upload.svg';

type TableBodyProps = {
  records: Array<DocumentsListReponseDTO> | null;
  enqueueSnackbar: any;
};

const TableBodyContent: React.FC<TableBodyProps> = (props: TableBodyProps) => {
  const { records } = props;
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [fileId, setFileId] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewFileName, setFileName] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const { authStore } = useCustomStore();
  const handleDownload = (id: string) => {
    const tokens = authStore.tokens;
    const url = `${Config.API_URL}${
      ApiRoutes.Documents.downloadDocument.Endpoint
    }?id=${id}&accessToken=${tokens?.accessToken ?? ''}`;
    window.open(url);
  };

  const handleOpen = (id: string, fileName: string) => {
    const tokens = authStore.tokens;
    const url = `${Config.API_URL}${
      ApiRoutes.Documents.downloadDocument.Endpoint
    }?id=${id}&accessToken=${tokens?.accessToken ?? ''}`;
    setUrl(url);
    setFileId(id);
    setFileName(fileName);
    setFileType(fileName.split('.').pop()?.toLowerCase() || '');
    setOpenPreview(true);
  };

  const handleClose = () => {
    setOpenPreview(false);
  };
  return (
    <>
      <TableBody>
        {records ? (
          records.length ? (
            records.map((row, index) => (
              <TableRow key={index.toString()} className={classes.tableRow}>
                <TableCell>
                  <Tooltip title={row.fileName} placement="top">
                    <Typography
                      component={'a'}
                      onClick={() => handleOpen(row.id, row.fileName)}
                      target={'_blank'}
                      style={{
                        color: '#0061FF',
                        cursor: 'pointer',
                        fontFamily: 'FiraSans-SemiBold',
                        fontSize: '1rem !important',
                        textDecoration: 'underline',
                        width: '350px',
                        wordBreak: 'break-all',
                      }}>
                      {row.fileName
                        ? row.fileName.length > 30
                          ? row.fileName.substring(0, 30).concat('...')
                          : row.fileName
                        : ''}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>{row.tags ? row.tags : '-'}</TableCell>
                <TableCell>{row.fileSize ? row.fileSize : '-'}</TableCell>
                <TableCell>
                  {row.uploadedOn
                    ? moment(row.uploadedOn).format('MM/DD/YYYY')
                    : '-'}
                </TableCell>
                <TableCell align="center">
                  <Grid item style={{ position: 'relative' }}>
                    <Box
                      id={'upload'}
                      className={classes.uploadDoc}
                      onClick={() => handleDownload(row.id)}>
                      <img src={uploadIcon} alt="icon" />
                      <span> Download </span>
                    </Box>
                  </Grid>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={5}>
              <div className={classes.notFound}>No data available</div>
            </TableCell>
          )
        ) : (
          <TableRow>
            <TableCell colSpan={5}>
              <div className={classes.loader}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <Backdrop open={openPreview} onClick={handleClose}>
        <Dialog
          open={openPreview}
          fullWidth
          onClose={handleClose}
          maxWidth={
            AllowedPdfFileTypes.includes(fileType)
              ? 'md'
              : AllowedImageFileTypes.includes(fileType)
              ? 'lg'
              : 'sm'
          }>
          <DialogTitle
            style={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '1.375rem',
              letterSpacing: 0,
            }}>
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <span>{previewFileName}</span>
              <ClearIcon
                style={{
                  color: '#979797',
                  cursor: 'pointer',
                  fontSize: '2rem',
                }}
                color="inherit"
                component="svg"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            {url && fileType === 'pdf' ? (
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                  // border: '1px solid rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: '100%',
                }}>
                <Viewer
                  fileUrl={url}
                  renderLoader={(percentages: number) => (
                    <div style={{ width: '600px' }}>
                      <ProgressBar progress={Math.round(percentages)} />
                    </div>
                  )}
                  renderPage={(props: RenderPageProps) => {
                    return (
                      <>
                        {props.canvasLayer.children}
                        <div style={{ display: 'none' }}>
                          {props.textLayer.children}
                        </div>
                        {props.annotationLayer.children}
                      </>
                    );
                  }}
                />
              </div>
            ) : url && AllowedImageFileTypes.includes(fileType) ? (
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: '100%',
                }}>
                <img
                  src={url}
                  alt=""
                  style={{
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  color: 'GrayText',
                  display: 'flex',
                  fontSize: '1.3rem',
                  height: '100px',
                  justifyContent: 'center',
                }}>
                Please
                <div
                  style={{
                    color: '#0061FF',
                    cursor: 'pointer',
                    display: 'inline-block',
                    fontFamily: 'FiraSans-SemiBold',
                    fontSize: '1.3rem',
                    padding: '0 5px',
                    textDecoration: 'underline',
                  }}
                  onClick={() => handleDownload(fileId)}>
                  Download
                </div>
                to View this Document Type
              </div>
            )}
          </DialogContent>
        </Dialog>
      </Backdrop>
    </>
  );
};
export default observer(TableBodyContent);
