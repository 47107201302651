import { Button, Grid, Menu, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import search from './../../../assets/images/h-search.svg';
import useStyles from './documentsStyle';

type DocumentFilterProps = {
  setAnchorEl: (src: any) => void;
  anchorEl: any;
  open: boolean | any;
  filters: any;
  setFilters: (src: any) => void;
  storeFilter: any;
};

const DocumentsFilter: React.FC<DocumentFilterProps> = (
  props: DocumentFilterProps,
) => {
  const { anchorEl, filters, open, setAnchorEl, setFilters, storeFilter } =
    props;
  const classes = useStyles();

  const setTags = (title: string) => {
    const arr = [...filters.tags];
    if (arr.some((e: any) => e === title)) {
      const index = arr.findIndex((e) => e === title);
      arr.splice(index, 1);
    } else {
      arr.push(title);
    }
    setFilters((pre: any) => ({
      ...pre,
      tags: arr,
    }));
  };
  useEffect(() => {
    setFilters((pre: any) => ({
      ...pre,
      fileName: storeFilter && storeFilter.fileName ? storeFilter.fileName : '',
      tags:
        storeFilter && storeFilter.tags && storeFilter.tags.length
          ? storeFilter.tags
          : [],
    }));
  }, [open, setFilters, storeFilter]);
  return (
    <Menu
      id="basic-menu"
      className="filter"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      <Grid style={{ padding: '1rem' }}>
        <Typography component={'h3'}>TAGS</Typography>
        <Typography
          component={'div'}
          className={classes.borderBottom}
          style={{ padding: '.5rem 0px 1rem 0rem' }}>
          {[
            'Annual',
            'Annual Inspection',
            'APTO',
            'Articles',
            'Authority Letter',
            'Background Authorization',
            'Bond-Brokerage',
            'Bond-OR',
            'CA # Letter',
            'Cab Card',
            'Certificate of Status',
            'Clearinghouse Query',
            'COI',
            'Driver',
            'Driver Application',
            'Driver License',
            'DOT',
            'DQ',
            'ELD Report',
            'EPN Report',
            'Equipment Lease',
            'Fictitious Name Letter',
            'FEIN Letter',
            'Fuel Card Report',
            'Fuel Receipts',
            'Fuel Taxes',
            'IFTA',
            'Hazmat',
            'IRP',
            'HOS Report',
            'KY',
            'Local Tag',
            'Lease Agreement',
            'Lease Termination',
            'Medical Card',
            'Medical Long Form',
            'MVR',
            'NJ Business Registration',
            'NJ Foreign Entity',
            'NM',
            'NY',
            'OR',
            'Participation Letter',
            'Pre-employment',
            'Permanent',
            'Permits',
            'Periodic Inspection',
            'PEV Report',
            'Pool Membership',
            'Post Accident',
            'Power Unit',
            'PSP Authorization',
            'PSP Result',
            'Random',
            'Random Selection',
            'Reasonable Suspicion',
            'Refusal to Test',
            'Result-Background Check',
            'Result-BAT',
            'Result-DT',
            'Return to Duty',
            'RKA',
            'Road Taxes',
            'Safety Policy',
            'Safety Lease',
            'SAP',
            'SCAC',
            'Temporary',
            'Title',
            'Trailer',
            'Training Acknowledgement',
            'Training Certificate',
            'Trip Sheets',
            'VQ',
          ].map((title, index) => (
            <Grid
              key={index}
              item
              className={classes.dflexA}
              onClick={() => setTags(title)}>
              <input
                type="checkbox"
                readOnly
                tabIndex={index}
                checked={filters.tags.some((val: string) => val === title)}
              />
              <Typography component={'div'} style={{ cursor: 'pointer' }}>
                {title}
              </Typography>
            </Grid>
          ))}
        </Typography>
        <Typography component={'h3'}>FILE NAME</Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filteIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <TextField
                type={'text'}
                sx={{
                  input: { backgroundColor: 'unset', color: '#fff' },
                }}
                // value={filters.fileName}
                defaultValue={
                  storeFilter && storeFilter.fileName
                    ? storeFilter.fileName
                    : ''
                }
                className={classes.inputF}
                placeholder="Search"
                onChange={(e: any) =>
                  setFilters((pre: any) => ({
                    ...pre,
                    fileName: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} className={classes.resetF}>
            <Button
              variant={'text'}
              className={'d-flex-ja '.concat(classes.resetF)}
              onClick={() => setAnchorEl('reset')}
              disabled={
                !(
                  storeFilter.fileName ||
                  (storeFilter.tags && storeFilter.tags.length)
                )
              }>
              RESET FILTERS
            </Button>
          </Grid>
          <Grid item md={6}>
            <Typography
              component={'button'}
              className={classes.filterBtn}
              style={{
                backgroundColor: !(
                  filters.fileName ||
                  (filters.tags && filters.tags.length)
                )
                  ? '#d3d3d3'
                  : '#DEC330',
              }}
              onClick={() => setAnchorEl('apply')}
              disabled={
                !(filters.fileName || (filters.tags && filters.tags.length))
              }>
              APPLY FILTER
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Menu>
  );
};

export default observer(DocumentsFilter);
