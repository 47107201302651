import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  fileInfo: {
    alignItems: 'center',
    color: '#979598',
    display: 'flex',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.875rem',
    justifyContent: 'space-between',
    textAlign: 'end',
  },
  fileName: {
    color: '#0061FF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.875rem',
    fontWeight: 500,
    wordBreak: 'break-all',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  gridheadingFlex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadDoc: {
    '& span': {
      marginLeft: '3px',
    },
    alignItems: 'end',
    color: '#0061FF',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-SemiBold',
    fontSize: '0.875rem',
    fontWeight: 600,
    textDecoration: 'underLine',
  },
});

export default useStyles;
