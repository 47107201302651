import { FormControl, Grid, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { InputText } from '../../../../Forms/InputText';
import search from './../../../../../assets/images/h-search.svg';

type Props = {
  classes: any;
  control: Control;
  label: string;
  name: string;
};

const TextInput: React.FC<Props> = ({ classes, control, label, name }) => {
  return (
    <FormControl fullWidth>
      <Typography
        component={'h3'}
        sx={{
          color: '#FFFFFF',
          fontFamily: 'FiraSans-Medium',
          fontSize: '15px',
          fontWeight: '500',
          height: '22px',
          letterSpacing: '1px',
          lineHeight: '22px',
          textTransform: 'capitalize',
          width: '166px',
        }}>
        {label}
      </Typography>
      <Grid className={classes.borderBottom}>
        <Grid container className={classes.searcBox}>
          <Grid item md={2} className={classes.dflexJA}>
            <img className={classes.filterIcon} src={search} alt="search" />
          </Grid>
          <Grid item md={10}>
            <Controller
              control={control}
              defaultValue=""
              name={name}
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    inputRefObj={ref}
                    name={name}
                    isRefreshValue={true}
                    // defaultValue={filter.name}
                    value={value}
                    placeholder="Search"
                    onChangeText={(e: any) => {
                      onChange(e);
                    }}
                    isEditable={false}
                    className={classes.inputF}
                    type={'text'}
                    variant="outlined"
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default TextInput;
