import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PrivateRoutes } from '../../../../config';
import NavRoutes from '../../../../config/routes/NavRoutes';
import { useCustomStore } from '../../../../hooks';
import RecommendedRoutes from './RecommendedRoutes';
import useStyles from './RecommendedStyles';

const Recommended: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { trainingStore } = useCustomStore();

  return (
    <div id="training-recommended-routes">
      <Typography component={'div'} className={classes.flex}>
        <Typography
          component={'div'}
          className={
            (location.pathname ===
              `${PrivateRoutes.TRAINING.RECOMMENDED.INSPECTIONS as string}` &&
              classes.activeRoute) ||
            classes.inactiveRoute
          }
          onClick={() => {
            trainingStore.resetTrainingInspectionFilters();
            navigate(`./${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.INSPECTIONS}`);
          }}>
          INSPECTIONS
        </Typography>
        <Typography
          component={'div'}
          className={
            (location.pathname ===
              `${PrivateRoutes.TRAINING.RECOMMENDED.ACCIDENTS as string}` &&
              classes.activeRoute) ||
            classes.inactiveRoute
          }
          style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
          onClick={() => {
            trainingStore.resetTrainingAccidentFilters();
            navigate(`./${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.ACCIDENTS}`);
          }}>
          ACCIDENTS
        </Typography>
      </Typography>
      <RecommendedRoutes />
    </div>
  );
};

export default observer(Recommended);
