import { makeAutoObservable } from 'mobx';

import { API_LIMIT } from '../config';
import { DotActivityListFilters, DotActivityListResponseDTO } from '../types';
import { RootStore } from '.';

export class DotActivityListStore {
  private _activityList: Array<DotActivityListResponseDTO> = [];
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';
  private _filter: DotActivityListFilters = {
    categories: [],
    dateSort: 'desc',
    driver: '',
    lastSubmittedBy: '',
    limit: API_LIMIT,
    nextLink: this.NextLink,
    submittedBy: '',
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setActivityList(data: Array<DotActivityListResponseDTO>) {
    this._activityList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setFilters(data: DotActivityListFilters) {
    this._filter = data;
  }
  setActivityListApiReset() {
    this.setNextLink('');
    this.setHasMoreResults(true);
    this.setActivityList([]);
    this.setFilters({
      ...this.Filters,
      categories: [],
      driver: '',
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: this.NextLink,
      submittedBy: '',
    });
  }
  get ShowLoader() {
    return this._showLoader;
  }

  get ActivityList() {
    return this._activityList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get Filters() {
    return this._filter;
  }
  async fetchActivityLists() {
    this.setNextLink('');
    const response =
      await this.rootStore.apiStore.dotActivityListApiStore.getActivityListList(
        this.Filters,
      );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = results;
      this.setActivityList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchActivityListsBySearchBased() {
    const response =
      await this.rootStore.apiStore.dotActivityListApiStore.getActivityListList(
        this.Filters,
      );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._activityList.concat(results);
      this.setActivityList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }
}
