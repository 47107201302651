import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: {
    border: '1px solid #E8E8E8',
    marginTop: '31px !important',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '12px',
    lineHeight: '24px',
  },
  link: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '12px !important',
    marginTop: '1rem',
    textDecoration: 'underline solid #0061ff',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  selectClassName: {
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
  },
}));

export default useStyles;
