import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  Adriver: {
    alignItems: 'center',
    borderBottom: '5px solid #DEC330',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Semibold !important',
    fontSize: '15px',
    fontWeight: 500,
    height: '80px',
    justifyContent: 'center',
    letterSpacing: '1px',
    lineHeight: '22px',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
  Ndriver: {
    alignItems: 'center',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Semibold',
    fontSize: '15px',
    fontWeight: 500,
    height: '80px',
    justifyContent: 'center',
    letterSpacing: '1px',
    lineHeight: '22px',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
  activeBox: {
    alignItems: 'center',
    backgroundColor: '#E8932E !important',
    borderRadius: '7px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.93px',
    opacity: '1 !important',
    width: '85px',
  },
  activeTab: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF !important',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.18)',
    color: '#241A2E !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.87rem !important',
    height: '40px',
    justifyContent: 'center',
    width: '118px',
  },
  // menu: {
  //   '& .MuiMenu-list': {
  //     display: 'contents !important',
  //   },
  // },
  // sideBarIcon: {
  //   display: 'none !important',
  //   [theme.breakpoints.down('md')]: {
  //     display: 'block !important',
  //   },
  // },
  borderBottom: {
    borderBottom: '1px solid #403849',
    marginBottom: '1rem !important',
  },
  createBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#FFFFFF !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    width: '171px',
  },
  dayBox: {
    alignItems: 'center',
    backgroundColor: '#403849',
    borderRadius: '7px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.93px',
    opacity: '0.43',
    width: '85px',
  },

  dflexA: {
    alignItems: 'center',
    display: 'flex',
  },

  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  fIcon: {
    alignItems: 'center',
    border: '2px solid #E7E7E7',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
  },

  ficonz: {
    height: '23px',
    width: '28px',
  },

  filterBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#fff !important',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },

  hIcon: {
    cursor: 'pointer',
    paddingRight: '0.5rem',
  },

  hTitle: {
    alignItems: 'center',
    color: '#241a2e',
    display: 'flex',
    fontFamily: 'FiraSans-Bold',
    fontSize: '1.25rem',
    textTransform: 'capitalize',
  },

  header: {
    ['@media (max-width:899px)']: {
      left: '0px !important',
    },
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ededed',
    borderTop: '5px solid #DEC330',
    display: 'flex',
    height: '80px',
    justifyContent: 'space-between',
    left: '245px',
    padding: '0 1.5rem 0 2rem',
    position: 'fixed',
    right: '0',
    // top: '0',
    zIndex: '100',
  },
  inActiveTab: {
    alignItems: 'center',
    backgroundColor: '#F8F8FA !important',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.18)',
    color: '#979598 !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.87rem !important',
    height: '40px',
    justifyContent: 'center',
    width: '118px',
  },
  resetF: {
    alignItems: 'center',
    color: '#777777 !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Bold',
    fontSize: '0.75rem !important',
    justifyContent: 'center !important',
    letterSpacing: '0.8px',
    textDecoration: 'underline solid #777777',
  },
  todoTab: {
    alignItems: 'center',
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #EDEDED',
    borderRadius: '4px',
    display: 'flex',
    height: '44px',
    marginLeft: '1rem !important',
    width: '234px',
  },
}));

export default useStyles;
