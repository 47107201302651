import { makeAutoObservable } from 'mobx';

import { RootStore } from './';

type SearchParams = {
  description?: string;
};
export class ViolationsStore {
  private _list: Violation[];
  private _limit = 20;
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';
  // private _equipmentSelectedList: Array<any> = [];
  private _sortBy = '';
  private _sortDirection = '';
  private _description = '';

  constructor(public rootStore: RootStore) {
    this._list = [];
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  resetSortParams() {
    this._sortBy = '';
    this._sortDirection = '';
  }
  resetSearchParams() {
    this._description = '';
  }
  setSearchParams(data: string) {
    this._description = data;
  }

  get Data() {
    return this._list;
  }

  get NextLink() {
    return this._nextLink;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get SearchParams() {
    return this._description;
  }

  resetParams() {
    this._nextLink = '';
    this._list = [];
  }

  getList = async (searchParams: SearchParams = {}) => {
    const { description = '' } = searchParams;
    this._description = description || this._description;
    const params = {
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._description ? { description: this._description } : {}),
    };
    const { list, nextLink } =
      await this.rootStore.apiStore.violationsApi.getData(params);
    this._list = [...this._list, ...list];
    if (nextLink === this._nextLink || list.length < this._limit) {
      this._nextLink = '';
    } else {
      this._nextLink = nextLink;
    }
  };

  getMoreDetailsByViolationId = (id: string) =>
    this.rootStore.apiStore.violationsApi.getMoreDetails(id);

  exportList = async (searchParams: SearchParams = {}) => {
    const { description = '' } = searchParams;
    this._description = description || this._description;
    const params = {
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(this._description ? { description: this._description } : {}),
    };
    return await this.rootStore.apiStore.violationsApi.export(params);
  };
}
