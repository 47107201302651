import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { DotActivityListDTO, DotActivityListFilters } from '../../types';
import { RootStore } from '..';

export class DotActivityListApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getActivityListList = (params: DotActivityListFilters) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.DotActivityList;
    const restParams = this.objClean({ ...params });
    return this.rootStore.apiStore.call<DotActivityListDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };
  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }
  //   getActivityListBySearch = (params: {
  //     limit: number;
  //     nextLink: string;
  //     searchField: string;
  //     searchTerm: string;
  //   }) => {
  //     const {
  //       GetListBySearch: { Endpoint, Method },
  //     } = ApiRoutes.ActivityList;

  //     // This is static response once you have the api remove this code and uncomment the commented code
  //     return this.rootStore.apiStore.call<ActivityListDTO>({
  //       method: Method,
  //       params,
  //       url: Endpoint,
  //     });
  //   };
}
