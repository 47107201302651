import { makeAutoObservable, toJS } from 'mobx';

import { RootStore } from './';

const initialData: any = {
  drver: [],
  fleet: [],
};

export class DriverOwnerNotification {
  private _adminNotification: any = { ...initialData };
  private _selectedNotification: any = null;
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  getNotificationDetails(): any {
    return { ...toJS(this._adminNotification) };
  }

  get getSelectedNotification(): any {
    const existedSelectedNotification = { ...this._selectedNotification };
    this.setSelectedNotification();
    return existedSelectedNotification;
  }

  setSelectedNotification(data: any = null) {
    this._selectedNotification = data;
  }

  async getInitialData() {
    const initialData: any =
      await this.rootStore.apiStore.adminNotificationApiStore.getInitialData({
        description: '',
        limit: 0,
        nextLink: '',
        onlySplash: '',
        title: '',
      });
    if (initialData.isOk()) {
      this._adminNotification = { ...initialData };
    }

    if (initialData.isErr()) {
      this._adminNotification = { ...initialData };
    }

    return initialData;
  }
}
