import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { PrivateRoutes } from '../../config';
import { useCustomStore } from '../../hooks';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import { AssignTrainingSchema } from '../../utils/ValidatorSchema';
import DialogMigrate from '../Dialog/DialogMigrate';
import { SelectDatePicker } from '../Forms/datePicker';
import { SelectInput } from '../Forms/selectInput';
import useStyles from './AssignTrainingDialogStyles';
import SelectAssignee from './SelectAssignee';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

type AssignTrainingDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
};

const AssignTodoDialog: React.FC<any> = (props: AssignTrainingDialogProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { trainingStore } = useCustomStore();
  const { isOpen, closeDialog } = props;

  const [reasons, setReasons] = useState<any[]>([]);
  const [selectedUserType, setSelectedUserType] = useState<
    'backOfficePersonnel' | 'driver' | null
  >(null);
  const [trainings, setTrainings] = useState<any[]>([]);
  const [loader, showLoader] = useState<boolean>(false);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{
    contactId: string;
    dueDate: string;
    reasonId: string;
    trainingId: string;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      contactId: '',
      dueDate: '',
      reasonId: '',
      trainingId: '',
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(AssignTrainingSchema),
  });

  const reasonIdWatch = watch('reasonId');
  const contactIdWatch = watch('contactId');
  const trainingIdWatch = watch('trainingId');

  const getTrainingAssignmentReasons = useCallback(
    async (userType?: number) => {
      const resp = await trainingStore.getTrainingAssignmentReasons({
        showSystemReason: false,
        userType,
      });
      if (resp.isErr()) {
        enqueueSnackbar(
          'Unable to fetch Training Assignment Reasons',
          SnackBarConfig('e'),
        );
      } else {
        setReasons(trainingStore.TrainingAssignmentReasonsData);
      }
    },
    [enqueueSnackbar, trainingStore],
  );

  const getAllTrainingsForCustomAssignment = useCallback(async () => {
    const formValues = getValues();
    if (formValues.contactId && formValues.reasonId) {
      setTrainings([]);
      const resp = await trainingStore.getAllTrainingsForCustomAssignment({
        contactId: formValues.contactId,
        reasonId: formValues.reasonId,
      });
      if (resp.isErr()) {
        enqueueSnackbar('Unable to fetch Trainings', SnackBarConfig('e'));
      } else {
        setTrainings(resp.value.response);
      }
    }
  }, [enqueueSnackbar, getValues, trainingStore]);

  useEffect(() => {
    getTrainingAssignmentReasons();
  }, [getTrainingAssignmentReasons]);

  const SelectedTraining = (): string => {
    const formValues = getValues();
    if (formValues.trainingId) {
      const training: any = trainings.find(
        (v) => v.trainingId === formValues.trainingId,
      );
      return `${(training?.lastTakenStatus || '-') as string} ${
        (training?.lastTakenDate || '') as string
      }`;
    }
    return '-';
  };

  const onChangeReason = (val: string): void => {
    setSelectedUserType(reasons.find((v) => v.id === val)?.userType || null);
    setTrainings([]);
    setValue('contactId', '');
    setValue('trainingId', '');
    setValue('dueDate', '');
  };

  const onChangeContact = (contact: any) => {
    if (typeof contact !== 'string' && contact?.id) {
      setValue('contactId', contact.id);
      getAllTrainingsForCustomAssignment();
    } else {
      setValue('contactId', '');
    }
  };

  const onFormSubmit = async (values: any) => {
    showLoader(true);
    const body: any = {
      ...values,
      dueDate: moment(values.dueDate).format('YYYY-MM-DD'),
      isAssigned: true,
    };
    const resp = await trainingStore.createCustomTrainingAssignment(body);
    if (resp.isErr()) {
      enqueueSnackbar('Unable to assign Training TO-DO', SnackBarConfig('e'));
    }
    if (resp.isOk()) {
      enqueueSnackbar('Training TO-DO assigned', SnackBarConfig('s'));
    }
    showLoader(false);
    if (location.pathname === `${PrivateRoutes.TRAINING.ASSIGNED as string}`) {
      trainingStore.setRefreshTrainingAssignedData(true);
    }
    closeDialog();
  };

  return (
    <DialogMigrate
      aria-labelledby="assign-todo-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={closeDialog}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            ASSIGN NEW TRAINING TO-DO
          </div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialog();
            }}>
            <ClearIcon />
          </Link>
        </div>
        {/* <div className={classes.headerSubtext}>
          The driver will have 60 days to complete this training video and quiz.
          Two attempts allowed by user.
        </div> */}
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.dialogContent}>
        <form id="assign-training-dialog" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Reason *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="reasonId"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      isFormSubmittedOnce={!!formState.submitCount}
                      errors={errors?.reasonId?.message || ''}
                      name={name}
                      size={'small'}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val: string) => {
                        onChange(val);
                        onChangeReason(val);
                      }}
                      isEditable={loader}
                      selectClassName={classes.selectClassName}
                      optionsList={reasons.map((r) => ({
                        label: r.name,
                        value: r.id,
                      }))}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Select Assignee *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="contactId"
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <SelectAssignee
                      onChange={(contact: any) => {
                        if (contact?.id) {
                          onChange(contact.id);
                        }
                        onChangeContact(contact);
                      }}
                      value={value}
                      userType={selectedUserType}
                      errors={errors?.contactId?.message}
                      name={name}
                      disable={loader || reasonIdWatch === ''}
                      disableNoneOption={false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Select Training *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="trainingId"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      isFormSubmittedOnce={!!formState.submitCount}
                      errors={errors?.trainingId?.message || ''}
                      name={name}
                      size={'small'}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val: string) => {
                        onChange(val);
                      }}
                      isEditable={loader || contactIdWatch === ''}
                      selectClassName={classes.selectClassName}
                      optionsList={trainings.map((r) => ({
                        label: r.trainingName,
                        value: r.trainingId,
                      }))}
                    />
                  );
                }}
              />
              {trainingIdWatch && SelectedTraining() !== '-' && (
                <Box className={classes.infoTitle}>
                  Latest Results: <span> {SelectedTraining()} </span>
                </Box>
              )}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Due Date *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="dueDate"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      isFormSubmittedOnce={!!formState.submitCount}
                      errors={errors?.dueDate?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeDate={(e): any => {
                        onChange(e);
                      }}
                      disabled={loader}
                      minDate={new Date()}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <BottomButton
          type={'submit'}
          disabled={loader}
          form="assign-training-dialog"
          variant="contained"
          sx={{ width: '100%' }}>
          <Typography className={classes.submitBtnTxt}>
            {loader ? (
              <CircularProgress size={20} sx={{ color: '#DEC330' }} />
            ) : (
              'ASSIGN TRAINING'
            )}
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(AssignTodoDialog);
