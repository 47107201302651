import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '../index';

export class FTQuarterFillingApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  addFTQuarterFilling = (data: any) => {
    const {
      Add: { Endpoint, Method },
    } = ApiRoutes.FTQuarterFilling;
    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: Endpoint,
    });
  };

  getInitialData = (params: any = {}) => {
    const {
      Get: { Endpoint, Method },
    } = ApiRoutes.FTQuarterFilling;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      url: Endpoint,
    });
  };
}
