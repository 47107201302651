import {
  DatePickerProps,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import React from 'react';

const SimplexDatepicker: React.FC<Omit<DatePickerProps, 'renderInput'>> = (
  props,
) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        allowSameDateSelection
        {...props}
        inputFormat="MM/dd/yyyy"
        renderInput={(params) => (
          <TextField onKeyDown={(e: any) => e.preventDefault()} {...params} />
        )}
        //   isRefreshValue={true}
        //   isEditable={false}
        disableHighlightToday
      />
    </LocalizationProvider>
  );
};
export default SimplexDatepicker;
