import { styled } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { VictoryPie, VictoryTooltip } from 'victory';

const Label = styled('div')(() => ({
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'flex-start',
  margin: 5,
  padding: 1,
}));

const LabelContainer = styled('div')(() => ({
  height: '100%',
  justifyContent: 'center',
  overflowY: 'auto',
  position: 'relative',
  width: '40%',
}));

const Div = styled('div')(() => ({
  width: '60%',
  zIndex: 5,
}));

const Circle = styled('div')<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  height: 10,
  width: 10,
}));

const Svg = styled('svg')(() => ({
  left: -50,
  overflow: 'visible',
  position: 'relative',
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.75rem',
  },
}));

const colors = ['#E8932E', '#A70D0D', '#000000', '#D8BF57', '#0DA728'];
// const colorsA = [
//   'rgb(232 147 46)',
//   'rgb(167 13 13)',
//   'rgb(232 147 46)',
//   'rgb(167 13 13)',
//   'rgb(232 147 46)',
// ];
const getIndexColor = (index: number) => {
  if (index < colors.length) {
    return index;
  } else {
    const diff = index - colors.length;
    return diff;
  }
};

type props = {
  trendingData: any;
  onClick: (index: number) => unknown;
};
const TopTrendingChart: React.FC<props> = ({ trendingData, onClick }) => {
  const [externalEventMutations, setExternalEventMutations] = useState<any>();
  const values: { x: string; y: number; keyDescription: string }[] =
    Object.values(trendingData);

  const handleMouseHover = (index: number) => {
    // setSelectedIndex(20);
    // const eventKey: string[] = [];
    // let i = 0;
    // while (i < 10) {
    //   if (i !== index) {
    //     eventKey.push(`${i}`);
    //   }
    //   i++;
    // }
    // setExternalEventMutations([
    //   {
    //     callback: () => setExternalEventMutations(undefined),
    //     eventKey,
    //     mutation: () => ({ style: { fill: 'white', opacity: 0.1 } }),
    //     target: ['data'],
    //   },
    // ]);
    let x = 0;
    const y = [];
    while (x < 10) {
      if (x !== index) {
        y.push({
          callback: () => setExternalEventMutations(undefined),
          eventKey: [`${x}`],
          mutation: () => ({
            style: { fill: 'antiquewhite' },
          }),
          target: ['data'],
        });
      }
      x = x + 1;
    }
    setExternalEventMutations(y);
  };

  const reset = () => {
    let x = 0;
    const y = [];
    while (x < 10) {
      y.push({
        callback: () => setExternalEventMutations(undefined),
        eventKey: [`${x}`],
        mutation: () => ({ style: colors[getIndexColor(x)] }),
        target: ['data'],
      });
      x = x + 1;
    }
    setExternalEventMutations(y);
  };

  return (
    <section className="flex-row-center">
      <Div>
        <Svg width={400} height={400}>
          <text
            x={170}
            y={200}
            style={{
              font: 'bold 36px FiraSans-Bold',
            }}>
            {values.reduce((prev, value) => prev + value.y, 0)}
          </text>
          <text
            x={170}
            y={230}
            style={{
              font: 'bold 24px FiraSans-Bold',
            }}>
            Total
          </text>

          <VictoryPie
            width={400}
            height={400}
            standalone={false}
            events={[
              {
                eventHandlers: {
                  onClick: (event, data: { index: number }) => {
                    onClick(data.index);
                  },
                },
                target: 'data',
              },
            ]}
            externalEventMutations={externalEventMutations}
            labelComponent={
              <VictoryTooltip style={{ fill: 'black' }} flyoutPadding={15} />
            }
            innerRadius={100}
            colorScale={colors}
            padAngle={1}
            data={trendingData}
            labels={({ datum }) => `${datum.x as number}: ${datum.y as number}`}
          />
        </Svg>
      </Div>

      <LabelContainer className="flex-column" style={{ marginLeft: '50px' }}>
        {values.map((value, index) =>
          !value.keyDescription ? (
            <Label
              key={index}
              className="flex-row"
              onClick={() => onClick(index)}
              onMouseLeave={reset}
              onMouseEnter={() => handleMouseHover(index)}>
              <div>
                <Circle color={colors[getIndexColor(index)]} />
              </div>
              <div className="flex-grow-1">
                <h5 className="margin-h-10">{value.x}</h5>
              </div>
              <h5>{value.y} </h5>
            </Label>
          ) : (
            <CustomTooltip
              key={index}
              title={value.keyDescription}
              placement="top"
              arrow
              disableInteractive>
              <Label
                className="flex-row"
                onClick={() => onClick(index)}
                onMouseLeave={reset}
                onMouseEnter={() => handleMouseHover(index)}>
                <div>
                  <Circle color={colors[getIndexColor(index)]} />
                </div>
                <div className="flex-grow-1">
                  <h5 className="margin-h-10">{value.x}</h5>
                </div>
                <h5>{value.y} </h5>
              </Label>
            </CustomTooltip>
          ),
        )}
      </LabelContainer>
    </section>
  );
};

export default TopTrendingChart;
