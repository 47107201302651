import {
  CircularProgress,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import SimplexGrid from '../../../components/SimplexGrid';
import { useCustomStore } from '../../../hooks';
import Inspections from '../InspectionsAndVoilations/Inspections';
import MoreDetailsInspections from '../InspectionsAndVoilations/MoreDetails';
import MoreDetailsViolations from '../Violations/MoreDetails';
import Violation from '../Violations/Violation';
import CrashesDetails from './CrashesDetails';
import TodoCard from './TodoCard';

const applySpacing = (el: any, index: number) => {
  const spacing = [
    100, 100, 100, 100, 200, 200, 100, 100, 250, 100, 100, 100, 100, 100, 100,
  ];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const applySpacingForInspections = (el: any, index: number) => {
  const spacing = [130, 130, 100, 130, 200, 200, 200, 200, 130, 100, 130];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const applySpacingforCrashes = (el: any, index: number) => {
  const spacing = [
    100, 100, 150, 100, 100, 100, 100, 100, 130, 100, 100, 100, 200, 200,
  ];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const applySpacingForTodos = (el: any, index: number) => {
  const spacing = [488, 100, 100, 150, 200];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const spacingDecider: Record<string, (el: any, index: number) => any> = {
  Accidents: applySpacingforCrashes,
  Inspections: applySpacingForInspections,
  ToDos: applySpacingForTodos,
};

const Section = styled('section')(() => ({
  flexGrow: 1,
  height: '100%',
}));

type Props = {
  isTodos: boolean;
  data: TrendGrid;
  title: string;
  isLoading: boolean;
  comingFrom: {
    driver: boolean;
    equipment: boolean;
    top10: boolean;
  };
};

const TrendGrid: React.FC<Props> = ({
  data,
  isTodos = false,
  title,
  isLoading,
  comingFrom,
}) => {
  const { trendsStore: store } = useCustomStore();
  const [prevSortBy, setPrevSortBy] = useState('');

  const navigate = useNavigate();

  const headerKeysWithSpacing = data.columns.map(
    spacingDecider[title] || applySpacing,
  );

  const getDriverCaption = (
    hasOnboarding = false,
    isTrainingAssigned = false,
  ) => {
    if (hasOnboarding) return 'New Driver Onboarding submitted';
    // Need to check this with backend
    // if (isTrainingAssigned) return 'Training Assigned';
    return '';
  };

  const changeDateFormat = (inputDate: any): string | null => {
    // expects Y-m-d
    const splitDate = inputDate?.split('-');
    if (splitDate?.length === 0) {
      return null;
    }

    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];

    return `${month as string}/${day as string}/${year as string}` as string;
  };

  // const getNewInspectionObj = useCallback((el: Inspection) => {
  //   const {
  //     primaryDriver,
  //     secondaryDriver,
  //     inspectionLevel,
  //     powerUnit,
  //     trailer,
  //     isDataQ,
  //     reportNumber,
  //     ...rest
  //   } = el;
  //   const newObj: Partial<InspectionData> = { ...rest };
  //   newObj.inspectionLevel = {
  //     primaryText: inspectionLevel?.level,
  //   };
  //   newObj.primaryDriver = {
  //     primaryText: primaryDriver?.name,
  //     status: primaryDriver?.matchedStatus,
  //   };
  //   newObj.secondaryDriver = {
  //     primaryText: secondaryDriver?.name,
  //     status: secondaryDriver?.matchedStatus,
  //   };
  //   newObj.trailerVin = {
  //     primaryText: trailer?.vin,
  //     status: trailer?.matchedStatus,
  //   };
  //   newObj.powerUnitVin = {
  //     primaryText: powerUnit?.vin,
  //     status: powerUnit?.matchedStatus,
  //   };
  //   newObj.reportNumber = {
  //     caption: isDataQ ? 'DataQ challenge submitted' : '',
  //     primaryText: reportNumber,
  //   };
  //   return newObj as InspectionData;
  // }, []);
  const getNewInspectionObj = useCallback((el: Inspection) => {
    const {
      primaryDriver,
      secondaryDriver,
      inspectionLevel,
      powerUnit,
      trailer,
      isDataQ,
      reportNumber,
      ...rest
    } = el;
    const newObj: Partial<InspectionData> = { ...rest };
    newObj.inspectionLevel = {
      primaryText: inspectionLevel?.name,
    };
    newObj.primaryDriver = {
      primaryText: primaryDriver?.name,
      status: primaryDriver?.matchedStatus,
    };
    newObj.secondaryDriver = {
      primaryText: secondaryDriver?.name,
      status: secondaryDriver?.matchedStatus,
    };
    newObj.trailerVin = {
      primaryText: trailer?.vin,
      status: trailer?.matchedStatus,
    };
    newObj.powerUnitVin = {
      primaryText: powerUnit?.vin,
      status: powerUnit?.matchedStatus,
    };
    newObj.reportNumber = {
      caption: isDataQ ? 'DataQ challenge submitted' : '',
      primaryText: reportNumber,
    };
    // if (status === 'Manage') {
    newObj.primaryDriver.secondaryText = primaryDriver?.matchedName;
    newObj.primaryDriver.caption = getDriverCaption(
      primaryDriver.hasOnboarding,
      primaryDriver.isTrainingAssigned,
    );

    newObj.secondaryDriver.secondaryText = secondaryDriver?.matchedName;
    newObj.secondaryDriver.caption = getDriverCaption(
      secondaryDriver?.hasOnboarding,
      secondaryDriver?.isTrainingAssigned,
    );

    newObj.trailerVin.secondaryText = trailer?.matchedVin;
    newObj.trailerVin.caption = trailer?.hasAddedToFleet
      ? 'Add to Fleet submitted'
      : '';

    newObj.powerUnitVin.secondaryText = powerUnit?.matchedVin;
    newObj.powerUnitVin.caption = powerUnit?.hasAddedToFleet
      ? 'Add to Fleet submitted'
      : '';

    newObj.reportNumber.caption = isDataQ ? 'DataQ challenge submitted' : '';
    // }
    return newObj as InspectionData;
  }, []);

  // const getNewCrashesObj = useCallback((el: Accident) => {
  //   const {
  //     primaryDriver,
  //     powerUnitVin,
  //     powerUnitVinMatchedStatus,
  //     primaryDriverMatchedStatus,
  //     ...rest
  //   } = el;
  //   const newObj: Record<string, any> = { ...rest };
  //   newObj.primaryDriver = {
  //     primaryText: primaryDriver,
  //     status: primaryDriverMatchedStatus,
  //   };
  //   newObj.powerUnitVin = {
  //     primaryText: powerUnitVin,
  //     status: powerUnitVinMatchedStatus,
  //   };
  //   return newObj as InspectionData;
  // }, []);
  const getNewCrashesObj = useCallback((el: Accident) => {
    const {
      primaryDriver,
      primaryDriverMatched,
      primaryDriverMatchedStatus,
      powerUnitVin,
      powerUnitVinMatched,
      powerUnitVinMatchedStatus,
      accidentNumber,
      accidentDate,
      hasPrimaryDriverOnboarding,
      hasPowerUnitAddedToFleet,
      ...rest
    } = el;
    const isDataQ = false;
    const newObj: Partial<any> = { ...rest };
    newObj.accidentDate = changeDateFormat(accidentDate);
    newObj.primaryDriver = {
      primaryText: primaryDriver,
      status: primaryDriverMatchedStatus,
    };
    newObj.powerUnitVin = {
      primaryText: powerUnitVin,
      status: powerUnitVinMatchedStatus,
    };
    newObj.accidentNumber = {
      caption: isDataQ ? 'DataQ challenge submitted' : '',
      primaryText: accidentNumber,
    };
    // if (status === 'Manage') {
    newObj.primaryDriver.secondaryText = primaryDriverMatched;
    newObj.powerUnitVin.secondaryText = powerUnitVinMatched;
    newObj.accidentNumber.caption = isDataQ ? 'DataQ challenge submitted' : '';

    if (hasPrimaryDriverOnboarding)
      newObj.primaryDriver.caption = 'New Driver Onboarding submitted';

    if (hasPowerUnitAddedToFleet)
      newObj.powerUnitVin.caption = 'Add to Fleet submitted';
    // }
    newObj.hasPrimaryDriverOnboarding = hasPrimaryDriverOnboarding;
    newObj.hasPowerUnitAddedToFleet = hasPowerUnitAddedToFleet;
    return newObj as any;
  }, []);

  const handleTodoView = (id: string) => {
    navigate('/todos?id=' + id);
  };

  const getNextList = () => {
    (async () => {
      store.setShowLoader(true);
      if (comingFrom.driver) {
        await store.getDriverDataX();
      }
      if (comingFrom.equipment) {
        await store.getEquipmentDataX();
      }
      if (comingFrom.top10) {
        await store.getTop10TrendingDataX();
      }
      store.setShowLoader(false);
    })();
  };

  const handleSort = (e: string) => {
    (() => {
      store.resetParams();
      store.resetGridData();
      store.setSortBy(e);
      setPrevSortBy(e);
      if (e === prevSortBy) {
        store.setSortDirection(
          (store.SortDirection &&
            (store.SortDirection === 'asc' ? 'desc' : 'asc')) ||
            'desc',
        );
      } else {
        store.setSortDirection('desc');
      }
      getNextList();
    })();
  };

  return (
    <Section id="simplex-cont">
      <div style={{ paddingTop: '35px' }}>
        <TableContainer
          sx={{ maxHeight: 'calc(100vh - 155px)' }}
          id="selected-trend-details-grid">
          <InfiniteScroll
            dataLength={store.List.length}
            style={{ overflow: 'unset' }}
            next={getNextList}
            hasMore={!!store.NextLink}
            scrollableTarget="selected-trend-details-grid"
            loader={
              store.ShowLoader && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              )
            }
            endMessage>
            <Table
              style={{
                borderSpacing: '0px 5px',
                minWidth: 700,
                tableLayout: 'fixed',
              }}
              stickyHeader
              aria-label="sticky table">
              <TableHead>
                <SimplexGrid.Header
                  top={0}
                  height={isTodos ? 80 : 115}
                  id="violations"
                  cells={[
                    ...headerKeysWithSpacing,
                    { noHeader: true, noSorting: true, spacing: 80 },
                  ]}
                  sortConfig={{ handleSort, store }}
                />
              </TableHead>
              <TableBody>
                {store.List.length === 0 && store.ShowLoader && (
                  <TableRow style={{ height: '80px', minHeight: '80px' }}>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      align="center"
                      colSpan={headerKeysWithSpacing.length}>
                      <Grid item xs={12} md={12} lg={12}>
                        <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
                {store.List.length === 0 && !store.ShowLoader && (
                  <TableRow style={{ height: '80px', minHeight: '80px' }}>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      align="center"
                      colSpan={headerKeysWithSpacing.length}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography
                          noWrap
                          sx={{
                            color: '#241A2E',
                            fontFamily: 'FiraSans-Semibold',
                            fontSize: '16px',
                            fontWeight: 600,
                            height: '23px',
                            letterSpacing: 0,
                            lineHeight: '23px',
                            textAlign: 'center',
                          }}>
                          No data available
                        </Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
                {store.List.length > 0 && (
                  <SimplexGrid.Body
                    id="violations"
                    data={store.List}
                    cells={headerKeysWithSpacing}>
                    {(el: any, index: number) => {
                      if (title === 'Inspections') {
                        return (
                          <Inspections
                            // WIP
                            cells={headerKeysWithSpacing as any[]}
                            key={index}
                            data={getNewInspectionObj(el)}
                            renderChild={(id: string) => (
                              <>
                                <br />
                                <MoreDetailsInspections
                                  id={id}
                                  showDriverInfo={
                                    comingFrom?.equipment || comingFrom?.top10
                                  }
                                  showEquipmentInfo={
                                    comingFrom?.driver || comingFrom?.top10
                                  }
                                />
                              </>
                            )}
                          />
                        );
                      }
                      if (title === 'Accidents') {
                        return (
                          <Inspections
                            // WIP
                            cells={headerKeysWithSpacing as any[]}
                            key={index}
                            data={getNewCrashesObj(el)}
                            renderChild={(id: string, data) => (
                              <>
                                <br />
                                <CrashesDetails
                                  id={data.accidentId as string}
                                />
                              </>
                            )}
                          />
                        );
                      }
                      return isTodos ? (
                        <TodoCard
                          onClickView={handleTodoView}
                          key={index}
                          data={el}
                        />
                      ) : (
                        <Violation
                          cells={headerKeysWithSpacing}
                          key={index}
                          data={{
                            ...el,
                            primaryDriverFullName:
                              el?.primaryDriver.fullName || '',
                            secondaryDriverFullName:
                              el?.secondaryDriver.fullName || '',
                          }}
                          renderChild={(id: string, data: any) => (
                            <MoreDetailsViolations
                              showDriverInfo={
                                comingFrom?.equipment || comingFrom?.top10
                              }
                              showEquipmentInfo={
                                comingFrom?.driver || comingFrom?.top10
                              }
                              id={id}
                            />
                          )}
                        />
                      );
                    }}
                  </SimplexGrid.Body>
                )}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      </div>
    </Section>
  );
};

export default observer(TrendGrid);
