import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import { useCustomStore } from '../../hooks';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './TrainingDialogStyles';

const CloseButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #CBCBCB',
  borderRadius: '0.5rem',
  boxSizing: 'border-box',
  color: '#241A2E',
  height: '48px',
  width: '170px',
}));

type TrainingCloseDialogProps = {
  showDialog: boolean;
  closeDialog: (action: 'close' | 'abandon') => void;
};

const TrainingCloseDialog: React.FC<TrainingCloseDialogProps> = (
  props: TrainingCloseDialogProps,
) => {
  const classes = useStyles();
  const { trainingStore, todoStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog, closeDialog } = props;

  const [loader, setLoader] = useState<boolean>(false);

  // Updating training odo details
  const getTodoDetailsForAnAssignment = useCallback(
    async () => {
      if (!todoStore.SelectedTrainingAssignment?.todoId) {
        return;
      }
      const resp = await todoStore.getTodoDetailsForAnAssignment(
        todoStore.SelectedTrainingAssignment.todoId,
      );
      if (resp.isErr()) {
        enqueueSnackbar('Unable to fetch todo details', SnackBarConfig('e'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, trainingStore],
  );

  // Updating assignment status
  const onCloseQuiz = useCallback(
    async () => {
      if (!todoStore.SelectedTrainingAssignmentId) {
        return;
      }
      setLoader(true);
      const resp = await trainingStore.updateTrainingAssignmentStatus(
        todoStore.SelectedTrainingAssignmentId,
        { status: 'abandonQuiz' },
      );
      if (resp.isErr()) {
        enqueueSnackbar('Unable to update status', SnackBarConfig('e'));
      } else {
        getTodoDetailsForAnAssignment();
        closeDialog('abandon');
      }
      setLoader(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, trainingStore],
  );

  return (
    <DialogMigrate
      aria-labelledby="training-close-dialog"
      className={classes.quizCloseDialog}
      maxWidth="md"
      open={showDialog}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => closeDialog('close')}>
      <DialogTitle className="dialog-title">
        <div className="title">THIS WILL RESULT IN AN AUTOMATIC FAIL</div>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <div className="sub-title">
          Are you sure you want to close this quiz?
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <CloseButton
          type={'button'}
          variant="contained"
          disabled={loader}
          onClick={() => onCloseQuiz()}>
          <Typography className="close-btn">
            {loader ? (
              <CircularProgress size={20} sx={{ color: '#A70D0D' }} />
            ) : (
              'YES, CLOSE QUIZ'
            )}
          </Typography>
        </CloseButton>
        <CloseButton
          type={'button'}
          variant="contained"
          disabled={loader}
          onClick={() => closeDialog('close')}>
          <Typography className="close-btn">CANCEL</Typography>
        </CloseButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(TrainingCloseDialog);
