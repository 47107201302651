import { makeAutoObservable } from 'mobx';

import { CreateIrpServiceInput } from '../types';
import { RootStore } from '.';

export class IrpServiceStore {
  private _IrpServiceInfo: {
    accountNumber: string;
    expirationDate: string;
    permitType: string;
    irpStateId: string;
  } | null = null;
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setIrpServiceValue(response: {
    accountNumber: string;
    expirationDate: string;
    permitType: string;
    irpStateId: string;
  }) {
    this._IrpServiceInfo = response;
  }

  get IrpServiceDetails() {
    return this._IrpServiceInfo;
  }
  async getPredefinedIrpServiceInfo() {
    const response = await this.rootStore.apiStore.irpApiStore.getIrpService();
    if (response.isOk()) {
      this.setIrpServiceValue(response.value);
    }
    return response;
  }

  async create(data: CreateIrpServiceInput) {
    const response = await this.rootStore.apiStore.irpApiStore.create(data);
    if (response.isOk()) {
      // this.setIrpServiceValue(response.value);
    }
    return response;
  }
}
