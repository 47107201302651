import makeStyles from '@mui/styles/makeStyles';

export const DueColors = {
  due0: '#A70D0D',
  due11to30: '#000000',
  due1to5: '#E8932E',
  due30above: '#0DA728',
  due6to10: '#D8BF57',
};

const useStyles = makeStyles((theme) => ({
  '& .MuiGrid-root': {
    padding: '0 16px !important',
  },
  '& .MuiPaper-root': {
    '.MuiGrid-container': {
      padding: '0px 16px !important',
    },
    padding: '16px 0px !important',
  },
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },
  actionButton: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Medium',
    fontSize: '15px',
    fontWeight: '500',
    letterSpacing: '1px',
    lineHeight: 'normal',
    textAlign: 'center',
  },
  backtoLoginArrow: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '25px',
    position: 'relative',
  },
  close: {
    color: '#D1D1D1 !important',
    fontSize: '0.875rem !important',
    marginLeft: '0.3rem !important',
  },
  colordot: {
    borderRadius: '50%',
    height: '12px',
    marginRight: '.5rem !important',
    width: '12px',
  },
  combinedTooltip: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    height: '122.9px',
    width: '207px',
  },
  companyBg: {
    alignItems: 'center',
    backgroundColor: '#E8932E',
    borderRadius: '17px',
    color: '#FFFFFF !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '14px !important',
    height: '34px',
    justifyContent: 'center',
    letterSpacing: '0.88px !important',
    lineHeight: '17px',
    paddingTop: '0.2rem',
    textTransform: 'uppercase',
    width: '104px',
  },
  companyLink: {
    color: '#0061FF',
    fontFamily: 'FiraSans-Light',
    fontSize: '16px',
    fontWeight: 300,
    height: '38px',
    letterSpacing: 0,
    lineHeight: '19px',
    width: '474px',
  },
  danger: {
    borderLeft: '8px solid #A70D0D',
  },
  dangerdot: {
    backgroundColor: '#A70D0D',
    borderRadius: '50%',
    height: '12px',
    marginRight: '.5rem !important',
    width: '12px',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  driverBg: {
    alignItems: 'center',
    backgroundColor: '#2E1633',
    borderRadius: '17px',
    color: '#FFFFFF !important',

    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '14px !important',
    height: '34px',
    justifyContent: 'center',
    letterSpacing: '0.88px !important',
    lineHeight: '17px',
    paddingTop: '0.2rem',
    textTransform: 'uppercase',
    width: '104px',
  },
  due0: {
    borderLeft: `8px solid ${DueColors.due0}`,
  },
  due11to30: {
    borderLeft: `8px solid ${DueColors.due11to30}`,
  },
  due1to5: {
    borderLeft: `8px solid ${DueColors.due1to5}`,
  },
  due30above: {
    borderLeft: `8px solid ${DueColors.due30above}`,
  },
  due6to10: {
    borderLeft: `8px solid ${DueColors.due6to10}`,
  },
  filterItem: {
    alignItems: 'center',
    display: 'flex',
    margin: '1rem 1rem 0 1rem !important',
    paddingLeft: '1rem',
  },
  fitem: {
    alignItems: 'center',
    backgroundColor: '#E8E8E8',
    borderRadius: '24px',
    color: '#979598 !important',
    display: 'flex',
    fontSize: '0.87rem !important',
    height: '34px',
    justifyContent: 'space-around',
    marginRight: '0.6rem !important',
    minWidth: '122px',
    padding: '0 .9rem',
  },
  fleetBg: {
    alignItems: 'center',
    backgroundColor: '#0DA728',
    borderRadius: '17px',
    color: '#FFFFFF !important',

    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '14px !important',
    height: '34px',
    justifyContent: 'center',
    letterSpacing: '0.88px !important',
    lineHeight: '17px',
    paddingTop: '0.2rem',
    textTransform: 'uppercase',
    width: '104px',
  },
  ftex: {
    color: '#979598 !important',
    cursor: 'pointer',
    fontSize: '0.87rem !important',
  },
  loginAsAdmin: {
    color: '#2573BF',
    fontFamily: 'FiraSans-Bold',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'right',
  },

  normal: {
    borderLeft: '8px solid #E8932E',
  },

  normaldot: {
    backgroundColor: '#E8932E',
    borderRadius: '50%',
    height: '12px',
    marginRight: '.5rem !important',
    width: '12px',
  },
  rectangleTooltip: {
    backgroundColor: '#37414F',
    height: '32px',
    width: '207px',
  },
  rowHeight: {
    height: '80px',
  },
  scroll: {
    overflow: 'hidden',
    position: 'relative',
  },
  success: {
    borderLeft: '8px solid #DEC330',
  },
  successdot: {
    backgroundColor: '#DEC330',
    borderRadius: '50%',
    height: '12px',
    marginRight: '.5rem !important',
    width: '12px',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderBg: {
    backgroundColor: '#f5f5f5!important',
  },
  trainingTodoVariant: {
    '& .todo-assigned': {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: '0.5rem',
    },
    '& .todo-status': {
      '& .value': {
        '&.status': {
          fontFamily: 'FiraSans-Medium',
        },
        fontFamily: 'FiraSans-Regular',
        paddingLeft: '0.3rem',
      },
      color: '#241A2E',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'FiraSans-Medium',
      fontSize: '12px',
      letterSpacing: 0,
      lineHeight: '18px',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  verticalBorder: {
    '&:last-child': {
      borderRight: 'unset',
    },
    borderRight: '1px solid #D8D8D8',
    paddingRight: '0.8rem',
  },
  view: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '1em !important',
    textDecoration: 'underline solid #0061FF',
  },
}));

export default useStyles;
