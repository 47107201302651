import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import Footer from '../../../../components/Footer';
import { useCustomStore } from '../../../../hooks';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import DialogCABLoginGov from '../../Dashboard/DialogCABLoginGov';

const Text = styled('b')(() => ({
  fontSize: 12,
  margin: '30px 90px 10px auto',
}));

const Error = styled('p')(() => ({
  color: 'red',
  fontSize: 12,
}));

const Label = styled(Typography)(() => ({
  fontSize: 12,
  margin: '5px 0',
}));

const Overlay = styled('div')(() => ({
  backgroundColor: 'white',
  height: '100%',
  opacity: 0.4,
  position: 'absolute',
  top: 0,
  width: '95%',
}));

const DotPinForm: React.FC<{
  onSubmit: (val: { dotPin: string }) => unknown;
  handleClose: () => unknown;
  getCredentials: (type: string) => void;
  isLoading: boolean;
}> = ({ onSubmit, handleClose, getCredentials, isLoading }) => {
  const [pin, setPin] = useState('');
  const [isValid, setIsValid] = useState(true);
  return (
    <>
      <DialogContent className="dot-pin-content">
        <h4>Update DOT PIN</h4>
        <Text>Enter your DOT PIN and submit to update.</Text>
        <br />
        <br />
        <Grid container>
          <Grid item lg={12}>
            <FormControl error={false} fullWidth>
              <Label paragraph>DOT PIN</Label>

              <TextField
                value={pin}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  setPin(value);
                  setIsValid(!!value);
                }}
                type={'text'}
                variant="standard"
              />
              {!isValid ? <Error>Required</Error> : ''}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Footer
          onSubmit={() => {
            if (pin) {
              onSubmit({ dotPin: pin });
            } else setIsValid(false);
          }}
          onCancel={() => {
            handleClose();
            getCredentials('DOT_PIN');
          }}
        />
      </DialogActions>
    </>
  );
};

// const FMCSAForm: React.FC<{
//   onSubmit: (val: { username: string; password: string }) => unknown;
//   handleClose: () => unknown;
//   getCredentials: (type: string) => void;
//   isLoading: boolean;
// }> = ({ onSubmit, handleClose, getCredentials, isLoading }) => {
//   const { userStore } = useCustomStore();
//   const { FMCSA } = userStore;
//   const [value, setValue] = useState<FMCSAPutPayload>({
//     password: '',
//     username: '',
//   });

//   const [error, setError] = useState<FMCSAPutPayload>({
//     password: '',
//     username: '',
//   });

//   useEffect(() => {
//     const fmcsaUserName = FMCSA?.username || '';
//     setValue({ password: '', username: fmcsaUserName });
//     return () => {
//       setValue({ password: '', username: '' });
//       setError({ password: '', username: '' });
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return (
//     <>
//       <DialogContent className="dot-pin-content">
//         <h4>Update FMCSA Portal Credentials</h4>
//         <Text>Enter your credentials and submit to update.</Text>
//         <br />
//         <br />
//         <Grid container>
//           <Grid item lg={12}>
//             <FormControl error={false} fullWidth>
//               <Label paragraph>Username</Label>

//               <TextField
//                 value={value?.username}
//                 onChange={(e) => {
//                   const username = e.currentTarget.value;
//                   setValue({ ...value, username });
//                   if (username) setError({ ...error, username: '' });
//                   else setError({ ...error, username: 'Required' });
//                 }}
//                 type="text"
//                 variant="standard"
//               />
//               {error.username && <Error>{error.username}</Error>}
//             </FormControl>
//           </Grid>
//           <Grid item lg={12}>
//             <FormControl error={false} fullWidth>
//               <Label paragraph>Password</Label>

//               <TextField
//                 value={value?.password}
//                 onChange={(e) => {
//                   const password = e.currentTarget.value;
//                   setValue({ ...value, password });
//                   if (password) setError({ ...error, password: '' });
//                   else setError({ ...error, password: 'Required' });
//                 }}
//                 type="password"
//                 variant="standard"
//               />
//               {error.password && <Error>{error.password}</Error>}
//             </FormControl>
//           </Grid>
//         </Grid>
//       </DialogContent>
//       <DialogActions>
//         <Footer
//           onSubmit={() => {
//             if (value.password && value.username) {
//               onSubmit(value);
//             } else
//               setError({
//                 password: value.password ? '' : 'Required',
//                 username: value.username ? '' : 'Required',
//               });
//           }}
//           onCancel={() => {
//             handleClose();
//             getCredentials('FMCSA');
//           }}
//         />
//       </DialogActions>
//     </>
//   );
// };

const UpdateCredentialsModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    userStore: {
      getCredentials,
      updateDOTpin,
      updateFMCSA,
      ShowDOTForm,
      ShowFMCSAForm,
      setShowDOTForm,
      setShowFMCSAForm,
    },
  } = useCustomStore();

  const { enqueueSnackbar } = useSnackbar();

  const handleDOTPinSubmit = async (val: { dotPin: string }) => {
    setIsLoading(true);
    const [res, err] = await updateDOTpin(val);
    setIsLoading(false);
    if (err || res?.errorMessage) {
      enqueueSnackbar(
        res?.errorMessage || 'Unable to update DOT PIN. Try again',
        SnackBarConfig('e'),
      );
    } else {
      if (res && res.status === 'Invalid') {
        enqueueSnackbar(
          res?.message || 'Unable to update DOT PIN. Try again',
          SnackBarConfig('e'),
        );
      } else {
        setShowDOTForm(false);
        enqueueSnackbar(
          res?.message || 'DOT updated successfully',
          SnackBarConfig('s'),
        );
        getCredentials('DOT_PIN');
      }
    }
  };

  const handleFMCSASubmit = async (val: {
    username: string;
    password: string;
  }) => {
    setIsLoading(true);
    const [res, err] = await updateFMCSA(val);
    setIsLoading(false);
    if (err || res?.errorMessage) {
      enqueueSnackbar(
        res?.errorMessage ||
          res?.message ||
          'Unable to update FMCSA. Try again',
        SnackBarConfig('e'),
      );
    } else {
      if (res && res.status === 'Invalid') {
        enqueueSnackbar(
          res?.message || 'Unable to update FMCSA Pin. Try again',
          SnackBarConfig('e'),
        );
      } else {
        setShowFMCSAForm(false);
        enqueueSnackbar(
          res?.message || 'FMCSA updated successfully',
          SnackBarConfig('s'),
        );
        getCredentials('FMCSA');
      }
    }
  };
  return (
    <Dialog
      open={ShowDOTForm || ShowFMCSAForm}
      maxWidth="md"
      onBackdropClick={() => {
        if (!isLoading) {
          setShowDOTForm(false);
          setShowFMCSAForm(false);
        }
      }}>
      <Card>
        {ShowDOTForm && (
          <DotPinForm
            isLoading={isLoading}
            handleClose={() => setShowDOTForm(false)}
            onSubmit={handleDOTPinSubmit}
            getCredentials={getCredentials}
          />
        )}
        {ShowFMCSAForm && (
          <DialogCABLoginGov
            handleClose={() => setShowFMCSAForm(false)}
            onSubmit={handleFMCSASubmit}
            getCredentials={getCredentials}
          />
          // <FMCSAForm
          //   isLoading={isLoading}
          //   handleClose={() => setShowFMCSAForm(false)}
          //   onSubmit={handleFMCSASubmit}
          //   getCredentials={getCredentials}
          // />
        )}

        {isLoading && (
          <Overlay className="flex-row-center">
            <CircularProgress size={30} sx={{ color: '#DEC330' }} />
          </Overlay>
        )}
      </Card>
    </Dialog>
  );
};

export default UpdateCredentialsModal;
