import Close from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';

import { AllowedUploadMimeTypes } from '../../config/Constants';

const Container = styled(Grid)(({ theme }) => {
  return {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    border: `dashed 2px ${theme.palette.grey[300]}`,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '10px 0',
    padding: 20,
  };
});

const Text = styled(Typography)<{ color?: string }>(({ theme, color }) => ({
  color: color || theme.palette.grey[500],
  lineHeight: 2,
  textAlign: 'center',
}));

const FeedbackRow = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 5,
}));

const CloseIcon = styled(IconButton)(() => ({
  height: 30,
  marginTop: -10,
  width: 30,
}));
type Props = {
  onUpload: (file: File | null) => any;
  onRemove: () => any;
  title?: string;
  allowedFileTypes?: string;
};

const UploadFileBox: React.FC<Props> = ({
  onUpload,
  title = '',
  onRemove,
  allowedFileTypes,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState('');

  useEffect(() => {
    onUpload(file);
  }, [file, onUpload]);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const item = event.dataTransfer.items[0];
    const file = item.getAsFile();
    const fileType = file?.type || '';
    if (
      (!allowedFileTypes && AllowedUploadMimeTypes.includes(fileType)) ||
      fileType.includes('image') ||
      (allowedFileTypes && allowedFileTypes.includes(fileType))
    ) {
      setFile(file);
    } else {
      setError(
        allowedFileTypes
          ? `Only ${allowedFileTypes} are accepted`
          : 'Only image, .pdf, .docx, .xlsx, .csv, .txt files are accepted',
      );
      setTimeout(() => {
        setError('');
      }, 2500);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { files = [] },
    } = event;
    const file = files?.length ? files[0] : null;
    setFile(file);
  };
  const handleClick = () => {
    if (file === null) {
      inputRef.current?.click();
    }
  };

  const removeFile = (e: any) => {
    e.stopPropagation();
    setFile(null);
    onRemove();
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  };
  return (
    <Container
      onClick={handleClick}
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}>
      <input
        ref={inputRef}
        type="file"
        accept={allowedFileTypes ? allowedFileTypes : AllowedUploadMimeTypes}
        hidden
        onChange={handleFileChange}
      />
      {error && (
        <Text color="red" paragraph>
          {error}
        </Text>
      )}
      {(file === null && (
        <Text paragraph>
          <b>
            <u>Upload File {title ? `for ${title}` : ''}</u>
          </b>
          <br />
          or <br />
          drag and drop file
        </Text>
      )) || (
        <FeedbackRow id="feedback-row" xs={12} wrap="wrap">
          <a href="#">{file?.name}</a>

          <CloseIcon onClick={removeFile} size="small">
            <Close />
          </CloseIcon>
        </FeedbackRow>
      )}
    </Container>
  );
};

export default UploadFileBox;
