import { makeAutoObservable } from 'mobx';

import { EquipmentSearchFormInputs } from '../types';
import { RootStore } from '.';

export class EquipmentStore {
  private _status = 'Active';
  private _equipmentList: Array<any> = [];
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';
  private _equipmentSelectedList: Array<any> = [];
  private _sortBy = '';
  private _sortDirection = '';
  // private _sortClicked = false;
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setEquipmentsList(data: Array<any>) {
    this._equipmentList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link ? link : '';
  }

  setStatus(value: string) {
    this._status = value;
  }

  setEquipmentApiReset() {
    this.setNextLink('');
    this.setHasMoreResults(false);
    this.setEquipmentsList([]);
  }
  resetSortParams() {
    this._sortBy = '';
    this._sortDirection = '';
  }

  restSlecetedEquipment() {
    this._equipmentSelectedList = [];
  }
  selectedEquipmentRefetch(equipmentList: Array<any>) {
    this._equipmentSelectedList = equipmentList;
  }
  setSelectedEquipmentList(
    equipments: Array<any>,
    unitsArr?: Array<any>,
    append?: any,
    arrayFieldRemove?: any,
    defaultKeyName?: any,
  ) {
    const arr = [...equipments];

    this._equipmentSelectedList = [
      ...(arr && arr.length
        ? arr.map((val) => {
            const index = this.SelectedEquipments.findIndex(
              (ele) => ele.unitId === val.unitId,
            );
            if (index !== -1) {
              val = { ...this.SelectedEquipments[index] };
            }
            return val;
          })
        : []),
    ];

    if (unitsArr && append && arrayFieldRemove) {
      // incase new quipment array will be already existing value remove also arr fileds removed
      const removedIndexArr: Array<number> = [];
      unitsArr.map((val, index) => {
        if (
          !this._equipmentSelectedList.some((ele) => ele.unitId === val.unitId)
        ) {
          removedIndexArr.push(index);
        }
      });
      arrayFieldRemove(removedIndexArr);
      this._equipmentSelectedList.map((val) => {
        if (!unitsArr.some((ele) => ele.unitId === val.unitId)) {
          append({ ...val, ...defaultKeyName });
        }
      });
    }
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  get SelectedEquipments() {
    return this._equipmentSelectedList;
  }
  get ShowLoader() {
    return this._showLoader;
  }

  get EquipmentList() {
    return this._equipmentList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Status() {
    return this._status;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  async fetchEquipmentsBySorting(
    todoId = '',
    requestType: string,
    clicked?: boolean,
  ) {
    // if (clicked) this._sortClicked = true;
    this.setEquipmentApiReset();
    return await this.fetchEquipments(todoId, requestType);
  }

  async fetchEquipments(todoId = '', requestType: string) {
    const params: {
      status: string;
      sortBy?: string;
      sortDirection?: string;
      todoId?: string;
      requestType?: string;
    } = {
      requestType,
      status: this._status,
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(todoId ? { todoId } : {}),
      ...(this.NextLink ? { nextLink: this.NextLink } : {}),
    };

    const response =
      await this.rootStore.apiStore.equipmentApi.getEquipmentList(params);
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._equipmentList.concat(results);
      this.setEquipmentsList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchEquipmentsBySearchBased(
    searchVal: EquipmentSearchFormInputs,
    todoId = '',
    requestType: string,
  ) {
    const params: {
      simplexId: string;
      unitNumber: string;
      vinNumber: string;
      status: string;
      todoId?: string;
      requestType?: string;
    } = {
      requestType,
      status: this._status,
      ...searchVal,
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(todoId ? { todoId } : {}),
      ...(this.NextLink ? { nextLink: this.NextLink } : {}),
    };

    const response =
      await this.rootStore.apiStore.equipmentApi.getEquipmentBySearch(params);
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._equipmentList.concat(results);
      this.setEquipmentsList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  // async fileUploadApi(data: { units: Array<any> }) {
  //   const finalpromise = data.units.map(async (ele: any) => {
  //     const filesDetails = this.SelectedEquipments.find(
  //       (val) => val.unitId === ele.unitId,
  //     );
  //     if (filesDetails && filesDetails.files) {
  //       const formData = new FormData();
  //       if (filesDetails.files && filesDetails.files.length) {
  //         filesDetails.files.map((file: any) => {
  //           formData.append('files', file);
  //         });
  //         const fileRes = await this.rootStore.fileStore.postFile(formData);
  //         ele.documents = fileRes;
  //       }
  //     }
  //     return ele;
  //   });
  //   let resFiles: any = [];
  //   await Promise.all(finalpromise).then((sucess) => {
  //     resFiles = sucess;
  //   });
  //   return await resFiles;
  // }
}
