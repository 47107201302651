import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import {
  CreateTrainingAccidentAssignmentInputs,
  CreateTrainingInspectionAssignmentInputs,
  TrainingAccidentFilters,
  TrainingAssignedFilters,
  TrainingCompletedFilters,
  TrainingInspectionFilters,
  UpdateQuizAnswersForAssignmentInputs,
} from '../../types';
import { objectClean } from '../../utils/helper';
import { RootStore } from '..';

export class TrainingApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getTrainingInspections = (filters: TrainingInspectionFilters) => {
    const {
      GetTrainingInspections: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(filters),
      },
      url: Endpoint,
    });
  };

  getTrainingInspectionRecommendations = (
    inspectionId: string,
    contactId: string,
  ) => {
    const {
      GetTrainingInspectionRecommendations: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        contactId,
        inspectionId,
      },
      url: Endpoint,
    });
  };

  getTrainingAccidents = (filters: TrainingAccidentFilters) => {
    const {
      GetTrainingAccidents: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(filters),
      },
      url: Endpoint,
    });
  };

  getTrainingAssigned = (filters: TrainingAssignedFilters) => {
    const {
      GetTrainingAssigned: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(filters),
      },
      url: Endpoint,
    });
  };

  getTrainingCompleted = (filters: TrainingCompletedFilters) => {
    const {
      GetTrainingCompleted: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(filters),
      },
      url: Endpoint,
    });
  };

  getTrainingAccidentRecommendations = (
    accidentId: string,
    contactId: string,
  ) => {
    const {
      GetTrainingAccidentRecommendations: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        accidentId,
        contactId,
      },
      url: Endpoint,
    });
  };

  getTrainingAssignmentStatuses = () => {
    const {
      GetTrainingAssignmentStatuses: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint,
    });
  };

  getTrainingAssignmentReasons = (params: {
    userType?: number;
    showSystemReason?: boolean;
  }) => {
    const {
      GetTrainingAssignmentReasons: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(params),
      },
      url: Endpoint,
    });
  };

  getCertificateForAnAssignment = (spURL: string) => {
    const {
      GetCertificateForAnAssignment: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        spURL,
      },
      responseType: 'blob',
      url: Endpoint,
    });
  };

  createTrainingAccidentAssignments = (
    body: CreateTrainingAccidentAssignmentInputs,
  ) => {
    const {
      CreateTrainingAccidentAssignments: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: Endpoint,
    });
  };

  createTrainingInspectionAssignments = (
    body: CreateTrainingInspectionAssignmentInputs,
  ) => {
    const {
      CreateTrainingInspectionAssignments: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: Endpoint,
    });
  };

  dismissAssignment = (assignmentId: string) => {
    const {
      DismissAssignment: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint.replace('{assignmentId}', assignmentId),
    });
  };

  getAllUsersForTraining = (params: {
    userType: 'backOfficePersonnel' | 'driver';
    searchText?: string;
    limit?: string;
    nextLink?: string;
  }) => {
    const {
      GetAllUsersForTraining: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(params),
      },
      url: Endpoint,
    });
  };

  getAllTrainingsForCustomAssignment = (params: {
    reasonId: string;
    contactId: string;
  }) => {
    const {
      GetAllTrainingsForCustomAssignment: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(params),
      },
      url: Endpoint,
    });
  };

  createCustomTrainingAssignment = (body: {
    trainingId: string;
    isAssigned: boolean;
    contactId: string;
    reasonId: string;
    dueDate: string;
  }) => {
    const {
      CreateCustomTrainingAssignment: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: Endpoint,
    });
  };

  changeTrainingAssignmentDueDate = (params: {
    assignmentId: string;
    dueDate: string;
  }) => {
    const {
      ChangeTrainingAssignmentDueDate: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: {
        dueDate: params.dueDate,
      },
      method: Method,
      url: Endpoint.replace('{assignmentId}', params.assignmentId),
    });
  };

  getTrainingFileLink = (body: { filePath: string }) => {
    const {
      GetTrainingFileLink: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: Endpoint,
    });
  };

  updateTrainingAssignmentStatus = (
    assignmentId: string,
    body: { status: string; hasPassedQuiz?: boolean; score?: number },
  ) => {
    const {
      UpdateTrainingAssignmentStatus: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: Endpoint.replace('{assignmentId}', assignmentId),
    });
  };

  getQuizDetailsForAssignment = (assignmentId: string) => {
    const {
      GetQuizDetailsForAssignment: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint.replace('{assignmentId}', assignmentId),
    });
  };

  updateQuizAnswersForAssignment = (
    assignmentId: string,
    body: UpdateQuizAnswersForAssignmentInputs,
  ) => {
    const {
      UpdateQuizAnswersForAssignment: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: Endpoint.replace('{assignmentId}', assignmentId),
    });
  };

  getReassignAssignmentContacts = (assignmentId: string) => {
    const {
      GetReassignAssignmentContacts: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint.replace('{assignmentId}', assignmentId),
    });
  };

  reassignAssignment = (assignmentId: string, driverId?: string) => {
    const {
      ReassignAssignment: { Endpoint, Method },
    } = ApiRoutes.Training;
    return this.rootStore.apiStore.call<any>({
      data: {
        driverId,
      },
      method: Method,
      url: Endpoint.replace('{assignmentId}', assignmentId),
    });
  };
}
