import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  actionBtn: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.9375rem',
    fontWeight: '500',
    height: '22px',
    letterSpacing: '1px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  cancelLink: {
    color: '#979797',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.8rem',
    letterSpacing: '0',
  },
  center: {
    textAlign: 'center',
  },
  darkBackground: {
    background: '#241A2E',
  },
  dialogAction: {
    '& .take-quiz-later-btn': {
      '&:hover': {
        backgroundColor: '#D1D1D1',
      },
      backgroundColor: '#D1D1D1',
      marginRight: '0.5rem',
    },
    justifyContent: 'space-between',
  },
  dialogContent: {
    display: 'flex',
    padding: '8px',
  },
  dialogTitleContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
    padding: '8px',
  },
  dialogWidth60: {
    '& .MuiDialog-paperScrollPaper': {
      minHeight: '50% !important',
      width: '60% !important',
    },
  },
  dialogWidth80: {
    '& .MuiDialog-paperScrollPaper': {
      width: '80% !important',
    },
  },
  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    margin: '0.6rem 0',
    marginLeft: 'unset !important',
  },
  documentDialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      minHeight: '25%',
      padding: '0.5rem 1rem 1rem 1rem !important',
      width: '30%',
    },
    '& .header': {
      color: '#241A2E',
      fontFamily: 'FiraSans-SemiBold',
      fontSize: '1.5rem',
      letterSpacing: '1.2px',
      lineHeight: '2rem',
    },
  },
  documentViewer: {
    height: '70vh',
    width: '100%',
  },
  outlinedBtn: {
    '&:hover': {
      border: '1px solid #CBCBCB',
    },
    border: '1px solid #CBCBCB',
    color: '#241A2E',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.9rem',
  },
  quizCloseDialog: {
    '& .MuiDialog-paperScrollPaper': {
      '& .close-btn': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Medium',
        fontSize: '0.9375rem',
        height: '22px',
        letterSpacing: '1px',
        lineHeight: '22px',
        textAlign: 'center',
      },
      '& .dialog-actions': {
        alignItems: 'baseline',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '1rem',
      },
      '& .dialog-content': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .dialog-title': {
        padding: '1rem 0 0',
      },
      '& .sub-title': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Regular',
        fontSize: '0.9rem',
        letterSpacing: '0',
        lineHeight: '22px',
        textAlign: 'center',
        width: '80%',
      },
      '& .title': {
        color: '#241A2E',
        fontFamily: 'FiraSans-Medium',
        fontSize: '1.1rem',
        lineHeight: '26px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      padding: '0.5rem 1rem 1rem 1rem !important',
      width: '30%',
    },
  },
  quizDialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      minHeight: '25%',
      padding: '0.5rem 1rem 1rem 1rem !important',
      width: '30%',
    },
  },
  quizHeader: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Medium',
    fontSize: '1rem',
    textTransform: 'uppercase',
  },
  quizPassed: {
    '& .attempts': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Light',
      fontSize: '0.8rem',
      fontStyle: 'italic',
      margin: '1rem 0 1rem 0',
      textAlign: 'center',
    },
    '& .quiz-description': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.8rem',
      marginTop: '0.5rem',
    },
    '& .quiz-failed': {
      '& .failed': {
        '& .icon': {
          color: '#DB2426',
          fontSize: '5rem',
        },
        '& .text': {
          color: '#DB2426',
          fontFamily: 'FiraSans-Medium',
          fontSize: '0.9rem',
        },
        alignItems: 'center',
        background: '#DB242629',
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        height: '9rem',
        justifyContent: 'center',
        width: '9rem',
      },
      display: 'flex',
      justifyContent: 'center',
      margin: '2.5rem 0 1.4rem 0',
    },
    '& .quiz-passed': {
      '& .passed': {
        '& .icon': {
          color: '#0DA728',
          fontSize: '5rem',
        },
        '& .text': {
          color: '#0DA728',
          fontFamily: 'FiraSans-Medium',
          fontSize: '0.9rem',
        },
        alignItems: 'center',
        background: '#0da72829',
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        height: '9rem',
        justifyContent: 'center',
        width: '9rem',
      },
      display: 'flex',
      justifyContent: 'center',
      margin: '2.5rem 0',
    },
    '& .quiz-title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
    },
    width: '100%',
  },
  quizQuestions: {
    '& .quiz-description': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.8rem',
      marginTop: '0.5rem',
    },
    '& .quiz-options': {
      '& .option': {
        background: '#F8F8FA',
        borderRadius: '0.5rem',
        color: '#979598',
        cursor: 'pointer',
        fontFamily: 'FiraSans-Medium',
        fontSize: '0.8rem',
        marginTop: '0.5rem',
        padding: '0.6rem',
      },
      '& .select-bg': {
        border: '1px solid #979598',
      },
      marginTop: '0.8rem',
    },
    '& .quiz-title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
    },
    '& .wrong-option': {
      color: '#DB2426',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.6rem',
      marginTop: '0.5rem',
      opacity: 0.8,
    },
    width: '100%',
  },
  rules: {
    '& .header': {
      color: '#241A2E',
      fontFamily: 'FiraSans-SemiBold',
      fontSize: '1.5rem',
      letterSpacing: '1.2px',
      lineHeight: '2rem',
    },
    '& .sub-header': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      marginTop: '1.5rem',
    },
  },
  thumbnailImg: {
    height: '100%',
    width: '100%',
  },
  videoDialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      minHeight: '50%',
      padding: '0.5rem 1rem 1rem 1rem !important',
      width: '60%',
    },
  },
  videoOrDocDialogContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
  },
});
export default useStyles;
