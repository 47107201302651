import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { SelectDatePicker } from '../../../../../components/Forms/datePicker';
import { AllowedUploadMimeTypes } from '../../../../../config/Constants';
import theme from '../../../../../materialtheme';
import { consentAcknowledgmentTermination } from '../../../../../utils/config';
import TerminationContracts from '../../../Todos/TerminationContracts';
import useStyles from '../../components/Address/AddressStyles';
import TerminateEquipmentCard from '../../components/TerminateEquipmentCard';
import { PSPDateForm as SignedDateForm } from '../components/PSPForm';

const Label = styled(Typography)(() => ({
  backgroundColor: '#FFF',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#FFF'),
  fontSize: 12,
  marginBottom: 10,
  marginTop: 10,
}));

const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
    borderColor: '#E6E6E6',
    color: '#FFF',
  },
  backgroundColor: '#FFF',
  border: '1px solid #E6E6E6',
  borderRadius: '5px',
  color: theme.palette.getContrastText('#FFF'),
  margin: 15,
  marginLeft: 0,
  textTransform: 'uppercase',
}));

const FeedbackRow = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const ErrorMessage = styled(Typography)(() => ({
  color: 'red',
}));
const FeedbackMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  marginTop: 5,
}));
const CloseIcon = styled(IconButton)(() => ({
  height: 30,
  marginTop: -10,
  width: 30,
}));

const Tick = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  marginRight: 10,
}));

type FormInputs = {
  control: any;
  terminationDate: string;
  expectDriverReturn: string;
};

type Props = {
  bottomSpace?: boolean;
  selectLabel?: string;
  attachmentLabel?: string;
  options?: any[];
  defaultvalue?: string;
  showError?: false;
  onChange?: (data: any) => any;
  shouldReset: boolean;
  id: number;
  equipmentsList?: any[];
  onInteractWithTCard?: (
    isChecked: boolean | undefined,
    selectedEqIndex: number,
    interactedControl: 'A' | 'B',
  ) => any;
  // interactedControl is Checkbox('A) to select or SelectDropDown('B') to select to surrender or not
  disableInteraction: boolean;
};
const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  maxWidth: 400,
  padding: theme.spacing(2),
}));

const ScreenTypeForm: React.FC<Props> = ({
  bottomSpace = true,
  selectLabel = '',
  attachmentLabel = '',
  options = [],
  defaultvalue = '-',
  showError = false,
  shouldReset = false,
  onChange = (data: any) => {},
  id: screenId,
  equipmentsList = [],
  onInteractWithTCard = (
    isChecked: boolean | undefined,
    selectedEqIndex: number,
  ) => {},
  disableInteraction = false,
}) => {
  const { control } = useForm<FormInputs>();
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState(defaultvalue);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showUpload, setShowUpload] = useState<boolean>(true);
  const [showSendToDriver, setShowSendToDriver] = useState<boolean>(true);
  const [attachment, setAttachment] = useState<File | null>();
  const location = useLocation();
  const [error, setError] = useState('');
  const [signedDate, setSignedDate] = useState<string>();
  const [selectedTerminationDate, setSelectedTerminationDate] =
    useState<string>('');
  const [selectedExpectedReturn, setSelectedExpectedReturn] =
    useState<boolean>();
  const [driverTerminationConsent, setDriverTerminationConsent] =
    useState(false);
  const [nameConsentDriver, setNameConsentDriver] = useState('');
  const [jobTitleConsentDriver, setJobTitleConsentDriver] = useState('');
  const [unitTerminationConsent, setUnitTerminationConsent] = useState(false);
  const [nameConsentUnits, setNameConsentUnit] = useState('');
  const [jobTitleConsentUnits, setJobTitleConsentUnit] = useState('');
  const [acknowledgmentFormDialog, setAcknowledgmentFormDialog] =
    useState<boolean>(false);
  const [typeContract, setTypeContract] = useState('Driver');
  const [acknowledgmentUnitConsent, setAcknowledgmentUnitConsent] =
    useState<boolean>(false);

  const handleShowDialog = (show: boolean) => {
    setAcknowledgmentFormDialog(show);
  };

  const resetForm = () => {
    setAttachment(null);
    setShowSendToDriver(true);
    setShowUpload(true);
    if (inputRef && inputRef.current) inputRef.current.value = '';
  };

  useEffect(() => {
    resetForm();
    setSelectedType(defaultvalue || '-');
    setError('');
    setSelectedTerminationDate('');
    setSelectedExpectedReturn(undefined);
  }, [defaultvalue, location.pathname]);

  useEffect(() => {
    if (shouldReset) {
      resetForm();
      setSelectedType('-');
      setSelectedTerminationDate('');
      setSelectedExpectedReturn(undefined);
    }
  }, [shouldReset]);

  const errorMessageForSignedDate =
    (screenId === 0 &&
      attachment &&
      !signedDate &&
      'Please choose Signed date') ||
    '';

  useEffect(() => {
    const data: { [key: string]: any } = {};
    data.selectedType = selectedType;
    data.selectedTerminationDate = selectedTerminationDate;
    data.selectedExpectedReturn = selectedExpectedReturn;
    data.driverTerminationConsent = driverTerminationConsent;
    data.unitTerminationConsent = unitTerminationConsent;
    data.acknowledgmentUnitConsent = acknowledgmentUnitConsent;
    data.nameConsentDriver = nameConsentDriver;
    data.nameConsentUnits = nameConsentUnits;
    data.jobTitleConsentDriver = jobTitleConsentDriver;
    data.jobTitleConsentUnits = jobTitleConsentUnits;

    if (screenId === 0 && attachment) {
      data.signedDate = signedDate;
    } else {
      delete data.signedDate;
    }
    const fd = new FormData();
    if (attachment) {
      fd.set('files', attachment);
      data.attachment = fd;
    }
    data.sendToDriver = !attachment;
    const ignoreRequestTypes = screenId === 3;
    const errorMsg =
      (!selectedType || selectedType == '-') && options.length
        ? screenId === 0
          ? 'Please select MVR Type'
          : 'Please select a Reason for Termination'
        : !selectedTerminationDate && screenId === 3
        ? 'Please select a Termination Date'
        : (!attachment &&
            showUpload &&
            !ignoreRequestTypes &&
            'Please attach file or click send to driver') ||
          errorMessageForSignedDate;
    data.error = errorMsg;
    setError(errorMsg);
    onChange(data);
  }, [
    selectedType,
    selectedTerminationDate,
    selectedExpectedReturn,
    attachment,
    errorMessageForSignedDate,
    showSendToDriver,
    onChange,
    showUpload,
    signedDate,
    options,
    screenId,
    driverTerminationConsent,
    unitTerminationConsent,
    nameConsentDriver,
    nameConsentUnits,
    jobTitleConsentDriver,
    jobTitleConsentUnits,
    acknowledgmentUnitConsent,
  ]);

  const handleFileChange = (event: any) => {
    const { files } = event.target;
    setAttachment(files[0]);
    setShowSendToDriver(false);
    if (inputRef && inputRef.current) inputRef.current.value = '';
  };

  const handleExpectationChange = (event: any) => {
    const isChecked = event.target.checked;
    setSelectedExpectedReturn(isChecked);
  };

  return (
    <Grid>
      <TerminationContracts
        todoType={typeContract}
        handleShowDialog={handleShowDialog}
        showDialog={acknowledgmentFormDialog}
      />
      {selectLabel && (
        <Grid
          item
          xs={12}
          sx={bottomSpace ? { marginBottom: '25px' } : { marginBottom: '0px' }}>
          <Label id="attachment-type-select-label" paragraph>
            {selectLabel} <sup>*</sup>
          </Label>
          <FormControl disabled={disableInteraction} fullWidth>
            <Select
              labelId="attachment-type-select-label"
              id="attachment-type-select"
              value={selectedType}
              onChange={(ev: any) => {
                setSelectedType(ev.target.value);
              }}>
              <MenuItem selected key={options.length} value="-">
                Select
              </MenuItem>
              {options.map((option: any, index: number) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {screenId !== 3 ? (
        <>
          {attachmentLabel && (
            <Grid item xs={12}>
              <Grid xs={12}>
                <Label id="attachment-type-select-label" paragraph>
                  {attachmentLabel} <sup>*</sup>
                </Label>
              </Grid>
              <Grid xs={12}>
                <input
                  onChange={handleFileChange}
                  type="file"
                  accept={AllowedUploadMimeTypes}
                  ref={inputRef}
                  hidden
                />
                {showUpload && (
                  <ActionButton
                    disabled={disableInteraction}
                    onClick={() => {
                      inputRef.current?.click();
                    }}>
                    Upload
                  </ActionButton>
                )}
                {showSendToDriver && (
                  <ActionButton
                    disabled={disableInteraction}
                    onClick={() => {
                      setShowUpload(false);
                    }}>
                    Send to Driver
                  </ActionButton>
                )}
              </Grid>
            </Grid>
          )}

          {attachment && (
            <FeedbackRow id="feedback-row" xs={12} wrap="wrap">
              <a href="#">{attachment?.name}</a>

              {/* <Typography paragraph>{attachment?.type}</Typography> */}
              <Typography paragraph>
                {`${Math.floor(attachment.size) / 1000000} MB`}
              </Typography>

              <CloseIcon
                disabled={disableInteraction}
                onClick={resetForm}
                size="small">
                <Close />
              </CloseIcon>
            </FeedbackRow>
          )}
          {!showUpload && !attachment && (
            <FeedbackRow id="feedback-row" xs={12} wrap="wrap">
              <Box sx={{ display: 'flex' }}>
                <Tick />
                <FeedbackMessage paragraph>
                  Sending Authorization/Request to driver for signature
                </FeedbackMessage>
              </Box>

              <CloseIcon
                disabled={disableInteraction}
                onClick={resetForm}
                size="small">
                <Close />
              </CloseIcon>
            </FeedbackRow>
          )}
          <br />
          {screenId === 0 && attachment && (
            <Grid item xs={12}>
              <Grid xs={12}>
                <SignedDateForm
                  onChange={(value) => {
                    setSignedDate(value);
                  }}
                  title="Signed Date"
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>Driver Termination Date *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="terminationDate"
              render={({ field: { onChange, name, ref }, formState }) => {
                return (
                  <SelectDatePicker
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    name={name}
                    isRefreshValue={true}
                    value={selectedTerminationDate}
                    className={classes.selectClassName}
                    onChangeDate={(val) => {
                      onChange(val);
                      setSelectedTerminationDate(
                        !val ? '' : new Date(val).toLocaleDateString('en-US'),
                      );
                    }}
                  />
                );
              }}
            />
          </Grid>
          <br />
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            display={'flex'}
            alignItems={'center'}>
            <Controller
              control={control}
              defaultValue=""
              name="expectDriverReturn"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <Checkbox
                    inputRef={ref}
                    name={name}
                    value={selectedExpectedReturn}
                    color="success"
                    tabIndex={0}
                    onChange={(event) => handleExpectationChange(event)}
                  />
                );
              }}
            />
            <Box
              className={classes.labels}
              fontFamily={'FiraSans-Medium !important'}>
              Do you expect the Driver to return within the next 30 days?
            </Box>
          </Grid>
          <Box
            sx={{
              borderRadius: 2,
              marginBottom: '8px',
              width: '100%',
            }}>
            <StyledPaper
              sx={{
                background: '#eee',
                borderColor: '#a6a6a6',
                margin: '2px',
                maxWidth: '100%',
                mx: 'auto',
                my: 1,
                p: 2,
              }}>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                sx={{
                  borderBottom: '2px',
                  borderBottomWidth: '2px',
                  marginBottom: '2px',
                }}>
                <Grid item>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setTypeContract('Driver');
                      handleShowDialog(true);
                    }}>
                    Driver Termination Acknowledgment Form
                  </Link>
                </Grid>
              </Grid>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                sx={{ marginBottom: '20px' }}>
                <Grid item>
                  <Checkbox
                    checked={driverTerminationConsent}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 35 },
                      '&.Mui-checked': {
                        color: green[500],
                      },
                    }}
                    onChange={(event) => {
                      setDriverTerminationConsent(event.target.checked);
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography align="justify">
                    I,<b> {nameConsentDriver}</b>, hereby Acknowledgment that i
                    have read and understand the terms outlined in the
                    {consentAcknowledgmentTermination.DTAF}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs>
                  <TextField
                    required
                    disabled={!driverTerminationConsent}
                    id="nameConsentDriver"
                    key="nameConsentDriver"
                    label="Name"
                    value={nameConsentDriver}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setNameConsentDriver(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    required
                    disabled={!driverTerminationConsent}
                    id="jobTitleConsentDriver"
                    key="jobTitleConsentDriver"
                    label="Job Title"
                    value={jobTitleConsentDriver}
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setJobTitleConsentDriver(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                wrap="nowrap"
                spacing={2}
                style={{ paddingTop: '4px' }}>
                <Grid item xs>
                  {!driverTerminationConsent && (
                    <p style={{ color: red[500], fontSize: '9pt' }}>
                      *Please indicate that you have read and agree to the
                      Acknowledgment Form
                    </p>
                  )}
                  {(!nameConsentDriver || !jobTitleConsentDriver) && (
                    <p style={{ color: red[500], fontSize: '9pt' }}>
                      *Please enter Name and Job title
                    </p>
                  )}
                </Grid>
              </Grid>
            </StyledPaper>
          </Box>
          <br />
          <br />
          {!!equipmentsList.length && (
            <h5 id="attachment-type-select-label">
              Do you want to Terminate the Equipment associated with this
              Driver? Select to Terminate
              <sup>*</sup>
            </h5>
          )}
          {equipmentsList?.map((equipment, index) => (
            <TerminateEquipmentCard
              disabled={disableInteraction}
              shouldReset={shouldReset}
              onSelectEquipment={(isChecked) =>
                onInteractWithTCard(isChecked, index, 'A')
              }
              onDecideToSurrender={(shouldSurrender) =>
                onInteractWithTCard(shouldSurrender, index, 'B')
              }
              data={equipment}
              key={index}
              handleShowUnitAcknowledgment={(show) =>
                setAcknowledgmentUnitConsent(show)
              }
            />
          ))}
          {acknowledgmentUnitConsent ? (
            <Box
              sx={{
                borderRadius: 2,
                marginBottom: '8px',
                width: '100%',
              }}>
              <StyledPaper
                sx={{
                  background: '#eee',
                  borderColor: '#a6a6a6',
                  margin: '2px',
                  maxWidth: '100%',
                  mx: 'auto',
                  my: 1,
                  p: 2,
                }}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                  sx={{
                    borderBottom: '2px',
                    borderBottomWidth: '2px',
                    marginBottom: '2px',
                  }}>
                  <Grid item>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        setTypeContract('Unit');
                        handleShowDialog(true);
                      }}>
                      Unit Termination Acknowledgment Form
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={2}
                  sx={{ marginBottom: '20px' }}>
                  <Grid item>
                    <Checkbox
                      checked={unitTerminationConsent}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 35 },
                        '&.Mui-checked': {
                          color: green[500],
                        },
                      }}
                      onChange={(event) => {
                        setUnitTerminationConsent(event.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography align="justify">
                      I,<b> {nameConsentUnits}</b>, hereby Acknowledgment that i
                      have read and understand the terms outlined in the
                      {consentAcknowledgmentTermination.UTAF}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs>
                    <TextField
                      required
                      disabled={!unitTerminationConsent}
                      id="nameConsentUnits"
                      label="Name"
                      value={nameConsentUnits}
                      fullWidth
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setNameConsentUnit(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      required
                      disabled={!unitTerminationConsent}
                      id="jobTitleConsentUnits"
                      label="Job Title"
                      value={jobTitleConsentUnits}
                      fullWidth
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setJobTitleConsentUnit(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledPaper>
            </Box>
          ) : (
            ''
          )}
        </>
      )}
      {error && showError && (
        <FeedbackRow id="feedback-row" xs={12} wrap="wrap">
          <ErrorMessage paragraph>
            {error} <sup>*</sup>
          </ErrorMessage>
        </FeedbackRow>
      )}
    </Grid>
  );
};

export default ScreenTypeForm;
