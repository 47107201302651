import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomSortingIcon from '../../../../components/SortingIcon/CustomSortingIcon';
import { AuthStore } from '../../../../store/auth';
import { TrainingStore } from '../../../../store/training';
import { AccessTypes, TrainingCompletedSortOptions } from '../../../../types';
import useStyles from './CompletedStyles';

const CompletedHeader: React.FC<{
  trainingStore: TrainingStore;
  authStore: AuthStore;
  trainingCompletedSortBy: (columnName: TrainingCompletedSortOptions) => void;
}> = ({ trainingStore, trainingCompletedSortBy, authStore }) => {
  const classes = useStyles();
  // Completed Tab Grid Header
  let completedHeader: any = [
    { label: 'Training Assigned', name: 'videoAssigned' },
    {
      label: 'Score',
      name: 'score',
      styles: {
        width: '20px',
      },
    },
    {
      label: 'Status',
      name: 'status',
      styles: {
        width: '180px',
      },
    },
    { label: 'Assigned By', name: 'assignedBy' },
    {
      label: 'Start Date',
      name: 'startDate',
      styles: {
        width: '120px',
      },
    },
    {
      label: 'End Date',
      name: 'endDate',
      styles: {
        width: '120px',
      },
    },
  ];
  if (authStore.UserAccessType === AccessTypes.CompanyAdmin) {
    const action = [
      {
        label: 'Action',
        name: 'action',
        styles: {
          width: '120px',
        },
      },
    ];
    completedHeader = [{ label: 'Assignee Name', name: 'contactName' }]
      .concat(completedHeader)
      .concat(action);
  }
  // Sorting enable columns
  const sortColumns = [
    'contactName',
    'videoAssigned',
    'score',
    'status',
    'assignedBy',
    'startDate',
    'endDate',
  ];

  return (
    <TableRow>
      {completedHeader.map((header: any, index: number) => (
        <TableCell
          key={index}
          className={classes.tableHeaderBg}
          sx={header.styles}>
          <Grid item xs={12}>
            <Typography
              component={'div'}
              noWrap
              style={{
                alignItems: 'center',
                display: 'flex',
                whiteSpace: 'unset',
              }}>
              <Typography
                component={'div'}
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                }}>
                {header.label}
              </Typography>

              {sortColumns.includes(header.name) && (
                <CustomSortingIcon
                  {...{
                    filterSortBy: trainingStore.TrainingCompletedFilters.sortBy,
                    filterSortDirection:
                      trainingStore.TrainingCompletedFilters.sortDirection,
                    sortBY: trainingCompletedSortBy,
                    sortByName: header.name,
                  }}
                />
              )}
            </Typography>
          </Grid>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default observer(CompletedHeader);
