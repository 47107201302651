import TabList from '@mui/lab/TabList';
import { Divider } from '@mui/material';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomStore } from '../../../../../hooks';
import { NavTabsTPTS } from './NavTabsConstent';
import { Img, useStyles } from './NavTabsStyles';

type NavBarPropsTypes = {
  setValue: any;
  value: string;
};
const TaxPermitsNavBarList: React.FC<NavBarPropsTypes> = (
  props: NavBarPropsTypes,
) => {
  const { globalStore, authStore, userStore }: any = useCustomStore();
  const classes = useStyles();
  const useNavigator = useNavigate();
  const { setValue, value } = props;
  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
    NavigationRoute(newValue);
  };
  const NavigationRoute = (tabValue: string) => {
    let navigationRoute: string | undefined | any = '/';
    navigationRoute = NavTabsTPTS.filter((tabs) => tabs.tab).find(
      (tab) => tab.index === tabValue,
    )?.route;
    globalStore.setCurrentRoute(navigationRoute);
    useNavigator(navigationRoute);
  };
  const shouldDisable = (isDisabled: boolean, tabValue?: string) => {
    if (!isDisabled) return false;
    return tabValue === 'statePermits' && !userStore.isTestAccount()
      ? isDisabled
      : false;
  };
  return (
    <>
      <TabList
        onChange={handleChange}
        aria-label="lab API tabs example"
        textColor="secondary"
        className={classes.tabs}
        variant="scrollable"
        scrollButtons={'auto'}
        allowScrollButtonsMobile={true}>
        {NavTabsTPTS.filter((tabs) => tabs.tab)
          .filter(
            (tabs) =>
              tabs.activeTabKey &&
              authStore.NavbarAccess.tpts &&
              authStore.NavbarAccess.tpts[tabs.activeTabKey],
          )
          .map((tab, index) => (
            <Tab
              key={index}
              disabled={shouldDisable(tab.isDisabled, tab.activeTabKey)}
              icon={
                <div
                  className={`${classes.tabsImage} ${
                    value === tab.index ? classes.tabImageHighlight : ''
                  }`}>
                  <Img
                    alt={tab.title}
                    src={value === tab.index ? tab.activeIcon : tab.icon}
                  />
                </div>
              }
              label={<div className={classes.tabsHeader}>{tab.title}</div>}
              value={tab.index}
            />
          ))}
      </TabList>
      <Divider />
    </>
  );
};

export default TaxPermitsNavBarList;
