import {
  CircularProgress,
  FormControl,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate } from 'react-router-dom';

import { SelectDatePicker } from '../../../../../components/Forms/datePicker';
import { InputText } from '../../../../../components/Forms/InputText';
import { useCustomStore } from '../../../../../hooks';
import { DirverSearchParams } from '../../../../../types';
import { getMaxDateOfBirthForDriver } from '../../../../../utils/helper';
import useStyles from '../../components/TaxPermitsEquipmentCard/EquipmentDialog/EquipmentDialogStyles';
import search from './../../../../../assets/images/h-search.svg';

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));
const SelectButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
    borderColor: '#E6E6E6',
    color: '#FFF',
  },
  backgroundColor: '#FFF',
  borderColor: '#E6E6E6',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#FFF'),
  fontWeight: 'bold',
}));
const TableHeaderCell = styled(TableCell)(() => ({
  padding: '10px 10px 10px 0 !important',
}));
// const TableFullWidthCell = styled(TableCell)(() => ({
//   width: '100%',
// }));
const LoderContainer = styled(TableCell)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));
const BoldHeading = styled(Typography)(() => ({ fontWeight: 'bold' }));
const tableCellStyles = {
  fontSize: 14,
};
const MVRScreen: React.FC = () => {
  const formControls = [
    { label: 'First Name', name: 'firstName' },
    { label: 'Last Name', name: 'lastName' },
    { label: 'License #', name: 'cdlNumber' },
    { label: 'DOB', name: 'dob' },
  ];
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const searchStr = location.search.slice(1, location.search.length);
  const [, setDriversList] = useState([]);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const { driverListStore } = useCustomStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [previousSearchString, setPreviousSearchString] = useState(searchStr);
  const actualList = Array.from(driverListStore.DriversList);
  const {
    control,
    getValues,
    resetField,
    reset,
    formState: { errors },
  } = useForm<DirverSearchParams>({
    criteriaMode: 'all',
    defaultValues: {
      cdlNumber: '',
      dob: '',
      firstName: '',
      lastName: '',
    },
    reValidateMode: 'onChange',
  });
  const getDriversList = useCallback(async () => {
    setIsLoading(true);
    // if (!location.search) return;
    const list: any = await driverListStore.searchDrivers(location.search);
    setIsLoading(false);
    setDriversList(list);
  }, [driverListStore, location.search]);
  useEffect(() => {
    if (location.search) {
      getDriversList();
    } else {
      setIsSearchClicked(false);
      resetField('dob');
      getDriversList();
    }
    return () => {
      driverListStore.resetDriversList();
      setDriversList([]);
    };
  }, [location.search, getDriversList, driverListStore, resetField]);
  useEffect(() => {
    if (!location.search) {
      reset();
      setPreviousSearchString('');
    }
  }, [location.pathname, location.search, reset, previousSearchString]);
  const getMoreDrivers = useCallback(() => {
    (async () => {
      setGetMoreLoader(true);
      await driverListStore.searchDrivers(location.search);
      setGetMoreLoader(false);
    })();
  }, [driverListStore, setGetMoreLoader, location.search]);
  function clean(obj: any) {
    for (const propName in obj) {
      if (obj[propName] === '' || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }
  function formatDate(date: string) {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  }
  const handleDriversSearch = () => {
    const newParams = clean(getValues());
    newParams.dob = formatDate(newParams.dob);
    const searchURL = new URLSearchParams(newParams);
    const searchURLStr = searchURL.toString();
    if (previousSearchString === searchURLStr) return;
    setPreviousSearchString(searchURLStr);
    setIsSearchClicked(true);
    navigate(`./?${searchURLStr}`);
  };
  const getFormControl: React.FC<{ label: string; name: string }> = ({
    label = '',
    name = '',
  }) => {
    const formControlName = name as keyof DirverSearchParams;
    return (
      <TableHeaderCell id="driver-search-table">
        <FormControl>
          <Controller
            control={control}
            defaultValue=""
            name={formControlName}
            render={({
              field: { onChange, value = '', name, ref },
              formState,
            }) => {
              const formControlConfig = {
                className: classes.selectClassName,
                errors:
                  (errors &&
                    errors[formControlName] &&
                    errors[formControlName]?.message) ||
                  '',
                inputRefObj: ref,
                isEditable: false,
                isRefreshValue: true,
                name: formControlName,
                onChangeText: (val: string) => onChange(val),
                placeholder: label,
                tabIndexVal: 0,
                type: 'text',
                value,
                variant: 'standard',
              };
              return formControlName === 'dob' ? (
                <SelectDatePicker
                  shouldClear
                  inputRefObj={ref}
                  errors={errors.dob?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  maxDate={getMaxDateOfBirthForDriver()}
                  value={value as string}
                  onChangeDate={(e: any) => {
                    onChange(e);
                  }}
                  openTo="year"
                  label={value ? '' : 'Enter DOB'}
                  placeholder="Enter DOB"
                />
              ) : (
                <InputText
                  {...(formControlConfig as any)}
                  intIcon={
                    <InputAdornment position="start">
                      <img className={classes.hIcon} src={search} />
                    </InputAdornment>
                  }
                />
              );
            }}
          />
        </FormControl>
      </TableHeaderCell>
    );
  };
  const getActionButton = () => {
    return (
      <TableCell>
        <div className={classes.headerActionBtn}>
          <DarkColorButton
            type="button"
            onClick={() => handleDriversSearch()}
            sx={{ borderRadius: '4px', width: '90px' }}>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontFamily: 'FiraSans-Medium',
                fontSize: '15px',
                fontWeight: 500,
                height: '22px',
                letterSpacing: '1px',
                lineHeight: '22px',
                textAlign: 'center',
              }}>
              Search
            </Typography>
          </DarkColorButton>
        </div>
      </TableCell>
    );
  };
  return (
    <>
      <section>
        <Typography variant="h3">Search & Select Driver</Typography>
      </section>
      <section id="driver-search-and-results">
        <Table>
          <TableHead style={{ padding: 0 }}>
            <TableRow>
              {formControls.map((fc) => getFormControl(fc))}
              {getActionButton()}
            </TableRow>
          </TableHead>
        </Table>
        <TableContainer
          id={'mvr-screen'}
          style={{
            maxHeight: 'calc(100vh - 310px)',
          }}>
          <InfiniteScroll
            dataLength={actualList.length}
            style={{
              overflow: 'unset',
            }}
            next={getMoreDrivers}
            hasMore={driverListStore.NextLink ? true : false}
            scrollableTarget="mvr-screen"
            loader={
              getMoreLoader && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              )
            }
            endMessage>
            {!isLoading && (
              <Table>
                <TableBody>
                  {actualList.map((driver = {}, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        borderBottom: '1px solid',
                        borderBottomColor: '#E6E6E6',
                      }}>
                      <TableCell>
                        <BoldHeading variant="h5">
                          {driver.firstName} {driver.lastName}
                        </BoldHeading>
                      </TableCell>
                      {driver.hasTerminationCase ? (
                        <TableCell>
                          <BoldHeading variant="h6" style={{ color: 'red' }}>
                            Driver Termination in Process
                          </BoldHeading>
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                      <TableCell style={tableCellStyles}>
                        {driver.licenseNumber || (
                          <BoldHeading variant="h5">-</BoldHeading>
                        )}
                      </TableCell>
                      <TableCell style={tableCellStyles}>
                        {driver.dob
                          ? moment(driver.dob).format('MM/DD/YYYY')
                          : null}
                      </TableCell>
                      <TableCell>
                        <SelectButton
                          disabled={driver.hasTerminationCase}
                          onClick={() => {
                            if (driver.hasTerminationCase) {
                              return;
                            }
                            const id: string = driver.contactId || '';
                            const { pathname = '' } = location;
                            if (pathname[pathname.length - 1] === '/') {
                              navigate(pathname + id);
                              return;
                            }
                            navigate(pathname + '/' + id);
                          }}
                          variant="outlined">
                          Select
                        </SelectButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
        {isLoading && (
          <LoderContainer>
            <CircularProgress size={30} sx={{ color: '#DEC330' }} />
          </LoderContainer>
        )}
        {actualList.length === 0 && isSearchClicked && !isLoading && (
          <h1>No drivers found.</h1>
        )}
      </section>
      <br />
    </>
  );
};
export default observer((props) => {
  return <MVRScreen />;
});
