import { styled } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomStore } from '../../../../hooks';
import TopTrendingChart from '../TopTrendingChart';
import ColumnChart from './ColumnChart';
import Trend from './Trend';

const Main = styled('main')(() => ({
  display: 'grid',
  gridGap: 15,
  gridTemplateColumns: '49% 49%',
  minHeight: '100vh',
  padding: 15,
}));

// const Article = styled('article')(() => ({
//   zIndex: 20,
// }));

const tabs: Top10Trending[] = ['Violations', 'Inspections', 'Accidents'];

// const processData = (data: any[]) => {
//   const datax: Record<string, number> = {};

//   data.forEach((el: any) => {
//     datax[el.x] = el.y;
//   });
//   return datax;
// };

const Trends: React.FC = () => {
  const navigate = useNavigate();
  const { trendsStore } = useCustomStore();

  const getDriverId = (index: number) => {
    const driver = trendsStore.topTenDrivers[index] as { keyCode: string };
    return driver.keyCode;
  };
  const getUnitId = (index: number) => {
    const driver = trendsStore.topTenUnits[index] as { keyCode: string };
    return driver.keyCode;
  };

  const handleChartClick = (
    label: string,
    trend: string,
    query: string,
    index = 0,
    cardLabel = '',
  ) => {
    const trendType_ = trend === 'Accidents' ? 'Crashes' : trend;
    const trendHistory = toJS(trendsStore.trendHistory);
    const selectedHistory = trendHistory[cardLabel];
    if (query === 'top10Trending') {
      navigate(
        `/${trendType_}-of?${encodeURI(
          `type=${label}&range=${selectedHistory?.range}`,
        )}`,
      );
      return;
    }
    if (query === 'driver') {
      const driverId = getDriverId(index);
      navigate(
        `/${trendType_}-of?${encodeURI(
          `${query}=${label}&range=${selectedHistory?.range}&driverId=${driverId}`,
        )}`,
      );
    } else {
      const unitId = getUnitId(index);
      navigate(
        `/${trendType_}-of?${encodeURI(
          `${query}=${label}&range=${selectedHistory?.range}&unitId=${unitId}`,
        )}`,
      );
    }
  };

  return (
    <Main id="dashboard-trends">
      <article>
        <Trend
          tabs={[...tabs, 'ToDos']}
          name="Equipment"
          renderchart={(data: DataPoint[], label: string) => (
            <ColumnChart
              onClick={(columnLabel: string, index: number) =>
                handleChartClick(columnLabel, label, 'vin', index, 'Equipment')
              }
              data={data}
              label={label === 'ToDos' ? 'To-Dos' : label}
              xAxislabelPrefix="#"
            />
          )}
        />
      </article>
      <article>
        <Trend
          tabs={[...tabs, 'ToDos']}
          name="Driver"
          renderchart={(data, label: string) => (
            <ColumnChart
              onClick={(columnLabel: string, index: number) =>
                handleChartClick(columnLabel, label, 'driver', index, 'Driver')
              }
              data={data}
              label={label === 'ToDos' ? 'To-Dos' : label}
            />
          )}
        />
      </article>
      <article>
        <Trend
          tabs={[...tabs]}
          name="Top 10 Trending"
          renderchart={(data, label: string) => (
            <TopTrendingChart
              onClick={(index) => {
                const id = toJS(data)[index].keyCode;
                handleChartClick(
                  id,
                  label,
                  'top10Trending',
                  index,
                  'Top 10 Trending',
                );
              }}
              trendingData={data}
            />
          )}
        />
      </article>
    </Main>
  );
};

export default observer(Trends);
