// import './LeftNav.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CompanyAdminNavTruck from '../../assets/images/client-admin-nav.svg';
import DownImg from '../../assets/images/down.svg';
import LogoNav2 from '../../assets/images/logo-nav2.svg';
import Logo from '../../assets/images/logoNav.svg';
import NavTruck from '../../assets/images/nav-truck.svg';
import { PrivateRoutes } from '../../config';
import { useCustomStore } from '../../hooks';
import { CustomAppPage } from '../../types';
import { AccessTypes } from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import { Pages } from './AppPages';
import useStyles from './LeftNavStyles';

type ExpandToggler = {
  servicerequest: boolean;
};

const profileRoute: any = {
  haschildren: false,
  icon: '/assets/images/n-dashboard.svg',
  route: PrivateRoutes.PROFILE,
  title: 'Profile',
  whiteIcon: '',
};

const LeftNav: React.FC = (props) => {
  const { authStore, globalStore, userStore }: any = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const useNavigator = useNavigate();
  const history = useLocation();
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<ExpandToggler>({
    servicerequest: false,
  });

  const [loginUser, setLoginUser] = React.useState<any>({
    accountName: '',
    name: '',
  });

  const handleChange = (panel: string) => (event: any, isExpanded: any) => {
    // setExpanded(isExpanded ? panel : false);
    setExpanded((p) => ({
      ...p,
      [panel]: isExpanded,
    }));
  };

  const disableAccess = useCallback(
    (title: string) => {
      if (authStore?._sidNav?.training) {
        return (
          title === 'Training' &&
          !authStore._sidNav?.training?.assigned &&
          !authStore._sidNav?.training?.completed &&
          !authStore._sidNav?.training?.recommended
        );
      } else {
        return false;
      }
    },
    [authStore._sidNav?.training],
  );

  useEffect(() => {
    // if (!globalStore.getCurrentRoute) {
    globalStore.setCurrentRoute(history.pathname);
    const historyPath: any = (history.pathname || '').split('/');

    if (historyPath[1]) {
      const expandKeys: string[] = Object.keys({ ...expanded });
      const mainContentRoute = (historyPath[1] || '').replace(/-/gim, '');
      const expandedModifiedData: any = {};
      expandKeys.forEach((key: any) => {
        expandedModifiedData[key] = false;
      });
      if (expandKeys.includes(mainContentRoute)) {
        expandedModifiedData[mainContentRoute] = true;
      }
      setExpanded((p) => ({
        ...p,
        ...expandedModifiedData,
      }));
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStore.getCurrentRoute]);

  useEffect(() => {
    getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDetail = useCallback(async () => {
    const userDetailResponse = await userStore.fetchUsersProfilelInfo();
    if (userDetailResponse.isOk()) {
      const { value } = userDetailResponse;
      setLoginUser({ ...value });
    }

    if (userDetailResponse.isErr()) {
      const {
        error: { message = null },
      } = userDetailResponse;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        const messages: any = {};
        Object.keys(message).forEach((key) => {
          messages[key] = message[key].message;
        });

        Object.keys(messages).forEach((msg: string) => {
          const key: string = msg;
          const value: string = messages[msg];

          enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
        });
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let appPages: CustomAppPage[] = React.useMemo(() => [], []);
  let appPagesUsr: string = React.useMemo(() => '', []);

  if (authStore.tokens) {
    const role = authStore.getTokenDetail?.accessType || '';
    appPages = Pages.AdminPages(authStore.NavbarAccess)
      .filter((ele) => {
        if (ele.haschildren) {
          ele.childrens = ele.childrens?.filter((val) => val.show);
        }
        return ele.show;
      })
      .filter((ele) => ele.roles?.includes(role));
    if (role === AccessTypes.CompanyAdmin) {
      appPagesUsr = 'Company';
    } else if (role === AccessTypes.Driver) {
      appPagesUsr = 'Driver';
    } else if (role === AccessTypes.OwnerOperator) {
      appPagesUsr = 'Owner Operator';
    }
  }

  const checkRouteDefiner = (childRoute: any) => {
    if (
      !childRoute ||
      !childRoute.subRouteList ||
      (Array.isArray(childRoute.subRouteList) &&
        !childRoute.subRouteList.length)
    ) {
      return false;
    }

    return childRoute.subRouteList.includes(globalStore.getCurrentRoute);
  };

  const Navigateroute = (route: CustomAppPage, status: string) => {
    useNavigator(route.route);
    globalStore.setCurrentRoute(route.route);
    globalStore.setMenu(false);
    if (status === 'parent') {
      setExpanded({
        servicerequest: false,
      });
    }
  };

  const getMailContentSideNavHighlightStatus = (route: any) => {
    if (
      route.subRouteList &&
      Array.isArray(route.subRouteList) &&
      route.subRouteList.length
    ) {
      return route.subRouteList.includes(globalStore.getCurrentRoute);
    }
    return route.route === globalStore.getCurrentRoute;
  };

  // const sideNavImageDesider = () => {
  //   if (!authStore?.getTokenDetail?.accessType) {
  //     return '';
  //   }
  //   if (authStore?.getTokenDetail?.accessType === AccessTypes.CompanyAdmin) {
  //     return CompanyAdminNavTruck;
  //   }
  //   return NavTruck;
  // };

  // const sideNavBackgroundColorDesider = () => {
  //   if (!authStore?.getTokenDetail?.accessType) {
  //     return '#241a2e';
  //   }
  //   if (authStore?.getTokenDetail?.accessType === AccessTypes.CompanyAdmin) {
  //     return '#d1d3d4';
  //   }
  //   return '#241a2e';
  // };

  const sideNavDesider = () => {
    return true;
    // if (!authStore?.getTokenDetail?.accessType) {
    //   return false;
    // }
    // if (authStore?.getTokenDetail?.accessType === AccessTypes.CompanyAdmin) {
    //   // if ((authStore?.getTokenDetail?.accessType) &&
    //   // [AccessTypes.CompanyAdmin,AccessTypes.Driver,AccessTypes.OwnerOperator].includes(
    //   //   authStore?.getTokenDetail?.accessType
    //   // )) {
    //   return true;
    // }
    // return false;
  };

  // Company Portal
  return (
    <div
      className={classes.sidebar}
      style={{
        backgroundColor: sideNavDesider() ? '#d1d3d4' : '#241a2e',
        backgroundImage: `url(${
          sideNavDesider() ? CompanyAdminNavTruck : NavTruck
        })`,
      }}>
      <img
        className={classes.logo}
        src={sideNavDesider() ? LogoNav2 : Logo}
        alt="Simplex Group"
      />
      <div
        className={sideNavDesider() ? classes.companyPortal : classes.portal}>
        {appPagesUsr} Portal{' '}
        {authStore.getTokenDetail && authStore.getTokenDetail?.adminId
          ? '(Impersonating)'
          : ''}
      </div>
      <div className={classes.menuBox}>
        {appPages.map((route, index) => {
          let hignLightClassName = classes.menuItem;
          let menuText: any = 'menu-text';

          if (sideNavDesider()) {
            hignLightClassName = classes.CompanyMenuItem;
            menuText = 'company-menu-text';
          }

          if (route.haschildren) {
            const expandname: any = route.routename || '';
            const expandList: any = { ...expanded };
            const expandstatus: boolean = expandList[expandname] || false;
            const initialRoute: string = globalStore.getCurrentRoute.split('/');
            let toggleClassName: any = '';
            if (
              route.route &&
              initialRoute[1] &&
              route.route === `/${initialRoute[1]}`
            ) {
              if (sideNavDesider()) {
                hignLightClassName = clsx(
                  classes.companyActiveItem,
                  classes.CompanyMenuItem,
                );
              } else {
                hignLightClassName = clsx(classes.activeItem, classes.menuItem);
              }
            }

            if (expandstatus) {
              if (sideNavDesider()) {
                toggleClassName = 'company-active-toggle';
              } else {
                toggleClassName = 'active-toggle';
              }
            }

            const imageHighLight = (routes: any) => {
              let allChildrenSubRoutes: any[] = [];
              routes.childrens.forEach((subroute: any) => {
                if (
                  subroute.subRouteList &&
                  Array.isArray(subroute.subRouteList) &&
                  subroute.subRouteList.length
                ) {
                  allChildrenSubRoutes = [
                    ...allChildrenSubRoutes,
                    ...subroute.subRouteList,
                  ];
                }
              });
              return allChildrenSubRoutes.includes(globalStore.getCurrentRoute);
            };

            return (
              <Accordion
                key={index}
                expanded={expandstatus}
                onChange={handleChange(expandname)}
                className={toggleClassName}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header">
                  <Typography component={'div'}>
                    <div className="menu-item">
                      {route && imageHighLight(route) ? (
                        <img
                          className="menu-icon"
                          src={sideNavDesider() ? route.caicon : route.aicon}
                          alt="icon"
                        />
                      ) : (
                        <img
                          className="menu-icon"
                          src={route.icon}
                          alt="icon"
                        />
                      )}
                      <div className={menuText}>{route.title}</div>
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'div'}>
                    {(route.childrens || []).map((childroute) => {
                      let subHighLightClassName =
                        'menu-text inner-menu d-flex-a';
                      if (childroute.route && checkRouteDefiner(childroute)) {
                        subHighLightClassName += ' a-inner-menu';
                      }
                      return (
                        <div
                          key={childroute.title}
                          // className="menu-text inner-menu d-flex-a"
                          className={subHighLightClassName}
                          onClick={() => Navigateroute(childroute, 'child')}>
                          {childroute.route && checkRouteDefiner(childroute) ? (
                            <div
                              className={
                                sideNavDesider()
                                  ? 'act-btn companyPortalColor'
                                  : 'act-btn portalColor'
                              }
                            />
                          ) : (
                            <div className="act-btn portalColor hidden" />
                          )}

                          <div>{childroute.title}</div>
                        </div>
                      );
                    })}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
            // return null;
          }
          if (route.route && getMailContentSideNavHighlightStatus(route)) {
            if (sideNavDesider()) {
              hignLightClassName = clsx(
                classes.companyActiveItem,
                classes.CompanyMenuItem,
              );
            } else {
              hignLightClassName = clsx(classes.activeItem, classes.menuItem);
            }
          }

          return (
            <React.Fragment key={index}>
              {route.title === 'Settings' && (
                <div
                  style={{
                    borderTop: sideNavDesider()
                      ? '1px solid #B1B4BE'
                      : '1px solid #3e3548',
                    marginBottom: '1rem',
                    marginLeft: '0.5rem',
                    marginRight: '1rem',
                    marginTop: '2rem',
                  }}
                />
              )}

              {!disableAccess(route.title) && (
                <div
                  className={hignLightClassName}
                  onClick={() => Navigateroute(route, 'parent')}>
                  {route.route &&
                  getMailContentSideNavHighlightStatus(route) ? (
                    <img
                      className="menu-icon"
                      src={sideNavDesider() ? route.caicon : route.aicon}
                      alt="icon"
                    />
                  ) : (
                    <img className="menu-icon" src={route.icon} alt="icon" />
                  )}
                  <div
                    className={
                      sideNavDesider()
                        ? classes.companyMenuText
                        : classes.menuText
                    }>
                    {route.title}
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div
        className={classes.profileBox}
        style={{
          borderTop: sideNavDesider()
            ? '1px solid #B1B4BE'
            : '1px solid #3E3548',
        }}
        onClick={() => Navigateroute(profileRoute, 'parent')}>
        <div className={classes.w90}>
          <div
            className={classes.company}
            style={{
              color: sideNavDesider() ? '#979598' : '#dec330',
            }}>
            {loginUser.accountName || ''}
          </div>
          <div
            className={classes.name}
            style={{
              color: sideNavDesider() ? '#241A2E' : '#FFFFFF',
            }}>
            {loginUser.name || ''}
          </div>
        </div>
        <div className={classes.w10}>
          <img className={classes.profileImg} src={DownImg} alt="arrow" />
        </div>
      </div>
    </div>
  );
};
export default observer(LeftNav);
