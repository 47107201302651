import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import { useCustomStore } from '../../hooks';
import {
  CreateTrainingAccidentAssignmentInputs,
  CreateTrainingInspectionAssignmentInputs,
} from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './TrainingAssignDialogStyles';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

interface TrainingAssignDialogProps {
  isOpen: boolean;
  id: string;
  contactId: string;
  driverName: string;
  closeDialog: (refresh: boolean) => void;
  type: 'accident' | 'inspection';
  driverType?: string;
}

const TrainingAssignDialog: React.FC<TrainingAssignDialogProps> = (
  props: TrainingAssignDialogProps,
) => {
  const classes = useStyles();
  const { trainingStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen, closeDialog, id, contactId, driverName, type, driverType } =
    props;

  enum LastTrainingAssignmentStatus {
    ASSIGNED = 'Assigned',
    IN_PROGRESS = 'In Progress',
    PASSED = 'Passed',
    FAILED = 'Failed',
    DISMISSED = 'Dismissed',
  }

  const [trainings, setTrainings] = useState<any[]>([]);
  const [loader, showLoader] = useState<boolean>(false);

  // Grid Headers
  const gridHeader: any =
    type === 'inspection'
      ? [
          { label: '', name: '' },
          { label: 'Training Recommended', name: 'trainingName' },
          { label: 'Violation Code', name: 'violationCode' },
          { label: 'Violation Description', name: 'violationDescription' },
          { label: 'Last Taken', name: 'lastTakenDate' },
          { label: 'Status', name: 'lastTakenStatus' },
        ]
      : [
          { label: '', name: '' },
          { label: 'Training Recommended', name: 'trainingName' },
          { label: 'Last Taken', name: 'lastTakenDate' },
          { label: 'Status', name: 'lastTakenStatus' },
        ];

  const getTrainingInspectionRecommendationsData = useCallback(async () => {
    trainingStore.setTrainingRecommendationsLoader(true);
    const resp = await trainingStore.getTrainingInspectionRecommendations(
      id,
      contactId,
    );
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Inspection Recommendations',
        SnackBarConfig('e'),
      );
    }
    if (resp.isOk()) {
      setTrainings(trainingStore.TrainingRecommendationsData);
    }
    trainingStore.setTrainingRecommendationsLoader(false);
  }, [contactId, enqueueSnackbar, id, trainingStore]);

  const getTrainingAccidentRecommendationsData = useCallback(async () => {
    trainingStore.setTrainingRecommendationsLoader(true);
    const resp = await trainingStore.getTrainingAccidentRecommendations(
      id,
      contactId,
    );
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Accident Recommendations',
        SnackBarConfig('e'),
      );
    }
    if (resp.isOk()) {
      setTrainings(trainingStore.TrainingRecommendationsData);
    }
    trainingStore.setTrainingRecommendationsLoader(false);
  }, [contactId, enqueueSnackbar, id, trainingStore]);

  useEffect(() => {
    if (type === 'accident') {
      getTrainingAccidentRecommendationsData();
    } else if (type === 'inspection') {
      getTrainingInspectionRecommendationsData();
    }
  }, [
    getTrainingInspectionRecommendationsData,
    getTrainingAccidentRecommendationsData,
    type,
  ]);

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedTraining: any,
  ) => {
    const { trainingId, violationId } = selectedTraining;
    const allTrainings = trainings.map((v: any) => {
      if (
        `${v.trainingId as string}-${v.violationId as string}` ===
        `${trainingId as string}-${violationId as string}`
      ) {
        v.isAssigned = event.target.checked;
      }
      return v;
    });
    setTrainings(allTrainings);
  };

  const onSubmitAssignments = async () => {
    showLoader(true);
    if (type === 'inspection') {
      const assignments = trainings.map((v: any) => {
        return {
          contactId,
          isAssigned: v.isAssigned,
          trainingId: v.trainingId,
          violationId: v.violationId,
        };
      });
      const inspectionPayload: CreateTrainingInspectionAssignmentInputs = {
        assignments,
        driverType: driverType || '',
        inspectionId: id,
      };
      const resp = await trainingStore.createTrainingInspectionAssignments(
        inspectionPayload,
      );
      if (resp.isErr()) {
        enqueueSnackbar(
          'Unable to assign TO-DO to Driver',
          SnackBarConfig('e'),
        );
      } else {
        enqueueSnackbar('TO-DO assigned to Driver', SnackBarConfig('s'));
        closeDialog(true);
      }
    }
    if (type === 'accident') {
      const assignments = trainings.map((v: any) => {
        return {
          contactId,
          isAssigned: v.isAssigned,
          trainingId: v.trainingId,
          violationId: v.violationId,
        };
      });
      const accidentPayload: CreateTrainingAccidentAssignmentInputs = {
        accidentId: id,
        assignments,
      };
      const resp = await trainingStore.createTrainingAccidentAssignments(
        accidentPayload,
      );
      if (resp.isErr()) {
        enqueueSnackbar(
          'Unable to assign TO-DO to Driver',
          SnackBarConfig('e'),
        );
      } else {
        enqueueSnackbar('TO-DO assigned to Driver', SnackBarConfig('s'));
        closeDialog(true);
      }
    }
    showLoader(false);
  };

  const getColorByStatus = (status: LastTrainingAssignmentStatus): string => {
    const colorCodes = {
      Assigned: '#241A2E',
      Dismissed: '#241A2E',
      Failed: '#DB2426',
      'In Progress': '#FFA500',
      Passed: '#0DA728',
    };
    return colorCodes[status] || '#241A2E';
  };

  return (
    <DialogMigrate
      aria-labelledby="training-assign-todo-dialog"
      className={classes.dialog}
      open={isOpen}
      maxWidth="md"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => closeDialog(false)}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Medium',
          fontSize: '1rem',
          letterSpacing: 0,
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            REVIEW {type === 'accident' ? 'ACCIDENT' : 'VIOLATION'}
          </div>
          <Link
            className={classes.cancelBtn}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialog(false);
            }}>
            Close
          </Link>
        </div>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <div className={classes.driverDetails}>
          <div className="title">{driverName}</div>
          <div className="sub-title">
            Select video assignments based on the{' '}
            {type === 'accident' ? 'accidents' : 'violations'}.
          </div>
          <div className="grid-table">
            {trainingStore.TrainingRecommendationsLoader ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : (
              <Grid container spacing={2} justifyContent="space-between">
                <TableContainer>
                  <Table
                    className={classes.table}
                    stickyHeader
                    aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {gridHeader.map((header: any, index: number) => (
                          <TableCell
                            key={index}
                            className={classes.tableHeaderBg}
                            sx={header.styles}>
                            <Grid item xs={12}>
                              <Typography
                                component={'div'}
                                noWrap
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  whiteSpace: 'unset',
                                }}>
                                <Typography
                                  component={'div'}
                                  sx={{
                                    color: '#979598',
                                    fontFamily: 'FiraSans-Regular',
                                    fontSize: '12px',
                                    letterSpacing: 0,
                                    lineHeight: '14px',
                                  }}>
                                  {header.label}
                                </Typography>
                              </Typography>
                            </Grid>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {trainingStore.TrainingRecommendationsData.length > 0 ? (
                        trainingStore.TrainingRecommendationsData.map(
                          (v: any, index: number) => (
                            <TableRow
                              key={index}
                              sx={{
                                backgroundColor: '#FFFFFF',
                                height: '50px',
                                minHeight: '40px',
                              }}>
                              {/* Action */}
                              <TableCell className={classes.tableCell}>
                                <Grid item xs={12} md={12} lg={12}>
                                  <Checkbox
                                    checked={v?.isAssigned}
                                    disabled={loader}
                                    onChange={(e: any) => handleSelect(e, v)}
                                    className={classes.actionCheckbox}
                                  />
                                </Grid>
                              </TableCell>
                              {/* Training Recommended # */}
                              <TableCell className={classes.tableCell}>
                                <Grid item xs={12} md={12} lg={12}>
                                  {v?.trainingName || '-'}
                                </Grid>
                              </TableCell>
                              {/* Violation Code and Violation Description */}
                              {type === 'inspection' && (
                                <>
                                  <TableCell className={classes.tableCell}>
                                    <Grid item xs={12} md={12} lg={12}>
                                      {v?.violationCode || '-'}
                                    </Grid>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Grid item xs={12} md={12} lg={12}>
                                      {v?.violationDescription || '-'}
                                    </Grid>
                                  </TableCell>
                                </>
                              )}
                              {/* Last Taken Date */}
                              <TableCell className={classes.tableCell}>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  style={{
                                    color: getColorByStatus(v?.lastTakenStatus),
                                    fontFamily: 'FiraSans-Medium',
                                  }}>
                                  {v?.lastTakenDate || '-'}
                                </Grid>
                              </TableCell>
                              {/* Last Taken Status */}
                              <TableCell className={classes.tableCell}>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  style={{
                                    color: getColorByStatus(v?.lastTakenStatus),
                                    fontFamily: 'FiraSans-Medium',
                                  }}>
                                  {v?.lastTakenStatus || '-'}
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ),
                        )
                      ) : (
                        <TableRow className={classes.rowHeight}>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            align="center"
                            colSpan={8}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                noWrap
                                sx={{
                                  color: '#241A2E',
                                  fontFamily: 'FiraSans-Semibold',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                  textAlign: 'center',
                                }}>
                                No data available
                              </Typography>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <BottomButton
          type={'button'}
          variant="contained"
          disabled={
            trainings.length === 0 || loader || authStore.IsImpersonatingMode
          }
          sx={{ width: '100%' }}
          onClick={() => onSubmitAssignments()}>
          <Typography className={classes.submitBtnTxt}>
            ASSIGN TO-DO TO DRIVER
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(TrainingAssignDialog);
