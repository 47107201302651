import { Box, Divider, FormControl, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { InputText } from '../../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../../components/Forms/selectInput';
import { SelectStateInput } from '../../../../../../components/Forms/selectStateDropdown';
import { AllowedUploadMimeTypes } from '../../../../../../config/Constants';
import { RequestType } from '../../../../../../types';
// import { RequestType } from '../../../../../../types';
import FilesList from '../../../components/FilesList/FilesList';
import TaxPermitsEquipmentCard from '../../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
import TaxPermitsEquipmentHeader from '../../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';
import useStyles from '../IRPServiceStyles';
import Schedule1Form from '../Schedule1Form';

type IRPServiceFormTypes = {
  btnDisable: any;
  control: any;
  errors: any;
  newFormOnSubmit: any;
  handleSubmit: any;
  equipment: Array<any>;
  onselect: (src: any) => void;
  onFilingPeriodSelect: (year: string, unitId: string) => void;
  onFileChange: (
    docType: string,
    unitId: string,
    action: 'add' | 'remove',
  ) => void;
  remove: (src: any) => void;
  fields: any;
  equipmentStore: any;
  irpServiceStore: any;
  setValue: any;
  updateMethod: any;
  setReminder: (src: boolean) => any;
  watch: (field: any) => any;
};

const IRPServiceNewForm: React.FC<IRPServiceFormTypes> = (
  props: IRPServiceFormTypes,
) => {
  const {
    btnDisable,
    handleSubmit,
    newFormOnSubmit,
    control,
    errors,
    equipment,
    onselect,
    onFilingPeriodSelect,
    onFileChange,
    remove,
    fields,
    equipmentStore,
    irpServiceStore,
    setReminder,
    setValue,
    updateMethod,
    // fileValidation,
    watch,
  } = props;
  const classes = useStyles();
  const [formType, setFormType] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDocTypes, setSelectedDocTypes] = useState<{
    [key: string]: string[];
  }>();

  useEffect(() => {
    setValue(
      'irpAccountNumber',
      irpServiceStore.IrpServiceDetails &&
        irpServiceStore.IrpServiceDetails.accountNumber
        ? irpServiceStore.IrpServiceDetails.accountNumber
        : '',
    );
    setValue(
      'irpExpirationDate',
      irpServiceStore.IrpServiceDetails &&
        irpServiceStore.IrpServiceDetails.expirationDate
        ? moment(irpServiceStore.IrpServiceDetails.expirationDate).format(
            'MM/DD/YYYY',
          )
        : '',
    );
  }, [irpServiceStore, setValue, updateMethod]);

  const responseTypeValue: any = watch('requestType');

  useEffect(() => {
    setFormType(responseTypeValue || '');
    setValue(
      'irpState',
      formType &&
        formType !== 'new' &&
        irpServiceStore.IrpServiceDetails &&
        irpServiceStore.IrpServiceDetails.irpStateId
        ? irpServiceStore.IrpServiceDetails.irpStateId
        : '',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseTypeValue]);

  useEffect(() => {
    const stateID =
      formType &&
      formType !== 'new' &&
      irpServiceStore.IrpServiceDetails &&
      irpServiceStore.IrpServiceDetails.irpStateId
        ? irpServiceStore.IrpServiceDetails.irpStateId
        : '';
    setSelectedState(stateID);
  }, [formType, irpServiceStore.IrpServiceDetails]);

  const formTypeChange = (e: any) => {
    // newFormRest();
    setFormType(e);
  };
  return (
    <form
      style={{
        width: '100%',
      }}
      id={'irpService'}
      onSubmit={handleSubmit(newFormOnSubmit)}>
      <FormControl variant="outlined" fullWidth size="small">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ md: 3, sm: 2, xs: 1 }}
          minHeight={'100%'}>
          <Grid item md={12} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>
              New, Renewal or Additional *
            </Box>
            <Controller
              control={control}
              defaultValue={''}
              name="requestType"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <SelectInput
                    name={name}
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.requestType?.message || ''}
                    isRefreshValue={true}
                    value={value}
                    size={'small'}
                    onChangeText={(e) => {
                      onChange(e);
                      formTypeChange(e);
                    }}
                    isEditable={btnDisable}
                    selectClassName={classes.selectClassName}
                    placeHolder={'Select'}
                    sortByOptionDisable={true}
                    optionsList={[
                      { label: 'New', value: 'new' },
                      { label: 'Renewal', value: 'renewal' },
                      { label: 'Additional', value: 'additional' },
                    ]}
                  />
                );
              }}
            />
            <Divider
              className={
                `${classes && classes.divider} ` +
                `${classes && classes.dividerMargin}`
              }
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TaxPermitsEquipmentHeader
              selecetedEquipment={equipment}
              onSelectEquipment={onselect}
              disabled={btnDisable}
              requestType="TPTS"
            />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={!fields || !fields.length ? { textAlign: 'center' } : {}}>
            {fields.map((ele: any, index: number) => (
              <TaxPermitsEquipmentCard
                key={index}
                ele={ele}
                removedCard={(src) => {
                  const temp = { ...selectedDocTypes };
                  delete temp?.[ele.unitId];
                  setSelectedDocTypes(temp);
                  remove(src);
                }}>
                <Divider className={classes.equipmentCardDivider} />
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
                  {Array.isArray(selectedDocTypes?.[ele.unitId]) &&
                    selectedDocTypes?.[ele.unitId]?.includes('Schedule 1') && (
                      <Grid item md={6} sm={12} xs={12}>
                        <Schedule1Form
                          onChange={(year) => {
                            onFilingPeriodSelect(year, ele.unitId);
                          }}
                        />
                      </Grid>
                    )}
                  {formType === 'new' ? (
                    <Grid item md={12} sm={12} xs={12}>
                      <FilesList
                        fileTypeOption={[
                          {
                            display: 'Vehicle Title',
                            name: 'Vehicle Title',
                            value: 'Vehicle Title',
                          },
                        ]}
                        acceptMimeType={AllowedUploadMimeTypes}
                        unitId={ele.unitId}
                        headingTitle="Title Document"
                        disabled={btnDisable}
                        files={
                          equipmentStore.SelectedEquipments &&
                          equipmentStore.SelectedEquipments.length &&
                          equipmentStore.SelectedEquipments.find(
                            (val: any) => val.unitId === ele.unitId,
                          ) &&
                          equipmentStore.SelectedEquipments.find(
                            (val: any) => val.unitId === ele.unitId,
                          ).files
                            ? equipmentStore.SelectedEquipments.find(
                                (val: any) => val.unitId === ele.unitId,
                              ).files
                            : []
                        }
                        uploadLabelName="Upload"
                        keyName="files"
                      />
                      {/* {fileValidation &&
                      equipmentStore.SelectedEquipments &&
                      equipmentStore.SelectedEquipments.length &&
                      equipmentStore.SelectedEquipments.find(
                        (val: any) => val.unitId === ele.unitId,
                      ) &&
                      (!equipmentStore.SelectedEquipments.find(
                        (val: any) => val.unitId === ele.unitId,
                      ).files ||
                        !equipmentStore.SelectedEquipments.find(
                          (val: any) => val.unitId === ele.unitId,
                        ).files.length) &&
                      formType === RequestType.New ? (
                        <div className="error-msg">Required</div>
                      ) : (
                        ''
                      )} */}
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item md={12} sm={12} xs={12}>
                    <FilesList
                      fileTypeOption={[
                        {
                          display: 'Finance Agreement',
                          name: 'Finance Agreement',
                          value: 'Finance Agreement',
                        },
                        {
                          display: 'Equipment Lease',
                          name: 'Equipment Lease Agreement',
                          value: 'Equipment Lease',
                        },
                        {
                          display: 'Safety Lease',
                          name: 'Safety Lease Agreement',
                          value: 'Safety Lease',
                        },
                        {
                          display: 'Cab Card Registration',
                          name: 'Cab Card Registration',
                          value: 'CAB Card',
                        },
                        {
                          display: 'Certificate of Insurance',
                          name: 'Certificate of Insurance',
                          value: 'Certificate Of Insurance',
                        },
                        {
                          display: 'Road Taxes',
                          name: 'Road Taxes',
                          value: 'Schedule 1',
                        },
                      ]}
                      acceptMimeType={AllowedUploadMimeTypes}
                      unitId={ele.unitId}
                      headingTitle="Finance Agreement, Equipment Lease Agreement, Safety Lease Agreement, Cab Card Registration, Certificate of Insurance, Road Taxes"
                      files={
                        equipmentStore.SelectedEquipments &&
                        equipmentStore.SelectedEquipments.length &&
                        equipmentStore.SelectedEquipments.find(
                          (val: any) => val.unitId === ele.unitId,
                        ) &&
                        equipmentStore.SelectedEquipments.find(
                          (val: any) => val.unitId === ele.unitId,
                        ).optionalFiles
                          ? equipmentStore.SelectedEquipments.find(
                              (val: any) => val.unitId === ele.unitId,
                            ).optionalFiles
                          : []
                      }
                      uploadLabelName="Upload"
                      keyName="optionalFiles"
                      onFileChange={(e, docType) => {
                        const existingDocs =
                          selectedDocTypes?.[ele.unitId] || [];
                        setSelectedDocTypes({
                          ...selectedDocTypes,
                          [ele.unitId]: [...existingDocs, docType],
                        });
                        onFileChange(docType, ele.unitId, 'add');
                      }}
                      onClearFile={(...params) => {
                        const docType = params[4] as string;
                        const { unitId } = ele;
                        const array =
                          selectedDocTypes?.[ele.unitId].filter(
                            (el) => el !== params[4],
                          ) || [];
                        setSelectedDocTypes({
                          ...selectedDocTypes,
                          [unitId]: array,
                        });
                        onFileChange(docType, unitId, 'remove');
                      }}
                      selectedDocTypes={selectedDocTypes?.[ele.unitId] || []}
                    />
                  </Grid>
                </Grid>
              </TaxPermitsEquipmentCard>
            ))}
            {!fields || !fields.length ? (
              <div className={'error-msg'}>Please Select Equipment</div>
            ) : (
              ''
            )}
          </Grid>
          {formType && formType !== 'new' && (
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>IRP Account # *</Box>
              <Controller
                control={control}
                defaultValue={
                  irpServiceStore.IrpServiceDetails &&
                  irpServiceStore.IrpServiceDetails.accountNumber
                    ? irpServiceStore.IrpServiceDetails.accountNumber
                    : ''
                }
                name="irpAccountNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.irpAccountNumber?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={
                        (irpServiceStore.IrpServiceDetails &&
                        irpServiceStore.IrpServiceDetails.accountNumber
                          ? true
                          : false) || btnDisable
                      }
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
          )}
          {formType && (
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Expiration Date</Box>
              <Controller
                control={control}
                defaultValue={
                  irpServiceStore.IrpServiceDetails &&
                  irpServiceStore.IrpServiceDetails.expirationDate
                    ? irpServiceStore.IrpServiceDetails.expirationDate
                    : ''
                }
                name="irpExpirationDate"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.irpExpirationDate?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={
                        // (irpServiceStore.IrpServiceDetails &&
                        // irpServiceStore.IrpServiceDetails.expirationDate
                        //   ? true
                        //   : false) || btnDisable
                        true
                      }
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
          )}
          {formType && (
            <Grid item md={4} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>
                State {formType === 'new' ? '*' : ''}
              </Box>
              <Controller
                control={control}
                defaultValue={selectedState}
                name="irpState"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectStateInput
                      defaultValue={selectedState}
                      keyName="id"
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.irpState?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(value) => {
                        onChange(value);
                        if (
                          watch('requestType') &&
                          watch('requestType') === RequestType.New &&
                          irpServiceStore.IrpServiceDetails &&
                          irpServiceStore.IrpServiceDetails.irpStateId === value
                        ) {
                          setReminder(true);
                        }
                      }}
                      isEditable={
                        (formType &&
                        formType !== 'new' &&
                        irpServiceStore.IrpServiceDetails &&
                        irpServiceStore.IrpServiceDetails.irpStateId
                          ? true
                          : false) || btnDisable
                      }
                      selectClassName={classes.selectClassName}
                    />
                  );
                }}
              />
            </Grid>
          )}
          {/* <Grid item md={8} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>
              What was the last fuel tax quarter you filed? *
            </Box>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ md: 2, sm: 2, xs: 1 }}>
              <Grid item md={6} sm={12} xs={12}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="year"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.year?.message || ''}
                        isRefreshValue={true}
                        value={value}
                        size={'small'}
                        onChangeText={onChange}
                        isEditable={btnDisable}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        optionsList={
                          <>
                            <option
                              value=""
                              className={classes.optionsClassName}
                              disabled>
                              Select Year
                            </option>
                            {[
                              moment().subtract(1, 'year').format('YYYY'),
                              moment().format('YYYY'),
                            ].map((e, index) => (
                              <option
                                key={index}
                                value={e}
                                className={classes.optionsClassName}>
                                {e}
                              </option>
                            ))}
                          </>
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="quarter"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.quarter?.message || ''}
                        isRefreshValue={true}
                        value={value}
                        size={'small'}
                        onChangeText={onChange}
                        isEditable={btnDisable}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        optionsList={
                          <>
                            <option
                              value=""
                              className={classes.optionsClassName}
                              disabled>
                              Select Quarter
                            </option>
                            <option
                              value="q1"
                              className={classes.optionsClassName}>
                              Q1
                            </option>
                            <option
                              value="q2"
                              className={classes.optionsClassName}>
                              Q2
                            </option>
                            <option
                              value="q3"
                              className={classes.optionsClassName}>
                              Q3
                            </option>
                            <option
                              value="q4"
                              className={classes.optionsClassName}>
                              Q4
                            </option>
                          </>
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </FormControl>
    </form>
  );
};

export default observer(IRPServiceNewForm);
