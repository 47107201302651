import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

export class TrendsAPIStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getTop10Trending(trendName: TrendType, type: Top10Trending, dataType = '') {
    const {
      Dashboard: { Trends },
    } = ApiRoutes;

    const { Method, Endpoint } = Trends[trendName];

    const params: any = {
      dataType,
      type,
    };

    try {
      return this.rootStore.apiStore.call({
        method: Method,
        params,
        url: Endpoint,
      });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ isOk: () => false });
    }
  }

  getEquipmentTypesByVin(vin = '', dataType = '', type = '') {
    const {
      TrendDetails: {
        Equipment: { Endpoint, Method },
      },
    } = ApiRoutes;
    try {
      return this.rootStore.apiStore.call({
        method: Method,
        params: {
          ...((dataType && { dataType }) || {}),
          type,
        },
        url: `${Endpoint}/${vin}/list`,
      });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ isOk: () => false });
    }
  }

  getEquipmentTypesByDriver = (driver = '', dataType = '', type = '') => {
    const {
      TrendDetails: {
        Driver: { Endpoint, Method },
      },
    } = ApiRoutes;
    try {
      return this.rootStore.apiStore.call({
        method: Method,
        params: {
          ...((dataType && { dataType }) || {}),
          type,
        },
        url: `${Endpoint}/${driver}/list`,
      });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ isOk: () => false });
    }
  };

  getEquipmentRecords(vin = '', typeId = '', params = {}) {
    const {
      TrendDetails: {
        Equipment: { Endpoint, Method },
      },
    } = ApiRoutes;
    try {
      return this.rootStore.apiStore.call({
        method: Method,
        params,
        url: `${Endpoint}/${vin}/type/${typeId}`,
      });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ isOk: () => false });
    }
  }

  getDriverRecords(driverId = '', typeId = '', params = {}) {
    const {
      TrendDetails: {
        Driver: { Endpoint, Method },
      },
    } = ApiRoutes;
    try {
      return this.rootStore.apiStore.call({
        method: Method,
        params,
        url: `${Endpoint}/${driverId}/type/${typeId}`,
      });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ isOk: () => false });
    }
  }

  getTo10TrendingRecords(typeId = '', trendType = '', params = {}) {
    const {
      TrendDetails: {
        Top10Trending: { Endpoint, Method },
      },
    } = ApiRoutes;
    try {
      return this.rootStore.apiStore.call({
        method: Method,
        params,
        url: `${Endpoint}/${trendType}/type/${typeId}`,
      });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ isOk: () => false });
    }
  }
}
