import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import { PrivateRoutes } from '../../../config';
import { useCustomStore } from '../../../hooks';
import ComingSoon from '../ComingSoon';
import useStyles from './settingsStyle';

const Settings: React.FC = () => {
  const classes = useStyles();

  const [show] = React.useState<boolean>(false);
  const { settingsStore } = useCustomStore();
  const navList: Array<{ name: string; route: string; public: boolean }> =
    React.useMemo(
      () => [
        {
          name: 'Company Information',
          public: false,
          route: PrivateRoutes.SETTINGSROUTE.COMPANYINFO,
        },
        {
          name: 'Notifications',
          public: false,
          route: PrivateRoutes.SETTINGSROUTE.NOTIFICATION,
        },
        {
          name: 'Terms & Conditions',
          public: true,
          route: 'https://simplexgroup.net/terms-of-use',
        },
        {
          name: 'Privacy Policy',
          public: true,
          route: 'https://simplexgroup.net/privacy-policy',
        },
      ],
      [],
    );

  if (show) {
    return <ComingSoon />;
  }

  return (
    <Card className={classes.card}>
      <CardContent sx={{ padding: 'unset' }}>
        <Typography component={'div'} className={classes.headingName}>
          Manage Settings
        </Typography>
        <Divider className={classes.divider} />
        {navList.map((ele, index, arr) => (
          <div key={index.toString()}>
            <Typography
              component={ele.public ? 'a' : Link}
              className={classes.listName}
              href={ele.route}
              {...(ele.public ? { target: '_blank' } : { to: ele.route })}
              onClick={(e: any) => {
                if (ele.name === 'Company Information') {
                  settingsStore.resetCompanyInfo();
                } else if (ele.name === 'Notifications') {
                  settingsStore.resetNotificationInfo();
                }
                e.stopPropagation();
              }}>
              <div>{ele.name}</div>
              <div>
                <ChevronRightIcon fontSize={'large'} />
              </div>
            </Typography>
            <Divider
              className={classes.divider}
              sx={{ margin: '0px 19px !important' }}
            />
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default Settings;
