import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  cardDivider: {
    margin: '12px 0px !important',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      // height: 'auto',
    },
  },
  divider: {
    border: '1px solid #E8E8E8',
    marginBottom: '15px !important',
    marginTop: '31px !important',
    width: '100%',
  },
  hideLabels: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
    opacity: 0,
  },
  infobox: {
    backgroundColor: '#F8F8FA !important',
    borderRadius: '7px',
    color: '#7A777D !important',
    fontSize: '0.85rem !important',
    lineHeight: '15px',
    marginTop: '20px',
    minHeight: '10px',
    padding: '1.5rem',
    position: 'relative',
  },
  infoicon: {
    left: '5px',
    position: 'absolute',
    top: '5px',
  },
  labels: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  selectClassName: {
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    // opacity: '0.43 !important',
  },
  uploadDoc: {
    '& span': {
      marginLeft: '3px',
    },
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
