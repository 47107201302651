import { yupResolver } from '@hookform/resolvers/yup';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import DialogMigrate from '../../../../../components/Dialog/DialogMigrate';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore } from '../../../../../hooks';
import {
  IRPServiceNewFormInputs,
  RequestType,
  TodoRequest,
} from '../../../../../types';
import {
  getFileNamingConvention,
  getStateNameByStateId,
} from '../../../../../utils/fileName.config';
import { getTaxPermitsActivityList } from '../../../../../utils/helper';
import { irpServicesKeys } from '../../../../../utils/irpServicesForm.config';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { IRPServiceNewFormSchema } from '../../../../../utils/ValidatorSchema';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import IRPServiceNewForm from './IRPServiceNewForm/NewForm';
import useStyles from './IRPServiceStyles';

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));

const TaxPermitsIRPService: React.FC<any> = () => {
  const classes = useStyles();
  const {
    authStore,
    equipmentStore,
    irpServiceStore,
    fileStore,
    activityListStore,
    todoStore,
  } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [reminder, setReminder] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  // const [fileValidation, setFileValidation] = useState<boolean>(false);
  const [notification, setNotification] = useState(false);

  const [resetFormRequestType, setResetFormRequestType] = useState<
    number | null
  >(null);
  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<IRPServiceNewFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      irpAccountNumber: '',
      irpExpirationDate: '',
      irpState: '',
      requestType: '',
      units: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(IRPServiceNewFormSchema),
  });

  const {
    fields,
    append,
    remove: arrayFieldRemove,
  } = useFieldArray({ control, name: 'units' });

  const [filingPeriods, setFilingPeriods] = useState<{
    [key: string]: string;
  }>({});
  const [uploadedDocs, setUploadedDocs] = useState<{
    [key: string]: string[];
  }>({});

  const getIRPPrdefinedValues = useCallback(async () => {
    setLoading(true);
    await irpServiceStore.getPredefinedIrpServiceInfo();
    setLoading(false);
  }, [irpServiceStore]);

  const getBodyFormData = useCallback(
    (data: IRPServiceNewFormInputs, equipment: Array<any> | any) => {
      const ApiFormData: any = {
        equipment: equipment.map((val: any) => {
          return {
            ...val,
            // areRoadTaxesUpToDate:
            //   val.areRoadTaxesUpToDate === 'yes' ? true : false,
          };
        }),
        irpState: data.irpState.includes('-')
          ? authStore.statesListOption.find(
              (state) => state.id === data.irpState,
            )?.code
          : data.irpState,
        // lastFuelTaxFiled: {
        //   quarter: data.quarter,
        //   year: Number(data.year),
        // },
        requestType: data.requestType,
      };

      if (data.requestType !== 'new') {
        ApiFormData.irpAccountNumber = data.irpAccountNumber;
      }
      return ApiFormData;
    },
    [authStore.statesListOption],
  );
  const fileUploadApi = useCallback(
    async (data: IRPServiceNewFormInputs) => {
      // const finalpromise = data.units.map(async (ele: any) => {
      //   const filesDetails = equipmentStore.SelectedEquipments.find(
      //     (val) => val.unitId === ele.unitId,
      //   );
      //   if (
      //     (filesDetails && filesDetails.files && filesDetails.files.length) ||
      //     (filesDetails &&
      //       filesDetails.optionalFiles &&
      //       filesDetails.optionalFiles.length)
      //   ) {
      //     const formData = new FormData();
      //     const fileData: Record<string, unknown>[] = [];
      //     data.requestType === RequestType.New &&
      //       Array.isArray(filesDetails.files) &&
      //       filesDetails.files.map((file: any) => {
      //         fileData.push({
      //           fileType: 'Title',
      //           service: 'irp',
      //           type: 'unit',
      //           unit: {
      //             unitId: filesDetails.unitId,
      //           },
      //         });
      //         formData.append('files', file);
      //       });
      // Array.isArray(filesDetails.optionalFiles) &&
      //   filesDetails.optionalFiles.map((file: any) => {
      //     fileData.push({
      //       fileType: JSON.parse(file.name).fileType,
      //       service: 'irp',
      //       type: 'unit',
      //       unit: {
      //         unitId: filesDetails.unitId,
      //       },
      //     });
      //     if (isJsonStringify(file.name)) {
      //       formData.append('files', file);
      //     } else {
      //       formData.append('files', file);
      //     }
      //       });
      //     if (formData.has('files')) {
      //       formData.append('data', JSON.stringify(fileData));
      //       const fileRes = await fileStore.postFile(formData);
      //       if (!fileRes) {
      //         ele.documents = false;
      //         return false;
      //       }
      //       ele.documents = fileRes;
      //     }
      //   }
      //   return ele;
      // });
      // let resFiles: any = [];
      // await Promise.all(finalpromise).then((sucess) => {
      //   resFiles = sucess;
      // });
      // return await resFiles;

      const filesRefArr: Array<{ unitId: string; fileIndex: number }> = [];
      const formData = new FormData();
      const fileData: Record<string, unknown>[] = [];
      let count = 0;
      const tempUnits = data.units.map((ele: any) => {
        const tempUnit = {
          ...ele,
        };
        const filesDetails = equipmentStore.SelectedEquipments.find(
          (val) => val.unitId === ele.unitId,
        );
        const expirationDate = getValues('irpExpirationDate');
        const { unitNumber = '', plateStateId = '' } = ele;
        const state = getStateNameByStateId(
          authStore.statesListOption,
          plateStateId,
        );

        if (filesDetails && filesDetails.files && filesDetails.files.length) {
          filesDetails.files.map((file: any) => {
            filesRefArr.push({ fileIndex: count, unitId: filesDetails.unitId });
            let name = file.name;
            try {
              name = JSON.parse(file.name).name;
            } catch (error) {
              //
            }
            formData.append('files', file, name);
            fileData.push({
              fileName: getFileNamingConvention['Vehicle Title'].name({
                expirationDate: moment(expirationDate).format('MM/DD/YYYY'),
                state,
                unitNumber,
              }),
              fileType: 'Vehicle Title',
              service: 'irp',
              type: 'unit',
              unit: {
                unitId: filesDetails.unitId,
              },
            });
            count = count + 1;
          });
        }

        filesDetails &&
          Array.isArray(filesDetails.optionalFiles) &&
          filesDetails.optionalFiles.map((file: any) => {
            const namePropOfFile = JSON.parse(file.name);
            const fileType = namePropOfFile.fileType as FileType;
            filesRefArr.push({ fileIndex: count, unitId: filesDetails.unitId });
            let expirationDate;
            if (namePropOfFile.expirationDate) {
              expirationDate = moment(namePropOfFile.expirationDate).format(
                'MM/DD/YYYY',
              );
            }
            const filingPeriod = filingPeriods[filesDetails.unitId];
            // IF filingPeriod has value, then key will be filePeriodYear
            tempUnit[irpServicesKeys[fileType] as string] =
              expirationDate || filingPeriod;

            let state;
            if (namePropOfFile.cabCardState) {
              state = getStateNameByStateId(
                authStore.statesListOption,
                namePropOfFile.cabCardState,
              );
              tempUnit['cabCardState'] = state;
            }
            fileData.push({
              fileName:
                getFileNamingConvention[fileType]?.name({
                  expirationDate,
                  filingPeriod,
                  state,
                  unitNumber,
                }) || `${fileType}`,
              fileType,
              service: 'irp',
              type: 'unit',
              unit: {
                unitId: filesDetails.unitId,
              },
            });
            let name = file.name;
            try {
              name = JSON.parse(file.name).name;
            } catch (error) {
              //
            }
            formData.append('files', file, name);
            count = count + 1;
          });
        return tempUnit;
      });
      formData.append('data', JSON.stringify(fileData));
      if (formData.has('files')) {
        const fileRes = await fileStore.postFile(formData);

        if (fileRes.isErr()) {
          enqueueSnackbar('File upload failed', SnackBarConfig('e'));
          return false;
        } else {
          const fileAPiResponse = fileRes.value;
          if (fileAPiResponse && fileAPiResponse.length) {
            fileAPiResponse.map((val: any) => {
              const unitId = filesRefArr.find(
                (ele) => ele.fileIndex === val.index,
              )?.unitId;
              const ind = data.units.findIndex((ele) => ele.unitId === unitId);
              if (ind !== -1) {
                tempUnits[ind].documents = tempUnits[ind]?.documents
                  ? tempUnits[ind].documents.concat([val])
                  : [val];
              }
            });
          }
          return { ...data, units: tempUnits };
        }
      }
      return { ...data, units: tempUnits };
    },
    [
      authStore,
      enqueueSnackbar,
      equipmentStore.SelectedEquipments,
      fileStore,
      filingPeriods,
      getValues,
    ],
  );
  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  const cancel = useCallback(() => {
    reset();
    setResetFormRequestType(Date.now());
    equipmentStore.restSlecetedEquipment();
    // setFileValidation(false);
    const removedIndexArr: Array<number> = [];
    fields.map((e: any, index: number) => {
      removedIndexArr.push(index);
    });
    arrayFieldRemove(removedIndexArr);
  }, [equipmentStore, fields, arrayFieldRemove, reset]);

  const isFilingPeriodFilled = useCallback(() => {
    for (const key in uploadedDocs) {
      if (uploadedDocs[key] && uploadedDocs[key].includes('Schedule 1')) {
        if (filingPeriods && !filingPeriods[key]) {
          return false;
        }
      }
    }
    return true;
  }, [filingPeriods, uploadedDocs]);

  const newFormOnSubmit = useCallback(
    async (data: IRPServiceNewFormInputs) => {
      if (!isFilingPeriodFilled()) return;
      if (data && data.units && data.units.length) {
        // if (
        //   data.requestType === RequestType.New &&
        //   equipmentStore.SelectedEquipments.some(
        //     (ele) => !ele.files || !ele.files.length,
        //   )
        // ) {
        //   setFileValidation(true);
        //   return;
        // }

        if (
          data.requestType === RequestType.New &&
          irpServiceStore.IrpServiceDetails &&
          irpServiceStore.IrpServiceDetails.irpStateId === data.irpState
        ) {
          setReminder(true);
          return;
        }
        if (!data.irpState) {
          data.irpState =
            (irpServiceStore?.IrpServiceDetails?.irpStateId as string) || '';
        }
        setBtnDisable(true);
        let isFileSuccess = true;
        const checkingRes = await fileUploadApi(data);
        if (!checkingRes) {
          isFileSuccess = false;
        }

        if (isFileSuccess) {
          const bodyData: any = getBodyFormData(
            data,
            checkingRes && checkingRes.units ? checkingRes.units : [],
          );
          if (location && location.state) {
            const { todoId } = location.state as TodoRequest;
            bodyData['todoId'] = todoId;
          }

          const getResp = await irpServiceStore.create(bodyData);
          setBtnDisable(false);
          // setFileValidation(false);
          if (getResp.isErr()) {
            let errorMessage = '';
            if (typeof getResp.error.message === 'string') {
              errorMessage = getResp.error.message;
            }
            if (typeof getResp.error.message === 'object') {
              errorMessage = 'Validation Error';
            }
            enqueueSnackbar(errorMessage, SnackBarConfig('e'));
          } else if (getResp.isOk()) {
            activityListRecall();
            cancel();
            setNotification(true);
            enqueueSnackbar(
              'Request submited successfully',
              SnackBarConfig('s'),
            );
          }
        } else {
          setBtnDisable(false);
          // enqueueSnackbar('File upload failed', SnackBarConfig('e'));
        }
      }
    },
    [
      activityListRecall,
      cancel,
      enqueueSnackbar,
      fileUploadApi,
      getBodyFormData,
      irpServiceStore,
      isFilingPeriodFilled,
      location,
    ],
  );
  const onselect = useCallback(
    (equipmentArr: any) => {
      equipmentStore.setSelectedEquipmentList(
        [
          ...equipmentArr.map((val: any) => {
            return {
              ...val,
              // areRoadTaxesUpToDate: '',
              files: [],
              optionalFiles: [],
            };
          }),
        ],
        getValues('units'),
        append,
        arrayFieldRemove,
        { files: [], optionalFiles: [] }, // { areRoadTaxesUpToDate: '', files: [], optionalFiles: [] },
      );
    },
    [append, arrayFieldRemove, equipmentStore, getValues],
  );

  const remove = (equip: any) => {
    if (!btnDisable) {
      let arr: Array<any> = equipmentStore.SelectedEquipments;
      if (equip) {
        arr = arr.filter((ele) => ele.unitId !== equip.unitId);
      }
      equipmentStore.setSelectedEquipmentList(arr);
      // Removed field
      if (equip && equip.unitId) {
        const unitsArr = getValues('units');
        const index = unitsArr.findIndex((val) => val.unitId === equip.unitId);
        if (index !== -1) {
          arrayFieldRemove(index);
        }
      }
    }
    const tempFilingPeriod = { ...filingPeriods };
    const tempUploadedDocs = { ...uploadedDocs };
    delete tempFilingPeriod?.[equip.unitId];
    delete tempUploadedDocs?.[equip.unitId];
    setFilingPeriods(tempFilingPeriod);
    setUploadedDocs(tempUploadedDocs);
  };

  // const onSelectFromTodo = useCallback((equipments: any) => {
  //   if (equipments.length) {
  //     const ArrayEquipment: any = equipments.map((e: any) => {
  //       return {
  //         ...toJS(e),
  //       };
  //     });
  //     const ExistedEquipmentDetails: any = [...getValues('units')];
  //     const newEquipmentArr: any = [];

  //     ArrayEquipment.forEach((equipment: any) => {
  //       const findStatusIndex: any = ExistedEquipmentDetails.findIndex(
  //         (e: any) => e.unitId === equipment.unitId,
  //       );
  //       if (findStatusIndex !== -1) {
  //         ExistedEquipmentDetails.splice(findStatusIndex, 1);
  //       } else {
  //         newEquipmentArr.push(equipment);
  //       }
  //     });

  //     let originalEquipmentArray: any = [...getValues('units')];
  //     if (ExistedEquipmentDetails.length) {
  //       ExistedEquipmentDetails.forEach((equipment: any) => {
  //         const findIndexElem = originalEquipmentArray.findIndex(
  //           (e: any) => e.unitId === equipment.unitId,
  //         );
  //         if (findIndexElem !== -1) {
  //           originalEquipmentArray.splice(findIndexElem, 1);
  //         }
  //       });
  //     }

  //     originalEquipmentArray = [
  //       ...originalEquipmentArray,
  //       ...newEquipmentArr,
  //     ].map((equipment: any) => {
  //       const obj = { ...equipment };
  //       if (!obj.terminateStatus) {
  //         obj.terminateStatus = '';
  //       }
  //       return obj;
  //     });

  //     equipmentreplace(originalEquipmentArray);

  //     terminateEquipmentStore.addEquipmentDetails(originalEquipmentArray);
  //   }
  // }, []);

  useEffect(() => {
    getIRPPrdefinedValues();
    equipmentStore.restSlecetedEquipment();
  }, [equipmentStore, getIRPPrdefinedValues]);

  useEffect(() => {
    let data = todoStore.EquipmentFromTodo;
    if (data) {
      setValue('requestType', 'renewal');
    }
    if (data && data.unit) {
      data = toJS(data.unit);
      data = [data];
      onselect(data);
      todoStore.setEquipmentFromTodo();
    }
  }, [todoStore, onselect, setValue]);

  if (notification) {
    return (
      <SuccessNotification
        title="IRP Service"
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return loading ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ md: 3, sm: 2, xs: 1 }}
      minHeight={'100%'}>
      <Grid item md={12} sm={12} xs={12}>
        <IRPServiceNewForm
          {...{
            btnDisable,
            classes,
            control,
            equipment: equipmentStore.SelectedEquipments,
            equipmentStore,
            errors,
            fields,
            // fileValidation,
            handleSubmit,
            irpServiceStore,
            newFormOnSubmit,
            onselect,
            remove,
            setReminder,
            setValue,
            updateMethod: resetFormRequestType,
            watch,
          }}
          onFileChange={(
            docType: string,
            unitId: string,
            action: 'add' | 'remove',
          ) => {
            let tempList = uploadedDocs[unitId] || [];
            if (action === 'add') tempList = [...tempList, docType];
            else tempList = tempList.filter((el) => el !== docType);
            setUploadedDocs({ ...uploadedDocs, [unitId]: tempList });
          }}
          onFilingPeriodSelect={(year, unitId) => {
            setFilingPeriods({ ...filingPeriods, [unitId]: year });
          }}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <TaxPermitsButtons
          formId={'irpService'}
          disabled={btnDisable}
          formReset={cancel}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <DialogMigrate
          aria-labelledby="Reminder"
          maxWidth={'sm'}
          className={classes.dialog}
          open={reminder}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          onClose={() => setReminder(false)}>
          <Typography component={'div'} sx={{ padding: '1.8rem' }}>
            <Typography component={'div'} sx={{ marginBottom: '1rem' }}>
              Permit for selected state already exists, you can only submit
              Renewal/Additional request type
            </Typography>
            <DarkColorButton
              type="button"
              onClick={() => setReminder(false)}
              variant="contained"
              sx={{ borderRadius: '4px', width: '100%' }}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'FiraSans-Medium',
                  fontSize: '0.9375rem',
                  fontWeight: 500,
                  height: '22px',
                  letterSpacing: '1px',
                  lineHeight: '22px',
                  textAlign: 'center',
                }}>
                Ok
              </Typography>
            </DarkColorButton>
          </Typography>
        </DialogMigrate>
      </Grid>
    </Grid>
  );
};

export default observer(TaxPermitsIRPService);
