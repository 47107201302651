import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  backBtn: {
    paddingBottom: '16px',
  },
  backBtnContainer: {
    color: '#0061FF',
    fontFamily: 'FiraSans-SemiBold',
    fontSize: '16px',
    fontWeight: 600,
  },
  card: {
    '&.MuiCard-root': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #d8d8d8',
      borderRadius: 'unset',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.08)',
      cursor: 'pointer',
      margin: 'auto',
      marginTop: '40px',
      width: '500px',
    },
  },
  divider: {
    '&.MuiDivider-root': {
      border: '0.5px solid #E2E2E2',
      boxSizing: 'border-box',
      height: '1px',
    },
  },
  headerHeading: {
    '&.MuiTypography-root': {
      color: '#241A2E',
      fontFamily: 'FiraSans-SemiBold',
      fontSize: '20px',
      paddingBottom: '3px',
    },
  },
  headerSubHeading: {
    '&.MuiTypography-root': {
      color: '#241A2E',
      fontSize: '15px',
      paddingBottom: '10px',
    },
  },
  headingName: {
    '&.MuiTypography-root': {
      color: '#241A2E',
      cursor: 'default',
      fontFamily: 'FiraSans-SemiBold',
      fontSize: '20px',
      letterSpacing: 0,
      lineHeight: '29px',
      padding: '22px 15px',
    },
  },
  listName: {
    '&.MuiTypography-root': {
      '&:hover': {
        backgroundColor: '#f3f3f3',
      },
      alignItems: 'center',
      color: '#241A2E',
      display: 'flex',
      fontFamily: 'FiraSans-Medium',
      fontSize: '18px',
      justifyContent: 'space-between',
      letterSpacing: 0,
      padding: '12px 19px',
      textDecoration: 'auto',
    },
  },
}));

export default useStyles;
