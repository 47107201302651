import styled from '@mui/material/styles/styled';
import makeStyles from '@mui/styles/makeStyles';

const Img = styled('img')({
  display: 'block',
  // margin: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
});
const ContentDiv = styled('div')({
  display: 'flex',
  padding: '12px 0px 0px 4px',
});
const Label = styled('div')({
  color: '#241a2e',
  fontFamily: 'FiraSans-Medium ',
  fontSize: '0.8125rem',
  fontWeight: '500',
});
const Badge = styled('div')({
  alignItems: 'center',
  backgroundColor: '#f8f3d6',
  borderRadius: '8px',
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  width: '32px',
});
const Values = styled('div')({
  color: '#241a2e',
  fontSize: '0.8125rem',
  wordBreak: 'break-word',
});
const Button = styled('button')({
  backgroundColor: ' #e9e9e9',
  border: 'unset',
  borderRadius: '16px',
  color: '#6a666d',
  fontSize: '0.875rem',
  fontStyle: 'italic',
  fontWeight: '500',
  height: '32px',
  width: '115px',
});
const RecordNotFound = styled('div')({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  paddingTop: 20,
  textAlign: 'center',
  width: '100%',
});

const useStyle = makeStyles({
  clearIcon: {
    cursor: 'pointer',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      // height: 'auto',
    },
  },
  dialogContent: {
    backgroundColor: '#edeced',
  },
  dialogContentPadding: {
    padding: '12px',
  },
  dialogMainContent: {
    '&.MuiDialogContent-root': {
      padding: '0px 18px 18px 18px !important',
    },
  },
  dialogModifiedContent: {
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    paddingBottom: '5px !important',
  },
  dialogNotesContent: {
    color: '#6e6d6e !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
  },
  dialogReferenceIdView: {
    color: '#979598 !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontStyle: 'italic !important',
    fontWeight: 500,
    paddingRight: '16px !important',
  },
  dialogTitle: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '1.375rem',
    letterSpacing: 0,
  },
  headerContainer: {
    flex: 'auto',
    fontFamily: 'FiraSans-Regular',
    fontSize: '1.5rem',
  },
  headerRightSide: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'end',
  },
  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});
export {
  Img,
  ContentDiv,
  Label,
  Button,
  Values,
  Badge,
  RecordNotFound,
  useStyle,
};
