import { makeAutoObservable } from 'mobx';

export class GlobalStore {
  private _currentRoute = '';
  private _menuOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentRoute(route: string) {
    this._currentRoute = route;
  }
  setMenu(menu: boolean) {
    this._menuOpen = menu;
  }

  get getCurrentRoute() {
    return this._currentRoute;
  }

  get showMenu() {
    return this._menuOpen;
  }
}
