import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import LeftNav from '../../../components/LeftNav/LeftNav';
import NotificationDialogue from '../../../components/NotificationDialogue/NotificationDialogue';
import TopAppBar from '../../../components/TopAppBar/TopAppBar';
import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import useStyles from './homeStyle';

const Home: React.FC<any> = (props) => {
  const classes = useStyles();
  const history = useLocation();
  const { globalStore, displayNotification, userStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const isTrendDetails = location.pathname.includes('-of');
  const [useNoOverflowPath, setUseNoOverflowPath] = useState(false);

  React.useEffect(() => {
    globalStore.setCurrentRoute(history.pathname);
    const paths: any = history.pathname.split('/');
    if (paths[1] && paths[1] !== 'notifications') {
      displayNotification.setInitialLoadingStatus(true);
      getDialogueNotificationStatus();
    }
    if (
      paths[1] &&
      ['accidents', 'inspections-and-violations', 'violations'].includes(
        paths[1],
      )
    ) {
      setUseNoOverflowPath(true);
    } else {
      setUseNoOverflowPath(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.pathname]);

  const getDialogueNotificationStatus = React.useCallback(async () => {
    const disalogueNotificationResponse: any =
      await displayNotification.setToggleDialogueDisplayStatus(true);

    if (
      disalogueNotificationResponse &&
      disalogueNotificationResponse.isErr()
    ) {
      const {
        error: { message = null },
      } = disalogueNotificationResponse;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.sideNav}>
        <LeftNav />
      </div>
      <div
        id="main-content"
        className={
          useNoOverflowPath
            ? classes.mainContentNoOverflow
            : classes.mainContent
        }>
        <TopAppBar
          headerTitle={props.title ? props.title : 'Dashboard'}
          globalSearch={props.globalSearch ? props.globalSearch : false}
          globalFilter={props.globalFilter ? props.globalFilter : false}
          documentsFilter={
            props.documentsFilter ? props.documentsFilter : false
          }
          showInDocumentStoreHeaderName={
            props.showInDocumentStoreHeaderName
              ? props.showInDocumentStoreHeaderName
              : ''
          }
          showTabs={isTrendDetails ? false : true}
          backButton={props?.backButton}
          accessType={userStore.UserData.accessType}
        />
        <div className="content-body">{props.children}</div>
      </div>
      <NotificationDialogue />
    </div>
  );
};

export default observer(Home);
