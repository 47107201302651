import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { UseFocus, useMountEffect } from '../../../hooks';
import { UnitType } from '../../../types';
import { SelectDatePicker } from '../../Forms/datePicker';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type fleetFormTypes = {
  classes: any;
  fleetController: any;
  fleetSubmit: any;
  filter: any;
  resetFilter: any;
  searchPage: any;
  setFilters: (src: any) => void;
  submitFleetFilter: any;
  fleetStore: any;
};

const FleetSearchForm: React.FC<fleetFormTypes> = (props: fleetFormTypes) => {
  const {
    classes,
    fleetController,
    fleetSubmit,
    filter,
    resetFilter,
    searchPage,
    setFilters,
    submitFleetFilter,
    fleetStore,
  } = props;

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  const [dates, setDates] = useState<{
    endDate: Date | undefined;
    startDate: Date | undefined;
  }>({
    endDate: undefined,
    startDate: undefined,
  });

  return (
    <form onSubmit={fleetSubmit(submitFleetFilter)} id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Unit #
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={fleetController}
                defaultValue=""
                name="unitNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.unitNumber}
                      value={filter.unitNumber}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          unitNumber: e,
                        }));
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Simplex Unit ID
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={fleetController}
                defaultValue=""
                name="simplexId"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.simplexId}
                      value={filter.simplexId}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          simplexId: e,
                        }));
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          VIN
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={fleetController}
                defaultValue=""
                name="vinNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.vinNumber}
                      value={filter.vinNumber}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          vinNumber: e,
                        }));
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Unit Type
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={12} sm={12}>
              <Controller
                control={fleetController}
                defaultValue=""
                name="unitType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={filter.unitType}
                      inputRefObj={input1Ref}
                      size={'small'}
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          unitType: e,
                        }));
                      }}
                      isEditable={false}
                      selectClassName={classes.selectWhiteBg}
                      placeHolder={'Select'}
                      optionsList={[
                        UnitType.Truck,
                        UnitType.Tractor,
                        UnitType.Trailer,
                        UnitType.Chassis,
                        UnitType.Bus,
                        UnitType.Tanker,
                        UnitType.Van,
                        UnitType.Limousine,
                        UnitType.Other,
                        UnitType.PickUpTruck,
                      ].map((value: any) => ({
                        label: value,
                        optionClassName: classes.optionsClassName,
                        value,
                      }))}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Registration Expiration Start Date
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10} className="expiration-date">
              <Controller
                control={fleetController}
                name="expirationStartDate"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      value={value}
                      isRefreshValue
                      inputRefObj={ref}
                      name={name}
                      onChangeDate={(d) => {
                        setDates({ ...dates, startDate: new Date(d) });
                        const formattedValue = moment(d).format('MM/DD/YYYY');
                        onChange(formattedValue);
                        setFilters((filters: any) => ({
                          ...filters,
                          expirationStartDate: formattedValue,
                        }));
                      }}
                      className={classes.selectClassName}
                      maxDate={dates.endDate ? dates.endDate : undefined}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Registration Expiration End Date
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10} className="expiration-date">
              <Controller
                control={fleetController}
                name="expirationEndDate"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      value={value}
                      isRefreshValue
                      inputRefObj={ref}
                      name={name}
                      onChangeDate={(d) => {
                        setDates({ ...dates, endDate: new Date(d) });
                        const formattedValue = moment(d).format('MM/DD/YYYY');
                        onChange(formattedValue);
                        setFilters((filters: any) => ({
                          ...filters,
                          expirationEndDate: formattedValue,
                        }));
                      }}
                      className={classes.selectClassName}
                      minDate={dates.startDate ? dates.startDate : undefined}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              disabled={
                !(
                  fleetStore.Filters.simplexId ||
                  fleetStore.Filters.vinNumber ||
                  fleetStore.Filters.unitNumber ||
                  fleetStore.Filters.unitType ||
                  (filter.expirationStartDate && filter.expirationEndDate)
                )
              }
              type="button"
              onClick={() => {
                resetFilter();
                setDates({ endDate: undefined, startDate: undefined });
              }}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            disabled={
              !(
                filter.simplexId ||
                filter.vinNumber ||
                filter.unitNumber ||
                filter.unitType ||
                (filter.expirationStartDate && filter.expirationEndDate)
              )
            }
            className={classes.filterBtn}
            style={{
              backgroundColor: !(
                filter.simplexId ||
                filter.vinNumber ||
                filter.unitNumber ||
                filter.unitType ||
                (filter.expirationStartDate && filter.expirationEndDate)
              )
                ? '#d3d3d3'
                : '#DEC330',
            }}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(FleetSearchForm);
