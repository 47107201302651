import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React from 'react';

const CustomPaper = styled(Paper)(() => ({
  backgroundColor: '#E5E9EF !important',
  flexGrow: 1,
  padding: '10px 0',
}));
const Label = styled('p')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
}));
const Value = styled('p')(({ theme }) => ({
  fontSize: 14,
}));

type Props = {
  title?: string;
  data: { label: string; value: string }[];
  rootClassName?: string;
  mainGridClassName?: string;
  spacing?: number;
  headerLg?: any;
  bodyLg?: any;
};
const SimplexCard: React.FC<Props> = ({
  title = '',
  data,
  rootClassName = '',
  mainGridClassName = '',
  spacing = 2,
  headerLg = 1,
  bodyLg = 1.5,
}) => {
  return (
    <CustomPaper id="simplex-card" elevation={0} className={rootClassName}>
      <Grid
        container
        spacing={spacing}
        paddingLeft={2}
        alignItems="center"
        className={mainGridClassName}>
        {title && (
          <Grid lg={headerLg} item alignItems="center">
            <h5>{title}</h5>
          </Grid>
        )}
        {data.map(({ label, value }, index) => (
          <Grid lg={bodyLg} item key={index}>
            {label && (
              <Grid container>
                <Grid item paddingY={0.5}>
                  <Label>{label}</Label>
                </Grid>
              </Grid>
            )}
            {value && (
              <Grid container>
                <Grid item paddingY={0.5}>
                  <Value>{value}</Value>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </CustomPaper>
  );
};

export default SimplexCard;
