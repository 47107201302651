import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { FleetDTO } from '../../types';
import { RootStore } from '..';

export class FleetApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getFleetList = (params: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const restParams = this.objClean({ ...params });
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Fleet;

    return this.rootStore.apiStore.call<FleetDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  getFleetsBySearch = (params: {
    status: string;
    vinNumber: string;
    unitNumber: string;
    simplexId: string;
    sortBy: string;
    sortDirection: string;
    nextLink: string;
  }) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Fleet;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<FleetDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  exportFleets = (params: {
    nextLink: string;
    status: string;
    vinNumber: string;
    unitNumber: string;
    simplexId: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      Export: { Endpoint, Method },
    } = ApiRoutes.Fleet;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      responseType: 'blob',
      url: Endpoint,
    });
  };

  objClean(obj: any) {
    for (const key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        typeof obj[key] !== 'boolean' &&
        !obj[key]
      ) {
        delete obj[key];
      }
    }
    return obj;
  }
}
