import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <Grid container justifyContent={'center'} mt="40px">
      <Grid item md={1}>
        <Typography
          component={'span'}
          color={'#979598'}
          fontSize={'0.875rem'}
          fontFamily={'FiraSans-Medium'}
          fontStyle={'italic'}
          fontWeight={500}>
          Coming soon
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ComingSoon;
