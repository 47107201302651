import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

import { useCustomStore } from '../../hooks';
import useStyles from './FooterStyles';

type ButtonTypes = {
  formId?: string;
  disabled?: boolean | any;
  formReset?: () => void;
  disableLoader?: boolean | any;
  isNext?: boolean;
  onSubmit?: () => any;
  onCancel?: () => any;
};
const Footer: React.FC<ButtonTypes> = (props: ButtonTypes) => {
  const {
    formId,
    disabled,
    disableLoader = true,
    onCancel,
    onSubmit,
    isNext = false,
  } = props;
  const classes = useStyles();
  const { authStore } = useCustomStore();

  return (
    <Typography component={'div'} className={classes.bottomAction}>
      <Typography component={'span'}>
        <Button
          disabled={disabled}
          variant={'text'}
          className={'d-flex-ja '.concat(classes.cancelBtn)}
          onClick={onCancel}>
          Cancel
        </Button>
      </Typography>
      <Typography component={'div'} className={classes.rightSidebtn}>
        <Button
          form={formId}
          disabled={
            disabled ||
            (authStore.getTokenDetail && authStore.getTokenDetail?.adminId)
          }
          sx={{
            '&:hover': {
              backgroundColor: '#dec330',
            },
            backgroundColor: '#dec330',
          }}
          type="submit"
          variant="contained"
          onClick={onSubmit}
          className={'add-btn d-flex-ja '
            .concat(classes.submited)
            .concat(
              authStore.getTokenDetail && authStore.getTokenDetail?.adminId
                ? ' disable'
                : '',
            )}>
          {disabled && disableLoader ? (
            <CircularProgress
              className={classes.circularProgress}
              size={30}
              sx={{ color: '#DEC330' }}
            />
          ) : (
            ''
          )}
          {isNext ? 'Next' : 'Submit'}
        </Button>
      </Typography>
    </Typography>
  );
};

export default Footer;
