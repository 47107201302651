import { styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

type Props = {
  type: 'DOT PIN' | 'FMCSA Crdentials';
  title: string;
  data: DOTPin | FMCSA;
  img?: any;
  onClickUpdate: () => unknown;
};

const Card = styled('article')(() => ({
  backgroundColor: '#FFF',
  borderRadius: 3,
  boxShadow: '0 4px 12px 0 rgba(0,0,0,0.06)',
  height: '100%',
  padding: '1.2rem',
}));

const P = styled('p')(() => ({
  color: '#241A2E',
  fontFamily: 'FiraSans-Regular',
  fontSize: '0.8rem',
  margin: '2px 0',
}));

const B = styled('b')<{ valid: boolean }>(({ valid }) => ({
  color: valid ? '#a10e24' : '#000',
}));

const A = styled('a')(() => ({
  color: 'blue',
  cursor: 'pointer',
  fontSize: 12,
  marginTop: '0.5rem',
  textDecoration: 'underline',
}));

const useStyles = makeStyles(() => ({
  header: {
    '& img': {
      margin: '-0.5rem 0 0 -0.5rem',
    },
    alignItems: 'center',
    display: 'flex',
  },
  info: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.8rem',
  },
  title: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Medium',
    fontSize: '1.1rem',
  },
}));

const Credentials: React.FC<Props> = ({ title, data, img, onClickUpdate }) => {
  const classes = useStyles();
  const { status, lastConfirmed } = data || {};

  return (
    <Card className={clsx(['flex-column', 'space-between', 'flex-grow-1'])}>
      <div className={classes.header}>
        {img && <img src={img} />}
        <span className={classes.title}>{title}</span>
      </div>
      <section className={clsx(['flex-row', classes.info])}>
        <P>
          Status: <B valid={status !== 'Valid'}> {status || '-'}</B>
        </P>
      </section>
      <section className={clsx(['flex-row', classes.info])}>
        <P>
          Last confirmed on:{' '}
          <B valid={status !== 'Valid'}>{lastConfirmed || 'N/A'}</B>
        </P>
      </section>
      <A onClick={onClickUpdate}>Update {title}</A>
    </Card>
  );
};

export default observer(Credentials);
