import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCustomStore } from '../../../../hooks';
import { isExpired } from '../../../../utils/helper';
import DriverInfo from '../../Profile/DriverInfo/DriverInfo';
import DriverMedicalInfo from '../../Profile/DriverInfo/DriverMedicalInfo';
import useStyles from '../../Profile/ProfileStyles';
import DriverUpdateForm from './DriverUpdateForm';
import MedicalCardForm from './MedicalCardForm';

const DriverProfile: React.FC = () => {
  const { authStore, driverListStore }: any = useCustomStore();
  const { ShowLoader } = driverListStore;
  const classes = useStyles();
  const params = useParams();
  const [UserData, setDriverList] = useState<any>(undefined);
  const [profileEdit, setProfileEdit] = useState<boolean>(false);
  const [medicalEdit, setMedicalEdit] = useState<boolean>(false);
  const [showMedicalCard, setShowMedicalCard] = useState<boolean>(true);
  const handleGetDriverList = async () => {
    driverListStore.setShowLoader(true);
    const id = params ? params.driverid : '';

    if (id) {
      const res = await driverListStore.getDriverById(id);
      if (res) {
        driverListStore.setShowLoader(false);
        setDriverList(res);
        res.licenseType === 'Non-CDL' && handleShowMedicalCard(false);
      }
    }
  };
  useEffect(() => {
    handleGetDriverList();
    // eslint-disable-next-line
  }, []);
  if (ShowLoader && !UserData) {
    return (
      <div className={classes.loader}>
        <CircularProgress color="primary" size={50} />
      </div>
    );
  }
  const handleShowMedicalCard = (show: boolean) => {
    setShowMedicalCard(show);
  };

  // const expiredStatusLabel = (date: string | null) => {
  //   if (date) {
  //     if (moment(date) < moment()) {
  //       return (
  //         <Typography
  //           style={{
  //             color: '#A70D0D',
  //             fontFamily: 'FiraSans-Bold',
  //             fontSize: '14px',
  //             lineHeight: '20px',
  //             marginLeft: '10px',
  //           }}>
  //           Expired
  //         </Typography>
  //       );
  //     } else {
  //       if (moment().diff(moment(date), 'days') < 30) {
  //         return (
  //           <Typography
  //             style={{
  //               color: '#E8932E',
  //               fontFamily: 'FiraSans-Bold',
  //               fontSize: '14px',
  //               lineHeight: '20px',
  //               marginLeft: '10px',
  //             }}>
  //             Expiring
  //           </Typography>
  //         );
  //       }
  //     }
  //     return;
  //   } else {
  //     return;
  //   }
  // };

  return (
    <div>
      {UserData && (
        <>
          {profileEdit ? (
            <DriverUpdateForm
              driverList={UserData}
              cancel={() => setProfileEdit(!profileEdit)}
              hanldeGetUsersProfilelInfo={handleGetDriverList}
              handleShowMedicalCard={handleShowMedicalCard}
            />
          ) : (
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: '20px' }}>
              <Grid lg={8} md={12}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item md={8}>
                        <Typography className={classes.profileTitle}>
                          Driver Information
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          className={classes.editBtn.concat(
                            authStore.getTokenDetail &&
                              authStore.getTokenDetail?.adminId
                              ? ' disable'
                              : '',
                          )}
                          {...(authStore.getTokenDetail &&
                          authStore.getTokenDetail?.adminId
                            ? {}
                            : setProfileEdit(true))}>
                          Edit
                        </Typography>
                      </Grid>
                      <DriverInfo {...{ UserData }} />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}

          {medicalEdit && showMedicalCard ? (
            <MedicalCardForm
              driverList={UserData}
              cancel={() => setMedicalEdit(!medicalEdit)}
              hanldeGetUsersProfilelInfo={handleGetDriverList}
            />
          ) : (
            <div>
              {showMedicalCard && (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: '20px' }}>
                  <Grid lg={8} md={12}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid
                            item
                            md={8}
                            style={{ alignItems: 'center', display: 'flex' }}>
                            <Typography className={classes.profileTitle}>
                              {medicalEdit
                                ? 'Update Medical Card'
                                : 'Medical Card'}
                            </Typography>
                            {isExpired(UserData.medicalCardExpiryDate) ? (
                              <InfoIcon
                                className={classes.expiredIcon}
                                style={{
                                  fill: '#E8932E !important',
                                  height: '18px !important',
                                  margin: '0 0.1rem 0 0.25rem ',
                                  paddingLeft: '10px',
                                  width: '18px !important',
                                }}
                              />
                            ) : null}
                            <Typography
                              component={'span'}
                              className={classes.expired}>
                              {isExpired(UserData.medicalCardExpiryDate)}
                            </Typography>
                            {/* {expiredStatusLabel(UserData.medicalCardExpiryDate)} */}
                          </Grid>
                          <Grid item md={4}>
                            <Typography
                              className={classes.editBtn.concat(
                                authStore.getTokenDetail &&
                                  authStore.getTokenDetail?.adminId
                                  ? ' disable'
                                  : '',
                              )}
                              {...(authStore.getTokenDetail &&
                              authStore.getTokenDetail?.adminId
                                ? {}
                                : setMedicalEdit(true))}>
                              Edit
                            </Typography>
                          </Grid>
                          <DriverMedicalInfo {...{ UserData }} />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
          <Grid
            container
            justifyContent="center"
            style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Grid lg={4} md={4} style={{ paddingRight: '10px' }}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      md={12}
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        marginBottom: '20px',
                      }}>
                      <Typography className={classes.profileTitle}>
                        MVR
                      </Typography>
                      {isExpired(UserData.mvrExpirationDate) ? (
                        <InfoIcon
                          className={classes.expiredIcon}
                          style={{ paddingLeft: '10px' }}
                        />
                      ) : null}
                      <Typography
                        component={'span'}
                        className={classes.expired}>
                        {isExpired(UserData.mvrExpirationDate)}
                      </Typography>
                      {/* {expiredStatusLabel(UserData.mvrExpirationDate)} */}
                    </Grid>
                    <Grid item md={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Box className={classes.formLabelClass}>Status</Box>
                          {/* <Typography>
                            {UserData.mvrStatus ? UserData.mvrStatus : '-'}
                          </Typography> */}
                          {UserData.mvrStatus ? (
                            <Typography style={{ color: '#241A2E' }}>
                              {UserData.mvrStatus}
                            </Typography>
                          ) : (
                            <Typography style={{ color: '#A70D0D' }}>
                              MISSING
                            </Typography>
                          )}
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                          <Box className={classes.formLabelClass}>
                            Expiration Date
                          </Box>
                          {UserData.mvrExpirationDate ? (
                            <Typography style={{ color: '#241A2E' }}>
                              {UserData.mvrExpirationDate}
                            </Typography>
                          ) : (
                            <Typography style={{ color: '#A70D0D' }}>
                              MISSING
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid lg={4} md={4} style={{ paddingLeft: '10px' }}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      md={12}
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        marginBottom: '20px',
                      }}>
                      <Typography className={classes.profileTitle}>
                        ClearingHouse
                      </Typography>
                      {isExpired(UserData.clearHouseExpDate) ? (
                        <InfoIcon
                          className={classes.expiredIcon}
                          style={{ paddingLeft: '10px' }}
                        />
                      ) : null}
                      <Typography
                        component={'span'}
                        className={classes.expired}>
                        {isExpired(UserData.clearHouseExpDate)}
                      </Typography>
                      {/* {expiredStatusLabel(UserData.clearHouseExpDate)} */}
                    </Grid>
                    <Grid item md={12}>
                      <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                          <Box className={classes.formLabelClass}>
                            Expiration Date
                          </Box>
                          {UserData.clearHouseExpDate ? (
                            <Typography style={{ color: '#241A2E' }}>
                              {UserData.clearHouseExpDate}
                            </Typography>
                          ) : (
                            <Typography style={{ color: '#A70D0D' }}>
                              MISSING
                            </Typography>
                          )}
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                          <Box className={classes.formLabelClass}>
                            Query Result
                          </Box>
                          <Typography>
                            {UserData.clearHouseQueryResult ? (
                              <Typography style={{ color: '#241A2E' }}>
                                {UserData.clearHouseQueryResult}
                              </Typography>
                            ) : (
                              <Typography style={{ color: '#A70D0D' }}>
                                MISSING
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default DriverProfile;
