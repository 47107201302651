import { styled, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { AllowedUploadMimeTypes } from '../../../../../config/Constants';
import { NewDriverSetupInputs } from '../../../../../types';
import UploadFiles from '../../components/UploadFiles';
import { PSPDateForm } from '../components/PSPForm';
import { BgCheckForm } from './BgCheckForm';

const optionsOfUpload = ['upload authorization', 'upload record'];
const sectionsWithSubForms = [
  'PSP',
  'Background Check',
  'Drivers Application',
  'Pre-employment Drug Test',
];
const optionsWithNoSubForms: { [key: string]: any } = {
  'Background Check': ['upload authorization'],
  'Drivers Application': [],
  PSP: [],
  'Pre-employment Drug Test': [],
};

type BgForm = {
  backgroundCheckOrderedDate: string;
  backgroundCheckResultsDate: string;
};
type Props = {
  documents: any[];
  driver?: NewDriverSetupInputs;
  onInteract: (sections: any[], subFormData?: any) => any;
  showAttachments: () => any;
  disableInteraction?: boolean;
  childFormValues: Record<string, Record<string, string>>;
};
const Title = styled(Typography)(() => ({
  fontSize: 12,
  marginBottom: 5,
}));

const UploadAttachments: React.FC<Props> = ({
  documents,
  onInteract,
  driver,
  showAttachments,
  disableInteraction = false,
  childFormValues = {},
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sections, setSections] = useState<NewDriverDocument[]>([]);
  const [selectedSection, setSelectedSection] = useState<{
    [key: string]: any;
  }>({});

  const [sectionSubFormData, setSectionSubFormData] = useState<{
    [key: string]: {
      [key: string]: any;
    };
  }>(childFormValues);

  useEffect(() => {
    const { licenseType = '' } = driver || {};
    let tempDocs: NewDriverDocument[] = [];
    if (licenseType === 'Non-CDL') {
      tempDocs = documents.filter((doc) => {
        const { caseIdName } = doc;
        return (
          caseIdName !== 'Pre-employment Drug Test' &&
          caseIdName !== 'Clearinghouse Query'
        );
      });
      setSections(tempDocs);
    } else if (licenseType === 'Mexican') {
      tempDocs = documents.filter((doc) => doc.caseIdName !== 'MVR');
      setSections(tempDocs);
    } else setSections([...documents]);
  }, [documents, driver]);

  const handleUpload = (event: any) => {
    const file = event.target.files[0];
    const selectedSectionIndex = selectedSection.index as number;

    const existingSections = [...sections];
    existingSections[selectedSectionIndex].name =
      existingSections[selectedSectionIndex].caseIdName;
    existingSections[selectedSectionIndex].attachment = file;
    existingSections[selectedSectionIndex].value = file;
    if (
      existingSections[selectedSectionIndex].requestType === '-' &&
      existingSections[selectedSectionIndex].defaultValue
    ) {
      existingSections[selectedSectionIndex].requestType = 'upload record';
    }
    existingSections[selectedSectionIndex].error = '';
    existingSections[selectedSectionIndex].showError = false;
    const currentSectionName =
      existingSections[selectedSectionIndex].name || '';

    if (
      sectionsWithSubForms.includes(currentSectionName) &&
      !sectionSubFormData[currentSectionName] &&
      Array.isArray(optionsWithNoSubForms[currentSectionName]) &&
      !optionsWithNoSubForms[currentSectionName].includes(
        existingSections[selectedSectionIndex].requestType,
      )
    ) {
      existingSections[selectedSectionIndex].subFormError = 'Date is required';
    }
    setSections([...existingSections]);
    onInteract(existingSections, sectionSubFormData);
    if (fileInputRef && fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleSelect = (requestType = '', index: number) => {
    setSelectedSection({
      index,
      requestType,
    });
    const existingSections = [...sections];
    const sectionName: string = existingSections[index]?.caseIdName || '';
    existingSections[index].name = sectionName;
    existingSections[index].requestType = requestType;
    existingSections[index].value = requestType;
    if (optionsOfUpload.includes(existingSections[index].requestType)) {
      existingSections[index].error = !existingSections[index].attachment
        ? 'Please attach file'
        : '';
      const currentSectionName = existingSections[index].name || '';
      if (
        sectionsWithSubForms.includes(currentSectionName) &&
        !sectionSubFormData[currentSectionName] &&
        Array.isArray(optionsWithNoSubForms[sectionName]) &&
        !optionsWithNoSubForms[sectionName].includes(requestType)
      ) {
        existingSections[index].subFormError = 'Date is required';
      }
    } else if (requestType === '-' && !existingSections[index].isOptional) {
      existingSections[index].error = '';
      existingSections[index].showError = true;
    } else {
      existingSections[index].error = '';
      existingSections[index].showError = false;
      existingSections[index].subFormError = '';
    }
    setSections([...existingSections]);
    onInteract(existingSections);
  };

  const resetRequestType = (index: number) => {
    const existingSections = [...sections];
    const currentSectionName = existingSections[index].caseIdName || '';
    const subFormData = { ...sectionSubFormData };
    delete subFormData[currentSectionName];
    setSectionSubFormData(subFormData);
    existingSections[index].requestType = '-';
    existingSections[index].attachment = null;
    existingSections[index].value = null;
    existingSections[index].error = '';
    existingSections[index].showError = false;
    existingSections[index].subFormError = '';
    setSections([...existingSections]);
    onInteract(existingSections, subFormData);
  };

  const updateErrorsForPSPSection = (index: number, formValue: any) => {
    const existingSections = [...sections];
    const section = existingSections[index];
    existingSections[index].error = section.attachment
      ? ''
      : 'Attachment is required';
    existingSections[index].subFormError = '';
    setSections([...existingSections]);
    onInteract(existingSections, formValue);
  };

  const updateErrorsForPrempDrugTestSection = (
    index: number,
    formValue: any,
  ) => {
    const existingSections = [...sections];
    const section = existingSections[index];
    if (section.attachment && !formValue) {
      existingSections[index].showError = true;
      existingSections[index].subFormError = 'Collection Date is required';
    } else {
      existingSections[index].showError = false;
      existingSections[index].subFormError = '';
    }
    setSections([...existingSections]);
    onInteract(existingSections, formValue);
  };

  const updateErrorsForBgCheckSection = (
    index: number,
    bgDate: BgForm,
    formValue: any,
  ) => {
    const existingSections = [...sections];
    const section = existingSections[index];
    if (
      !bgDate.backgroundCheckOrderedDate ||
      !bgDate.backgroundCheckResultsDate
    )
      existingSections[index].subFormError = 'Date is required';
    else if (!section.attachment) {
      existingSections[index].error = 'Attachment is required';
      existingSections[index].subFormError = '';
    } else {
      existingSections[index].error = '';
      existingSections[index].subFormError = '';
    }
    setSections([...existingSections]);
    onInteract(existingSections, formValue);
  };

  const updateErrorsForDriverApplicationSection = (
    index: number,
    bgDate: string,
    formValue: any,
  ) => {
    const existingSections = [...sections];
    const section = existingSections[index];
    if (!bgDate) existingSections[index].subFormError = 'Date is required';
    else if (!section.attachment) {
      existingSections[index].error = 'Attachment is required';
      existingSections[index].subFormError = '';
    } else {
      existingSections[index].error = '';
      existingSections[index].subFormError = '';
    }
    setSections([...existingSections]);
    onInteract(existingSections, formValue);
  };

  const renderPSPForm = (requestType: string, index: number) => {
    if (requestType === 'upload record') {
      return (
        <PSPDateForm
          title="PSP Run Date"
          defaultValue={sectionSubFormData?.PSP?.pspRunDate}
          onChange={(date: string) => {
            const value = {
              ...sectionSubFormData,
              PSP: {
                pspRunDate: date,
              },
            };
            setSectionSubFormData(value);
            updateErrorsForPSPSection(index, value);
          }}
        />
      );
    }
    if (requestType === 'upload authorization') {
      const minDate = new Date();
      minDate.setDate(minDate.getDate() - 30);
      return (
        <PSPDateForm
          defaultValue={sectionSubFormData?.PSP?.pspAuthSignedDate}
          minDate={minDate}
          title="PSP Authorization Signed Date"
          onChange={(date: string) => {
            const value = {
              ...sectionSubFormData,
              PSP: {
                pspAuthSignedDate: date,
              },
            };
            setSectionSubFormData(value);
            updateErrorsForPSPSection(index, value);
          }}
        />
      );
    }
  };

  const renderBgCheckForm = (requestType: string, index: number) => {
    if (requestType === 'upload record') {
      return (
        <BgCheckForm
          defaultValue={sectionSubFormData?.['Background Check'] as any}
          onChange={(date: BgForm) => {
            const value = {
              ...sectionSubFormData,
              'Background Check': {
                ...date,
              },
            };
            setSectionSubFormData(value);
            updateErrorsForBgCheckSection(index, date, value);
          }}
        />
      );
    }
  };
  const renderDriverApplicationForm = (requestType: string, index: number) => {
    if (requestType === 'upload record') {
      // reusing PSPDateFrom for DriverApplicationForm
      return (
        <PSPDateForm
          defaultValue={
            sectionSubFormData?.['Drivers Applcation']?.applicationDate
          }
          onChange={(date: string) => {
            const value = {
              ...sectionSubFormData,
              'Drivers Application': {
                applicationDate: date,
              },
            };
            setSectionSubFormData(value);
            updateErrorsForDriverApplicationSection(index, date, value);
          }}
          title="Application Date"
        />
      );
    }
  };

  const renderPrempDrugTestForm = (index: number) => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 30);
    // Reusing PSPDateFrom  for Pre-employment Drug Test section
    return (
      <PSPDateForm
        title="Collection Date"
        minDate={minDate}
        onChange={(date: string) => {
          const value = {
            ...sectionSubFormData,
            'Pre-employment Drug Test': {
              collectionDate: date,
            },
          };
          setSectionSubFormData(value);
          updateErrorsForPrempDrugTestSection(index, value);
        }}
      />
    );
  };

  const renderChild = (section: NewDriverDocument, index: number) => {
    const { shouldShowForm, requestType, name = '', attachment } = section;
    if (shouldShowForm && shouldShowForm(requestType)) {
      if (name === 'PSP') {
        return renderPSPForm(requestType, index);
      }
      if (name === 'Background Check') {
        return renderBgCheckForm(requestType, index);
      }
      if (name === 'Drivers Application') {
        return renderDriverApplicationForm(requestType, index);
      }
    }

    if (name === 'Pre-employment Drug Test' && attachment) {
      return renderPrempDrugTestForm(index);
    }
    return null;
  };

  return (
    <div className="attachment">
      <Title>Driver Created</Title>
      <h3>
        {`${driver?.firstName || ''} ${driver?.lastName || ''} | DL#: ${
          driver?.licenseNumber || ''
        } | DOB: ${driver?.dob || ''}`}{' '}
        <span className="link-text" onClick={showAttachments}>
          Show Details
        </span>
      </h3>

      <br />
      <p className="menu-text">
        Complete the following Driver Set Up by Uploading the proper documents
        or requesting the driver to upload.
      </p>
      <input
        onChange={handleUpload}
        ref={fileInputRef}
        type="file"
        accept={AllowedUploadMimeTypes}
        hidden
      />
      {sections.map((section, index) => (
        <UploadFiles
          // selectedOptionId={sections[index].selectedOptionId}
          // attachment={sections[index].attachment}
          onRemoveRequest={() => resetRequestType(index)}
          onSelect={(requestType: string) => handleSelect(requestType, index)}
          onClick={() => setSelectedSection({ ...selectedSection, index })}
          fileInputRef={fileInputRef}
          key={index}
          disable={disableInteraction}
          // sectionIndex={index}
          {...section}
          onSubmitModal={(attachment) => {
            const event = {
              target: {
                files: [attachment],
              },
            };
            handleUpload(event);
          }}
          renderChild={() => renderChild(section, index)}
        />
      ))}
    </div>
  );
};

export default UploadAttachments;
