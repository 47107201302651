import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ContentDiv, Label, Value } from './ListItemStyles';

interface ListItemProps {
  label: string;
  value: any;
  moreLabelWidth?: boolean;
}
const FallbackText = styled(Typography)((props) => ({
  color: '#DB2426',
  fontSize: 12,
  fontStyle: 'italic',
  fontWeight: 'bold',
  margin: '5px 0',
  textTransform: 'uppercase',
}));
const ListItem: React.FC<ListItemProps> = ({
  label,
  value = '',
  moreLabelWidth = false,
}) => {
  return (
    <ContentDiv>
      <Grid container minHeight={'100%'}>
        <Grid item md={moreLabelWidth ? 6 : 4}>
          <Label>{label}</Label>
        </Grid>
        <Grid item md={6}>
          <Value>{value || <FallbackText>MISSING</FallbackText>}</Value>
        </Grid>
      </Grid>
    </ContentDiv>
  );
};

export default ListItem;
