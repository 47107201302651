import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  card: {
    '&.MuiCard-root': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #d8d8d8',
      borderRadius: 'unset',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.08)',
      cursor: 'pointer',
      minHeight: '203px',
    },
  },
  companyInfoContainer: {
    margin: 'auto',
    marginTop: '40px',
    width: '782px',
  },
  headingName: {
    '&.MuiTypography-root': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Bold',
      fontSize: '20px',
      paddingBottom: '18px',
    },
  },
  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },
  infodis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },
  loader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: '203px',
  },
}));

export default useStyles;
