import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';

import { useCustomStore } from '../../hooks';
import { InputText } from '../Forms/InputText';

const InfiniteSelect = styled('div')(() => ({
  position: 'relative',
}));

const Container = styled(Box)(() => ({
  backgroundColor: '#FFF',
  boxShadow: '2px 1px 5px 1px #cccccc',
  maxHeight: '150px',
  overflowY: 'scroll',
  position: 'absolute',
  width: '100%',
  zIndex: 10,
}));

const SelectPrimaryDriver: React.FC<{
  errors?: string;
  name?: string;
  onChange: (value: string | null) => unknown;
  disable?: boolean;
  disableNoneOption?: boolean;
  value: string;
  ref: any;
  initialDriverName?: string;
}> = ({
  errors,
  name,
  value,
  onChange,
  disable = false,
  disableNoneOption = false,
  ref,
  initialDriverName,
}) => {
  const { primaryDriversStore } = useCustomStore();
  const [showList, setShowList] = useState<boolean>();
  const [driverName, setDriverName] = useState<string>();

  const [showLoader, setShowLoader] = useState<boolean>();

  const intervalRef = useRef<NodeJS.Timeout>();

  const [isPointerAway, setIsPointerAway] = useState<boolean>(false);

  React.useEffect(() => {
    if (primaryDriversStore.DriversList.length) return;
    primaryDriversStore.setShowLoader(true);
    primaryDriversStore
      .getList()
      .then(() => primaryDriversStore.setShowLoader(false));
  }, [primaryDriversStore]);

  const handleScroll = (event: any) => {
    const myDiv = event?.target as HTMLDivElement;
    if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 100) {
      if (primaryDriversStore.NextLink) {
        setShowLoader(true);
        primaryDriversStore.getList().then(() => setShowLoader(false));
      }
    }
  };

  const handleTextChange = (text: string) => {
    onChange('');
    setDriverName(text);
    if (intervalRef.current) clearTimeout(intervalRef.current);
    intervalRef.current = setTimeout(() => {
      primaryDriversStore.resetParamsAndData();
      setShowLoader(true);
      primaryDriversStore.setName(text);
      primaryDriversStore.getList().then(() => setShowLoader(false));
    }, 500);
  };

  useEffect(() => {
    if (!value) {
      setDriverName('');
      return;
    }
    if (driverName) return;
    const selectedDriver = primaryDriversStore.DriversList.find(
      (el) => el?.contactid === value,
    );

    setDriverName(
      selectedDriver?.fullname ||
        initialDriverName ||
        primaryDriversStore.PrimaryDriverName,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, primaryDriversStore.PrimaryDriverName]);

  useEffect(() => {
    return () => {
      primaryDriversStore.resetParamsAndData();
    };
  }, [primaryDriversStore]);

  return (
    <InfiniteSelect id="infinite-select-container">
      <InputText
        autoComplete="off"
        inputRefObj={ref}
        errors={errors}
        name={name as string}
        isRefreshValue={true}
        value={driverName}
        onClick={() => setShowList(true)}
        onChangeText={handleTextChange}
        isEditable={disable}
        type="text"
        variant="standard"
        placeholder="Search"
        onBlurText={() => {
          if (isPointerAway) {
            setShowList(false);
          }
        }}
      />

      {showList && !disable && (
        <Container
          onMouseEnter={() => setIsPointerAway(false)}
          onMouseLeave={() => setIsPointerAway(true)}
          onScroll={handleScroll}>
          <List>
            <ListItem
              disabled={disableNoneOption}
              disablePadding
              key={-1}
              onClick={() => {
                if (disableNoneOption) return;
                onChange('None');
                setDriverName('None');
                setShowList(false);
              }}>
              <ListItemButton>
                <ListItemText>None</ListItemText>
              </ListItemButton>
            </ListItem>
            {!showLoader && primaryDriversStore.DriversList.length === 0 && (
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="-No Results-" />
                </ListItemButton>
              </ListItem>
            )}
            {primaryDriversStore.DriversList.map((primaryDriver, index) => (
              <ListItem
                disablePadding
                key={index}
                onClick={() => {
                  onChange(primaryDriver.contactid);
                  setDriverName(primaryDriver.fullname);
                  setShowList(false);
                }}>
                <ListItemButton>
                  <ListItemText primary={primaryDriver.fullname} />
                </ListItemButton>
              </ListItem>
            ))}
            {showLoader && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#DEC330', marginTop: 20 }}
                />
              </div>
            )}
          </List>
        </Container>
      )}
    </InfiniteSelect>
  );
};

export default observer(SelectPrimaryDriver);
