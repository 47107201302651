import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { CompanyOrDriverDTO } from '../../types';
import { RootStore } from '..';

export class TodoApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getTodoList = (params: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Todo;
    const restParams = this.objClean({ ...params });
    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      // params,
      url: Endpoint,
    });
  };

  getTodosBySearch = (params: {
    limit: number;
    nextLink: string;
    status: string;
    category: Array<any>;
    firstName: string;
    lastName: string;
    simplexId: string;
    year: string;
    make: string;
    vinNumber: string;
    unitNumber: string;
    dueIn: string;
    type: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Todo;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  exportTodos = (params: {
    nextLink: string;
    status: string;
    category: Array<any>;
    firstName: string;
    lastName: string;
    simplexId: string;
    year: string;
    make: string;
    vinNumber: string;
    unitNumber: string;
    dueIn: string;
    type: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      Export: { Endpoint, Method },
    } = ApiRoutes.Todo;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      responseType: 'blob',
      url: Endpoint,
    });
  };

  submitTodo = (URL: string, data: any) => {
    const {
      FormSubmit: { Endpoint, Method },
    } = ApiRoutes.Todo;
    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: Endpoint.concat('-' + URL),
    });
  };

  submitTodoAcknowledgmentFormRequest = (data: any) => {
    const { Method, Endpoint } = ApiRoutes.Todo.UpdateAcknowledgmentFormTodo;
    const response = this.rootStore.apiStore.call({
      data,
      method: Method,
      url: Endpoint,
    });
    return response;
  };

  dismissTodo = (data: { todoId: string }) => {
    const {
      DismissTodo: { Endpoint, Method },
    } = ApiRoutes.Todo;
    return this.rootStore.apiStore.call<void>({
      data,
      method: Method,
      url: Endpoint(data.todoId),
    });
  };

  docuSign = (todo: any, slug: string) => {
    const {
      DriverApplication: { Endpoint, Method },
    } = ApiRoutes.DocuSign;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        contactId: todo.contactId,
        todoId: todo.id,
      },
      url: `${Endpoint}/${slug}`,
    });
  };

  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }

  getTodoDetailsForAnAssignment = (todoId: string) => {
    const {
      GetTodoDetailsForAnAssignment: { Endpoint, Method },
    } = ApiRoutes.Todo;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        todoId,
      },
      url: Endpoint,
    });
  };
}
