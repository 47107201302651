import NavRoutes from './routes/NavRoutes';

export const Config = {
  API_URL: `${process.env.REACT_APP_BASE_URL || ''}/v1`,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_PRIVACY_POLICY: process.env.REACT_APP_PRIVACY_POLICY,
  REACT_APP_TERMS: process.env.REACT_APP_TERMS,
};

export const API_LIMIT = 20;

export const PublicRoute = {
  CREATEPASSWORD: `/${NavRoutes.PUBLIC.CREATEPASSWORD}`,
  FORGOTPASSWORD: `/${NavRoutes.PUBLIC.FORGOTPASSWORD}`,
  FORGOTPASSWORDWITHID: `/${NavRoutes.PUBLIC.FORGOTPASSWORD}/${NavRoutes.PUBLIC.CONTACT.ID}`,
  LOGIN: `/${NavRoutes.PUBLIC.LOGIN}`,
  LOGINASIMPERSONATE: NavRoutes.PUBLIC.IMPERSONATE,
  RESETPASSWORD: `/${NavRoutes.PUBLIC.RESETPASSWORD}`,
};

export const PrivateRoute: any = {
  DASHBOARD: `/${NavRoutes.PRIVATE.LEFT_NAV.DASHBOARD}`,
  DOCUMENTS: `/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}`,
  DOT: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.LEFT_NAV.DOT}`,
  DRIVERS: `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVERS}`,
  FLEET: `/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}`,
  NOTIFICATIONS: `/${NavRoutes.PRIVATE.LEFT_NAV.NOTIFICATIONS}`,
  PROFILE: `/${NavRoutes.PRIVATE.LEFT_NAV.PROFILE}`,
  REPORTS: `/${NavRoutes.PRIVATE.LEFT_NAV.REPORTS}`,
  SERVICEREQUEST: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}`,
  SETTINGS: `/${NavRoutes.PRIVATE.SETTINGSROUTE.SETTINGS}`,
  TAXPERMITS: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.LEFT_NAV.TAXPERMITS}`,
  TODOS: `/${NavRoutes.PRIVATE.LEFT_NAV.TODOS}`,
  USERS: `/${NavRoutes.PRIVATE.LEFT_NAV.USERS}`,
};

export const AllowedImageFileTypes = [
  'png',
  'jpeg',
  'jpg',
  'svg',
  'gif',
  'tiff',
  'tif',
  'jfif',
];

export const AllowedPdfFileTypes = ['pdf'];
export const AllowedDocFileTypes = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'txt'];

export const AllowedUploadFileTypes = [
  ...AllowedImageFileTypes,
  ...AllowedPdfFileTypes,
  ...AllowedDocFileTypes,
];

export const AllowedUploadMimeTypes = [
  'text/csv', // csv
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  // 'application/vnd.ms-excel', // xls
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'image/*', // images
  'application/pdf', // pdf
  'text/plain', // txt
].join(',');

export const dateKeysForFormatting: DateKey[] = [
  'expirationDate',
  'licenseExpirationDate',
];

export const TopAppBarTitle = {
  DATA_Q_CHALLENGE: 'Data Q Challenge',
};
