import { makeAutoObservable } from 'mobx';

import { API_LIMIT } from '../config';
import { ActivityListFilters, ActivityListResponseDTO } from '../types';
import { RootStore } from '.';

export class ActivityListStore {
  private _activityList: Array<ActivityListResponseDTO> = [];
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';
  private _filter: ActivityListFilters = {
    categories: [],
    dateSort: 'desc',
    lastSubmittedBy: '',
    limit: API_LIMIT,
    nextLink: this.NextLink,
    submittedBy: '',
    unitNumber: '',
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setActivityList(data: Array<ActivityListResponseDTO>) {
    this._activityList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setFilters(data: ActivityListFilters) {
    this._filter = data;
  }
  setActivityListApiReset() {
    this.setNextLink('');
    this.setHasMoreResults(true);
    this.setActivityList([]);
    this.setFilters({
      ...this.Filters,
      categories: [],
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: this.NextLink,
      submittedBy: '',
      unitNumber: '',
    });
  }
  get ShowLoader() {
    return this._showLoader;
  }

  get ActivityList() {
    return this._activityList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get Filters() {
    return this._filter;
  }
  async fetchActivityLists() {
    this.setNextLink('');
    const response =
      await this.rootStore.apiStore.activityListApiStore.getActivityListList(
        this.Filters,
      );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = results;
      this.setActivityList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchActivityListsBySearchBased() {
    const response =
      await this.rootStore.apiStore.activityListApiStore.getActivityListList(
        this.Filters,
      );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._activityList.concat(results);
      this.setActivityList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }
}
