import { makeAutoObservable } from 'mobx';

import { RootStore } from '.';

export class DashboardStore {
  private _todos: any = '';
  private _showLoader = false;
  private _dataLoader = false;
  private _data: any = '';
  private _inspectionData: {
    total: number;
    withViolations: number;
    withoutViolations: number;
    lastUpdatedDate: string;
  } | null = null;
  private _basicCategoriesData: any = null;
  private _trendingItemsData: any = null;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setTodos(data: any) {
    this._todos = data;
  }
  setData(data: any) {
    this._data = data;
  }
  setBasicCategoriesData(data: any) {
    this._basicCategoriesData = data;
  }
  setTrendingItems(data: any) {
    this._trendingItemsData = data;
  }
  setInspectionData(
    data: {
      total: number;
      withViolations: number;
      withoutViolations: number;
      lastUpdatedDate: string;
    } | null,
  ) {
    this._inspectionData = data;
  }
  setShowLoader(value: boolean) {
    this._showLoader = value;
  }
  setDataLoader(value: boolean) {
    this._dataLoader = value;
  }
  resetDashboardData() {
    this._inspectionData = null;
    this._data = null;
    this._todos = null;
    this._basicCategoriesData = null;
  }
  get ShowLoader() {
    return this._showLoader;
  }
  get DataLoader() {
    return this._dataLoader;
  }
  get Todos() {
    return this._todos;
  }
  get Data() {
    return this._data;
  }
  get InspectionData() {
    return this._inspectionData;
  }
  get BasicCategoriesData() {
    return this._basicCategoriesData;
  }
  get TrendingItems() {
    return this._trendingItemsData;
  }
  async fetchDashboardTodos() {
    const response =
      await this.rootStore.apiStore.dashboardApiStore.getTodosList();
    if (response.isOk()) {
      this.setTodos(response);
    }
    return response;
  }
  async fetchDashboardData() {
    const response =
      await this.rootStore.apiStore.dashboardApiStore.getDataList();
    if (response.isOk()) {
      this.setData(response);
    }
    return response;
  }
  async fetchDashboardInspectionCount() {
    const response =
      await this.rootStore.apiStore.dashboardApiStore.getInspectionDataList();
    if (response.isOk()) {
      this.setInspectionData(response.value);
    }
    return response;
  }

  async fetchDashboardBasicCategoriesCount() {
    const response =
      await this.rootStore.apiStore.dashboardApiStore.getBasicCategoriesDataList();
    if (response.isOk()) {
      this.setBasicCategoriesData(response.value);
    }
    return response;
  }

  async fetchTrendingItemsCount() {
    const response =
      await this.rootStore.apiStore.dashboardApiStore.getTrendingItemsDataList();
    if (response.isOk()) {
      this.setTrendingItems(response.value);
    }
    return response;
  }
}
