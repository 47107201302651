import styled from '@mui/system/styled';

export const Main = styled('main')({
  padding: 30,
  paddingTop: 0,
});

export const Div = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '400px',
  width: '100%',
});
