import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore } from '../../../hooks'; // UseFocus
import { SelectDatePicker } from '../../Forms/datePicker';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

const InspectionsSearchForm: React.FC<any> = (props) => {
  const { classes, searchPage, onClose } = props;

  // const [input1Ref, setInput1Focus] = UseFocus();
  const { inspectionsAndViolationsStore: store, authStore } = useCustomStore();
  const {
    control,
    handleSubmit,
    // formState: { errors },
    setValue,
    // reset,
    watch,
  } = useForm<{
    reportNumber: string;
    inspectionState: string;
    primaryDriverMatchStatus:
      | 'All'
      | 'No match'
      | 'Auto-match'
      | 'Confirmed'
      | null;
    secondaryDriverMatchStatus:
      | 'All'
      | 'No match'
      | 'Auto-match'
      | 'Confirmed'
      | null;
    truckMatchStatus: 'All' | 'No match' | 'Auto-match' | 'Confirmed' | null;
    trailerMatchStatus: 'All' | 'No match' | 'Auto-match' | 'Confirmed' | null;
    primaryDriverName: string;
    matchedPrimaryDriverName: string;
    secondaryDriverName: string;
    matchedSecondaryDriverName: string;
    powerUnitVin: string;
    matchedPowerUnitVin: string;
    trailerVin: string;
    matchedTrailerVin: string;
    inspectionDateStart: string;
    inspectionDateEnd: string;
  }>({
    defaultValues: {
      inspectionDateEnd: store.SearchParams.inspectionDateEnd,
      inspectionDateStart: store.SearchParams.inspectionDateStart,
      inspectionState: store.SearchParams.inspectionState,
      matchedPowerUnitVin: store.SearchParams.matchedPowerUnitVin,
      matchedPrimaryDriverName: store.SearchParams.matchedPrimaryDriverName,
      matchedSecondaryDriverName: store.SearchParams.matchedSecondaryDriverName,
      matchedTrailerVin: store.SearchParams.matchedTrailerVin,
      powerUnitVin: store.SearchParams.powerUnitVin,
      primaryDriverMatchStatus:
        store.SearchParams.primaryDriverMatchStatus === ''
          ? 'All'
          : store.SearchParams.primaryDriverMatchStatus,
      primaryDriverName: store.SearchParams.primaryDriverName,
      reportNumber: store.SearchParams.reportNumber,
      secondaryDriverMatchStatus:
        store.SearchParams.secondaryDriverMatchStatus === ''
          ? 'All'
          : store.SearchParams.secondaryDriverMatchStatus,
      secondaryDriverName: store.SearchParams.secondaryDriverName,
      trailerMatchStatus:
        store.SearchParams.trailerMatchStatus === ''
          ? 'All'
          : store.SearchParams.trailerMatchStatus,
      trailerVin: store.SearchParams.trailerVin,
      truckMatchStatus:
        store.SearchParams.truckMatchStatus === ''
          ? 'All'
          : store.SearchParams.truckMatchStatus,
    },
  });

  const matchStatus = ['All', 'No match', 'Auto-match', 'Confirmed'];

  // const formValue = useMemo(() => getValues(), [getValues]);

  const initSearch = useCallback(
    (data) => {
      (async () => {
        store.setShowLoader(true);
        store.resetParams();
        store.resetSearchParams();
        store.setSearchParams(data);
        store.setShowLoader(true);
        store.setResetManageSelectAll(true);
        await store.getList();
        store.setShowLoader(false);
        onClose();
      })();
    },
    [store, onClose],
  );

  const resetFilter = useCallback(async () => {
    store.resetParams();
    store.resetSearchParams();
    store.resetSortParams();
    // resetting form
    setValue('reportNumber', '');
    setValue('inspectionDateEnd', '');
    setValue('inspectionDateStart', '');
    setValue('inspectionState', '');
    setValue('matchedPowerUnitVin', '');
    setValue('matchedPrimaryDriverName', '');
    setValue('matchedSecondaryDriverName', '');
    setValue('matchedTrailerVin', '');
    setValue('powerUnitVin', '');
    setValue('primaryDriverMatchStatus', 'All');
    setValue('primaryDriverName', '');
    setValue('secondaryDriverMatchStatus', 'All');
    setValue('secondaryDriverName', '');
    setValue('trailerMatchStatus', 'All');
    setValue('trailerVin', '');
    setValue('truckMatchStatus', 'All');
    store.setShowLoader(true);
    store.setResetManageSelectAll(true);
    await store.getList();
    store.setShowLoader(false);
    // onClose();
  }, [store, setValue]);
  return (
    <form onSubmit={handleSubmit(initSearch)} id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search Report Number
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="reportNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search Inspection State
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="inspectionState"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={authStore.statesListOption.map((state) => {
                        return {
                          label: state.fullName,
                          optionClassName: classes.optionsClassName,
                          value: state.id,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Primary Driver Match Status
        </Typography>
        <Grid className={classes.borderBottom} style={{ marginTop: '15px' }}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue="All"
                name="primaryDriverMatchStatus"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={matchStatus.map((status: string) => {
                        return {
                          label: status,
                          optionClassName: classes.optionsClassName,
                          value: status,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Secondary Driver Match Status
        </Typography>
        <Grid className={classes.borderBottom} style={{ marginTop: '15px' }}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue="All"
                name="secondaryDriverMatchStatus"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={matchStatus.map((status: string) => {
                        return {
                          label: status,
                          optionClassName: classes.optionsClassName,
                          value: status,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Truck Match Status
        </Typography>
        <Grid className={classes.borderBottom} style={{ marginTop: '15px' }}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue="All"
                name="truckMatchStatus"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={matchStatus.map((status: string) => {
                        return {
                          label: status,
                          optionClassName: classes.optionsClassName,
                          value: status,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Trailer Match Status
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue="All"
                name="trailerMatchStatus"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.driverType}
                      value={value}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={matchStatus.map((status: string) => {
                        return {
                          label: status,
                          optionClassName: classes.optionsClassName,
                          value: status,
                        };
                      })}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Primary Driver Name
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="primaryDriverName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search by Matched Primary Driver Name
        </Typography>
        <Grid className={classes.borderBottom} style={{ marginTop: '15px' }}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="matchedPrimaryDriverName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Secondary Driver Name
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="secondaryDriverName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search by Matched Secondary Driver Name
        </Typography>
        <Grid className={classes.borderBottom} style={{ marginTop: '15px' }}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="matchedSecondaryDriverName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Power Unit VIN
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="powerUnitVin"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search by Matched Power Unit VIN
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="matchedPowerUnitVin"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search By Trailer VIN
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="trailerVin"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
          }}>
          Search by Matched Trailer VIN
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="matchedTrailerVin"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <Typography component={'h3'} className={classes.filterLabel}>
        Inspection Date
      </Typography>
      <div className={clsx(classes.borderBottom, classes.dateFilter)}>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            From:
          </Typography>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10} className="date-picker">
              <Controller
                control={control}
                defaultValue=""
                name="inspectionDateStart"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      value={value}
                      isRefreshValue
                      inputRefObj={ref}
                      name={name}
                      onChangeDate={(d) => {
                        const formattedValue = moment(d).format('MM/DD/YYYY');
                        onChange(formattedValue);
                      }}
                      className={classes.selectClassName}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            To:
          </Typography>

          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10} className="date-picker">
              <Controller
                control={control}
                defaultValue=""
                name="inspectionDateEnd"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      value={value}
                      isRefreshValue
                      inputRefObj={ref}
                      name={name}
                      onChangeDate={(d) => {
                        const formattedValue = moment(d).format('MM/DD/YYYY');
                        onChange(formattedValue);
                      }}
                      className={classes.selectClassName}
                      minDate={
                        watch('inspectionDateStart')
                          ? new Date(watch('inspectionDateStart'))
                          : undefined
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
      </div>

      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              // disabled
              type="button"
              onClick={resetFilter}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            // disabled={!formValue.reportNumber && !formValue.inspectionState}
            className={classes.filterBtn}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(InspectionsSearchForm);
