import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import Footer from '../../../../components/Footer';
import { useCustomStore } from '../../../../hooks';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';

const Error = styled('p')(() => ({
  color: 'red',
  fontSize: 12,
}));

const Label = styled(Typography)(() => ({
  fontSize: 12,
  margin: '5px 0',
}));

const commonStyles = {
  border: 1,
  height: '4rem',
  m: 1,
  paddingleft: '50px',
  width: '100%',
};

const Overlay = styled('div')(() => ({
  backgroundColor: 'white',
  height: '100%',
  opacity: 0.4,
  position: 'absolute',
  top: 0,
  width: '95%',
}));

const useStyles = makeStyles((theme) => ({
  radiobtn: {
    '& PrivateSwitchBase-root Mui-checked': {
      color: '#DEC330',
    },
  },
  root: {
    '& .MuiStepIcon-root.Mui-active': {
      color: '#DEC330',
    },
    '& .MuiStepIcon-root.Mui-completed': {
      color: '#DEC330',
    },
  },
}));

const DialogCABLoginGov: React.FC<{
  onSubmit: (val: { username: string; password: string }) => unknown;
  handleClose: () => unknown;
  getCredentials: (type: string) => void;
}> = ({ onSubmit, handleClose, getCredentials }) => {
  const [valueLoginGov, setValueLoginGov] = useState<FMCSAPutPayload>({
    password: '',
    username: '',
  });

  const [authenticationPayLoad, setAuthenticationPayLoad] =
    useState<FmcsaAuthentication>({
      authType: '',
      mfaCode: '',
      token: '',
    });

  const [credentialLoginResponse, setCredentialLoginResponse] =
    useState<CredentialLoginResponse>();

  const [error, setError] = useState<FMCSAPutPayload>({
    password: '',
    username: '',
  });
  const [errorCodeMfa, setErrorCodeMfa] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [optionAuthenticationValue, setOptionAuthenticationValue] =
    useState('');
  const [helperTextOptionAuthentication, setHelperTextOptionAuthentication] =
    useState('Select your authentication method');
  const [activeStep, setActiveStep] = useState(0);
  const handleStep = () => {
    if (activeStep === 0) handleSubmitLogin(valueLoginGov);
    else if (activeStep === 1) handleSubmitAuthentication();
    else if (activeStep === 2) handleSubmitCode(authenticationPayLoad);
  };

  useEffect(() => {
    setValueLoginGov({ password: '', username: '' });
    return () => {
      setValueLoginGov({ password: '', username: '' });
      setError({ password: '', username: '' });
    };
  }, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueauthType = (event.target as HTMLInputElement).value;
    const authType =
      credentialLoginResponse?.mfa.options?.filter(
        (options) => options.value == valueauthType,
      )[0].type || 'sms';
    setAuthenticationPayLoad({
      ...authenticationPayLoad,
      authType,
    });
    setOptionAuthenticationValue((event.target as HTMLInputElement).value);
    // setAuthenticationPayLoad({
    //   ...authenticationPayLoad,
    //   authType: (event.target as HTMLInputElement).value,
    // });
    setHelperTextOptionAuthentication(' ');
  };

  const {
    userStore: {
      updateFMCSALoginGov,
      postFMCSAAuthenticationConfirm,
      updateFMCSA,
    },
  } = useCustomStore();

  const handleSubmitLogin = async (value: {
    password: string;
    username: string;
  }) => {
    if (!value.username) {
      setError({ ...error, username: 'Required' });
      return;
    }
    if (!value.password) {
      setError({ ...error, password: 'Required' });
      return;
    }
    setIsLoading(true);
    const [res, err] = await updateFMCSALoginGov(value);
    setIsLoading(false);
    if (err || res?.errorMessage) {
      enqueueSnackbar(
        res?.errorMessage ||
          res?.message ||
          'Unable to update FMCSA. Try again',
        SnackBarConfig('e'),
      );
    } else {
      if (res && res.status === 'Invalid') {
        enqueueSnackbar(
          res?.message || 'Unable to update FMCSA Pin. Try again',
          SnackBarConfig('e'),
        );
      } else {
        enqueueSnackbar(
          res?.message || 'FMCSA Login Gov successfully',
          SnackBarConfig('s'),
        );
        setCredentialLoginResponse(res as CredentialLoginResponse);
        setAuthenticationPayLoad({
          ...authenticationPayLoad,
          token: res!.token,
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleSubmitAuthentication = () => {
    if (!optionAuthenticationValue) {
      setHelperTextOptionAuthentication('Select your authentication method');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleSubmitCode = async (value: {
    mfaCode: string;
    token: string;
    authType: string;
  }) => {
    if (!value.mfaCode) {
      setError({ ...error, username: 'Required' });
      return;
    }
    setIsLoading(true);
    const [res, err] = await postFMCSAAuthenticationConfirm(value);
    if (err || res?.errorMessage) {
      enqueueSnackbar(
        res?.errorMessage ||
          res?.message ||
          'Unable to update FMCSA. Try again',
        SnackBarConfig('e'),
      );
    } else {
      if (res && res.status === 'Invalid') {
        enqueueSnackbar(
          res?.message || 'Unable to update FMCSA Pin. Try again',
          SnackBarConfig('e'),
        );
      } else {
        enqueueSnackbar(
          res?.message || 'FMCSA Authentication Confirmation successfully',
          SnackBarConfig('s'),
        );
        const [responseUpdate, errorUpdate] = await updateFMCSA(valueLoginGov);
        if (errorUpdate || responseUpdate?.status === 'Valid') {
          enqueueSnackbar(
            res?.errorMessage ||
              res?.message ||
              'Unable to update FMCSA. Try again',
            SnackBarConfig('e'),
          );
        }
        setCredentialLoginResponse(res as CredentialLoginResponse);
        getCredentials('FMCSA');
        setIsLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleClose();
      }
    }
  };

  return (
    <>
      <DialogContent className="dot-pin-content">
        <h4>FMCSA Portal Credentials</h4>
        <br />
        <br />
        <Box sx={{ width: '100%' }}>
          <Stepper
            className={classes.root}
            alternativeLabel
            activeStep={activeStep}>
            <Step id="SignIn" key="signIn">
              <StepLabel>Sign In</StepLabel>
            </Step>
            <Step id="athenticationMethod" key="authenticationMethod">
              <StepLabel>Authentication Method</StepLabel>
            </Step>
            <Step id="dotPin" key="dotPin">
              <StepLabel>Code Confirmation</StepLabel>
            </Step>
          </Stepper>
          <div>
            {activeStep === 0 ? (
              <Box sx={{ width: '100%' }}>
                <form>
                  <FormControl error={false} fullWidth>
                    <Label paragraph>Email</Label>
                    <TextField
                      key="emailText"
                      value={valueLoginGov?.username}
                      onChange={(e) => {
                        const username = e.currentTarget.value;
                        setValueLoginGov({ ...valueLoginGov, username });
                        if (username) setError({ ...error, username: '' });
                        else setError({ ...error, username: 'Required' });
                      }}
                      type="text"
                      variant="standard"
                    />
                    {error.username && <Error>{error.username}</Error>}
                  </FormControl>
                  <FormControl error={false} fullWidth>
                    <Label paragraph>Password</Label>
                    <TextField
                      key="passwordText"
                      value={valueLoginGov?.password}
                      onChange={(e) => {
                        const password = e.currentTarget.value;
                        setValueLoginGov({ ...valueLoginGov, password });
                        if (password) setError({ ...error, password: '' });
                        else setError({ ...error, password: 'Required' });
                      }}
                      type="password"
                      variant="standard"
                    />
                    {error.password && <Error>{error.password}</Error>}
                  </FormControl>
                </form>
              </Box>
            ) : (
              <Box />
            )}
            {activeStep === 1 ? (
              <form>
                <FormControl sx={{ m: 3 }} variant="standard">
                  <FormHelperText>
                    {helperTextOptionAuthentication}
                  </FormHelperText>
                  <RadioGroup
                    aria-labelledby=""
                    name="authenticationMethod"
                    value={optionAuthenticationValue}
                    onChange={handleRadioChange}>
                    {credentialLoginResponse?.mfa.options?.map((option) => (
                      <Box
                        key={option.value}
                        sx={{
                          ...commonStyles,
                          borderRadius: '16px',
                          paddingLeft: '10px',
                        }}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: '#DEC330',
                                },
                                color: '#DEC330',
                              }}
                              size="small"
                              value={option.value}
                              onSelect={(e) => {
                                const valueauthType = e.currentTarget.value;
                                const authType =
                                  credentialLoginResponse?.mfa.options?.filter(
                                    (options) => options.value == valueauthType,
                                  )[0].type;
                                setAuthenticationPayLoad({
                                  ...authenticationPayLoad,
                                  authType,
                                });
                              }}
                            />
                          }
                          label={option.header}
                        />
                        <br />
                        <span style={{ fontSize: 9, paddingLeft: '10px' }}>
                          {option.message}
                        </span>
                        <span style={{ fontSize: 9, paddingLeft: '10px' }}>
                          {option.phone}
                        </span>
                      </Box>
                    ))}
                  </RadioGroup>
                </FormControl>
              </form>
            ) : (
              <Box />
            )}
            {activeStep === 2 ? (
              <form>
                <FormControl error={false} fullWidth>
                  <Label paragraph>Code</Label>
                  <TextField
                    value={authenticationPayLoad?.mfaCode}
                    onChange={(e) => {
                      const mfaCode = e.currentTarget.value;
                      setAuthenticationPayLoad({
                        ...authenticationPayLoad,
                        mfaCode,
                      });
                      if (mfaCode) setErrorCodeMfa('');
                      else setErrorCodeMfa('Required');
                    }}
                    variant="standard"
                  />
                  {errorCodeMfa && <Error>{errorCodeMfa}</Error>}
                </FormControl>
              </form>
            ) : (
              <Box />
            )}
          </div>
        </Box>
        {isLoading && (
          <Overlay className="flex-row-center">
            <CircularProgress size={30} sx={{ color: '#DEC330' }} />
          </Overlay>
        )}
      </DialogContent>
      <DialogActions>
        <Footer
          onSubmit={() => {
            handleStep();
          }}
          onCancel={() => {
            handleClose();
            getCredentials('FMCSA');
          }}
          isNext={activeStep < 2 ? true : false}
        />
      </DialogActions>
    </>
  );
};

export default DialogCABLoginGov;
