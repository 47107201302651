import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

type GetListResponse = {
  count: number;
  nextLink: string;
  response: Accident[];
};

type MoreDetailsResponse = {
  accidentId?: string;
  roadwayTraffic?: string;
  roadAccessControl?: string;
  roadSurfaceCondition?: string;
  weatherCondition?: string;
  lightCondition?: string;
  primaryDriver?: {
    fullName: string;
    licenseState: string;
    dob: string;
    age: number;
  };
  matchedPrimaryDriver?: {
    fullName: string;
    dob: string;
    simplexId: string;
  };
  powerUnit?: {
    vin: string;
    licenseNumber: string;
    licenseState: string;
  };
  matchedTruckInfo?: {
    vinNumber: string;
    unitNumber: string;
    simplexUnitNumber: string;
  };
};

type GetParams = {
  type?: 'all' | 'manage-all' | 'manage-completed';
  limit?: number;
  nextLink?: string;
  sortDirection?: string;
  sortBy?: string;
  sortChanged?: boolean;
  primaryDriverMatchStatus?: string;
  truckMatchStatus?: '' | 'No match' | 'Auto-match' | 'Confirmed';
  driverName?: string;
  matchedDriverId?: string;
  vin?: string;
  accidentNumber?: string;
  accidentState?: string;
  accidentDateStart?: string;
  accidentDateEnd?: string;
};

type getEquipmentListParams = {
  type?: 'power unit' | 'trailer';
  vin?: string;
  nextLink?: string | number;
  limit?: number;
};

type getDriverListParams = {
  name?: string;
  nextLink?: string | number;
  limit?: number;
};

type submitDataQParams = {
  challengeReason: string;
  challengeType: string;
  reasonType: string;
  supportDocuments?: File[];
  explanation?: string;
  violationCodes?: string;
  source: string;
};
export class AccidentsApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getData = async (params: GetParams) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Accidents;

    const response = await this.rootStore.apiStore.call<GetListResponse>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (response.isOk()) {
      return {
        count: response.value.count,
        list: response.value.response,
        nextLink: response.value.nextLink,
      };
    }
    return {
      count: 0,
      list: [],
      nextLink: '',
    };
  };

  export = async (params: GetParams) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Accidents;

    return await this.rootStore.apiStore.call<GetListResponse>({
      method: Method,
      params,
      responseType: 'blob',
      url: Endpoint + '/export',
    });
  };

  getMoreDetails = async (id: string): Promise<MoreDetailsResponse> => {
    const {
      GetList: { Endpoint = '', Method },
    } = ApiRoutes.Accidents;

    const url = `${Endpoint}/${id}`;
    const response = await this.rootStore.apiStore.call<MoreDetailsResponse>({
      method: Method,
      url,
    });

    if (response.isOk()) {
      return response.value;
    }
    return {};
  };

  matchDriversForAccidents = async (data: any): Promise<any> => {
    const {
      Match: { Endpoint, Method },
    } = ApiRoutes.Accidents;
    const url = `${Endpoint}`;
    const response = await this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url,
    });
    return response;
  };

  getAllDrivers = async (params: getDriverListParams) => {
    const {
      GetAllDrivers: { Endpoint, Method },
    } = ApiRoutes.Accidents;

    const response = await this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (response.isOk()) {
      return {
        list: response.value.response,
        nextLink: response.value.nextLink,
      };
    }
    return {
      count: 0,
      list: [],
      nextLink: '',
    };
  };

  getAllEquipments = async (params: getEquipmentListParams) => {
    const {
      GetAllEquipments: { Endpoint, Method },
    } = ApiRoutes.Accidents;

    const response = await this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (response.isOk()) {
      return {
        count: response.value.count || 0,
        list: response.value.response,
        nextLink: response.value.nextLink || '',
      };
    }
    return {
      count: 0,
      list: [],
      nextLink: '',
    };
  };

  submitDataQChallenge = async (payload: submitDataQParams): Promise<any> => {
    const {
      submitDataQChallenge: { Endpoint, Method },
    } = ApiRoutes.Accidents;
    const url = `${Endpoint as string}`;
    const response = await this.rootStore.apiStore.call<any>({
      data: payload,
      method: Method,
      url,
    });
    return response;
  };
}
