import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

import SuccessImage from '../../assets/images/green-checkmark.svg';

const Text = styled(Typography)(() => ({
  color: 'red',
  textAlign: 'center',
  width: 450,
}));

type SuccessImageTypes = {
  postReqMessage?: string;
  title: string;
  handleNotification: () => any;
};

const SuccessNotification: React.FC<SuccessImageTypes> = ({
  title = '',
  handleNotification,
  postReqMessage,
}: SuccessImageTypes) => {
  return (
    <div className="notification">
      <p className="notification-title">{title}</p>
      <div className="success-image">
        <img src={SuccessImage} alt="success" />
      </div>
      <p className="success-text">Submission Successful</p>
      <p
        onClick={() => handleNotification()}
        className="create-new-record-text">
        {title.toLowerCase() === 'ifta'
          ? `create another ${title} request`
          : title.includes('Data Q Challenge')
          ? 'CREATE NEW DATA Q CHALLENGE SUBMISSION'
          : 'create '.concat(title)}
      </p>
      {postReqMessage && <Text>{postReqMessage}</Text>}
    </div>
  );
};

export default SuccessNotification;
