import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  actFilter: {
    padding: '1.5rem 0 1rem 0',
  },
  activeBox: {
    alignItems: 'center',
    backgroundColor: '#E8932E !important',
    borderRadius: '7px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.93px',
    opacity: '1 !important',
    width: '85px',
  },
  asend: {
    alignItems: 'center',
    color: '#979598 !important',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '12px !important ',
    paddingRight: '1rem',
  },
  borderBottom: {
    borderBottom: '1px solid #403849',
    marginBottom: '1rem !important',
  },
  cardHeading: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: '12px',
  },
  cardMainHeading: {
    alignSelf: 'center',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '18px !important',
    padding: '14px',
  },
  close: {
    color: '#D1D1D1 !important',
    fontSize: '14px !important',
    marginLeft: '0.3rem !important',
  },
  dayBox: {
    alignItems: 'center',
    backgroundColor: '#403849',
    borderRadius: '7px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.93px',
    opacity: '0.43',
    width: '85px',
  },
  dflexA: {
    alignItems: 'center',
    display: 'flex',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  fIcon: {
    alignItems: 'center',
    border: '2px solid #E7E7E7',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
  },
  filteIcon: {
    width: '18px',
  },
  filterBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#fff !important',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  filterItem: {
    alignItems: 'center',
    backgroundColor: '#E8E8E8',
    borderRadius: '24px',
    color: '#979598 !important',
    cursor: 'pointer !important',
    display: 'flex',
    fontSize: '14px !important',
    height: '34px',
    justifyContent: 'space-between',
    margin: '0 0.5rem 0.5rem 0!important',
    minWidth: '72px',
    padding: '0 0.7rem',
  },
  filterList: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 1rem',
    width: '100%',
  },
  filterText: {
    color: '#979598 !important',
    cursor: 'pointer !important',
    fontSize: '14px !important',
  },
  fimg: {
    width: '28px',
  },
  hIcon: {
    cursor: 'pointer',
    paddingRight: '0.5rem',
  },
  inputF: {
    '&::placeholder': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      opacity: '0.43',
    },
    '&:focusVisible': {
      outline: 'unset !important',
    },
    background: 'unset !important',
    border: 'unset !important',
    color: '#ffff !important',
    width: '100%',
  },
  loader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: 'calc(100vh - 200px)',
  },
  padcss: {
    paddingLeft: '0px !important',
    paddingTop: '0px !important',
  },
  resetF: {
    alignItems: 'center',
    color: '#777777 !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Bold',
    fontSize: '12px !important',
    justifyContent: 'center !important',
    letterSpacing: '0.8px',
    textDecoration: 'underline solid #777777',
  },
  searcBox: {
    alignItems: 'center',
    backgroundColor: '#403849',
    borderRadius: '7px',
    display: 'flex',
    height: '43px',
    marginBottom: '1rem !important',
  },
  tabPanel: {
    backgroundColor: '#fff !important',
    maxHeight: 'calc(100vh - 205px)',
    minHeight: 'calc(100vh - 205px)',
    overflowY: 'auto',
    padding: '2rem 4rem 2rem 4rem !important',
  },
});
export default useStyles;
