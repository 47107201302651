import { Paper, TableCell } from '@mui/material';
import styled from '@mui/system/styled';
import { observer } from 'mobx-react-lite';
import React from 'react';

const CustomPaper = styled(Paper)(() => ({
  backgroundColor: '#E5E9EF !important',
  padding: '10px',
}));

const PaperWrap = styled('div')(() => ({
  display: 'flex',
}));

const Text = styled('p')(() => ({
  color: '#241A2E',
  fontSize: 14,
  whiteSpace: 'break-spaces',
}));

const Violations: React.FC<any> = ({ cells = [], data }) => {
  return (
    <CustomPaper elevation={0} style={{ paddingLeft: 0, paddingRight: 0 }}>
      <PaperWrap>
        {cells.map((item: any, index_: number) => (
          <TableCell
            width={item.spacing}
            key={index_}
            style={{ width: `${item.spacing as string}px` as string }}>
            {item.highlight ? (
              <h4>
                {item.name && (data[item.name] || data[item.name] === 0)
                  ? data[item.name]
                  : '-'}
              </h4>
            ) : (
              <Text>
                {item.name && (data[item.name] || data[item.name] === 0)
                  ? data[item.name]
                  : '-'}
              </Text>
            )}
          </TableCell>
        ))}
      </PaperWrap>
    </CustomPaper>
  );
};

export default observer(Violations);
