import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import SimplexDatepicker from '../../../../../../components/SimplexDatepicker';

type Form = {
  backgroundCheckOrderedDate: string;
  backgroundCheckResultsDate: string;
};

type Props = {
  onChange: (date: Form) => void;
  defaultValue?: Form;
};

export const BgCheckForm: React.FC<Props> = ({
  onChange,
  defaultValue = {
    backgroundCheckOrderedDate: '',
    backgroundCheckResultsDate: '',
  },
}) => {
  const [value, setValue] = useState<Form>(defaultValue);
  return (
    <>
      <br />
      <Typography paragraph>Date ordered</Typography>
      <SimplexDatepicker
        value={value.backgroundCheckOrderedDate}
        maxDate={new Date()}
        onChange={(e: any) => {
          if (e) {
            const date = new Date(e);
            const dateText = date.toLocaleDateString('en-US');
            const tempValue = {
              ...value,
              backgroundCheckOrderedDate: dateText,
            };
            onChange(tempValue);
            setValue(tempValue);
          }
        }}
      />
      <br />
      <br />
      <Typography paragraph>Date results received</Typography>
      <SimplexDatepicker
        value={value.backgroundCheckResultsDate}
        maxDate={new Date()}
        onChange={(e: any) => {
          if (e) {
            const date = new Date(e);
            const dateText = date.toLocaleDateString('en-US');
            const tempValue = {
              ...value,
              backgroundCheckResultsDate: dateText,
            };
            onChange(tempValue);
            setValue(tempValue);
          }
        }}
      />
    </>
  );
};
