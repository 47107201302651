import { makeAutoObservable } from 'mobx';

import { Config } from '../config';
import { ApiError, UpdateUserInput } from '../types';
import { formatPhoneNumber } from '../utils/helper';
import { RootStore } from '.';

export class UserStore {
  private _userData: any = {};
  private _showLoader = false;
  private _credentials: Credentials = {};

  private _showDOTForm = false;
  private _showFMCSAForm = false;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setUserData(data: any) {
    if (data && data.phoneNumber) {
      data.phoneNumber = formatPhoneNumber(data.phoneNumber);
    }
    this._userData = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setShowDOTForm = (shouldShow: boolean) => {
    this._showDOTForm = shouldShow;
  };

  setShowFMCSAForm = (shouldShow: boolean) => {
    this._showFMCSAForm = shouldShow;
  };

  get ShowLoader() {
    return this._showLoader;
  }

  get UserData() {
    return this._userData;
  }

  get DOTPin() {
    return this._credentials.dotPin;
  }

  get FMCSA() {
    return this._credentials.fmcsa;
  }

  get ShowDOTForm() {
    return this._showDOTForm;
  }
  get ShowFMCSAForm() {
    return this._showFMCSAForm;
  }

  isTestAccount() {
    return Config.APP_ENV !== 'production' ||
      [
        '9bc710c8-ccf3-eb11-94ef-000d3a18f5a5', // TEST LLC
        '0db52628-3199-eb11-a9f6-005056a93d01', // TEST01
        '801c19db-3099-eb11-a9f6-005056a93d01', // VELOX TRANSPORT SOLUTIONS LLC
      ].includes(this._userData.companyId)
      ? true
      : false;
  }

  async fetchUsersProfilelInfo() {
    const response =
      await this.rootStore.apiStore.userApi.getUsersProfilelInfo();
    if (response.isOk()) {
      const { value } = response;
      this.setUserData(value);
    }
    return response;
  }

  async updateProfileInfo(userData: UpdateUserInput) {
    const response = await this.rootStore.apiStore.userApi.updateProfileInfo(
      userData,
    );
    if (response.isOk()) {
      const { value } = response;
      this.setUserData(value);
    }
    return response;
  }

  updateMedical = async (data: any) =>
    await this.rootStore.apiStore.userApi.updateMedicalRequest(data);

  _getDOTpin = async () => {
    const res = await this.rootStore.apiStore.userApi.getDOTpin();
    if (res.isOk()) {
      this._credentials.dotPin = res.value.response;
    }
  };

  _getFMCSA = async () => {
    const res = await this.rootStore.apiStore.userApi.getFMCSA();
    if (res.isOk()) {
      this._credentials.fmcsa = res.value.response;
    }
  };

  updateDOTpin = async (
    data: DotPinPutPayload,
  ): Promise<
    [(DOTPin & { errorMessage?: string }) | undefined, ApiError | undefined]
  > => {
    const res = await this.rootStore.apiStore.userApi.updateDOTpin(data);
    if (res.isOk()) {
      return [res.value.response, undefined];
    } else {
      return [undefined, res.error];
    }
  };

  updateFMCSA = async (
    data: FMCSAPutPayload,
  ): Promise<
    [(FMCSA & { errorMessage?: string }) | undefined, ApiError | undefined]
  > => {
    const res = await this.rootStore.apiStore.userApi.updateFMCSA(data);
    if (res.isOk()) {
      return [res.value.response, undefined];
    } else {
      return [undefined, res.error];
    }
  };

  updateFMCSALoginGov = async (
    data: FMCSAPutPayload,
  ): Promise<
    [
      (CredentialLoginResponse & { errorMessage?: string }) | undefined,
      ApiError | undefined,
    ]
  > => {
    const res = await this.rootStore.apiStore.userApi.updateFMCSALoginGov(data);
    if (res.isOk()) {
      return [res.value.response, undefined];
    } else {
      return [undefined, res.error];
    }
  };

  postFMCSAAuthenticationConfirm = async (
    data: FmcsaAuthentication,
  ): Promise<
    [
      (FmcsaAuthenticationResponse & { errorMessage?: string }) | undefined,
      ApiError | undefined,
    ]
  > => {
    const res =
      await this.rootStore.apiStore.userApi.postFMCSAAuthenticationConfirm(
        data,
      );
    if (res.isOk()) {
      return [res.value.response, undefined];
    } else {
      return [undefined, res.error];
    }
  };

  getCredentials = (credentialType = '') => {
    if (!credentialType) {
      this._getDOTpin();
      this._getFMCSA();
    }
    if (credentialType === 'DOT_PIN') this._getDOTpin();
    if (credentialType === 'FMCSA') this._getFMCSA();
  };
}
