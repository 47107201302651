import { Grid } from '@mui/material';
import React from 'react';

import DriverListItem from '../DriverInfo/DriverListItem';

const OwnerInfo: React.FC<{
  UserData: any;
}> = ({ UserData }) => {
  const userAddress: { [key: string]: string } = {
    address: UserData.address || '',
    state: UserData.state || '',
    zip: UserData.zip || '',
  };
  return (
    <>
      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem label="Name" value={UserData.name} />
        <DriverListItem label="Email" value={UserData.email} />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem label="Phone Number" value={UserData.phoneNumber} />
        <DriverListItem label="DOB" value={UserData.dob} />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Address"
          value={
            UserData.address &&
            `${userAddress.address}, ${userAddress.state}, ${userAddress.zip}`
          }
        />
        <DriverListItem label="Contact Type" value={UserData.contactType} />
      </Grid>
    </>
  );
};

export default OwnerInfo;
