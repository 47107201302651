import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { InputText } from '../../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../../components/Forms/selectInput';
import { UseFocus, useMountEffect } from '../../../../../../hooks';
import { RequestType } from '../../../../../../types';
import TaxPermitsEquipmentCard from '../../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
import TaxPermitsEquipmentHeader from '../../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';

type statesFormTypes = {
  classes: any;
  control: any;
  errors: any;
  formType: any;
  onSubmit: any;
  handleSubmit: any;
  equipment: Array<any>;
  onselect: (src: any) => void;
  remove: (src: any) => void;
  btnDisable: any;
  setValue: any;
  KentuckyStateInfo: { dotNumber: string; ucrExpDate: string } | null;
  updateMethod: any;
  getValues: any;
};
const StatePermitsKentuckyForm: React.FC<statesFormTypes> = (
  props: statesFormTypes,
) => {
  const {
    btnDisable,
    classes,
    control,
    errors,
    formType,
    handleSubmit,
    onSubmit,
    equipment,
    onselect,
    remove,
    setValue,
    KentuckyStateInfo,
    updateMethod,
    // getValues,
  } = props;
  // const [showHasUcrFiled, setShowHasUcrFiled] = useState<boolean>(false);

  // const checkingHasFilled = useCallback(() => {
  //   setShowHasUcrFiled(
  //     moment().isAfter(moment(getValues('ucrExpDate')).add(1, 'day')),
  //   );
  // }, [getValues]);
  useEffect(() => {
    if (setValue) {
      setValue(
        'dotNumber',
        KentuckyStateInfo && KentuckyStateInfo.dotNumber
          ? KentuckyStateInfo.dotNumber
          : '',
      );
      // setValue(
      //   'ucrExpDate',
      //   KentuckyStateInfo && KentuckyStateInfo.ucrExpDate
      //     ? KentuckyStateInfo.ucrExpDate
      //     : '',
      // );
    }
  }, [setValue, KentuckyStateInfo, updateMethod]);
  // useEffect(() => {
  //   checkingHasFilled();
  // }, [checkingHasFilled]);

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  return (
    <form
      style={{
        width: '100%',
      }}
      id={formType}
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
        <Grid item md={12} sm={12} xs={12}>
          <Box className={classes.labels}>New or Additional *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="requestType"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  inputRefObj={input1Ref}
                  size={'small'}
                  errors={errors.requestType?.message || ''}
                  onChangeText={onChange}
                  isEditable={btnDisable}
                  selectClassName={classes.selectClassName}
                  placeHolder={'Select'}
                  sortByOptionDisable={true}
                  optionsList={
                    [
                      { label: 'New', value: RequestType.New },
                      { label: 'Additional', value: RequestType.Additional },
                    ]
                    // <>
                    //   <option
                    //     value={RequestType.Empty}
                    //     disabled
                    //     className={classes.optionsClassName}>
                    //     Select
                    //   </option>
                    //   <option
                    //     value={RequestType.New}
                    //     className={classes.optionsClassName}>
                    //     New
                    //   </option>
                    //   {/* <option
                    //     value={RequestType.Renewal}
                    //     className={classes.optionsClassName}>
                    //     Renewal
                    //   </option> */}
                    //   <option
                    //     value={RequestType.Additional}
                    //     className={classes.optionsClassName}>
                    //     Additional
                    //   </option>
                    // </>
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>DOT</Box>
          <Controller
            control={control}
            defaultValue={
              KentuckyStateInfo && KentuckyStateInfo.dotNumber
                ? KentuckyStateInfo.dotNumber
                : ''
            }
            name="dotNumber"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.dotNumber?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={onChange}
                  isEditable={true}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        {/* <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>UCR Expiration Date</Box>
          <Controller
            control={control}
            defaultValue={
              KentuckyStateInfo && KentuckyStateInfo.ucrExpDate
                ? KentuckyStateInfo.ucrExpDate
                : null
            }
            name="ucrExpDate"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectDatePicker
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.ucrExpDate?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeDate={(e) => {
                    onChange(e), checkingHasFilled();
                  }}
                  isEditable={
                    (KentuckyStateInfo && KentuckyStateInfo.ucrExpDate
                      ? true
                      : false) || btnDisable
                  }
                />
              );
            }}
          />
        </Grid>
        {showHasUcrFiled ? (
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>
              Have you filed the UCR for {moment().format('YYYY')}? *
            </Box>
            <Controller
              control={control}
              defaultValue=""
              name="hasUcrFiled"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    inputRefObj={ref}
                    size={'small'}
                    errors={errors.hasUcrFiled?.message || ''}
                    onChangeText={onChange}
                    isEditable={btnDisable}
                    selectClassName={classes.selectClassName}
                    placeHolder={'Select'}
                    sortByOptionDisable={true}
                    optionsList={
                      [
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' },
                      ]
                      // <>
                      //   <option
                      //     value=""
                      //     disabled
                      //     className={classes.optionsClassName}>
                      //     Select
                      //   </option>
                      //   <option
                      //     value="yes"
                      //     className={classes.optionsClassName}>
                      //     Yes
                      //   </option>
                      //   <option value="no" className={classes.optionsClassName}>
                      //     No
                      //   </option>
                      // </>
                    }
                  />
                );
              }}
            />
          </Grid>
        ) : (
          ''
        )} */}
        <Grid item md={12} sm={12} xs={12}>
          <TaxPermitsEquipmentHeader
            selecetedEquipment={equipment}
            onSelectEquipment={onselect}
            disabled={btnDisable}
            requestType="TPTS"
          />
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={
            !equipment || !equipment.length ? { textAlign: 'center' } : {}
          }>
          {equipment.map((ele, index) => (
            <TaxPermitsEquipmentCard
              key={index}
              ele={ele}
              removedCard={remove}
              grossWeightShow={true}
            />
          ))}
          {!equipment || !equipment.length ? (
            <div className={'error-msg'}>Please Select Equipment</div>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Typography component={'div'} className={classes.infobox}>
        <InfoIcon className={classes.infoicon} />
        The UCR must be current for a KY permit to be issued. Please speak to
        your Simplex representative about filing your UCR during your phone
        call.
      </Typography>
    </form>
  );
};

export default observer(StatePermitsKentuckyForm);
