import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  renewalFormCardBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  renewalFormCardContent: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.4rem',
  },
  renewalFormCardDesign: {
    backgroundColor: '#FFFFFF !important',
    border: '2px solid #D1D1D1',
    borderRadius: '8px !important',
    marginBottom: '8px !important',
  },
  renewalFormCardDivider: {
    margin: '12px 0px !important',
  },
  renewalFormCardValue: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.9375rem',
    textTransform: 'capitalize',
  },
  renewalFormHeadingTitle: {
    color: '#241A2E',
    fontSize: '0.75rem',
  },
  renewalFormRemoveBtn: {
    alignSelf: 'center',
    color: '#A70D0D',
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: 600,
    textDecoration: 'underline',
  },
});
export default useStyles;
