import {
  Button,
  ButtonProps,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  styled,
} from '@mui/material';
import React from 'react';

import DriverTerminationContract from '../Drivers/DriverTerminationContract';
import FleetTerminationContract from '../Fleet/FleetTerminationContract';

type ToDoUnitTerminationIProps = {
  todoType?: string;
  showDialog: boolean;
  handleShowDialog: (show: boolean) => void;
};

const LightColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #CBCBCB',
  borderRadius: '8px',
  boxSizing: 'border-box',
  color: theme.palette.getContrastText('#FFFFFF'),
  height: '45px',
  width: '116px',
}));

const TerminationContracts: React.FC<ToDoUnitTerminationIProps> = ({
  todoType = '',
  showDialog = false,
  handleShowDialog,
}) => {
  //   const [unit, setUnit] = useState<{ [key: string]: string }>();
  //   const [driver, setDriver] = useState<{ [key: string]: string }>();

  return (
    <Dialog
      open={showDialog}
      maxWidth="lg"
      onBackdropClick={() => {
        handleShowDialog(false);
      }}>
      <Card>
        <DialogContent>
          {todoType === 'Driver' ? (
            <DriverTerminationContract />
          ) : (
            <FleetTerminationContract />
          )}
        </DialogContent>

        <DialogActions>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            sx={{ alignItems: 'center', marginBottom: '20px' }}>
            <Grid item lg>
              <LightColorButton
                type={'button'}
                variant="contained"
                sx={{ width: '100%' }}
                onClick={() => {
                  handleShowDialog(false);
                }}>
                Close
              </LightColorButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default TerminationContracts;
