import {
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const Card = styled(Grid)(() => ({
  backgroundColor: '#FFF',
  border: '1px solid #D1D1D1',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  margin: '5px 0 10px 0',
  padding: 10,
}));
const CardRow = styled(Grid)(() => ({
  alignItems: 'center',
  display: 'flex',
}));
const CardItem = styled(Grid)(() => ({
  flexDirection: 'column',
  flexGrow: 1,
  padding: 10,
  textTransform: 'capitalize',
}));

const Label = styled(Typography)(() => ({
  fontSize: 13,
  margin: '4px 0',
}));

const ErrorMsg = styled(Typography)(() => ({
  color: 'red',
  fontSize: 14,
  margin: '3px 0',
}));

type Props = {
  key?: number;
  data: any;
  requestType?: any[];
  shouldReset: boolean;
  onSelectEquipment?: (isChecked: boolean) => any;
  onDecideToSurrender?: (isChecked: boolean | undefined) => any;
  disabled: boolean;
  handleShowUnitAcknowledgment: (show: boolean) => void;
};

const surrenderPermitsOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const TerminateEquipmentCard: React.FC<Props> = ({
  data,
  requestType = [],
  onSelectEquipment = (isChecked: boolean) => {},
  onDecideToSurrender = (isChecked: boolean | undefined) => {},
  shouldReset = false,
  disabled = false,
  handleShowUnitAcknowledgment,
}) => {
  const [permit, setPermit] = useState('-');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (!isChecked) setPermit('-');
  }, [isChecked]);
  useEffect(() => {
    if (shouldReset) {
      setPermit('-');
      setIsChecked(false);
    }
  }, [shouldReset, data]);
  return (
    <Card>
      <CardRow style={{ opacity: isChecked ? 1 : 0.5 }}>
        <CardItem>
          <Checkbox
            disabled={disabled}
            checked={isChecked}
            onChange={(ev) => {
              setIsChecked(ev.target.checked);
              onSelectEquipment(ev.target.checked);
              handleShowUnitAcknowledgment(ev.target.checked);
            }}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 }, color: '#D1D1D1' }}
            color="success"
          />
        </CardItem>
        <CardItem>
          <Label paragraph>Equipment Type</Label>
          <h4>{data.equipmentType || '-'}</h4>
        </CardItem>
        <CardItem>
          <Label paragraph>Unit Type</Label>
          <h4>{data.unitType || '-'}</h4>
        </CardItem>
        <CardItem>
          <Label paragraph>Unit #</Label>
          <h4>{data.unitNumber || '-'}</h4>
        </CardItem>
        <CardItem>
          <Label paragraph>VIN</Label>
          <h4>{data.vinNumber || '-'}</h4>
        </CardItem>
      </CardRow>
      {isChecked && data.equipmentType !== 'trailer' && (
        <CardRow>
          <CardItem>
            <Label paragraph>
              Does the selected equipments have any state permits or
              registrations that need to be surrendered or terminated?
              <sup>*</sup>
            </Label>
            <FormControl disabled={disabled} fullWidth>
              <Select
                labelId="attachment-type-select-label"
                id="attachment-type-select"
                value={permit}
                onChange={(ev: any) => {
                  const value = ev.target.value;
                  setPermit(value);
                  if (value === '-') return onDecideToSurrender(undefined);
                  onDecideToSurrender(value);
                }}>
                <MenuItem selected key={requestType.length} value="-">
                  Select
                </MenuItem>
                {surrenderPermitsOptions.map((option: any, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardItem>
        </CardRow>
      )}
      {data.error && (
        <CardRow>
          <CardItem>
            <ErrorMsg paragraph>{data.error}</ErrorMsg>
          </CardItem>
        </CardRow>
      )}
    </Card>
  );
};

export default TerminateEquipmentCard;
