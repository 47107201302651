import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { SelectDatePicker } from '../../../../../../components/Forms/datePicker';
import { InputText } from '../../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../../components/Forms/selectInput';
import { SelectStateInput } from '../../../../../../components/Forms/selectStateDropdown';
import { UseFocus, useMountEffect } from '../../../../../../hooks';
import { NYAndNMStateDto, RequestType } from '../../../../../../types';
import TaxPermitsEquipmentCard from '../../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
import TaxPermitsEquipmentHeader from '../../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';

type statesFormTypes = {
  fromTodoPage: boolean;
  classes: any;
  control: any;
  errors: any;
  formType: any;
  onSubmit: any;
  handleSubmit: any;
  equipment: Array<any>;
  onselect: (src: any) => void;
  remove: (src: any) => void;
  btnDisable: any;
  NyAndNMStateInfo: NYAndNMStateDto | null;
  setValue: any;
  updateMethod: any;
  watch: any;
};
const StatePermitsNewMexicoAndNewYorkForm: React.FC<statesFormTypes> = (
  props: statesFormTypes,
) => {
  const {
    classes,
    control,
    errors,
    formType,
    fromTodoPage,
    handleSubmit,
    onSubmit,
    equipment,
    onselect,
    remove,
    NyAndNMStateInfo,
    setValue,
    updateMethod,
    // watch,
  } = props;

  const [formRequestType, setRequestType] = useState('');
  const [btnDisable, setBtnDisable] = useState<boolean>();

  // useEffect(() => {
  //   setRequestType(watch && watch('requestType') ? watch('requestType') : '');
  // }, [watch]);
  useEffect(() => {
    if (NyAndNMStateInfo?.hasPermit) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [btnDisable, NyAndNMStateInfo]);

  useEffect(() => {
    if (setValue) {
      setValue(
        'dotNumber',
        NyAndNMStateInfo && NyAndNMStateInfo.dotNumber
          ? NyAndNMStateInfo.dotNumber
          : '',
      );
      setValue(
        'mailingAddress',
        formRequestType === RequestType.New &&
          NyAndNMStateInfo &&
          NyAndNMStateInfo.mailingAddress &&
          NyAndNMStateInfo.mailingAddress.address
          ? NyAndNMStateInfo.mailingAddress.address
          : '',
      );
      setValue(
        'mailingCity',
        formRequestType === RequestType.New &&
          NyAndNMStateInfo &&
          NyAndNMStateInfo.mailingAddress &&
          NyAndNMStateInfo.mailingAddress.city
          ? NyAndNMStateInfo.mailingAddress.city
          : '',
      );
      setValue(
        'mailingState',
        formRequestType === RequestType.New &&
          NyAndNMStateInfo &&
          NyAndNMStateInfo.mailingAddress &&
          NyAndNMStateInfo.mailingAddress.state
          ? NyAndNMStateInfo.mailingAddress.state
          : '',
      );
      setValue(
        'mailingZip',
        formRequestType === RequestType.New &&
          NyAndNMStateInfo &&
          NyAndNMStateInfo.mailingAddress &&
          NyAndNMStateInfo.mailingAddress.zip
          ? String(NyAndNMStateInfo.mailingAddress.zip)
          : '',
      );
      setValue(
        'expirationDate',
        NyAndNMStateInfo && NyAndNMStateInfo.expirationDate
          ? NyAndNMStateInfo.expirationDate
          : '',
      );
      if (!NyAndNMStateInfo || !NyAndNMStateInfo.hasPermit) {
        setValue('requestType', RequestType.New);
        setRequestType(RequestType.New);
      } else if (
        (NyAndNMStateInfo &&
          NyAndNMStateInfo.expirationDate &&
          !moment(moment(NyAndNMStateInfo.expirationDate)).isAfter(moment())) ||
        fromTodoPage
      ) {
        setValue('requestType', RequestType.Renewal);
        setRequestType(RequestType.Renewal);
      } else if (
        NyAndNMStateInfo &&
        NyAndNMStateInfo.expirationDate &&
        moment(moment(NyAndNMStateInfo.expirationDate)).isAfter(moment())
      ) {
        setValue('requestType', RequestType.Additional);
        setRequestType(RequestType.Additional);
      }
    }
  }, [setValue, NyAndNMStateInfo, updateMethod, formRequestType, fromTodoPage]);

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  const optionList = () => {
    if (!NyAndNMStateInfo || !NyAndNMStateInfo.hasPermit) {
      return [{ label: 'New', value: RequestType.New }];
    } else if (
      (NyAndNMStateInfo &&
        NyAndNMStateInfo.expirationDate &&
        !moment(moment(NyAndNMStateInfo.expirationDate)).isAfter(moment())) ||
      fromTodoPage
    ) {
      return [{ label: 'Renewal', value: RequestType.Renewal }];
    } else if (
      NyAndNMStateInfo &&
      NyAndNMStateInfo.expirationDate &&
      moment(moment(NyAndNMStateInfo.expirationDate)).isAfter(moment())
    ) {
      return [
        {
          label: 'Additional',
          value: RequestType.Additional,
        },
      ];
    }
    return [];
  };
  return (
    <form
      style={{
        width: '100%',
      }}
      id={formType}
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
        <Grid item md={12} sm={12} xs={12}>
          <Box className={classes.labels}>New, Renewal or Additional *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="requestType"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  inputRefObj={input1Ref}
                  size={'small'}
                  errors={errors.requestType?.message || ''}
                  onChangeText={(e) => {
                    onChange(e);
                    setRequestType(e);
                  }}
                  isEditable={btnDisable || true}
                  selectClassName={classes.selectClassName}
                  placeHolder={'Select'}
                  sortByOptionDisable={true}
                  optionsList={optionList()}
                />
              );
            }}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid item md={6} sm={6} xs={6}>
            <Box className={classes.labels}>DOT *</Box>
            <Controller
              control={control}
              defaultValue={
                NyAndNMStateInfo && NyAndNMStateInfo.dotNumber
                  ? NyAndNMStateInfo.dotNumber
                  : ''
              }
              name="dotNumber"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.dotNumber?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={onChange}
                    isEditable={btnDisable}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid>
          {(formRequestType === RequestType.New &&
            NyAndNMStateInfo &&
            NyAndNMStateInfo.expirationDate) ||
          (formRequestType === RequestType.Renewal &&
            NyAndNMStateInfo &&
            NyAndNMStateInfo.expirationDate &&
            !moment(moment(NyAndNMStateInfo.expirationDate)).isAfter(
              moment(),
            )) ||
          (formRequestType === RequestType.Additional &&
            NyAndNMStateInfo &&
            NyAndNMStateInfo.expirationDate &&
            moment(moment(NyAndNMStateInfo.expirationDate)).isAfter(
              moment(),
            )) ? (
            <Grid item md={6} sm={6} xs={6}>
              <Box className={classes.labels}>Expiration Date</Box>
              <Controller
                control={control}
                defaultValue={
                  NyAndNMStateInfo && NyAndNMStateInfo.expirationDate
                    ? NyAndNMStateInfo.expirationDate
                    : ''
                }
                name="expirationDate"
                render={({
                  field: { onChange, name, value, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      inputRefObj={ref}
                      errors={errors?.expirationDate?.message || ''}
                      name={name}
                      onChangeDate={(e: string) => {
                        onChange(e);
                      }}
                      disabled={btnDisable}
                      value={value}
                    />
                  );
                }}
              />
            </Grid>
          ) : null}
        </Grid>
        {formRequestType === RequestType.New && (
          <>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.labels}>Mailing Address *</Box>
              <Controller
                control={control}
                defaultValue={
                  NyAndNMStateInfo &&
                  NyAndNMStateInfo.mailingAddress &&
                  NyAndNMStateInfo.mailingAddress.address
                    ? NyAndNMStateInfo.mailingAddress.address
                    : ''
                }
                name="mailingAddress"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.mailingAddress?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Box className={classes.labels}>City *</Box>
              <Controller
                control={control}
                defaultValue={
                  NyAndNMStateInfo &&
                  NyAndNMStateInfo.mailingAddress &&
                  NyAndNMStateInfo.mailingAddress.city
                    ? NyAndNMStateInfo.mailingAddress.city
                    : ''
                }
                name="mailingCity"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.mailingCity?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Box className={classes.labels}>State *</Box>
              <Controller
                control={control}
                defaultValue={
                  NyAndNMStateInfo &&
                  NyAndNMStateInfo.mailingAddress &&
                  NyAndNMStateInfo.mailingAddress.state
                    ? NyAndNMStateInfo.mailingAddress.state
                    : ''
                }
                name="mailingState"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectStateInput
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.mailingState?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={onChange}
                      size={'small'}
                      isEditable={btnDisable}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <Box className={classes.labels}>ZIP *</Box>
              <Controller
                control={control}
                defaultValue={
                  NyAndNMStateInfo &&
                  NyAndNMStateInfo.mailingAddress &&
                  NyAndNMStateInfo.mailingAddress.zip
                    ? String(NyAndNMStateInfo.mailingAddress.zip)
                    : ''
                }
                name="mailingZip"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.mailingZip?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      maxLengthVal="5"
                      value={value}
                      onChangeText={onChange}
                      isEditable={btnDisable}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
          </>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <TaxPermitsEquipmentHeader
            selecetedEquipment={equipment}
            onSelectEquipment={onselect}
            requestType="TPTS"
          />
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={
            !equipment || !equipment.length ? { textAlign: 'center' } : {}
          }>
          {equipment.map((ele, index) => (
            <TaxPermitsEquipmentCard
              key={index}
              ele={ele}
              removedCard={remove}
              grossWeightShow={true}
            />
          ))}
          {!equipment || !equipment.length ? (
            <div className={'error-msg'}>Please Select Equipment</div>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(StatePermitsNewMexicoAndNewYorkForm);
