import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Typography,
  // Typography,
} from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useLocation } from 'react-router-dom';

import { InputText } from '../../../../../components/Forms/InputText';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore, UseFocus, useMountEffect } from '../../../../../hooks';
// import { SelectInput } from '../../../../../components/Forms/selectInput';
import { DotUpdateFormInputs, TodoRequest } from '../../../../../types';
import {
  getTaxPermitsActivityList,
  TransformSsn,
} from '../../../../../utils/helper';
// import FilesList from '../../components/FilesList/FilesList';
// import { SelectInput } from '../../../../../components/Forms/selectInput';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { DotUpdateFormSchema } from '../../../../../utils/ValidatorSchema';
import ServiceRequestAddress from '../../components/Address/Address';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import useStyles from './DotUpdateStyles';

type DisableStatusType = {
  dot: boolean;
  dotpin: boolean;
  fein: boolean;
  ssn: boolean;
};

const TaxPermitsDotUpdate: React.FC<any> = () => {
  const { dotUpdateStore, authStore, activityListStore }: any =
    useCustomStore();
  const [trigger, setTrigger] = useState<boolean>(true);
  const [ownerssnnew, setownerssnnew] = useState<{
    valArr: string[];
    valStr: string;
  }>({
    valArr: [],
    valStr: '',
  });
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [btnDisableInitialLoader, setBtnDisableInitialLoader] =
    useState<boolean>(true);
  const [initialLoader, setInitialLoader] = useState<boolean>(true);
  const [notification, setNotification] = useState(false);
  const [disableStatus, setDisableStatus] = useState<DisableStatusType>({
    dot: true,
    dotpin: true,
    fein: false,
    ssn: false,
  });
  const [initialFormApiData, setInitialFormApiData] = useState<any>({});
  const [input1Ref, setInput1Focus] = UseFocus();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
    // reset: newFormRest,
    getValues,
    setValue,
    trigger: triggerForm,
    watch: watchForm,
  } = useForm<DotUpdateFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      biennial: false,
      dot: '',
      dotPin: '',
      email: '',
      estimatedMiles: '',
      fein: '',
      mailingAddress: '',
      mailingCity: '',
      mailingState: '',
      mailingZip: '',
      name: '',
      phoneNumber: '',
      physicalAddress: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      sameAs: false,
      ssn: '',
      vmt: false,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(DotUpdateFormSchema),
  });

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useMountEffect(setInput1Focus);

  const getInitialData = useCallback(async () => {
    setBtnDisableInitialLoader(false);
    setInitialLoader(true);
    const response = await dotUpdateStore.setInitialData();

    setBtnDisableInitialLoader(true);
    setInitialLoader(false);
    setownerssnnew({
      valArr: [],
      valStr: '',
    });
    if (response.status === 'success') {
      let {
        data: { fein, ssn },
      } = response;
      fein = !!fein;
      ssn = !!ssn;

      setDisableStatus((p) => ({
        ...p,
        fein,
        ssn,
      }));

      setInitialFormDataFn(response.data);
      setInitialFormApiData({ ...response.data });
    }

    if (response.status === 'error') {
      enqueueSnackbar(response.message, SnackBarConfig('e'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const setInitialFormDataFn = (data: any) => {
    Object.keys(data).forEach((key: any) => {
      const value = data[key];
      if (['physicalState', 'mailingState'].includes(key)) {
        const findedState: any = toJS(authStore.statesListOption).find(
          (e: any) => e.code === value,
        );
        if (findedState) {
          setValue(key, value);
          triggerForm(key);
        } else {
          setValue(key, '');
        }
      } else {
        setValue(key, value);
        if (key === 'dotPin') {
          setDisableStatus((pre) => ({
            ...pre,
            dotpin: value !== '' ? true : false,
          }));
        }
      }
    });
  };

  const cancelDotUpdate = () => {
    // newFormRest();
    dotUpdateStore.resetDotUpdate();
    setownerssnnew({
      valArr: [],
      valStr: '',
    });
    setInitialFormDataFn(initialFormApiData);
  };

  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  const onSubmit = useCallback(async (data: DotUpdateFormInputs) => {
    setBtnDisable(true);
    let todoID = '';
    if (location && location.state) {
      const { todoId } = location.state as TodoRequest;
      todoID = todoId;
    }

    const addResponse = await dotUpdateStore.addDotUpdateDetail(todoID);
    setBtnDisable(false);

    if (addResponse.isOk()) {
      enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
      setTrigger((p) => !p);
      activityListRecall();
      setNotification(true);
    }

    if (addResponse.isErr()) {
      const {
        error: { message = null },
      } = addResponse;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        // const messages: any = {};
        // Object.keys(message).forEach((key) => {
        //   messages[key] = message[key].message;
        // });

        // Object.keys(messages).forEach((msg: string) => {
        //   const key: string = msg;
        //   const value: string = messages[msg];

        //   enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
        // });
        enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAddressFn = (
    addressKey: string,
    fieldKey: string,
    fieldValue: string,
  ) => {
    if (addressKey === 'mailAddress') {
      dotUpdateStore.setMailAddressDetail({ [fieldKey]: fieldValue });
    } else if (addressKey === 'physicalAddress') {
      let mailKey = '';
      if (fieldKey === 'address') {
        mailKey = 'mailingAddress';
      } else if (fieldKey === 'city') {
        mailKey = 'mailingCity';
      } else if (fieldKey === 'state') {
        mailKey = 'mailingState';
      } else if (fieldKey === 'zipcode') {
        mailKey = 'mailingZip';
      }
      changePhysicalAddressDetail(fieldKey, fieldValue, mailKey);
    }
  };

  const changePhysicalAddressDetail = (
    key: string,
    value: string,
    mailKey: any,
  ) => {
    dotUpdateStore.setPhysicalAddressDetail({ [key]: value });
    if (getValues('sameAs')) {
      dotUpdateStore.setMailAddressDetail({ [key]: value });
      setValue(mailKey, value);
    }
  };

  const onChangeCopyAddress = (e: any) => {
    dotUpdateStore.setDotUpdateDetail(
      'physicalMailAddressSameStatus',
      e.target.checked,
    );
    if (e.target.checked) {
      setValue('mailingAddress', getValues('physicalAddress'));
      setValue('mailingCity', getValues('physicalCity'));
      setValue('mailingState', getValues('physicalState'));
      setValue('mailingZip', getValues('physicalZip'));
      triggerForm('mailingAddress');
      triggerForm('mailingCity');
      triggerForm('mailingState');
      triggerForm('mailingZip');

      const {
        physicalAddress: address,
        physicalCity: city,
        physicalState: state,
        physicalZip: zipcode,
      } = getValues();
      dotUpdateStore.setMailAddressDetail({ address, city, state, zipcode });
    }
  };
  if (notification) {
    return (
      <SuccessNotification
        title="DOT Update"
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return initialLoader ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <form
      style={{
        width: '100%',
      }}
      id={'dot-update-form'}
      onSubmit={handleSubmit(onSubmit)}>
      <FormControl variant="outlined" fullWidth size="small">
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ md: 3, sm: 2, xs: 1 }}
          minHeight={'100%'}>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>DOT#</Box>
            <Controller
              control={control}
              defaultValue=""
              name="dot"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.dot?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      onChange(val);
                      dotUpdateStore.setDotUpdateDetail('dot', val);
                    }}
                    isEditable={disableStatus.dot}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>DOT PIN *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="dotPin"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={input1Ref}
                    errors={errors.dotPin?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      if (/[^0-9a-zA-Z]/gim.test(val)) {
                        return;
                      }
                      onChange(val);
                      dotUpdateStore.setDotUpdateDetail('dotpin', val);
                    }}
                    isEditable={disableStatus.dotpin}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
            {!getValues('dotPin') ? (
              <Typography component={'div'} className={classes.infobox}>
                <InfoIcon sx={{ marginRight: '8px' }} />
                <div>
                  DOT PIN is required to update your DOT. A Simplex
                  representative will assist you with recovering your PIN
                </div>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>
              FEIN {!disableStatus.fein && <span>*</span>}
            </Box>
            <Controller
              control={control}
              defaultValue=""
              name="fein"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.fein?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      if (/[^0-9a-zA-Z]/gim.test(val)) {
                        return;
                      }
                      onChange(val);
                      dotUpdateStore.setDotUpdateDetail('fein', val);
                    }}
                    isEditable={disableStatus.fein}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>
              Owner SSN {!disableStatus.ssn && <span>*</span>}
            </Box>
            <Controller
              control={control}
              defaultValue=""
              name="ssn"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    autoComplete={'off'}
                    errors={errors.ssn?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={TransformSsn(value)}
                    // value={value}
                    onChangeText={(val: any, selectionStart: number) => {
                      if (/[^\d*-]/gim.test(val)) {
                        return;
                      }
                      if (!val) {
                        onChange('');
                        dotUpdateStore.setDotUpdateDetail('ownerssn', '');
                        setownerssnnew({
                          valArr: [],
                          valStr: '',
                        });
                        return;
                      }

                      function patternDesider(length: number) {
                        let regexstr: any = '';
                        if (length < 3) {
                          regexstr = '$1';
                        } else if (length === 3) {
                          regexstr = '$1-';
                        } else if (length < 6) {
                          regexstr = '$1-$2';
                        } else if (length === 6) {
                          regexstr = '$1-$2-';
                        } else if (length > 6) {
                          regexstr = '$1-$2-$3';
                        }
                        return regexstr;
                      }

                      let PatternStringDesiderValue: any = patternDesider(
                        val.length,
                      );

                      let { valArr, valStr } = ownerssnnew;
                      const accessindex: number = selectionStart - 1;
                      if (valStr.length < val.length) {
                        if (selectionStart === valStr.length + 1) {
                          if (selectionStart >= 12) {
                            return;
                          }
                          valArr[accessindex] = val[accessindex];
                          for (let i = 0; i < valArr.length; i++) {
                            if (!valArr[i]) {
                              valArr[i] = '-';
                            }
                          }
                        } else {
                          const cpyValArr: any = [...valArr];
                          cpyValArr.splice(accessindex, 0, val[accessindex]);
                          let cpyValArrss: any = cpyValArr
                            .join('')
                            .replace(/[-]/gim, '');
                          // .replace(/[0]+$/gmi,'')

                          cpyValArrss = cpyValArrss.replace(
                            /(.{0,3})(.{0,2})(.{0,4})/,
                            PatternStringDesiderValue,
                          );

                          // const cpyValArrssLength: number = cpyValArrss.length
                          cpyValArrss = cpyValArrss.split('');
                          // .concat(sliceValues.slice((cpyValArrssLength + 1), 11));

                          valArr = cpyValArrss.slice(0, 11);
                        }
                      }

                      if (val.length < valStr.length) {
                        let cpyvalArr = [...valArr];
                        const valueStrLength: number = valStr.length;
                        if (valueStrLength - 1 === val.length) {
                          if (valStr[selectionStart] === '-') {
                            selectionStart -= 1;
                          }
                          cpyvalArr.splice(selectionStart, 1);
                          cpyvalArr = cpyvalArr
                            .join('')
                            .replace(/[-]/gim, '')
                            .replace(
                              /(.{0,3})(.{0,2})(.{0,4})/,
                              patternDesider(val.length - 1),
                            )
                            .split('');

                          PatternStringDesiderValue = patternDesider(
                            val.length - 1,
                          );

                          valArr = cpyvalArr;
                        } else {
                          let cpyValArr: any = [...valArr];
                          const deletedSequence: any =
                            valStr.length - val.length;
                          cpyValArr.splice(selectionStart, deletedSequence);

                          // const valLengthNum:number = val.length;
                          const valLengthNum: number = cpyValArr.length;
                          let addStepIndex: any = 0;

                          if (
                            valLengthNum === 5 &&
                            !cpyValArr.find((e: any) => e === '-')
                          ) {
                            addStepIndex = 1;
                          }

                          cpyValArr = cpyValArr
                            .join('')
                            .replace(/[-]/gim, '')
                            .replace(
                              /(.{0,3})(.{0,2})(.{0,4})/,
                              patternDesider(
                                valLengthNum + Number(addStepIndex),
                              ),
                            )
                            .split('');

                          PatternStringDesiderValue = patternDesider(
                            valLengthNum + Number(addStepIndex),
                          );

                          valArr = cpyValArr;
                        }
                      }

                      let valueStr: any = valArr.join('').replace(/[-]/gim, '');
                      // .replace(/[0]+$/gmi,'')

                      valueStr = valueStr.replace(
                        /(.{0,3})(.{0,2})(.{0,4})/,
                        PatternStringDesiderValue,
                      );

                      valStr = valueStr;
                      setownerssnnew({
                        valArr,
                        valStr,
                      });

                      onChange(valueStr);
                      dotUpdateStore.setDotUpdateDetail(
                        'ownerssn',
                        valueStr.replace(/[-]/gim, ''),
                      );
                    }}
                    isEditable={disableStatus.ssn}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ServiceRequestAddress
              control={control}
              addressName="physicalAddress"
              errors={errors}
              disableStatus={getValues('sameAs')}
              onChangeFn={changeAddressFn}
              fields={{
                address: 'physicalAddress',
                city: 'physicalCity',
                state: 'physicalState',
                zipcode: 'physicalZip',
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            display={'flex'}
            alignItems={'center'}>
            <Controller
              control={control}
              defaultValue={false}
              name="sameAs"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <Checkbox
                    color={'success'}
                    size={'medium'}
                    checked={value}
                    name={name}
                    ref={ref}
                    onChange={(e) => {
                      onChange(e);
                      onChangeCopyAddress(e);
                    }}
                  />
                );
              }}
            />
            <Box
              className={`${classes.formLabelClass} ` + `${classes.fontBold}`}>
              Mailing Address is same as Physical Address
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ServiceRequestAddress
              control={control}
              addressName="mailAddress"
              errors={errors}
              disableStatus={getValues('sameAs')}
              onChangeFn={changeAddressFn}
              fields={{
                address: 'mailingAddress',
                city: 'mailingCity',
                state: 'mailingState',
                zipcode: 'mailingZip',
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>Legal Name *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="name"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.name?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      onChange(val);
                      dotUpdateStore.setCompanyDetail({ profileName: val });
                    }}
                    isEditable={false}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>Email *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="email"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.email?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      onChange(val);
                      dotUpdateStore.setCompanyDetail({ email: val });
                    }}
                    isEditable={false}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>Phone Number *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="phoneNumber"
              render={({
                field: { onChange, value, name, ref: phNumberRef },
                formState,
              }) => {
                return (
                  // <InputText
                  //   isFormSubmittedOnce={!!formState.submitCount}
                  //   inputRefObj={ref}
                  //   errors={errors.phoneNumber?.message || ''}
                  //   name={name}
                  //   isRefreshValue={true}

                  //   value={value}
                  //   onChangeText={(val) => {
                  //     onChange(val);
                  //     dotUpdateStore.setCompanyDetail({ phoneNumber: val });
                  //   }}
                  //   isEditable={false}
                  //   className={classes.selectClassName}
                  //   type={'text'}
                  //   variant="standard"
                  // />
                  <React.Fragment>
                    <NumberFormat
                      className={classes.selectClassName}
                      format="(###) ###-####"
                      value={value}
                      allowEmptyFormatting
                      mask="_"
                      getInputRef={(ref: any) => {
                        phNumberRef = ref;
                      }}
                      onValueChange={(value: any) => {
                        onChange(value.value);
                        dotUpdateStore.setCompanyDetail({
                          phoneNumber: value.value,
                        });
                      }}
                    />
                    <span className="custom-error">
                      {errors.phoneNumber?.message || ''}
                    </span>
                  </React.Fragment>
                );
              }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.checkBoxFlex}>
              <Controller
                control={control}
                defaultValue={false}
                name="biennial"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <Checkbox
                      inputRef={ref}
                      // disabled={loading || flatworkComplete}
                      checked={value}
                      onChange={(val: any) => {
                        onChange(val);
                        dotUpdateStore.setDotUpdateDetail(
                          'biennialReport',
                          val.target.checked,
                        );
                      }}
                      name={name}
                      value={value}
                      color="success"
                      tabIndex={0}
                    />
                  );
                }}
              />
              <Box
                className={classes.formLabelClass}
                style={{
                  fontFamily: 'FiraSans-Medium',
                }}>
                Biennial Report
              </Box>
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.checkBoxFlex}>
              <Controller
                control={control}
                defaultValue={false}
                name="vmt"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <Checkbox
                      inputRef={ref}
                      // disabled={loading || flatworkComplete}
                      checked={value}
                      onChange={(val: any) => {
                        onChange(val);
                        if (!val.target.checked) {
                          setValue('estimatedMiles', '');
                        }
                        dotUpdateStore.setDotUpdateDetail(
                          'vmtReport',
                          val.target.checked,
                        );
                      }}
                      name={name}
                      value={value}
                      color="success"
                      tabIndex={0}
                    />
                  );
                }}
              />
              <Box
                className={classes.formLabelClass}
                style={{
                  fontFamily: 'FiraSans-Medium',
                }}>
                VMT Report
              </Box>
            </div>
          </Grid>
          {watchForm('vmt') ? (
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Estimated Miles *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="estimatedMiles"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.estimatedMiles?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val: any) => {
                        if (/[^\d]/gim.test(val)) {
                          return;
                        }
                        onChange(val);
                        dotUpdateStore.setDotUpdateDetail(
                          'estimatedMiles',
                          val,
                        );
                      }}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
          ) : (
            ''
          )}
          <Grid item md={12} sm={12} xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <TaxPermitsButtons
              formId={'dot-update-form'}
              disabled={btnDisable}
              formReset={cancelDotUpdate}
              disableLoader={btnDisableInitialLoader}
            />
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
};

export default observer(TaxPermitsDotUpdate);
