import ClearIcon from '@mui/icons-material/Clear';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import DialogMigrate from '../../../../../../components/Dialog/DialogMigrate';
import {
  ActivityListResponseDTO,
  DotActivityListResponseDTO,
} from '../../../../../../types';

type ActivityListDialogTypes = {
  classes: any;
  isOpen: boolean | any;
  closeDialogWindow: () => void;
  notesView: ActivityListResponseDTO | DotActivityListResponseDTO | null;
};
const ActivityListDialogContent: React.FC<ActivityListDialogTypes> = (
  props: ActivityListDialogTypes,
) => {
  const { classes, isOpen, closeDialogWindow, notesView } = props;
  return (
    <DialogMigrate
      aria-labelledby="activitylist-view"
      className={classes.dialog}
      maxWidth={'md'}
      open={isOpen}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onClose={closeDialogWindow}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            {notesView && notesView.subject
              ? notesView.subject.split('-')[1]
                ? notesView.subject.split('-')[1]
                : ''
              : ''}
          </div>
          <Typography component={'div'} className={classes.headerRightSide}>
            <Typography
              component={'span'}
              className={classes.dialogReferenceIdView}>
              Reference #
              {notesView && notesView.referenceId ? notesView.referenceId : ''}
            </Typography>
            <ClearIcon
              className={classes.clearIcon}
              onClick={() => {
                closeDialogWindow();
              }}
            />
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogMainContent}>
        <Typography component={'div'} className={classes.dialogContent}>
          <div className={classes.dialogContentPadding}>
            <Typography
              component={'div'}
              className={classes.dialogModifiedContent}>
              Note modified by{' '}
              {notesView && notesView.submittedBy ? notesView.submittedBy : ''}
            </Typography>
            <Typography
              component={'div'}
              className={classes.dialogNotesContent}>
              Portal Notes for{' '}
              {notesView && notesView.subject ? notesView.subject : ''}
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  notesView && notesView.notes
                    ? notesView.notes
                    : '<p>Notes not found</p>',
              }}
            />
          </div>
        </Typography>
      </DialogContent>
    </DialogMigrate>
  );
};

export default observer(ActivityListDialogContent);
