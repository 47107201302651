import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import simplexLogo from '../../assets/images/notification/simplex.svg';
import { useCustomStore } from '../../hooks';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './NotificationDialogueStyles';

const NotificationDialogue: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const { displayNotification }: any = useCustomStore();
  return (
    <DialogMigrate
      open={displayNotification.getDialogueOpenStatus}
      aria-labelledby="equipment-select"
      className={classes.dialog}
      maxWidth="md"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onClose={() => displayNotification.setToggleDialogueDisplayStatus()}>
      <Typography component={'div'}>
        <Typography component={'div'} className={classes.dialogHead}>
          <Typography component={'div'} className={classes.dialogtitle}>
            Simplex Notifications
          </Typography>
          <Typography
            component={'div'}
            className={classes.dialogclose}
            onClick={() =>
              displayNotification.setToggleDialogueDisplayStatus()
            }>
            OKAY
          </Typography>
        </Typography>
        <Typography component={'div'} className={classes.dialogBody}>
          {displayNotification.getloadingEffectStatus ? (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <CircularProgress size={30} style={{ marginTop: 20 }} />
            </div>
          ) : displayNotification.getUsersNotificationDetails.length ? (
            displayNotification.getUsersNotificationDetails.map(
              (notification: any, index: number) => {
                if (notification.description) {
                  const replacevalue: string = (
                    notification.description || ''
                  ).replace(/\n/gim, '<br>');
                  notification.description = replacevalue;
                }

                return (
                  <Typography
                    component={'div'}
                    className={classes.notDetails}
                    key={index}>
                    <Typography component={'div'} className={classes.flexA}>
                      <Typography
                        component={'img'}
                        src={notification.type === 'ad-hoc' ? simplexLogo : ''}
                        alt={'img'}
                        sx={{
                          marginRight: '3px',
                          width: '30px',
                        }}
                      />
                      <Typography
                        component={'div'}
                        className={classes.notTitle}>
                        {notification.type === 'ad-hoc'
                          ? 'Simplex Notice - '
                          : ''}
                        {notification.title}
                      </Typography>
                    </Typography>
                    <Typography
                      component={'div'}
                      className={classes.notDisc}
                      dangerouslySetInnerHTML={{
                        __html: notification.description,
                      }}
                    />
                    {notification.hyperLink && notification.hyperLinkText && (
                      <Typography
                        component={'a'}
                        className={classes.notLink}
                        onClick={() => {
                          if (notification.hyperLink) {
                            window.open(notification.hyperLink, '_blank');
                          }
                        }}>
                        {notification.hyperLinkText}
                      </Typography>
                    )}
                  </Typography>
                );
              },
            )
          ) : (
            <div style={{ padding: '2rem 0', textAlign: 'center' }}>
              No Records Found
            </div>
          )}
        </Typography>
      </Typography>
    </DialogMigrate>
  );
};

export default observer(NotificationDialogue);
