import { PrivateRoutes } from '../../../../../config';
import { customNavTabs } from '../../../../../types';
import annualReportLogo from './../../../../../assets/images/TPTS/annual-report.svg';
import annualReportActiveLogo from './../../../../../assets/images/TPTS/annual-report-active.svg';
import dotLogo from './../../../../../assets/images/TPTS/dot.svg';
import dotActiveLogo from './../../../../../assets/images/TPTS/dot-active.svg';
import ftQuarterLogo from './../../../../../assets/images/TPTS/ft-quarter.svg';
import ftQuarterActiveLogo from './../../../../../assets/images/TPTS/ft-quarter-active.svg';
import iftaLogo from './../../../../../assets/images/TPTS/ifta.svg';
import iftaActiveLogo from './../../../../../assets/images/TPTS/ifta-active.svg';
import irpLogo from './../../../../../assets/images/TPTS/irp.svg';
import irpActiveLogo from './../../../../../assets/images/TPTS/irp-active.svg';
import roadTaxLogo from './../../../../../assets/images/TPTS/road-tax.svg';
import roadTaxActiveLogo from './../../../../../assets/images/TPTS/road-tax-active.svg';
import statePermitsLogo from './../../../../../assets/images/TPTS/state-permits.svg';
import statePermitsActiveLogo from './../../../../../assets/images/TPTS/state-permits-active.svg';
import ucrLogo from './../../../../../assets/images/TPTS/ucr.svg';
import ucrActiveLogo from './../../../../../assets/images/TPTS/ucr-active.svg';

export const NavTabsTPTS: customNavTabs[] = [
  {
    activeIcon: ucrActiveLogo,
    activeTabKey: 'ucr',
    filter: true,
    icon: ucrLogo,
    index: '1',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.UCR,
    shortkey: 'ucr',
    tab: true,
    title: 'UCR',
  },
  {
    activeIcon: roadTaxActiveLogo,
    activeTabKey: 'roadTaxes',
    filter: true,
    icon: roadTaxLogo,
    index: '2',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.ROADTAX,
    shortkey: 'roadTaxes',
    tab: true,
    title: 'Road Taxes /2290',
  },
  {
    activeIcon: annualReportActiveLogo,
    activeTabKey: 'annualReport',
    filter: true,
    icon: annualReportLogo,
    index: '3',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.ANNUALREPORT,
    shortkey: 'annualReport',
    tab: true,
    title: 'Annual Report',
  },
  {
    activeIcon: iftaActiveLogo,
    activeTabKey: 'ifta',
    filter: true,
    icon: iftaLogo,
    index: '4',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.IFTA,
    shortkey: 'ifta',
    tab: true,
    title: 'IFTA',
  },
  {
    activeIcon: statePermitsActiveLogo,
    activeTabKey: 'statePermits',
    filter: false,
    icon: statePermitsLogo,
    index: '5',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.STATEPERMITS,
    shortkey: '',
    tab: true,
    title: 'State Permits',
  },
  {
    activeIcon: statePermitsActiveLogo,
    activeTabKey: 'statePermits',
    filter: true,
    icon: statePermitsLogo,
    index: '',
    isDisabled: false,
    route: '',
    shortkey: 'nyStatePermit',
    tab: false,
    title: 'State Permits - New York',
  },
  {
    activeIcon: statePermitsActiveLogo,
    activeTabKey: 'statePermits',
    filter: true,
    icon: statePermitsLogo,
    index: '',
    isDisabled: false,
    route: '',
    shortkey: 'nmStatePermit',
    tab: false,
    title: 'State Permits - New Mexico',
  },
  {
    activeIcon: statePermitsActiveLogo,
    activeTabKey: 'statePermits',
    filter: true,
    icon: statePermitsLogo,
    index: '',
    isDisabled: false,
    route: '',
    shortkey: 'kyStatePermit',
    tab: false,
    title: 'State Permits- Kentucky',
  },
  {
    activeIcon: statePermitsActiveLogo,
    activeTabKey: 'statePermits',
    filter: true,
    icon: statePermitsLogo,
    index: '',
    isDisabled: false,
    route: '',
    shortkey: 'orNewStatePermit',
    tab: false,
    title: 'State Permits - Oregon New',
  },
  {
    activeIcon: statePermitsActiveLogo,
    activeTabKey: 'statePermits',
    filter: true,
    icon: statePermitsLogo,
    index: '',
    isDisabled: false,
    route: '',
    shortkey: 'orTempStatePermit',
    tab: false,
    title: 'State Permits - Oregon Temporary',
  },
  {
    activeIcon: irpActiveLogo,
    activeTabKey: 'irpService',
    filter: true,
    icon: irpLogo,
    index: '6',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.IRPSERVICE,
    shortkey: 'irp',
    tab: true,
    title: 'IRP Service',
  },
  {
    activeIcon: dotActiveLogo,
    activeTabKey: 'dotUpdate',
    filter: true,
    icon: dotLogo,
    index: '7',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.DOTUPDATE,
    shortkey: 'dot',
    tab: true,
    title: 'DOT Update',
  },
  {
    activeIcon: ftQuarterActiveLogo,
    activeTabKey: 'ftQuarterlyFiling',
    filter: true,
    icon: ftQuarterLogo,
    index: '8',
    isDisabled: false,
    route: PrivateRoutes.SERVICEREQUEST.FTQUARTERFILLING,
    shortkey: 'ftQuarterly',
    tab: true,
    title: 'Fuel Tax Quarterly Filing',
  },
];
