import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  renewalFormHeading: {
    color: '#241A2E !important',
    fontSize: '1.375rem !important',
  },
  renewalFormHeadingSearchLink: {
    backgroundColor: 'unset !important',
    border: 'unset !important',
    color: '#0061FF !important',
    cursor: 'pointer',
    fontSize: '0.875rem !important',
    fontWeight: '600 !important',
    textDecoration: 'underLine',
  },
  renewalFormheadingFlex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});
export default useStyles;
