import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RoadTaxPermitsFormInputs } from '../../types';
import { RootStore } from '..';

export class RoadTaxesApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  createRoadTaxes = (data: RoadTaxPermitsFormInputs) => {
    const {
      CreateRoadTax: { Endpoint, Method },
    } = ApiRoutes.RoadTaxes;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: Endpoint,
    });
  };
}
