import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

type GetListResponse = {
  nextLink: string;
  response: Violation[];
};
type MoreDetailsResponse = {
  primaryDriver?: any;
  secondaryDriver?: any;
  truckInfo?: any;
  trailerInfo?: any;
  violationId?: string;
};

type GetParams = {
  limit?: number;
  nextLink?: string;
  sortDirection?: string;
  sortBy?: string;
};
export class ViolationsApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getData = async (params: GetParams) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Violations;

    const response = await this.rootStore.apiStore.call<GetListResponse>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (response.isOk()) {
      return {
        list: response.value.response,
        nextLink: response.value.nextLink,
      };
    }
    return {
      list: [],
      nextLink: '',
    };
  };
  getMoreDetails = async (id: string): Promise<MoreDetailsResponse> => {
    const {
      GetList: { Endpoint = '', Method },
    } = ApiRoutes.Violations;

    const url = `${Endpoint}/${id}`;
    const response = await this.rootStore.apiStore.call<MoreDetailsResponse>({
      method: Method,
      url,
    });

    if (response.isOk()) {
      return response.value;
    }
    return {};
  };

  export = async (params: GetParams) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Violations;

    return await this.rootStore.apiStore.call<GetListResponse>({
      method: Method,
      params,
      responseType: 'blob',
      url: Endpoint + '/export',
    });
  };
}
