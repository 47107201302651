import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
// import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import DialogMigrate from '../../../../../components/Dialog/DialogMigrate';
import { SelectInput } from '../../../../../components/Forms/selectInput';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore } from '../../../../../hooks';
import {
  RequestType,
  StatePermitsFormInputs,
  StatePermitsKentuckyFormInputs,
  StatePermitsOregonFormInputs,
  StatesPermits,
  TodoRequest,
} from '../../../../../types';
import { getTaxPermitsActivityList } from '../../../../../utils/helper';
import { createSearchQueryObject } from '../../../../../utils/searchParams';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import {
  StatePermitsFormSchema,
  StatePermitsKentuckyFormSchema,
  StatePermitsOregonFormSchema,
} from '../../../../../utils/ValidatorSchema';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import useStyles from './StatePermitsStyles';
import StatePermitsKentuckyForm from './StatesForms/KentuckyForm';
import StatePermitsNewMexicoAndNewYorkForm from './StatesForms/NewMexicoAndNewYorkForm';
import StatePermitsOregonForm from './StatesForms/oregonForm';

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));

const TaxPermitsStatePermits: React.FC<any> = () => {
  const classes = useStyles();
  const { statePermitsStore, activityListStore, todoStore } = useCustomStore();
  const { NyAndNMStateInfo, KentuckyStateInfo, OregonStateInfo } =
    statePermitsStore;
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [formType, setFormType] = useState<StatesPermits>(StatesPermits.Empty);
  const [equipment, setEquipment] = useState<Array<any>>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState(false);
  const [resetFormRequestType, setResetFormRequestType] = useState<
    number | null
  >(null);
  const [dateOfEntryError, setDateOfEntryError] = useState<string>('');
  const [dateOfExistEntry, setDateOfExistEntry] = useState<string>('');
  const [dateWarning, setDateWarning] = useState<{
    dateOfEntryWarning: string;
    dateOfExistWarning: string;
  }>({ dateOfEntryWarning: '', dateOfExistWarning: '' });

  const [reminder, setReminder] = useState<boolean>(false);

  const navigate = useNavigate();

  const searchQuery = createSearchQueryObject(location.search);

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue: NYAndNMFormSetValue,
    watch: watchNyAndNm,
  } = useForm<StatePermitsFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      dotNumber: '',
      expirationDate: '',
      mailingAddress: '',
      mailingCity: '',
      mailingState: '',
      mailingZip: '',
      requestType: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(StatePermitsFormSchema),
  });
  // validation
  const {
    control: KyControl,
    handleSubmit: KyHandleSubmit,
    formState: { errors: KyErrors },
    reset: KyFormReset,
    setValue: KentuckyFormSetValue,
    getValues: KentuckyGetValues,
  } = useForm<StatePermitsKentuckyFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      dotNumber: '',
      hasUcrFiled: '',
      requestType: '',
      ucrExpDate: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(StatePermitsKentuckyFormSchema),
  });
  // validation
  const {
    control: OrControl,
    handleSubmit: OrHandleSubmit,
    formState: { errors: OrErrors },
    reset: OrFormReset,
    getValues,
    setValue: OrFormSetValue,
  } = useForm<StatePermitsOregonFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      dateOfEntry: undefined,
      dateOfExit: undefined,
      deliveryAddress: '',
      deliveryCity: '',
      deliveryState: '',
      deliveryZip: '',
      dotNumber: '',
      loadDescription: '',
      milesTraveled: undefined,
      pointOfEntry: '',
      pointOfExit: '',
      requestType: '',
      routeEntry: '',
      routeExit: '',
      units: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(StatePermitsOregonFormSchema),
  });
  const {
    fields,
    append,
    remove: arrayFieldRemove,
  } = useFieldArray({ control: OrControl, name: 'units' });

  const addOrRemoveArrFields = useCallback(
    (arr: Array<any>, removedObj: any) => {
      const unitsArr = getValues('units');
      arr.map((val) => {
        if (!unitsArr.some((ele) => ele.unitId === val.unitId)) {
          append({ ...val, odometer: null });
        }
      });
      const removedIndexArr: Array<number> = [];
      // incase new quipment array will be already existing value remove also arr fileds removed
      unitsArr.map((val, index) => {
        if (!arr.some((ele) => ele.unitId === val.unitId)) {
          removedIndexArr.push(index);
        }
      });
      arrayFieldRemove(removedIndexArr);
      // Removed field
      if (removedObj && removedObj.unitId) {
        const index = unitsArr.findIndex(
          (val) => val.unitId === removedObj.unitId,
        );
        if (index !== -1) {
          arrayFieldRemove(index);
        }
      }
    },
    [append, arrayFieldRemove, getValues],
  );

  const onselect = useCallback(
    (equipmentArr: any) => {
      setEquipment(equipmentArr);
      if (formType === StatesPermits.Oregon) {
        addOrRemoveArrFields(equipmentArr, {});
      }
    },
    [addOrRemoveArrFields, formType],
  );

  const remove = (equip: any) => {
    if (!btnDisable) {
      let arr: Array<any> = equipment;
      if (equip) {
        arr = arr.filter((ele) => ele.unitId !== equip.unitId);
      }
      setEquipment(arr);
      if (formType === StatesPermits.Oregon) {
        addOrRemoveArrFields(equipment, equip);
      }
    }
  };

  const getStatesPrdefinedValues = useCallback(
    async (e: StatesPermits) => {
      setLoading(true);
      await statePermitsStore.getPredefinedStatesInfo(e);
      setLoading(false);
    },
    [statePermitsStore],
  );

  const cancel = useCallback(() => {
    reset();
    KyFormReset();
    OrFormReset();
    setEquipment([]);
    setResetFormRequestType(Date.now());
    setFormType(StatesPermits.Empty);
    setDateOfEntryError('');
    setDateOfExistEntry('');
    setDateWarning((pre) => ({
      ...pre,
      ...{ dateOfEntryWarning: '', dateOfExistWarning: '' },
    }));
  }, [KyFormReset, OrFormReset, reset]);

  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  const getNyAndNmBodyFormData = useCallback(
    (data: any) => {
      if (data.requestType === RequestType.New) {
        return {
          dotNumber: data.dotNumber,
          equipment: equipment,
          ...(NyAndNMStateInfo && NyAndNMStateInfo.expirationDate
            ? {
                expirationDate: moment(data.expirationDate).format(
                  'MM/DD/YYYY',
                ),
              }
            : {}),
          mailingAddress: {
            address: data.mailingAddress,
            city: data.mailingCity,
            state: data.mailingState,
            zip: Number(data.mailingZip),
          },
          requestType: data.requestType,
          state: formType,
        };
      } else {
        return {
          dotNumber: data.dotNumber,
          equipment: equipment,
          ...((data.requestType === RequestType.Renewal &&
            NyAndNMStateInfo &&
            NyAndNMStateInfo.expirationDate &&
            moment(
              moment(NyAndNMStateInfo.expirationDate, 'DD-MM-YYYY'),
            ).isAfter(moment().format('DD-MM-YYYY'))) ||
          (data.requestType === RequestType.Additional &&
            NyAndNMStateInfo &&
            NyAndNMStateInfo.expirationDate &&
            !moment(
              moment(NyAndNMStateInfo.expirationDate, 'DD-MM-YYYY'),
            ).isAfter(moment().format('DD-MM-YYYY')))
            ? {
                expirationDate: moment(data.expirationDate).format(
                  'MM/DD/YYYY',
                ),
              }
            : {}),
          requestType: data.requestType,
          state: formType,
        };
      }
    },
    [NyAndNMStateInfo, equipment, formType],
  );
  const getKentuckyBodyFormData = useCallback(
    (data: StatePermitsKentuckyFormInputs) => {
      return {
        dotNumber: data.dotNumber,
        equipment: equipment,
        hasUcrFiled: moment().isAfter(moment(data.ucrExpDate).add(1, 'day'))
          ? data.hasUcrFiled === 'yes'
            ? true
            : false
          : null,
        requestType: data.requestType,
        state: formType,
        ucrExpDate: moment(data.ucrExpDate).format(),
      };
    },
    [equipment, formType],
  );
  const getOregonBodyFormData = useCallback(
    (data: StatePermitsOregonFormInputs) => {
      if (data.requestType === RequestType.New) {
        return {
          requestType: data.requestType,
          state: formType,
        };
      }
      return {
        deliveryAddress: {
          address: data.deliveryAddress,
          city: data.deliveryCity,
          state: data.deliveryState,
          zip: Number(data.deliveryZip),
        },
        dotNumber: data.dotNumber,
        equipment: data.units,
        loadDescription: data.loadDescription,
        milesTraveled: data.milesTraveled,
        pointOfEntry: {
          city: data.pointOfEntry,
          date: moment(data.dateOfEntry).format(),
          highway: data.routeEntry,
        },
        pointOfExit: {
          city: data.pointOfExit,
          date: moment(data.dateOfExit).format(),
          highway: data.routeExit,
        },
        requestType: data.requestType,
        state: formType,
      };
    },
    [formType],
  );
  const onSubmit = useCallback(
    async (data: StatePermitsFormInputs) => {
      if (formType === data.mailingState) {
        setReminder(true);
        return;
      }
      if (equipment && equipment.length) {
        const bodyData: any = getNyAndNmBodyFormData(data);
        setBtnDisable(true);
        if (location && location.state) {
          const { todoId } = location.state as TodoRequest;
          bodyData['todoId'] = todoId;
        }
        const getResp = await statePermitsStore.createNYAndNMStatePermits(
          bodyData,
        );
        setBtnDisable(false);
        if (getResp.isErr()) {
          enqueueSnackbar(
            getResp.error.message.toString(),
            SnackBarConfig('e'),
          );
        } else if (getResp.isOk()) {
          activityListRecall();
          cancel();
          enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
          if (searchQuery.todoId) {
            navigate(location.pathname);
          }
        }
      }
    },
    [
      equipment,
      formType,
      getNyAndNmBodyFormData,
      location,
      navigate,
      searchQuery,
      statePermitsStore,
      enqueueSnackbar,
      activityListRecall,
      cancel,
    ],
  );
  const onSubmitKyForm = useCallback(
    async (data: StatePermitsKentuckyFormInputs) => {
      if (equipment && equipment.length) {
        const bodyData = getKentuckyBodyFormData(data);
        setBtnDisable(true);
        const getResp = await statePermitsStore.createKentuckyStatePermits(
          bodyData,
        );
        setBtnDisable(false);
        if (getResp.isErr()) {
          enqueueSnackbar(
            getResp.error.message.toString(),
            SnackBarConfig('e'),
          );
        } else if (getResp.isOk()) {
          activityListRecall();
          cancel();
          enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
          setNotification(true);
        }
      }
    },
    [
      activityListRecall,
      cancel,
      enqueueSnackbar,
      equipment,
      getKentuckyBodyFormData,
      statePermitsStore,
    ],
  );
  const onSubmitOrForm = useCallback(
    async (data: StatePermitsOregonFormInputs) => {
      if (
        data.requestType === RequestType.Temporary &&
        formType === data.deliveryState
      ) {
        setReminder(true);
        return;
      }
      if (
        (equipment &&
          equipment.length &&
          !dateOfEntryError &&
          !dateOfExistEntry &&
          data.requestType === RequestType.Temporary) ||
        data.requestType === RequestType.New
      ) {
        setBtnDisable(true);
        const bodyData = getOregonBodyFormData(data);
        const getResp = await statePermitsStore.createOregonStatePermits(
          bodyData,
        );
        setBtnDisable(false);
        if (getResp.isErr()) {
          enqueueSnackbar(
            getResp.error.message.toString(),
            SnackBarConfig('e'),
          );
        } else if (getResp.isOk()) {
          activityListRecall();
          cancel();
          enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
        }
      }
    },
    [
      activityListRecall,
      cancel,
      dateOfEntryError,
      dateOfExistEntry,
      enqueueSnackbar,
      equipment,
      formType,
      getOregonBodyFormData,
      statePermitsStore,
    ],
  );
  const formTypeChange = (e: any) => {
    reset();
    KyFormReset();
    OrFormReset();
    setFormType(e);
    setEquipment([]);
    getStatesPrdefinedValues(e);
  };

  useEffect(() => {
    const data = todoStore.EquipmentFromTodo;
    if (data) {
      const obj: { [key: string]: StatesPermits } = {
        'NM Permit': StatesPermits.NewMexico,
        'NY Permit': StatesPermits.NewYork,
        'OR Permit': StatesPermits.Oregon,
      };
      const todoType = (data && data.todoType) || '';
      getStatesPrdefinedValues(obj[todoType]);
      setFormType(obj[todoType] || StatesPermits.Empty);
      // data.todoType === 'NM Permit'
      //   ? setFormType(StatesPermits.NewMexico)
      //   : data.todoType === 'NY Permit'
      //   ? setFormType(StatesPermits.NewYork)
      //   : setFormType(StatesPermits.Empty);
      if (data && data.dotNumber) {
        if (
          obj[todoType] === StatesPermits.NewYork ||
          obj[todoType] === StatesPermits.NewMexico
        ) {
          statePermitsStore.setNyAndNMStateValues({
            dotNumber: data.dotNumber,
          });
          NYAndNMFormSetValue('dotNumber', data.dotNumber);
        }
        if (obj[todoType] === StatesPermits.Oregon) {
          statePermitsStore.setOregonValues({ dotNumber: data.dotNumber });
          OrFormSetValue('dotNumber', data.dotNumber);
        }
        setResetFormRequestType(Date.now());
      }
      NYAndNMFormSetValue('requestType', 'renewal');

      // Clears the todo data once its extracted from store
      todoStore.setEquipmentFromTodo();
    }
  }, [
    NYAndNMFormSetValue,
    OrFormSetValue,
    statePermitsStore,
    todoStore,
    getStatesPrdefinedValues,
  ]);

  if (notification) {
    return (
      <SuccessNotification
        title="State Permits"
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return (
    <Grid container rowSpacing={2} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
      <Grid item md={12} sm={12} xs={12}>
        <Box className={classes.labels}>Select State Permits *</Box>
        <SelectInput
          name={'statePermits'}
          isRefreshValue={true}
          value={formType}
          size={'small'}
          onChangeText={formTypeChange}
          isEditable={btnDisable || loading}
          selectClassName={classes.selectClassName}
          placeHolder={'Select'}
          optionsList={[
            { label: 'Kentucky', value: StatesPermits.Kentucky },
            { label: 'Oregon', value: StatesPermits.Oregon },
            { label: 'New Mexico', value: StatesPermits.NewMexico },
            { label: 'New York', value: StatesPermits.NewYork },
          ]}
        />
      </Grid>
      {formType && loading ? (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <CircularProgress
            size={30}
            sx={{ color: '#DEC330', marginTop: '20px' }}
          />
        </div>
      ) : (
        <Grid item md={12} sm={12} xs={12}>
          <>
            {[StatesPermits.NewMexico, StatesPermits.NewYork].includes(
              formType,
            ) ? (
              <StatePermitsNewMexicoAndNewYorkForm
                fromTodoPage={!!searchQuery.todoId}
                {...{
                  NyAndNMStateInfo,
                  btnDisable,
                  classes,
                  control,
                  equipment,
                  errors,
                  formType,
                  handleSubmit,
                  onSubmit,
                  onselect,
                  remove,
                  setValue: NYAndNMFormSetValue,
                  updateMethod: resetFormRequestType,
                  watch: watchNyAndNm,
                }}
              />
            ) : (
              ''
            )}
            {[StatesPermits.Kentucky].includes(formType) ? (
              <StatePermitsKentuckyForm
                {...{
                  KentuckyStateInfo,
                  btnDisable,
                  classes,
                  control: KyControl,
                  equipment,
                  errors: KyErrors,
                  formType,
                  getValues: KentuckyGetValues,
                  handleSubmit: KyHandleSubmit,
                  onSubmit: onSubmitKyForm,
                  onselect,
                  remove,
                  setValue: KentuckyFormSetValue,
                  updateMethod: resetFormRequestType,
                }}
              />
            ) : (
              ''
            )}
            {[StatesPermits.Oregon].includes(formType) ? (
              <StatePermitsOregonForm
                {...{
                  OregonStateInfo,
                  btnDisable,
                  classes,
                  control: OrControl,
                  dateOfEntryError,
                  dateOfExistEntry,
                  dateWarning,
                  equipment,
                  errors: OrErrors,
                  fields,
                  formType,
                  getValues,
                  handleSubmit: OrHandleSubmit,
                  onSubmit: onSubmitOrForm,
                  onselect,
                  remove,
                  reset: OrFormReset,
                  setDateOfEntryError,
                  setDateOfExistEntry,
                  setDateWarning,
                  setValue: OrFormSetValue,
                  updateMethod: resetFormRequestType,
                }}
              />
            ) : (
              ''
            )}
            {formType ? (
              <>
                <Divider className={classes.divider} />
                <div style={{ width: '100%' }}>
                  <TaxPermitsButtons
                    formId={formType}
                    disabled={btnDisable}
                    formReset={cancel}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </>
        </Grid>
      )}
      <Grid item md={12} sm={12} xs={12}>
        <DialogMigrate
          aria-labelledby="Reminder"
          maxWidth={'sm'}
          className={classes.dialog}
          open={reminder}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          onClose={() => setReminder(false)}>
          <Typography component={'div'} sx={{ padding: '1.8rem' }}>
            <Typography component={'div'} sx={{ marginBottom: '1rem' }}>
              Permit for selected state already exists, you can only submit
              Renewal/Additional request type
            </Typography>
            <DarkColorButton
              type="button"
              onClick={() => setReminder(false)}
              variant="contained"
              sx={{ borderRadius: '4px', width: '100%' }}>
              <Typography
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'FiraSans-Medium',
                  fontSize: '0.9375rem',
                  fontWeight: 500,
                  height: '22px',
                  letterSpacing: '1px',
                  lineHeight: '22px',
                  textAlign: 'center',
                }}>
                Ok
              </Typography>
            </DarkColorButton>
          </Typography>
        </DialogMigrate>
      </Grid>
    </Grid>
  );
};

export default observer(TaxPermitsStatePermits);
