import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgress, Grid, TextField } from '@mui/material';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import filterIcon from '../../../assets/images/filter.svg';
import search from '../../../assets/images/h-search.svg';
import SuccessNotification from '../../../components/SuccessNotification';
import { TopAppBarTitle } from '../../../config/Constants';
import { API_LIMIT } from '../../../config/Constants';
import { useCustomStore } from '../../../hooks';
import {
  // AccessTypes,
  // customNavTabs,
  DotActivityListFilters,
} from '../../../types';
import { refreshActivityListByStore } from '../../../utils/helper';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import DotActivityList from '../ServiceRequest/components/CardsList/DotActivityList';
// import ActivityList from '../ServiceRequest/components/ActivityList/ActivityList';
import DataQChallengeDetails from './DataQChallengeDetails';
import DataQChallengeForm from './DataQChallengeForm';
import useStyles from './DataQChallengeStyles';

export type LabelValueType = {
  label: string;
  value: string;
};

export type LookupDataType = {
  challengeReasonLabel: string;
  challengeReasonValue: string;
  challengeType: 'Inspection' | 'Violation' | 'Accident';
  reasonTypes: LabelValueType[];
};

type DataQChallengeV2PropsType = {
  setAppBarTitle: (title: string) => unknown;
};
const DataQChallengeV2 = (
  props: DataQChallengeV2PropsType,
): React.ReactElement => {
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    inspectionsAndViolationsStore,
    accidentsStore,
    dotActivityListStore,
    // authStore,
  } = useCustomStore();
  const { ShowLoader, ActivityList, HasMoreResults, NextLink } =
    dotActivityListStore;
  const { Filters } = dotActivityListStore;
  const [filter, setFilters] = useState<DotActivityListFilters>({
    categories: [],
    dateSort: 'desc',
    driver: '',
    lastSubmittedBy: '',
    limit: API_LIMIT,
    nextLink: '',
    submittedBy: '',
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const [value, setValue] = useState<string>('');
  const [getMoreLoader, setGetMoreLoader] = useState<boolean>(false);

  const allCategories = [
    { key: 'Motor Vehicle Record', value: 'MVR' },
    { key: 'Add to Fleet', value: 'Add to Fleet' },
    { key: 'Driver Onboarding', value: 'New Driver Setup' },
    { key: 'Data Q', value: 'Data Q Challenge' },
    { key: 'Vehicle Inspection', value: 'Equipment Inspection' },
    { key: 'Driver Termination', value: 'Terminate Driver' },
    { key: 'Equipment Termination', value: 'Terminate Equipment' },
  ];

  const { setAppBarTitle } = props;
  const [notification, setNotification] = useState(false);

  const [loader, setLoader] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);

  const id: string = params && params.id ? params.id : '';
  const from: string = params && params.from ? params.from : '';
  const { basicInfo = null } = (location?.state as any)
    ? (location?.state as any)
    : { basicInfo: null };
  const fromInspections = from === 'inspections';

  const getAllActivityList = useCallback(async () => {
    const getEquipmentsResp = await refreshActivityListByStore(
      dotActivityListStore,
    );
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
  }, [dotActivityListStore, enqueueSnackbar]);

  const getActivityList = useCallback(async () => {
    dotActivityListStore.setShowLoader(true);
    const getEquipmentsResp = await dotActivityListStore.fetchActivityLists();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    dotActivityListStore.setShowLoader(false);
  }, [dotActivityListStore, enqueueSnackbar]);

  useEffect(() => {
    getAllActivityList();
  }, [getAllActivityList]);

  const getMoreActivityList = useCallback(async () => {
    dotActivityListStore.setFilters({ ...filter, ...{ nextLink: NextLink } });
    setGetMoreLoader(true);
    const getEquipmentsResp =
      await dotActivityListStore.fetchActivityListsBySearchBased();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [NextLink, dotActivityListStore, enqueueSnackbar, filter]);

  const setlastSubmited = useCallback(
    (key: string, value: string) => {
      setFilters((pre: any) => ({
        ...pre,
        [key]: filter.lastSubmittedBy === value ? '' : value,
      }));
    },
    [filter],
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setCategories = useCallback(
    (value: string) => {
      const arr = [...filter.categories];

      if (arr.some((e: any) => e === value)) {
        const index = arr.findIndex((e) => e === value);
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
      setFilters((pre: any) => ({
        ...pre,
        categories: arr,
      }));
    },
    [filter],
  );
  const applyFilters = () => {
    dotActivityListStore.setFilters({ ...filter, nextLink: '' });
    // getAllActivityList();
    getActivityList();
    handleClose();
  };
  const resetFilter = () => {
    setFilters((pre: any) => ({
      ...pre,
      categories: [],
      dateSort: 'desc',
      driver: '',
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: '',
      submittedBy: '',
    }));
    dotActivityListStore.setFilters({
      categories: [],
      dateSort: filter.dateSort,
      driver: '',
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: '',
      submittedBy: '',
    });
    getActivityList();
    // handleClose();
  };
  const removeFilter = (key: string, value: string) => {
    const arr = [...filter.categories];
    if (key === 'categories') {
      arr.splice(Number(value), 1);
    }
    setFilters((pre: any) => ({
      ...pre,
      [key]: key === 'categories' ? arr : value,
    }));

    dotActivityListStore.setFilters({
      ...filter,
      ...{ [key]: key === 'categories' ? arr : value },
      nextLink: '',
    });
    getActivityList();
    handleClose();
  };
  const formatCatergories = (
    shortKey: string,
  ): { key: string; value: string } => {
    let key = { key: '', value: '' };
    switch (shortKey) {
      case 'Motor Vehicle Record':
        key = { key: shortKey, value: 'MVR' };
        break;
      case 'Add to Fleet':
        key = { key: shortKey, value: 'Add to Fleet' };
        break;
      case 'Driver Onboarding':
        key = { key: shortKey, value: 'New Driver Setup' };
        break;
      case 'Data Q':
        key = { key: shortKey, value: 'Data Q Challenge' };
        break;
      case 'Vehicle Inspection':
        key = { key: shortKey, value: 'Equipment Inspection' };
        break;
      case 'Driver Termination':
        key = { key: shortKey, value: 'Terminate Driver' };
        break;
      case 'Equipment Termination':
        key = { key: shortKey, value: 'Terminate Equipment' };
        break;

      default:
        key = { key: '', value: '' };
        break;
    }
    return key;
  };

  const getInspectionAndViolationDetails = useCallback(
    async (id: string) => {
      const resp =
        await inspectionsAndViolationsStore.getMoreDetailsByInspectionId(id);
      if (!resp) {
        enqueueSnackbar(
          'Unable to fetch Inspection & Violations information',
          SnackBarConfig('e'),
        );
      } else {
        setData(resp);
      }
      setLoader(false);
    },
    [enqueueSnackbar, inspectionsAndViolationsStore],
  );

  const getAccidentDetails = useCallback(
    async (id: string) => {
      const resp = await accidentsStore.getMoreDetailsByAccidentId(id);
      if (!resp) {
        enqueueSnackbar(
          'Unable to fetch Accident information',
          SnackBarConfig('e'),
        );
      } else {
        setData(resp);
      }
      setLoader(false);
    },
    [enqueueSnackbar, accidentsStore],
  );

  useEffect(() => {
    if (id && from) {
      if (from === 'inspections') {
        getInspectionAndViolationDetails(id);
        setAppBarTitle(
          `${
            TopAppBarTitle.DATA_Q_CHALLENGE as string
          } - Inspections and Violations`,
        );
      }
      if (from === 'accidents') {
        getAccidentDetails(id);
        setAppBarTitle(
          `${TopAppBarTitle.DATA_Q_CHALLENGE as string} - Accidents`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, getInspectionAndViolationDetails, getAccidentDetails, id]);

  const inspectionsAndViolationsLookupData: LookupDataType[] = [
    {
      challengeReasonLabel: 'The Inspection does not belong to the company',
      challengeReasonValue: 'inspectionNotBelongsToCompany',
      challengeType: 'Inspection',
      reasonTypes: [
        {
          label:
            'Company and or USDOT Number is listed incorrectly on the inspection report',
          value: 'inspectionIncorrectInspectionOrUSDOT',
        },
        {
          label: 'Driver Unknown or Vehicle Unknown',
          value: 'inspectionUnknownDriverOrVehicle',
        },
      ],
    },
    {
      challengeReasonLabel: "I haven't received the report",
      challengeReasonValue: 'reportNotReceived',
      challengeType: 'Inspection',
      reasonTypes: [
        {
          label:
            'Driver lost report, never received the report or did not turn the report into the company',
          value: 'lostReport',
        },
      ],
    },
    {
      challengeReasonLabel:
        'The report contains a violation that is not a violation',
      challengeReasonValue: 'notAViolation',
      challengeType: 'Violation',
      reasonTypes: [],
    },
    {
      challengeReasonLabel: 'I received a different conviction',
      challengeReasonValue: 'differentConviction',
      challengeType: 'Violation',
      reasonTypes: [],
    },
  ];

  const accidentsLookupData: LookupDataType[] = [
    {
      challengeReasonLabel: 'The Accident does not belong to me or my company',
      challengeReasonValue: 'accidentNotBelongsToMe',
      challengeType: 'Accident',
      reasonTypes: [
        {
          label:
            'Company and or USDOT Number is listed incorrectly on the accident report',
          value: 'accidentIncorrectInspectionOrUSDOT',
        },
        {
          label: 'Driver Unknown or Vehicle Unknown',
          value: 'accidentUnknownDriverOrVehicle',
        },
      ],
    },
    {
      challengeReasonLabel: 'This is a Non-Preventable Accident',
      challengeReasonValue: 'nonPreventableAccident',
      challengeType: 'Accident',
      reasonTypes: [
        {
          label: 'Driver was not at-fault/could not avoid the collision',
          value: 'notAvoidTheCollision',
        },
      ],
    },
  ];

  const supportingDocMessage = {
    accidentIncorrectInspectionOrUSDOT:
      'Upload a print-out of Accident Report from the FMCSA portal',
    accidentUnknownDriverOrVehicle:
      'Upload a print-out of Accident Report from the FMCSA portal ',
    inspectionIncorrectInspectionOrUSDOT:
      'Upload a print-out of Inspection Report from the FMCSA portal',
    inspectionUnknownDriverOrVehicle:
      'Upload a print-out of Inspection Report from the FMCSA portal',
    lostReport: 'Upload a print-out of Inspection Report from the FMCSA portal',
    notAvoidTheCollision:
      'Upload a Police Report and any additional supporting pictures or documentation',
    violation: 'Upload any supporting pictures or documentation',
  };

  const sourceData: LabelValueType[] = [
    {
      label: 'Crash Report',
      value: 'Crash Report',
    },
    {
      label: 'Inspection Report',
      value: 'Inspection Report',
    },
    {
      label: 'FMCSA Portal',
      value: 'FMCSA Portal',
    },
    {
      label: 'SimplexHub Portal',
      value: 'SimplexHub Portal',
    },
    {
      label: 'Simplex2Go Mobile App',
      value: 'Simplex2Go Mobile App',
    },
  ];

  const getFormData = (): LookupDataType[] => {
    if (fromInspections) {
      return data &&
        data.violations &&
        Array.isArray(data.violations) &&
        data.violations.length > 0
        ? inspectionsAndViolationsLookupData
        : inspectionsAndViolationsLookupData.filter(
            (v) => v.challengeType !== 'Violation',
          );
    }
    return accidentsLookupData;
  };

  const getViolationsData = (): any[] => {
    if (
      data &&
      data.violations &&
      Array.isArray(data.violations) &&
      data.violations.length > 0
    ) {
      return data.violations
        .filter((v: any) => {
          return v.totalTimeViolationWeight > 0;
        })
        .map((v: any) => ({ ...v, selected: false }));
    }
    return [];
  };

  const onDataQSSubmit = async (payload: any) => {
    let isError = false;
    if (payload.violationCodes && payload.violationCodes.length > 0) {
      payload.violationCodes.map((vc: any) => {
        if (!vc.id || !vc.code || !vc.groupId) {
          isError = true;
        }
      });
    }
    if (isError) {
      enqueueSnackbar(
        'Error while submitting DataQ. Invalid Data.',
        SnackBarConfig('e'),
      );
      return;
    }

    const formData = new FormData();
    const fileData: Record<string, unknown>[] = [];
    formData.append('challengeReason', payload.challengeReason);
    formData.append('challengeType', payload.challengeType);
    formData.append('explanation', payload.explanation);
    formData.append('reasonType', payload.reasonType);
    formData.append('source', payload.source);
    formData.append(
      'matchedPrimaryDriverId',
      data.matchedPrimaryDriver.contactId
        ? data.matchedPrimaryDriver.contactId
        : '',
    );

    formData.append(
      'violationCodes',
      payload.violationCodes && payload.violationCodes.length > 0
        ? JSON.stringify(payload.violationCodes)
        : '',
    );

    payload.supportDocuments.map((val: any) => {
      const fileType = 'Data Q';

      const fileName = val.name;
      formData.append('files', val);
      fileData.push({
        challengeReason: payload.challengeReason,
        challengeType: payload.challengeType,
        fileName,
        fileType,
        service: 'Create DQ for Accident',
        type: 'account',
      });
    });

    if (formData.has('files')) {
      formData.append('data', JSON.stringify(fileData));
    }

    if (from === 'inspections') {
      formData.append('id', basicInfo.reportNumber.primaryText);
      formData.append('dqViolationDate', basicInfo.inspectionDate || '');
      const resp = await inspectionsAndViolationsStore.submitDataQChallenge(
        formData,
      );
      if (resp.isErr()) {
        enqueueSnackbar(
          'Error while submitting DataQ. Invalid Data.',
          SnackBarConfig('e'),
        );
        // enqueueSnackbar(String(resp.error.message), SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Successfully submited DataQ', SnackBarConfig('s'));
        getAllActivityList();
        setNotification(true);
      }
    } else {
      formData.append('id', basicInfo.accidentNumber.primaryText);
      formData.append('dqViolationDate', basicInfo.accidentDate || '');
      const resp = await accidentsStore.submitDataQChallenge(formData);
      if (resp.isErr()) {
        enqueueSnackbar('Error while submitting DataQ', SnackBarConfig('e'));
        enqueueSnackbar(String(resp.error.message), SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Successfully submited DataQ', SnackBarConfig('s'));
        getAllActivityList();
        setNotification(true);
      }
    }
  };
  // if (notification) {
  //   return (

  //   );
  // }

  return (
    <Grid container className={classes.dataQChallenge}>
      {loader ? (
        <Grid item xs={7} className="data-q-loader">
          <CircularProgress size={50} sx={{ color: '#DEC330' }} />
        </Grid>
      ) : (
        <Grid item xs={7} className="data-q-details">
          {notification ? (
            <SuccessNotification
              title={
                `Data Q Challenge Submitted for ${
                  from === 'inspections' ? 'Inspection' : 'Accidents'
                }` as string
              }
              handleNotification={() => {
                setNotification(false);
              }}
              postReqMessage="Your request has been submitted to Simplex for review. Please note, it does not mean your request has been filed with the FMCSA nor does it guarantee removal of any violation. A Simplex Representative will contact you if additional information to support your request for a Data Q Challenge is needed.	"
            />
          ) : (
            <>
              <DataQChallengeDetails
                data={data}
                basicInfo={basicInfo}
                from={from}
              />
              <DataQChallengeForm
                formData={getFormData()}
                sourceData={sourceData}
                violationData={getViolationsData()}
                from={from}
                supportingDocMessage={supportingDocMessage}
                onDataQSSubmit={onDataQSSubmit}
              />
            </>
          )}
        </Grid>
      )}
      {/* Right Side content */}
      <Grid
        item
        xs={5}
        className={classes.padcss}
        style={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}>
        <Typography
          className={classes.actFilter}
          component={'div'}
          display={'inline-flex'}
          flexWrap={'wrap'}
          width={'100%'}
          justifyContent={'space-between'}
          style={{ backgroundColor: 'rgb(234 234 234 / 38%)' }}>
          <Typography
            component={'span'}
            gutterBottom
            className={classes.cardMainHeading}>
            Activity List
          </Typography>
          <Typography
            component={'div'}
            gutterBottom
            className={classes.cardHeading}>
            <Typography className={classes.asend} component={'div'}>
              {filter.dateSort === 'asc' ? 'Date Ascending' : 'Date Descending'}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '5px',
                }}
                onClick={() =>
                  removeFilter(
                    'dateSort',
                    filter.dateSort === 'asc' ? 'desc' : 'asc',
                  )
                }>
                <KeyboardArrowUpIcon
                  fontSize="small"
                  {...(filter.dateSort === 'desc' ? { color: 'disabled' } : {})}
                />
                <KeyboardArrowDownIcon
                  fontSize="small"
                  {...(filter.dateSort === 'asc' ? { color: 'disabled' } : {})}
                />{' '}
              </div>
            </Typography>
            <Typography
              className={classes.fIcon}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <img className={classes.fimg} src={filterIcon} alt="filter" />
            </Typography>
            <Menu
              id="basic-menu"
              className="filter"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <Grid style={{ padding: '1rem' }}>
                <Typography component={'h3'}>CATEGORY</Typography>
                <Typography
                  component={'div'}
                  className={classes.borderBottom}
                  style={{ padding: '1rem 0' }}>
                  {allCategories.map((category, index) => (
                    <Grid
                      key={index}
                      item
                      className={classes.dflexA}
                      onClick={() => setCategories(category.key)}>
                      <input
                        type="checkbox"
                        tabIndex={0}
                        checked={filter.categories.some(
                          (val) => val === category.key,
                        )}
                      />
                      <Typography
                        component={'div'}
                        style={{ cursor: 'pointer' }}>
                        {category.key}
                      </Typography>
                    </Grid>
                  ))}
                </Typography>
                <Typography component={'h3'}>SUBMITTED IN LAST</Typography>
                <Typography component={'div'} className={classes.borderBottom}>
                  <Grid
                    className={classes.dflexA}
                    style={{ padding: '1rem 0' }}>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox as string} ` +
                          `${
                            (filter.lastSubmittedBy == '15'
                              ? classes.activeBox
                              : '') as string
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '15')
                        }>
                        15 DAYS
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox as string} ` +
                          `${
                            (filter.lastSubmittedBy == '30'
                              ? classes.activeBox
                              : '') as string
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '30')
                        }>
                        30 DAYS
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox as string} ` +
                          `${
                            (filter.lastSubmittedBy == '60'
                              ? classes.activeBox
                              : '') as string
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '60')
                        }>
                        60 DAYS
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography component={'h3'}>Driver Name</Typography>
                <Grid className={classes.borderBottom}>
                  <Grid container className={classes.searcBox}>
                    <Grid item md={2} className={classes.dflexJA}>
                      <img
                        className={classes.filteIcon}
                        src={search}
                        alt="search"
                      />
                    </Grid>
                    <Grid item md={10}>
                      <TextField
                        type={'text'}
                        value={filter.driver}
                        sx={{
                          input: { backgroundColor: 'unset', color: '#fff' },
                        }}
                        className={classes.inputF}
                        placeholder="Search"
                        onChange={(e: any) =>
                          setFilters((pre: any) => ({
                            ...pre,
                            driver: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Typography component={'h3'}>SUBMITTED BY</Typography>
                <Grid className={classes.borderBottom}>
                  <Grid container className={classes.searcBox}>
                    <Grid item md={2} className={classes.dflexJA}>
                      <img
                        className={classes.filteIcon}
                        src={search}
                        alt="search"
                      />
                    </Grid>
                    <Grid item md={10}>
                      <TextField
                        type={'text'}
                        value={filter.submittedBy}
                        sx={{
                          input: { backgroundColor: 'unset', color: '#fff' },
                        }}
                        className={classes.inputF}
                        placeholder="Search"
                        onChange={(event: any) =>
                          setFilters((pre: any) => ({
                            ...pre,
                            submittedBy: event.target.value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={6} className={classes.resetF}>
                    <Typography
                      component={'div'}
                      className={classes.resetF}
                      onClick={resetFilter}>
                      RESET FILTERS
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      component={'div'}
                      className={classes.filterBtn}
                      onClick={applyFilters}>
                      APPLY FILTER
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Menu>
          </Typography>
          <Typography component={'div'} className={classes.filterList}>
            {Filters.lastSubmittedBy ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('lastSubmittedBy', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.lastSubmittedBy} Days
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.driver ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('driver', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.driver}
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.submittedBy ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('submittedBy', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.submittedBy}
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.categories && Filters.categories.length
              ? Filters.categories.map((val: any, index: any) => (
                  <Typography
                    component={'div'}
                    className={classes.filterItem}
                    key={index}
                    onClick={() => removeFilter('categories', String(index))}>
                    <Typography
                      component={'div'}
                      className={classes.filterText}>
                      {formatCatergories(val)?.value}
                    </Typography>
                    <Typography className={classes.close} component={'span'}>
                      X
                    </Typography>
                  </Typography>
                ))
              : ''}
          </Typography>
        </Typography>
        {ShowLoader ? (
          <div className={classes.loader}>
            <CircularProgress size={30} sx={{ color: '#DEC330' }} />
          </div>
        ) : (
          <DotActivityList
            ActivityList={
              ActivityList && ActivityList.length ? ActivityList : []
            }
            getMoreActivityList={getMoreActivityList}
            getMoreLoader={getMoreLoader}
            HasMoreResults={HasMoreResults}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default observer(DataQChallengeV2);
