import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TrainingAssignDialog from '../../../../../components/TrainingAssignDialog/TrainingAssignDialog';
import { useCustomStore } from '../../../../../hooks';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import InspectionsHeader from './InspectionsHeader';
import InspectionsList from './InspectionsList';
import useStyles from './InspectionsStyles';

const Inspections: React.FC = () => {
  const { trainingStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
  const [moreTrainingInspection, setMoreTrainingInspections] =
    useState<boolean>(false);
  const [selectedTrainingInspection, setSelectedTrainingInception] =
    useState<any>();

  const getTrainingInspections = useCallback(async () => {
    trainingStore.setTrainingInspectionsLoader(true);
    const inspectionsResp = await trainingStore.getInitialTrainingInspections();
    if (inspectionsResp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Inspections',
        SnackBarConfig('e'),
      );
    }
    trainingStore.setTrainingInspectionsLoader(false);
  }, [enqueueSnackbar, trainingStore]);

  const getMoreTrainingInspections = useCallback(async () => {
    setMoreTrainingInspections(true);
    const resp = await trainingStore.getTrainingInspections();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Inspections',
        SnackBarConfig('e'),
      );
    }
    setMoreTrainingInspections(false);
  }, [enqueueSnackbar, trainingStore]);

  const trainingInspectionsSortBy = (columnName: string) => {
    trainingStore.setTrainingInspectionFilters({
      sortBy: columnName,
      sortChanged: true,
      sortDirection:
        trainingStore.TrainingInspectionFilters.sortDirection === 'asc'
          ? 'desc'
          : 'asc',
    });
    getTrainingInspections();
  };

  useEffect(() => {
    getTrainingInspections();
  }, [getTrainingInspections]);

  const onAssignInspection = (inspection: any) => {
    if (inspection && inspection.driver) {
      setOpenAssignDialog(true);
      setSelectedTrainingInception(inspection);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {trainingStore.TrainingInspectionsLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '100px',
            marginTop: '100px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{ maxHeight: 'calc(100vh - 125px)' }}
            id="training-inspections-table">
            <InfiniteScroll
              dataLength={trainingStore.TrainingInspectionsData.length}
              style={{ overflow: 'unset' }}
              next={getMoreTrainingInspections}
              hasMore={trainingStore.HasMoreTrainingInspections}
              scrollableTarget={'training-inspections-table'}
              loader={
                moreTrainingInspection && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <InspectionsHeader
                    trainingStore={trainingStore}
                    trainingInspectionsSortBy={trainingInspectionsSortBy}
                  />
                </TableHead>
                <TableBody>
                  {trainingStore.TrainingInspectionsData.length > 0 ? (
                    <InspectionsList
                      trainingStore={trainingStore}
                      onAssignInspection={onAssignInspection}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={10}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
      {openAssignDialog && selectedTrainingInspection && (
        <TrainingAssignDialog
          isOpen={openAssignDialog}
          id={selectedTrainingInspection.inspectionId}
          contactId={selectedTrainingInspection.driver.id}
          driverName={selectedTrainingInspection.driver.name}
          driverType={selectedTrainingInspection.driverType}
          type="inspection"
          closeDialog={(refresh) => {
            setOpenAssignDialog(false);
            refresh && getTrainingInspections();
          }}
        />
      )}
    </Box>
  );
};

export default observer(Inspections);
