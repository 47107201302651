import { FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { InputText } from '../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../components/Forms/selectInput';
import { useCustomStore } from '../../../../../hooks';
import { AddressProps } from '../../../../../types';
import useStyles from './AddressStyles';

const ServiceRequestAddress: React.FC<AddressProps> = ({
  control,
  errors,
  onChangeFn,
  addressName,
  disableStatus,
  fields,
}: any) => {
  const { authStore }: any = useCustomStore();
  const [state, setState] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const authState = authStore.statesListOption;
    const stateData = toJS(authState);
    if (stateData && stateData.length) {
      setState(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.statesListOption]);

  const checkStateExists = (value: string) => {
    if (!value) {
      return '';
    }
    let valueDefine: any = '';
    if (toJS(authStore.statesListOption).find((e: any) => e.code === value)) {
      valueDefine = value;
    } else {
      valueDefine = '';
    }
    return valueDefine;
  };

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ md: 3, sm: 2, xs: 1 }}
      minHeight={'100%'}>
      <Grid item md={6} sm={12} xs={12}>
        <Box className={classes.labels}>
          {addressName === 'physicalAddress'
            ? 'Physical Address *'
            : 'Mailing Address *'}
        </Box>
        <Controller
          control={control}
          defaultValue=""
          name={fields.address}
          render={({
            field: { onChange, value, name, ref, onBlur },
            formState,
          }) => {
            return (
              <InputText
                isFormSubmittedOnce={!!formState.submitCount}
                inputRefObj={ref}
                errors={errors?.[fields.address]?.message || ''}
                name={name}
                isRefreshValue={true}
                value={value}
                onChangeText={(val) => {
                  onChange(val);
                  onChangeFn(addressName, 'address', val);
                }}
                onBlurText={onBlur}
                isEditable={addressName === 'mailAddress' && disableStatus}
                className={classes.selectClassName}
                type={'text'}
                variant="standard"
              />
            );
          }}
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <Box className={classes.labels}>City *</Box>
        <Controller
          control={control}
          defaultValue=""
          name={fields.city}
          render={({
            field: { onChange, value, name, ref, onBlur },
            formState,
          }) => {
            return (
              <InputText
                isFormSubmittedOnce={!!formState.submitCount}
                inputRefObj={ref}
                errors={errors?.[fields.city]?.message || ''}
                name={name}
                isRefreshValue={true}
                value={value}
                onChangeText={(val) => {
                  onChange(val);
                  onChangeFn(addressName, 'city', val);
                }}
                onBlurText={onBlur}
                isEditable={addressName === 'mailAddress' && disableStatus}
                className={classes.selectClassName}
                type={'text'}
                variant="standard"
              />
            );
          }}
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <Box className={classes.labels}>State *</Box>
        <Controller
          control={control}
          defaultValue=""
          name={fields.state}
          render={({
            field: { onChange, value, name, ref, onBlur },
            formState,
          }) => {
            return (
              <FormControl>
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors?.[fields.state]?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={checkStateExists(value)}
                  onBlurFn={onBlur}
                  onChangeText={(val) => {
                    onChange(val);
                    onChangeFn(addressName, 'state', val);
                  }}
                  size={'small'}
                  isEditable={addressName === 'mailAddress' && disableStatus}
                  selectClassName={classes.selectClassName}
                  placeHolder={'Select'}
                  sortByOptionDisable={true}
                  optionsList={state.map((stateval: any, index: number) => ({
                    label: stateval.fullName,
                    value: stateval.code,
                  }))}
                  // {
                  //   <>
                  //     <option value="" className={classes.optionsClassName}>
                  //       Select
                  //     </option>
                  //     {state.map((stateval: any, index: number) => (
                  //       <option
                  //         key={index}
                  //         value={stateval.code}
                  //         className={classes.optionsClassName}>
                  //         {stateval.fullName}
                  //       </option>
                  //     ))}
                  //   </>
                  // }
                />
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <Box className={classes.labels}>ZIP *</Box>
        <Controller
          control={control}
          defaultValue=""
          name={fields.zipcode}
          render={({
            field: { onChange, value, name, ref, onBlur },
            formState,
          }) => {
            return (
              <InputText
                isFormSubmittedOnce={!!formState.submitCount}
                inputRefObj={ref}
                errors={errors?.[fields.zipcode]?.message || ''}
                name={name}
                isRefreshValue={true}
                value={value}
                onChangeText={(val) => {
                  if (/[^\d]/gim.test(val)) {
                    return;
                  }
                  onChange(val);
                  onChangeFn(addressName, 'zipcode', val);
                }}
                onBlurText={onBlur}
                isEditable={addressName === 'mailAddress' && disableStatus}
                className={classes.selectClassName}
                type={'text'}
                variant="standard"
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
export default observer(ServiceRequestAddress);
