import { makeAutoObservable, toJS } from 'mobx';

import { API_LIMIT } from '../config';
import {
  AdminUsersSearchFilters,
  AdminUsersSearchFormInputs,
  UsersSearchFilters,
  UsersSearchFormInputs,
} from '../types';
import { RootStore } from '.';

export class AdminUsersStore {
  private _limit = API_LIMIT;
  private _status = 'Users';
  private _adminList: Array<any> = [];
  private _showLoader = false;
  private _hasMoreResults = false;
  private _nextLink = '';
  private _filter: UsersSearchFilters = {
    limit: API_LIMIT,
    name: '',
    nextLink: this.NextLink,
  };
  private _sortBy = 'name';
  private _sortDirection = 'asc';

  private _adminUsersList: Array<any> = [];
  private _adminRolesList: Array<any> = [];
  private _adminUsersShowLoader = false;
  private _adminUsershasMoreResults = false;
  private _adminUsersNextLink = '';
  private _adminUsersFilter: AdminUsersSearchFilters = {
    email: '',
    firstName: '',
    lastName: '',
    limit: API_LIMIT,
    nextLink: this.AdminUsersNextLink,
  };
  private _adminRoleDetails: any = {
    manageAdmins: false,
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setAdminUsersHasMoreResults(hasMoreResults: boolean) {
    this._adminUsershasMoreResults = hasMoreResults;
  }

  setFilters(data: UsersSearchFilters) {
    this._filter = data;
  }

  setAdminList(data: Array<any>) {
    this._adminList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setStatus(value: string) {
    this._status = value;
  }

  setAdminApiReset(hasMoreResult = false) {
    this.setNextLink('');
    this.setHasMoreResults(hasMoreResult);
    this.setAdminList([]);
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  setAdminUsersList(data: Array<any>) {
    this._adminUsersList = data;
  }

  setAdminRolesList(role: Array<any>) {
    this._adminRolesList = role;
  }

  setAdminUsersShowLoader(value: boolean) {
    this._adminUsersShowLoader = value;
  }

  setAdminUsersNextLink(link: string) {
    this._adminUsersNextLink = link;
  }

  setAdminUsersApiReset() {
    this.setAdminUsersNextLink('');
    this.setAdminUsersHasMoreResults(true);
    this.setAdminUsersList([]);
  }

  setNewRoleDetails(key: any, value: any) {
    const existedData: any = {
      ...toJS(this._adminRoleDetails),
    };
    existedData[key] = value;
    this._adminRoleDetails = existedData;
  }

  get getNewRoleDetails() {
    return { ...toJS(this._adminRoleDetails) };
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get Filters() {
    return this._filter;
  }

  get AdminList() {
    return this._adminList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Status() {
    return this._status;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get AdminUsersList() {
    return this._adminUsersList;
  }

  get AdminRolesList() {
    return this._adminRolesList;
  }

  get AdminUsersNextLink() {
    return this._adminUsersNextLink;
  }

  get AdminUsersShowLoader() {
    return this._adminUsersShowLoader;
  }

  get AdminUsersFilters() {
    return this._adminUsersFilter;
  }

  get AdminUsersHasMoreResults() {
    return this._adminUsershasMoreResults;
  }

  async fetchAdminsBySorting() {
    this.setAdminApiReset(false);
    return await this.fetchAdmins();
  }

  async fetchAdmins() {
    const params: any = {};
    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['sortBy'] = this.SortBy;
    params['sortDirection'] = this.SortDirection;

    const response = await this.rootStore.apiStore.adminUsersApi.getAdminList(
      params,
      this._status,
    );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._adminList.concat(results);
      this.setAdminList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchAdminsBySearchBased(searchVal: UsersSearchFormInputs) {
    this.setAdminApiReset(true);
    const params: {
      limit: number;
      nextLink: string;
      name: string;
      sortBy: string;
      sortDirection: string;
    } = {
      limit: this._limit,
      nextLink: '',
      ...searchVal,
      sortBy: this.SortBy,
      sortDirection: this.SortDirection,
    };

    const response =
      await this.rootStore.apiStore.adminUsersApi.getAdminsBySearch(params);
    if (response.isOk()) {
      const { response: results } = response.value;
      this.setAdminList(results);
    }
    return response;
  }

  async fetchAdminUsers(searchVal: AdminUsersSearchFormInputs) {
    const params: any = {};
    params['limit'] = this._limit;
    params['nextLink'] = this._adminUsersNextLink;
    params['sortBy'] = this.SortBy === 'name' ? 'Full Name' : this.SortBy;
    params['sortDirection'] = this.SortDirection;
    if (searchVal !== undefined) {
      params['firstName'] =
        searchVal.firstName !== '' ? searchVal.firstName : '';
      params['lastName'] = searchVal.lastName !== '' ? searchVal.lastName : '';
      params['email'] = searchVal.email !== '' ? searchVal.email : '';
      params['contactType'] =
        searchVal.contactType !== '' ? searchVal.contactType : '';
    }

    const response =
      await this.rootStore.apiStore.adminUsersApi.getAdminUsersList(params);
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._adminUsersList.concat(results);

      this.setAdminUsersList(fullResult);
      this.setAdminUsersNextLink(nextLink);
      this.setAdminUsersHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchAdminRoles() {
    this.setAdminRolesList([]);
    const params: any = {};
    params['limit'] = this._limit;
    params['nextLink'] = '';

    const rolesResponse =
      await this.rootStore.apiStore.adminUsersApi.getAdminRolesList(params);
    if (rolesResponse.isOk()) {
      const { response: roleResults } = rolesResponse.value;
      const rolesResult = this._adminRolesList.concat(roleResults);
      this.setAdminRolesList(rolesResult);
    }
    return rolesResponse;
  }

  async fetchAdminUsersBySearchBased(searchVal: AdminUsersSearchFormInputs) {
    // this.setAdminApiReset(true);
    // const params: {
    //   limit: number;
    //   nextLink: string;
    //   firstName: string;
    //   lastName: string;
    //   email: string;
    // } = {
    //   limit: this._limit,
    //   nextLink: '',
    //   ...searchVal,
    // };
    const params: any = {};
    params['limit'] = this._limit;
    params['nextLink'] = this._adminUsersNextLink;
    params['sortBy'] = this.SortBy === 'name' ? 'Full Name' : this.SortBy;
    params['sortDirection'] = this.SortDirection;
    if (searchVal !== undefined) {
      params['firstName'] =
        searchVal.firstName !== '' ? searchVal.firstName : '';
      params['lastName'] = searchVal.lastName !== '' ? searchVal.lastName : '';
      params['email'] = searchVal.email !== '' ? searchVal.email : '';
      params['contactType'] =
        searchVal.contactType !== '' ? searchVal.contactType : '';
    }

    const response =
      await this.rootStore.apiStore.adminUsersApi.getAdminsUsersBySearch(
        params,
      );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._adminUsersList.concat(results);

      this.setAdminUsersList(fullResult);
      this.setAdminUsersNextLink(nextLink);
      this.setAdminUsersHasMoreResults(nextLink ? true : false);
      // this.setAdminUsersList(results);
    }
    return response;
  }

  async addAdminUsers(selectedList: any) {
    const response =
      await this.rootStore.apiStore.adminUsersApi.createAdminUsers(
        selectedList,
      );
    if (response.isOk()) {
      this.setAdminUsersApiReset();
    }
    return response;
  }

  async deleteAdmin(id: string) {
    const response = await this.rootStore.apiStore.adminUsersApi.deleteAdmin(
      id,
    );
    if (response.isOk()) {
      this.setAdminApiReset(false);
    }
    return response;
  }

  async updateAdmin(user: any) {
    const response = await this.rootStore.apiStore.adminUsersApi.updateAdmin(
      user,
    );
    if (response.isOk()) {
      this.setAdminApiReset(false);
    }
    return response;
  }

  async addAdminRoles(data: any) {
    const response =
      await this.rootStore.apiStore.adminUsersApi.createAdminRoles(data);
    if (response.isOk()) {
      this.setAdminApiReset(false);
    }
    return response;
  }

  async resendPortalInvitation(data: any) {
    const response =
      await this.rootStore.apiStore.adminUsersApi.resendPortalInvitation(data);
    if (response.isOk()) {
      this.setAdminApiReset(false);
    }
    return response;
  }
}
