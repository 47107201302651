import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    height: '44px',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  cancelLink: {
    color: '#979797',
    cursor: 'pointer',
    fontSize: '1rem',
    letterSpacing: '0',
  },
  center: {
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      padding: '0.5rem 1rem 1rem 1rem',
      width: '30%',
    },
  },
  dialogAction: {
    padding: '0 1.5rem',
    width: '100%',
  },
  dialogContent: {
    width: '100%',
  },
  dialogTitle: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '1.375rem',
    letterSpacing: 0,
  },
  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    margin: '0.6rem 0',
    marginLeft: 'unset !important',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  headerContainer: {
    color: '#241A2E',
    flex: 'auto',
    fontFamily: 'FiraSans-Regular',
    fontSize: '1.1rem',
    fontWeight: 600,
    letterSpacing: '1.2px',
    lineHeight: '26px',
    textTransform: 'uppercase',
  },
  headerSubtext: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.9rem',
    letterSpacing: 0,
    lineHeight: '15px',
    width: '95%',
  },
  headerWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoTitle: {
    '& span': {
      fontWeight: '600 !important',
    },
    color: '#979598 !important',
    fontFamily: 'Fira Sans-Regular',
    fontSize: '0.8rem !important',
    fontWeight: 500,
    lineHeight: '24px !important',
  },
  selectClassName: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },
  submitBtnTxt: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.9375rem',
    fontWeight: '500',
    height: '22px',
    letterSpacing: '1px',
    lineHeight: '22px',
    textAlign: 'center',
  },
});
export default useStyles;
