import { createContext } from 'react';

import { NewDriverSetupInputs } from '../../../../../types';

const NewDriverCtx = createContext<{
  data: NewDriverSetupInputs;
  [key: string]: any;
} | null>(null);

export default NewDriverCtx;
