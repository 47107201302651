import { makeAutoObservable, toJS } from 'mobx';

import { RootStore } from '.';

export class DataQchallengeStore {
  private _initialApiOptionData: any = {};
  private _dataQChallenge: any = {
    options: [],
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get dataQChallengeDetails(): any {
    return { ...toJS(this._dataQChallenge) };
  }

  resetDataQchallengeStore() {
    this._dataQChallenge = {
      options: [...this._initialApiOptionData],
    };
  }

  async addDataQChallenge(contactData: any) {
    const { dataQChallenges }: any = this.dataQChallengeDetails;

    const violationArray: any = [];
    [...toJS(dataQChallenges)].forEach((dataQ: any) => {
      if (dataQ && dataQ.selected) {
        violationArray.push({
          id: dataQ.id,
        });
      }
    });

    const finalData: any = {
      contactId: contactData.contactId,
      violations: violationArray,
    };

    const addResponse =
      await this.rootStore.apiStore.dataQChallengeApiStore.addDataQChallenge(
        finalData,
      );
    return addResponse;
  }

  setDataQChallengesCheckToggle(
    index: number,
    checkstatus: boolean,
    id: string,
  ) {
    this._dataQChallenge.dataQChallenges[index] = {
      ...this._dataQChallenge.dataQChallenges[index],
      id: id,
      selected: checkstatus,
    };
  }

  async getInitialData() {
    const initialResponse =
      await this.rootStore.apiStore.dataQChallengeApiStore.getInitialData();
    if (initialResponse.isOk()) {
      const dataQChallenges: any = initialResponse.value;
      if (dataQChallenges && Array.isArray(initialResponse.value)) {
        this._initialApiOptionData = dataQChallenges;
        this._dataQChallenge = {
          dataQChallenges,
        };
      }
    }

    return initialResponse;
  }
}
