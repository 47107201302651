import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { EquipmentDTO } from '../../types';
import { RootStore } from '..';

export class EquipmentApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getEquipmentList = (params: {
    status: string;
    sortBy?: string;
    sortDirection?: string;
    todoId?: string;
    requestType?: string;
  }) => {
    const restParams = this.objClean({ ...params });
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Equipment;

    // This is static response once you have the api remove this code and uncomment the commented code
    return this.rootStore.apiStore.call<EquipmentDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  getEquipmentBySearch = (params: {
    status: string;
    sortBy?: string;
    sortDirection?: string;
    todoId?: string;
    requestType?: string;
    simplexId: string;
    unitNumber: string;
    vinNumber: string;
  }) => {
    const restParams = this.objClean({ ...params });

    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Equipment;

    // This is static response once you have the api remove this code and uncomment the commented code
    return this.rootStore.apiStore.call<EquipmentDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }
}
