import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { DisplayBoardSearchParam } from '../types';
import { userNotificationDataAccess } from '../utils/helper';
import { RootStore } from '.';

export class DisplayNotification {
  private _dialogueData: {
    loading: boolean;
    open: boolean;
    notifications: any[];
    initialLoading: boolean;
  } = {
    initialLoading: true,
    loading: false,
    notifications: [],
    open: false,
  };
  private _searchParamDetails: {
    lastSeenOn: string;
    onlySplash: boolean;
  } = {
    lastSeenOn: '',
    onlySplash: true,
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get getDialogueOpenStatus() {
    return this._dialogueData.open;
  }

  get getloadingEffectStatus() {
    return this._dialogueData.loading;
  }

  get getUsersNotificationDetails() {
    const datas: any = [...this._dialogueData.notifications];
    return datas;
  }

  get getNotificationSearchParam(): DisplayBoardSearchParam {
    let lastSeenOnValue: any = userNotificationDataAccess(
      'loggedIn',
      this.rootStore.authStore.getTokenDetail?.id,
    );

    if (lastSeenOnValue) {
      lastSeenOnValue = moment(lastSeenOnValue).toISOString();
    }

    const returnData: any = {
      onlySplash: this._searchParamDetails.onlySplash,
    };
    if (lastSeenOnValue) {
      returnData.lastSeenOn = lastSeenOnValue;
    }
    return returnData;
  }

  setToggleDialogueDisplayStatus(status: any = false) {
    if (this._dialogueData.initialLoading || this._dialogueData.open) {
      if (!this._dialogueData.initialLoading) {
        this._dialogueData.open = false;
      }
      // this._dialogueData.open = status;
      if (status) {
        return this.getInitialNotificationData(status);
      }
    }
  }

  setInitialLoadingStatus(status: any = true) {
    this._dialogueData.initialLoading = status;
  }

  setLoadingEffect(status: any = false) {
    this._dialogueData.loading = status;
  }

  setNotificationDetails(data: any = [], status?: boolean) {
    this._dialogueData.notifications = data;
    if (status) {
      userNotificationDataAccess(
        'loggedIn',
        this.rootStore.authStore.getTokenDetail?.id,
        moment().format(),
      );
    }
  }

  async getInitialNotificationData(status: any = false) {
    this.setLoadingEffect(true);
    const initialData: any =
      await this.rootStore.apiStore.notificationApiStore.getInitialData({
        ...this.getNotificationSearchParam,
      });
    this.setLoadingEffect(false);

    if (initialData.isOk()) {
      const notifications: any = [...initialData.value.response];
      if (status && notifications.length) {
        this._dialogueData.open = status;
        this.setNotificationDetails(notifications, status);
      } else {
        this._dialogueData.open = false;
        this.setNotificationDetails([]);
        this.setToggleDialogueDisplayStatus();
      }
    }

    if (initialData.isErr()) {
      this.setNotificationDetails([]);
      this.setToggleDialogueDisplayStatus();
    }
    this._dialogueData.initialLoading = false;

    return initialData;
  }
}
