import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { CompanyOrDriverDTO } from '../../types';
import { RootStore } from '..';

// type SubmitRequestResponse = {
//   value: string;
// };
export class DriverApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getDriverList = (params: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Driver;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  getDriversBySearch = (params: {
    limit: number;
    nextLink: string;
    status: string;
    name: string;
    driverType: string;
    simplexId: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Driver;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  submitRequest = async (data: any) => {
    const {
      NewDriver: { Endpoint, Method },
    } = ApiRoutes.Driver;
    try {
      const response = await this.rootStore.apiStore.call({
        data,
        method: Method,
        url: Endpoint,
      });
      return response;
    } catch (err) {
      return err ? false : true;
    }
  };

  updateRequest = async (data: any, id: string) => {
    const {
      UpdateDriver: { Endpoint, Method },
    } = ApiRoutes.Driver;
    try {
      const response = await this.rootStore.apiStore.call({
        data,
        method: Method,
        url: Endpoint(id),
      });
      return response;
    } catch (err) {
      return err ? false : true;
    }
  };

  updateMedicalRequest = async (data: any, id: string) => {
    const {
      UpdateMedical: { Endpoint, Method },
    } = ApiRoutes.Driver;
    try {
      const response = await this.rootStore.apiStore.call({
        data,
        method: Method,
        url: Endpoint(id),
      });
      return response;
    } catch (err) {
      return err ? false : true;
    }
  };

  exportDrivers = (params: {
    nextLink: string;
    status: string;
    name: string;
    driverType: string;
    simplexId: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      Export: { Endpoint, Method },
    } = ApiRoutes.Driver;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      responseType: 'blob',
      url: Endpoint,
    });
  };

  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }
}
