import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
// import { saveAs } from 'file-saver';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TrainingCertificateViewDialog from '../../../../components/TrainingDialog/TrainingCertificateViewDialog';
import TrainingReAssignDialog from '../../../../components/TrainingReAssignDialog/TrainingReAssignDialog';
import VideoStreamDialog from '../../../../components/VideoStreamDialog/VideoStreamDialog';
import { ApiRoutes } from '../../../../config/ApiRoutes';
import { Config } from '../../../../config/Constants';
import { useCustomStore } from '../../../../hooks';
import { AccessTypes, TrainingCompletedSortOptions } from '../../../../types';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import CompletedHeader from './CompletedHeader';
import CompletedList from './CompletedList';
import useStyles from './CompletedStyles';

const Completed: React.FC = () => {
  const { trainingStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [moreCompletedData, setMoreCompletedData] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] = useState<any>(null);
  const [reAssignType, setReAssignType] = useState<'current' | 'other'>(
    'current',
  );
  const [showReAssignDialog, setShowReAssignDialog] = useState<boolean>(false);
  const [showVideoStreamDialog, setShowVideoStreamDialog] =
    useState<boolean>(false);
  const [showCertificateDialog, setShowCertificateDialog] =
    useState<any>(false);
  const [certificateUrl, setCertificateUrl] = useState<string>('');

  const getTrainingCompletedData = useCallback(async () => {
    trainingStore.setTrainingCompletedLoader(true);
    const resp = await trainingStore.getInitialTrainingCompleted();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Completed Assignments',
        SnackBarConfig('e'),
      );
    }
    trainingStore.setTrainingCompletedLoader(false);
  }, [enqueueSnackbar, trainingStore]);

  const getMoreTrainingCompletedData = useCallback(async () => {
    setMoreCompletedData(true);
    const resp = await trainingStore.getTrainingCompleted();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Completed Assignments',
        SnackBarConfig('e'),
      );
    }
    setMoreCompletedData(false);
  }, [enqueueSnackbar, trainingStore]);

  // const onDownloadCertificate = useCallback(
  //   async (assignment: any) => {
  //     trainingStore.setDownloadTrainingCertificate({
  //       id: assignment.id,
  //       loader: true,
  //     });
  //     const resp = await trainingStore.getTrainingCertificate(assignment.spURL);
  //     if (resp.isErr()) {
  //       enqueueSnackbar('Unable to download certificate', SnackBarConfig('e'));
  //     }
  //     if (resp.isOk()) {
  //       const {
  //         assignedContact: { name = 'Driver' },
  //         training: { title = 'Training' },
  //       } = assignment;
  //       saveAs(resp.value, `${name as string}-${title as string}`);
  //     }
  //     trainingStore.setDownloadTrainingCertificate({
  //       id: '',
  //       loader: false,
  //     });
  //   },
  //   [enqueueSnackbar, trainingStore],
  // );

  const onDownloadCertificate = useCallback(
    (assignment: any) => {
      trainingStore.setDownloadTrainingCertificate({
        id: assignment.id,
        loader: true,
      });
      const tokens = authStore.tokens;
      const url = `${Config.API_URL}${
        ApiRoutes.Training.GetCertificateForAnAssignment.Endpoint
      }?spURL=${assignment.spURL as string}&accessToken=${
        tokens?.accessToken ?? ''
      }`;
      window.open(url);
      trainingStore.setDownloadTrainingCertificate({
        id: '',
        loader: false,
      });
    },
    [authStore.tokens, trainingStore],
  );

  const onViewCertificate = useCallback(
    (assignment: any) => {
      trainingStore.setDownloadTrainingCertificate({
        id: assignment.id,
        loader: true,
      });
      const tokens = authStore.tokens;
      const url = `${Config.API_URL}${
        ApiRoutes.Training.GetCertificateForAnAssignment.Endpoint
      }?spURL=${assignment.spURL as string}&accessToken=${
        tokens?.accessToken ?? ''
      }`;
      setCertificateUrl(url);
      trainingStore.setDownloadTrainingCertificate({
        id: '',
        loader: false,
      });
      setCertificateUrl(url);
      setShowCertificateDialog(true);
    },
    [authStore.tokens, trainingStore, setShowCertificateDialog],
  );

  const onCloseCertifcateDialog = (refresh: boolean) => {
    setCertificateUrl('');
    setShowCertificateDialog(false);
  };

  const trainingCompletedSortBy = (
    columnName: TrainingCompletedSortOptions,
  ) => {
    trainingStore.setTrainingCompletedFilters({
      sortBy: columnName,
      sortChanged: true,
      sortDirection:
        trainingStore.TrainingCompletedFilters.sortDirection === 'asc'
          ? 'desc'
          : 'asc',
    });
    getTrainingCompletedData();
  };

  useEffect(() => {
    getTrainingCompletedData();
  }, [getTrainingCompletedData]);

  // Show Assignment Re-Assignment dialog
  const onReAssignAssignment = (
    assignment: any,
    reAssign: 'current' | 'other',
  ) => {
    if (assignment) {
      setReAssignType(reAssign);
      setSelectedAssignment(assignment);
      setShowReAssignDialog(true);
    }
  };

  // Show training assignment video
  const onVideoStream = (assignment: any) => {
    if (assignment) {
      setSelectedAssignment(assignment);
      setShowVideoStreamDialog(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {trainingStore.TrainingCompletedLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{ maxHeight: 'calc(100vh - 125px)' }}
            id="training-completed-table">
            <InfiniteScroll
              dataLength={trainingStore.TrainingCompletedData.length}
              style={{ overflow: 'unset' }}
              next={getMoreTrainingCompletedData}
              hasMore={trainingStore.HasMoreTrainingCompleted}
              scrollableTarget={'training-completed-table'}
              loader={
                moreCompletedData && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <CompletedHeader
                    trainingStore={trainingStore}
                    authStore={authStore}
                    trainingCompletedSortBy={trainingCompletedSortBy}
                  />
                </TableHead>
                <TableBody>
                  {trainingStore.TrainingCompletedData &&
                  trainingStore.TrainingCompletedData.length > 0 ? (
                    <CompletedList
                      trainingStore={trainingStore}
                      authStore={authStore}
                      onDownloadCertificate={onDownloadCertificate}
                      onReAssignAssignment={onReAssignAssignment}
                      onVideoStream={onVideoStream}
                      onViewCertificate={onViewCertificate}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={8}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
      {showReAssignDialog && (
        <TrainingReAssignDialog
          isOpen={showReAssignDialog}
          assignment={selectedAssignment}
          reAssignType={reAssignType}
          closeDialog={(refresh: boolean) => {
            setShowReAssignDialog(false);
            refresh && getTrainingCompletedData();
          }}
        />
      )}
      {showVideoStreamDialog && (
        <VideoStreamDialog
          showDialog={showVideoStreamDialog}
          title={selectedAssignment?.training?.title || ''}
          videoUrl={selectedAssignment?.training?.fileUrl || ''}
          hasDocument={selectedAssignment?.training?.hasDocument || false}
          allowControls={authStore.UserAccessType === AccessTypes.CompanyAdmin}
          closeDialog={() => {
            setShowVideoStreamDialog(false);
          }}
        />
      )}
      {showCertificateDialog && (
        <TrainingCertificateViewDialog
          showDialog={showCertificateDialog}
          closeDialog={onCloseCertifcateDialog}
          url={certificateUrl}
        />
      )}
    </Box>
  );
};

export default observer(Completed);
