import React, { useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import Dashboard from '../../screens/private/Dashboard';
import Documents from '../../screens/private/Documents/documents';
import Fleet from '../../screens/private/Fleet/Fleets';
import Home from '../../screens/private/Home/Home';
import Notifications from '../../screens/private/Notifications/notifications';
import Profile from '../../screens/private/Profile/Profile';
import Reports from '../../screens/private/Reports/reports';
import SelectedTrendDetails from '../../screens/private/SelectedTrendDetails';
import DriverServiceRequest from '../../screens/private/ServiceRequest/Driver/driverServiceRequest';
import PhysicalExamLocation from '../../screens/private/ServiceRequest/Driver/pages/PhysicalExamLocation/PhysicalExamLocation';
import CompanyInfo from '../../screens/private/Settings/CompanyInfo/CompanyInfo';
import NotificationSettings from '../../screens/private/Settings/Notification/notificationSettings';
import Settings from '../../screens/private/Settings/settings';
import Todos from '../../screens/private/Todos/Todos';
import Training from '../../screens/private/Training';
import LoginAsImpersonate from '../../screens/public/LoginAsImpersonate/LoginAsImpersonate';
import { PublicRoute } from '../Constants';
import { PrivateRoutes } from '../index';
// import {
//   DashboardRoutes,
//   DocumentsRoutes,
//   FleetRoutes,
//   NotificationRoutes,
//   ReportsRoutes,
//   ServicereportRoutes,
//   SettingsRoute,
//   TodosRoutes,
// } from '../Constants';
// import Servicerequest from '../../screens/private/ServiceRequest/servicerequest';

const DriverRoutes: React.FC = () => {
  const {
    DASHBOARD,
    FLEETDOCUMENTS,
    NOTIFICATIONS,
    TODOS,
    FLEET,
    DOCUMENTS,
    REPORTS,
    SETTINGSROUTE,
    SERVICEREQUEST,
    PROFILE,
    DRIVERSERVICEREQUEST,
    VIOLATIONS_OF,
    CRASHES_OF,
    INSPECTIONS_OF,
    TRAINING,
  } = PrivateRoutes;

  const { PHYSICALEXAMLOCATION } = DRIVERSERVICEREQUEST;
  const [title, setTitle] = useState('');
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const todoId = searchParams.get('id') || '';

  return (
    <Routes>
      <Route path={'/'} element={<Navigate to={DASHBOARD} />} />
      <Route
        path={PublicRoute.LOGINASIMPERSONATE}
        element={<LoginAsImpersonate />}
      />
      <Route
        path={`${DASHBOARD as string}/*`}
        element={
          <Home>
            <Dashboard />
          </Home>
        }
      />
      <Route
        path={NOTIFICATIONS}
        element={
          <Home
            title={'Notifications'}
            globalSearch={true}
            globalFilter={false}>
            <Notifications />
          </Home>
        }
      />

      <Route
        path={TODOS}
        element={
          <Home
            {...(state || todoId ? { backButton: true } : {})}
            title={'To-Dos'}
            globalSearch={false}
            globalFilter={true}>
            <Todos />
          </Home>
        }
      />

      <Route
        path={FLEET}
        element={
          <Home title={'Fleet'} globalSearch={true} globalFilter={false}>
            <Fleet />
          </Home>
        }
      />

      <Route
        path={DOCUMENTS}
        element={
          <Home
            title={'Documents'}
            globalSearch={false}
            globalFilter={true}
            documentsFilter={true}>
            <Documents />
          </Home>
        }
      />

      <Route
        path={FLEETDOCUMENTS}
        element={
          <Home
            title={'Documents'}
            globalSearch={false}
            globalFilter={true}
            documentsFilter={true}
            showInDocumentStoreHeaderName={true}>
            <Documents
              {...{ backButton: true, backUrl: FLEET, type: 'unit' }}
            />
          </Home>
        }
      />

      <Route
        path={REPORTS}
        element={
          <Home title={'Reports'} globalSearch={true} globalFilter={false}>
            <Reports />
          </Home>
        }
      />

      <Route
        path={SETTINGSROUTE.SETTINGS}
        element={
          <Home title={'Settings'}>
            <Settings />
          </Home>
        }
      />
      <Route
        path={SETTINGSROUTE.COMPANYINFO}
        element={
          <Home title={'Settings'}>
            <CompanyInfo />
          </Home>
        }
      />
      <Route
        path={SETTINGSROUTE.NOTIFICATION}
        element={
          <Home title={'Settings'}>
            <NotificationSettings {...{ headerName: 'Driver' }} />
          </Home>
        }
      />

      <Route
        path={PHYSICALEXAMLOCATION}
        element={
          <Home>
            <DriverServiceRequest
              tabValue={'1'}
              childComponent={<PhysicalExamLocation />}
            />
          </Home>
        }
      />
      <Route
        path={SERVICEREQUEST.BASEROUTE}
        element={
          <Home>
            <Settings />
          </Home>
        }
      />
      <Route
        path={PROFILE}
        element={
          <Home title="Profile">
            <Profile />
          </Home>
        }
      />
      <Route
        path={VIOLATIONS_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={CRASHES_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={INSPECTIONS_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />

      <Route
        path={`${TRAINING.BASE_ROUTE as string}/*`}
        element={
          <Home globalSearch={true} globalFilter={false} title={'Training'}>
            <Training />
          </Home>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default DriverRoutes;
