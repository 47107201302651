import { FormControl, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

import { useCustomStore } from '../../../../../hooks';
import { SelectInput } from '../../../../Forms/selectInput';

type Props = {
  classes: any;
  control: Control;
  name: string;
  label: string;
};

const SelectStates: React.FC<Props> = ({ classes, control, name, label }) => {
  const { authStore } = useCustomStore();
  const states = useMemo(() => {
    return authStore.statesListOption;
  }, [authStore]);
  return (
    <FormControl fullWidth>
      <Typography
        component={'h3'}
        sx={{
          color: '#FFFFFF',
          fontFamily: 'FiraSans-Medium',
          fontSize: '15px',
          fontWeight: '500',
          height: '22px',
          letterSpacing: '1px',
          lineHeight: '22px',
          textTransform: 'capitalize',
          width: '166px',
        }}>
        {label}
      </Typography>
      <Typography component={'div'} className={classes.borderBottom}>
        <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
          <Controller
            control={control}
            defaultValue=""
            name={name}
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectInput
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  inputRefObj={ref}
                  size={'small'}
                  onChangeText={(e: any) => {
                    onChange(e);
                  }}
                  isEditable={false}
                  selectClassName={classes.selectWhiteBg}
                  placeHolder={'Select'}
                  optionsList={states.map((value: any) => ({
                    label: value.code,
                    optionClassName: classes.optionsClassName,
                    value: value.id,
                  }))}
                />
              );
            }}
          />
        </Grid>
      </Typography>
    </FormControl>
  );
};

export default SelectStates;
