import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { AdminNotificationSearchParam } from '../../types';
import { RootStore } from '..';

export class AdminNotificationApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  getInitialData(params: AdminNotificationSearchParam) {
    const {
      Get: { Endpoint, Method },
    } = ApiRoutes.Notification;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: { ...params },
      url: Endpoint,
    });
  }
}
