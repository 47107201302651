import makeStyles from '@mui/styles/makeStyles';

import LoginBanner from '../../assets/images/login-banner.svg';

const useStyles = makeStyles((theme) => ({
  backtoLogin: {
    color: '#0061FF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.8px',
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  backtoLoginArrow: {
    bottom: 60,
    position: 'relative',
    right: 60,
  },
  buttonStyles: {
    '& .MuiButton-endIcon': {
      marginRight: 0,
      position: 'absolute',
      right: 18,
    },
    '&.MuiButton-root': {
      backgroundColor: '#DEC330',
      borderRadius: '4px',
      boxShadow: 'none',
      color: theme.palette.common.white,
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.9375rem',
      fontWeight: '500',
      height: 44,
      letterSpacing: '1px',
      lineHeight: '22px',
      marginBottom: 24,
      marginTop: 31,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: '#DEC330',
      boxShadow: 'none',
    },
  },
  forgotPassword: {
    color: '#0061FF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '0.8px',
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  inputStyles: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#f3f3f3 !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '0.875rem !important',
      fontWeight: ' 500 !important',
      letterSpacing: '0 !important',
      // opacity: '0.43 !important',
    },
    '& .MuiFilledInput-root:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:hover': {
      border: 'none',
      outline: 'none',
    },
    '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& Mui-error': {
      height: 0,
    },
    '& input': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      padding: '11px',
    },
    '& label': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.75rem',
      height: 24,
      letterSpacing: 0,
    },
  },
  labelText: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    height: '24px',
    letterSpacing: 0,
    lineHeight: '24px',
    textAlign: 'left',
  },
  labels: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
    textAlign: 'left',
  },
  lineCopy2: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    marginTop: '30px',
    width: '355px',
  },
  loader: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  loginSimplexFooter: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    letterSpacing: 0,
    lineHeight: '24px',
    marginTop: '22px',
    textAlign: 'center',
  },
  logoNav2: {
    height: '61px',
    width: '217.79px',
  },
  options: {
    backgroundColor: '#F8F8FA',
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.875rem',
    fontWeight: 500,
    height: '20px',
    letterSpacing: 0,
    lineHeight: '20px',
    width: '159px',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  rectangle: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
    padding: '35px',
    textAlign: 'center',
    width: '414px',
  },
  selectInputStyles: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#f3f3f3 !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '0.875rem !important',
      fontWeight: ' 500 !important',
      letterSpacing: '0 !important',
      // opacity: '0.43 !important',
    },
    '& .MuiFilledInput-root:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:hover': {
      border: 'none',
      outline: 'none',
    },
    '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& input': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
    },
    '& label': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.75rem',
      height: 24,
      letterSpacing: 0,
    },
  },
  sliderHome: {
    '& .MuiGrid-root': {
      // paddingTop: '8rem ',
    },
    background: `url(${LoginBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    justifyContent: 'unset !important',
    minHeight: '100%',
    width: '100%',
  },
}));

export default useStyles;
