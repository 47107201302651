import React from 'react';

const EquipmentInspection: React.FC = () => {
  return (
    <div>
      <div>
        Equipment inspections are conducted in our Miami, FL location at
      </div>
      <div style={{ marginTop: '0.4rem' }}>
        <span style={{ textDecoration: 'underline solid #000' }}>
          7500 NW 52nd St., Suite 100, Miami, FL 33166
        </span>
      </div>
      <div style={{ marginTop: '1.5rem' }}>
        To schedule an inspection, please contact our office at{' '}
        <span style={{ color: '#0061FF' }}>305-859-4592</span>.
      </div>
    </div>
  );
};

export default EquipmentInspection;
