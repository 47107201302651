import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import SortingIcon from '../../../../components/SortingIcon/SortingIcon';
import useStyles from '../documentsStyle';

type TableHeaderProps = {
  sortBY: (src: any) => void;
  storeFilter: any;
};

const TableHeader: React.FC<TableHeaderProps> = (props: TableHeaderProps) => {
  const { sortBY, storeFilter } = props;
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {[
          { keyValue: 'fileName', name: 'File Name' },
          { keyValue: 'tags', name: 'Tags' },
          { keyValue: 'fileSize', name: 'File Size' },
          { keyValue: 'uploadedOn', name: 'Uploaded' },
          { keyValue: 'fileName', name: '.' },
        ].map((ele, index, arr) => (
          <TableCell key={index} className={classes.tableHeaderBg}>
            <Grid item xs={12}>
              <Typography
                component={'div'}
                noWrap
                style={{ alignItems: 'center', display: 'flex' }}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#979598',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '0.75rem !important',
                    letterSpacing: 0,
                    lineHeight: '14px',
                    ...(index === arr.length - 1
                      ? { visibility: 'hidden' }
                      : {}),
                  }}>
                  {ele.name}
                </Typography>
                {index === arr.length - 1 ? (
                  ''
                ) : (
                  <SortingIcon
                    {...{
                      sortBY,
                      sortByName: ele.keyValue,
                      store: storeFilter,
                    }}
                  />
                )}
              </Typography>
            </Grid>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default observer(TableHeader);
