import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    '&.MuiCard-root': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #d8d8d8',
      borderRadius: 'unset',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.08)',
      cursor: 'pointer',
      marginBottom: '20px',
    },
  },
  divider: {
    '&.MuiDivider-root': {
      border: '0.5px solid #E2E2E2',
      boxSizing: 'border-box',
      height: '1px',
    },
  },
  headingName: {
    '&.MuiTypography-root': {
      color: '#241A2E',
      cursor: 'default',
      fontFamily: 'FiraSans-SemiBold',
      fontSize: '18px',
      letterSpacing: 0,
      lineHeight: '29px',
      padding: '16px 15px',
    },
  },
  listName: {
    '&.MuiTypography-root': {
      '&:hover': {
        backgroundColor: '#f3f3f3',
      },
      alignItems: 'center',
      color: '#241A2E',
      display: 'flex',
      fontFamily: 'FiraSans-Regular',
      fontSize: '18px',
      justifyContent: 'space-between',
      letterSpacing: 0,
      padding: '12px 19px',
      textDecoration: 'auto',
    },
  },
  loader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: '203px',
  },
  notificationContainer: {
    margin: 'auto',
    marginTop: '40px',
    width: '500px',
  },
  switch: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          border: 0,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        color: '#fff',
        transform: 'translateX(16px)',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        border: '6px solid #fff',
        color: '#33cf4d',
      },
      margin: 2,
      padding: 0,
      transitionDuration: '300ms',
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      height: 22,
      width: 22,
    },
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      borderRadius: 26 / 2,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
    height: 26,
    padding: 0,
    width: 42,
  },
  switchWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
  },
}));
export default useStyles;
