import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import SortingIcon from '../../../components/SortingIcon/SortingIcon';
import useStyles from './driverStyle';

const DriverHeader: React.FC<{
  driverHeader: any;
  driverStore: any;
}> = ({ driverHeader, driverStore }) => {
  const classes = useStyles();
  const sortBY = async (e: any) => {
    driverStore.setSortBy(e);
    driverStore.setSortDirection(
      driverStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    driverStore.setShowLoader(true);
    await driverStore.fetchDriversBySorting();
    driverStore.setShowLoader(false);
  };

  return (
    <TableRow>
      {driverHeader.map((header: any, index: number) => (
        <TableCell key={index} className={classes.tableHeaderBg}>
          <Grid item xs={12}>
            <Typography
              component={'div'}
              style={{ alignItems: 'center', display: 'flex' }}>
              <Typography
                component={'div'}
                sx={{
                  color: header.name === 'name' ? '#241A2E' : '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                }}>
                {header.lable}
              </Typography>

              {header !== '' && header.name !== '' && (
                <SortingIcon
                  {...{ sortBY, sortByName: header.name, store: driverStore }}
                />
              )}
            </Typography>
          </Grid>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default observer(DriverHeader);
