import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
// import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EyeViewIcon from '../../../assets/images/eye-grey.svg';
import ValueCheck from '../../../assets/images/value-check.svg';
import ValueX from '../../../assets/images/value-x.svg';
import NavRoutes from '../../../config/routes/NavRoutes';
import { AuthStore } from '../../../store/auth';
// import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import useStyles from './driverStyle';

type ListPropsTypes = {
  driverLists: any;
  onTerminate: (driver: any, id: string) => void;
  driverStore: any;
  authStore: AuthStore;
  onClickViewTodos: (id: string, name: string, type: string) => unknown;
};

const DriverLists = (props: ListPropsTypes): React.ReactElement => {
  const { authStore, driverLists, onTerminate, driverStore, onClickViewTodos } =
    props;
  const navigate = useNavigate();
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleDriverOption = (event: any, contactId: string) => {
    const obj: any = {};

    if (tooltipIsOpen[contactId]) {
      obj[contactId] = false;
      setAnchorEl(null);
    } else {
      obj[contactId] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const showDataOfContent = useCallback(
    (text: string | null, textColor = '#241A2E', showMissingLabel = false) => {
      return text ? (
        <Typography
          noWrap
          sx={{
            color: textColor,
            fontFamily: 'FiraSans-Regular',
            fontSize: '16px',
            height: '19px',
            letterSpacing: 0,
            lineHeight: '19px',
          }}>
          {text}
        </Typography>
      ) : showMissingLabel ? (
        <Typography
          noWrap
          sx={{
            color: '#A70D0D !important',
            fontFamily: 'FiraSans-Regular',
            fontSize: '16px',
            height: '19px',
            letterSpacing: 0,
            lineHeight: '19px',
          }}>
          MISSING
        </Typography>
      ) : (
        <Typography
          noWrap
          sx={{
            color: '#241A2E',
            fontFamily: 'FiraSans-Regular',
            fontSize: '16px',
            height: '19px',
            letterSpacing: 0,
            lineHeight: '19px',
            textAlign: 'center',
          }}>
          -
        </Typography>
      );
    },
    [],
  );

  return driverLists.map((driver: any, index: number) => (
    <TableRow
      key={index}
      className={classes.rowHeight}
      style={{ minHeight: '80px' }}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Semibold',
              fontSize: '16px',
              fontWeight: 600,
              height: '23px',
              letterSpacing: 0,
              lineHeight: '23px',
            }}>
            {showDataOfContent(driver.name)}
          </Typography>
          {showDataOfContent(driver.simplexId)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(driver.terminal)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(driver.driverType)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(driver.dob)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(driver.dlType)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(driver.dlState, true)}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(driver.dlNumber, true)}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {showDataOfContent(
            driver.hireDate
              ? moment(driver.hireDate).format('M/DD/YYYY')
              : null,
            true,
          )}
        </Grid>
      </TableCell>

      {driverStore.Status !== 'Terminated' && (
        <>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              {showDataOfContent(
                driver.mvrExpirationDate
                  ? moment(driver.mvrExpirationDate).format('M/DD/YYYY')
                  : null,
                moment(driver.mvrExpirationDate) < moment() &&
                  driver.dlType !== 'Mexican'
                  ? '#A70D0D'
                  : '#241A2E',
                driver.dlType !== 'Mexican' ? true : false,
              )}
            </Grid>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              {showDataOfContent(
                driver.clearingHouseExpirationDate
                  ? moment(driver.clearingHouseExpirationDate).format(
                      'M/DD/YYYY',
                    )
                  : null,
                moment(driver.clearingHouseExpirationDate) < moment() &&
                  driver.dlType !== 'Non-CDL'
                  ? '#A70D0D'
                  : '#241A2E',
                driver.dlType !== 'Non-CDL' ? true : false,
              )}
            </Grid>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              {showDataOfContent(
                driver.cdlExpirationDate
                  ? moment(driver.cdlExpirationDate).format('M/DD/YYYY')
                  : null,
                moment(driver.cdlExpirationDate) < moment()
                  ? '#A70D0D'
                  : '#241A2E',
                true,
              )}
            </Grid>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              {showDataOfContent(
                driver.medicalCardExpirationDate
                  ? moment(driver.medicalCardExpirationDate).format('M/DD/YYYY')
                  : null,
                moment(driver.medicalCardExpirationDate) < moment()
                  ? '#A70D0D'
                  : '#241A2E',
                true,
              )}
            </Grid>
          </TableCell>
        </>
      )}

      {driverStore.Status === 'Terminated' && (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Grid item xs={12} md={12} lg={12}>
            {showDataOfContent(
              driver.terminationDate
                ? moment(driver.terminationDate).format('M/DD/YYYY')
                : null,
              true,
            )}
          </Grid>
        </TableCell>
      )}
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {driver.preEmploymentDrugTestOnFile ? 'Yes' : 'No'}
        </Grid>
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          {driver.dqStatus ? (
            driver.dqStatus == 'COMPLIANT' ? (
              <img src={ValueCheck} alt="success" />
            ) : (
              // <Typography
              //   noWrap
              //   className={driver.dqStatus !== '' ? classes.compliant : ''}>
              //   {driver.dqStatus}
              // </Typography>
              <img src={ValueX} alt="failure" />
              // <Typography
              //   noWrap
              //   className={driver.dqStatus !== '' ? classes.compliantNot : ''}>
              //   {driver.dqStatus}
              // </Typography>
            )
          ) : (
            <Typography
              noWrap
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                height: '19px',
                letterSpacing: 0,
                lineHeight: '19px',
                textAlign: 'center',
              }}>
              -
            </Typography>
          )}
        </Grid>
      </TableCell>

      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <img
            src={EyeViewIcon}
            alt="view"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVERS}/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}/${driver.contactId}?name=${driver.name}`,
              )
            }
          />

          {/* <Typography
            className={classes.view}
            noWrap
            onClick={() =>
              navigate(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVERS}/${NavRoutes.PRIVATE.LEFT_NAV.DOCUMENTS}/${driver.contactId}?name=${driver.name}`,
              )
            }>
            View
          </Typography> */}
        </Grid>
      </TableCell>
      {driverStore.Status !== 'Terminated' && (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Grid item style={{ position: 'relative' }}>
            <IconButton
              onClick={(event) => handleDriverOption(event, driver.contactId)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              className="Driver-popclass"
              open={tooltipIsOpen[driver.contactId]}
              onClose={(event) => handleDriverOption(event, driver.contactId)}
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}>
              <Box
                sx={{
                  backgroundColor: '#241A2E',
                  borderRadius: '8px',
                  boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                  minHeight: '100.9px',
                  padding: '0.5rem 0',
                  position: 'relative',
                  width: '207px',
                }}>
                <div className="arrow">.</div>
                <Typography
                  component={'div'}
                  className="n-pop-btn"
                  onClick={() => {
                    // eslint-disable-next-line
                    const route = `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVER}/${NavRoutes.PRIVATE.DRIVERS.PROFILE}/${driver.contactId}`;
                    navigate(route);
                  }}>
                  Edit/View Profile
                </Typography>
                <Typography
                  component={'div'}
                  className={'n-pop-btn '.concat(
                    authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                      ? 'disable'
                      : '',
                  )}
                  {...(authStore.getTokenDetail &&
                  authStore.getTokenDetail?.adminId
                    ? {}
                    : {
                        onClick: () => onTerminate(driver, driver.contactId),
                      })}>
                  Terminate
                </Typography>
                <Typography
                  component={'div'}
                  className={'n-pop-btn '.concat(
                    authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                      ? 'disable'
                      : '',
                  )}
                  {...(authStore.getTokenDetail &&
                  authStore.getTokenDetail?.adminId
                    ? {}
                    : {
                        onClick: (e: any) => {
                          if (driver && driver.email) {
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            window.open(`mailto:${driver.email}`);
                          }
                          e.preventDefault();
                        },
                      })}>
                  Email
                </Typography>
                <Typography
                  component={'div'}
                  className={'n-pop-btn '.concat(
                    authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                      ? 'disable'
                      : '',
                  )}
                  {...(authStore.getTokenDetail &&
                  authStore.getTokenDetail?.adminId
                    ? {}
                    : {
                        onClick: () =>
                          onClickViewTodos(
                            driver.contactId,
                            driver.name,
                            driver.driverType,
                          ),
                      })}>
                  To-Dos
                </Typography>
              </Box>
            </Popover>
          </Grid>
        </TableCell>
      )}
    </TableRow>
  ));
};

export default observer(DriverLists);
