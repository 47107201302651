import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    height: '44px',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  cancelLink: {
    color: '#979797',
    cursor: 'pointer',
    fontSize: '1rem',
    letterSpacing: '0',
  },

  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      height: 'auto',
    },
  },
  dialogBody: {
    backgroundColor: '#ffffff !important',
    maxHeight: 'calc(100vh - 200px)',
    minHeight: '200px',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
  },
  dialogHead: {
    alignItems: 'center',
    backgroundColor: '#EFEFEF',
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between',
    padding: '0 1.5rem',
  },
  dialogclose: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px !important',
    textDecoration: 'underline solid #0061FF',
  },
  dialogtitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '22px !important',
  },

  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    width: '100%',
  },
  flexA: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '0.5rem !important',
  },
  hIcon: {
    height: '16px',
    width: '16px',
  },
  headerActionBtn: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  headerContainer: {
    flex: 'auto',
  },
  headerWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    // width: '95%',
  },
  loader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '80px',
    marginTop: '80px',
    width: '100%',
  },
  noRecordsFound: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    paddingTop: 20,
    textAlign: 'center',
    width: '100%',
  },
  notDetails: {
    borderBottom: '1px solid #EAEAEA',
    cursor: 'pointer',
    padding: '1.5rem',
  },
  notDisc: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Light !important',
    fontSize: '1rem !important',
    lineHeight: '19px !important',
    marginBottom: '1rem !important',
    marginTop: '0.5rem !important',
  },
  notLink: {
    color: '#0061FF !important',
    fontSize: '0.87rem !important',
    marginTop: '1rem !important',
    textDecoration: 'underline solid #0061FF',
    wordBreak: 'break-all',
  },
  notTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1.12rem !important',
  },

  searchBtn: {
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    height: '44px',
  },
  searchText: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.9375rem',
    fontWeight: 500,
    height: '22px',
    letterSpacing: '1px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  selectBtn: {
    border: '1px solid #CBCBCB',
    borderRadius: '8px',
    boxSizing: 'border-box',
    height: '45px',
    // width: '116px',
  },
  selectClassName: {
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    paddingLeft: '10px !important',
  },
  selectedBtn: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    height: '45px',
    // width: '115px',
  },
});
export default useStyles;
