import { Grid, styled } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Trailers from '../../../assets/images/Trailers.svg';
import Trucks from '../../../assets/images/Trucks.svg';
import { PrivateRoutes } from '../../../config';
import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import TodosGraph from './TodosGraphs';
import TopTrendingChart from './TopTrendingChart';
import Trend from './Trends/Trend';

const Main = styled('main')(() => ({
  boxSizing: 'border-box',
  display: 'grid',
  gridGap: 20,
  gridTemplateColumns: '49% 49%',
  height: '100vh',
  padding: 10,
}));

const Article = styled('article')(() => ({
  justifyContent: 'space-between',
  padding: 10,
}));

const ArticleX = styled('article')(() => ({
  position: 'relative',
  zIndex: 20,
}));

const Column = styled('div')<{ border?: boolean }>(({ border = false }) => ({
  borderRight: `${border ? 1 : 0}px solid #e0e0e0`,
  paddingRight: border ? 20 : 10,
}));

type Props = {
  role: string;
};

/* TODO: Refactor Trailer and Truck count sections into components and reuse here and
 * Highlights.
 */
const tabs: Top10Trending[] = ['Violations', 'Inspections', 'Accidents'];

const AlternateDashboard: React.FC<Props> = ({ role }) => {
  const navigate = useNavigate();
  const {
    dashboardStore,
    authStore: { NavbarAccess: access },
    trendsStore,
  } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const { Data, DataLoader, Todos } = dashboardStore;

  const getTodos = useCallback(async () => {
    dashboardStore.setShowLoader(true);
    const getFleetsResp = await dashboardStore.fetchDashboardTodos();
    dashboardStore.setShowLoader(false);

    if (getFleetsResp.isErr()) {
      enqueueSnackbar(String(getFleetsResp.error.message), SnackBarConfig('e'));
    }
  }, [dashboardStore, enqueueSnackbar]);

  const getData = useCallback(async () => {
    dashboardStore.setDataLoader(true);
    const getFleetsResp = await dashboardStore.fetchDashboardData();
    dashboardStore.setDataLoader(false);

    if (getFleetsResp.isErr()) {
      enqueueSnackbar(String(getFleetsResp.error.message), SnackBarConfig('e'));
    }
  }, [dashboardStore, enqueueSnackbar]);

  useEffect(() => {
    dashboardStore.resetDashboardData();
    getTodos();
    getData();
  }, [getTodos, getData, dashboardStore]);

  const handleChartClick = (
    label = '',
    trend = '',
    query = '',
    index = 0,
    cardLabel = '',
  ) => {
    const trendType_ = trend === 'Accidents' ? 'Crashes' : trend;
    const trendHistory = toJS(trendsStore.trendHistory);
    const selectedHistory = trendHistory[cardLabel];
    if (query === 'top10Trending') {
      navigate(
        `/${trendType_}-of?${encodeURI(
          `type=${label}&range=${selectedHistory?.range}`,
        )}`,
      );
      return;
    }
  };
  return (
    <Main id="dashboard-trends">
      <Column border>
        <ArticleX>
          <Trend
            tabs={[...tabs]}
            name="Top 10 Trending"
            renderchart={(data, label: string) => (
              <TopTrendingChart
                onClick={(index) => {
                  const id = toJS(data)[index].keyCode;
                  handleChartClick(
                    id,
                    label,
                    'top10Trending',
                    index,
                    'Top 10 Trending',
                  );
                }}
                trendingData={data}
              />
            )}
          />
        </ArticleX>
        {role === 'ownerOperator' && access?.dashboard?.fleetCount && (
          <Article className="flex-row">
            <div
              className="violation-card drivers-card border-gray"
              onClick={() =>
                navigate(PrivateRoutes.FLEET, {
                  state: { showBackBtn: true },
                })
              }>
              <div className="wheel-icon">
                <img src={Trucks} />
              </div>
              <p className="drivers-card-label">Power Units</p>
              <p className="drivers-card-value">
                {DataLoader ? '-' : Data?.value?.unitDriverCount?.trucks}
              </p>
            </div>
            <div
              className="violation-card drivers-card border-gray"
              onClick={() =>
                navigate(PrivateRoutes.FLEET, {
                  state: { showBackBtn: true },
                })
              }>
              <div className="wheel-icon">
                <img src={Trailers} />
              </div>
              <p className="drivers-card-label">Trailers</p>
              <p className="drivers-card-value">
                {DataLoader ? '-' : Data?.value?.unitDriverCount?.trailers}
              </p>
            </div>
          </Article>
        )}
      </Column>
      {(access?.dashboard?.companyTodo ||
        access?.dashboard?.driverTodo ||
        access?.dashboard?.fleetTodo ||
        access?.dashboard?.trainingTodo) && (
        <Column>
          {access?.dashboard?.companyTodo && (
            <Grid item lg={1} width="410px">
              <div className="basics-card">
                <div className="card">
                  <p className="card-header">Company To-Do&#39;s</p>
                  <p
                    className="card-link"
                    onClick={() =>
                      navigate(PrivateRoutes.TODOS, {
                        state: { todoType: 'Company' },
                      })
                    }>
                    View All
                  </p>
                </div>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  paddingBottom="10px">
                  <TodosGraph
                    key={'company'}
                    todosData={Todos?.value?.company}
                  />
                </Grid>
              </div>
            </Grid>
          )}
          {access?.dashboard?.driverTodo && (
            <Grid item lg={1} width="410px">
              <div className="basics-card">
                <div className="card">
                  <p className="card-header">Driver To-Do&#39;s</p>
                  <p
                    className="card-link"
                    onClick={() =>
                      navigate(PrivateRoutes.TODOS, {
                        state: { todoType: 'Driver' },
                      })
                    }>
                    View All
                  </p>
                </div>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  paddingBottom="10px">
                  <TodosGraph key={'driver'} todosData={Todos?.value?.driver} />
                </Grid>
              </div>
            </Grid>
          )}

          <br />
          {access?.dashboard?.fleetTodo && (
            <Grid item lg={1} width="410px">
              <div className="basics-card">
                <div className="card">
                  <p className="card-header">Fleet To-Do&#39;s</p>
                  <p
                    className="card-link"
                    onClick={() =>
                      navigate(PrivateRoutes.TODOS, {
                        state: { todoType: 'Fleet' },
                      })
                    }>
                    View All
                  </p>
                </div>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  paddingBottom="10px">
                  <TodosGraph key={'fleet'} todosData={Todos?.value?.fleet} />
                </Grid>
              </div>
            </Grid>
          )}

          <br />
          {access?.dashboard?.trainingTodo && (
            <Grid item lg={1} width="410px">
              <div className="basics-card">
                <div className="card">
                  <p className="card-header">Training To-Do&#39;s</p>
                  <p
                    className="card-link"
                    onClick={() =>
                      navigate(PrivateRoutes.TODOS, {
                        state: { todoType: 'Training' },
                      })
                    }>
                    View All
                  </p>
                </div>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  paddingBottom="10px">
                  <TodosGraph
                    key={'training'}
                    todosData={Todos?.value?.training}
                  />
                </Grid>
              </div>
            </Grid>
          )}
        </Column>
      )}
    </Main>
  );
};

export default observer(AlternateDashboard);
