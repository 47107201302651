import { makeAutoObservable } from 'mobx';

// import { API_LIMIT } from '../config';
import { FleetSearchFilters, FleetSearchFormInputs } from '../types';
import { RootStore } from '.';

export class FleetStore {
  private _status = 'Active';
  private _fleetList: Array<any> = [];
  private _countPowerUnit: number | undefined = undefined;
  private _countTrailer: number | undefined = undefined;
  private _showLoader = false;
  private _hasMoreResults = false;
  private _nextLink = '';
  private _equipmentFromFleet: any = null;
  private _filter: FleetSearchFilters = {
    simplexId: '',
    unitNumber: '',
    unitType: '',
    vinNumber: '',
  };
  private _sortBy = 'unitNumber';
  private _sortDirection = 'asc';
  private _sortInfo = '';

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setFilters(data: FleetSearchFilters) {
    this._filter = data;
  }

  setFleetList(data: Array<any>) {
    this._fleetList = data;
  }

  setCount(
    countPowerUnit: number | undefined,
    countTrailer: number | undefined,
  ) {
    this._countPowerUnit = countPowerUnit;
    this._countTrailer = countTrailer;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setStatus(value: string) {
    this._status = value;
  }

  setFleetApiReset(hasMoreResult = false) {
    this.setNextLink('');
    this.setHasMoreResults(hasMoreResult);
    this.setFleetList([]);
    this.setCount(undefined, undefined);
    this.setFilters({
      ...this.Filters,
      expirationEndDate: '',
      expirationStartDate: '',
      nextLink: this.NextLink,
      simplexId: '',
      unitNumber: '',
      unitType: '',
      vinNumber: '',
    });
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  setEquipmentFromFleet(data: any = null) {
    this._equipmentFromFleet = data;
  }

  setSortInfo(data: string) {
    this._sortInfo = data;
  }

  get Count() {
    return {
      countPowerUnit: this._countPowerUnit,
      countTrailer: this._countTrailer,
    };
  }

  get EquipmentFromFleet() {
    return this._equipmentFromFleet;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get Filters() {
    return this._filter;
  }

  get FleetList() {
    return this._fleetList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Status() {
    return this._status;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get SortInfo() {
    return this._sortInfo;
  }

  async fetchFleetsBySorting(sortChanged = true) {
    // this.setFleetApiReset(false);
    return await this.fetchFleets(sortChanged);
  }

  async fetchFleets(sortChanged?: boolean) {
    const params: any = {};
    this.Filters.vinNumber != ''
      ? (params['vinNumber'] = this.Filters.vinNumber)
      : null;
    this.Filters.unitNumber != ''
      ? (params['unitNumber'] = this.Filters.unitNumber)
      : null;
    this.Filters.simplexId != ''
      ? (params['simplexId'] = this.Filters.simplexId)
      : null;
    this.Filters.unitType != ''
      ? (params['unitType'] = this.Filters.unitType)
      : null;
    params['status'] = this._status;
    params['sortBy'] = this.SortBy;
    params['sortDirection'] = this.SortDirection;
    params['nextLink'] = sortChanged ? '' : this.NextLink;
    params.sortChanged = !!sortChanged;

    const response = await this.rootStore.apiStore.fleetApi.getFleetList(
      params,
    );
    if (response.isOk()) {
      const {
        response: results,
        nextLink,
        countPowerUnit,
        countTrailer,
      } = response.value;
      const fullResult = this._fleetList.concat(results);

      this.setFleetList(fullResult);
      if (this._status !== 'Terminated') {
        this.setCount(countPowerUnit, countTrailer);
      } else {
        if (
          this._countPowerUnit === undefined &&
          this._countTrailer === undefined
        )
          this.setCount(countPowerUnit, countTrailer);
      }
      this.setNextLink(nextLink ? nextLink : '');
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchFleetsBySearchBased(searchVal: FleetSearchFormInputs) {
    // this.setFleetApiReset(true);
    const params: {
      status: string;
      vinNumber: string;
      unitNumber: string;
      simplexId: string;
      sortBy: string;
      sortDirection: string;
      unitType: string;
      sortChanged: boolean;
    } = {
      status: this._status,
      ...searchVal,
      sortBy: this.SortBy,
      sortChanged: false,
      sortDirection: this.SortDirection,
    };

    const response = await this.rootStore.apiStore.fleetApi.getFleetsBySearch({
      ...params,
      nextLink: this.NextLink,
    });
    if (response.isOk()) {
      const {
        response: results,
        countPowerUnit,
        nextLink,
        countTrailer,
      } = response.value;
      const fullResult = this._fleetList.concat(results);

      this.setFleetList(fullResult);
      this.setCount(countPowerUnit, countTrailer);
      this.setNextLink(nextLink ? nextLink : '');
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async exportFleets(searchVal: FleetSearchFormInputs) {
    const params: {
      nextLink: string;
      status: string;
      vinNumber: string;
      unitNumber: string;
      simplexId: string;
      sortBy: string;
      sortDirection: string;
      unitType: string;
    } = {
      nextLink: '',
      status: this._status,
      ...searchVal,
      sortBy: this.SortBy,
      sortDirection: this.SortDirection,
    };

    const response = await this.rootStore.apiStore.fleetApi.exportFleets(
      params,
    );
    return response;
  }
}
