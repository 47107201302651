import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TrainingAssignDialog from '../../../../../components/TrainingAssignDialog/TrainingAssignDialog';
import { useCustomStore } from '../../../../../hooks';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import AccidentsHeader from './AccidentsHeader';
import AccidentsList from './AccidentsList';
import useStyles from './AccidentsStyles';

const Accidents: React.FC = () => {
  const { trainingStore } = useCustomStore();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
  const [moreTrainingAccidents, setMoreTrainingAccidents] =
    useState<boolean>(false);
  const [selectedTrainingAccident, setSelectedTrainingAccident] =
    useState<any>();

  const getTrainingAccidents = useCallback(async () => {
    trainingStore.setTrainingAccidentsLoader(true);
    const resp = await trainingStore.getInitialTrainingAccidents();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Accidents',
        SnackBarConfig('e'),
      );
    }
    trainingStore.setTrainingAccidentsLoader(false);
  }, [enqueueSnackbar, trainingStore]);

  const getMoreTrainingAccidents = useCallback(async () => {
    setMoreTrainingAccidents(true);
    const resp = await trainingStore.getTrainingAccidents();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Accidents',
        SnackBarConfig('e'),
      );
    }
    setMoreTrainingAccidents(false);
  }, [enqueueSnackbar, trainingStore]);

  const trainingAccidentsSortBy = (columnName: string) => {
    trainingStore.setTrainingAccidentFilters({
      sortBy: columnName,
      sortChanged: true,
      sortDirection:
        trainingStore.TrainingAccidentFilters.sortDirection === 'asc'
          ? 'desc'
          : 'asc',
    });
    getTrainingAccidents();
  };

  useEffect(() => {
    getTrainingAccidents();
  }, [getTrainingAccidents]);

  const onAssignAccident = (accident: any) => {
    if (accident && accident.driver) {
      setOpenAssignDialog(true);
      setSelectedTrainingAccident(accident);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {trainingStore.TrainingAccidentsLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{ maxHeight: 'calc(100vh - 125px)' }}
            id="training-accidents-table">
            <InfiniteScroll
              dataLength={trainingStore.TrainingAccidentsData.length}
              style={{ overflow: 'unset' }}
              next={getMoreTrainingAccidents}
              hasMore={trainingStore.HasMoreTrainingAccidents}
              scrollableTarget={'training-accidents-table'}
              loader={
                moreTrainingAccidents && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <AccidentsHeader
                    trainingAccidentsSortBy={trainingAccidentsSortBy}
                    trainingStore={trainingStore}
                  />
                </TableHead>
                <TableBody>
                  {trainingStore.TrainingAccidentsData.length > 0 ? (
                    <AccidentsList
                      trainingStore={trainingStore}
                      onAssignAccident={onAssignAccident}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={8}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
      {openAssignDialog && selectedTrainingAccident && (
        <TrainingAssignDialog
          isOpen={openAssignDialog}
          id={selectedTrainingAccident.accidentId}
          contactId={selectedTrainingAccident.driver.id}
          driverName={selectedTrainingAccident.driver.name}
          type="accident"
          closeDialog={(refresh) => {
            setOpenAssignDialog(false);
            refresh && getTrainingAccidents();
          }}
        />
      )}
    </Box>
  );
};

export default observer(Accidents);
