import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { TrendTodoSearchFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

const TODO_TYPES = [
  'Cab Card/ IRP Registration (F)',
  'IRP Permit (C)',
  'Local Registration (F)',
  'Trailer Registration (F)',
  'UCR (C)',
  'Road Taxes (F)',
  // 'Annual Report (C)',
  'IFTA Permit (C)',
  'Fuel Tax Filing (C)',
  'NM Permit (F)',
  'NY Permit (F)',
  'OR Monthly Tax Filing (C)',
  'OR Permit (F)',
  'DOT Biennal Update (C)',
  'DOT VMT Update (C)',
  'AR Report (C)',
  'Hazmat Permit (C)',
  'SCAC (C)',
  // 'CA/MCP (C)',
  "Driver's License (D)",
  'Medical Card (D)',
  'MVR (D)',
  'Clearinghouse Query (D)',
  'Driver Application (D)',
  'Annual Inspection (F)',
  'Drug Test (D)',
  'PSP (D)',
  'Background Check (D)',
  'Pre-Employment MVR (D)',
  'Pre-Employment PSP Authorization (D)',
  'Pre-Employment Drug Test (D)',
  'Pre-Employment Clearinghouse Query (D)',
  'Pre-Employment Medical Card (D)',
  'Pre-Employment Driver Application (D)',
  'Pre-Employment Background Check (D)',
  'Pre-Employment Driver License (D)',
  'MVR Authorization (D)',

  'All (T)',
  'Video-based (T)',
  'Document-based (T)',
];

const getIsFormEmpty = (formValue = {}, filter: Record<string, any>) => {
  const tempFilter = { ...filter };
  if (tempFilter.todoCategory.length > 0) {
    return false;
  }
  delete tempFilter?.todoCategory;
  delete tempFilter?.limit;
  const allValues = Object.values({ ...formValue, ...tempFilter });
  if (!allValues.length) {
    return true;
  } else {
    const nonEmptyValue = allValues.find((value) => value);
    if (nonEmptyValue) return false;
    else return true;
  }
};
const processParams = (obj: Record<string, any>) => {
  const tempObj: Record<string, any> = {};
  for (const key in obj) {
    if (obj[key]) {
      if (key.includes('Date')) {
        tempObj[key] = moment(obj[key]).format('MM/DD/YYYY');
      } else if (key === 'primaryDriverName') {
        tempObj.driverName = obj[key];
      } else {
        tempObj[key] = obj[key];
      }
    }
  }
  return tempObj;
};

type todoFormTypes = {
  classes: any;
  todoController: any;
  todoSubmit: any;
  filter: any;
  resetFilter: any;
  searchPage: any;
  setFilters: (src: any) => void;
  submitTodoFilter: any;
  todoStore: any;
  userStore: any;
  authStore: any;
  todoReset: any;
  onCategoryChange: (categories: string[]) => unknown;
  onClose: () => unknown;
  searchTypeOfTodoForm: string;
};

const TrendsTodoSearch: React.FC<todoFormTypes> = (props: todoFormTypes) => {
  const { trainingStore, trendsStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const {
    classes,
    searchPage,
    userStore,
    onCategoryChange,
    onClose,
    searchTypeOfTodoForm,
  } = props;

  const [selectSearch, setSelectSearch] = useState('');
  const [todoTypes, setTodoTypes] = useState<string[]>(TODO_TYPES);
  const [assignees, setAssignees] = useState<any[]>([]);
  const [reasons, setReasons] = useState<any[]>([]);

  const { TrendsTodoFilters } = trendsStore;

  const { control, reset, getValues, setValue } =
    useForm<TrendTodoSearchFormInputs>({
      criteriaMode: 'all',
      defaultValues: {
        firstName: TrendsTodoFilters.firstName,
        lastName: TrendsTodoFilters.lastName,
        make: TrendsTodoFilters.make,
        searchType: TrendsTodoFilters.searchType,
        simplexId: TrendsTodoFilters.simplexId,
        todoCategory: TrendsTodoFilters.todoCategory,
        todoDueIn: TrendsTodoFilters.todoDueIn,
        todoTrainingAssignedTo: TrendsTodoFilters.todoTrainingAssignedTo,
        todoTrainingReason: TrendsTodoFilters.todoTrainingReason,
        todoType: TrendsTodoFilters.todoType,
        unitNumber: TrendsTodoFilters.unitNumber,
        vinNumber: TrendsTodoFilters.vinNumber,
        year: TrendsTodoFilters.year,
      },
      mode: 'all',
      reValidateMode: 'onChange',
    });

  const todoCategories: any = [
    {
      index: 0,
      show: userStore.UserData.accessType === 'companyAdmin' ? true : false,
      title: 'Company',
    },
    {
      index: 1,
      show:
        userStore.UserData.accessType === 'companyAdmin' ||
        userStore.UserData.accessType === 'ownerOperator'
          ? true
          : false,
      title: 'Driver',
    },
    {
      index: 2,
      show:
        userStore.UserData.accessType === 'companyAdmin' ||
        userStore.UserData.accessType === 'ownerOperator'
          ? true
          : false,
      title: 'Fleet',
    },
    {
      index: 3,
      show: true,
      title: 'Training',
    },
  ];

  const todoDueIn = [
    {
      name: 'Less Than 5 Days',
      value: 'Less Than 5 Days',
    },
    {
      name: '5-10 Days',
      value: '10-5 Days',
    },
    {
      name: '11-15 Days',
      value: '15-11 Days',
    },
    {
      name: '16-30 Days',
      value: '30-16 Days',
    },
    {
      name: '31-45 Days',
      value: '45-31 Days',
    },
    {
      name: '46-75 Days',
      value: '75-46 Days',
    },
    {
      name: 'Past Due',
      value: 'past due',
    },
    {
      name: 'Missing',
      value: 'missing',
    },
  ];

  const filterTodoTypes = (arr: string[]) => {
    let temp: string[] = [];
    if (arr.includes('Fleet'))
      temp = [
        ...temp,
        ...TODO_TYPES.filter((todoType) => todoType.includes('(F)')),
      ];
    if (arr.includes('Driver'))
      temp = [
        ...temp,
        ...TODO_TYPES.filter((todoType) => todoType.includes('(D)')),
      ];
    if (arr.includes('Company'))
      temp = [
        ...temp,
        ...TODO_TYPES.filter((todoType) => todoType.includes('(C)')),
      ];
    if (arr.includes('Training'))
      temp = [
        ...temp,
        ...TODO_TYPES.filter((todoType) => todoType.includes('(T)')),
      ];
    if (!arr.length) temp = [...TODO_TYPES];
    return temp;
  };

  const setCategories = useCallback(
    (value: string) => {
      const arr = Array.isArray(TrendsTodoFilters.todoCategory)
        ? TrendsTodoFilters.todoCategory.slice()
        : [];
      if (arr.some((e: any) => e === value)) {
        const index = arr.findIndex((e) => e === value);
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
      trendsStore.setTrendsTodoFilters({
        todoCategory: arr,
      });
      setValue('todoCategory', arr);
      onCategoryChange(arr);
      setTodoTypes(filterTodoTypes(arr));
      if (!arr.includes('Training')) {
        trendsStore.setTrendsTodoFilters({
          todoTrainingAssignedTo: '',
          todoTrainingReason: '',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [TrendsTodoFilters.todoCategory, trendsStore, onCategoryChange],
  );

  const getAllTrainingAssignees = useCallback(async () => {
    if (trainingStore.TrainingAssigneesData.length > 0) {
      setAssignees(trainingStore.TrainingAssigneesData);
      setValue(
        'todoTrainingAssignedTo',
        TrendsTodoFilters.todoTrainingAssignedTo || '',
      );
    } else {
      trainingStore.setTrainingAssigneesLoader(true);
      const resp = await trainingStore.getAllUsersForTraining({
        limit: '5000',
        searchText: '',
        userType: 'backOfficePersonnel',
      });
      if (resp.isOk()) {
        const { response } = resp.value;
        setAssignees(response);
        trainingStore.setTrainingAssigneesData(response);
        setValue(
          'todoTrainingAssignedTo',
          TrendsTodoFilters.todoTrainingAssignedTo || '',
        );
      } else {
        enqueueSnackbar(
          'Unable to fetch Training Assignees',
          SnackBarConfig('e'),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, trainingStore]);

  const getTrainingAssignmentReasons = useCallback(async () => {
    trainingStore.setTrainingAssignmentReasonsLoader(true);
    const resp = await trainingStore.getTrainingAssignmentReasons({
      showSystemReason: true,
    });
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Assignment Reasons',
        SnackBarConfig('e'),
      );
    } else {
      setReasons(trainingStore.TrainingAssignmentReasonsData);
      setValue(
        'todoTrainingReason',
        TrendsTodoFilters.todoTrainingReason || '',
      );
    }
    trainingStore.setTrainingAssignmentReasonsLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, trainingStore]);

  useEffect(() => {
    getAllTrainingAssignees();
    getTrainingAssignmentReasons();
  }, [getAllTrainingAssignees, getTrainingAssignmentReasons, trainingStore]);

  useEffect(() => {
    if (
      TrendsTodoFilters.year != '' ||
      TrendsTodoFilters.make != '' ||
      TrendsTodoFilters.vinNumber != '' ||
      TrendsTodoFilters.unitNumber != ''
    ) {
      setSelectSearch('Unit');
    } else {
      setSelectSearch('Driver');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  useEffect(() => {
    setSelectSearch(searchTypeOfTodoForm);
    if (
      TrendsTodoFilters.todoCategory &&
      Array.isArray(TrendsTodoFilters.todoCategory) &&
      TrendsTodoFilters.todoCategory.length > 0
    ) {
      setTodoTypes(filterTodoTypes(TrendsTodoFilters.todoCategory));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTypeOfTodoForm]);

  const isFormEmpty = getIsFormEmpty(getValues(), TrendsTodoFilters);

  const fromTrendsByDriver = location.search.includes('driver=');
  const fromTrendsByUnit = location.search.includes('vin=');

  const sendRequests = () => {
    (async () => {
      if (fromTrendsByDriver) {
        await trendsStore.getDriverDataX();
      }
      if (fromTrendsByUnit) {
        await trendsStore.getEquipmentDataX();
      }

      if (!fromTrendsByDriver && !fromTrendsByUnit) {
        await trendsStore.getTop10TrendingDataX();
      }
      trendsStore.setShowLoader(false);
      onClose();
    })();
  };

  const handleFormSubmit = () => {
    const formValues = getValues();
    trendsStore.resetParams();
    trendsStore.resetGridData();
    trendsStore.resetSortParams();
    trendsStore.setSearchParams({ ...processParams(formValues) });
    trendsStore.setTrendsTodoFilters(formValues);
    trendsStore.setShowLoader(true);
    sendRequests();
  };

  const handleReset = () => {
    trendsStore.resetTrendsTodoFilters();
    trendsStore.resetParams();
    trendsStore.resetGridData();
    trendsStore.resetSortParams();
    reset();
    trendsStore.setShowLoader(true);
    sendRequests();
    onClose();
  };

  return (
    <form onSubmit={(event) => event.preventDefault()} id={searchPage}>
      {/* Category filter */}
      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) && (
        <>
          <Typography component={'h3'}>CATEGORY</Typography>
          <Typography
            component={'div'}
            className={classes.borderBottom}
            style={{ paddingBottom: '1rem' }}>
            {todoCategories.map(
              (tab: any, index: number) =>
                tab.show && (
                  <Grid
                    key={index}
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories(tab.title)}>
                    <input
                      type="checkbox"
                      tabIndex={0}
                      readOnly
                      checked={
                        (TrendsTodoFilters.todoCategory &&
                          TrendsTodoFilters.todoCategory.some(
                            (val: any) => val === tab.title,
                          )) ||
                        false
                      }
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      {tab.title}
                    </Typography>
                  </Grid>
                ),
            )}
          </Typography>
        </>
      )}

      {/* Type filter */}
      {userStore.UserData.accessType === 'companyAdmin' && (
        <>
          <Typography component={'h3'}>TYPE</Typography>
          <Typography component={'div'} className={classes.borderBottom}>
            <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
              <Controller
                control={control}
                defaultValue=""
                name="todoType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      inputRefObj={input1Ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectWhiteBg}
                      placeHolder={'Select'}
                      optionsList={todoTypes.map((value: any) => ({
                        label: value,
                        optionClassName: classes.optionsClassName,
                        value,
                      }))}
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </>
      )}
      {userStore.UserData.accessType === 'ownerOperator' && (
        <>
          <Typography component={'h3'}>TYPE</Typography>
          <Typography component={'div'} className={classes.borderBottom}>
            <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
              <Controller
                control={control}
                defaultValue=""
                name="todoType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      inputRefObj={input1Ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectWhiteBg}
                      placeHolder={'Select'}
                      optionsList={todoTypes
                        .filter(
                          (el) => el.includes('(D)') || el.includes('(F)'),
                        )
                        .map((value: any) => ({
                          label: value,
                          optionClassName: classes.optionsClassName,
                          value,
                        }))}
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </>
      )}
      {userStore.UserData.accessType === 'driver' && (
        <>
          <Typography component={'h3'}>TYPE</Typography>
          <Typography component={'div'} className={classes.borderBottom}>
            <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
              <Controller
                control={control}
                defaultValue=""
                name="todoType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      inputRefObj={input1Ref}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectWhiteBg}
                      placeHolder={'Select'}
                      optionsList={todoTypes
                        .filter((el) => el.includes('(D)'))
                        .map((value: any) => ({
                          label: value,
                          optionClassName: classes.optionsClassName,
                          value,
                        }))}
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </>
      )}
      {/* Due In filter */}
      <Typography component={'h3'}>DUE IN</Typography>
      <Typography component={'div'} className={classes.borderBottom}>
        <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
          <Controller
            control={control}
            defaultValue=""
            name="todoDueIn"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectInput
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  inputRefObj={input1Ref}
                  size={'small'}
                  onChangeText={onChange}
                  isEditable={false}
                  selectClassName={classes.selectWhiteBg}
                  placeHolder={'Select'}
                  sortByOptionDisable={true}
                  optionsList={todoDueIn.map(
                    (each: { name: string; value: string }) => ({
                      label: each.name,
                      optionClassName: classes.optionsClassName,
                      value: each.value,
                    }),
                  )}
                />
              );
            }}
          />
        </Grid>
      </Typography>
      {/* Search Type filter */}
      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        selectSearch &&
        selectSearch !== 'Training' && (
          <>
            <Typography component={'h3'}>SEARCH TYPE</Typography>
            <Typography component={'div'}>
              <Grid className={classes.dflexA} style={{ padding: '0.5rem 0' }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="searchType"
                  render={({
                    field: { onChange, value = '', name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isRefreshValue={true}
                        inputRefObj={ref}
                        value={value}
                        size={'small'}
                        onChangeText={(e: any) => {
                          onChange(e);
                          setSelectSearch(e);
                        }}
                        // isEditable is shouldDisable for this component
                        isEditable={true}
                        selectClassName={classes.selectWhiteBg}
                        placeHolder={'Select'}
                        optionsList={[
                          {
                            label: 'Driver Search',
                            optionClassName: classes.optionsClassName,
                            value: 'Driver',
                          },
                          {
                            label: 'Unit Search',
                            optionClassName: classes.optionsClassName,
                            value: 'Unit',
                          },
                        ]}
                      />
                    );
                  }}
                />
              </Grid>
            </Typography>
          </>
        )}

      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        selectSearch === 'Driver' && (
          <Typography component={'div'} className={classes.borderBottom}>
            {/* First name filter */}
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="firstName"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        placeholder="First Name"
                        onChangeText={onChange}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {/* Last name filter */}
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="lastName"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        placeholder="Last Name"
                        onChangeText={onChange}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {/* Simplex Id filter */}
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="simplexId"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        placeholder="Simplex ID"
                        onChangeText={onChange}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        )}
      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        selectSearch === 'Unit' && (
          <Typography component={'div'} className={classes.borderBottom}>
            {/* Year filter */}
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="year"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        placeholder="Year"
                        onChangeText={onChange}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {/* Make filter */}
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="make"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        placeholder="Make"
                        onChangeText={onChange}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {/* VIN filter */}
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="vinNumber"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        placeholder="VIN"
                        onChangeText={onChange}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {/* Unit number filter */}
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="unitNumber"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        placeholder="UNIT Number"
                        onChangeText={onChange}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        )}
      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        getValues('todoCategory') &&
        getValues('todoCategory')?.includes('Training') && (
          <>
            <Typography component={'div'} className={classes.borderBottom}>
              <Typography
                component="h4"
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'FiraSans-Medium',
                  fontSize: '15px',
                  fontWeight: '500',
                  height: '22px',
                  letterSpacing: '1px',
                  lineHeight: '22px',
                  textTransform: 'capitalize',
                  width: '166px',
                }}>
                Reason
              </Typography>
              <Typography component="div">
                <Grid
                  className={classes.dflexA}
                  style={{ padding: '0.5rem 0' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="todoTrainingReason"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          name={name}
                          isRefreshValue={true}
                          inputRefObj={ref}
                          value={value}
                          size="small"
                          onChangeText={onChange}
                          selectClassName={classes.selectWhiteBg}
                          placeHolder="Select"
                          optionsList={reasons.map((r) => ({
                            label: r.name,
                            value: r.id,
                          }))}
                        />
                      );
                    }}
                  />
                </Grid>
              </Typography>
            </Typography>
            <Typography component={'div'} className={classes.borderBottom}>
              <Typography
                component="h4"
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'FiraSans-Medium',
                  fontSize: '15px',
                  fontWeight: '500',
                  height: '22px',
                  letterSpacing: '1px',
                  lineHeight: '22px',
                  textTransform: 'capitalize',
                  width: '166px',
                }}>
                Assigned To
              </Typography>
              <Typography component="div">
                <Grid
                  className={classes.dflexA}
                  style={{ padding: '0.5rem 0' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="todoTrainingAssignedTo"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          name={name}
                          isRefreshValue={true}
                          inputRefObj={ref}
                          value={value}
                          size="small"
                          onChangeText={onChange}
                          selectClassName={classes.selectWhiteBg}
                          placeHolder="Select"
                          optionsList={assignees.map((r) => ({
                            label: r.name,
                            value: r.id,
                          }))}
                        />
                      );
                    }}
                  />
                </Grid>
              </Typography>
            </Typography>
          </>
        )}
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              tabIndex={0}
              type="button"
              onClick={handleReset}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            onClick={handleFormSubmit}
            component={'button'}
            disabled={isFormEmpty}
            className={classes.filterBtn}
            style={{
              backgroundColor: isFormEmpty ? '#d3d3d3' : '#DEC330',
            }}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(TrendsTodoSearch);
