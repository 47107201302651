import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import DialogMigrate from '../../../../../../components/Dialog/DialogMigrate';
import { InputText } from '../../../../../../components/Forms/InputText';
import { useCustomStore } from '../../../../../../hooks';
import { EquipmentSearchFormInputs } from '../../../../../../types';
import { SnackBarConfig } from '../../../../../../utils/SnackBarConfig';
import { EquipmentSearchSchema } from '../../../../../../utils/ValidatorSchema';
import search from './../../../../../../assets/images/h-search.svg';
import useStyles from './EquipmentDialogStyles';

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));

const LightColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #CBCBCB',
  borderRadius: '8px',
  boxSizing: 'border-box',
  color: theme.palette.getContrastText('#FFFFFF'),
  height: '45px',
  width: '116px',
}));

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

const EquipmentDialog: React.FC<any> = (props: any) => {
  const { isOpen, closeDialog, selecetedEquipmentList, requestType } = props;
  const headers = [
    'Equipment Type',
    'Unit Type',
    'Unit #',
    'Simplex Unit #',
    'VIN',
  ];
  const classes = useStyles();
  const { equipmentStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const { ShowLoader, EquipmentList, HasMoreResults } = equipmentStore;
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [searchEquipmets, setSearchEquipments] =
    useState<EquipmentSearchFormInputs>({
      simplexId: '',
      unitNumber: '',
      vinNumber: '',
    });
  const [selectList, setSelectList] = useState<Array<any>>([]);
  useEffect(() => {
    setSelectList([]);
    setSelectList(
      selecetedEquipmentList && selecetedEquipmentList.length
        ? selecetedEquipmentList.map((val: any) => val)
        : [],
    );
  }, [selecetedEquipmentList]);
  // validation
  const {
    control,
    // handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<EquipmentSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      simplexId: '',
      unitNumber: '',
      vinNumber: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(EquipmentSearchSchema),
  });
  const getAllEquipments = useCallback(async () => {
    equipmentStore.setShowLoader(true);
    const getEquipmentsResp = await equipmentStore.fetchEquipments(
      '',
      requestType,
    );
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    equipmentStore.setShowLoader(false);
  }, [equipmentStore, enqueueSnackbar, requestType]);

  useEffect(() => {
    getAllEquipments();
  }, [getAllEquipments]);

  const getSearchBasedEquipments = useCallback(
    async (searchParams: EquipmentSearchFormInputs) => {
      if (
        !searchParams.unitNumber &&
        !searchParams.simplexId &&
        !searchParams.vinNumber
      ) {
        equipmentStore.setEquipmentApiReset();
        getAllEquipments();
        return;
      }
      equipmentStore.setShowLoader(true);
      const getEquipmentsResp =
        await equipmentStore.fetchEquipmentsBySearchBased(
          searchParams,
          '',
          requestType,
        );
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      equipmentStore.setShowLoader(false);
    },
    [equipmentStore, getAllEquipments, enqueueSnackbar, requestType],
  );

  // add more Equipments on page scroll
  const getMoreEquipments = useCallback(async () => {
    if (
      searchEquipmets.unitNumber ||
      searchEquipmets.simplexId ||
      searchEquipmets.vinNumber
    ) {
      getSearchBasedEquipments(searchEquipmets);
      return;
    }
    setGetMoreLoader(true);
    const getEquipmentsResp = await equipmentStore.fetchEquipments(
      '',
      requestType,
    );
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [
    equipmentStore,
    enqueueSnackbar,
    getSearchBasedEquipments,
    searchEquipmets,
    requestType,
  ]);

  const equipementSearchsubmit = useCallback(
    (data: EquipmentSearchFormInputs) => {
      if (data.unitNumber || data.simplexId || data.vinNumber) {
        equipmentStore.setEquipmentApiReset();
        setSearchEquipments((pre) => ({ ...pre, ...data }));
        getSearchBasedEquipments(data);
      }
    },
    [equipmentStore, getSearchBasedEquipments],
  );

  const onselect = (equipment: any) => {
    let arr = selectList;
    const index = selectList.findIndex(
      (val: any) => val.unitId === equipment.unitId,
    );
    if (index !== -1) {
      arr = arr.filter((val: any) => val.unitId !== equipment.unitId);
    } else {
      arr.push(equipment);
    }
    setSelectList([...arr]);
  };
  const submit = () => {
    if (selectList && selectList.length > 15) {
      enqueueSnackbar('Maximum 15 records allowed', SnackBarConfig('e'));
      return;
    }
    props.onSelect && props.onSelect(selectList);
    closeDialogWindow();
  };
  const closeDialogWindow = () => {
    equipmentStore.setEquipmentApiReset();
    closeDialog && closeDialog();
  };
  const clearForm = () => {
    reset();
    setSearchEquipments((pre) => ({
      ...pre,
      ...{
        simplexId: '',
        unitNumber: '',
        vinNumber: '',
      },
    }));
    equipmentStore.setEquipmentApiReset();
    getAllEquipments();
  };
  const searchValue = () => {
    equipementSearchsubmit(getValues());
  };
  return (
    <DialogMigrate
      aria-labelledby="equipment-select"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={closeDialogWindow}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            Search & Select Equipment
          </div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialogWindow();
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>
      <form id={'equipment-dialog'} className={classes.headerWrapper}>
        <Grid
          container
          spacing={1}
          ml={0}
          pl={'15px'}
          pr={'24px'}
          sx={{ paddingBottom: '9.5px', width: '100%' }}>
          <Grid item md={3} xs={3}>
            <FormControl>
              <Controller
                control={control}
                defaultValue=""
                name="unitNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      inputRefObj={ref}
                      placeholder="Unit #"
                      errors={errors.unitNumber?.message || ''}
                      isRefreshValue={true}
                      isEditable={false}
                      type={'text'}
                      variant="standard"
                      value={value}
                      initIcon={
                        <InputAdornment position="start">
                          <img className={classes.hIcon} src={search} />
                        </InputAdornment>
                      }
                      className={classes.selectClassName}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={3}>
            <FormControl>
              <Controller
                control={control}
                defaultValue=""
                name="simplexId"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      inputRefObj={ref}
                      placeholder="Simplex Unit #"
                      errors={errors.simplexId?.message || ''}
                      isRefreshValue={true}
                      isEditable={false}
                      type={'text'}
                      variant="standard"
                      value={value}
                      initIcon={
                        <InputAdornment position="start">
                          <img className={classes.hIcon} src={search} />
                        </InputAdornment>
                      }
                      className={classes.selectClassName}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={3}>
            <FormControl>
              <Controller
                control={control}
                defaultValue=""
                name="vinNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      inputRefObj={ref}
                      placeholder="VIN"
                      errors={errors.vinNumber?.message || ''}
                      isRefreshValue={true}
                      isEditable={false}
                      type={'text'}
                      variant="standard"
                      value={value}
                      initIcon={
                        <InputAdornment position="start">
                          <img className={classes.hIcon} src={search} />
                        </InputAdornment>
                      }
                      className={classes.selectClassName}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={3}>
            <div className={classes.headerActionBtn}>
              <DarkColorButton
                type="button"
                onClick={() => searchValue()}
                variant="contained"
                sx={{ borderRadius: '4px', width: '100%' }}>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontFamily: 'FiraSans-Medium',
                    fontSize: '0.9375rem',
                    fontWeight: 500,
                    height: '22px',
                    letterSpacing: '1px',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  Search
                </Typography>
              </DarkColorButton>

              {searchEquipmets.unitNumber ||
              searchEquipmets.simplexId ||
              searchEquipmets.vinNumber ? (
                <Typography component={'span'} marginLeft={'12px'}>
                  <Button
                    variant={'text'}
                    className={'d-flex-ja '.concat(classes.cancelBtn)}
                    onClick={() => clearForm()}>
                    Clear
                  </Button>
                </Typography>
              ) : (
                ''
              )}
            </div>
          </Grid>
        </Grid>
      </form>

      <DialogContent id="scroll">
        <Box
          sx={{
            marginBottom: '15px',
          }}>
          <Grid container spacing={2}>
            <Divider className={classes.divider} />
            {headers.map((header: any, index: number) => (
              <Grid item xs={2} key={index}>
                <Typography
                  noWrap
                  sx={{
                    color: '#A4A4A4',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '0.75rem',
                    height: '14px',
                    letterSpacing: 0,
                    lineHeight: '14px',
                    width: '89px',
                  }}>
                  {header}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <InfiniteScroll
          dataLength={EquipmentList.length}
          // height={windowHeight}
          style={{ overflow: 'unset' }}
          next={getMoreEquipments}
          hasMore={HasMoreResults}
          scrollableTarget={'scroll'}
          loader={
            getMoreLoader && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#DEC330', marginTop: 20 }}
                />
              </div>
            )
          }
          endMessage>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: '#FFFFFF',
              marginBottom: '10px',
            }}>
            {ShowLoader ? (
              <div className={classes.loader}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : EquipmentList.length > 0 ? (
              EquipmentList.map((equipment: any, index: number) => (
                <React.Fragment key={index}>
                  <Grid item xs={2}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        height: '14px',
                        letterSpacing: 0,
                        lineHeight: '14px',
                        textTransform: 'capitalize',
                        width: '89px',
                      }}>
                      {equipment.equipmentType}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        height: '18px',
                        letterSpacing: 0,
                        lineHeight: '18px',
                        textTransform: 'capitalize',
                      }}>
                      {equipment.unitType}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        height: '18px',
                        letterSpacing: 0,
                        lineHeight: '18px',
                        textTransform: 'capitalize',
                      }}>
                      {equipment.unitNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        height: '18px',
                        letterSpacing: 0,
                        lineHeight: '18px',
                        textTransform: 'capitalize',
                      }}>
                      {equipment.simplexId}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        height: '18px',
                        letterSpacing: 0,
                        lineHeight: '18px',
                        textTransform: 'capitalize',
                      }}>
                      {equipment.vinNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {selectList.some(
                      (ele) => ele.unitId === equipment.unitId,
                    ) ? (
                      <DarkColorButton
                        type="button"
                        variant="contained"
                        style={{ bottom: '8px', width: '115px' }}
                        onClick={() => onselect(equipment)}>
                        <Typography
                          sx={{
                            color: '#FFFFFF',
                            fontFamily: 'FiraSans-Medium',
                            fontSize: '0.9375rem',
                            fontWeight: '500',
                            height: '22px',
                            letterSpacing: '1px',
                            lineHeight: '22px',
                            textAlign: 'center',
                          }}>
                          Selected
                        </Typography>
                      </DarkColorButton>
                    ) : (
                      <LightColorButton
                        type="button"
                        variant="contained"
                        style={{ bottom: '8px' }}
                        onClick={() => onselect(equipment)}>
                        <Typography
                          sx={{
                            color: '#241A2E',
                            fontFamily: 'FiraSans-Medium',
                            fontSize: '0.9375rem',
                            fontWeight: '500',
                            height: '22px',
                            letterSpacing: '1px',
                            lineHeight: '22px',
                            textAlign: 'center',
                            width: '55px',
                          }}>
                          Select
                        </Typography>
                      </LightColorButton>
                    )}
                  </Grid>
                  <Divider className={classes.divider} />
                </React.Fragment>
              ))
            ) : (
              <div className={classes.noRecordsFound}>No data available</div>
            )}
            <Divider className={classes.divider} />
          </Grid>
        </InfiniteScroll>
      </DialogContent>
      <DialogActions>
        <BottomButton
          type={'button'}
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => submit()}>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontFamily: 'FiraSans-Medium',
              fontSize: '0.9375rem',
              fontWeight: '500',
              height: '22px',
              letterSpacing: '1px',
              lineHeight: '22px',
              textAlign: 'center',
            }}>
            APPLY & CONTINUE
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(EquipmentDialog);
