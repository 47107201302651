import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
  },
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  divider: {
    border: '1px solid #E8E8E8',
    marginTop: '31px !important',
  },
  draft: {
    boxShadow: 'unset',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  gridheadingFlex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0 !important',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  quarterClassName: {
    backgroundColor: '#f3f3f3 !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    marginTop: '1.5rem',
  },
  renewalFormCardDivider: {
    margin: '12px 0px !important',
  },
  rightSidebtn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectClassName: {
    backgroundColor: '#f3f3f3 !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
  },
  smallLabel: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '0.75rem !important',
    marginBottom: '0.5rem !important',
  },
  stateStatus: {
    backgroundColor: '#F8F8FA',
    borderRadius: '7px',
    minHeight: '100px',
    padding: '0.6rem !important',
  },
  submited: {
    boxShadow: 'unset',
  },
  uploadDoc: {
    color: '#0061FF !important',
    cursor: 'pointer !important',
    fontFamily: 'FiraSans-SemiBold !important',
    fontSize: '0.875rem !important',
    fontWeight: '600 !important',
    textDecoration: 'underLine',
  },
  w100: {
    width: '100%',
  },
}));

export default useStyles;
