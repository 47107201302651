import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore } from '../../hooks';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import { SelectInput } from '../Forms/selectInput';
import useStyles from './TrainingReAssignDialogStyles';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

type TrainingReAssignDialogProps = {
  isOpen: boolean;
  assignment: any;
  reAssignType: 'current' | 'other';
  closeDialog: (refresh: boolean) => void;
};

const TrainingReAssignDialog: React.FC<TrainingReAssignDialogProps> = (
  props: TrainingReAssignDialogProps,
) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { trainingStore } = useCustomStore();
  const { isOpen, closeDialog, assignment, reAssignType } = props;

  const [contacts, setContacts] = useState<any[]>([]);
  const [loader, showLoader] = useState<boolean>(false);
  const [contactsLoader, setContactsLoader] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    driverId: string;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      driverId: '',
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const getReassignAssignmentContacts = useCallback(async () => {
    if (!assignment || !assignment.id) {
      return;
    }
    setContactsLoader(true);
    const resp = await trainingStore.getReassignAssignmentContacts(
      assignment.id,
    );
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Re-Assign Contacts',
        SnackBarConfig('e'),
      );
    } else {
      setContacts(resp.value.response);
    }
    setContactsLoader(false);
  }, [assignment, enqueueSnackbar, trainingStore]);

  useEffect(() => {
    if (reAssignType === 'other') {
      getReassignAssignmentContacts();
    }
  }, [getReassignAssignmentContacts, reAssignType]);

  const onFormSubmit = (values: any) => {
    if (!values.driverId) {
      setError('driverId', { message: 'Required' });
      return;
    }
    reassignAssignment(values.driverId);
  };

  const reassignAssignment = async (driverId?: string) => {
    if (
      !assignment ||
      !assignment.id ||
      (reAssignType === 'other' && !driverId)
    ) {
      return;
    }
    showLoader(true);
    const resp = await trainingStore.reassignAssignment(
      assignment.id,
      driverId,
    );
    if (resp.isErr()) {
      enqueueSnackbar('Unable to reassign training', SnackBarConfig('e'));
    }
    if (resp.isOk()) {
      enqueueSnackbar('Training Re-Assigned', SnackBarConfig('s'));
    }
    showLoader(false);
    closeDialog(true);
  };

  return (
    <DialogMigrate
      aria-labelledby="training-re-assign-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onClose={() => closeDialog(false)}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
        }}>
        <div className={classes.headerWrapper}>
          {reAssignType === 'other' && (
            <>
              <div className={classes.headerContainer}>RE-ASSIGN TRAINING</div>
              <Link
                className={classes.cancelLink}
                color="inherit"
                underline="always"
                onClick={() => {
                  closeDialog(false);
                }}>
                <ClearIcon />
              </Link>
            </>
          )}
          {reAssignType === 'current' && (
            <div
              className={classes.headerContainer}
              style={{ textAlign: 'center' }}>
              Are you sure you want to re-assign this assignment?
            </div>
          )}
        </div>
      </DialogTitle>
      <Divider className={classes.divider} />
      {reAssignType === 'other' && (
        <DialogContent className={classes.dialogContent}>
          {contactsLoader ? (
            <CircularProgress size={25} sx={{ color: '#DEC330' }} />
          ) : (
            <form
              id="training-re-assign"
              style={{ width: '100%' }}
              onSubmit={handleSubmit(onFormSubmit)}>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>Driver *</Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="driverId"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          name={name}
                          errors={errors?.driverId?.message || ''}
                          isRefreshValue={true}
                          value={value}
                          size={'small'}
                          onChangeText={onChange}
                          isEditable={false}
                          placeHolder={'Select'}
                          optionsList={contacts.map((r: any) => ({
                            label: `${
                              r.driverType === 'primaryDriver'
                                ? 'Primary Driver -'
                                : r.driverType === 'secondaryDriver'
                                ? 'Secondary Driver -'
                                : ''
                            } ${r.name as string}`,
                            value: r.id,
                          }))}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
      )}
      <DialogActions
        className={clsx({
          [classes.currentDialogAction]: reAssignType === 'current',
          [classes.otherDialogAction]: reAssignType === 'other',
        })}>
        {reAssignType === 'other' && (
          <BottomButton
            type={'submit'}
            disabled={loader || contactsLoader}
            form="training-re-assign"
            variant="contained"
            sx={{ width: '100%' }}>
            <Typography className={classes.submitBtnTxt}>
              {loader ? (
                <CircularProgress size={20} sx={{ color: '#DEC330' }} />
              ) : (
                'RE-ASSIGN'
              )}
            </Typography>
          </BottomButton>
        )}
        {reAssignType === 'current' && (
          <>
            <Link
              className={classes.cancelLink}
              color="inherit"
              underline="always"
              onClick={() => {
                if (loader) return;
                closeDialog(false);
              }}>
              NO, CANCEL
            </Link>
            <BottomButton
              type={'button'}
              disabled={loader}
              variant="contained"
              style={{ margin: 'unset', width: '50%' }}
              onClick={() => reassignAssignment()}>
              <Typography className={classes.submitBtnTxt}>
                {loader ? (
                  <CircularProgress size={20} sx={{ color: '#DEC330' }} />
                ) : (
                  'YES, RE-ASSIGN'
                )}
              </Typography>
            </BottomButton>
          </>
        )}
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(TrainingReAssignDialog);
