import { Grid } from '@mui/material';
import React from 'react';

import DriverListItem from './DriverListItem';

const DriverMedicalInfo: React.FC<{
  UserData: any;
}> = ({ UserData }) => {
  return (
    <>
      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Issue Date"
          value={UserData.medicalCardIssueDate}
        />
        <DriverListItem
          label="Expiration Date"
          value={UserData.medicalCardExpiryDate}
        />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Medical Examiner Name"
          value={UserData.medicalExaminerName}
        />
        <DriverListItem
          label="National Registry #"
          value={UserData.nationalRegistryNumber}
        />
      </Grid>

      <Grid container style={{ padding: '0.5rem 0' }}>
        <DriverListItem
          label="Medical Card on File"
          value={UserData.medicalCardOnFile ? 'Yes' : 'No'}
        />
      </Grid>
    </>
  );
};

export default DriverMedicalInfo;
