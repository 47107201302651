import './style/style.scss';

import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import PrivateRoute from './config/routes/PrivateRoute';
import PublicRoute from './config/routes/PublicRoute';
import { useCustomStore } from './hooks';

const App: React.FC = () => {
  const { authStore } = useCustomStore();
  const [isReady, setReady] = useState<boolean | null>(false);

  const handleAppEntry = useCallback(async () => {
    await authStore.fetchTokens();

    if (authStore.tokens)
      await authStore.fetchProfileAccess(authStore.tokens.accessToken);

    setReady(true);
  }, [authStore]);

  const statesOptionList = useCallback(async () => {
    await authStore.statesMasterList();
  }, [authStore]);

  useEffect(() => {
    handleAppEntry();
  }, [handleAppEntry]);

  // useEffect(() => {
  //   const tokenValues = authStore.getTokenDetail;
  //   authStore.portalNavBarAccess(tokenValues && tokenValues.portalAccess);
  // }, [authStore.getTokenDetail, authStore]);

  useEffect(() => {
    const tokenValues = authStore.getTokenDetail;
    if (tokenValues) {
      statesOptionList();
    }
  }, [authStore.getTokenDetail, statesOptionList]);

  if (isReady) {
    const hasTokenAndUserId = !!authStore.tokens;
    return hasTokenAndUserId ? <PrivateRoute /> : <PublicRoute />;
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
      }}>
      <CircularProgress size={30} sx={{ color: '#DEC330' }} />
    </div>
  );
};

export default observer(App);
