import { makeAutoObservable } from 'mobx';

import { RootStore } from './';

type SearchParams = {
  accidentNumber?: string;
  type?: 'all' | 'manage-all' | 'manage-completed';
  limit?: number;
  nextLink?: string;
  sortDirection?: string;
  sortBy?: string;
  sortChanged?: boolean;
  primaryDriverMatchStatus?: '' | 'No match' | 'Auto-match' | 'Confirmed';
  truckMatchStatus?: '' | 'No match' | 'Auto-match' | 'Confirmed';
  driverName?: string;
  matchedDriverId?: string;
  vin?: string;
  accidentState?: string;
  accidentDateStart?: string;
  accidentDateEnd?: string;
};

type getEquipmentListParams = {
  type?: 'power unit' | 'trailer';
  vin?: string;
  nextLink?: string | number;
  limit?: number;
};

type getDriverListParams = {
  name?: string;
  nextLink?: string | number;
  limit?: number;
};

// type submitDataQParams = {
//   challengeReason: string;
//   challengeType: string;
//   reasonType: string;
//   supportDocuments?: File[];
//   explanation?: string;
//   violationCodes?: string[];
//   source: string;
// };
export class AccidentsStore {
  private _list: Accident[];
  private _limit = 50;
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';

  public _status = 'All';
  private _sortDirection = '';
  private _sortBy = '';
  private _sortChanged = false;
  // private _accidentNumber = '';
  // private _accidentState = '';
  private _searchFilterParams: SearchParams = {
    accidentDateEnd: '',
    accidentDateStart: '',
    accidentNumber: '',
    accidentState: '',
    driverName: '',
    matchedDriverId: '',
    primaryDriverMatchStatus: '',
    truckMatchStatus: '',
    vin: '',
  };
  private _manageType: 'all' | 'manage-all' | 'manage-completed';
  private _count = 0;

  constructor(public rootStore: RootStore) {
    this._list = [];
    this._count = 0;
    this._manageType = 'manage-all';

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  setManageType(data: 'manage-all' | 'manage-completed') {
    this._manageType = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setCount(count: number) {
    this._count = count;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  setStatus(value: string) {
    this._status = value;
  }

  setSortChanged(sortChanged: boolean) {
    this._sortChanged = sortChanged;
  }

  resetSortParams() {
    this._sortBy = '';
    this._sortDirection = '';
    this._sortChanged = true;
  }

  setSearchParams(filters: any) {
    this._searchFilterParams = {
      ...this._searchFilterParams,
      ...filters,
    };
  }

  resetSearchParams() {
    this.setSearchParams({
      accidentDateEnd: '',
      accidentDateStart: '',
      accidentNumber: '',
      accidentState: '',
      driverName: '',
      matchedDriverId: '',
      primaryDriverMatchStatus: '',
      truckMatchStatus: '',
      vin: '',
    });
  }

  get SearchParams() {
    return this._searchFilterParams;
  }

  get ManageType() {
    return this._manageType;
  }

  get Data() {
    return this._list;
  }

  get Count() {
    return this._count;
  }

  get NextLink() {
    return this._nextLink;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get Status() {
    return this._status;
  }

  get sortChanged() {
    return this._sortChanged;
  }

  resetParams() {
    this._nextLink = '';
    this._list = [];
  }

  getList = async () => {
    const _type = this.Status === 'All' ? 'all' : this._manageType;
    const params = {
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._searchFilterParams.accidentNumber
        ? { accidentNumber: this._searchFilterParams.accidentNumber }
        : {}),
      ...{ sortChanged: this._sortChanged },
      ...(this._searchFilterParams.accidentState
        ? { accidentState: this._searchFilterParams.accidentState }
        : {}),
      ...(this._searchFilterParams.primaryDriverMatchStatus
        ? {
            primaryDriverMatchStatus:
              this._searchFilterParams.primaryDriverMatchStatus,
          }
        : {}),
      ...(this._searchFilterParams.truckMatchStatus
        ? { truckMatchStatus: this._searchFilterParams.truckMatchStatus }
        : {}),
      ...(this._searchFilterParams.driverName
        ? { driverName: this._searchFilterParams.driverName }
        : {}),
      ...(this._searchFilterParams.matchedDriverId
        ? { matchedDriverId: this._searchFilterParams.matchedDriverId }
        : {}),
      ...(this._searchFilterParams.vin
        ? { vin: this._searchFilterParams.vin }
        : {}),
      ...(this._searchFilterParams.accidentDateStart
        ? { accidentDateStart: this._searchFilterParams.accidentDateStart }
        : {}),
      ...(this._searchFilterParams.accidentDateEnd
        ? { accidentDateEnd: this._searchFilterParams.accidentDateEnd }
        : {}),
      type: _type,
    };
    const { list, nextLink, count } =
      await this.rootStore.apiStore.accidentsApi.getData(params);
    this._list = [...this._list, ...list];
    if (nextLink === this._nextLink || list.length < this._limit) {
      this._nextLink = '';
    } else {
      this._nextLink = nextLink;
    }
    this._count = count;
  };

  getMoreDetailsByAccidentId = (id: string) =>
    this.rootStore.apiStore.accidentsApi.getMoreDetails(id);

  exportList = async (searchParams: SearchParams = {}) => {
    const _type = this.Status === 'All' ? 'all' : this._manageType;
    const params = {
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._searchFilterParams.accidentNumber
        ? { accidentNumber: this._searchFilterParams.accidentNumber }
        : {}),
      ...{ sortChanged: this._sortChanged },
      ...(this._searchFilterParams.accidentState
        ? { accidentState: this._searchFilterParams.accidentState }
        : {}),
      ...(this._searchFilterParams.primaryDriverMatchStatus
        ? {
            primaryDriverMatchStatus:
              this._searchFilterParams.primaryDriverMatchStatus,
          }
        : {}),
      ...(this._searchFilterParams.truckMatchStatus
        ? { truckMatchStatus: this._searchFilterParams.truckMatchStatus }
        : {}),
      ...(this._searchFilterParams.driverName
        ? { driverName: this._searchFilterParams.driverName }
        : {}),
      ...(this._searchFilterParams.matchedDriverId
        ? { matchedDriverId: this._searchFilterParams.matchedDriverId }
        : {}),
      ...(this._searchFilterParams.vin
        ? { vin: this._searchFilterParams.vin }
        : {}),
      ...(this._searchFilterParams.accidentDateStart
        ? { accidentDateStart: this._searchFilterParams.accidentDateStart }
        : {}),
      ...(this._searchFilterParams.accidentDateEnd
        ? { accidentDateEnd: this._searchFilterParams.accidentDateEnd }
        : {}),
      type: _type,
    };
    return await this.rootStore.apiStore.accidentsApi.export(params);
  };

  confirmMatch = async (payload: any) => {
    const resp =
      await this.rootStore.apiStore.accidentsApi.matchDriversForAccidents(
        payload,
      );
    return resp;
  };

  getAllDrivers = async (params: getDriverListParams = {}) => {
    const resp = await this.rootStore.apiStore.accidentsApi.getAllDrivers(
      params,
    );
    return resp;
  };

  getAllEquipments = async (params: getEquipmentListParams = {}) => {
    const resp = await this.rootStore.apiStore.accidentsApi.getAllEquipments(
      params,
    );
    return resp;
  };

  submitDataQChallenge = async (payload: any) => {
    const resp =
      await this.rootStore.apiStore.accidentsApi.submitDataQChallenge(payload);
    return resp;
  };
}
