import React, { useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import Dashboard from '../../screens/private/Dashboard';
import Documents from '../../screens/private/Documents/documents';
import Fleet from '../../screens/private/Fleet/Fleets';
import FleetUpdate from '../../screens/private/Fleet/FleetUpdate/FleetUpdate';
import Home from '../../screens/private/Home/Home';
import Notifications from '../../screens/private/Notifications/notifications';
import Profile from '../../screens/private/Profile/Profile';
import Reports from '../../screens/private/Reports/reports';
import SelectedTrendDetails from '../../screens/private/SelectedTrendDetails';
import Dot from '../../screens/private/ServiceRequest/Dot';
import AddToFleet from '../../screens/private/ServiceRequest/Dot/AddToFleet';
import EquipmentInspection from '../../screens/private/ServiceRequest/Dot/EquipmentInspection/EquipmentInspection';
import TerminateEquipment from '../../screens/private/ServiceRequest/Dot/TerminateEquipment/TerminateEquipment';
import PhysicalExamLocation from '../../screens/private/ServiceRequest/Driver/pages/PhysicalExamLocation/PhysicalExamLocation';
import TaxPermitsAnnualReport from '../../screens/private/ServiceRequest/TaxPermits/AnnualReports/AnnualReport';
import TaxPermitsDotUpdate from '../../screens/private/ServiceRequest/TaxPermits/DotUpdate/DotUpdate';
import TaxPermitsFTQuarterFilling from '../../screens/private/ServiceRequest/TaxPermits/FTQuarterFilling/FTQuarterFilling';
import TaxPermitsIFTA from '../../screens/private/ServiceRequest/TaxPermits/IFTA/IFTA';
import TaxPermitsIRPService from '../../screens/private/ServiceRequest/TaxPermits/IRPService/IRPService';
import TaxPermitsRoadTaxes from '../../screens/private/ServiceRequest/TaxPermits/RoadTaxes/RoadTaxes';
import TaxPermitsStatePermits from '../../screens/private/ServiceRequest/TaxPermits/StatePermits/StatePermits';
import Taxsandpermits from '../../screens/private/ServiceRequest/TaxPermits/Taxsandpermits';
import TaxPermitsUcr from '../../screens/private/ServiceRequest/TaxPermits/UCR/Ucr';
import CompanyInfo from '../../screens/private/Settings/CompanyInfo/CompanyInfo';
import NotificationSettings from '../../screens/private/Settings/Notification/notificationSettings';
import Settings from '../../screens/private/Settings/settings';
import Todos from '../../screens/private/Todos/Todos';
import Training from '../../screens/private/Training';
import LoginAsImpersonate from '../../screens/public/LoginAsImpersonate/LoginAsImpersonate';
import { PublicRoute } from '../Constants';
import { PrivateRoutes } from '../index';

const OwnerOperatorRoutes: React.FC = () => {
  const {
    DASHBOARD,
    NOTIFICATIONS,
    TODOS,
    FLEET,
    FLEETDOCUMENTS,
    DOCUMENTS,
    REPORTS,
    SETTINGSROUTE,
    SERVICEREQUEST,
    PROFILE,
    DRIVERSERVICEREQUEST,
    VIOLATIONS_OF,
    CRASHES_OF,
    INSPECTIONS_OF,
    TRAINING,
    UPDATEFLEET,
  } = PrivateRoutes;
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { UCR } = SERVICEREQUEST;
  const { PHYSICALEXAMLOCATION } = DRIVERSERVICEREQUEST;
  const [title, setTitle] = useState('');
  const todoId = searchParams.get('id') || '';

  return (
    <Routes>
      <Route path={'/'} element={<Navigate to={DASHBOARD} />} />
      <Route
        path={PublicRoute.LOGINASIMPERSONATE}
        element={<LoginAsImpersonate />}
      />
      <Route
        path={`${DASHBOARD as string}/*`}
        element={
          <Home>
            <Dashboard />
          </Home>
        }
      />
      <Route
        path={NOTIFICATIONS}
        element={
          <Home
            title={'Notifications'}
            globalSearch={true}
            globalFilter={false}>
            <Notifications />
          </Home>
        }
      />

      <Route
        path={TODOS}
        element={
          <Home
            title={'To-Dos'}
            {...(state || todoId ? { backButton: true } : {})}
            globalSearch={false}
            globalFilter={true}>
            <Todos />
          </Home>
        }
      />

      <Route
        path={FLEET}
        element={
          <Home
            title={'Fleet'}
            globalSearch={true}
            globalFilter={false}
            {...(state &&
            typeof state === 'object' &&
            Object.keys(state).includes('showBackBtn')
              ? { backButton: true }
              : {})}>
            <Fleet />
          </Home>
        }
      />

      <Route
        path={UPDATEFLEET}
        element={
          <Home backButton title="UpdateFleet">
            <FleetUpdate />
          </Home>
        }
      />

      <Route
        path={FLEETDOCUMENTS}
        element={
          <Home
            title={'Documents'}
            globalSearch={false}
            globalFilter={true}
            documentsFilter={true}
            showInDocumentStoreHeaderName={true}>
            <Documents
              {...{ backButton: true, backUrl: FLEET, type: 'unit' }}
            />
          </Home>
        }
      />

      <Route
        path={DOCUMENTS}
        element={
          <Home
            title={'Documents'}
            globalSearch={false}
            globalFilter={true}
            documentsFilter={true}>
            <Documents />
          </Home>
        }
      />

      <Route
        path={REPORTS}
        element={
          <Home title={'Reports'} globalSearch={true} globalFilter={false}>
            <Reports />
          </Home>
        }
      />

      <Route
        path={SETTINGSROUTE.SETTINGS}
        element={
          <Home title={'Settings'}>
            <Settings />
          </Home>
        }
      />
      <Route
        path={UCR}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits tabValue={'1'} childComponent={<TaxPermitsUcr />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.ROADTAX}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'2'}
              childComponent={<TaxPermitsRoadTaxes />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.ANNUALREPORT}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'3'}
              childComponent={<TaxPermitsAnnualReport />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.IFTA}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'4'}
              childComponent={<TaxPermitsIFTA />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.STATEPERMITS}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'5'}
              childComponent={<TaxPermitsStatePermits />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.IRPSERVICE}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'6'}
              childComponent={<TaxPermitsIRPService />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.DOTUPDATE}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'7'}
              childComponent={<TaxPermitsDotUpdate />}
            />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.FTQUARTERFILLING}
        element={
          <Home title={'Service Request'}>
            <Taxsandpermits
              tabValue={'8'}
              childComponent={<TaxPermitsFTQuarterFilling />}
            />
          </Home>
        }
      />
      <Route
        path={PHYSICALEXAMLOCATION}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'11'} childComponent={<PhysicalExamLocation />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.ADDTOFLEET}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'6'} childComponent={<AddToFleet />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.EQUIPMENTINSPECTION}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'7'} childComponent={<EquipmentInspection />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.TERMINATEEQUIPMENT}
        element={
          <Home title={'Service Request'}>
            <Dot tabValue={'10'} childComponent={<TerminateEquipment />} />
          </Home>
        }
      />
      <Route
        path={PrivateRoutes.SERVICEREQUEST.TERMINATEEQUIPMENT_BY_ID}
        element={
          <Home backButton title={'Service Request'}>
            <Dot tabValue={'10'} childComponent={<TerminateEquipment />} />
          </Home>
        }
      />
      <Route
        path={PROFILE}
        element={
          <Home title="Profile">
            <Profile />
          </Home>
        }
      />

      <Route
        path={SETTINGSROUTE.COMPANYINFO}
        element={
          <Home title={'Settings'}>
            <CompanyInfo />
          </Home>
        }
      />

      <Route
        path={SETTINGSROUTE.NOTIFICATION}
        element={
          <Home title={'Settings'}>
            <NotificationSettings {...{ headerName: 'Driver' }} />
          </Home>
        }
      />
      <Route
        path={VIOLATIONS_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={CRASHES_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />
      <Route
        path={INSPECTIONS_OF}
        element={
          <Home backButton globalSearch title={title}>
            <SelectedTrendDetails setAppBarTitle={setTitle} />
          </Home>
        }
      />

      <Route
        path={`${TRAINING.BASE_ROUTE as string}/*`}
        element={
          <Home globalSearch={true} globalFilter={false} title={'Training'}>
            <Training />
          </Home>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default OwnerOperatorRoutes;
