export const getPlaceholderData = (index: number) => {
  switch (index) {
    case 0:
      return `
          <div>
            <h4 class="header">Unsafe Driving</h4><br/>
            <p>
              The driver, as the party responsible for the safe operation of a
              CMV, shall operate the CMV in a safe manner in full accordance
              with local, state and federal laws that regulate the safe
              operation of a CMV. Drivers shall, at all times, evaluate any
              potential hazards which could lead to an accident that could
              negatively impact personal property or the health or well-being of
              the general public.
            </p>
            <br/>
            <p>
              Drivers committing violations of any local, state or federal laws
              that could potentially incur in a loss, shall be immediately
              consulted by the carrier, and the carrier shall be responsible of
              reevaluating the driver’s skills to operate a CMV. It is highly
              recommended to retrain drivers periodically on the safe operation
              of the CMV.
            </p>
          </div>`;

    case 1:
      return `
          <div>
            <h4 class="header">Hours of Service</h4><br/>
            <p>
              “Hours of service” refers to the maximum amount of time drivers
              are permitted to be on duty including driving time, and specifies
              number and length of rest periods, to help ensure that drivers
              stay awake and alert. Therefore, the driver is the sole party
              responsible for complying with this regulation. At no time shall
              drivers deviate from this rule, as violation of this rule puts not
              only the driver but the general public in danger of a loss that
              could involve property damage and/or physical harm. Carriers
              shall, at all times, supervise the day-to-day operation of drivers
              to ensure compliance with this rule.
            </p>            
            <br/>
            <p>
              It is highly recommended for all carriers to periodically retrain
              drivers on this rule to ensure safety compliance.
            </p>
          </div>`;
    case 2:
      return `
          <div>
            <h4 class="header">Driver Fitness</h4><br/>
            <p>
              Maintaining required DOT/FMCSA and State Law Enforcement required
              documentation is crucial when identifying and showing legitimacy
              of what is required by regulations. No commercial driver should
              operate a CMV with expired or missing required documentation that
              pertains to qualifications as a commercial motor vehicle driver,
              the equipment the driver is operating and the cargo being
              transported.
            </p>
            <br/>
            <p>
            Documents to have current and ready for inspection if requested: 
            <ol>
            <li>CDL</li>
            <li>Physical Examination Card or Medical Card </li>
            <li>Truck and Trailer Registration </li>
            <li>Truck and Trailer Mechanical Inspection Form </li>
            <li>MC Authorization Form (Motor Carrier Authority Letter) </li>
            <li>State Jurisdiction Permits (if applicable) </li>
            <li>IFTA, NM, NY, KYU, OR </li>
            <li>Certificate of Insurance</li>
            <li>Bill of Landings </li>
            </ol>
            </p>
            <br/>
            <p>Missing and/or expired documentation will cause trip delay, out of service (OOS) violations and most likely impose monetary fines that could negatively impact your earnings. </p>
            <br/>
            <p>If any documentation is missing, we urge to immediately bring these to order, and provide Simplex with a current copy for compliance record keeping purposes, as these will be requested during a Federal or State Compliance Audit.  </p>
          </div>
        `;
    case 4:
      return `<div>
        <h4 class="header">Vehicle Maintainance</h4><br/>
        <p>
        In order to avoid mechanical defects or breakdowns that could jeopardize the safe operation of a CMV, drivers shall periodically conduct a visual inspection of the CMV prior to commencing driving operations and through the course of the work shift. The visual inspection of the CMV should be guided towards identifying possible malfunctions in areas such as: 
        <br/>
        <ol>
        <li>Lighting components</li> 
        <li>Brake functions and hoses</li>
        <li>Tires condition</li>
        <li>Couplings </li>
        <li>Grease leaking </li>
        <li>Air leaking </li>
        <li>Engine or Transmission failure</li>
        </ol>
        </p>
        <br/>
        <p>If the driver encounters a malfunction on any part or component that could compromise the safe operation of the CMV, the driver shall immediately discontinue operating the CMV until the encountered deficiency has been corrected.</p>
         </div> `;
    default:
      return '';
  }
};
