import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
// import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ApiRoutes } from '../../config/ApiRoutes';
import { Config } from '../../config/Constants';
import { useCustomStore } from '../../hooks';
import { UpdateQuizAnswersForAssignmentInputs } from '../../types';
import { decryptFileUrl } from '../../utils/helper';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './TrainingDialogStyles';

const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '48px',
  width: '170px',
}));

interface TrainingQuizDialogProps {
  showDialog: boolean;
  closeDialog: (action: 'close' | 'refresh' | 'warn') => void;
  onShowCertificateClick: (url: string) => void;
}

type QuizScreenType = 'rules' | 'question' | 'pass' | 'fail';

const TrainingQuizDialog: React.FC<TrainingQuizDialogProps> = (
  props: TrainingQuizDialogProps,
) => {
  const classes = useStyles();
  const { authStore, trainingStore, todoStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog, closeDialog, onShowCertificateClick } = props;

  const [startQuizLoader, setStartQuizLoader] = useState<boolean>(false);
  const [updateOptionLoader, setUpdateOptionLoader] = useState<boolean>(false);

  const [quizScreen, setQuizScreen] = useState<QuizScreenType>('rules');
  const [trainingStatus, setTrainingStatus] = useState<any>(null);
  const [quizData, setQuizData] = useState<any>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [selectedOptionId, setSelectedOptionId] = useState<string>('');
  const [selectedOptionIsCorrect, setSelectedOptionIsCorrect] =
    useState<boolean>(false);
  const [selectedOptionAttempts, setSelectedOptionAttempts] =
    useState<number>(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState<number>(0);
  const [showOptionError, setShowOptionError] = useState<boolean>(false);

  const onDownloadCertificate = useCallback(() => {
    if (!trainingStatus || !trainingStatus?.assignmentId) {
      return;
    }
    trainingStore.setDownloadTrainingCertificate({
      id: trainingStatus.assignmentId,
      loader: true,
    });
    const tokens = authStore.tokens;
    const url = `${Config.API_URL}${
      ApiRoutes.Training.GetCertificateForAnAssignment.Endpoint
    }?spURL=${trainingStatus.spURL as string}&accessToken=${
      tokens?.accessToken ?? ''
    }` as string;
    onShowCertificateClick(url);
    trainingStore.setDownloadTrainingCertificate({
      id: '',
      loader: false,
    });
  }, [authStore.tokens, trainingStatus, trainingStore, onShowCertificateClick]);
  const certificateAvailableCheckInterval = useRef<any>();

  // Updating training todo details
  const getTodoDetailsForAnAssignment = useCallback(
    async () => {
      if (!todoStore.SelectedTrainingAssignmentTodoId) {
        return;
      }
      const resp = await todoStore.getTodoDetailsForAnAssignment(
        todoStore.SelectedTrainingAssignmentTodoId,
      );
      if (resp.isErr()) {
        enqueueSnackbar('Unable to fetch todo details', SnackBarConfig('e'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, trainingStore],
  );

  // Decrypting quiz questions
  const decryptQuizQuestions = (encryptedQuestion: string): void => {
    const decryptData = decryptFileUrl(encryptedQuestion);
    const decryptQuiz = decryptData ? JSON.parse(decryptData) : null;
    setQuizData(decryptQuiz);
    if (
      decryptQuiz &&
      Array.isArray(decryptQuiz.questions) &&
      decryptQuiz.questions.length > 0
    ) {
      setSelectedQuestion({
        ...decryptQuiz.questions[0],
        index: 0,
      });
    }
    setQuizScreen('question');
  };

  // Get quiz encrypted details
  const getQuizDetailsForAssignment = useCallback(
    async () => {
      if (!todoStore.SelectedTrainingAssignmentId) {
        return;
      }
      !startQuizLoader && setStartQuizLoader(true);
      const resp = await trainingStore.getQuizDetailsForAssignment(
        todoStore.SelectedTrainingAssignmentId,
      );
      if (resp.isErr()) {
        enqueueSnackbar('Unable to fetch quiz', SnackBarConfig('e'));
      } else {
        decryptQuizQuestions(resp.value.response);
      }
      setStartQuizLoader(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, trainingStore],
  );

  // On start quiz, update quiz status as 'startQuiz'
  const onStartQuiz = async () => {
    setStartQuizLoader(true);
    const statusResp = await trainingStore.updateTrainingAssignmentStatus(
      todoStore.SelectedTrainingAssignmentId,
      {
        status: 'startQuiz',
      },
    );
    if (statusResp.isOk()) {
      setTrainingStatus(statusResp.value);
    } else {
      enqueueSnackbar('Unable to update status', SnackBarConfig('e'));
    }
    // Resetting question related state before starting quiz
    setSelectedQuestion(null);
    setSelectedOptionId('');
    setSelectedOptionIsCorrect(false);
    setSelectedOptionAttempts(0);
    setCorrectAnswersCount(0);
    setShowOptionError(false);
    // Fetching quiz questions
    getQuizDetailsForAssignment();
  };

  // Move to next question
  const goToNextQuestion = (): void => {
    const questions: any[] = quizData.questions;
    const nextQuestion = questions[+selectedQuestion.index + 1];
    setSelectedOptionId('');
    setSelectedOptionIsCorrect(false);
    setSelectedOptionAttempts(0);
    setSelectedQuestion({
      ...nextQuestion,
      index: +selectedQuestion.index + 1,
    });
  };

  // On clicking next button in quiz questions screen
  const onNextQuestion = async () => {
    if (!selectedOptionId) {
      // Should select an option before go to next question
      return;
    }
    const selectedOption = {
      attempts: selectedOptionAttempts,
      id: selectedOptionId,
      isCorrect: selectedOptionIsCorrect,
    };
    let totalCorrectAnswers = correctAnswersCount;
    if (!selectedOption.isCorrect && selectedOption.attempts === 0) {
      // If it is first attempt and selected wrong option then show warning message
      setSelectedOptionIsCorrect(false);
      setSelectedOptionAttempts(selectedOptionAttempts + 1);
      setSelectedOptionId('');
      setShowOptionError(true);
      return;
    } else {
      // If it is second option then go ahead with the selected option whether it is correct or wrong.
      selectedOption.attempts = selectedOption.attempts + 1;
    }
    if (selectedOption.isCorrect) {
      // Increase correct answers count if user selects correct option
      setCorrectAnswersCount(correctAnswersCount + 1);
      totalCorrectAnswers = correctAnswersCount + 1;
    }
    const totalQuestionsCount = quizData.questions.length;
    const hasNextQuestion = selectedQuestion.index < totalQuestionsCount - 1;
    // Answer update payload
    const payload: UpdateQuizAnswersForAssignmentInputs = {
      answers: [
        {
          attempt: selectedOption.attempts,
          isCorrect: selectedOption.isCorrect,
          quizQuestionAnswerId: selectedOption.id,
        },
      ],
      attemptNumber: trainingStatus.attemptNumber,
      isFirstAnswer: selectedQuestion.index === 0,
      isLastAnswer: !hasNextQuestion,
      quizId: quizData.quiz.id,
      quizQuestionId: selectedQuestion.id,
    };
    if (!hasNextQuestion) {
      // if it is last question then pass quiz score and status...
      payload.passingScorePercentage = quizData.quiz.correctAnswersPercentage;
      payload.totalQuestionsCount = totalQuestionsCount;
      payload.correctAnswersCount = totalCorrectAnswers;
      payload.incorrectAnswersCount = totalQuestionsCount - totalCorrectAnswers;
      payload.score = Math.round(
        (totalCorrectAnswers / totalQuestionsCount) * 100,
      );
      payload.status =
        totalCorrectAnswers >= quizData.quiz.correctAnswersCount
          ? 'Passed'
          : 'Failed';
    }
    setUpdateOptionLoader(true);
    const resp = await trainingStore.updateQuizAnswersForAssignment(
      quizData.assignmentId,
      payload,
    );
    if (resp.isOk()) {
      if (!hasNextQuestion) {
        const statusResp = await trainingStore.updateTrainingAssignmentStatus(
          quizData.assignmentId,
          {
            hasPassedQuiz: payload.status === 'Passed',
            score: payload.score,
            status: 'completedQuiz',
          },
        );
        if (statusResp.isOk()) {
          setTrainingStatus(statusResp.value);
          if (payload.status === 'Passed') {
            trainingStore.setDownloadTrainingCertificate({
              id: quizData.assignmentId,
              loader: true,
            });
            // let isCertifcateAvailable = false;
            certificateAvailableCheckInterval.current = setInterval(
              async (statusResp) => {
                try {
                  const tokens = authStore.tokens;
                  const url = `${Config.API_URL}${
                    ApiRoutes.Training.GetCertificateForAnAssignment.Endpoint
                  }?spURL=${statusResp.value.spURL as string}&accessToken=${
                    tokens?.accessToken ?? ''
                  }` as string;

                  const resp = await trainingStore.checkIfCertificateAvailable(
                    url,
                  );

                  if (!resp.isErr()) {
                    // isCertifcateAvailable = true;
                    trainingStore.setDownloadTrainingCertificate({
                      id: '',
                      loader: false,
                    });
                    clearInterval(certificateAvailableCheckInterval.current);
                    certificateAvailableCheckInterval.current = false;
                  }
                } catch (err) {
                  clearInterval(certificateAvailableCheckInterval.current);
                  certificateAvailableCheckInterval.current = false;
                }
              },
              3000,
              statusResp,
            );
          }
          setQuizScreen(payload.status === 'Passed' ? 'pass' : 'fail');
          getTodoDetailsForAnAssignment();
        } else {
          enqueueSnackbar('Unable to update status', SnackBarConfig('e'));
        }
      } else {
        goToNextQuestion();
      }
    } else {
      enqueueSnackbar('Unable to update question', SnackBarConfig('e'));
    }
    setUpdateOptionLoader(false);
  };

  const renderQuizRules = (): ReactElement => {
    return (
      <div className={classes.rules}>
        <div className="header"> Are you ready to start this quiz?</div>
        <div className="sub-header">
          WARNING: Once you start quiz, you must complete fully. If you quit or
          close out, it will count as an automatic fail.
        </div>
      </div>
    );
  };

  // On select an option for a question
  const onSelectQOption = (optionId: string, isCorrect: boolean): void => {
    showOptionError && setShowOptionError(false);
    setSelectedOptionId(optionId);

    if (isCorrect) {
      // Selected correct option
      setSelectedOptionIsCorrect(true);
    } else {
      // Selected wrong option
      setSelectedOptionIsCorrect(false);
    }
  };

  const renderQuizQuestions = (): ReactElement => {
    return (
      <div className={classes.quizQuestions}>
        {selectedQuestion !== null && quizData && quizData.quiz && (
          <>
            <div className="quiz-title">
              {`${String(+selectedQuestion.index + 1)} of ${
                quizData.questions.length as string
              } ${selectedQuestion.title as string}`}
            </div>
            <div className="quiz-description">{selectedQuestion.question}</div>
            <div className="quiz-options">
              {Array.isArray(selectedQuestion.options) &&
                selectedQuestion.options.map((option: any) => (
                  <div
                    className={clsx({
                      option: true,
                      'select-bg': selectedOptionId === option.id,
                    })}
                    key={option.id}
                    onClick={() =>
                      onSelectQOption(option.id, option.isCorrect)
                    }>
                    {option.option}
                  </div>
                ))}
            </div>
          </>
        )}
        {showOptionError && (
          <div className="wrong-option">
            The option selected is not correct.
          </div>
        )}
      </div>
    );
  };

  const renderQuizPassed = (): ReactElement => {
    return (
      <div className={classes.quizPassed}>
        {/* <div className="quiz-title">
          {correctAnswersCount} of {quizData.questions.length} Correct
        </div> */}
        <div className="quiz-description">
          You passed this exam and earned a certificate!
        </div>
        <div className="quiz-passed">
          <div className="passed">
            <DoneIcon className="icon" />
            <div className="text">Passed</div>
          </div>
        </div>
      </div>
    );
  };

  const renderQuizFailed = (): ReactElement => {
    return (
      <div className={classes.quizPassed}>
        {/* <div className="quiz-title">
          {correctAnswersCount} of {quizData.questions.length} Correct
        </div> */}
        <div className="quiz-description">
          You failed this exam. Try taking this exam again...
        </div>
        <div className="quiz-failed">
          <div className="failed">
            <RemoveIcon className="icon" />
            <div className="text">Failed</div>
          </div>
        </div>
        {trainingStatus?.canRetakeQuiz && (
          <div className="attempts">1 attempt left</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      if (certificateAvailableCheckInterval.current) {
        clearInterval(certificateAvailableCheckInterval.current);
      }
    };
  }, [certificateAvailableCheckInterval]);

  return (
    <DialogMigrate
      aria-labelledby="training-quiz-dialog"
      className={classes.quizDialog}
      maxWidth="sm"
      open={showDialog}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => closeDialog('close')}>
      {quizScreen !== 'rules' && (
        <DialogTitle className={classes.dialogTitleContainer}>
          <div className={classes.quizHeader}>
            QUIZ {['pass', 'fail'].includes(quizScreen) && 'COMPLETE'}
          </div>
          {(quizScreen === 'pass' &&
            !trainingStore.DownloadTrainingCertificate.loader) ||
            (['question', 'fail'].includes(quizScreen) && (
              <Link
                className={classes.cancelLink}
                color="inherit"
                underline="always"
                onClick={() => {
                  closeDialog(
                    ['pass', 'fail'].includes(quizScreen) ? 'refresh' : 'warn',
                  );
                }}>
                CLOSE
              </Link>
            ))}
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        {quizScreen === 'rules' && renderQuizRules()}
        {quizScreen === 'question' && renderQuizQuestions()}
        {quizScreen === 'pass' && renderQuizPassed()}
        {quizScreen === 'fail' && renderQuizFailed()}
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions
        className={classes.dialogAction}
        style={{ alignSelf: quizScreen === 'question' ? 'end' : 'unset' }}>
        {quizScreen === 'rules' && (
          <>
            <Link
              className={classes.cancelLink}
              color="inherit"
              underline="always"
              onClick={() => {
                closeDialog('close');
              }}>
              NO, TAKE LATER
            </Link>
            <ActionButton
              type={'button'}
              variant="contained"
              disabled={startQuizLoader}
              onClick={onStartQuiz}>
              <Typography className={classes.actionBtn}>
                {startQuizLoader ? (
                  <CircularProgress size={25} sx={{ color: '#DEC330' }} />
                ) : (
                  'YES, START QUIZ'
                )}
              </Typography>
            </ActionButton>
          </>
        )}
        {quizScreen === 'question' && (
          <ActionButton
            type={'button'}
            variant="contained"
            disabled={!selectedOptionId || updateOptionLoader}
            onClick={onNextQuestion}>
            <Typography className={classes.actionBtn}>
              {updateOptionLoader ? (
                <CircularProgress size={25} sx={{ color: '#DEC330' }} />
              ) : (
                'NEXT'
              )}
            </Typography>
          </ActionButton>
        )}
        {quizScreen === 'pass' && (
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            className={classes.outlinedBtn}
            disabled={trainingStore.DownloadTrainingCertificate.loader}
            onClick={() => onDownloadCertificate()}>
            {trainingStore.DownloadTrainingCertificate.loader ? (
              <>
                <span>GENERATING CERTIFICATE..</span>
                <CircularProgress
                  size={18}
                  sx={{ color: '#000', marginLeft: '0.4rem' }}
                />
              </>
            ) : (
              'SHOW CERTIFICATE'
            )}
          </Button>
        )}
        {quizScreen === 'fail' && trainingStatus?.canRetakeQuiz && (
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            className={classes.outlinedBtn}
            onClick={() => {
              setQuizScreen('rules');
            }}>
            RETAKE QUIZ
          </Button>
        )}
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(TrainingQuizDialog);
