import { Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';

import { useCustomStore } from '../../../../../hooks';
import useStyles from './ButtonTaxPermitsStyles';

type ButtonTypes = {
  formId: string;
  disabled?: boolean | any;
  formReset?: () => void;
  disableLoader?: boolean | any;
  submitButtonName?: string;
};
const TaxPermitsButtons: React.FC<ButtonTypes> = (props: ButtonTypes) => {
  const {
    formId,
    disabled,
    formReset,
    submitButtonName = 'Submit',
    disableLoader = true,
  } = props;

  const classes = useStyles();
  const { authStore } = useCustomStore();

  const clearForm = () => {
    formReset && formReset();
  };
  return (
    <Typography component={'div'} className={classes.bottomAction}>
      <Typography component={'span'}>
        <Button
          disabled={disabled}
          variant={'text'}
          className={'d-flex-ja '.concat(classes.cancelBtn)}
          onClick={() => clearForm()}>
          Cancel
        </Button>
      </Typography>
      <Typography component={'div'} className={classes.rightSidebtn}>
        {/* <Button
          form={formId}
          type="submit"
          variant="contained"
          className={'cancel-btn d-flex-ja '.concat(classes.draft)}
          style={{ marginRight: '1rem' }}>
          save draft
        </Button> */}
        <Button
          form={formId}
          sx={{
            '&:hover': {
              backgroundColor: '#dec330',
            },
            backgroundColor: '#dec330',
          }}
          disabled={
            disabled ||
            (authStore.getTokenDetail && authStore.getTokenDetail?.adminId)
          }
          type="submit"
          variant="contained"
          className={'add-btn d-flex-ja '
            .concat(classes.submited)
            .concat(
              authStore.getTokenDetail && authStore.getTokenDetail?.adminId
                ? ' disable'
                : '',
            )}>
          {disabled && disableLoader ? (
            <CircularProgress
              size={20}
              className={classes.circularProgress}
              color="primary"
            />
          ) : (
            ''
          )}
          {submitButtonName}
        </Button>
      </Typography>
    </Typography>
  );
};

export default TaxPermitsButtons;
