import { Card, CardContent, CircularProgress, Grid } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import NavRoutes from '../../../../../config/routes/NavRoutes';
import { useCustomStore } from '../../../../../hooks';
import SelectedDriverInfo from '../../components/SelectedDriverInfo';
import AlertDialog from './AlertDialog';

const LoderContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));
const DriverInfo: React.FC = () => {
  const { driverListStore } = useCustomStore();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const { SelectedDriver } = driverListStore;
  const id = params ? params.id : '';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleProceed = () => {
    if (id) {
      navigate(
        `/${NavRoutes.PRIVATE.LEFT_NAV.DRIVER}/${NavRoutes.PRIVATE.DRIVERS.PROFILE}/${id}`,
      );
    }
    setOpen(false);
  };
  const handleGetDriverinfo = useCallback(
    async (id: string) => {
      setIsLoading(true);
      await driverListStore.getDriverById(id);
      setIsLoading(false);
    },
    [driverListStore],
  );
  useEffect(() => {
    const id = params ? params.id : '';
    if (id) {
      handleGetDriverinfo(id);
    }
  }, [handleGetDriverinfo, params]);
  return (
    <div>
      <div style={{ display: 'none' }}>
        <AlertDialog
          handleOpen={handleOpen}
          handleClose={handleClose}
          isOpen={open}
          header={
            <div className="modal-header">
              <p>Are you sure you want to leave this page?</p>
            </div>
          }
          body={
            <div className="modal-body">
              <div>
                <p className="body-header">Progress will be lost</p>
                <p className="body-text">
                  Click Proceed to advance or cancel to Stay
                </p>
              </div>
            </div>
          }
          footer={
            <div className="modal-footer">
              <button onClick={handleClose} className="cancel">
                No, Cancel
              </button>
              <button onClick={handleProceed} className="proceed-btn">
                Yes, Proceed
              </button>
            </div>
          }
        />
      </div>
      <Card
        style={{
          backgroundColor: '#fff',
          borderRadius: 'unset',
          boxShadow: 'none',
        }}>
        <CardContent>
          {!isLoading && (
            <SelectedDriverInfo
              onClickEdit={handleOpen}
              SelectedDriver={SelectedDriver && SelectedDriver}
            />
          )}
          {isLoading && (
            <LoderContainer>
              <CircularProgress size={30} sx={{ color: '#DEC330' }} />
            </LoderContainer>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default observer(DriverInfo);
