import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    height: '100%',
    position: 'relative',
  },
  mainContainerNoOverflow: {
    display: 'flex',
    position: 'relative',
  },
  mainContent: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'unset',
    },
    flex: 1,
    paddingLeft: '15.3rem',
    position: 'relative',
  },
  mainContentNoOverflow: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 'unset',
    },
    flex: 1,
    paddingLeft: '15.3rem',
    position: 'relative',
  },
  sideNav: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
