import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  actionCheckbox: {
    '& .MuiSvgIcon-root': { fontSize: 28 },
    '&.Mui-checked': {
      color: '#0DA728',
    },
  },
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  center: {
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      padding: '0.5rem 1rem 1rem 1rem !important',
    },
  },
  dialogAction: {
    padding: '0 1.5rem !important',
  },
  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    margin: '0.6rem 0',
    marginLeft: 'unset !important',
  },
  driverDetails: {
    '& .grid-table': {
      marginTop: '1.3rem',
      width: '100%',
    },
    '& .sub-title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.8rem',
      letterSpacing: 0,
      lineHeight: '22px',
      width: '95%',
    },
    '& .title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1rem',
      letterSpacing: '1.2px',
      lineHeight: '26px',
    },
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  headerContainer: {
    color: '#241A2E',
    flex: 'auto',
    fontFamily: 'FiraSans-Regular',
    fontSize: '1.1rem',
    fontWeight: 600,
    letterSpacing: '1.2px',
    lineHeight: '26px',
    textTransform: 'uppercase',
  },
  headerWrapper: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoTitle: {
    '& span': {
      fontWeight: '600 !important',
    },
    color: '#979598 !important',
    fontFamily: 'Fira Sans-Regular',
    fontSize: '0.7rem !important',
    fontWeight: 500,
    lineHeight: '24px !important',
  },
  rowHeight: {
    height: '50px',
    minHeight: '40px',
  },
  submitBtnTxt: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.8rem',
    fontWeight: '500',
    height: '22px',
    letterSpacing: '1px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  table: {
    borderSpacing: '0px 5px',
    minWidth: 700,
  },
  tableCell: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.7rem !important',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  tableHeaderBg: {
    backgroundColor: '#FFFFFF !important',
  },
});
export default useStyles;
