import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { InputText } from '../../../components/Forms/InputText';
import { SelectInput } from '../../../components/Forms/selectInput';
import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { DataQChallengeSchema } from '../../../utils/ValidatorSchema';
import { LabelValueType, LookupDataType } from './DataQChallenge';
import DataQChallengeDocUploadDialog from './DataQChallengeDocUploadDialog';
import useStyles from './DataQChallengeStyles';

type DataQChallengeFormPropsType = {
  formData: LookupDataType[];
  sourceData: LabelValueType[];
  violationData: any[];
  from: string;
  supportingDocMessage: any;
  onDataQSSubmit: any;
};
/** Supporting documents upload limit */
const SUPPORTING_DOCS_LIMIT = 3;
const MAX_FILE_SIZE_IN_MB = 8;

type DataQChallengeFormType = {
  challengeReason: string;
  reasonType: string;
  challengeType: 'Inspection' | 'Violation' | 'Accident';
  source: string;
  explanation: string;
  violationCodes: any[];
};

type CreateChallengeType = {
  challengeReason: string;
  challengeType: string;
  reasonType: string;
  supportDocuments?: File[];
  explanation?: string;
  violationCodes?: {
    id: string;
    code: string;
    date: string;
  }[];
  source: string;
};

const DataQChallengeForm = (
  props: DataQChallengeFormPropsType,
): React.ReactElement => {
  const { authStore } = useCustomStore();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    formData,
    sourceData,
    violationData,
    from,
    supportingDocMessage,
    onDataQSSubmit,
  } = props;

  const [supportingDocs, setSupportingDocs] = useState<File[]>([]);
  const [docHelperText, setDocHelperText] = useState<string>('');
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [isDataQSubmiting, setIsDataQSubmiting] = useState<boolean>(false);

  const {
    control,
    formState: { errors }, // isValid
    getValues,
    setValue,
    watch,
    handleSubmit,
    setError,
  } = useForm<DataQChallengeFormType>({
    criteriaMode: 'all',
    defaultValues: {
      challengeReason: '',
      challengeType: from === 'accidents' ? 'Accident' : 'Inspection',
      explanation: '',
      reasonType: '',
      source: '',
      violationCodes: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(DataQChallengeSchema),
  });
  const {
    fields: violationCodeFields,
    replace: onViolationCodeReplace,
    update: onViolationCodeUpdate,
  } = useFieldArray({
    control,
    name: 'violationCodes',
  });
  const challengeTypeWatch = watch('challengeType');
  const reasonTypeWatch = watch('reasonType');

  useEffect(() => {
    onViolationCodeReplace(violationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Lookup data for challenge reason and reason type drop downs
  const getLookupData = (type: any): LabelValueType[] => {
    if (type === 'challengeReason') {
      return formData.map((v) => ({
        label: v.challengeReasonLabel,
        value: v.challengeReasonValue,
      }));
    } else if (type === 'reasonType') {
      return (
        formData.find(
          (v) => v.challengeReasonValue === getValues('challengeReason'),
        )?.reasonTypes || []
      );
    }
    return [];
  };

  const onChangeChallengeReason = (value: string): void => {
    const challengeType =
      formData.find((v) => v.challengeReasonValue === value)?.challengeType ||
      'Inspection';
    setValue('challengeType', challengeType);
    setValue('reasonType', '');
  };

  // Supporting documents
  const onSubmitSupportingDoc = (file: File): void => {
    if (supportingDocs.length === SUPPORTING_DOCS_LIMIT) {
      setDocHelperText(
        `Maximum supporting documents limit is ${SUPPORTING_DOCS_LIMIT} only`,
      );
      enqueueSnackbar(
        String(
          `Maximum supporting documents limit is ${SUPPORTING_DOCS_LIMIT} only`,
        ),
        SnackBarConfig('e'),
      );
      return;
    }
    if (
      supportingDocs.findIndex(
        (v) => v.name === file.name && v.size === file.size,
      ) !== -1
    ) {
      setDocHelperText(`${file.name} already added in the upload list`);
      enqueueSnackbar(
        String(`${file.name} already added in the upload list`),
        SnackBarConfig('e'),
      );
      return;
    }
    if (file.size / 1048576.0 > MAX_FILE_SIZE_IN_MB) {
      setDocHelperText(
        `file size greater than ${MAX_FILE_SIZE_IN_MB}MB not allowed`,
      );
      enqueueSnackbar(
        String(`file size greater than ${MAX_FILE_SIZE_IN_MB}MB not allowed`),
        SnackBarConfig('e'),
      );
      return;
    }
    const docFiles = [...supportingDocs, file];
    setSupportingDocs(docFiles);
    setShowUploadDialog(false);
    if (docHelperText) {
      setTimeout(() => {
        setDocHelperText('');
      }, 2500);
    }
  };

  const onClickSupportingDoc = () => {
    docHelperText && setDocHelperText('');
    setShowUploadDialog(true);
  };

  const onRemoveSupportingDoc = (fileIndex: number) => {
    setDocHelperText('');
    const docFiles = [...supportingDocs];
    docFiles.splice(fileIndex, 1);
    setSupportingDocs(docFiles);
  };

  // Violation Unit column format
  const getViolationUnit = (violationUnit: string): string => {
    switch (violationUnit) {
      case 'Driver':
        return 'D';
      default:
        return '-';
    }
  };

  // Data Q Challenge create request
  const onSubmitDataQForm = async (values: DataQChallengeFormType) => {
    // if (!isValid) {
    //   return;
    // }

    const payload: CreateChallengeType = {
      challengeReason:
        getLookupData('challengeReason').find(
          (v) => v.value === values.challengeReason,
        )?.label || '',
      challengeType: values.challengeType,
      explanation: values.explanation,
      reasonType:
        getLookupData('reasonType').find((v) => v.value === values.reasonType)
          ?.label || '',
      source: values.source,
      supportDocuments: supportingDocs,
    };

    if (values.challengeType == 'Violation') {
      const selectedViolationCodes = values.violationCodes
        .filter((v: any) => v.selected)
        .map((v: any) => {
          return {
            code: v.violationCode,
            date: v.violationDate || new Date().toISOString(),
            groupId: v.violationGroupId,
            id: v.violationCodeId,
          };
        });
      if (selectedViolationCodes.length === 0) {
        setError('violationCodes', {
          message: 'Violations required',
          type: 'custom',
        });
        return;
      }
      payload.violationCodes = selectedViolationCodes;
    }
    setIsDataQSubmiting(true);
    await onDataQSSubmit(payload);
    setIsDataQSubmiting(false);
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={classes.dataQChallengeForm}>
      <form onSubmit={handleSubmit(onSubmitDataQForm)}>
        <Grid container>
          <Grid item xs={12} className={classes.formTitle}>
            Fill Out & Submit Challenge
          </Grid>
          {/* Challenge Reason */}
          <Grid item xs={9} className={classes.gutterPadding}>
            <Box className={classes.formLabelClass}>Challenge Reason *</Box>
            <Controller
              control={control}
              name="challengeReason"
              defaultValue=""
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    errors={errors?.challengeReason?.message || ''}
                    name={name}
                    size={'small'}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val: string) => {
                      onChangeChallengeReason(val);
                      onChange(val);
                    }}
                    isEditable={false}
                    defaultOptionLabel="Select Challenge Reason"
                    selectClassName={classes.selectClassName}
                    optionsList={getLookupData(name).map((v) => ({
                      label: v.label,
                      value: v.value,
                    }))}
                  />
                );
              }}
            />
          </Grid>
          {/* Challenge Type */}
          <Grid item xs={3} pl={2} className={classes.gutterPadding}>
            <Box className={classes.formLabelClass}>Type</Box>
            <Box className={classes.formTypeLabel}>{challengeTypeWatch}</Box>
          </Grid>
          {/* Reason Type */}
          <Grid item xs={12} className={classes.gutterPadding}>
            <Box className={classes.formLabelClass}>Reason Type *</Box>
            {/* Violation Codes */}
            {challengeTypeWatch === 'Violation' ? (
              <Grid item xs={12} className={classes.violationsGird}>
                <Grid container>
                  <Grid item md={5} className="item">
                    <Typography
                      className="title"
                      style={{ paddingLeft: '3.5rem' }}>
                      BASIC
                    </Typography>{' '}
                  </Grid>
                  <Grid item md={2} className="item">
                    <Typography className="title">Out of Service</Typography>
                  </Grid>
                  {/* <Grid item md={2} className="item">
                    <Typography className="title">
                      Convicted of a Different Charge
                    </Typography>
                  </Grid> */}
                  <Grid item md={2} className="item">
                    <Typography className="title">
                      Violation Severity Weight
                    </Typography>
                  </Grid>
                  <Grid item md={2} className="item">
                    <Typography className="title">Time Weight</Typography>
                  </Grid>
                  {/* <Grid item md={2} className="item">
                    <Typography className="title">
                      Basic Violations per Inspection
                    </Typography>
                  </Grid> */}
                  <Grid item md={1} className="item">
                    <Typography className="title">Unit</Typography>
                  </Grid>
                </Grid>
                <List className="violations-list">
                  {violationCodeFields.map((code: any, index: number) => {
                    return (
                      <ListItem key={index}>
                        <Grid container className="list-container">
                          <Grid item md={5}>
                            <Typography
                              component={'div'}
                              style={{
                                display: 'flex',
                                padding: '0px 10px 0px 15px',
                              }}>
                              <label className="main">
                                <Controller
                                  control={control}
                                  name={`violationCodes.${index}.selected`}
                                  render={({ field }) => {
                                    return (
                                      <input
                                        type="checkbox"
                                        {...field}
                                        onChange={(event: any) => {
                                          field.onChange(event.target.checked);
                                          onViolationCodeUpdate(index, {
                                            ...code,
                                            selected: event.target.checked,
                                          });
                                        }}
                                        checked={code.selected}
                                        disabled={false}
                                      />
                                    );
                                  }}
                                />
                                <span className="geekmark" />
                              </label>
                              <Typography component={'div'}>
                                <Typography
                                  component={'div'}
                                  className="violation-basic">
                                  {getValues(`violationCodes.${index}.basic`) ||
                                    '-'}
                                  <Typography
                                    noWrap
                                    component={'span'}
                                    className="violation-code">
                                    {getValues(
                                      `violationCodes.${index}.violationCode`,
                                    ) || '-'}
                                  </Typography>
                                </Typography>
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography
                              component={'div'}
                              className="default-value">
                              {getValues(
                                `violationCodes.${index}.outOfService`,
                              ) || '-'}
                            </Typography>
                          </Grid>
                          {/* <Grid item md={2}>
                            <Typography
                              component={'div'}
                              className="default-value">
                              {getValues(
                                `violationCodes.${index}.isConvictedDifferentCharge`,
                              ) || '-'}
                            </Typography>
                          </Grid> */}
                          <Grid item md={2}>
                            <Typography
                              component={'div'}
                              className="default-value">
                              {getValues(
                                `violationCodes.${index}.violationSeverityWeight`,
                              ) || '-'}
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography
                              component={'div'}
                              className="default-value">
                              {getValues(
                                `violationCodes.${index}.timeWeight`,
                              ) || '-'}
                            </Typography>
                          </Grid>
                          {/* <Grid item md={2}>
                            <Typography
                              component={'div'}
                              className="default-value">
                              {getValues(
                                `violationCodes.${index}.basicViolationPerInspection`,
                              ) || '-'}
                            </Typography>
                          </Grid> */}
                          <Grid item md={1}>
                            <Typography
                              component={'div'}
                              className="default-value">
                              {getViolationUnit(
                                getValues(
                                  `violationCodes.${index}.violationUnit`,
                                ),
                              )}
                            </Typography>
                          </Grid>
                          {getValues(
                            `violationCodes.${index}.violationName`,
                          ) && (
                            <Grid item md={12}>
                              <Typography
                                component={'div'}
                                className="default-value"
                                style={{
                                  paddingLeft: '3.1rem',
                                  paddingTop: '0.5rem',
                                }}>
                                {getValues(
                                  `violationCodes.${index}.violationName`,
                                ) || '-'}
                              </Typography>
                            </Grid>
                          )}
                          <Grid item md={12}>
                            <Typography
                              pt={1}
                              component={'div'}
                              className="default-value"
                              style={{ paddingLeft: '3.1rem' }}>
                              Description:{' '}
                              {getValues(
                                `violationCodes.${index}.description`,
                              ) || '-'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
                {errors?.violationCodes &&
                  !Array.isArray(errors.violationCodes) && (
                    <FormHelperText error>Violations Required</FormHelperText>
                  )}
              </Grid>
            ) : (
              // Reason Types drop-down
              <Controller
                control={control}
                defaultValue=""
                name="reasonType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      isFormSubmittedOnce={!!formState.submitCount}
                      errors={errors?.reasonType?.message || ''}
                      name={name}
                      size={'small'}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val: string) => {
                        onChange(val);
                      }}
                      isEditable={false}
                      defaultOptionLabel="Select Type"
                      selectClassName={classes.selectClassName}
                      optionsList={getLookupData(name).map((v) => ({
                        label: v.label,
                        value: v.value,
                      }))}
                    />
                  );
                }}
              />
            )}
          </Grid>
          {/* Supporting Documents */}
          <Grid item xs={12} className={classes.gutterPadding}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              pb={1.5}>
              <Grid item xs={9}>
                <Box className={classes.formLabelClass}>
                  Supporting Documents
                </Box>
                <Box
                  className={classes.formTypeLabel}
                  sx={{ marginTop: '0.5rem !important' }}>
                  {challengeTypeWatch === 'Violation'
                    ? supportingDocMessage.violation
                    : supportingDocMessage[reasonTypeWatch] ||
                      'Upload any and upto 3 supporting documents, each not exceeding more than 8MB'}
                </Box>
              </Grid>
              <Grid item xs={3} className={classes.uploadBtn}>
                <Button
                  variant="outlined"
                  size="small"
                  className="upload-btn"
                  component="label"
                  onClick={onClickSupportingDoc}>
                  UPLOAD
                </Button>
              </Grid>
              {docHelperText && (
                <Grid item xs={12} className={classes.docHelperText}>
                  {docHelperText}
                </Grid>
              )}
              {supportingDocs.length > 0 &&
                supportingDocs.map((docFile: any, index: number) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    mt={docHelperText ? 0 : 1.5}
                    className={classes.gridItem}>
                    <span className="support-doc-title">{docFile.name}</span>
                    <span className="support-doc-actions">
                      {`${(Number(docFile.size) / 1024 / 1024).toFixed(2)}mb`}{' '}
                      <ClearIcon
                        className="icon"
                        onClick={() => onRemoveSupportingDoc(index)}
                      />
                    </span>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {/* Source Data */}
          <Grid item xs={12} className={classes.gutterPadding}>
            <Box className={classes.formLabelClass}>
              Where did you view the data you want reviewed? *
            </Box>
            <Controller
              control={control}
              defaultValue=""
              name="source"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    errors={errors?.source?.message || ''}
                    name={name}
                    size={'small'}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val: string) => {
                      onChange(val);
                    }}
                    isEditable={false}
                    defaultOptionLabel="Select Source"
                    selectClassName={classes.selectClassName}
                    optionsList={sourceData.map((v) => ({
                      label: v.label,
                      value: v.value,
                    }))}
                  />
                );
              }}
            />
          </Grid>
          {/* Explanation */}
          <Grid item xs={12} className={classes.gutterPadding}>
            <Box className={classes.formLabelClass}>
              Data Q Challenge Explanation
            </Box>
            <Controller
              control={control}
              defaultValue=""
              name="explanation"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <InputText
                    name={name}
                    isFormSubmittedOnce={!!formState.submitCount}
                    errors={errors?.explanation?.message || ''}
                    isRefreshValue={true}
                    value={value}
                    placeholder="Provide any additional details you want to share with Simplex regarding the Data Q Challenge"
                    onChangeText={onChange}
                    isEditable={false}
                    type={'text'}
                    isTextArea={true}
                    variant="outlined"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          {/* Actions */}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Link
              color="inherit"
              underline="always"
              className={classes.cancelLink}
              onClick={() => {
                goBack();
              }}>
              CANCEL
            </Link>
            <Button
              disabled={authStore.IsImpersonatingMode}
              variant="contained"
              className={classes.submitBtn}
              type="submit">
              {isDataQSubmiting ? (
                <CircularProgress size={20} sx={{ color: '#fff' }} />
              ) : (
                'SUBMIT'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      {showUploadDialog && (
        <DataQChallengeDocUploadDialog
          isOpen={showUploadDialog}
          title={
            challengeTypeWatch === 'Violation'
              ? supportingDocMessage.violation
              : supportingDocMessage[reasonTypeWatch] ||
                'Upload any supporting documents'
          }
          onSubmitSupportingDoc={onSubmitSupportingDoc}
          onClose={() => setShowUploadDialog(false)}
        />
      )}
    </div>
  );
};

export default observer(DataQChallengeForm);
