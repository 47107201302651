import { Box, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import logoNav2 from '../../../assets/images/logo-nav2.svg';
import { InputText } from '../../../components/Forms/InputText';
import Loader from '../../../components/Loader/Loader';
import { PrivateRoutes } from '../../../config';
import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import useStyles from '../PublicStyles';

const LoginAsImpersonate: React.FC = () => {
  const classes = useStyles();
  const { authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const useNavigator = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [loginAs, setLoginAs] = React.useState<{
    accessToken: string | null;
    name: string | null;
  }>({
    accessToken: '',
    name: '',
  });

  useEffect(() => {
    setLoginAs((pre) => ({
      ...pre,
      accessToken: searchParams.get('accessToken'),
      name: searchParams.get('name'),
    }));
  }, [searchParams]);

  const OnSubmit = async () => {
    setLoading(true);
    await authStore.logout(true);
    const bodyData = {
      accessToken: loginAs.accessToken,
    };
    const tokenResponse = await authStore.directLoginAs(bodyData);
    setLoading(false);
    if (tokenResponse.isErr()) {
      // // eslint-disable-next-line react-hooks/rules-of-hooks
      // useNavigator(PublicRoutes.LOGIN);
      enqueueSnackbar(String(tokenResponse.error.message), SnackBarConfig('e'));
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useNavigator(PrivateRoutes.DASHBOARD);
    }
  };
  return (
    <Grid
      container
      spacing={0}
      height={'100vh'}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      className={classes.sliderHome}>
      <Grid item margin={'auto'}>
        <div className={classes.rectangle}>
          <img src={logoNav2} className={classes.logoNav2} />

          <Grid
            sx={{
              marginTop: '31px',
            }}>
            <Grid container>
              <Box className={classes.labels}>Login As</Box>
              <Grid item md={12} sm={12} xs={12}>
                <InputText
                  name={'name'}
                  isRefreshValue={true}
                  value={loginAs.name ? loginAs.name : ''}
                  isEditable={true}
                  className={classes.inputStyles}
                  type={'text'}
                  variant="filled"
                />
              </Grid>
            </Grid>
          </Grid>
          <Button
            disabled={loading}
            tabIndex={0}
            type={'button'}
            variant="contained"
            fullWidth
            className={classes.buttonStyles}
            onClick={OnSubmit}>
            {loading ? (
              <Loader
                className={'flex-centering mt-1em mb-1em'}
                style={{ color: '#DEC330', marginBottom: 16, marginTop: 16 }}
              />
            ) : (
              'Login'
            )}
          </Button>
          {/* <Link
            to={`/forgot-password/${
              authStore.ContactData && authStore.ContactData.length === 1
                ? authStore.ContactData[0].contactId
                : onSelectContactId
            }`}
            tabIndex={0}
            className={classes.forgotPassword}>
            Forgot Password?
          </Link> */}
        </div>
      </Grid>
    </Grid>
  );
};
export default LoginAsImpersonate;
