import { CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ActivityListResponseDTO } from '../../../../../types';
import { NavTabsTPTS } from '../NavTabs/NavTabsConstent';
import statePermitsLogo from './../../../../../assets/images/TPTS/state-permits.svg';
import {
  Badge,
  ContentDiv,
  Img,
  Label,
  RecordNotFound,
  useStyle,
  Values,
} from './CardlistStyles';
import ActivityListDialogContent from './DialogContent/DialogContent';

type TaxPermitsProps = {
  ActivityList?: Array<ActivityListResponseDTO>;
  getMoreActivityList?: () => void;
  HasMoreResults?: any;
  getMoreLoader?: any;
};

const TaxPermitsCardList: React.FC<TaxPermitsProps> = (
  props: TaxPermitsProps,
) => {
  const { ActivityList, getMoreActivityList, getMoreLoader, HasMoreResults } =
    props;
  const classes = useStyle();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notesView, setNotesView] = useState<ActivityListResponseDTO | null>(
    null,
  );
  const getMoreList = () => {
    getMoreActivityList && getMoreActivityList();
  };
  const closeDialogWindow = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Box
        id={'scrollParent'}
        sx={{ minWidth: 275 }}
        style={{
          height: 'calc(100vh - 175px)',
          overflowX: 'unset',
          overflowY: 'auto',
        }}>
        <InfiniteScroll
          dataLength={
            ActivityList && ActivityList.length ? ActivityList.length : 0
          }
          style={{
            overflow: 'unset',
          }}
          next={getMoreList}
          hasMore={HasMoreResults}
          scrollableTarget={'scrollParent'}
          loader={
            getMoreLoader && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#DEC330', marginTop: 20 }}
                />
              </div>
            )
          }
          endMessage>
          {ActivityList && ActivityList.length ? (
            ActivityList.map((val, index) => (
              <Card
                key={index}
                style={{
                  backgroundColor: '#fff',
                  borderBottom: '1px solid #d8d8d8',
                  borderRadius: 'unset',
                  boxShadow: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsOpen(true);
                  setNotesView((pre) => ({ ...pre, ...val }));
                }}>
                <CardContent>
                  <Grid
                    container
                    spacing={0.5}
                    minHeight={'100%'}
                    width={'100%'}>
                    <Grid item md={1}>
                      <Badge>
                        {val?.category ? (
                          <Img
                            alt=""
                            src={
                              NavTabsTPTS.filter((tabs) => tabs.filter).find(
                                (tab) => tab.shortkey === val.category,
                              )?.activeIcon
                            }
                          />
                        ) : (
                          <Img alt="" src={statePermitsLogo} />
                        )}
                      </Badge>
                    </Grid>
                    <Grid item md={7} padding={'0px 13px !important'}>
                      <Typography
                        variant="h5"
                        component="span"
                        fontSize={'1rem'}
                        fontWeight={500}
                        fontFamily={'FiraSans-Medium'}
                        color={'#241a2e'}>
                        {val.subject
                          ? val.subject.split('-')[1]
                            ? val.subject.split('-')[1]
                            : ''
                          : ''}
                      </Typography>

                      {val.unitNumbers ? (
                        <ContentDiv>
                          <Grid container spacing={0.5} minHeight={'100%'}>
                            <Grid item md={6}>
                              <Label> Unit #:</Label>
                            </Grid>
                            <Grid item md={6}>
                              <Values> {val?.unitNumbers}</Values>
                            </Grid>
                          </Grid>
                        </ContentDiv>
                      ) : (
                        ''
                      )}
                      <ContentDiv>
                        <Grid container spacing={0.5} minHeight={'100%'}>
                          <Grid item md={6}>
                            <Label> Submitted:</Label>
                          </Grid>
                          <Grid item md={6}>
                            <Values>
                              {moment(val.submittedOn).format('MM/DD/YY')}
                            </Values>
                          </Grid>
                        </Grid>
                      </ContentDiv>

                      <ContentDiv>
                        <Grid container spacing={0.5} minHeight={'100%'}>
                          <Grid item md={6}>
                            <Label> Submitted By: </Label>
                          </Grid>
                          <Grid item md={6}>
                            <Values> {val.submittedBy}</Values>
                          </Grid>
                        </Grid>
                      </ContentDiv>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        component={'span'}
                        color={'#979598'}
                        fontSize={'0.875rem'}
                        fontFamily={'FiraSans-Medium'}
                        fontStyle={'italic'}
                        fontWeight={500}>
                        Ref #{val.referenceId ? val.referenceId : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))
          ) : (
            <RecordNotFound>No data available</RecordNotFound>
          )}
        </InfiniteScroll>
      </Box>
      <ActivityListDialogContent
        {...{ classes, closeDialogWindow, isOpen, notesView }}
      />
    </>
  );
};

export default observer(TaxPermitsCardList);
