import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { CircularProgress, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import { API_LIMIT } from '../../../../config/Constants';
import { useCustomStore } from '../../../../hooks';
import { ActivityListFilters } from '../../../../types';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import TaxPermitsCardList from '../components/CardsList/CardLists';
import TaxPermitsNavBarList from '../components/NavTabs/NavTabs';
import { NavTabsTPTS } from '../components/NavTabs/NavTabsConstent';
import filterIcon from './../../../../assets/images/filter.svg';
import search from './../../../../assets/images/h-search.svg';
import useStyles from './TaxsandpermitsStyles';

type TaxPermitsTypes = {
  tabValue: string;
  childComponent?: any;
};
const TaxPermits: React.FC<TaxPermitsTypes> = (Props: TaxPermitsTypes) => {
  const { tabValue } = Props;

  const classes = useStyles();

  const { activityListStore, authStore }: any = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const { ShowLoader, ActivityList, Filters, HasMoreResults, NextLink } =
    activityListStore;
  const [value, setValue] = useState<string>('1');
  const [getMoreLoader, setGetMoreLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilters] = useState<ActivityListFilters>({
    categories: [],
    dateSort: 'desc',
    lastSubmittedBy: '',
    limit: API_LIMIT,
    nextLink: '',
    submittedBy: '',
    unitNumber: '',
  });
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setFilters((pre) => ({
      ...pre,
      ...Filters,
    }));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllActivityList = useCallback(async () => {
    activityListStore.setShowLoader(true);
    const getEquipmentsResp = await activityListStore.fetchActivityLists();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    activityListStore.setShowLoader(false);
  }, [activityListStore, enqueueSnackbar]);

  const getMoreActivityList = useCallback(async () => {
    activityListStore.setFilters({ ...filter, ...{ nextLink: NextLink } });
    setGetMoreLoader(true);
    const getEquipmentsResp =
      await activityListStore.fetchActivityListsBySearchBased();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [NextLink, activityListStore, enqueueSnackbar, filter]);

  const applyFilters = () => {
    activityListStore.setFilters({ ...filter, nextLink: '' });
    getAllActivityList();
    handleClose();
  };
  const resetFilter = () => {
    setFilters((pre) => ({
      ...pre,
      categories: [],
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: '',
      submittedBy: '',
      unitNumber: '',
    }));
    if (
      activityListStore.Filters.categories.length ||
      activityListStore.Filters.lastSubmittedBy ||
      activityListStore.Filters.submittedBy ||
      activityListStore.Filters.unitNumber
    ) {
      activityListStore.setFilters({
        categories: [],
        dateSort: filter.dateSort,
        lastSubmittedBy: '',
        limit: API_LIMIT,
        nextLink: '',
        submittedBy: '',
        unitNumber: '',
      });
      getAllActivityList();
    }
    // handleClose();
  };
  useEffect(() => {
    setFilters((pre) => ({
      ...pre,
      categories: [],
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: '',
      submittedBy: '',
      unitNumber: '',
    }));
    activityListStore.setFilters({
      categories: [],
      dateSort: filter.dateSort,
      lastSubmittedBy: '',
      limit: API_LIMIT,
      nextLink: '',
      submittedBy: '',
      unitNumber: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllActivityList();
  }, [getAllActivityList]);
  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);

  const setlastSubmited = useCallback(
    (key: string, value: string) => {
      setFilters((pre) => ({
        ...pre,
        [key]: filter.lastSubmittedBy === value ? '' : value,
      }));
    },
    [filter],
  );
  const setCategories = useCallback(
    (value: string) => {
      const arr = [...filter.categories];
      if (arr.some((e: any) => e === value)) {
        const index = arr.findIndex((e) => e === value);
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
      setFilters((pre) => ({
        ...pre,
        categories: arr,
      }));
    },
    [filter],
  );
  const removeFilter = (key: string, value: string) => {
    const arr = [...filter.categories];
    if (key === 'categories') {
      arr.splice(Number(value), 1);
    }
    setFilters((pre) => ({
      ...pre,
      [key]: key === 'categories' ? arr : value,
    }));

    activityListStore.setFilters({
      ...filter,
      ...{ [key]: key === 'categories' ? arr : value },
      ...{ nextLink: '' },
    });
    getAllActivityList();
    handleClose();
  };

  const formatCatergories = (
    shortKey: string,
  ): { shortkey: string; title: string } => {
    let key: any = { shortkey: '', title: '' };
    key = NavTabsTPTS.filter((tabs) => tabs.filter).find(
      (tab) => tab.shortkey === shortKey,
    );
    return key;
  };
  return (
    <Grid container spacing={0.5} minHeight={'100%'} bgcolor={'#FFFFFF'}>
      {/* left Side content */}
      <Grid
        item
        xs={12}
        lg={8}
        md={12}
        sm={12}
        className={classes.padcss}
        style={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}
        minHeight={'calc(100vh - 80px)'}
        borderRight={'1px solid #d8d8d8'}>
        <TabContext value={value}>
          <TaxPermitsNavBarList setValue={setValue} value={value} />
          <TabPanel value={value} className={classes.tabPanel}>
            {Props.childComponent}
          </TabPanel>
        </TabContext>
      </Grid>

      {/* Right Side content */}
      <Grid
        item
        xs={12}
        lg={4}
        md={12}
        sm={12}
        className={classes.padcss}
        style={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}>
        <Typography
          className={classes.actFilter}
          component={'div'}
          display={'inline-flex'}
          flexWrap={'wrap'}
          width={'100%'}
          justifyContent={'space-between'}
          style={{ backgroundColor: 'rgb(234 234 234 / 38%)' }}>
          <Typography
            component={'span'}
            gutterBottom
            className={classes.cardMainHeading}>
            Activity List
          </Typography>
          <Typography
            component={'div'}
            gutterBottom
            className={classes.cardHeading}>
            <Typography className={classes.asend} component={'div'}>
              {filter.dateSort === 'asc' ? 'Date Ascending' : 'Date Descending'}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '5px',
                }}
                onClick={() =>
                  removeFilter(
                    'dateSort',
                    filter.dateSort === 'asc' ? 'desc' : 'asc',
                  )
                }>
                <KeyboardArrowUpIcon
                  fontSize="small"
                  {...(filter.dateSort === 'desc' ? { color: 'disabled' } : {})}
                />
                <KeyboardArrowDownIcon
                  fontSize="small"
                  {...(filter.dateSort === 'asc' ? { color: 'disabled' } : {})}
                />{' '}
              </div>
            </Typography>
            <Typography
              className={classes.fIcon}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <img className={classes.fimg} src={filterIcon} alt="filter" />
            </Typography>
            <Menu
              id="basic-menu"
              className="filter"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <Grid style={{ padding: '1rem' }}>
                <Typography component={'h3'}>CATEGORY</Typography>
                <Typography
                  component={'div'}
                  className={classes.borderBottom}
                  style={{ padding: '1rem 0' }}>
                  {NavTabsTPTS.filter((tabs) => tabs.filter)
                    .filter(
                      (tabs) =>
                        tabs.activeTabKey &&
                        authStore.NavbarAccess.tpts &&
                        authStore.NavbarAccess.tpts[tabs.activeTabKey],
                    )
                    .map((tab, index) => (
                      <Grid
                        key={index}
                        item
                        className={classes.dflexA}
                        onClick={() => setCategories(tab.shortkey)}>
                        <input
                          type="checkbox"
                          readOnly
                          tabIndex={0}
                          checked={filter.categories.some(
                            (val) => val === tab.shortkey,
                          )}
                        />
                        <Typography
                          component={'div'}
                          style={{ cursor: 'pointer' }}>
                          {tab.title}
                        </Typography>
                      </Grid>
                    ))}
                </Typography>
                <Typography component={'h3'}>SUBMITTED IN LAST</Typography>
                <Typography component={'div'} className={classes.borderBottom}>
                  <Grid
                    className={classes.dflexA}
                    style={{ padding: '1rem 0' }}>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox} ` +
                          `${
                            filter.lastSubmittedBy == '15'
                              ? classes.activeBox
                              : ''
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '15')
                        }>
                        15 DAYS
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox} ` +
                          `${
                            filter.lastSubmittedBy == '30'
                              ? classes.activeBox
                              : ''
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '30')
                        }>
                        30 DAYS
                      </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4}>
                      <Typography
                        component={'div'}
                        className={
                          `${classes.dayBox} ` +
                          `${
                            filter.lastSubmittedBy == '60'
                              ? classes.activeBox
                              : ''
                          }`
                        }
                        onClick={() =>
                          setlastSubmited('lastSubmittedBy', '60')
                        }>
                        60 DAYS
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography component={'h3'}>UNIT #</Typography>
                <Grid className={classes.borderBottom}>
                  <Grid container className={classes.searcBox}>
                    <Grid item md={2} className={classes.dflexJA}>
                      <img
                        className={classes.filteIcon}
                        src={search}
                        alt="search"
                      />
                    </Grid>
                    <Grid item md={10}>
                      <TextField
                        type={'text'}
                        value={filter.unitNumber}
                        sx={{
                          input: { backgroundColor: 'unset', color: '#fff' },
                        }}
                        className={classes.inputF}
                        placeholder="Search"
                        onChange={(e: any) =>
                          setFilters((pre) => ({
                            ...pre,
                            unitNumber: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Typography component={'h3'}>SUBMITTED BY #</Typography>
                <Grid className={classes.borderBottom}>
                  <Grid container className={classes.searcBox}>
                    <Grid item md={2} className={classes.dflexJA}>
                      <img
                        className={classes.filteIcon}
                        src={search}
                        alt="search"
                      />
                    </Grid>
                    <Grid item md={10}>
                      <TextField
                        type={'text'}
                        value={filter.submittedBy}
                        sx={{
                          input: { backgroundColor: 'unset', color: '#fff' },
                        }}
                        className={classes.inputF}
                        placeholder="Search"
                        onChange={(event: any) =>
                          setFilters((pre) => ({
                            ...pre,
                            submittedBy: event.target.value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item md={6} className={classes.resetF}>
                    <Button
                      disabled={
                        !(
                          activityListStore.Filters.categories.length ||
                          activityListStore.Filters.lastSubmittedBy ||
                          activityListStore.Filters.submittedBy ||
                          activityListStore.Filters.unitNumber
                        )
                      }
                      variant={'text'}
                      className={'d-flex-ja '.concat(classes.resetF)}
                      onClick={resetFilter}>
                      RESET FILTERS
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      component={'button'}
                      disabled={
                        !(
                          filter.categories.length ||
                          filter.lastSubmittedBy ||
                          filter.submittedBy ||
                          filter.unitNumber
                        )
                      }
                      className={classes.filterBtn}
                      style={{
                        backgroundColor: !(
                          filter.categories.length ||
                          filter.lastSubmittedBy ||
                          filter.submittedBy ||
                          filter.unitNumber
                        )
                          ? '#d3d3d3'
                          : '#DEC330',
                      }}
                      onClick={applyFilters}>
                      APPLY FILTER
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Menu>
          </Typography>
          <Typography component={'div'} className={classes.filterList}>
            {Filters.lastSubmittedBy ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('lastSubmittedBy', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.lastSubmittedBy} Days
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.unitNumber ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('unitNumber', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.unitNumber}
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.submittedBy ? (
              <Typography
                component={'div'}
                className={classes.filterItem}
                onClick={() => removeFilter('submittedBy', '')}>
                <Typography component={'div'} className={classes.filterText}>
                  {Filters.submittedBy}
                </Typography>
                <Typography className={classes.close} component={'span'}>
                  X
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {Filters.categories && Filters.categories.length
              ? Filters.categories.map((val: any, index: number) => (
                  <Typography
                    component={'div'}
                    className={classes.filterItem}
                    key={index}
                    onClick={() => removeFilter('categories', String(index))}>
                    <Typography
                      component={'div'}
                      className={classes.filterText}>
                      {formatCatergories(val)?.title}
                    </Typography>
                    <Typography className={classes.close} component={'span'}>
                      X
                    </Typography>
                  </Typography>
                ))
              : ''}
          </Typography>
        </Typography>
        {ShowLoader ? (
          <div className={classes.loader}>
            <CircularProgress
              size={30}
              sx={{ color: '#DEC330', marginTop: 20 }}
            />
          </div>
        ) : (
          <TaxPermitsCardList
            ActivityList={
              ActivityList && ActivityList.length ? ActivityList : []
            }
            getMoreActivityList={getMoreActivityList}
            getMoreLoader={getMoreLoader}
            HasMoreResults={HasMoreResults}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default observer(TaxPermits);
