import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import FileUpload from '../../../../components/FileUpload';
import { SelectDatePicker } from '../../../../components/Forms/datePicker';
import { InputText } from '../../../../components/Forms/InputText';
import { useCustomStore } from '../../../../hooks';
import { UpdateMedicalCardFormInputs } from '../../../../types';
import { getFileNamingConvention } from '../../../../utils/fileName.config';
import { handleGetUpdatedData } from '../../../../utils/helper';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import { UpdateMedicalCardFormSchema } from '../../../../utils/ValidatorSchema';
import useStyles from '../../ServiceRequest/TaxPermits/DotUpdate/DotUpdateStyles';

type Props = {
  driverList: any;
  cancel?: any;
  hanldeGetUsersProfilelInfo?: any;
};
const MedicalCardForm: React.FC<Props> = ({
  driverList,
  cancel,
  hanldeGetUsersProfilelInfo,
}) => {
  const { authStore, driverStore, driverListStore }: any = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [medicalFile, setMedicalFile] = useState<any>();
  const [medicalFileError, setMedicalFileError] = useState<boolean>(false);
  const { driverid } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm<UpdateMedicalCardFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      medicalCardExpiryDate: '',
      medicalCardIssueDate: '',
      medicalExaminerName: '',
      nationalRegistryNumber: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(UpdateMedicalCardFormSchema),
  });

  const submitMedicalCardDetails = async (data: any) => {
    try {
      const medicalFormData = handleGetUpdatedData(dirtyFields, data);
      const medicalFile = data?.medicalFile;
      const res = await driverStore.updateMedical(
        {
          ...medicalFormData,
          medicalFile,
        },
        driverid,
      );
      setBtnDisable(false);
      if (res && res.value) {
        enqueueSnackbar(
          'Information updated successfully',
          SnackBarConfig('s'),
        );
        cancel();
        hanldeGetUsersProfilelInfo();
      }
      if (res && res.error) {
        enqueueSnackbar('Something went wrong', SnackBarConfig('e'));
      }
    } catch (err) {
      setBtnDisable(false);
      return;
    }
  };
  const submitMedicalCardFile = async (data: any) => {
    try {
      const { medicalCardExpiryDate, medicalCardIssueDate } = data;

      const fileName = getFileNamingConvention[
        'Medical Card & National Registry'
      ].name({
        expirationDate: moment(medicalCardExpiryDate).format('MM/DD/YYYY'),
        issueDate: moment(medicalCardIssueDate).format('MM/DD/YYYY'),
      });
      const formData = new FormData();
      const fileData: any[] = [
        {
          driver: {
            contactId: driverid,
          },
          fileName,
          fileType: 'Medical Card & National Registry',
          service: 'medical card',
          type: 'driver',
        },
      ];
      formData.append('files', medicalFile);
      formData.append('data', JSON.stringify(fileData));
      const fileUploadResponse = await driverListStore.uploadFileForSignature(
        formData,
      );

      if (fileUploadResponse.isErr()) {
        setBtnDisable(false);
        enqueueSnackbar('File upload failed', SnackBarConfig('e'));
      } else {
        const medicalDetails = {
          medicalFile: {
            fileName: fileUploadResponse.value[0].fileName,
            fileSize: fileUploadResponse.value[0].fileSize,
            fileType: fileUploadResponse.value[0].fileType,
            location: fileUploadResponse.value[0].location,
          },
        };
        submitMedicalCardDetails({
          ...data,
          ...medicalDetails,
        });
      }
    } catch (err) {
      return;
    }
  };
  const onSubmit = (data: UpdateMedicalCardFormInputs) => {
    setBtnDisable(true);
    data.medicalCardIssueDate = moment(data.medicalCardIssueDate).format(
      'YYYY-MM-DD',
    );
    data.medicalCardExpiryDate = moment(data.medicalCardExpiryDate).format(
      'YYYY-MM-DD',
    );

    if (driverList?.medicalCardOnFile && !medicalFile)
      submitMedicalCardDetails(data);
    else if (!driverList?.medicalCardOnFile && !medicalFile) {
      setMedicalFileError(true);
      setBtnDisable(false);
    } else submitMedicalCardFile(data);
  };
  const handleGetMedicalList = useCallback(
    (driverList) => {
      if (driverList) {
        const {
          medicalCardExpiryDate,
          medicalCardIssueDate,
          medicalExaminerName,
          nationalRegistryNumber,
        } = driverList;
        setValue('medicalCardExpiryDate', medicalCardExpiryDate);
        setValue('medicalCardIssueDate', medicalCardIssueDate);
        setValue('medicalExaminerName', medicalExaminerName);
        setValue('nationalRegistryNumber', nationalRegistryNumber);
      }
    },
    [setValue],
  );
  useEffect(() => {
    handleGetMedicalList(driverList);
  }, [handleGetMedicalList, driverList]);
  return (
    <form
      style={{
        width: '100%',
      }}
      id="medical-card-form"
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="center" style={{ margin: '20px 0' }}>
        <Grid lg={8} md={12}>
          <Card>
            <CardHeader
              title={<h3>Update Medical Card</h3>}
              action={
                <Grid spacing={1}>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="text"
                    className={classes.primaryText}>
                    Cancel
                  </Button>

                  <Button
                    form="medical-card-form"
                    type="submit"
                    variant="text"
                    disabled={
                      btnDisable ||
                      (authStore.getTokenDetail &&
                        authStore.getTokenDetail?.adminId)
                    }
                    className={classes.primaryText.concat(
                      authStore.getTokenDetail &&
                        authStore.getTokenDetail?.adminId
                        ? ' disable'
                        : '',
                    )}>
                    Save
                  </Button>
                </Grid>
              }
              style={{ fontWeight: 'bolder' }}
            />
            {btnDisable ? (
              <div className={classes.medicalCardLoader}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : (
              <CardContent>
                <Grid container spacing={2}>
                  <>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Medical Examiner Name *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="medicalExaminerName"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.medicalExaminerName?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                                // annualReportStore.setAnnualDetail('registeredAgent', val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        National Registry *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="nationalRegistryNumber"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={
                                errors?.nationalRegistryNumber?.message || ''
                              }
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                                // annualReportStore.setAnnualDetail('registeredAgent', val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>Issue Date *</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="medicalCardIssueDate"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectDatePicker
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              maxDate={new Date()}
                              errors={
                                errors?.medicalCardIssueDate?.message || ''
                              }
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeDate={(e) => {
                                onChange(e);
                                // validation();
                              }}
                              disabled={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Expiration Date*
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="medicalCardExpiryDate"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectDatePicker
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={
                                errors?.medicalCardExpiryDate?.message || ''
                              }
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeDate={(e) => {
                                onChange(e);
                                // validation();
                              }}
                              disabled={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <FileUpload
                      fileLabel={
                        driverList?.medicalCardOnFile
                          ? 'Update Medical Card (Front)'
                          : 'Upload Medical Card (Front)*'
                      }
                      setFile={setMedicalFile}
                      file={medicalFile}
                    />

                    {medicalFileError && !medicalFile ? (
                      <Typography style={{ marginLeft: '20px' }}>
                        <p className="error-msg">Required</p>
                      </Typography>
                    ) : null}
                  </>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default MedicalCardForm;
