import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomSortingIcon from '../../../../components/SortingIcon/CustomSortingIcon';
import { AuthStore } from '../../../../store/auth';
import { TrainingStore } from '../../../../store/training';
import { AccessTypes, TrainingAssignedSortOptions } from '../../../../types';
import useStyles from './AssignedStyles';

const AssignedHeader: React.FC<{
  trainingStore: TrainingStore;
  authStore: AuthStore;
  trainingAssignedSortBy: (columnName: TrainingAssignedSortOptions) => void;
}> = ({ trainingStore, trainingAssignedSortBy, authStore }) => {
  const classes = useStyles();
  // Assigned Tab Grid Header
  let assignedHeader: any = [
    { label: 'Training Assigned', name: 'videoAssigned' },
    {
      label: 'Attempts Left',
      name: 'attemptsList',
      styles: {
        width: '20px',
      },
    },
    {
      label: 'Status',
      name: 'status',
      styles: {
        width: '100px',
      },
    },
    { label: 'Assigned By', name: 'assignedBy' },
    {
      label: 'Assigned Date',
      name: 'assignedDate',
      styles: {
        width: '120px',
      },
    },
    {
      label: 'Due Date',
      name: 'dueDate',
      styles: {
        width: '120px',
      },
    },
    {
      name: '',
      styles: {
        width: '20px',
      },
    },
  ];
  if (authStore.UserAccessType === AccessTypes.CompanyAdmin) {
    assignedHeader = [{ label: 'Assignee Name', name: 'contactName' }].concat(
      assignedHeader,
    );
  }
  // Sorting enable columns
  const sortColumns = [
    'contactName',
    'videoAssigned',
    'attemptsList',
    'status',
    'assignedBy',
    'assignedDate',
    'dueDate',
  ];

  return (
    <TableRow>
      {assignedHeader.map((header: any, index: number) => (
        <TableCell
          key={index}
          className={classes.tableHeaderBg}
          sx={header.styles}>
          <Grid item xs={12}>
            <Typography
              component={'div'}
              noWrap
              style={{
                alignItems: 'center',
                display: 'flex',
                whiteSpace: 'unset',
              }}>
              <Typography
                component={'div'}
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                }}>
                {header.label}
              </Typography>

              {sortColumns.includes(header.name) && (
                <CustomSortingIcon
                  {...{
                    filterSortBy: trainingStore.TrainingAssignedFilters.sortBy,
                    filterSortDirection:
                      trainingStore.TrainingAssignedFilters.sortDirection,
                    sortBY: trainingAssignedSortBy,
                    sortByName: header.name,
                  }}
                />
              )}
            </Typography>
          </Grid>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default observer(AssignedHeader);
