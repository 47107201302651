import { makeAutoObservable, toJS } from 'mobx';

import { AdminNotificationSearchParam } from '../types';
import { RootStore } from './';

export class AdminNotificationStore {
  private _adminNotification: any = [];
  private _selectedNotification: any = null;
  private _notificationSearchParams: {
    limit: number;
    searchParam: AdminNotificationSearchParam;
    triggerNotification: boolean;
  } = {
    limit: 10,
    searchParam: {
      description: '',
      nextLink: '',
      title: '',
    },
    triggerNotification: false,
  };
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get getNotificationDetails(): any {
    return [...toJS(this._adminNotification)];
  }

  get getSelectedNotification(): any {
    const existedSelectedNotification = { ...this._selectedNotification };
    this.setSelectedNotification();
    return existedSelectedNotification;
  }

  get getNotificationHeaderParams(): any {
    const search: any = { ...toJS(this._notificationSearchParams.searchParam) };
    return {
      description: search.description,
      nextLink: search.nextLink,
      title: search.title,
    };
  }

  get getNotificationTriggerStatus(): any {
    return this._notificationSearchParams.triggerNotification;
  }

  setNotificationCountIncrement() {
    const existedDetails: any = { ...toJS(this._notificationSearchParams) };
    existedDetails.limit += 10;
    existedDetails.triggerNotification = !existedDetails.triggerNotification;
    this._notificationSearchParams = { ...existedDetails };
  }

  setNotificationHeaderSearchParams(param: any) {
    this._notificationSearchParams = {
      ...toJS(this._notificationSearchParams.searchParam),
      ...param,
    };
  }

  setSelectedNotification(data: any = []) {
    this._selectedNotification = data;
  }

  setNotificationDetails(data: any = []) {
    const previousData: any = [...toJS(this._adminNotification)];
    if (previousData.length === data.length && data.length > 0) {
      this._notificationSearchParams.limit -= 10;
    }
    this._adminNotification = data;
  }

  async getInitialData() {
    const initialData: any =
      await this.rootStore.apiStore.adminNotificationApiStore.getInitialData({
        ...this.getNotificationHeaderParams,
        limit: this._notificationSearchParams.limit,
      });
    if (initialData.isOk()) {
      this.setNotificationDetails([...initialData.value.response]);
    }

    if (initialData.isErr()) {
      this.setNotificationDetails([]);
    }

    return initialData;
  }
}
