import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomStore } from '../../../../hooks';
import { AuthStore } from '../../../../store/auth';
import { TrainingStore } from '../../../../store/training';
import { AccessTypes } from '../../../../types';
import useStyles from './AssignedStyles';

type ListPropsTypes = {
  trainingStore: TrainingStore;
  authStore: AuthStore;
  onDismissConfirm: (data: any) => void;
  onDueDateUpdate: (data: any) => void;
};

const AssignedList = (props: ListPropsTypes): React.ReactElement => {
  const { todoStore } = useCustomStore();
  const { trainingStore, onDismissConfirm, authStore, onDueDateUpdate } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handlePopoverOption = (event: any, id: string) => {
    const obj: any = {};

    if (tooltipIsOpen[id]) {
      obj[id] = false;
      setAnchorEl(null);
    } else {
      obj[id] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const navigateToTodo = (todoId: string) => {
    todoStore.setStatus('Open');
    navigate(`/todos?id=${todoId}`);
  };

  // Video Assigned Column
  const renderVideoAssignedColumn = useCallback((assignment: any) => {
    const {
      reason,
      training,
      inspectionDetails: {
        inspectionId,
        inspectionReportNumber,
        inspectionDate,
        inspectionState,
        violationCode,
        violationDescription,
      },
      accidentDetails: {
        accidentId,
        accidentNumber,
        accidentDate,
        accidentState,
      },
    } = assignment;
    return (
      <div className="video-assigned-cell">
        <span className="video-assigned">{training?.title || '-'}</span>
        {reason && (
          <div className="sub-text">
            <span className="sub-header">Reason:&nbsp;</span>
            <span>{reason}</span>
          </div>
        )}
        {inspectionId ? (
          <>
            {inspectionReportNumber && (
              <div className="sub-text">
                <span className="sub-header">Report Number:&nbsp;</span>
                <span>{inspectionReportNumber}</span>
              </div>
            )}
            {inspectionDate && (
              <div className="sub-text">
                <span className="sub-header">Inspection Date:&nbsp;</span>
                <span>{inspectionDate}</span>
              </div>
            )}
            {inspectionState && (
              <div className="sub-text">
                <span className="sub-header">Inspection State:&nbsp;</span>
                <span>{inspectionState}</span>
              </div>
            )}
            {violationCode && (
              <div className="sub-text">
                <span className="sub-header">Violation Code:&nbsp;</span>
                <span>{violationCode}</span>
              </div>
            )}
            {violationDescription && (
              <div className="sub-text">
                <span className="sub-header">Violation Description:&nbsp;</span>
                <span>{violationDescription}</span>
              </div>
            )}
          </>
        ) : (
          ''
        )}
        {accidentId ? (
          <>
            {accidentNumber && (
              <div className="sub-text">
                <span className="sub-header">Accident Number:&nbsp;</span>
                <span>{accidentNumber}</span>
              </div>
            )}
            {accidentDate && (
              <div className="sub-text">
                <span className="sub-header">Accident Date:&nbsp;</span>
                <span>{accidentDate}</span>
              </div>
            )}
            {accidentState && (
              <div className="sub-text">
                <span className="sub-header">Accident State:&nbsp;</span>
                <span>{accidentState}</span>
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    );
  }, []);

  // To-Do Dismiss action column in Assigned tab
  const renderActionColumn = (rowData: any): React.ReactElement => {
    if (authStore.UserAccessType === AccessTypes.CompanyAdmin) {
      const anchorId = `dismiss-${String(rowData.id)}`;
      return (
        <>
          <IconButton onClick={(event) => handlePopoverOption(event, anchorId)}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            className="popclass"
            open={tooltipIsOpen[anchorId] || false}
            onClose={(event) => handlePopoverOption(event, anchorId)}
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}>
            <Box className={classes.trainingActions}>
              <div className="arrow">.</div>
              <Typography
                component={'div'}
                className={'n-pop-btn'}
                onClick={() => onDueDateUpdate(rowData)}>
                Edit Due Date
              </Typography>
              <Typography
                component={'div'}
                className={'n-pop-btn'}
                onClick={() => onDismissConfirm(rowData)}>
                Dismiss
              </Typography>
            </Box>
          </Popover>
        </>
      );
    } else {
      return (
        <Button
          variant="outlined"
          size="large"
          className="complete-button"
          disabled={!rowData?.todoId}
          onClick={() => navigateToTodo(rowData.todoId)}>
          COMPLETE
        </Button>
      );
    }
  };

  return (
    <>
      {trainingStore.TrainingAssignedData.map((a: any, index: number) => (
        <TableRow
          key={index}
          sx={{
            backgroundColor: '#FFFFFF',
            height: '80px',
            minHeight: '80px',
            paddingBottom: '20px',
          }}>
          {/* Driver Name */}
          {authStore.UserAccessType === AccessTypes.CompanyAdmin && (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  noWrap
                  style={{
                    fontFamily: 'FiraSans-Bold',
                    fontSize: '0.8rem',
                  }}>
                  {a?.assignedContact?.name || '-'}
                </Typography>
              </Grid>
            </TableCell>
          )}
          {/* Video Assigned and Reason */}
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className={classes.videoAssignedCell}>
              {renderVideoAssignedColumn(a)}
            </Grid>
          </TableCell>
          {/* Attempts Left */}
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {a?.hasQuiz ? a?.attemptsLeft || '-' : 'N/A'}
            </Grid>
          </TableCell>
          {/* Status */}
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {a?.status || '-'}
            </Grid>
          </TableCell>
          {/* Assigned By */}
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {a?.assignedBy?.name || '-'}
            </Grid>
          </TableCell>
          {/* Assigned Date & Due Date */}
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {a?.assignedDate || '-'}
            </Grid>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Grid item xs={12} md={12} lg={12}>
              {a?.dueDate || '-'}
            </Grid>
          </TableCell>
          {/* Dismiss To-Do */}
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item className={classes.gridActions}>
              {renderActionColumn(a)}
            </Grid>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default observer(AssignedList);
