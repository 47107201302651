import { CircularProgress, Paper, Stack } from '@mui/material';
import styled from '@mui/system/styled';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import SimplexCard from '../../../components/SimplexCard';
import SimplexGrid from '../../../components/SimplexGrid';
import { useCustomStore } from '../../../hooks';
import AccidentSubTableCard from './AccidentSubTableCard';
import { Div } from './styles';

const CustomPaper = styled(Paper)(() => ({
  // '.leftGridSpacing': {
  //   justifyContent: 'space-between',
  //   minWidth: '80%',
  //   p: {
  //     overflowWrap: 'anywhere',
  //   },
  // },
  // '.rightGridSpacing': {
  //   justifyContent: 'space-between',
  //   p: {
  //     overflowWrap: 'anywhere',
  //   },
  // },
  alignItems: 'center',
  backgroundColor: '#E5E9EF !important',
  display: 'grid',
  gridTemplateColumns: '60% 1% 39%',
}));

const Seperator = styled('div')(() => ({
  backgroundColor: '#D1D1D1',
  height: 40,
  marginBottom: 5,
  marginTop: 5,
  width: 1,
}));

type Details = {
  primaryDriver?: any;
  matchedPrimaryDriver?: any;
  truckInfo?: any;
  matchedTruckInfo?: any;
  accidentId?: string;
  roadwayTraffic?: string;
  roadAccessControl?: string;
  roadSurfaceCondition?: string;
  weatherCondition?: string;
  lightCondition?: string;
  accidentTableDetails?: Array<any>;
};

type Props = {
  id: string;
  showDriverInfo: boolean;
  showEquipmentInfo: boolean;
};
const headerKeys = [
  {
    label: 'Roadway Trafficway',
    name: 'roadwayTraffic',
    noSorting: true,
  },
  {
    label: 'Road Acces Control',
    name: 'roadAccessControl',
    noSorting: true,
  },
  {
    label: 'Road Surface Condition',
    name: 'roadSurfaceCondition',
    noSorting: true,
  },
  {
    label: 'Weather Condition',
    name: 'weatherCondition',
    noSorting: true,
  },
  {
    label: 'lightCondition',
    name: 'lightCondition',
    noSorting: true,
  },
];

const applySpacing = (el: any, index: number) => {
  const spacing = [250, 250, 250, 250, 250];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const headerKeysWithSpacing = headerKeys.map(applySpacing);

const primaryDriverLabels = [
  'Full Name',
  'Driver DOB',
  'Driver Age',
  'Driver License State',
];
const primaryDriverKeys: string[] = ['fullName', 'dob', 'age', 'licenseState'];
const truckInfoLabels = ['VIN', 'License Number', 'License State'];
const truckInfoKeys: string[] = ['vin', 'licenseNumber', 'licenseState'];

const matchedDriverLabels = ['Full Name', 'DOB', 'Simplex ID'];
const matchedDriverKeys: string[] = ['fullName', 'dob', 'simplexId'];
const matchedTruckLabels = ['VIN', 'Unit#', 'Simplex ID'];
const matchedTruckKeys: string[] = [
  'vinNumber',
  'unitNumber',
  'simplexUnitNumber',
];

const convertObjectToArray = (obj: any) => {
  const values: any[] = Object.values(obj);
  const keys = Object.keys(obj);
  return keys.map((key, index) => ({
    label: values[index].label,
    value: values[index].value || '-',
  }));
};

const MoreDetails: React.FC<Props> = ({
  id = '',
  showDriverInfo = true,
  showEquipmentInfo = true,
}) => {
  const { accidentsStore: store, authStore } = useCustomStore();
  const { statesListOption } = authStore;

  const [details, setDetails] = useState<Details>();
  const [showLoader, setShowLoader] = useState<boolean>();

  const getValueByKeys = (keys: any[], labels: string[], data: any) =>
    keys.reduce(
      (acc: any, key_, index: number) => ({
        ...acc,
        [key_]: { label: labels[index], value: data[key_] || '-' },
      }),
      {},
    );
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const {
        primaryDriver = {},
        powerUnit = {},
        matchedPrimaryDriver = {},
        matchedTruckInfo = {},
        ...rest
      } = await store.getMoreDetailsByAccidentId(id);
      setShowLoader(false);
      const accidentTableDetails = [{ ...rest }];
      const primaryDriver_ = getValueByKeys(
        primaryDriverKeys,
        primaryDriverLabels,
        primaryDriver,
      );

      const powerUnit_ = getValueByKeys(
        truckInfoKeys,
        truckInfoLabels,
        powerUnit,
      );

      const matchedPrimaryDriver_ = getValueByKeys(
        matchedDriverKeys,
        matchedDriverLabels,
        matchedPrimaryDriver,
      );

      const matchedTruckInfo_ = getValueByKeys(
        matchedTruckKeys,
        matchedTruckLabels,
        matchedTruckInfo,
      );

      setDetails({
        accidentTableDetails,
        matchedPrimaryDriver: matchedPrimaryDriver_,
        matchedTruckInfo: matchedTruckInfo_,
        primaryDriver: primaryDriver_,
        truckInfo: powerUnit_,
        ...accidentTableDetails[0],
      });
    })();
  }, [id, store, statesListOption]);

  // const handleSort = (e: any)=> {
  //   details?.violations.sort()
  // }

  return (
    <Stack spacing={0.5}>
      {!showLoader && (
        <>
          {showDriverInfo && (
            <CustomPaper elevation={0}>
              {details?.primaryDriver && (
                <SimplexCard
                  title="Primary Driver"
                  data={convertObjectToArray(details.primaryDriver)}
                  spacing={0}
                  headerLg={2}
                  bodyLg={2}
                  mainGridClassName={'leftGridSpacing'}
                />
              )}
              <Seperator />
              {details?.matchedPrimaryDriver && (
                <SimplexCard
                  title="Matched Driver"
                  data={convertObjectToArray(details.matchedPrimaryDriver)}
                  spacing={0}
                  headerLg={3}
                  bodyLg={3}
                  mainGridClassName={'rightGridSpacing'}
                />
              )}
            </CustomPaper>
          )}
          {showEquipmentInfo && (
            <CustomPaper elevation={0}>
              {details?.truckInfo && (
                <SimplexCard
                  title="Power Unit"
                  data={convertObjectToArray(details.truckInfo)}
                  spacing={0}
                  headerLg={2}
                  bodyLg={2}
                  mainGridClassName={'leftGridSpacing'}
                />
              )}
              <Seperator />
              {details?.matchedTruckInfo && (
                <SimplexCard
                  title="Matched Truck"
                  data={convertObjectToArray(details.matchedTruckInfo)}
                  spacing={0}
                  headerLg={3}
                  bodyLg={3}
                  mainGridClassName={'rightGridSpacing'}
                />
              )}
            </CustomPaper>
          )}
          {details?.accidentTableDetails &&
          details.accidentTableDetails.length ? (
            <SimplexGrid.Wrap>
              <SimplexGrid.Header
                id="accidents"
                cells={[...headerKeysWithSpacing]}
                height={50}
                tableCellSx={{
                  backgroundColor: '#F1F4F9',
                  border: '0 !important',
                }}
                // sortConfig={{ handleSort, store }}
              />
              <SimplexGrid.Body
                id="accidents"
                data={details.accidentTableDetails}
                cells={headerKeysWithSpacing}>
                {(el: any, index: number) => (
                  <AccidentSubTableCard
                    cells={headerKeysWithSpacing}
                    data={el}
                    key={index}
                  />
                )}
              </SimplexGrid.Body>
            </SimplexGrid.Wrap>
          ) : null}
        </>
      )}
      {showLoader && (
        <Div>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </Div>
      )}
    </Stack>
  );
};

export default observer(MoreDetails);
