import { makeAutoObservable } from 'mobx';

import { RootStore } from './';

type SearchParams = {
  type?: 'all' | 'manage-all' | 'manage-completed';
  sortDirection?: string;
  sortBy?: string;
  sortChanged?: boolean;
  primaryDriverMatchStatus?:
    | ''
    | 'All'
    | 'No match'
    | 'Auto-match'
    | 'Confirmed';
  secondaryDriverMatchStatus?:
    | ''
    | 'All'
    | 'No match'
    | 'Auto-match'
    | 'Confirmed';
  truckMatchStatus?: '' | 'All' | 'No match' | 'Auto-match' | 'Confirmed';
  trailerMatchStatus?: '' | 'All' | 'No match' | 'Auto-match' | 'Confirmed';
  primaryDriverName?: string;
  matchedPrimaryDriverName?: string;
  secondaryDriverName?: string;
  matchedSecondaryDriverName?: string;
  powerUnitVin?: string;
  matchedPowerUnitVin?: string;
  trailerVin?: string;
  matchedTrailerVin?: string;
  reportNumber?: string;
  inspectionState?: string;
  inspectionDateStart?: string;
  inspectionDateEnd?: string;
};

type getEquipmentListParams = {
  type?: 'power unit' | 'trailer';
  vin?: string;
  nextLink?: string | number;
  limit?: number;
  unitId?: string;
};

type getDriverListParams = {
  name?: string;
  nextLink?: string | number;
  limit?: number;
  driverId?: string;
};

// type submitDataQParams = {
//   challengeReason: string;
//   challengeType: string;
//   reasonType: string;
//   supportDocuments?: File[];
//   explanation?: string;
//   violationCodes?: string[];
//   source: string;
// };

export class InspectionsAndViolationsStore {
  private _list: Inspection[];
  private _limit = 50;
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';
  private _count = 0;
  // private _equipmentSelectedList: Array<any> = [];
  public _status = 'All';
  private _sortDirection = '';
  private _sortBy = '';
  private _sortChanged = false;
  // private _reportNumber = '';
  // private _inspectionState = '';
  private _searchFilterParams: SearchParams = {
    inspectionDateEnd: '',
    inspectionDateStart: '',
    inspectionState: '',
    matchedPowerUnitVin: '',
    matchedPrimaryDriverName: '',
    matchedSecondaryDriverName: '',
    matchedTrailerVin: '',
    powerUnitVin: '',
    primaryDriverMatchStatus: 'All',
    primaryDriverName: '',
    reportNumber: '',
    secondaryDriverMatchStatus: 'All',
    secondaryDriverName: '',
    trailerMatchStatus: 'All',
    trailerVin: '',
    truckMatchStatus: 'All',
  };

  private _manageType: 'all' | 'manage-all' | 'manage-completed';
  private _resetManageSelectAll = false;

  constructor(public rootStore: RootStore) {
    this._list = [];
    this._count = 0;
    this._manageType = 'manage-all';
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setCount(count: number) {
    this._count = count;
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setStatus(value: string) {
    this._status = value;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  setSortChanged(sortChanged: boolean) {
    this._sortChanged = sortChanged;
  }

  resetSortParams() {
    this._sortBy = '';
    this._sortDirection = '';
    this._sortChanged = true;
  }

  setSearchParams(filters: any) {
    this._searchFilterParams = {
      ...this._searchFilterParams,
      ...filters,
    };
  }

  resetSearchParams() {
    this.setSearchParams({
      inspectionDateEnd: '',
      inspectionDateStart: '',
      inspectionState: '',
      matchedPowerUnitVin: '',
      matchedPrimaryDriverName: '',
      matchedSecondaryDriverName: '',
      matchedTrailerVin: '',
      powerUnitVin: '',
      primaryDriverMatchStatus: 'All',
      primaryDriverName: '',
      reportNumber: '',
      secondaryDriverMatchStatus: 'All',
      secondaryDriverName: '',
      trailerMatchStatus: 'All',
      trailerVin: '',
      truckMatchStatus: 'All',
    });
  }

  setManageType(data: 'manage-all' | 'manage-completed') {
    this._manageType = data;
  }

  setResetManageSelectAll(value: boolean) {
    this._resetManageSelectAll = value;
  }
  get ResetManageSelectAll() {
    return this._resetManageSelectAll;
  }

  get SearchParams() {
    return this._searchFilterParams;
  }

  get Data() {
    return this._list;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Count() {
    return this._count;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get Status() {
    return this._status;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get sortChanged() {
    return this._sortChanged;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get ManageType() {
    return this._manageType;
  }

  resetParams() {
    this._nextLink = '';
    this._list = [];
  }

  getList = async () => {
    const _type = this.Status === 'All' ? 'all' : this._manageType;
    this._searchFilterParams.primaryDriverMatchStatus =
      this._searchFilterParams.primaryDriverMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.primaryDriverMatchStatus;
    this._searchFilterParams.secondaryDriverMatchStatus =
      this._searchFilterParams.secondaryDriverMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.secondaryDriverMatchStatus;
    this._searchFilterParams.truckMatchStatus =
      this._searchFilterParams.truckMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.truckMatchStatus;
    this._searchFilterParams.trailerMatchStatus =
      this._searchFilterParams.trailerMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.trailerMatchStatus;
    const params = {
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...{ sortChanged: this._sortChanged },
      ...(this._searchFilterParams.reportNumber
        ? { reportNumber: this._searchFilterParams.reportNumber }
        : {}),
      ...(this._searchFilterParams.inspectionState
        ? { inspectionState: this._searchFilterParams.inspectionState }
        : {}),
      ...(this._searchFilterParams.primaryDriverMatchStatus
        ? {
            primaryDriverMatchStatus:
              this._searchFilterParams.primaryDriverMatchStatus,
          }
        : {}),
      ...(this._searchFilterParams.secondaryDriverMatchStatus
        ? {
            secondaryDriverMatchStatus:
              this._searchFilterParams.secondaryDriverMatchStatus,
          }
        : {}),
      ...(this._searchFilterParams.truckMatchStatus
        ? { truckMatchStatus: this._searchFilterParams.truckMatchStatus }
        : {}),
      ...(this._searchFilterParams.trailerMatchStatus
        ? { trailerMatchStatus: this._searchFilterParams.trailerMatchStatus }
        : {}),
      ...(this._searchFilterParams.primaryDriverName
        ? { primaryDriverName: this._searchFilterParams.primaryDriverName }
        : {}),
      ...(this._searchFilterParams.matchedPrimaryDriverName
        ? {
            matchedPrimaryDriverName:
              this._searchFilterParams.matchedPrimaryDriverName,
          }
        : {}),
      ...(this._searchFilterParams.secondaryDriverName
        ? { secondaryDriverName: this._searchFilterParams.secondaryDriverName }
        : {}),
      ...(this._searchFilterParams.matchedSecondaryDriverName
        ? {
            matchedSecondaryDriverName:
              this._searchFilterParams.matchedSecondaryDriverName,
          }
        : {}),
      ...(this._searchFilterParams.powerUnitVin
        ? { powerUnitVin: this._searchFilterParams.powerUnitVin }
        : {}),
      ...(this._searchFilterParams.matchedPowerUnitVin
        ? { matchedPowerUnitVin: this._searchFilterParams.matchedPowerUnitVin }
        : {}),
      ...(this._searchFilterParams.trailerVin
        ? { trailerVin: this._searchFilterParams.trailerVin }
        : {}),
      ...(this._searchFilterParams.matchedTrailerVin
        ? { matchedTrailerVin: this._searchFilterParams.matchedTrailerVin }
        : {}),
      ...(this._searchFilterParams.inspectionDateStart
        ? { inspectionDateStart: this._searchFilterParams.inspectionDateStart }
        : {}),
      ...(this._searchFilterParams.inspectionDateEnd
        ? { inspectionDateEnd: this._searchFilterParams.inspectionDateEnd }
        : {}),
      type: _type,
    };
    const { list, nextLink, count } =
      await this.rootStore.apiStore.inspectionsAndViolationsApi.getData(params);
    this._list = [...this._list, ...list];
    if (nextLink === this._nextLink || list.length < this._limit) {
      this._nextLink = '';
    } else {
      this._nextLink = nextLink;
    }
    this._count = count;
  };

  getMoreDetailsByInspectionId = (id: string) =>
    this.rootStore.apiStore.inspectionsAndViolationsApi.getMoreDetails(id);

  exportList = async () => {
    const _type = this.Status === 'All' ? 'all' : this._manageType;
    this._searchFilterParams.primaryDriverMatchStatus =
      this._searchFilterParams.primaryDriverMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.primaryDriverMatchStatus;
    this._searchFilterParams.secondaryDriverMatchStatus =
      this._searchFilterParams.secondaryDriverMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.secondaryDriverMatchStatus;
    this._searchFilterParams.truckMatchStatus =
      this._searchFilterParams.truckMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.truckMatchStatus;
    this._searchFilterParams.trailerMatchStatus =
      this._searchFilterParams.trailerMatchStatus === 'All'
        ? ''
        : this._searchFilterParams.trailerMatchStatus;
    const params = {
      ...(this._limit ? { limit: this._limit } : {}),
      ...(this._nextLink ? { nextLink: this._nextLink } : {}),
      ...(this._sortBy ? { sortBy: this._sortBy } : {}),
      ...(this._sortDirection ? { sortDirection: this._sortDirection } : {}),
      ...{ sortChanged: this._sortChanged },
      ...(this._searchFilterParams.reportNumber
        ? { reportNumber: this._searchFilterParams.reportNumber }
        : {}),
      ...(this._searchFilterParams.inspectionState
        ? { inspectionState: this._searchFilterParams.inspectionState }
        : {}),
      ...(this._searchFilterParams.primaryDriverMatchStatus
        ? {
            primaryDriverMatchStatus:
              this._searchFilterParams.primaryDriverMatchStatus,
          }
        : {}),
      ...(this._searchFilterParams.secondaryDriverMatchStatus
        ? {
            secondaryDriverMatchStatus:
              this._searchFilterParams.secondaryDriverMatchStatus,
          }
        : {}),
      ...(this._searchFilterParams.truckMatchStatus
        ? { truckMatchStatus: this._searchFilterParams.truckMatchStatus }
        : {}),
      ...(this._searchFilterParams.trailerMatchStatus
        ? { trailerMatchStatus: this._searchFilterParams.trailerMatchStatus }
        : {}),
      ...(this._searchFilterParams.primaryDriverName
        ? { primaryDriverName: this._searchFilterParams.primaryDriverName }
        : {}),
      ...(this._searchFilterParams.matchedPrimaryDriverName
        ? {
            matchedPrimaryDriverName:
              this._searchFilterParams.matchedPrimaryDriverName,
          }
        : {}),
      ...(this._searchFilterParams.secondaryDriverName
        ? { secondaryDriverName: this._searchFilterParams.secondaryDriverName }
        : {}),
      ...(this._searchFilterParams.matchedSecondaryDriverName
        ? {
            matchedSecondaryDriverName:
              this._searchFilterParams.matchedSecondaryDriverName,
          }
        : {}),
      ...(this._searchFilterParams.powerUnitVin
        ? { powerUnitVin: this._searchFilterParams.powerUnitVin }
        : {}),
      ...(this._searchFilterParams.matchedPowerUnitVin
        ? { matchedPowerUnitVin: this._searchFilterParams.matchedPowerUnitVin }
        : {}),
      ...(this._searchFilterParams.trailerVin
        ? { trailerVin: this._searchFilterParams.trailerVin }
        : {}),
      ...(this._searchFilterParams.matchedTrailerVin
        ? { matchedTrailerVin: this._searchFilterParams.matchedTrailerVin }
        : {}),
      ...(this._searchFilterParams.inspectionDateStart
        ? { inspectionDateStart: this._searchFilterParams.inspectionDateStart }
        : {}),
      ...(this._searchFilterParams.inspectionDateEnd
        ? { inspectionDateEnd: this._searchFilterParams.inspectionDateEnd }
        : {}),
      type: _type,
    };
    return await this.rootStore.apiStore.inspectionsAndViolationsApi.export(
      params,
    );
  };

  getInspectionDriverViolationMatch = async (inspectionIds: string[]) => {
    return await this.rootStore.apiStore.inspectionsAndViolationsApi.getInspectionDriverViolationMatch(
      inspectionIds,
    );
  };

  confirmMatch = async (payload: any) => {
    const resp =
      await this.rootStore.apiStore.inspectionsAndViolationsApi.matchDriversForInspectionsAndViolations(
        payload,
      );
    return resp;
  };

  getAllDrivers = async (params: getDriverListParams = {}) => {
    const resp =
      await this.rootStore.apiStore.inspectionsAndViolationsApi.getAllDrivers(
        params,
      );
    return resp;
  };

  getAllEquipments = async (params: getEquipmentListParams = {}) => {
    const resp =
      await this.rootStore.apiStore.inspectionsAndViolationsApi.getAllEquipments(
        params,
      );
    return resp;
  };

  submitDataQChallenge = async (payload: any) => {
    const resp =
      await this.rootStore.apiStore.inspectionsAndViolationsApi.submitDataQChallenge(
        payload,
      );
    return resp;
  };
}
