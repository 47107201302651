import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import TodoForm from '../../../components/ToDoForm';
import TrainingCertificateViewDialog from '../../../components/TrainingDialog/TrainingCertificateViewDialog';
import TrainingCloseDialog from '../../../components/TrainingDialog/TrainingCloseDialog';
import TrainingQuizDialog from '../../../components/TrainingDialog/TrainingQuizDialog';
import TrainingVideoDialog from '../../../components/TrainingDialog/TrainingVideoDialog';
import { useCustomStore } from '../../../hooks';
import { AccessTypes, TodoSearchFormInputs } from '../../../types';
import { todoTypeBasedConfig } from '../../../utils/config';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import ComingSoon from '../ComingSoon';
import TodoHeader from './TodoHeader';
import TodoLists from './TodoLists';
import useStyles from './todoStyle';

type LocationState = {
  driverId?: string;
  todoType?: string;
  unitId?: string;
  showBackBtn?: boolean;
  firstName?: string;
  lastName?: string;
  driverType?: string;
};

const Todos: React.FC = () => {
  const { todoStore, globalStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const [comingSoon] = useState(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [showUpdate, setShowUpdate]: any = useState({
    data: {},
    show: false,
  });
  const [showTrainingVideoDialog, setShowTrainingVideoDialog] =
    useState<boolean>(false);
  const [showTrainingQuizDialog, setShowTrainingQuizDialog] =
    useState<boolean>(false);
  const [showTrainingCloseDialog, setShowTrainingCloseDialog] =
    useState<boolean>(false);
  const [showCertificateDialog, setShowCertificateDialog] =
    useState<any>(false);

  const [certificateUrl, setCertificateUrl] = useState<string>('');

  const todoId = searchParams.get('id') || '';

  const todoHeader: any = [
    { lable: 'To-Do', name: 'title', show: true },
    { lable: 'Category', name: 'category', show: true },
    { lable: 'Due Date', name: 'dueDate', show: true },
    {
      lable: 'Due In',
      name: 'dueIn',
      show: todoStore.Status !== 'Completed' ? true : false,
    },
    {
      lable: 'Actions',
      name: '',
      // show: todoStore.Status !== 'Completed' ? true : false,
      show: true,
    },
    {
      lable: 'Resolved Date',
      name: 'modifiedOn',
      show: todoStore.Status === 'Completed' ? true : false,
    },
  ];

  const todoCategories: any = [
    {
      index: '1',
      title: 'Fleet',
    },
    {
      index: '2',
      title: 'Driver',
    },
    {
      index: '3',
      title: 'Company',
    },
    {
      index: '4',
      title: 'Training',
    },
  ];

  const getTodosLists = useCallback(
    async (isReset: boolean) => {
      todoStore.setShowLoader(true);
      if (isReset) {
        todoStore.resetFilters();
      }
      const getTodosResp = await todoStore.fetchTodosBySorting();
      if (getTodosResp.isOk()) {
        todoStore.setShowLoader(false);
      }
      if (getTodosResp.isErr()) {
        todoStore.setShowLoader(false);
        enqueueSnackbar(
          String(getTodosResp.error.message),
          SnackBarConfig('e'),
        );
      }
    },
    [todoStore, enqueueSnackbar],
  );

  useEffect(() => {
    if (todoId) {
      todoStore.setTodoApiReset();
      (async () => {
        todoStore.setShowLoader(true);
        const res = await todoStore.fetchTodos(todoId);
        todoStore.setShowLoader(false);
        if (res.isErr()) {
          enqueueSnackbar(String(res.error.message), SnackBarConfig('e'));
        }
      })();
    }
  }, [todoId, todoStore, enqueueSnackbar]);

  const getSearchBasedTodos = useCallback(
    async (searchParams: TodoSearchFormInputs) => {
      if (
        !searchParams.firstName &&
        !searchParams.category &&
        !searchParams.lastName &&
        !searchParams.simplexId &&
        !searchParams.year &&
        !searchParams.make &&
        !searchParams.vinNumber &&
        !searchParams.unitNumber &&
        !searchParams.dueIn &&
        !searchParams.type &&
        !searchParams.driverId &&
        !searchParams.unitId
      ) {
        todoStore.setTodoApiReset(false);
        getTodosLists(true);
        return;
      }
      // todoStore.setShowLoader(true);
      const getSearchResp = await todoStore.fetchTodosBySearchBased(
        searchParams,
        false,
      );
      if (getSearchResp.isErr()) {
        enqueueSnackbar(
          String(getSearchResp.error.message),
          SnackBarConfig('e'),
        );
      }
      todoStore.setShowLoader(false);
    },
    [todoStore, getTodosLists, enqueueSnackbar],
  );

  const getMoreTodos = useCallback(async () => {
    if (
      todoStore.Filters.firstName ||
      todoStore.Filters.lastName ||
      todoStore.Filters.simplexId ||
      todoStore.Filters.year ||
      todoStore.Filters.make ||
      todoStore.Filters.vinNumber ||
      todoStore.Filters.unitNumber ||
      todoStore.Filters.dueIn ||
      todoStore.Filters.type ||
      todoStore.Filters.unitId ||
      todoStore.Filters.driverId
    ) {
      const filters: TodoSearchFormInputs = {
        driverId: todoStore.Filters.driverId,
        dueIn: todoStore.Filters.dueIn,
        firstName: todoStore.Filters.firstName,
        lastName: todoStore.Filters.lastName,
        make: todoStore.Filters.make,
        simplexId: todoStore.Filters.simplexId,
        type: todoStore.Filters.type,
        unitId: todoStore.Filters.unitId,
        unitNumber: todoStore.Filters.unitNumber,
        vinNumber: todoStore.Filters.vinNumber,
        year: todoStore.Filters.year,
      };
      setGetMoreLoader(true);
      await getSearchBasedTodos(filters);
      setGetMoreLoader(false);
      return;
    }
    setGetMoreLoader(true);
    const getTodosResp = await todoStore.fetchTodos();
    if (getTodosResp.isErr()) {
      enqueueSnackbar(String(getTodosResp.error.message), SnackBarConfig('e'));
    }
    setGetMoreLoader(false);
  }, [todoStore, enqueueSnackbar, getSearchBasedTodos]);

  const removeFilter = (key: string, value: string) => {
    const arr = todoStore.Filters.categories;
    if (key === 'categories') {
      arr.splice(Number(value), 1);
      todoStore.setFilters({
        categories: arr,
        dueIn: '',
        firstName: '',
        lastName: '',
        limit: 0,
        make: '',
        nextLink: '',
        simplexId: '',
        type: '',
        unitNumber: '',
        vinNumber: '',
        year: '',
      });
    } else {
      todoStore.setFilters({
        ...todoStore.Filters,
        [key]: key === 'categories' ? arr : value,
        nextLink: '',
      });
    }
    getTodosLists(false);
  };

  const formatCatergories = (title: string): { title: string } => {
    let key: any = { title: '' };
    key = todoCategories.find((tab: any) => tab.title === title);
    return key;
  };

  const todoResolveForm = useCallback(
    async (todo: any, updateOrRequestOrResolve: string) => {
      const { requestValue, updateValue, update, request, resolve, redirect } =
        todoTypeBasedConfig[todo.todoType];

      if (updateOrRequestOrResolve === 'update') {
        if (update) {
          // Show form builder
          setShowUpdate({
            data: {
              caseId: todo.caseId,
              category: todo.category,
              contactId: todo.contactId,
              permitInfo: todo?.permitInfo || {},
              todoCategoryType: todo?.todoCategoryType,
              todoId: todo.id,
              todoType: todo.todoType,
              type: updateValue,
              unitId: todo.unitId,
            },
            show: true,
          });
        }
      }
      if (updateOrRequestOrResolve === 'request') {
        if (request) {
          // Show form builder
          setShowUpdate({
            data: {
              category: todo.category,
              contactId: todo.contactId,
              permitInfo: todo?.permitInfo || {},
              todoCategoryType: todo?.todoCategoryType,
              todoId: todo.id,
              todoType: todo.todoType,
              type: requestValue,
              unitId: todo.unitId,
            },
            show: true,
          });
        } else {
          todoStore.setEquipmentFromTodo(todo);
          if (todo.todoType === 'MVR' && todo.contactId != '') {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            navigate(
              `${redirect as string}/${todo.contactId as string}?todoId=${
                todo.id as string
              }`,
            );
          } else if (todo.todoType === 'Fuel Tax Filing') {
            const todoText = todo.text.trim();
            const textArray = todoText.split(' ');
            const quarter = textArray[textArray.length - 2] || '';
            const year = textArray[textArray.length - 1] || '';
            navigate(
              `${redirect as string}?todoId=${todo.id as string}&quarter=${
                quarter as string
              }&year=${year as string}`,
            );
          } else {
            navigate(`${redirect as string}?todoId=${todo.id as string}`, {
              state: {
                todoId: todo.id,
              },
            });
          }

          globalStore.setCurrentRoute(String(redirect));
        }
      }
      if (updateOrRequestOrResolve === 'resolve') {
        if (resolve) {
          todoStore.setShowLoader(true);
          const docuSignResp = await todoStore.docuSign(todo);

          if (docuSignResp.isOk()) {
            const urlCDA: string = docuSignResp.value;
            // window.open(docuSignResp.value, 'popup', 'width=600,height=600');
            const url = `${urlCDA}${authStore.tokens!.accessToken!}`;
            window.open(url, '_blank');
            todoStore.setShowLoader(false);
          }
          if (docuSignResp.isErr()) {
            todoStore.setShowLoader(false);
            enqueueSnackbar(
              String(docuSignResp.error.message),
              SnackBarConfig('e'),
            );
          }
        }
      }

      if (updateOrRequestOrResolve === 'dismiss') {
        todoStore.setShowLoader(true);
        const dismissedResp = await todoStore.dismissTodo(todo.id);
        if (dismissedResp.isOk()) {
          todoStore.setShowLoader(false);
          enqueueSnackbar('Todo Dismissed Successfully!', SnackBarConfig('s'));
        }
        if (dismissedResp.isErr()) {
          todoStore.setShowLoader(false);
          enqueueSnackbar(
            String(dismissedResp.error.message),
            SnackBarConfig('e'),
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const { todoType = '' } = (location?.state as LocationState) || {
      todoType: '',
    };
    if (todoType || todoId) return;
    getTodosLists(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTodosLists, location]);

  useEffect(() => {
    const {
      todoType = '',
      driverId = '',
      unitId,
      firstName,
      lastName,
      driverType = '',
    } = (location?.state as LocationState) || {};

    if (driverId || unitId) {
      if (todoType && driverType != 'Owner Operator') {
        todoStore.setFilters({
          ...todoStore.Filters,
          categories: [...todoStore.Filters.categories, todoType],
        });
        todoStore.setSearchType(todoType);
      }
      if (firstName) {
        todoStore.setFilters({
          ...todoStore.Filters,
          firstName: firstName,
        });
      }
      if (lastName) {
        todoStore.setFilters({
          ...todoStore.Filters,
          lastName: lastName,
        });
      }
      // Not using todoType, because filtering should happen in all categories
      getSearchBasedTodos({ ...todoStore.Filters, driverId, unitId });
      return;
    }
    if (todoType) {
      todoStore.setFilters({
        ...todoStore.Filters,
        categories: [...todoStore.Filters.categories, todoType],
      });
      todoStore.setSearchType(todoType);
      if (firstName) {
        todoStore.setFilters({
          ...todoStore.Filters,
          firstName: firstName,
        });
      }
      if (lastName) {
        todoStore.setFilters({
          ...todoStore.Filters,
          lastName: lastName,
        });
      }
      getSearchBasedTodos({
        category: [...todoStore.Filters.categories, todoType],
        ...(todoStore.Filters.unitNumber
          ? { unitNumber: todoStore.Filters.unitNumber }
          : {}),
      } as TodoSearchFormInputs);
    }
    return () => {
      todoStore.setFilters({
        ...todoStore.Filters,
        categories: [],
      });
      todoStore.setSearchType('');
    };
  }, [getSearchBasedTodos, location, todoStore]);

  const onTrainingTodoAction = (
    trainingDetails: any,
    action: 'video' | 'quiz',
  ) => {
    if (action === 'video') {
      setShowTrainingVideoDialog(true);
    }
    if (action === 'quiz') {
      setShowTrainingQuizDialog(true);
    }
    todoStore.setSelectedTrainingAssignment(trainingDetails);
  };

  const onCloseQuizDialog = (action: 'close' | 'refresh' | 'warn') => {
    if (action === 'close') {
      setShowTrainingQuizDialog(false);
    } else if (action === 'warn') {
      setShowTrainingCloseDialog(true);
    } else if (action === 'refresh') {
      setShowTrainingQuizDialog(false);
      getTodosLists(true);
    }
  };

  const onCloseQuizWarnDialog = (action: 'close' | 'abandon') => {
    if (action === 'close') {
      setShowTrainingCloseDialog(false);
    } else if (action === 'abandon') {
      setShowTrainingCloseDialog(false);
      setShowTrainingQuizDialog(false);
      getTodosLists(true);
    }
  };

  const onCloseVideoDialog = (refresh: boolean) => {
    if (refresh) {
      getTodosLists(true);
    }
    setShowTrainingVideoDialog(false);
  };

  const onShowCertifcateClick = (url: string) => {
    setCertificateUrl(url);
    setShowTrainingVideoDialog(false);
    setShowTrainingQuizDialog(false);
    setShowCertificateDialog(true);
  };

  const onCloseCertifcateDialog = (refresh: boolean) => {
    if (refresh) {
      getTodosLists(true);
    }
    setCertificateUrl('');
    setShowCertificateDialog(false);
  };

  // Show all ToDos button
  const renderShowAllTodosOption = (): ReactElement => {
    return todoId && authStore.UserAccessType !== AccessTypes.CompanyAdmin ? (
      <Typography
        component={'div'}
        className={classes.fitem}
        onClick={() => navigate('/todos')}>
        <Typography component={'div'} className={classes.ftex}>
          Show all To-Dos
        </Typography>
      </Typography>
    ) : (
      <span />
    );
  };

  const getTodosFilterPills = (todosDueIn: string) => {
    if (todosDueIn === '10-5 Days') {
      return '5-10 Days';
    } else if (todosDueIn === '30-11 Days') {
      return '11-30 Days';
    } else {
      return todosDueIn;
    }
  };

  return comingSoon ? (
    <ComingSoon />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {!todoStore.ShowLoader && (
        <Grid container>
          <Grid item md={12}>
            <Typography component={'div'} className={classes.filterItem}>
              {renderShowAllTodosOption()}

              {todoStore.Filters.type ? (
                <Typography
                  component={'div'}
                  className={classes.fitem}
                  onClick={() => removeFilter('type', '')}>
                  <Typography component={'div'} className={classes.ftex}>
                    {todoStore.Filters.type}
                  </Typography>
                  <Typography
                    component={'div'}
                    className={classes.ftex}
                    style={{
                      paddingLeft: '.5rem',
                      transform: 'scaleX(1.3)',
                    }}>
                    X
                  </Typography>
                </Typography>
              ) : (
                ''
              )}

              {todoStore.Filters.dueIn ? (
                <Typography
                  component={'div'}
                  className={classes.fitem}
                  onClick={() => removeFilter('dueIn', '')}>
                  <Typography component={'div'} className={classes.ftex}>
                    {getTodosFilterPills(todoStore.Filters.dueIn)}
                  </Typography>
                  <Typography
                    component={'div'}
                    className={classes.ftex}
                    style={{
                      paddingLeft: '.5rem',
                      transform: 'scaleX(1.3)',
                    }}>
                    X
                  </Typography>
                </Typography>
              ) : (
                ''
              )}

              {todoStore.Filters.categories &&
              todoStore.Filters.categories.length
                ? todoStore.Filters.categories.map(
                    (val: any, index: number) => (
                      <Typography
                        component={'div'}
                        className={classes.fitem}
                        key={index}
                        onClick={() =>
                          removeFilter('categories', String(index))
                        }>
                        <Typography component={'div'} className={classes.ftex}>
                          {formatCatergories(val)?.title}
                        </Typography>
                        <Typography
                          component={'div'}
                          className={classes.ftex}
                          style={{
                            paddingLeft: '.5rem',
                            transform: 'scaleX(1.3)',
                          }}>
                          X
                        </Typography>
                      </Typography>
                    ),
                  )
                : ''}
            </Typography>
          </Grid>
        </Grid>
      )}

      {showUpdate.show && (
        <TodoForm
          todoType={showUpdate?.data?.todoType || ''}
          todoCategoryType={showUpdate?.data?.todoCategoryType || ''}
          todoId={showUpdate.data.todoId}
          unitId={showUpdate.data.unitId}
          contactId={showUpdate.data.contactId}
          caseId={showUpdate.data.caseId}
          variant={showUpdate.data.type}
          onClose={() => {
            setShowUpdate({ data: {}, show: false });
            todoStore.IsRefresh ? getTodosLists(false) : null;
          }}
          permitInfo={showUpdate.data.permitInfo}
        />
      )}
      {todoStore.ShowLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          style={{ padding: '0 2rem' }}
          justifyContent="space-between">
          <Grid item md={12} style={{ paddingTop: 'unset !important' }}>
            <TableContainer
              id="todos-table"
              sx={{ maxHeight: 'calc(100vh - 180px)' }}>
              <InfiniteScroll
                dataLength={
                  todoStore.TodoList && todoStore.TodoList.length
                    ? todoStore.TodoList.length
                    : 0
                }
                style={{ overflow: 'unset' }}
                next={getMoreTodos}
                hasMore={todoStore.HasMoreResults}
                scrollableTarget={'todos-table'}
                loader={
                  getMoreLoader && (
                    <div style={{ textAlign: 'center', width: '100%' }}>
                      <CircularProgress
                        size={30}
                        sx={{ color: '#DEC330', marginTop: 20 }}
                      />
                    </div>
                  )
                }
                endMessage>
                <Table
                  className={classes.table}
                  style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 0.5rem',
                  }}
                  stickyHeader
                  aria-label="sticky table">
                  <TableHead>
                    <TodoHeader {...{ todoHeader, todoStore }} />
                  </TableHead>
                  <TableBody>
                    {todoStore.TodoList && todoStore.TodoList.length > 0 ? (
                      <TodoLists
                        resolveUR={todoResolveForm}
                        onTrainingTodoAction={onTrainingTodoAction}
                      />
                    ) : (
                      <TableRow
                        className={classes.rowHeight}
                        style={{ minHeight: '80px' }}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          align="center"
                          colSpan={8}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                fontFamily: 'FiraSans-Semibold',
                                fontSize: '16px',
                                fontWeight: 600,
                                height: '23px',
                                letterSpacing: 0,
                                lineHeight: '23px',
                                textAlign: 'center',
                              }}>
                              No data available
                            </Typography>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {showTrainingVideoDialog && (
        <TrainingVideoDialog
          showDialog={showTrainingVideoDialog}
          onOpenQuiz={() => {
            setShowCertificateDialog(false);
            setShowTrainingVideoDialog(false);
            setShowTrainingQuizDialog(true);
          }}
          closeDialog={onCloseVideoDialog}
          onShowCertificateClick={onShowCertifcateClick}
        />
      )}
      {showTrainingQuizDialog && (
        <TrainingQuizDialog
          showDialog={showTrainingQuizDialog}
          closeDialog={onCloseQuizDialog}
          onShowCertificateClick={onShowCertifcateClick}
        />
      )}
      {showTrainingCloseDialog && (
        <TrainingCloseDialog
          showDialog={showTrainingCloseDialog}
          closeDialog={onCloseQuizWarnDialog}
        />
      )}
      {showCertificateDialog && (
        <TrainingCertificateViewDialog
          showDialog={showCertificateDialog}
          closeDialog={onCloseCertifcateDialog}
          url={certificateUrl}
        />
      )}
    </Box>
  );
};

export default observer(Todos);
