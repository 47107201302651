import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  checkboxLabels: {
    '&.MuiFormControlLabel-root': {
      alignItems: 'center !important',
    },
  },
  divider: {
    color: '#E8E8E8',
    marginTop: '31px !important',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  infobox: {
    backgroundColor: '#F8F8FA !important',
    borderRadius: '7px',
    color: '#7A777D !important',
    fontSize: '0.85rem !important',
    lineHeight: '15px',
    marginTop: '20px',
    minHeight: '10px',
    padding: '1.5rem',
    position: 'relative',
  },
  infoicon: {
    left: '5px',
    position: 'absolute',
    top: '5px',
  },
  labels: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  selectClassName: {
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    // opacity: '0.43 !important',
  },
}));

export default useStyles;
