import { CircularProgress, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { VictoryPie, VictoryTooltip } from 'victory';

type ColorTextMapType = { text: string; color: number; order: number };
const Label = styled('div')(() => ({
  alignItems: 'center',
  cursor: 'default',
  justifyContent: 'flex-start',
  margin: 5,
  padding: 1,
}));

const LabelContainer = styled('div')(() => ({
  height: '100%',
  justifyContent: 'center',
  overflowY: 'auto',
  position: 'relative',
  width: '55%',
}));

const Div = styled('div')(() => ({
  width: '45%',
  zIndex: 5,
}));

const Circle = styled('div')<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  height: 10,
  width: 10,
}));

const Svg = styled('svg')(() => ({
  left: -35,
  overflow: 'visible',
  position: 'relative',
}));

const Section = styled('section')(() => ({
  height: 200,
  width: '90%',
}));

const DueIn = styled('div')(() => ({
  color: '#241A2E',
  fontFamily: 'FiraSans-Medium',
  fontSize: '0.8rem',
  fontStyle: 'italic',
  opacity: '50%',
  paddingLeft: '0.5rem',
}));

const Text = styled('div')(() => ({
  color: '#241A2E',
  fontFamily: 'FiraSans-Medium',
  fontSize: '0.9rem',
}));

const Value = styled('div')(() => ({
  color: '#241A2E',
  fontFamily: 'FiraSans-Regular',
  fontSize: '0.9rem',
}));

const colors = [
  /* orange*/ '#E8932E',
  /* red*/ '#A70D0D',
  /* black*/ '#000000',
  /* yellow*/ '#D8BF57',
  /* green*/ '#0DA728',
];
const MessageColorMap: ColorTextMapType[] = [
  {
    color: 1,
    order: 0,
    text: 'Missing & Past Due',
  },
  {
    color: 0,
    order: 1,
    text: 'Less Than 5 Days',
  },
  {
    color: 3,
    order: 2,
    text: '5-10 Days',
  },
  {
    color: 2,
    order: 3,
    text: '11-30 Days',
  },
  {
    color: 2,
    order: 3,
    text: '11-14 Days',
  },
  {
    color: 4,
    order: 4,
    text: 'More Than 30 days',
  },
  {
    color: 4,
    order: 4,
    text: 'More Than 14 Days',
  },
];

const getIndexColor = (index: number, value?: string) => {
  try {
    if (
      value &&
      MessageColorMap.filter(
        (val: ColorTextMapType) =>
          val.text.toLowerCase() === value.toLowerCase(),
      ).length > 0
    ) {
      return MessageColorMap.filter(
        (val: ColorTextMapType) =>
          val.text.toLowerCase() === value.toLowerCase(),
      )[0]?.color;
    } else {
      if (index < colors.length) {
        return index;
      } else {
        const diff = index - colors.length;
        return diff;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const getIndexOrder = (index: number, value?: string) => {
  try {
    if (
      value &&
      MessageColorMap.filter(
        (val: ColorTextMapType) =>
          val.text.toLowerCase() === value.toLowerCase(),
      ).length > 0
    ) {
      return MessageColorMap.filter(
        (val: ColorTextMapType) =>
          val.text.toLowerCase() === value.toLowerCase(),
      )[0]?.order;
    } else {
      return index;
    }
  } catch (e) {
    console.error(e);
  }
};
type props = {
  todosData: any;
  onClick?: (index: number) => unknown;
};
const TodosGraphs: React.FC<props> = ({
  todosData = {},
  onClick = () => {},
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [externalEventMutations, setExternalEventMutations] = useState<any>();

  const [newData, setNewData] = useState<{ x: string; y: number }[]>();
  const [colorScheme, setColorScheme] = useState<string[]>([]);
  const values: { x: string; y: number }[] = newData || [];

  useEffect(() => {
    setShowLoader(true);
    const obj: { x: string; y: number }[] = [];
    for (const key in todosData) {
      if (todosData[key] !== undefined) obj.push({ x: key, y: todosData[key] });
    }
    obj?.sort((a, b) => {
      return (getIndexOrder(1, a?.x) || 0) - (getIndexOrder(1, b?.x) || 0);
    });
    setColorScheme(obj?.map((val, i) => colors[getIndexColor(i, val.x) || 0]));
    setNewData(obj);
    setShowLoader(false);
  }, [todosData]);

  const handleMouseHover = (index: number) => {
    let x = 0;
    const y = [];
    while (x < 10) {
      if (x !== index) {
        y.push({
          callback: () => setExternalEventMutations(undefined),
          eventKey: [`${x}`],
          mutation: () => ({
            style: { fill: 'antiquewhite' },
          }),
          target: ['data'],
        });
      }
      x = x + 1;
    }
    setExternalEventMutations(y);
  };

  const reset = () => {
    try {
      const y: any = [];
      const _newData = newData;
      _newData
        ?.sort((a, b) => {
          return (getIndexOrder(1, a?.x) || 0) - (getIndexOrder(1, b?.x) || 0);
        })
        .forEach(function (val, index) {
          y.push({
            callback: () => setExternalEventMutations(undefined),
            eventKey: [`${index}`],
            mutation: () => ({
              style: { fill: colors[getIndexColor(index, val.x) as number] },
            }),
            target: ['data'],
          });
        });
      setNewData(_newData);
      setExternalEventMutations(y);
    } catch (e) {
      console.error(e);
    }
  };

  return showLoader ? (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}>
      <CircularProgress size={30} sx={{ color: '#DEC330' }} />
    </div>
  ) : (
    <Section className="flex-row">
      <Div>
        <Svg width={100} height={100}>
          <text
            x={85}
            y={100}
            style={{
              font: 'bold 18px FiraSans-Bold',
            }}>
            {values.reduce((prev, value) => prev + value.y, 0)}
          </text>
          <text
            x={85}
            y={120}
            style={{
              font: 'bold 14px FiraSans-Bold',
            }}>
            Total
          </text>

          <VictoryPie
            width={200}
            height={200}
            standalone={false}
            events={[
              {
                eventHandlers: {
                  onClick: (event, data: { index: number }) => {
                    onClick(data.index);
                  },
                },
                target: 'data',
              },
            ]}
            externalEventMutations={externalEventMutations}
            labelComponent={
              <VictoryTooltip style={{ fill: 'black' }} flyoutPadding={15} />
            }
            innerRadius={70}
            colorScale={colorScheme}
            padAngle={1}
            data={newData}
            labels={({ datum }) => `${datum.x as number}: ${datum.y as number}`}
          />
        </Svg>
      </Div>

      <LabelContainer className="flex-column">
        <DueIn>Due In:</DueIn>
        {values
          .sort((a, b) => {
            return (
              (getIndexOrder(1, a?.x) || 0) - (getIndexOrder(1, b?.x) || 0)
            );
          })
          .map((value, index) => (
            <Label
              key={index}
              className="flex-row"
              onClick={() => onClick(index)}
              onMouseLeave={reset}
              onMouseEnter={() => handleMouseHover(index)}>
              <Circle color={colors[getIndexColor(index, value.x) || 0]} />
              <div className="flex-grow-1">
                <Text className="margin-h-10">{value.x}</Text>
              </div>
              <Value>{value.y} </Value>
            </Label>
          ))}
      </LabelContainer>
    </Section>
  );
};

export default TodosGraphs;
