import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: '1px solid #403849',
    marginBottom: '1rem !important',
  },
  close: {
    color: '#D1D1D1 !important',
    fontSize: '0.875rem !important',
    marginLeft: '0.3rem !important',
  },
  dflexA: {
    alignItems: 'center',
    display: 'flex',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      maxWidth: '600px',
      // height: 'auto',
    },
  },
  filteIcon: {
    width: '18px',
  },
  filterBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#fff !important',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  filterItem: {
    alignItems: 'center',
    backgroundColor: '#E8E8E8',
    borderRadius: '24px',
    color: '#979598 !important',
    cursor: 'pointer !important',
    display: 'flex',
    fontSize: '0.875rem !important',
    height: '34px',
    justifyContent: 'space-between',
    margin: '0 0.5rem 0.5rem 0!important',
    minWidth: '72px',
    padding: '0 1rem',
  },
  filterList: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0.9rem 1.8rem 0.4rem',
    width: '100%',
  },
  filterText: {
    color: '#979598 !important',
    cursor: 'pointer !important',
    fontSize: '0.875rem !important',
  },
  inputF: {
    '&::placeholder': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      opacity: '0.43',
    },
    '&:focusVisible': {
      outline: 'unset !important',
    },
    background: 'unset !important',
    border: 'unset !important',
    color: '#ffff !important',
    width: '100%',
  },
  loader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: '203px',
  },
  notFound: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Semibold',
    fontSize: '16px',
    lineHeight: '23px',
    textAlign: 'center',
  },
  resetF: {
    alignItems: 'center',
    color: '#777777 !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Bold',
    fontSize: '0.75rem !important',
    justifyContent: 'center !important',
    letterSpacing: '0.8px',
    textDecoration: 'underline solid #777777',
  },
  searcBox: {
    alignItems: 'center',
    backgroundColor: '#403849',
    borderRadius: '7px',
    display: 'flex',
    height: '43px',
    marginBottom: '1rem !important',
  },
  tableHeaderBg: {
    backgroundColor: '#f5f5f5!important',
  },
  tableRow: {
    '&:hover': {
      '& #upload': {
        visibility: 'visible !important',
      },
    },
  },
  uploadDoc: {
    '& img': {
      transform: 'rotate(180deg)',
    },
    '& span': {
      marginLeft: '3px',
    },
    alignItems: 'end',
    color: '#0061FF',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-SemiBold',
    fontSize: '0.875rem',
    fontWeight: 600,
    textDecoration: 'underLine',
    visibility: 'hidden',
  },
}));
export default useStyles;
