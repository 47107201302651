import { makeAutoObservable, toJS } from 'mobx';

import { Address, DotUpdate } from '../types';
import { RootStore } from './';

const InitialValue: DotUpdate = {
  biennialReport: false,
  companyDetails: {
    email: '',
    phoneNumber: '',
    profileName: '',
  },
  dot: '',
  dotpin: '',
  estimatedMiles: '',
  fein: '',
  mailAddress: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
  ownerssn: '',
  physicalAddress: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
  physicalMailAddressSameStatus: false,
  vmtReport: false,
};

export class DotUpdateStore {
  private _dotUpdate: DotUpdate = { ...InitialValue };
  private _initialData: DotUpdate = { ...InitialValue };
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get dotUpdateDetail(): DotUpdate {
    return { ...toJS(this._dotUpdate) };
  }

  setPhysicalAddressDetail(addressDetail: Address) {
    this._dotUpdate.physicalAddress = {
      ...this._dotUpdate.physicalAddress,
      ...addressDetail,
    };
  }

  setMailAddressDetail(addressDetail: Address) {
    this._dotUpdate.mailAddress = {
      ...this._dotUpdate.mailAddress,
      ...addressDetail,
    };
  }

  setCompanyDetail(companyDetail: Address) {
    this._dotUpdate.companyDetails = {
      ...this._dotUpdate.companyDetails,
      ...companyDetail,
    };
  }

  setDotUpdateDetail(key: string, value: string) {
    if (key === 'vmtReport' && !value) {
      this._dotUpdate.estimatedMiles = '';
    }
    this._dotUpdate = {
      ...this._dotUpdate,
      [key]: value,
    };
  }

  getInitialData() {
    return { ...toJS(this._initialData) };
  }

  resetDotUpdate() {
    this._dotUpdate = { ...this._initialData };
  }

  async setInitialData() {
    const response: any =
      await this.rootStore.apiStore.dotUpdateApiStore.getInitialData();

    if (response.isOk()) {
      const {
        value: {
          companyProfile: { name: cmpname, email, phone },
          dotNumber,
          dotPin,
          feinNumber,
          mailingAddress: {
            address: mailAddr,
            city: mailCity,
            state: mailState,
            zip: mailZip,
          },
          ownerSsn,
          physicalAddress: {
            address: physAddr,
            city: physCity,
            state: physState,
            zip: physZip,
          },
          sameAsPhysicalAddress,
        },
      } = response;

      const returndata = {
        biennial: false,
        dot: dotNumber || '',
        dotPin: dotPin || '',
        email: email || '',
        estimatedMiles: '',
        fein: feinNumber || '',
        mailingAddress: mailAddr || '',
        mailingCity: mailCity || '',
        mailingState: mailState || '',
        mailingZip: mailZip || '',
        name: cmpname || '',
        // ownerSsn: ownerSsn || '',
        phoneNumber: phone || '',
        physicalAddress: physAddr || '',
        physicalCity: physCity || '',
        physicalState: physState || '',
        physicalZip: physZip || '',
        sameAs: sameAsPhysicalAddress || false,
        ssn: ownerSsn || '',
        vmt: false,
      };

      this._dotUpdate = {
        biennialReport: false,
        companyDetails: {
          email: email || '',
          phoneNumber: phone || '',
          profileName: cmpname || '',
        },
        dot: dotNumber || '',
        dotpin: dotPin || '',
        estimatedMiles: '',
        fein: feinNumber || '',
        mailAddress: {
          address: mailAddr || '',
          city: mailCity || '',
          state: mailState || '',
          zipcode: mailZip || '',
        },
        ownerssn: ownerSsn || '',
        physicalAddress: {
          address: physAddr || '',
          city: physCity || '',
          state: physState || '',
          zipcode: physZip || '',
        },
        physicalMailAddressSameStatus: sameAsPhysicalAddress || false,
        vmtReport: false,
      };

      this._initialData = { ...this._dotUpdate };

      return {
        data: returndata,
        message: '',
        status: 'success',
      };
    }

    if (response.isErr()) {
      return {
        data: {},
        message: response.error.message || 'Error while getting data',
        status: 'error',
      };
    }
  }

  async addDotUpdateDetail(todoId?: string) {
    const {
      biennialReport,
      companyDetails: { email = '', phoneNumber = '', profileName = '' },
      dot = '',
      dotpin = '',
      estimatedMiles = '',
      fein = '',
      ownerssn = '',
      physicalAddress: {
        address: physiacalAddr = '',
        city: physicalCity = '',
        state: physicalState = '',
        zipcode: physicalZipCode = '',
      },
      mailAddress: {
        address: mailAddr = '',
        city: mailCity = '',
        state: mailState = '',
        zipcode: mailZipCode = '',
      },
      physicalMailAddressSameStatus = false,
      vmtReport = false,
    } = this._dotUpdate;

    const apiData: any = {
      companyProfileName: profileName,
      dotNumber: dot,
      dotPin: dotpin,
      email: email,
      feinNumber: fein,
      isBiennialReport: biennialReport,
      isVMTReport: vmtReport,
      mailingAddress: {
        address: mailAddr,
        city: mailCity,
        state: mailState,
        zip: mailZipCode,
      },
      ownerSSN: ownerssn,
      phone: phoneNumber,
      physicalAddress: {
        address: physiacalAddr,
        city: physicalCity,
        state: physicalState,
        zip: physicalZipCode,
      },
      sameAsPhysicalAddress: physicalMailAddressSameStatus,
    };

    if (vmtReport) {
      apiData.estimatedMiles = estimatedMiles;
    }
    if (todoId && todoId != '') {
      apiData['todoId'] = todoId;
    }
    const addResponse: any =
      await this.rootStore.apiStore.dotUpdateApiStore.addDotUpdateReport(
        apiData,
      );

    if (addResponse.isOk()) {
      this._dotUpdate = { ...this._initialData };
    }
    return addResponse;
  }
}
