// import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
// import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { AuthStore } from '../../../store/auth';
import { TodosStore } from '../../../store/todos';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import useStyles from '../SearchFilterStyles';
import search from './../../../assets/images/h-search.svg';

const TODO_TYPES = [
  'Cab Card/ IRP Registration (F)',
  'IRP Permit (C)',
  'Local Registration (F)',
  'Trailer Registration (F)',
  'UCR (C)',
  'Road Taxes (F)',
  // 'Annual Report (C)',
  'IFTA Permit (C)',
  'Fuel Tax Filing (C)',
  'NM Permit (F)',
  'NY Permit (F)',
  'OR Monthly Tax Filing (C)',
  'OR Permit (F)',
  'DOT Biennal Update (C)',
  'DOT VMT Update (C)',
  'AR Report (C)',
  'Hazmat Permit (C)',
  'SCAC (C)',
  // 'CA/MCP (C)',
  "Driver's License (D)",
  'Medical Card (D)',
  'MVR (D)',
  'Clearinghouse Query (D)',
  'Driver Application (D)',
  'Annual Inspection (F)',
  'Drug Test (D)',
  'PSP (D)',
  'Background Check (D)',
  'Pre-Employment MVR (D)',
  'Pre-Employment PSP Authorization (D)',
  'Pre-Employment Drug Test (D)',
  'Pre-Employment Clearinghouse Query (D)',
  'Pre-Employment Medical Card (D)',
  'Pre-Employment Driver Application (D)',
  'Pre-Employment Background Check (D)',
  'Pre-Employment Driver License (D)',
  'MVR Authorization (D)',
  'Termination Acknowledgment Form (C)',
];

const TRAINING_TODO_TYPES = [
  'All (T)',
  'Video-based (T)',
  'Document-based (T)',
];

const categoryTypeMapping: any = {
  Company: '(C)',
  Driver: '(D)',
  Fleet: '(F)',
  Training: '(T)',
};

type todoFormTypes = {
  classes: any;
  todoController: any;
  todoSubmit: any;
  filter: any;
  resetFilter: any;
  searchPage: any;
  setFilters: (src: any) => void;
  submitTodoFilter: any;
  todoStore: TodosStore;
  userStore: any;
  authStore: AuthStore;
  todoReset: any;
  onCategoryChange: (categories: string[]) => unknown;
  searchTypeOfTodoForm: string;
  setTodoControlValue: any;
};

const TodoSearchForm: React.FC<todoFormTypes> = (props: todoFormTypes) => {
  const { trainingStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const {
    classes,
    todoController,
    todoSubmit,
    filter,
    resetFilter,
    searchPage,
    setFilters,
    submitTodoFilter,
    todoStore,
    userStore,
    authStore,
    todoReset,
    onCategoryChange,
    searchTypeOfTodoForm,
    setTodoControlValue,
  } = props;

  const [selectSearch, setSelectSearch] = useState('');
  const [todoTypes, setTodoTypes] = useState<string[]>([...TODO_TYPES]);
  const [assignees, setAssignees] = useState<any[]>([]);
  const [reasons, setReasons] = useState<any[]>([]);

  const todoCategories: any = [
    {
      index: 0,
      show: userStore.UserData.accessType === 'companyAdmin' ? true : false,
      title: 'Company',
    },
    {
      index: 1,
      show:
        userStore.UserData.accessType === 'companyAdmin' ||
        userStore.UserData.accessType === 'ownerOperator'
          ? true
          : false,
      title: 'Driver',
    },
    {
      index: 2,
      show:
        userStore.UserData.accessType === 'companyAdmin' ||
        userStore.UserData.accessType === 'ownerOperator'
          ? true
          : false,
      title: 'Fleet',
    },
    {
      index: 2,
      show:
        userStore.UserData.accessType === 'companyAdmin' ||
        userStore.UserData.accessType === 'ownerOperator'
          ? true
          : false,
      title: 'Training',
    },
  ];

  const todoDueIn = [
    {
      name: 'Less Than 5 Days',
      value: 'Less Than 5 Days',
    },
    {
      name: '5-10 Days',
      value: '10-5 Days',
    },
    {
      name: '11-30 Days',
      value: '30-11 Days',
    },
    {
      name: 'More than 30 days',
      value: 'more than 30 days',
    },
    {
      name: 'Missing & Past Due',
      value: 'missing & past due',
    },
  ];

  const todoDueInForTraining = [
    {
      name: 'Less Than 5 Days',
      value: 'Less Than 5 Days',
    },
    {
      name: '5-10 Days',
      value: '10-5 Days',
    },
    {
      name: '11-14 Days',
      value: '14-11 Days',
    },
    {
      name: 'More Than 14 Days',
      value: 'more than 14 days',
    },
  ];

  const getDueInOptions = () => {
    let _dueInOptions = [];
    if (!filter.categories.includes('Training')) {
      _dueInOptions = todoDueIn;
    } else {
      if (filter.categories.length > 1) {
        _dueInOptions = todoDueIn;
      } else {
        _dueInOptions = todoDueInForTraining;
      }
    }

    return _dueInOptions.map((each: { name: string; value: string }) => ({
      label: each.name,
      optionClassName: classes.optionsClassName,
      value: each.value,
    }));
  };

  const filterTodoTypes = (arr: string[]) => {
    let temp: string[] = [];
    if (arr.includes('Fleet'))
      temp = [
        ...temp,
        ...TODO_TYPES.filter((todoType) => todoType.includes('(F)')),
      ];
    if (arr.includes('Driver'))
      temp = [
        ...temp,
        ...TODO_TYPES.filter((todoType) => todoType.includes('(D)')),
      ];
    if (arr.includes('Company'))
      temp = [
        ...temp,
        ...TODO_TYPES.filter((todoType) => todoType.includes('(C)')),
      ];
    if (arr.includes('Training')) temp = [...temp, ...TRAINING_TODO_TYPES];
    if (!arr.length) temp = [...TODO_TYPES];
    return temp;
  };

  const getSearchType = (categories: string[]): string => {
    if (categories.length !== 1) return '';
    if (categories.includes('Driver')) return 'Driver';
    if (categories.includes('Fleet')) return 'Unit';
    if (categories.includes('Company')) return '';
    if (categories.includes('Training')) return 'Training';
    return '';
  };

  const setCategories = useCallback(
    (value: string) => {
      const arr = [...filter.categories];
      if (arr.some((e: any) => e === value)) {
        const index = arr.findIndex((e) => e === value);
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }
      setFilters((pre: any) => ({
        ...pre,
        categories: arr,
      }));
      const searchType = getSearchType(arr);
      todoStore.setSearchType(searchType);
      onCategoryChange(arr);
      setTodoTypes(filterTodoTypes(arr));
      if (!arr.includes('Training')) {
        setFilters((pre: any) => ({
          ...pre,
          trainingAssignedTo: '',
          trainingReason: '',
        }));
      }
      if (
        filter.type &&
        !arr.some((category: any) =>
          filter.type.includes(categoryTypeMapping[category]),
        )
      ) {
        setFilters((pre: any) => ({
          ...pre,
          type: '',
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.categories, setFilters, onCategoryChange],
  );

  useEffect(() => {
    if (
      !(
        todoStore.Filters.categories.length ||
        todoStore.Filters.dueIn ||
        todoStore.Filters.type ||
        todoStore.Filters.firstName ||
        todoStore.Filters.lastName ||
        todoStore.Filters.simplexId ||
        todoStore.Filters.year ||
        todoStore.Filters.make ||
        todoStore.Filters.vinNumber ||
        todoStore.Filters.unitNumber
      )
    ) {
      todoReset();
    }

    if (
      filter.year != '' ||
      filter.make != '' ||
      filter.vinNumber != '' ||
      filter.unitNumber != ''
    ) {
      setSelectSearch('Unit');
    } else {
      setSelectSearch('Driver');
    }

    if (userStore.UserData.accessType === 'driver') {
      authStore.UserRoleAcess === 'Super Admin' ||
        (authStore.UserRoleAcess === 'Company Admin' &&
          (userStore.UserData['accessType'] = 'companyAdmin'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  const getAllTrainingAssignees = useCallback(async () => {
    if (!authStore.IsCompanyAdminLogin) {
      return;
    }
    if (trainingStore.TrainingAssigneesData.length > 0) {
      setAssignees(trainingStore.TrainingAssigneesData);
      setTodoControlValue(
        'trainingAssignedTo',
        filter.trainingAssignedTo || '',
      );
    } else {
      trainingStore.setTrainingAssigneesLoader(true);
      const resp = await trainingStore.getAllUsersForTraining({
        limit: '5000',
        searchText: '',
        userType: 'backOfficePersonnel',
      });
      if (resp.isOk()) {
        const { response } = resp.value;
        setAssignees(response);
        trainingStore.setTrainingAssigneesData(response);
        setTodoControlValue(
          'trainingAssignedTo',
          filter.trainingAssignedTo || '',
        );
      } else {
        enqueueSnackbar(
          'Unable to fetch Training Assignees',
          SnackBarConfig('e'),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, trainingStore]);

  const getTrainingAssignmentReasons = useCallback(async () => {
    trainingStore.setTrainingAssignmentReasonsLoader(true);
    const resp = await trainingStore.getTrainingAssignmentReasons({
      showSystemReason: true,
    });
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Assignment Reasons',
        SnackBarConfig('e'),
      );
    } else {
      setReasons(trainingStore.TrainingAssignmentReasonsData);
      setTodoControlValue('trainingReason', filter.trainingReason || '');
    }
    trainingStore.setTrainingAssignmentReasonsLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, trainingStore]);

  useEffect(() => {
    if (filter.categories && filter.categories.includes('Training')) {
      getAllTrainingAssignees();
      getTrainingAssignmentReasons();
    }
  }, [
    filter.categories,
    getAllTrainingAssignees,
    getTrainingAssignmentReasons,
    trainingStore,
  ]);

  useEffect(() => {
    if (todoStore.SearchType === 'Fleet') {
      setSelectSearch('Unit');
    } else {
      setSelectSearch(todoStore.SearchType || searchTypeOfTodoForm);
    }
    if (
      filter.categories &&
      Array.isArray(filter.categories) &&
      filter.categories.length > 0
    ) {
      setTodoTypes(filterTodoTypes(filter.categories));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTypeOfTodoForm]);

  return (
    <form onSubmit={todoSubmit(submitTodoFilter)} id={searchPage}>
      {/* Category filter */}
      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) && (
        <>
          <Typography component={'h3'}>CATEGORY</Typography>
          <Typography
            component={'div'}
            className={classes.borderBottom}
            style={{ paddingBottom: '1rem' }}>
            {todoCategories.map(
              (tab: any, index: number) =>
                tab.show && (
                  <Grid
                    key={index}
                    item
                    className={classes.dflexA}
                    onClick={() => setCategories(tab.title)}>
                    <input
                      type="checkbox"
                      tabIndex={0}
                      readOnly
                      checked={filter.categories.some(
                        (val: any) => val === tab.title,
                      )}
                    />
                    <Typography component={'div'} style={{ cursor: 'pointer' }}>
                      {tab.title}
                    </Typography>
                  </Grid>
                ),
            )}
          </Typography>
        </>
      )}
      {/* Type filter */}
      {userStore.UserData.accessType === 'companyAdmin' && (
        <>
          <Typography component={'h3'}>TYPE</Typography>
          <Typography component={'div'} className={classes.borderBottom}>
            <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
              <Controller
                control={todoController}
                defaultValue=""
                name="type"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={filter.type}
                      inputRefObj={input1Ref}
                      size={'small'}
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          type: e,
                        }));
                      }}
                      isEditable={false}
                      selectClassName={classes.selectWhiteBg}
                      placeHolder={'Select'}
                      optionsList={todoTypes.map((value: any) => ({
                        label: value,
                        optionClassName: classes.optionsClassName,
                        value,
                      }))}
                      // {
                      //   <>
                      //     <option
                      //       value=""
                      //       disabled
                      //       className={classes.optionsClassName}>
                      //       Select
                      //     </option>
                      //     {todoTypes.map((value: any, index: number) => (
                      //       <option
                      //         key={index}
                      //         value={value}
                      //         className={classes.optionsClassName}>
                      //         {value}
                      //       </option>
                      //     ))}
                      //   </>
                      // }
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </>
      )}
      {userStore.UserData.accessType === 'ownerOperator' &&
        selectSearch !== 'Training' && (
          <>
            <Typography component={'h3'}>TYPE</Typography>
            <Typography component={'div'} className={classes.borderBottom}>
              <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="type"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isRefreshValue={true}
                        value={filter.type}
                        inputRefObj={input1Ref}
                        size={'small'}
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            type: e,
                          }));
                        }}
                        isEditable={false}
                        selectClassName={classes.selectWhiteBg}
                        placeHolder={'Select'}
                        optionsList={todoTypes
                          .filter(
                            (el) => el.includes('(D)') || el.includes('(F)'),
                          )
                          .map((value: any) => ({
                            label: value,
                            optionClassName: classes.optionsClassName,
                            value,
                          }))}
                      />
                    );
                  }}
                />
              </Grid>
            </Typography>
          </>
        )}
      {userStore.UserData.accessType === 'driver' &&
        selectSearch !== 'Training' && (
          <>
            <Typography component={'h3'}>TYPE</Typography>
            <Typography component={'div'} className={classes.borderBottom}>
              <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="type"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isRefreshValue={true}
                        value={filter.type}
                        inputRefObj={input1Ref}
                        size={'small'}
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            type: e,
                          }));
                        }}
                        isEditable={false}
                        selectClassName={classes.selectWhiteBg}
                        placeHolder={'Select'}
                        optionsList={todoTypes
                          .filter((el) => el.includes('(D)'))
                          .map((value: any) => ({
                            label: value,
                            optionClassName: classes.optionsClassName,
                            value,
                          }))}
                      />
                    );
                  }}
                />
              </Grid>
            </Typography>
          </>
        )}
      {/* Due In filter */}
      {todoStore.Status === 'Open' && (
        <>
          <Typography component={'h3'}>DUE IN</Typography>
          <Typography component={'div'} className={classes.borderBottom}>
            <Grid className={classes.dflexA} style={{ padding: '1rem 0' }}>
              <Controller
                control={todoController}
                defaultValue=""
                name="dueIn"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.dueIn}
                      value={filter.dueIn}
                      inputRefObj={input1Ref}
                      size={'small'}
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          dueIn: e,
                        }));
                      }}
                      isEditable={false}
                      selectClassName={classes.selectWhiteBg}
                      placeHolder={'Select'}
                      sortByOptionDisable={true}
                      optionsList={getDueInOptions()}
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </>
      )}
      {/* Search Type filter */}
      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        selectSearch &&
        selectSearch !== 'Training' && (
          <>
            <Typography component={'h3'}>SEARCH TYPE</Typography>
            <Typography component={'div'}>
              <Grid className={classes.dflexA} style={{ padding: '0.5rem 0' }}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="searchType"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isRefreshValue={true}
                        inputRefObj={ref}
                        value={value}
                        size={'small'}
                        onChangeText={(e: any) => {
                          onChange(e);
                          setSelectSearch(e);
                        }}
                        // isEditable is shouldDisable for this component
                        isEditable={true}
                        selectClassName={classes.selectWhiteBg}
                        placeHolder={'Select'}
                        optionsList={
                          [
                            {
                              label: 'Driver Search',
                              optionClassName: classes.optionsClassName,
                              value: 'Driver',
                            },
                            {
                              label: 'Unit Search',
                              optionClassName: classes.optionsClassName,
                              value: 'Unit',
                            },
                          ]
                          // <>
                          //   <option
                          //     value=""
                          //     disabled
                          //     className={classes.optionsClassName}>
                          //     Select
                          //   </option>
                          //   <option
                          //     value="Driver"
                          //     className={classes.optionsClassName}>
                          //     Driver Search
                          //   </option>
                          //   {/* {userStore.UserData.accessType !==
                          //     'ownerOperator' && ( */}
                          //   <option
                          //     value="Unit"
                          //     className={classes.optionsClassName}>
                          //     Unit Search
                          //   </option>
                          //   {/* )} */}
                          // </>
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Typography>
          </>
        )}

      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        selectSearch === 'Driver' && (
          <Typography component={'div'} className={classes.borderBottom}>
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="firstName"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        // defaultValue={filter.firstName}
                        value={filter.firstName}
                        placeholder="First Name"
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            firstName: e,
                          }));
                        }}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="lastName"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        // defaultValue={filter.lastName}
                        value={filter.lastName}
                        placeholder="Last Name"
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            lastName: e,
                          }));
                        }}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="simplexId"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        // defaultValue={filter.simplexId}
                        value={filter.simplexId}
                        placeholder="Simplex ID"
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            simplexId: e,
                          }));
                        }}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        )}
      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        selectSearch === 'Unit' && (
          <Typography component={'div'} className={classes.borderBottom}>
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="year"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        // defaultValue={filter.year}
                        value={filter.year}
                        placeholder="Year"
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            year: e,
                          }));
                        }}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="make"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        // defaultValue={filter.make}
                        value={filter.make}
                        placeholder="Make"
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            make: e,
                          }));
                        }}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="vinNumber"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        // defaultValue={filter.vinNumber}
                        value={filter.vinNumber}
                        placeholder="VIN"
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            vinNumber: e,
                          }));
                        }}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={todoController}
                  defaultValue=""
                  name="unitNumber"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        inputRefObj={ref}
                        name={name}
                        isRefreshValue={true}
                        // defaultValue={filter.unitNumber}
                        value={filter.unitNumber}
                        placeholder="UNIT Number"
                        onChangeText={(e: any) => {
                          onChange(e);
                          setFilters((pre: any) => ({
                            ...pre,
                            unitNumber: e,
                          }));
                        }}
                        isEditable={false}
                        className={classes.inputF}
                        type={'text'}
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Typography>
        )}

      {['companyAdmin', 'ownerOperator'].includes(
        userStore.UserData.accessType,
      ) &&
        filter.categories?.includes('Training') &&
        selectSearch === 'Training' && (
          <>
            <Typography component={'div'} className={classes.borderBottom}>
              <Typography
                component="h4"
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'FiraSans-Medium',
                  fontSize: '15px',
                  fontWeight: '500',
                  height: '22px',
                  letterSpacing: '1px',
                  lineHeight: '22px',
                  textTransform: 'capitalize',
                  width: '166px',
                }}>
                Reason
              </Typography>
              <Typography component="div">
                <Grid
                  className={classes.dflexA}
                  style={{ padding: '0.5rem 0' }}>
                  <Controller
                    control={todoController}
                    defaultValue=""
                    name="trainingReason"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          name={name}
                          isRefreshValue={true}
                          inputRefObj={ref}
                          value={value}
                          size="small"
                          onChangeText={(e: any) => {
                            onChange(e);
                            setFilters((pre: any) => ({
                              ...pre,
                              trainingReason: e,
                            }));
                          }}
                          selectClassName={classes.selectWhiteBg}
                          placeHolder="Select"
                          optionsList={reasons.map((r) => ({
                            label: r.name,
                            value: r.id,
                          }))}
                        />
                      );
                    }}
                  />
                </Grid>
              </Typography>
            </Typography>
            {authStore.IsCompanyAdminLogin && (
              <Typography component={'div'} className={classes.borderBottom}>
                <Typography component="h4" className={styles.filterLabel}>
                  Assigned To
                </Typography>
                <Typography component="div">
                  <Grid
                    className={classes.dflexA}
                    style={{ padding: '0.5rem 0' }}>
                    <Controller
                      control={todoController}
                      defaultValue=""
                      name="trainingAssignedTo"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <SelectInput
                            name={name}
                            isRefreshValue={true}
                            inputRefObj={ref}
                            value={value}
                            size="small"
                            onChangeText={(e: any) => {
                              onChange(e);
                              setFilters((pre: any) => ({
                                ...pre,
                                trainingAssignedTo: e,
                              }));
                            }}
                            selectClassName={classes.selectWhiteBg}
                            placeHolder="Select"
                            optionsList={assignees.map((r) => ({
                              label: r.name,
                              value: r.id,
                            }))}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Typography>
              </Typography>
            )}
          </>
        )}

      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              tabIndex={0}
              type="button"
              onClick={() => {
                setSelectSearch('');
                resetFilter();
              }}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            disabled={
              !(
                filter.categories.length ||
                filter.dueIn ||
                filter.type ||
                filter.firstName ||
                filter.lastName ||
                filter.simplexId ||
                filter.year ||
                filter.make ||
                filter.vinNumber ||
                filter.unitNumber
              )
            }
            className={classes.filterBtn}
            style={{
              backgroundColor: !(
                filter.categories.length ||
                filter.dueIn ||
                filter.type ||
                filter.firstName ||
                filter.lastName ||
                filter.simplexId ||
                filter.year ||
                filter.make ||
                filter.vinNumber ||
                filter.unitNumber
              )
                ? '#d3d3d3'
                : '#DEC330',
            }}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(TodoSearchForm);
