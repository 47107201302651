import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  accessTypeInfo: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    textTransform: 'capitalize',
  },
  cardBox: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    minHeight: '305px',
    width: '782px',
  },
  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    marginLeft: '0px !important',
    width: '100%',
  },
  editBtn: {
    color: '#0061FF',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '16px !important',
    textAlign: 'right',
    textDecoration: 'underline solid #0061ff',
  },
  expired: {
    color: '#E8932E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1rem !important',
  },
  expiredIcon: {
    fill: '#E8932E !important',
    height: '18px !important',
    margin: '0 0.1rem 0 0.25rem ',
    width: '18px !important',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },
  infodis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },
  labels: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  loader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: 'calc(100vh - 200px)',
  },
  logoutLink: {
    color: '#A70D0D !important',
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'FiraSans-SemiBold !important',
    fontSize: '16px !important',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '23px',
    paddingTop: '.5rem',
    textDecoration: 'underline solid #A70D0D !important',
  },
  profileTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '20px !important',
  },
  saveBtn: {
    background: 'none !important',
    border: 'none',
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '16px !important',
    textAlign: 'right',
    textDecoration: 'underline solid #0061ff',
  },
  selectClassName: {
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    textTransform: 'capitalize',
    // opacity: '0.43 !important',
  },
  submited: {
    boxShadow: 'unset',
  },
}));

export default useStyles;
