import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import jwt_decode from 'jwt-decode';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import logoNav2 from '../../../assets/images/logo-nav2.svg';
import { InputText } from '../../../components/Forms/InputText';
import { SelectInput } from '../../../components/Forms/selectInput';
import Loader from '../../../components/Loader/Loader';
import { PublicRoute } from '../../../config/Constants';
import { useCustomStore } from '../../../hooks';
import { ResetPasswordValidationInput } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { ResetPasswordSchema } from '../../../utils/ValidatorSchema';
import useStyles from '../PublicStyles';

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const { apiStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const resetToken = searchParams.get('token') || '';
  const contactData: any = jwt_decode(String(resetToken));

  const [loading, setLoading] = useState(false);

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordValidationInput>({
    criteriaMode: 'all',
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  });

  const submitResetPassword = useCallback(
    async (data: ResetPasswordValidationInput) => {
      setLoading(true);

      const resetPasswordResp = await apiStore.userApi.resetPassword({
        password: data.password,
        resetToken: String(resetToken) !== '' ? String(resetToken) : '',
      });

      if (resetPasswordResp.isOk()) {
        enqueueSnackbar(resetPasswordResp.value, SnackBarConfig('s'));
        navigate(PublicRoute.LOGIN);
      } else {
        enqueueSnackbar(
          String(resetPasswordResp.error.message),
          SnackBarConfig('e'),
        );
        setLoading(false);
      }
    },
    [resetToken, apiStore.userApi, enqueueSnackbar, navigate],
  );

  return (
    <Grid
      container
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      className={classes.sliderHome}>
      <Grid item margin={'auto'}>
        <div className={classes.rectangle}>
          <img src={logoNav2} className={classes.logoNav2} />
          <form
            style={{
              width: '100%',
            }}
            id="reset-password"
            onSubmit={handleSubmit(submitResetPassword)}>
            <Grid
              sx={{
                marginTop: '31px',
              }}>
              <FormControl error={false} fullWidth>
                <Grid container>
                  {contactData.companyName && (
                    <>
                      <Box className={classes.labels}>Selected Company</Box>
                      <Grid item md={12} sm={12} xs={12}>
                        <SelectInput
                          isEditable={true}
                          name="company"
                          value={contactData.id}
                          selectClassName={classes.selectInputStyles}
                          optionsList={[
                            {
                              label: contactData.companyName,
                              value: contactData.id,
                            },
                          ]}
                        />
                      </Grid>
                    </>
                  )}

                  {contactData.email && (
                    <>
                      <Box
                        className={classes.labels}
                        sx={{
                          marginTop: '12px',
                        }}>
                        Email
                      </Box>
                      <Grid item md={12} sm={12} xs={12}>
                        <InputText
                          name="email"
                          value={contactData.email}
                          isEditable={true}
                          className={classes.inputStyles}
                          type={'email'}
                          variant="filled"
                        />
                      </Grid>
                    </>
                  )}

                  <Box
                    className={classes.labels}
                    sx={{
                      marginTop: '12px',
                    }}>
                    Enter New Password
                  </Box>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="password"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <InputText
                            isFormSubmittedOnce={!!formState.submitCount}
                            inputRefObj={ref}
                            errors={errors.password?.message || ''}
                            name={name}
                            isRefreshValue={true}
                            value={value}
                            onChangeText={onChange}
                            isSecureTextEntry={true}
                            isEditable={false}
                            className={classes.inputStyles}
                            type={'password'}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Box
                    className={classes.labels}
                    sx={{
                      marginTop: '12px',
                    }}>
                    Re-type New Password
                  </Box>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="confirmPassword"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <InputText
                            isFormSubmittedOnce={!!formState.submitCount}
                            inputRefObj={ref}
                            errors={errors.confirmPassword?.message || ''}
                            name={name}
                            isRefreshValue={true}
                            value={value}
                            onChangeText={onChange}
                            isSecureTextEntry={true}
                            isEditable={false}
                            className={classes.inputStyles}
                            type={'password'}
                            variant="filled"
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Button
              disabled={loading}
              tabIndex={0}
              type="submit"
              form="reset-password"
              variant="contained"
              fullWidth
              className={classes.buttonStyles}>
              {loading ? (
                <Loader
                  className={'flex-centering mt-1em mb-1em'}
                  style={{ color: '#DEC330', marginBottom: 16, marginTop: 16 }}
                />
              ) : (
                'Submit'
              )}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(ResetPassword);
