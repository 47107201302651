import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import * as React from 'react';

type Props = {
  handleOpen: () => any;
  handleClose: () => any;
  isOpen: boolean;
  buttonName?: string;
  header: any;
  body: any;
  footer: any;
};
const AlertDialog: React.FC<Props> = ({
  handleClose,
  handleOpen,
  isOpen,
  buttonName = 'Submit',
  header,
  body,
  footer,
}) => {
  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        {buttonName}
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
          <Divider style={{ marginBottom: '10px' }} />
          {footer}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default AlertDialog;
