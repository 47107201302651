import { makeAutoObservable, toJS } from 'mobx';

import { MVRFormInputs } from '../types';
import { RootStore } from './';

export class MvrStore {
  private _mvrValue: MVRFormInputs = {
    type: '',
  };
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  setMvrForm(value: string) {
    this._mvrValue.type = value;
  }

  get getMvrData() {
    return toJS(this._mvrValue.type);
  }

  resetMvr() {
    this._mvrValue = {
      type: '',
    };
  }

  async addUcrRecord() {
    const response = await this.rootStore.apiStore.mvrApi.addMvr({
      type: this._mvrValue.type,
    });

    return response;
  }
}
