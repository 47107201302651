import { makeAutoObservable, toJS } from 'mobx';

import { RootStore } from './';

export class IFTAStore {
  private _ifta: any = {
    equipments: [],
    fuelCardDetails: [],
    iftaAccount: '',
    renewalOrNewState: '',
  };
  private _initialApiData: any = {};

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get iftaDetails(): any {
    return { ...toJS(this._ifta) };
  }

  setIftaDetail(key: string, value: any) {
    this._ifta = {
      ...this._ifta,
      [key]: value,
    };
  }

  addEquipmentDetail(equipments: any) {
    this._ifta.equipments = equipments;
  }

  removeEquipmentDetail(equipment: any) {
    const modifiedDataIndex: any = [...this._ifta.equipments].findIndex(
      (equip: any) => equip.unitId !== equipment.unitId,
    );
    if (modifiedDataIndex !== -1) {
      this._ifta.equipments.splice(modifiedDataIndex, 1);
    }
  }

  addFuelCardDetail(files: any[]) {
    this._ifta.fuelCardDetails.push(...files);
  }

  removeFuelCardDetail(index: number) {
    this._ifta.fuelCardDetails.splice(index, 1);
  }

  get getInitialApiReponseData() {
    return { ...toJS(this._initialApiData) };
  }

  resetIFTADetails() {
    this._ifta = {
      equipments: [],
      fuelCardDetails: [],
      iftaAccount: this._initialApiData.accountNumber,
      renewalOrNewState: '',
    };
  }

  async getInitialData() {
    const initialResponse =
      await this.rootStore.apiStore.iftaApiStore.getInitialData();
    if (initialResponse.isOk()) {
      const { value } = initialResponse;
      this._initialApiData = value;
      this._ifta = {
        equipments: [],
        fuelCardDetails: [],
        iftaAccount: value.accountNumber,
        renewalOrNewState: '',
      };
    }
    return initialResponse;
  }

  async addIFTA(todoId?: string) {
    const {
      renewalOrNewState,
      iftaAccount,
      equipments,
      fuelCardDetails,
      iftaState,
    } = this._ifta;
    const fileDatas: any = [...toJS(fuelCardDetails)];
    const equipmentDetails = [...toJS(equipments)];
    let fileApiRes: any = [];

    if (fileDatas.length) {
      const fd: any = new FormData();
      const individualFileInformationDetail: any = [];
      fileDatas.forEach((file: any) => {
        fd.append('files', file);
        individualFileInformationDetail.push({
          fileType:
            renewalOrNewState === 'new' ? 'IFTA letter' : 'Registration letter',
          service: 'ifta',
          type: 'account',
        });
      });
      fd.append('data', JSON.stringify(individualFileInformationDetail));
      const imageRes: any = await this.rootStore.apiStore.fileApi.uploadFile(
        fd,
      );

      if (imageRes.isOk()) {
        fileApiRes = imageRes.value.map((e: any) => ({
          fileName: e.fileName,
          fileSize: e.fileSize,
          fileType: e.fileType,
          location: e.location,
        }));
      }

      if (imageRes.isErr()) {
        return {
          response: imageRes,
          status: 'error',
        };
      }
    }

    const apiData = {
      documents: fileApiRes,
      equipment: equipmentDetails,
      expirationDate: this._ifta.expirationDate,
      iftaAccountNumber: iftaAccount,
      iftaState:
        iftaState ||
        this.rootStore.authStore.statesListOption.find(
          (state) => state.id === this._ifta.iftaState,
        )?.code,
      requestType: renewalOrNewState,
      todoId: todoId != '' ? todoId : '',
    };
    const finalAddResponse = await this.rootStore.apiStore.iftaApiStore.addIFTA(
      apiData,
    );
    if (finalAddResponse.isOk()) {
      return {
        response: this.getInitialData(),
        status: 'success',
      };
    }

    if (finalAddResponse.isErr()) {
      return {
        response: finalAddResponse,
        status: 'error',
      };
    }
  }
}
