import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@mui/material/styles/styled';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback } from 'react';

import { useCustomStore } from '../../../hooks';
import { AccessTypes } from '../../../types';
import { TODO_CATEGORY } from '../../../utils/config';
// import { todoTypeBasedConfig } from '../../../utils/config';
import useStyles, { DueColors } from './todoStyle';
import TodoVariants from './TodoVariants';

const LightColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:disabled': {
    '& p': {
      color: '#a9a9a9',
    },
    background: '#e6e6e6 !important',
    border: 'none',
    color: '#a9a9a9',
  },
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #CBCBCB',
  borderRadius: '8px',
  boxSizing: 'border-box',
  color: theme.palette.getContrastText('#FFFFFF'),
  // height: '45px',
  // width: '138px',
  marginRight: '0.3rem',
}));

const resolvedByDriverAndOwnerOnly = [
  'Driver Application',
  'MVR Authorization',
  'Pre-Employment Driver Application',
  'Pre-Employment PSP Authorization',
];

const resolvedOnlyArray = [
  'Driver Application',
  'MVR Authorization',
  'Pre-Employment Driver Application',
  'PSP',
  'Pre-Employment PSP Authorization',
];

const requestAlsoTodos = [
  'MVR',
  'Driver Termination Acknowledgment Form',
  'Unit Termination Acknowledgment Form',
];

const todosWithNoActions = [
  'Pre-Employment MVR',
  'Drug Test',
  'Pre-Employment Drug Test',
  'Pre-Employment Clearinghouse Query',
  'Background Check',
  'Pre-Employment Background Check',
  'Training',
];
const updateOnlyArray = [
  'Local Registration',
  'Trailer Registration',

  'Drivers License',
  'Pre-Employment Driver License',

  'Medical Card',
  'Pre-Employment Medical Card',

  'Annual Inspection',
  'Clearinghouse Query',
];

const userRolesWhichAreNotAdmins = [
  AccessTypes.Driver,
  AccessTypes.OwnerOperator,
];

const TodoLists: React.FC<{
  resolveUR: (todo: any, updateOrRequest: string) => void;
  onTrainingTodoAction: (todoDetails: any, action: 'video' | 'quiz') => void;
}> = ({ resolveUR, onTrainingTodoAction }) => {
  const { todoStore, userStore, authStore } = useCustomStore();
  const todoLists: any = todoStore.TodoList;
  const classes = useStyles();

  const todoTypeAccess: any = {
    'AR Report': {
      access: true,
    },
    'Annual Inspection': {
      access: true,
    },
    'Annual Report': {
      access: authStore.NavbarAccess?.tpts.annualReport,
    },
    'Cab Card/ IRP Registration': {
      access: authStore.NavbarAccess?.tpts.irpService,
    },
    'Clearinghouse Query': {
      access: true,
    },
    'DOT Biennal Update': {
      access: authStore.NavbarAccess?.tpts.dotUpdate,
    },
    'DOT VMT Update': {
      access: authStore.NavbarAccess?.tpts.dotUpdate,
    },
    'Driver Application': {
      access: true,
    },
    'Drivers License': {
      access: true,
    },
    'Fuel Tax Filing': {
      access: authStore.NavbarAccess?.tpts.ftQuarterlyFiling,
    },
    'Hazmat Permit': {
      access: true,
    },
    'Hazmat Training': {
      access: true,
    },
    'IFTA Permit': {
      access: authStore.NavbarAccess?.tpts.ifta,
    },
    'IRP Permit': {
      access: authStore.NavbarAccess?.tpts.irpService,
    },
    'Local Registration': {
      access: true,
    },
    MVR: {
      access: authStore.NavbarAccess?.dot.mvr,
    },
    'Medical Card': {
      access: true,
    },
    'NM Permit': {
      access: authStore.NavbarAccess?.tpts.statePermits,
    },
    'NY Permit': {
      access: authStore.NavbarAccess?.tpts.statePermits,
    },
    'OR Monthly Tax Filing': {
      access: true,
    },
    'OR Permit': {
      access: authStore.NavbarAccess?.tpts.statePermits,
    },
    'Road Taxes': {
      access: authStore.NavbarAccess?.tpts.roadTaxes,
    },
    SCAC: {
      access: true,
    },
    'Trailer Registration': {
      access: true,
    },
    UCR: {
      access: authStore.NavbarAccess?.tpts.ucr,
    },
  };

  const showDataOfContent = useCallback((text: string | null) => {
    return text ? (
      <Typography
        component={'div'}
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Light !important',
          fontSize: '16px',
          height: 'auto',
          letterSpacing: 0,
          lineHeight: '19px',
        }}>
        {text}
      </Typography>
    ) : (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          letterSpacing: 0,
          lineHeight: '19px',
          textAlign: 'center',
        }}>
        -
      </Typography>
    );
  }, []);

  const renderTrainingTodoActions = useCallback(
    (trainingTodo: any, todo: any) => {
      try {
        return (
          <TableCell>
            {(authStore.UserAccessType !== AccessTypes.CompanyAdmin ||
              userStore.UserData.contactType === 'Driver' ||
              trainingTodo?.assignedTo?.id === userStore.UserData?.contactId) &&
            trainingTodo &&
            trainingTodo.allowActions ? (
              <Typography
                component={'div'}
                style={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem' }}>
                {Array.isArray(trainingTodo.actions) &&
                  trainingTodo.actions.includes(
                    trainingTodo?.hasDocument ? 'document' : 'video',
                  ) && (
                    <LightColorButton
                      type="button"
                      variant="contained"
                      onClick={() =>
                        onTrainingTodoAction(trainingTodo, 'video')
                      }>
                      <Typography noWrap className={classes.actionButton}>
                        {trainingTodo?.hasDocument
                          ? 'VIEW DOCUMENT'
                          : 'WATCH VIDEO'}
                      </Typography>
                    </LightColorButton>
                  )}
                {Array.isArray(trainingTodo.actions) &&
                  trainingTodo.actions.includes('quiz') && (
                    <LightColorButton
                      type="button"
                      variant="contained"
                      onClick={() =>
                        onTrainingTodoAction(trainingTodo, 'quiz')
                      }>
                      <Typography noWrap className={classes.actionButton}>
                        TAKE QUIZ
                      </Typography>
                    </LightColorButton>
                  )}
              </Typography>
            ) : (
              <Typography component={'div'}>
                {todoStore.Status === 'Completed' && todo?.status
                  ? todo.status
                  : '-'}
              </Typography>
            )}
          </TableCell>
        );
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return todoLists.map((todo: any, index: number) => (
    <TableRow
      key={index}
      className={classes.rowHeight}
      style={{ minHeight: '80px' }}>
      <TableCell
        // className={
        //   todo.duein >= 5 && todo.duein <= 1
        //     ? classes.danger
        //     : todo.duein > 10 && todo.duein <= 30
        //     ? classes.normal
        //     : todo.duein > 30
        //     ? classes.success
        //     : classes.danger
        // }

        className={
          todo.duein < 1
            ? classes.due0
            : todo.duein >= 1 && todo.duein <= 5
            ? classes.due1to5
            : todo.duein >= 6 && todo.duein <= 10
            ? classes.due6to10
            : (todo.category === 'Training' &&
                todo.duein >= 11 &&
                todo.duein <= 14) ||
              (todo.category !== 'Training' &&
                todo.duein >= 11 &&
                todo.duein <= 30)
            ? classes.due11to30
            : todo.duein > 30 ||
              (todo.category === 'Training' && todo.duein > 14)
            ? classes.due30above
            : classes.due0
        }
        style={{
          maxWidth: '500px',
        }}>
        {todo.text ? (
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Bold',
              fontSize: '16px',
              fontWeight: 600,
              letterSpacing: 0,
              lineHeight: '23px',
              paddingBottom: '0.5rem',
            }}>
            {todo.text}
          </Typography>
        ) : (
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
              letterSpacing: 0,
              lineHeight: '19px',
              textAlign: 'center',
            }}>
            -
          </Typography>
        )}
        {todo.category === TODO_CATEGORY.TRAINING ? (
          !todoStore.TrainingTodoDetails[todo.id]?.loader ? (
            todoStore.TrainingTodoDetails[todo.id]?.data ? (
              <TodoVariants
                variants={{
                  assignedByName:
                    todoStore.TrainingTodoDetails[todo.id]?.data?.assignedBy
                      ?.name || '-',
                  assignedDate:
                    todoStore.TrainingTodoDetails[todo.id]?.data
                      ?.assignedDate || '-',
                  assignedToName:
                    todoStore.TrainingTodoDetails[todo.id]?.data?.assignedTo
                      ?.name || '-',
                  assignmentReason:
                    todoStore.TrainingTodoDetails[todo.id]?.data
                      ?.assignmentReason || '-',
                  assignmentStatus:
                    todoStore.TrainingTodoDetails[todo.id]?.data
                      ?.assignmentStatus || '-',
                  failedAttempts:
                    todoStore.TrainingTodoDetails[todo.id]?.data?.quiz
                      ?.failedAttempts || 0,
                  hasDocument:
                    todoStore.TrainingTodoDetails[todo.id]?.data?.hasDocument ||
                    false,
                  hasQuiz:
                    todoStore.TrainingTodoDetails[todo.id]?.data?.hasQuiz ||
                    false,
                  totalAttempts:
                    todoStore.TrainingTodoDetails[todo.id]?.data?.quiz
                      ?.totalAttempts || 0,
                  videoStatus:
                    todoStore.TrainingTodoDetails[todo.id]?.data?.videoStatus ||
                    '-',
                }}
                category={todo.category}
              />
            ) : (
              <div />
            )
          ) : (
            <CircularProgress size={20} sx={{ color: '#DEC330' }} />
          )
        ) : todo.unit || todo.driver || todo.company ? (
          todo.todoType === 'Termination Acknowledgment Form' ? (
            <TodoVariants
              variants={{
                driver: todo.driver,
                todoCategoryType: todo.todoCategoryType,
                unit: todo.unit,
              }}
              category={todo.category}
            />
          ) : (
            <TodoVariants
              variants={
                todo.category === 'Fleet'
                  ? todo.unit
                  : todo.category === 'Driver'
                  ? todo.driver
                  : todo.company
              }
              category={todo.category}
            />
          )
        ) : (
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
              letterSpacing: 0,
              lineHeight: '19px',
            }}>
            {todo.unitId === null ||
            todo.unitId === undefined ||
            todo.contactId === null ||
            todo.contactId === undefined
              ? todo.subText
                ? todo.subText
                : '-'
              : '-'}
          </Typography>
        )}
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        {todo.category ? (
          <Typography
            noWrap
            className={
              todo.category === 'Fleet'
                ? classes.fleetBg
                : todo.category === 'Driver'
                ? classes.driverBg
                : classes.companyBg
            }>
            {todo.category}
          </Typography>
        ) : (
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
              letterSpacing: 0,
              lineHeight: '19px',
              textAlign: 'center',
            }}>
            -
          </Typography>
        )}
      </TableCell>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        {showDataOfContent(
          todo.expirationDate && moment(todo.expirationDate).isValid()
            ? moment(todo.expirationDate).format('M/DD/YYYY')
            : null,
        )}
      </TableCell>
      {todoStore.Status !== 'Completed' && (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Typography
            style={{ alignItems: 'center', display: 'flex' }}
            component={'div'}
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Bold',
              fontSize: '16px',
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: '23px',
              minWidth: '100px',
            }}>
            <Typography
              className={classes.colordot}
              style={{
                backgroundColor:
                  todo.duein < 1
                    ? DueColors.due0
                    : todo.duein >= 1 && todo.duein <= 5
                    ? DueColors.due1to5
                    : todo.duein >= 6 && todo.duein <= 10
                    ? DueColors.due6to10
                    : (todo.category === 'Training' &&
                        todo.duein >= 11 &&
                        todo.duein <= 14) ||
                      (todo.category !== 'Training' &&
                        todo.duein >= 11 &&
                        todo.duein <= 30)
                    ? DueColors.due11to30
                    : todo.duein > 30 ||
                      (todo.category === 'Training' && todo.duein > 14)
                    ? DueColors.due30above
                    : DueColors.due0,
              }}
            />

            {typeof todo.duein === 'string'
              ? todo.duein
              : String(todo.duein) === '0'
              ? 'Today'
              : String(todo.duein).concat(' Days')}
          </Typography>
        </TableCell>
      )}
      {todo && !todosWithNoActions.includes(todo.todoType) ? (
        todoStore.Status !== 'Completed' ? (
          <TableCell style={{ paddingBottom: 0 }}>
            <Typography
              component={'div'}
              style={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem' }}>
              {resolvedOnlyArray.includes(todo.todoType) ? (
                requestAlsoTodos.includes(todo.todoType) ? (
                  <>
                    <LightColorButton
                      type="button"
                      variant="contained"
                      disabled={
                        authStore.getTokenDetail &&
                        authStore.getTokenDetail?.adminId
                      }
                      style={{ bottom: '8px' }}
                      onClick={() => resolveUR(todo, 'resolve')}>
                      <Typography
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Medium',
                          fontSize: '15px',
                          fontWeight: '500',
                          letterSpacing: '1px',
                          lineHeight: 'normal',
                          textAlign: 'center',
                        }}>
                        Resolve
                      </Typography>
                    </LightColorButton>
                    <LightColorButton
                      type="button"
                      disabled={
                        authStore.getTokenDetail &&
                        authStore.getTokenDetail?.adminId
                      }
                      variant="contained"
                      style={{ bottom: '8px' }}
                      onClick={() => resolveUR(todo, 'request')}>
                      <Typography
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Medium',
                          fontSize: '15px',
                          fontWeight: '500',
                          letterSpacing: '1px',
                          lineHeight: 'normal',
                          textAlign: 'center',
                        }}>
                        Request
                      </Typography>
                    </LightColorButton>
                  </>
                ) : userRolesWhichAreNotAdmins.includes(
                    userStore.UserData.accessType,
                  ) && resolvedByDriverAndOwnerOnly.includes(todo.todoType) ? (
                  <LightColorButton
                    type="button"
                    variant="contained"
                    disabled={
                      authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                    }
                    style={{ bottom: '8px' }}
                    onClick={() => resolveUR(todo, 'resolve')}>
                    <Typography
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Medium',
                        fontSize: '15px',
                        fontWeight: '500',
                        letterSpacing: '1px',
                        lineHeight: 'normal',
                        textAlign: 'center',
                      }}>
                      Resolve
                    </Typography>
                  </LightColorButton>
                ) : (
                  <Typography component={'div'} style={{ paddingLeft: '15px' }}>
                    -
                  </Typography>
                )
              ) : (
                <>
                  <LightColorButton
                    type="button"
                    disabled={
                      authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                    }
                    variant="contained"
                    style={{ bottom: '8px' }}
                    onClick={() => resolveUR(todo, 'update')}>
                    <Typography
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Medium',
                        fontSize: '15px',
                        fontWeight: '500',
                        letterSpacing: '1px',
                        lineHeight: 'normal',
                        textAlign: 'center',
                      }}>
                      Update
                    </Typography>
                  </LightColorButton>

                  {userStore.UserData.accessType === 'driver' &&
                    (resolvedOnlyArray.includes(todo.todoType) ? (
                      <LightColorButton
                        type="button"
                        disabled={
                          authStore.getTokenDetail &&
                          authStore.getTokenDetail?.adminId
                        }
                        variant="contained"
                        style={{ bottom: '8px' }}
                        onClick={() => resolveUR(todo, 'resolve')}>
                        <Typography
                          sx={{
                            color: '#241A2E',
                            fontFamily: 'FiraSans-Medium',
                            fontSize: '15px',
                            fontWeight: '500',
                            letterSpacing: '1px',
                            lineHeight: 'normal',
                            textAlign: 'center',
                          }}>
                          Resolve
                        </Typography>
                      </LightColorButton>
                    ) : (
                      !updateOnlyArray.includes(todo.todoType) &&
                      todoTypeAccess[todo.todoType]?.access && (
                        <LightColorButton
                          type="button"
                          disabled={
                            authStore.getTokenDetail &&
                            authStore.getTokenDetail?.adminId
                          }
                          variant="contained"
                          style={{ bottom: '8px' }}
                          onClick={() => resolveUR(todo, 'request')}>
                          <Typography
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Medium',
                              fontSize: '15px',
                              fontWeight: '500',
                              letterSpacing: '1px',
                              lineHeight: 'normal',
                              textAlign: 'center',
                            }}>
                            Request
                          </Typography>
                        </LightColorButton>
                      )
                    ))}
                </>
              )}
            </Typography>
          </TableCell>
        ) : (
          <TableCell>
            <Typography
              component={'div'}
              style={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem' }}
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: '23px',
                minWidth: '100px',
              }}>
              {todo?.status === 'Open'
                ? 'Expired'
                : todo?.status || 'Completed'}
            </Typography>
          </TableCell>
        )
      ) : todo && todo.category === TODO_CATEGORY.TRAINING ? (
        renderTrainingTodoActions(
          todoStore.TrainingTodoDetails[todo.id]?.data,
          todo,
        )
      ) : (
        <TableCell>
          <Typography component={'div'}>
            {todoStore.Status === 'Completed' && todo?.status
              ? todo.status
              : '-'}
          </Typography>
        </TableCell>
      )}
      {todoStore.Status === 'Completed' && (
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          {showDataOfContent(
            todo.modifiedOn
              ? moment(todo.modifiedOn).format('M/DD/YYYY')
              : null,
          )}
        </TableCell>
      )}
    </TableRow>
  ));
};

export default observer(TodoLists);
