import { toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useCustomStore } from '../../../../hooks';

const inspectionByUnitFields = [
  {
    label: 'Search Driver Name',
    name: 'primaryDriverName',
    type: 'text',
  },
  {
    label: 'Inspection Date',
    name: 'inspection',
    type: 'date',
  },
  {
    label: 'Inspection End Date',
    name: 'inspectionEndDate',
    type: '',
  },
  {
    label: 'Select State',
    name: 'state',
    type: 'stateCode',
  },
  {
    label: 'Report Number',
    name: 'reportNumber',
    type: 'text',
  },
];

const inspectionByDriverFields = [
  {
    label: 'Inspection Date',
    name: 'inspection',
    type: 'date',
  },
  {
    label: 'Inspection End Date',
    name: 'inspectionEndDate',
    type: '',
  },
  {
    label: 'Select State',
    name: 'state',
    type: 'stateCode',
  },
  {
    label: 'VIN',
    name: 'vin',
    type: 'text',
  },
  {
    label: 'Report Number',
    name: 'reportNumber',
    type: 'text',
  },
];

const config: Record<string, Record<string, Record<string, string>[]>> = {
  driver: {
    '/Crashes': [
      {
        label: 'Accident Date',
        name: 'accident',
        type: 'date',
      },
      {
        label: 'Accident End Date',
        name: 'accidentEndDate',
        type: '',
      },
      {
        label: 'VIN',
        name: 'vin',
        type: 'text',
      },
      {
        label: 'Accident Number',
        name: 'accidentNumber',
        type: 'text',
      },
    ],
    '/Inspections': inspectionByDriverFields,
    '/Violations': [
      {
        label: 'Violation Date',
        name: 'violation',
        type: 'date',
      },
      {
        label: 'Violaion End Date',
        name: 'violationEndDate',
        type: '',
      },
      {
        label: 'Select State',
        name: 'state',
        type: 'stateCode',
      },
      {
        label: 'VIN',
        name: 'vin',
        type: 'text',
      },
      {
        label: 'Violation Code',
        name: 'violationCode',
        type: 'text',
      },
      {
        label: 'Report Number',
        name: 'reportNumber',
        type: 'text',
      },
    ],
  },
  top10: {
    '/Crashes': [
      {
        label: 'Search Driver Name',
        name: 'driverName',
        type: 'text',
      },
      {
        label: 'Accident Date',
        name: 'accident',
        type: 'date',
      },
      {
        label: 'Accident End Date',
        name: 'accidentEndDate',
        type: '',
      },
      // {
      //   label: 'VIN',
      //   name: 'vin',
      //   type: 'text',
      // },
      {
        label: 'Accident Number',
        name: 'accidentNumber',
        type: 'text',
      },
    ],
    '/Inspections': [
      {
        label: 'Search Driver Name',
        name: 'primaryDriverName',
        type: 'text',
      },
      {
        label: 'Inspection Date',
        name: 'inspection',
        type: 'date',
      },
      {
        label: 'Inspection End Date',
        name: 'inspectionEndDate',
        type: '',
      },
      {
        label: 'Select State',
        name: 'state',
        type: 'stateCode',
      },
      // {
      //   label: 'VIN',
      //   name: 'vin',
      //   type: 'text',
      // },
      {
        label: 'Report Number',
        name: 'reportNumber',
        type: 'text',
      },
    ],
    '/Violations': [
      {
        label: 'Search Driver Name',
        name: 'primaryDriverName',
        type: 'text',
      },
      {
        label: 'Violation Date',
        name: 'violation',
        type: 'date',
      },
      {
        label: 'Violaion End Date',
        name: 'violationEndDate',
        type: '',
      },
      {
        label: 'Select State',
        name: 'state',
        type: 'stateCode',
      },
      // {
      //   label: 'VIN',
      //   name: 'vin',
      //   type: 'text',
      // },
      {
        label: 'Violation Code',
        name: 'violationCode',
        type: 'text',
      },
      {
        label: 'Report Number',
        name: 'reportNumber',
        type: 'text',
      },
    ],
  },
  unit: {
    '/Crashes': [
      {
        label: 'Search Driver Name',
        name: 'driverName',
        type: 'text',
      },
      {
        label: 'Accident Date',
        name: 'accident',
        type: 'date',
      },
      {
        label: 'Accident End Date',
        name: 'accidentEndDate',
        type: '',
      },
      {
        label: 'Accident Number',
        name: 'accidentNumber',
        type: 'text',
      },
    ],
    '/Inspections': inspectionByUnitFields,
    '/Violations': [
      {
        label: 'Search Driver Name',
        name: 'primaryDriverName',
        type: 'text',
      },
      {
        label: 'Violation Date',
        name: 'violation',
        type: 'date',
      },
      {
        label: 'Violation End Date',
        name: 'violationEndDate',
        type: '',
      },
      {
        label: 'Select State',
        name: 'state',
        type: 'stateCode',
      },
      {
        label: 'Violation Code',
        name: 'violationCode',
        type: 'text',
      },
      {
        label: 'Report Number',
        name: 'reportNumber',
        type: 'text',
      },
    ],
  },
};

const getInitialValues = (formControls: Record<string, string>[]) => {
  const obj: Record<string, string> = {};
  formControls.forEach((fc: Record<string, string>) => {
    obj[fc.name] = '';
  });
  return obj;
};

const useTrendDetailsForm = (
  searchByDriver = false,
  searchByUnit = false,
  trendType = '',
) => {
  const formControls =
    (searchByDriver && config.driver?.[trendType]) ||
    (searchByUnit && config.unit?.[trendType]) ||
    config.top10?.[trendType];

  const [isFormEmpty, setIsFormEmpty] = useState(true);

  const { trendsStore: store } = useCustomStore();

  const { control, watch, reset } = useForm({
    ...getInitialValues(formControls),
    defaultValues: toJS(store.searchParams),
  });
  const formValue = watch();

  useEffect(() => {
    const allValues = Object.values(formValue);
    if (!allValues.length) {
      setIsFormEmpty(true);
    } else {
      const nonEmptyValue = allValues.find((value) => value);
      if (nonEmptyValue) setIsFormEmpty(false);
      else setIsFormEmpty(true);
    }
  }, [formValue]);

  return { control, formControls, formValue, isFormEmpty, reset };
};

export default useTrendDetailsForm;
