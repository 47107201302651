import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Checkbox } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Switch from 'react-switch';

import SimplexGrid from '../../../components/SimplexGrid';
import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import Inspections from './Inspections';
import MatchDriversAndVins from './MatchDriversAndVinsDialog';
import MatchDriversAndVinsMultple from './MatchDriversAndVinsMultipleDialog';
import MoreDetails from './MoreDetails';
import { useStyles } from './styles';

const allHeaderKeys = [
  {
    checkbox: false,
    highlight: true,
    label: 'Inspection Date',
    name: 'inspectionDate',
  },
  {
    label: 'Inspection State',
    name: 'inspectionState',
  },
  {
    label: 'Report Number',
    name: 'reportNumber',
  },
  {
    label: 'Inspection Level',
    name: 'inspectionLevel',
  },
  {
    label: 'Primary Driver',
    name: 'primaryDriver',
  },
  {
    label: 'Secondary Driver',
    name: 'secondaryDriver',
  },
  {
    label: 'Power Unit VIN',
    name: 'powerUnitVin',
  },
  {
    label: 'Trailer VIN',
    name: 'trailerVin',
  },
  {
    label: 'Total # of Violations in Inspection',
    name: 'noOfViolations',
  },
  {
    label: 'Out Of Service',
    name: 'outOfService',
  },
  {
    label: 'Total Weight of Inspection Violations',
    name: 'totalWeight',
  },
];

const manageHeaderKeys = [
  {
    checkbox: true,
    highlight: true,
    label: 'Inspection Date',
    name: 'inspectionDate',
  },
  {
    label: 'Inspection State',
    name: 'inspectionState',
  },
  {
    label: 'Report Number',
    name: 'reportNumber',
  },
  {
    label: 'Primary Driver',
    name: 'primaryDriver',
  },
  {
    label: 'Secondary Driver',
    name: 'secondaryDriver',
  },
  {
    label: 'Power Unit VIN',
    name: 'powerUnitVin',
  },
  {
    label: 'Trailer VIN',
    name: 'trailerVin',
  },
];

const applyAllHeaderSpacing = (el: any, index: number) => {
  const spacing = [120, 110, 100, 120, 200, 180, 200, 200, 110, 90, 110];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const applyManageHeaderSpacing = (el: any, index: number) => {
  const spacing = [160, 160, 160, 220, 220, 220, 220];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const allHeaderKeysWithSpacing = allHeaderKeys.map(applyAllHeaderSpacing);
const manageHeaderKeysWithSpacing = manageHeaderKeys.map(
  applyManageHeaderSpacing,
);

const InspectionsAndViolations: React.FC = () => {
  const {
    inspectionsAndViolationsStore: store,
    userStore,
    authStore,
  } = useCustomStore();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { DOTPin, FMCSA } = userStore;
  const { NavbarAccess: access } = authStore;

  const showBanner = () => {
    const isDotMissingOrInvalid =
      (DOTPin && DOTPin?.status !== 'Valid') || false;
    const isFMCSAMissingOrInvalid =
      (FMCSA && FMCSA?.status !== 'Valid') || false;
    const showDOTBanner =
      (access?.dashboard?.dotPin && isDotMissingOrInvalid) || false;
    const showFMCSABanner =
      (access?.dashboard?.fmcsa && isFMCSAMissingOrInvalid) || false;
    return showDOTBanner || showFMCSABanner;
  };
  const managePageHeaderTopSpacing = showBanner() ? '120px' : '80px';
  const [prevSortBy, setPrevSortBy] = useState('');
  const [showCompleted, setShowCompleted] = useState(false);
  const [isHeaderRowChecked, setIsHeaderRowChecked] = useState(false);
  const [
    selectedInspectionsAndViolations,
    setSelectedInspectionsAndViolations,
  ]: any = useState([]);
  const [
    showSingleRecMatchDriversAndVinsDialogue,
    setShowSingleRecMatchDriversAndVinsDialogue,
  ]: any = useState(false);
  const [
    showMultipleRecMatchDriversAndVinsDialogue,
    setShowMultipleRecMatchDriversAndVinsDialogue,
  ]: any = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState('');

  const getList = useCallback(async () => {
    store.setShowLoader(true);
    const manageType = showCompleted ? 'manage-completed' : 'manage-all';
    store.setManageType(manageType);
    await store.getList();
    store.setShowLoader(false);
  }, [store, showCompleted]);

  useEffect(() => {
    (async () => {
      const manageType = showCompleted ? 'manage-completed' : 'manage-all';
      store.setManageType(manageType);
      await getList();
    })();
    return () => {
      store.resetParams();
    };
  }, [store, store.Status, getList, showCompleted]);

  const getNextList = useCallback(() => {
    (async () => {
      const manageType = showCompleted ? 'manage-completed' : 'manage-all';
      store.setManageType(manageType);
      await getList();
    })();
  }, [getList, showCompleted, store]);

  const handleSort = useCallback(
    (e) => {
      (async () => {
        store.resetParams();
        store.setSortBy(e);
        setPrevSortBy(e);
        if (e === prevSortBy) {
          store.setSortDirection(
            (store.SortDirection &&
              (store.SortDirection === 'asc' ? 'desc' : 'asc')) ||
              'desc',
          );
        } else {
          store.setSortDirection('desc');
        }
        const manageType = showCompleted ? 'manage-completed' : 'manage-all';
        store.setManageType(manageType);
        await getList();
      })();
    },
    [prevSortBy, store, getList, showCompleted],
  );

  const manageSelectAll = useCallback(
    (e) => {
      setSelectedInspectionsAndViolations([]);
      setIsHeaderRowChecked(false);
      const totalToBeSelected = store.Count < 30 ? store.Count : 30;
      if (e.currentTarget.checked) {
        setIsHeaderRowChecked(true);
        const _selectedInspectionsAndViolations = [];
        for (let i = 0; i < totalToBeSelected; i++) {
          _selectedInspectionsAndViolations.push(store.Data[i].inspectionId);
        }
        setSelectedInspectionsAndViolations(_selectedInspectionsAndViolations);
      }
    },
    [store.Data, store.Count],
  );

  useEffect(() => {
    if (store.ResetManageSelectAll) {
      setSelectedInspectionsAndViolations([]);
      setIsHeaderRowChecked(false);
      store.setResetManageSelectAll(false);
    }
  }, [store, store.ResetManageSelectAll]);

  const manageSelected = (string?: any) => {
    if (string === 'multiple') {
      setShowMultipleRecMatchDriversAndVinsDialogue(true);
    } else {
      setSelectedMatchId(string);
      setShowSingleRecMatchDriversAndVinsDialogue(true);
    }
  };

  const closeSingleDialog = async (reload = true): Promise<any> => {
    setShowSingleRecMatchDriversAndVinsDialogue(false);
    if (reload) {
      setSelectedMatchId('');
      store.resetParams();
      await getList();
    }
  };

  const closeMultipleRecDialog = async (reload = true): Promise<any> => {
    setShowMultipleRecMatchDriversAndVinsDialogue(false);
    if (reload) {
      setSelectedInspectionsAndViolations([]);
      store.resetParams();
      await getList();
    }
  };

  const getDriverCaption = (
    hasOnboarding = false,
    isTrainingAssigned = false,
  ) => {
    if (hasOnboarding) return 'New Driver Onboarding submitted';
    // Need to check this with backend
    // if (isTrainingAssigned) return 'Training Assigned';
    return '';
  };

  const getNewInspectionObj = useCallback((el: Inspection) => {
    const {
      primaryDriver,
      secondaryDriver,
      inspectionLevel,
      powerUnit,
      trailer,
      isDataQ,
      reportNumber,
      ...rest
    } = el;
    const newObj: Partial<InspectionData> = { ...rest };
    newObj.inspectionLevel = {
      primaryText: inspectionLevel?.name,
    };
    newObj.primaryDriver = {
      primaryText: primaryDriver?.name,
      status: primaryDriver?.matchedStatus,
    };
    newObj.secondaryDriver = {
      primaryText: secondaryDriver?.name,
      status: secondaryDriver?.matchedStatus,
    };
    newObj.trailerVin = {
      primaryText: trailer?.vin,
      status: trailer?.matchedStatus,
    };
    newObj.powerUnitVin = {
      primaryText: powerUnit?.vin,
      status: powerUnit?.matchedStatus,
    };
    newObj.reportNumber = {
      caption: isDataQ ? 'DataQ challenge submitted' : '',
      primaryText: reportNumber,
    };
    // if (status === 'Manage') {
    newObj.primaryDriver.secondaryText = primaryDriver?.matchedName;
    newObj.primaryDriver.caption = getDriverCaption(
      primaryDriver.hasOnboarding,
      primaryDriver.isTrainingAssigned,
    );

    newObj.secondaryDriver.secondaryText = secondaryDriver?.matchedName;
    newObj.secondaryDriver.caption = getDriverCaption(
      secondaryDriver?.hasOnboarding,
      secondaryDriver?.isTrainingAssigned,
    );

    newObj.trailerVin.secondaryText = trailer?.matchedVin;
    newObj.trailerVin.caption = trailer?.hasAddedToFleet
      ? 'Add to Fleet submitted'
      : '';

    newObj.powerUnitVin.secondaryText = powerUnit?.matchedVin;
    newObj.powerUnitVin.caption = powerUnit?.hasAddedToFleet
      ? 'Add to Fleet submitted'
      : '';

    newObj.reportNumber.caption = isDataQ ? 'DataQ challenge submitted' : '';
    // }
    return newObj as InspectionData;
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {store.Status === 'Manage' && (
        <div
          className="manage-page-header"
          style={{
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'space-between',
            left: '240px',
            paddingTop: '13px',
            position: 'fixed',
            top: managePageHeaderTopSpacing,
            width: '84%',
            zIndex: 8,
          }}>
          <div
            style={{
              marginBottom: '10px',
              paddingLeft: '38px',
            }}>
            {showCompleted
              ? 'Matched Inspections and Violations'
              : `${store.ShowLoader ? '' : store.Count} Unmanaged Inspections`}
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              marginBottom: '10px',
            }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                paddingRight: '25px',
              }}>
              Show Completed&nbsp;&nbsp;
              <Switch
                onChange={(checked: boolean, e: any, id: string) => {
                  setSelectedInspectionsAndViolations([]);
                  setIsHeaderRowChecked(false);
                  setShowCompleted(checked);
                }}
                checked={showCompleted ? true : false}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={'#0DA728'}
                offColor={'#787880'}
                width={51}
                height={31}
                className={'activeSwitch'}
                disabled={store.ShowLoader}
              />
            </div>
            <div
              style={{
                alignItems: 'start',
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '33px',
              }}>
              <Typography component={'div'}>
                <Typography
                  component={'div'}
                  className={classes.createBtn.concat(
                    selectedInspectionsAndViolations.length < 2
                      ? ` disable ${classes.manageButtonDisable as string}`
                      : '',
                  )}
                  {...(selectedInspectionsAndViolations.length < 2
                    ? {}
                    : { onClick: () => manageSelected('multiple') })}>
                  Manage Selected ({selectedInspectionsAndViolations.length})
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '11px',
                  fontStyle: 'italic',
                  fontWeight: '500',
                  height: 0,
                  lineHeight: '13px',
                  padding: '4px 0 5px 0',
                  textAlign: 'center',
                }}>
                *Select 2 or more records to update
              </Typography>
            </div>
          </div>
        </div>
      )}
      {store.Data.length === 0 && store.ShowLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          style={{
            overflow: 'hidden',
            padding: '0 2rem 0 3rem',
            paddingTop: store.Status === 'Manage' ? '75px' : '0px',
            position: 'relative',
          }}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{
              maxHeight: `${
                store.Status === 'Manage'
                  ? 'calc(100vh - 180px)'
                  : 'calc(100vh - 125px)'
              }`,
            }}
            id="inspections-violations-table">
            <InfiniteScroll
              dataLength={store.Data.length}
              style={{
                overflow: 'unset',
              }}
              next={getNextList}
              hasMore={!!store.NextLink}
              scrollableTarget={'inspections-violations-table'}
              loader={
                store.ShowLoader && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                style={{
                  borderSpacing: '0px 5px',
                  minWidth: 700,
                  tableLayout: 'fixed',
                }}
                stickyHeader
                aria-label="sticky table">
                <TableHead
                  style={{
                    height: `${
                      store.Status === 'Manage' ? '60px' : '100px'
                    }` as string,
                  }}>
                  <SimplexGrid.Header
                    id="inspections-and-violations"
                    cells={
                      store.Status === 'All'
                        ? [
                            ...allHeaderKeysWithSpacing,
                            { noHeader: true, noSorting: true, spacing: 80 },
                          ]
                        : [
                            ...manageHeaderKeysWithSpacing,
                            { noHeader: true, noSorting: true, spacing: 80 },
                            { noHeader: true, noSorting: true, spacing: 80 },
                          ]
                    }
                    sortConfig={{ handleSort, store }}
                    checkBoxConfig={{
                      handleCheck: manageSelectAll,
                      tooltip: 'Max selection limit 30',
                    }}
                    showRowCheck={store.Status === 'Manage' ? true : false}
                    isRowChecked={
                      store.Status === 'Manage' ? isHeaderRowChecked : false
                    }
                    top={store.Status === 'Manage' ? 74 : 0}
                    checkboxPaddingBottom="6px"
                    gridHeaderStackPaddingTop="26px"
                    gridHeaderStackPaddingBottom="26px"
                  />
                </TableHead>
                <TableBody>
                  {store.Data.length > 0 ? (
                    <SimplexGrid.Body
                      id="inspections-and-violations"
                      data={store.Data}
                      cells={
                        store.Status === 'All'
                          ? allHeaderKeysWithSpacing
                          : manageHeaderKeysWithSpacing
                      }>
                      {(el: Inspection, index: number) => (
                        <>
                          {store.Status === 'Manage' && (
                            <TableCell
                              sx={{
                                minWidth: '50px',
                                padding: '0 16px 0 16px',
                                textAlign: 'left',
                                verticalAlign: 'middle',
                                width: '50px',
                              }}
                              padding={'none'}
                              width={80}>
                              <Checkbox
                                style={{
                                  backgroundColor: '#FFF',
                                  border: '1px solid #FFF',
                                  borderRadius: '4px',
                                }}
                                onChange={(event: any) => {
                                  const index =
                                    selectedInspectionsAndViolations.indexOf(
                                      el.inspectionId,
                                    );
                                  if (index > -1) {
                                    const _selectedInspectionsAndViolations =
                                      selectedInspectionsAndViolations;
                                    _selectedInspectionsAndViolations.splice(
                                      index,
                                      1,
                                    );
                                    setSelectedInspectionsAndViolations([
                                      ..._selectedInspectionsAndViolations,
                                    ]);
                                  } else {
                                    if (
                                      selectedInspectionsAndViolations.length ===
                                      30
                                    ) {
                                      enqueueSnackbar(
                                        String(
                                          'Cannot select more than 30 records',
                                        ),
                                        SnackBarConfig('e'),
                                      );
                                      return;
                                    } else {
                                      setSelectedInspectionsAndViolations([
                                        ...selectedInspectionsAndViolations,
                                        el.inspectionId,
                                      ]);
                                    }
                                  }
                                }}
                                color="success"
                                tabIndex={0}
                                key={el.inspectionId}
                                checked={selectedInspectionsAndViolations.includes(
                                  el.inspectionId,
                                )}
                              />
                            </TableCell>
                          )}
                          <Inspections
                            cells={
                              store.Status === 'All'
                                ? allHeaderKeysWithSpacing
                                : manageHeaderKeysWithSpacing
                            }
                            key={index}
                            data={getNewInspectionObj(el)}
                            renderChild={(id: string) => (
                              <MoreDetails
                                showDriverInfo={true}
                                showEquipmentInfo={true}
                                id={id}
                              />
                            )}
                            isManage={store.Status === 'Manage' ? true : false}
                            handleMatchInfo={manageSelected}
                          />
                        </>
                      )}
                    </SimplexGrid.Body>
                  ) : (
                    <TableRow style={{ height: '80px', minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={
                          store.Status === 'All'
                            ? allHeaderKeysWithSpacing.length
                            : manageHeaderKeysWithSpacing.length
                        }>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
      {showSingleRecMatchDriversAndVinsDialogue && (
        <MatchDriversAndVins
          isOpen={showSingleRecMatchDriversAndVinsDialogue}
          closeDialog={async ({ reload }: { reload?: boolean }) => {
            await closeSingleDialog(reload);
          }}
          selectedInspectionsViolations={
            selectedMatchId !== ''
              ? store.Data.filter((iv) => {
                  return iv.inspectionId === selectedMatchId;
                })
              : []
          }
        />
      )}
      {showMultipleRecMatchDriversAndVinsDialogue && (
        <MatchDriversAndVinsMultple
          isOpen={showMultipleRecMatchDriversAndVinsDialogue}
          closeDialog={async ({ reload }: { reload?: boolean }) => {
            await closeMultipleRecDialog(reload);
          }}
          selectedInspectionsViolations={store.Data.filter((iv: any) => {
            return selectedInspectionsAndViolations.includes(iv.inspectionId);
          })}
        />
      )}
    </Box>
  );
};

export default observer(InspectionsAndViolations);
