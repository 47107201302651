import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { API_LIMIT, PrivateRoutes } from '../../../config';
import NavRoutes from '../../../config/routes/NavRoutes';
import { useCustomStore } from '../../../hooks';
import { DriverSearchFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import DriverHeader from './DriverHeader';
import DriverLists from './DriverLists';
import useStyles from './driverStyle';

const Drivers: React.FC = () => {
  const { authStore, driverStore, globalStore, todoStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();

  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const [searchDrivers] = useState<DriverSearchFormInputs>({
    driverType: driverStore.Filters.driverType,
    name: driverStore.Filters.name,
    simplexId: driverStore.Filters.simplexId,
  });

  const driverHeader: any = [
    { lable: 'Driver Name/Simplex Driver ID', name: 'name' },
    { lable: 'Terminal', name: 'terminal' },
    { lable: 'Driver Type', name: 'driverType' },
    { lable: 'DOB', name: 'dob' },
    { lable: 'License Type', name: 'dlType' },
    { lable: 'License State/Number', name: '' },
    { lable: 'Hire Date', name: 'hireDate' },

    { lable: 'MVR Expiration Date', name: 'mvrExpirationDate' },
    {
      lable: 'Clearinghouse Expiration Date',
      name: 'clearingHouseExpirationDate',
    },
    { lable: 'Driver License Expiration Date', name: 'cdlExpirationDate' },
    {
      lable: 'Medical Card Expiration Date',
      name: 'medicalCardExpirationDate',
    },
    {
      lable: 'Pre-Employment Drug Test On File',
      name: 'sgPreEmploymentDrugTestOnFile',
    },
    { lable: 'DQ Status', name: '' },
    { lable: 'DQ Files', name: '' },
    '',
  ];
  const terminationHeader: any = [
    { lable: 'Driver Name/Simplex Driver ID', name: 'name' },
    { lable: 'Terminal', name: 'terminal' },
    { lable: 'Driver Type', name: 'driverType' },
    { lable: 'DOB', name: '' },
    { lable: 'License Type', name: '' },
    { lable: 'License State/Number', name: '' },
    { lable: 'Hire Date', name: 'hireDate' },
    {
      lable: 'Termination Date',
      name: 'terminationDate',
    },
    {
      lable: 'Pre-Employment Drug Test On File',
      name: 'sgPreEmploymentDrugTestOnFile',
    },
    { lable: 'DQ Status', name: '' },
    { lable: 'Documents', name: '' },
    '',
  ];

  useEffect(() => {
    return () => {
      driverStore.setDriverApiReset(false);
      driverStore.setFilters({
        driverType: '',
        limit: API_LIMIT,
        name: '',
        nextLink: '',
        simplexId: '',
      });
    };
  }, [driverStore]);

  const getDriversLists = useCallback(async () => {
    driverStore.setShowLoader(true);
    const getDriversResp = await driverStore.fetchDriversBySorting();
    if (getDriversResp.isOk()) {
      driverStore.setShowLoader(false);
    }
    if (getDriversResp.isErr()) {
      driverStore.setShowLoader(false);
      enqueueSnackbar(
        String(getDriversResp.error.message),
        SnackBarConfig('e'),
      );
    }
  }, [driverStore, enqueueSnackbar]);

  const getSearchBasedDrivers = useCallback(
    async (searchParams: DriverSearchFormInputs) => {
      if (
        !searchParams.name &&
        !searchParams.simplexId &&
        !searchParams.driverType
      ) {
        driverStore.setDriverApiReset(false);
        getDriversLists();
        return;
      }
      driverStore.setShowLoader(true);
      const getEquipmentsResp = await driverStore.fetchDriversBySearchBased(
        searchParams,
      );
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      driverStore.setShowLoader(false);
    },
    [driverStore, getDriversLists, enqueueSnackbar],
  );

  const getMoreDrivers = useCallback(async () => {
    if (
      searchDrivers.name ||
      searchDrivers.simplexId ||
      searchDrivers.driverType
    ) {
      getSearchBasedDrivers(searchDrivers);
      return;
    }
    setGetMoreLoader(true);
    const getEquipmentsResp = await driverStore.fetchDrivers();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [driverStore, enqueueSnackbar, getSearchBasedDrivers, searchDrivers]);

  const navigateToTerminate = (driver: any, id = '') => {
    driverStore.setEquipmentFromDriver(driver);
    const currentRoute: string =
      PrivateRoutes.SERVICEREQUEST.TERMINATEDRIVER || '';
    navigate(`${currentRoute}/${id}`);
    globalStore.setCurrentRoute(PrivateRoutes.SERVICEREQUEST.TERMINATEDRIVER);
  };

  const handleClickViewTodos = (
    driverId: string,
    driverName: string,
    driverType: string,
  ) => {
    const nextRoute = NavRoutes.PRIVATE.LEFT_NAV.TODOS;
    todoStore.setFilters({
      categories: [],
      dueIn: '',
      firstName: '',
      lastName: '',
      limit: 20,
      make: '',
      nextLink: '',
      simplexId: '',
      type: '',
      unitNumber: '',
      vinNumber: '',
      year: '',
    });
    todoStore.setSearchType('Driver');
    const driverNameParts = driverName.split(' ');
    navigate(`/${nextRoute}`, {
      state: {
        driverId,
        driverType: driverType,
        firstName: driverNameParts[0],
        lastName:
          driverNameParts.length > 1
            ? driverNameParts[driverNameParts.length - 1]
            : '',
        showBackBtn: true,
        todoType: 'Driver',
      },
    });
  };

  useEffect(() => {
    getDriversLists();
  }, [getDriversLists]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {!driverStore.ShowLoader && (
        <Grid>
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
              letterSpacing: 0,
              lineHeight: '19px',
              padding: '1rem 1.5rem 2.5rem 1.5rem',
            }}>
            {driverStore.Count} {driverStore.Status} Drivers
          </Typography>
        </Grid>
      )}

      {driverStore.ShowLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          style={{ padding: '0 2rem' }}
          justifyContent="space-between">
          <TableContainer
            sx={{ maxHeight: 'calc(100vh - 200px)' }}
            id="drivers-table">
            <InfiniteScroll
              dataLength={
                driverStore.DriverList && driverStore.DriverList.length
                  ? driverStore.DriverList.length
                  : 0
              }
              style={{ overflow: 'unset' }}
              next={getMoreDrivers}
              hasMore={driverStore.HasMoreResults}
              scrollableTarget={'drivers-table'}
              loader={
                getMoreLoader && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <DriverHeader
                    driverHeader={
                      driverStore.Status === 'Terminated'
                        ? terminationHeader
                        : driverHeader
                    }
                    {...{ driverStore }}
                  />
                </TableHead>
                <TableBody>
                  {driverStore.DriverList &&
                  driverStore.DriverList.length > 0 ? (
                    <DriverLists
                      {...{
                        authStore,
                        driverLists: driverStore.DriverList,
                        driverStore: driverStore,
                        onTerminate: navigateToTerminate,
                      }}
                      onClickViewTodos={handleClickViewTodos}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={13}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
    </Box>
  );
};

export default observer(Drivers);
