import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import search from '../../assets/images/h-search.svg';
import { AllowedUploadMimeTypes } from '../../config/Constants';
import { useCustomStore } from '../../hooks';
import { EquipmentSearchFormInputs } from '../../types';
import {
  fieldsForFileType,
  getFileNamingConvention,
  todoVariantToFileType,
} from '../../utils/fileName.config';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import { EquipmentSearchSchema } from '../../utils/ValidatorSchema';
import DialogMigrate from '../Dialog/DialogMigrate';
import { InputText } from '../Forms/InputText';
import SortingIcon from '../SortingIcon/SortingIcon';
import useStyles from './EquipmentDialogStyles';

const useFormStyles = makeStyles(() => ({
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 2,
  },
}));

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));

const LightColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #CBCBCB',
  borderRadius: '8px',
  boxSizing: 'border-box',
  color: theme.palette.getContrastText('#FFFFFF'),
  height: '45px',
  width: '116px',
}));

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '2px 0',
}));
const FileNameChip = styled(Chip)<ButtonProps>(({ theme }) => ({
  marginBottom: 10,
  maxWidth: 140,
  textOverflow: 'ellipsis',
}));

const SelectEquipmentModal: React.FC<any> = (props: any) => {
  const { onClose, todoId, requestType } = props;
  const headers = [
    {
      hideSorting: true,
      label: 'Equipment Type',
    },
    {
      label: 'Unit Type',
      name: 'unitType',
    },
    {
      label: 'Unit #',
      name: 'unitNumber',
    },
    {
      label: 'Simplex Unit #',
      name: 'simplexId',
    },
    {
      label: 'VIN #',
      name: 'vinNumber',
    },
  ];
  const classes = useStyles();
  const formStyles = useFormStyles();
  const { equipmentStore, driverListStore, todoStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const { ShowLoader, EquipmentList, HasMoreResults } = equipmentStore;
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [todoComplete, setTodoComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idsWithAttachments, setIdsWithAttachments] = useState<{
    [key: string]: {
      data: any;
      file: File;
    };
  }>({});
  const [selectedEquipment, setSelectedEquipment] = useState<any>({});

  const [searchEquipmets, setSearchEquipments] =
    useState<EquipmentSearchFormInputs>({
      simplexId: '',
      unitNumber: '',
      vinNumber: '',
    });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    control,
    // handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<EquipmentSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      simplexId: '',
      unitNumber: '',
      vinNumber: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(EquipmentSearchSchema),
  });

  const getAllEquipments = useCallback(async () => {
    equipmentStore.setShowLoader(true);
    const getEquipmentsResp = await equipmentStore.fetchEquipments(
      todoId,
      requestType,
    );
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    equipmentStore.setShowLoader(false);
  }, [equipmentStore, enqueueSnackbar, todoId, requestType]);

  useEffect(() => {
    getAllEquipments();
  }, [getAllEquipments]);

  useEffect(() => {
    return () => {
      equipmentStore.resetSortParams();
    };
  }, [equipmentStore]);

  const getSearchBasedEquipments = useCallback(
    async (searchParams: EquipmentSearchFormInputs) => {
      if (
        !searchParams.unitNumber &&
        !searchParams.simplexId &&
        !searchParams.vinNumber
      ) {
        equipmentStore.setEquipmentApiReset();
        getAllEquipments();
        return;
      }
      equipmentStore.setShowLoader(true);
      const getEquipmentsResp =
        await equipmentStore.fetchEquipmentsBySearchBased(
          searchParams,
          todoId,
          requestType,
        );
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      equipmentStore.setShowLoader(false);
    },
    [equipmentStore, getAllEquipments, enqueueSnackbar, todoId, requestType],
  );

  // add more Equipments on page scroll
  const getMoreEquipments = useCallback(async () => {
    if (
      searchEquipmets.unitNumber ||
      searchEquipmets.simplexId ||
      searchEquipmets.vinNumber
    ) {
      getSearchBasedEquipments(searchEquipmets);
      return;
    }
    setGetMoreLoader(true);
    const getEquipmentsResp = await equipmentStore.fetchEquipments(
      todoId,
      requestType,
    );
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [
    equipmentStore,
    enqueueSnackbar,
    getSearchBasedEquipments,
    searchEquipmets,
    todoId,
    requestType,
  ]);

  const equipementSearchsubmit = useCallback(
    (data: EquipmentSearchFormInputs) => {
      if (data.unitNumber || data.simplexId || data.vinNumber) {
        equipmentStore.setEquipmentApiReset();
        setSearchEquipments((pre) => ({ ...pre, ...data }));
        getSearchBasedEquipments(data);
      }
    },
    [equipmentStore, getSearchBasedEquipments],
  );
  const handleClick = (equipment: any) => {
    setSelectedEquipment({
      equipmentType: equipment.equipmentType,
      grossVehicleWeight: equipment.grossVehicleWeight,
      simplexId: equipment.simplexId,
      status: 'Active',
      unitId: equipment.unitId,
      unitNumber: equipment.unitNumber,
      unitType: equipment.unitType,
      vinNumber: equipment.vinNumber,
    });
    fileInputRef.current?.click();
  };
  const handleFileUpload = (event: any) => {
    const file = event?.target.files[0];
    setIdsWithAttachments({
      ...idsWithAttachments,
      [selectedEquipment.unitId]: {
        data: selectedEquipment,
        file,
      },
    });
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const getDataForFileUpload = (equipment: any) => {
    const { unitId } = equipment;
    const { variant = '' } = props;
    const fileType: FileType | undefined =
      todoVariantToFileType[variant as TodoFormVariant];

    const fileTypeFieldsArray = fileType ? fieldsForFileType[fileType] : [];
    const fileTypeFieldsObj: Record<string, unknown> = {};

    fileTypeFieldsArray.forEach((x) => {
      fileTypeFieldsObj[x] = equipment[x]?.toString();
    });

    fileTypeFieldsObj.expirationDate = props?.permitInfo?.expDate;
    fileTypeFieldsObj.nySeries = props?.permitInfo?.nySeries;

    const fileName =
      fileType && getFileNamingConvention[fileType]
        ? getFileNamingConvention[fileType].name(fileTypeFieldsObj)
        : variant;

    const obj: any = {
      fileName,
      fileType,
      service: variant,
    };
    obj.unit = {
      unitId,
    };
    obj.type = 'unit';
    return [obj];
  };

  const resolveFileUpload = (data: any) => {
    const { value, error } = data;
    if (error) {
      return {};
    }
    return value[0];
  };
  const rejectFileUpload = () => {
    return {};
  };

  const preparePayloadForFileUpload = (data: any, file: File) => {
    const fData = new FormData();
    fData.set('files', file);
    fData.set('data', JSON.stringify(data));
    return fData;
  };
  const handleSubmit = () => {
    const selectedIds = Object.keys(idsWithAttachments);
    if (selectedIds && selectedIds.length > 15) {
      enqueueSnackbar('Maximum 15 records allowed', SnackBarConfig('e'));
      return;
    }

    const fileUploadRequests = selectedIds.map((id) => {
      const { file, data: data_ } = idsWithAttachments[id];
      const data = getDataForFileUpload(data_);
      const payload = preparePayloadForFileUpload(data, file);
      return driverListStore
        .uploadFileForSignature(payload)
        .then(resolveFileUpload)
        .catch(rejectFileUpload);
    });
    setIsLoading(true);
    (async (requests) => {
      const responses = await Promise.all(requests);
      const equipment = selectedIds.map((id, index) => ({
        ...idsWithAttachments[id].data,
        document: responses[index],
      }));
      const payload = { equipment, fieldsChanged: [], todoComplete };
      const variant: string = props.variant;
      const todoId: string = props.todoId;
      const url = `tpts/${variant}/${todoId}`;
      const { success } = await todoStore.submitTodo(url, payload);
      setIsLoading(false);
      if (success) {
        enqueueSnackbar(
          'Request submitted successfully',
          SnackBarConfig('success'),
        );
        closeDialogWindow();
        return;
      }
      enqueueSnackbar(
        'Failed to submit request. Try again',
        SnackBarConfig('e'),
      );
      return;
    })(fileUploadRequests);
  };
  const closeDialogWindow = () => {
    equipmentStore.setEquipmentApiReset();
    onClose && onClose();
  };
  const clearForm = () => {
    reset();
    setSearchEquipments((pre) => ({
      ...pre,
      ...{
        simplexId: '',
        unitNumber: '',
        vinNumber: '',
      },
    }));
    equipmentStore.setEquipmentApiReset();
    getAllEquipments();
  };
  const searchValue = () => {
    equipementSearchsubmit(getValues());
  };
  const handleFileDelete = (id: string) => {
    const temp = { ...idsWithAttachments };
    delete temp[id];
    setIdsWithAttachments({ ...temp });
  };
  const getUploadBtnOrFileFeedback = (equipment: any) => {
    const id = equipment.unitId;
    const attachment = idsWithAttachments[id];
    const hasAttachment = !!attachment;
    return !hasAttachment ? (
      <LightColorButton
        type="button"
        variant="contained"
        style={{ bottom: '8px' }}
        onClick={() => handleClick(equipment)}>
        <Typography
          sx={{
            color: '#241A2E',
            fontFamily: 'FiraSans-Medium',
            fontSize: '0.9375rem',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textAlign: 'center',
            width: '55px',
          }}>
          Upload
        </Typography>
      </LightColorButton>
    ) : (
      <>
        <FileNameChip
          onDelete={() => handleFileDelete(id)}
          label={attachment.file.name}
        />
        <br />
      </>
    );
  };
  const sortBY = async (e: any) => {
    equipmentStore.setSortBy(e);
    equipmentStore.setSortDirection(
      equipmentStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    equipmentStore.setShowLoader(true);
    await equipmentStore.fetchEquipmentsBySorting(todoId, requestType, true);
    equipmentStore.setShowLoader(false);
  };
  return (
    <DialogMigrate
      aria-labelledby="equipment-select"
      className={classes.dialog}
      maxWidth="md"
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={closeDialogWindow}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            Search & Select Equipment
          </div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialogWindow();
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>
      {!isLoading && (
        <form id={'equipment-dialog'} className={classes.headerWrapper}>
          <Grid
            container
            spacing={1}
            ml={0}
            pl={'15px'}
            pr={'24px'}
            sx={{ paddingBottom: '9.5px', width: '100%' }}>
            <Grid item md={3} xs={3}>
              <FormControl>
                <Controller
                  control={control}
                  defaultValue=""
                  name="unitNumber"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        name={name}
                        inputRefObj={ref}
                        placeholder="Unit #"
                        errors={errors.unitNumber?.message || ''}
                        isRefreshValue={true}
                        isEditable={false}
                        type={'text'}
                        variant="standard"
                        value={value}
                        initIcon={
                          <InputAdornment position="start">
                            <img className={classes.hIcon} src={search} />
                          </InputAdornment>
                        }
                        className={classes.selectClassName}
                        onChangeText={(val) => {
                          onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} xs={3}>
              <FormControl>
                <Controller
                  control={control}
                  defaultValue=""
                  name="simplexId"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        name={name}
                        inputRefObj={ref}
                        placeholder="Simplex Unit #"
                        errors={errors.simplexId?.message || ''}
                        isRefreshValue={true}
                        isEditable={false}
                        type={'text'}
                        variant="standard"
                        value={value}
                        initIcon={
                          <InputAdornment position="start">
                            <img className={classes.hIcon} src={search} />
                          </InputAdornment>
                        }
                        className={classes.selectClassName}
                        onChangeText={(val) => {
                          onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} xs={3}>
              <FormControl>
                <Controller
                  control={control}
                  defaultValue=""
                  name="vinNumber"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        name={name}
                        inputRefObj={ref}
                        placeholder="VIN"
                        errors={errors.vinNumber?.message || ''}
                        isRefreshValue={true}
                        isEditable={false}
                        type={'text'}
                        variant="standard"
                        value={value}
                        initIcon={
                          <InputAdornment position="start">
                            <img className={classes.hIcon} src={search} />
                          </InputAdornment>
                        }
                        className={classes.selectClassName}
                        onChangeText={(val) => {
                          onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} xs={3}>
              <div className={classes.headerActionBtn}>
                <DarkColorButton
                  type="button"
                  onClick={() => searchValue()}
                  variant="contained"
                  sx={{ borderRadius: '4px', width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontFamily: 'FiraSans-Medium',
                      fontSize: '0.9375rem',
                      fontWeight: 500,
                      height: '22px',
                      letterSpacing: '1px',
                      lineHeight: '22px',
                      textAlign: 'center',
                    }}>
                    Search
                  </Typography>
                </DarkColorButton>

                {searchEquipmets.unitNumber ||
                searchEquipmets.simplexId ||
                searchEquipmets.vinNumber ? (
                  <Typography component={'span'} marginLeft={'12px'}>
                    <Button
                      variant={'text'}
                      className={'d-flex-ja '.concat(classes.cancelBtn)}
                      onClick={() => clearForm()}>
                      Clear
                    </Button>
                  </Typography>
                ) : (
                  ''
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      )}
      {isLoading && (
        <div className={formStyles.overlay}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      )}
      <>
        <DialogContent id="scroll">
          <Box
            sx={{
              marginBottom: '15px',
            }}>
            <Grid container spacing={2}>
              <Divider className={classes.divider} />
              {headers.map((header: any, index: number) => (
                <Grid
                  container
                  xs={2}
                  key={index}
                  justifyContent="flex-start"
                  alignItems="center"
                  marginLeft="20">
                  <Grid item>
                    <Typography
                      noWrap
                      sx={{
                        color: '#A4A4A4',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.75rem',
                        height: '14px',
                        letterSpacing: 0,
                        lineHeight: '14px',
                      }}>
                      {header.label}
                    </Typography>
                  </Grid>
                  {!header.hideSorting && (
                    <Grid item>
                      <SortingIcon
                        {...{
                          sortBY,
                          sortByName: header.name,
                          store: equipmentStore,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
          <input
            ref={fileInputRef}
            onChange={handleFileUpload}
            type="file"
            accept={AllowedUploadMimeTypes}
            hidden
          />
          <InfiniteScroll
            dataLength={EquipmentList.length}
            // height={windowHeight}
            style={{ overflow: 'unset' }}
            next={getMoreEquipments}
            hasMore={HasMoreResults}
            scrollableTarget={'scroll'}
            loader={
              getMoreLoader && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              )
            }
            endMessage>
            <Grid
              container
              spacing={2}
              style={{
                backgroundColor: '#FFFFFF',
                marginBottom: '10px',
              }}>
              {ShowLoader ? (
                <div className={classes.loader}>
                  <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                </div>
              ) : EquipmentList.length > 0 ? (
                EquipmentList.map((equipment: any, index: number) => (
                  <React.Fragment key={index}>
                    <Grid item xs={2}>
                      <Typography
                        noWrap
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Regular',
                          fontSize: '0.9375rem',
                          height: '14px',
                          letterSpacing: 0,
                          lineHeight: '14px',
                          textTransform: 'capitalize',
                        }}>
                        {equipment.equipmentType}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        noWrap
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Regular',
                          fontSize: '0.9375rem',
                          height: '18px',
                          letterSpacing: 0,
                          lineHeight: '18px',
                          textTransform: 'capitalize',
                        }}>
                        {equipment.unitType}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        noWrap
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Regular',
                          fontSize: '0.9375rem',
                          height: '18px',
                          letterSpacing: 0,
                          lineHeight: '18px',
                          textTransform: 'capitalize',
                        }}>
                        {equipment.unitNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        noWrap
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Regular',
                          fontSize: '0.9375rem',
                          height: '18px',
                          letterSpacing: 0,
                          lineHeight: '18px',
                          textTransform: 'capitalize',
                        }}>
                        {equipment.simplexId}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        noWrap
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Regular',
                          fontSize: '0.9375rem',
                          height: '18px',
                          letterSpacing: 0,
                          lineHeight: '18px',
                          textTransform: 'capitalize',
                        }}>
                        {equipment.vinNumber}
                        {equipment.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {getUploadBtnOrFileFeedback(equipment)}
                    </Grid>
                    <Divider className={classes.divider} />
                  </React.Fragment>
                ))
              ) : (
                <div className={classes.noRecordsFound}>No data available</div>
              )}
              <Divider className={classes.divider} />
            </Grid>
          </InfiniteScroll>
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid sm={12} lg={12}>
              <FormControlLabel
                onChange={(e) => {
                  setTodoComplete(!todoComplete);
                }}
                label="Complete the TODO"
                control={<Checkbox />}
              />
            </Grid>
            <Grid sm={12} lg={12}>
              <BottomButton
                type={'button'}
                variant="contained"
                sx={{ width: '100%' }}
                onClick={() => handleSubmit()}>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontFamily: 'FiraSans-Medium',
                    fontSize: '0.9375rem',
                    fontWeight: '500',
                    height: '22px',
                    letterSpacing: '1px',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  Submit Todo
                </Typography>
              </BottomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    </DialogMigrate>
  );
};

export default observer(SelectEquipmentModal);
