import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import {
  CreateTrainingAccidentAssignmentInputs,
  CreateTrainingInspectionAssignmentInputs,
  TrainingAccidentFilters,
  TrainingAssignedFilters,
  TrainingCompletedFilters,
  TrainingInspectionFilters,
  TrainingType,
  UpdateQuizAnswersForAssignmentInputs,
} from '../types';
import { API_DATE_FORMAT } from '../utils/helper';
import { RootStore } from '.';

export const TrainingAssignmentStatus = {
  ASSIGNED: {
    ASSIGNED: 'Assigned',
    QUIZ_FAILED: 'Quiz Failed',
    QUIZ_PENDING: 'Quiz Pending',
    VIDEO_PENDING: 'Video Pending',
  },
  COMPLETED: {
    COMPLETED: 'Completed',
    DISMISSED: 'Dismissed',
    FAILED: 'Failed',
    NO_ACTION_ON_RECOMMENDATION: 'No Action on Recommendation',
    NO_ACTION_ON_TODO: 'No Action on To-Do',
    RECOMMENDATION_DISMISSED: 'Recommendation Dismissed',
  },
};

export class TrainingStore {
  private _refreshTrainingAssignedData = false;

  private _trainingInspectionsData: any[] = [];
  private _trainingRecommendationsData: any[] = [];
  private _trainingAccidentsData: any[] = [];
  private _trainingAssignedData: any[] = [];
  private _trainingCompletedData: any[] = [];
  private _trainingAssignmentStatusesData: any[] = [];
  private _trainingAssignmentReasonsData: any[] = [];
  private _trainingAssigneesData: any[] = [];

  private _trainingInspectionsLoader = false;
  private _trainingRecommendationsLoader = false;
  private _trainingAccidentsLoader = false;
  private _trainingAssignedLoader = false;
  private _trainingCompletedLoader = false;
  private _trainingAssignmentStatusesLoader = false;
  private _trainingAssignmentReasonsLoader = false;
  private _trainingAssigneesLoader = false;

  private _trainingInspectionsNextLink = '';
  private _trainingAccidentsNextLink = '';
  private _trainingAssignedNextLink = '';
  private _trainingCompletedNextLink = '';

  private _hasMoreTrainingInspections = false;
  private _hasMoreTrainingAccidents = false;
  private _hasMoreTrainingAssigned = false;
  private _hasMoreTrainingCompleted = false;

  private _downloadTrainingCertificate: { loader: boolean; id: string } = {
    id: '',
    loader: false,
  };

  private _trainingInspectionFilters: TrainingInspectionFilters = {
    driverId: '',
    driverName: '',
    driverSimplexId: '',
    inspectionDateEnd: '',
    inspectionDateStart: '',
    inspectionState: '',
    limit: '20',
    nextLink: '',
    reportNumber: '',
    sortBy: '',
    sortChanged: false,
    sortDirection: 'asc',
  };
  private _trainingAccidentFilters: TrainingAccidentFilters = {
    accidentDateEnd: '',
    accidentDateStart: '',
    accidentNumber: '',
    accidentState: '',
    driverId: '',
    driverName: '',
    limit: '20',
    nextLink: '',
    powerUnitVin: '',
    sortBy: '',
    sortChanged: false,
    sortDirection: 'asc',
  };
  private _trainingAssignedFilters: TrainingAssignedFilters = {
    accidentNumber: '',
    assignedBy: '',
    assignedById: '',
    assignedDateEnd: '',
    assignedDateStart: '',
    contactId: '',
    contactName: '',
    dueDateEnd: '',
    dueDateStart: '',
    inspectionReportNumber: '',
    limit: '20',
    nextLink: '',
    reason: '',
    sortBy: undefined,
    sortChanged: false,
    sortDirection: 'asc',
    status: undefined,
    trainingType: TrainingType.ALL,
    videoAssigned: '',
  };
  private _trainingCompletedFilters: TrainingCompletedFilters = {
    accidentNumber: '',
    assignedBy: '',
    assignedById: '',
    contactId: '',
    contactName: '',
    endDateEnd: '',
    endDateStart: '',
    inspectionReportNumber: '',
    limit: '20',
    nextLink: '',
    reason: '',
    sortBy: undefined,
    sortChanged: false,
    sortDirection: 'asc',
    startDateEnd: '',
    startDateStart: '',
    status: undefined,
    trainingType: TrainingType.ALL,
    videoAssigned: '',
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setRefreshTrainingAssignedData(value: boolean) {
    this._refreshTrainingAssignedData = value;
  }
  get RefreshTrainingAssignedData() {
    return this._refreshTrainingAssignedData;
  }

  setTrainingInspectionsData(data: any[]) {
    this._trainingInspectionsData = data;
  }
  setTrainingRecommendationsData(data: any[]) {
    this._trainingRecommendationsData = data;
  }
  setTrainingAccidentsData(data: any[]) {
    this._trainingAccidentsData = data;
  }
  setTrainingAssignedData(data: any[]) {
    this._trainingAssignedData = data;
  }
  setTrainingCompletedData(data: any[]) {
    this._trainingCompletedData = data;
  }
  setTrainingAssignmentStatusesData(data: any[]) {
    this._trainingAssignmentStatusesData = data;
  }
  setTrainingAssignmentReasonsData(data: any[]) {
    this._trainingAssignmentReasonsData = data;
  }
  setTrainingAssigneesData(data: any[]) {
    this._trainingAssigneesData = data;
  }
  get TrainingInspectionsData() {
    return this._trainingInspectionsData;
  }
  get TrainingRecommendationsData() {
    return this._trainingRecommendationsData;
  }
  get TrainingAssigneesData() {
    return this._trainingAssigneesData;
  }
  get TrainingAccidentsData() {
    return this._trainingAccidentsData;
  }
  get TrainingAssignedData() {
    return this._trainingAssignedData;
  }
  get TrainingCompletedData() {
    return this._trainingCompletedData;
  }
  get TrainingAssignmentStatusesData() {
    return this._trainingAssignmentStatusesData;
  }
  get TrainingAssignedAssignmentStatusesData() {
    return this._trainingAssignmentStatusesData.filter((s) =>
      Object.values(TrainingAssignmentStatus.ASSIGNED).includes(s?.name),
    );
  }
  get TrainingCompletedAssignmentStatusesData() {
    return this._trainingAssignmentStatusesData.filter((s) =>
      Object.values(TrainingAssignmentStatus.COMPLETED).includes(s?.name),
    );
  }
  get TrainingAssignmentReasonsData() {
    return this._trainingAssignmentReasonsData;
  }

  setTrainingInspectionsLoader(value: boolean) {
    this._trainingInspectionsLoader = value;
  }
  setTrainingRecommendationsLoader(value: boolean) {
    this._trainingRecommendationsLoader = value;
  }
  setTrainingAccidentsLoader(value: boolean) {
    this._trainingAccidentsLoader = value;
  }
  setTrainingAssignedLoader(value: boolean) {
    this._trainingAssignedLoader = value;
  }
  setTrainingCompletedLoader(value: boolean) {
    this._trainingCompletedLoader = value;
  }
  setTrainingAssignmentStatusesLoader(value: boolean) {
    this._trainingAssignmentStatusesLoader = value;
  }
  setTrainingAssignmentReasonsLoader(value: boolean) {
    this._trainingAssignmentReasonsLoader = value;
  }
  setTrainingAssigneesLoader(value: boolean) {
    this._trainingAssigneesLoader = value;
  }
  get TrainingInspectionsLoader() {
    return this._trainingInspectionsLoader;
  }
  get TrainingRecommendationsLoader() {
    return this._trainingRecommendationsLoader;
  }
  get TrainingAccidentsLoader() {
    return this._trainingAccidentsLoader;
  }
  get TrainingAssignedLoader() {
    return this._trainingAssignedLoader;
  }
  get TrainingCompletedLoader() {
    return this._trainingCompletedLoader;
  }
  get TrainingAssignmentStatusesLoader() {
    return this._trainingAssignmentStatusesLoader;
  }
  get TrainingAssignmentReasonsLoader() {
    return this._trainingAssignmentReasonsLoader;
  }
  get TrainingAssigneesLoader() {
    return this._trainingAssigneesLoader;
  }

  setHasMoreTrainingInspections(hasMore: boolean) {
    this._hasMoreTrainingInspections = hasMore;
  }
  setHasMoreTrainingAccidents(hasMore: boolean) {
    this._hasMoreTrainingAccidents = hasMore;
  }
  setHasMoreTrainingAssigned(hasMore: boolean) {
    this._hasMoreTrainingAssigned = hasMore;
  }
  setHasMoreTrainingCompleted(hasMore: boolean) {
    this._hasMoreTrainingCompleted = hasMore;
  }
  get HasMoreTrainingInspections() {
    return this._hasMoreTrainingInspections;
  }
  get HasMoreTrainingAccidents() {
    return this._hasMoreTrainingAccidents;
  }
  get HasMoreTrainingAssigned() {
    return this._hasMoreTrainingAssigned;
  }
  get HasMoreTrainingCompleted() {
    return this._hasMoreTrainingCompleted;
  }

  setTrainingInspectionsNextLink(link: string) {
    this._trainingInspectionsNextLink = link;
  }
  setTrainingAccidentsNextLink(link: string) {
    this._trainingAccidentsNextLink = link;
  }
  setTrainingAssignedNextLink(link: string) {
    this._trainingAssignedNextLink = link;
  }
  setTrainingCompletedNextLink(link: string) {
    this._trainingCompletedNextLink = link;
  }
  get TrainingInspectionsNextLink() {
    return this._trainingInspectionsNextLink;
  }
  get TrainingAccidentsNextLink() {
    return this._trainingAccidentsNextLink;
  }
  get TrainingAssignedNextLink() {
    return this._trainingAssignedNextLink;
  }
  get TrainingCompletedNextLink() {
    return this._trainingCompletedNextLink;
  }

  setTrainingInspectionFilters(filters: TrainingInspectionFilters) {
    this._trainingInspectionFilters = {
      ...this._trainingInspectionFilters,
      ...filters,
    };
  }
  setTrainingAccidentFilters(filters: TrainingAccidentFilters) {
    this._trainingAccidentFilters = {
      ...this._trainingAccidentFilters,
      ...filters,
    };
  }
  setTrainingAssignedFilters(filters: TrainingAssignedFilters) {
    this._trainingAssignedFilters = {
      ...this._trainingAssignedFilters,
      ...filters,
    };
  }
  setTrainingCompletedFilters(filters: TrainingCompletedFilters) {
    this._trainingCompletedFilters = {
      ...this._trainingCompletedFilters,
      ...filters,
    };
  }
  get TrainingInspectionFilters() {
    return this._trainingInspectionFilters;
  }
  get TrainingAccidentFilters() {
    return this._trainingAccidentFilters;
  }
  get TrainingAssignedFilters() {
    return this._trainingAssignedFilters;
  }
  get TrainingCompletedFilters() {
    return this._trainingCompletedFilters;
  }
  resetTrainingInspectionFilters() {
    this.setTrainingInspectionFilters({
      driverId: '',
      driverName: '',
      driverSimplexId: '',
      inspectionDateEnd: '',
      inspectionDateStart: '',
      inspectionState: '',
      reportNumber: '',
      sortBy: '',
      sortChanged: true,
      sortDirection: 'asc',
    });
  }
  resetTrainingAccidentFilters() {
    this.setTrainingAccidentFilters({
      accidentDateEnd: '',
      accidentDateStart: '',
      accidentNumber: '',
      accidentState: '',
      driverId: '',
      driverName: '',
      powerUnitVin: '',
      sortBy: '',
      sortChanged: true,
      sortDirection: 'asc',
    });
  }
  resetTrainingAssignedFilters() {
    this.setTrainingAssignedFilters({
      accidentNumber: '',
      assignedBy: '',
      assignedById: '',
      assignedDateEnd: '',
      assignedDateStart: '',
      contactId: '',
      contactName: '',
      dueDateEnd: '',
      dueDateStart: '',
      inspectionReportNumber: '',
      reason: '',
      sortBy: undefined,
      sortChanged: false,
      sortDirection: 'asc',
      status: undefined,
      trainingType: TrainingType.ALL,
      videoAssigned: '',
    });
  }
  resetTrainingCompletedFilters() {
    this.setTrainingCompletedFilters({
      accidentNumber: '',
      assignedBy: '',
      assignedById: '',
      contactId: '',
      contactName: '',
      endDateEnd: '',
      endDateStart: '',
      inspectionReportNumber: '',
      reason: '',
      sortBy: undefined,
      sortChanged: false,
      sortDirection: 'asc',
      startDateEnd: '',
      startDateStart: '',
      status: undefined,
      trainingType: TrainingType.ALL,
      videoAssigned: '',
    });
  }

  setDownloadTrainingCertificate(data: { loader: boolean; id: string }) {
    this._downloadTrainingCertificate = data;
  }
  get DownloadTrainingCertificate() {
    return this._downloadTrainingCertificate;
  }

  resetTrainingInspectionsApi() {
    this.setTrainingInspectionsNextLink('');
    this.setHasMoreTrainingInspections(false);
    this.setTrainingInspectionsData([]);
  }
  resetTrainingRecommendationsData() {
    this.setTrainingRecommendationsData([]);
  }
  resetTrainingAccidentsApi() {
    this.setTrainingAccidentsNextLink('');
    this.setHasMoreTrainingAccidents(false);
    this.setTrainingAccidentsData([]);
  }
  resetTrainingAssignedApi() {
    this.setTrainingAssignedNextLink('');
    this.setHasMoreTrainingAssigned(false);
    this.setTrainingAssignedData([]);
  }
  resetTrainingCompletedApi() {
    this.setTrainingCompletedNextLink('');
    this.setHasMoreTrainingCompleted(false);
    this.setTrainingCompletedData([]);
  }

  async getTrainingInspections(): Promise<any> {
    const params: TrainingInspectionFilters = {
      ...this._trainingInspectionFilters,
      inspectionDateEnd: this._trainingInspectionFilters?.inspectionDateEnd
        ? moment(this._trainingInspectionFilters.inspectionDateEnd).format(
            API_DATE_FORMAT,
          )
        : '',
      inspectionDateStart: this._trainingInspectionFilters?.inspectionDateStart
        ? moment(this._trainingInspectionFilters.inspectionDateStart).format(
            API_DATE_FORMAT,
          )
        : '',
      nextLink: this.TrainingInspectionsNextLink,
    };
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingInspections(
        params,
      );
    if (response.isOk()) {
      const { response: data, nextLink } = response.value;
      const fullResult = this._trainingInspectionsData.concat(data);
      this.setTrainingInspectionsData(fullResult);
      this.setTrainingInspectionsNextLink(String(nextLink));
      this.setHasMoreTrainingInspections(nextLink ? true : false);
      if (Array.isArray(data) && data.length < 1) {
        this.setTrainingInspectionsNextLink('');
        this.setHasMoreTrainingInspections(false);
      }
    }
    return response;
  }

  async getTrainingInspectionRecommendations(
    inspectionId: string,
    contactId: string,
  ): Promise<any> {
    this.resetTrainingRecommendationsData();
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingInspectionRecommendations(
        inspectionId,
        contactId,
      );
    if (response.isOk()) {
      const { response: data } = response.value;
      this.setTrainingRecommendationsData(
        data.map((v: any) => {
          return { ...v, isAssigned: true };
        }),
      );
    }
    return response;
  }

  async getTrainingAccidents(): Promise<any> {
    const params: TrainingAccidentFilters = {
      ...this._trainingAccidentFilters,
      accidentDateEnd: this._trainingAccidentFilters?.accidentDateEnd
        ? moment(this._trainingAccidentFilters.accidentDateEnd).format(
            API_DATE_FORMAT,
          )
        : '',
      accidentDateStart: this._trainingAccidentFilters?.accidentDateStart
        ? moment(this._trainingAccidentFilters.accidentDateStart).format(
            API_DATE_FORMAT,
          )
        : '',
      nextLink: this.TrainingAccidentsNextLink,
    };
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingAccidents(
        params,
      );
    if (response.isOk()) {
      const { response: data, nextLink } = response.value;
      const fullResult = this._trainingAccidentsData.concat(data);
      this.setTrainingAccidentsData(fullResult);
      this.setTrainingAccidentsNextLink(String(nextLink));
      this.setHasMoreTrainingAccidents(nextLink ? true : false);
      if (Array.isArray(data) && data.length < 1) {
        this.setTrainingAccidentsNextLink('');
        this.setHasMoreTrainingAccidents(false);
      }
    }
    return response;
  }

  async getTrainingAssigned(): Promise<any> {
    const params: TrainingAssignedFilters = {
      ...this._trainingAssignedFilters,
      assignedDateEnd: this._trainingAssignedFilters?.assignedDateEnd
        ? moment(this._trainingAssignedFilters.assignedDateEnd).format(
            API_DATE_FORMAT,
          )
        : '',
      assignedDateStart: this._trainingAssignedFilters?.assignedDateStart
        ? moment(this._trainingAssignedFilters.assignedDateStart).format(
            API_DATE_FORMAT,
          )
        : '',
      dueDateEnd: this._trainingAssignedFilters?.dueDateEnd
        ? moment(this._trainingAssignedFilters.dueDateEnd).format(
            API_DATE_FORMAT,
          )
        : '',
      dueDateStart: this._trainingAssignedFilters?.dueDateStart
        ? moment(this._trainingAssignedFilters.dueDateStart).format(
            API_DATE_FORMAT,
          )
        : '',
      nextLink: this.TrainingAssignedNextLink,
    };
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingAssigned(
        params,
      );
    if (response.isOk()) {
      const { response: data, nextLink } = response.value;
      const fullResult = this._trainingAssignedData.concat(data);
      this.setTrainingAssignedData(fullResult);
      this.setTrainingAssignedNextLink(String(nextLink));
      this.setHasMoreTrainingAssigned(nextLink ? true : false);
      this.setRefreshTrainingAssignedData(false);
      if (Array.isArray(data) && data.length < 1) {
        this.setTrainingAssignedNextLink('');
        this.setHasMoreTrainingAssigned(false);
      }
    }
    return response;
  }

  async getTrainingCompleted(): Promise<any> {
    const params: TrainingCompletedFilters = {
      ...this._trainingCompletedFilters,
      endDateEnd: this._trainingCompletedFilters?.endDateEnd
        ? moment(this._trainingCompletedFilters.endDateEnd).format(
            API_DATE_FORMAT,
          )
        : '',
      endDateStart: this._trainingCompletedFilters?.endDateStart
        ? moment(this._trainingCompletedFilters.endDateStart).format(
            API_DATE_FORMAT,
          )
        : '',
      nextLink: this.TrainingCompletedNextLink,
      startDateEnd: this._trainingCompletedFilters?.startDateEnd
        ? moment(this._trainingCompletedFilters.startDateEnd).format(
            API_DATE_FORMAT,
          )
        : '',
      startDateStart: this._trainingCompletedFilters?.startDateStart
        ? moment(this._trainingCompletedFilters.startDateStart).format(
            API_DATE_FORMAT,
          )
        : '',
    };
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingCompleted(
        params,
      );
    if (response.isOk()) {
      const { response: data, nextLink } = response.value;
      const fullResult = this._trainingCompletedData.concat(data);
      this.setTrainingCompletedData(fullResult);
      this.setTrainingCompletedNextLink(String(nextLink));
      this.setHasMoreTrainingCompleted(nextLink ? true : false);
      if (Array.isArray(data) && data.length < 1) {
        this.setTrainingCompletedNextLink('');
        this.setHasMoreTrainingCompleted(false);
      }
    }
    return response;
  }

  async getTrainingAccidentRecommendations(
    accidentId: string,
    contactId: string,
  ): Promise<any> {
    this.resetTrainingRecommendationsData();
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingAccidentRecommendations(
        accidentId,
        contactId,
      );
    if (response.isOk()) {
      const { response: data } = response.value;
      this.setTrainingRecommendationsData(
        data.map((v: any) => {
          return { ...v, isAssigned: true };
        }),
      );
    }
    return response;
  }

  async getTrainingAssignmentStatuses(): Promise<any> {
    this.setTrainingAssignmentStatusesData([]);
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingAssignmentStatuses();
    if (response.isOk()) {
      const { response: data } = response.value;
      this.setTrainingAssignmentStatusesData(data);
    }
    return response;
  }

  async getTrainingAssignmentReasons(params: {
    userType?: number;
    showSystemReason?: boolean;
  }): Promise<any> {
    this.setTrainingAssignmentReasonsData([]);
    const response =
      await this.rootStore.apiStore.trainingApiStore.getTrainingAssignmentReasons(
        // eslint-disable-next-line prettier/prettier
        params,
      );
    if (response.isOk()) {
      const { response: data } = response.value;
      this.setTrainingAssignmentReasonsData(data);
    }
    return response;
  }

  async getInitialTrainingInspections() {
    this.resetTrainingInspectionsApi();
    return await this.getTrainingInspections();
  }
  async getInitialTrainingAccidents() {
    this.resetTrainingAccidentsApi();
    return await this.getTrainingAccidents();
  }
  async getInitialTrainingAssigned() {
    this.resetTrainingAssignedApi();
    return await this.getTrainingAssigned();
  }
  async getInitialTrainingCompleted() {
    this.resetTrainingCompletedApi();
    return await this.getTrainingCompleted();
  }

  async createTrainingAccidentAssignments(
    body: CreateTrainingAccidentAssignmentInputs,
  ): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.createTrainingAccidentAssignments(
      body,
    );
  }

  async createTrainingInspectionAssignments(
    body: CreateTrainingInspectionAssignmentInputs,
  ): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.createTrainingInspectionAssignments(
      body,
    );
  }

  async getTrainingCertificate(spURL: string): Promise<any> {
    const response =
      await this.rootStore.apiStore.trainingApiStore.getCertificateForAnAssignment(
        spURL,
      );
    return response;
  }

  async dismissAssignment(assignmentId: string): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.dismissAssignment(
      assignmentId,
    );
  }

  async getAllTrainingsForCustomAssignment(params: {
    reasonId: string;
    contactId: string;
  }): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.getAllTrainingsForCustomAssignment(
      params,
    );
  }

  async getAllUsersForTraining(params: {
    userType: 'backOfficePersonnel' | 'driver';
    searchText?: string;
    limit?: string;
    nextLink?: string;
  }): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.getAllUsersForTraining(
      { limit: '100', ...params },
    );
  }

  async createCustomTrainingAssignment(body: {
    trainingId: string;
    isAssigned: boolean;
    contactId: string;
    reasonId: string;
    dueDate: string;
  }): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.createCustomTrainingAssignment(
      body,
    );
  }

  async changeTrainingAssignmentDueDate(params: {
    assignmentId: string;
    dueDate: string;
  }): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.changeTrainingAssignmentDueDate(
      params,
    );
  }

  async getTrainingFileLink(body: { filePath: string }): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.getTrainingFileLink(
      body,
    );
  }

  async updateTrainingAssignmentStatus(
    assignmentId: string,
    body: { status: string; hasPassedQuiz?: boolean; score?: number },
  ): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.updateTrainingAssignmentStatus(
      assignmentId,
      body,
    );
  }

  async getQuizDetailsForAssignment(assignmentId: string): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.getQuizDetailsForAssignment(
      assignmentId,
    );
  }

  async updateQuizAnswersForAssignment(
    assignmentId: string,
    body: UpdateQuizAnswersForAssignmentInputs,
  ): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.updateQuizAnswersForAssignment(
      assignmentId,
      body,
    );
  }

  async getReassignAssignmentContacts(assignmentId: string): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.getReassignAssignmentContacts(
      assignmentId,
    );
  }

  async reassignAssignment(
    assignmentId: string,
    driverId?: string,
  ): Promise<any> {
    return await this.rootStore.apiStore.trainingApiStore.reassignAssignment(
      assignmentId,
      driverId,
    );
  }

  async checkIfCertificateAvailable(url: string): Promise<any> {
    return await this.rootStore.apiStore.call<any>({
      method: 'GET',
      url,
    });
  }
}
