export const terminateDriver = [
  'Voluntary Quit',
  'Laid Off',
  'Client Terminated',
  'Performance deficiency',
  'Negative Response from Previous Employer',
  'Positive Drug Test',
  'Failed MVR',
  'Failed Background Check',
  'Insurance Issue',
  'Unknown',
];
