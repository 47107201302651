import React from 'react';

import DriverInfo from '../DriverInfo/DriverInfo';
import DataQChallengeForm from './DataQChallengeForm';
import useStyles from './DataQChallengeStyles';

const DataQChallenge: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <DriverInfo />
      <DataQChallengeForm />
    </div>
  );
};

export default DataQChallenge;
