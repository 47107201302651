import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

const DriverTerminationContract: React.FC = () => {
  const pStyle = {
    fontFamily: 'Calibri,sans-serif',
    fontSize: '11.0pt',
    marginBottom: '8.0pt',
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    textAlign: 'justify' as const,
  };

  const pStyleCenter = {
    fontFamily: 'Calibri,sans-serif',
    fontSize: '11.0pt',
    marginBottom: '8.0pt',
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    textAlign: 'center' as const,
  };

  return (
    <Box>
      <p style={pStyleCenter}>
        <strong>Driver Termination and Rehire Disclosure</strong>
      </p>
      <p style={pStyle}>
        Simplex Transportation Management Services, Inc. d/b/a Simplex Group
        Driver Termination and Rehire Policy (&quot;Policy&quot;) governs the
        termination and rehire process for drivers managed through Simplex
        Group&rsquo;s Services. By proceeding with this driver termination
        request, you acknowledge and agree to comply with the terms outlined in
        Disclosure.
      </p>
      <p style={pStyleCenter}>
        <u>Termination and Rehire Terms</u>
      </p>
      <p style={pStyle}>
        Regulatory Requirement: In accordance with the regulations set forth by
        the Federal Motor Carrier Safety Administration (FMCSA) 49 CFR 382.301,
        &sect;391.21, and 391.23, in the event, a driver has not been employed
        by the company for a duration exceeding 30 days, the driver hiring
        process must be reinitiated. This includes, but is not limited to,
        background checks, drug testing, and other necessary screenings.
      </p>
      <p style={pStyle}>
        Billing and Payment: Termination of a Driver&rsquo;s services does not
        absolve the customer of any outstanding fees or charges accrued up to
        the termination date. All outstanding balances must be settled as per
        the terms of our agreement.
      </p>
      <p style={pStyle}>
        Rehire Fee: Should you wish to rehire a driver after a period of 30 days
        or more, a new driver setup fee will be applicable. This fee covers the
        costs associated with completing the hiring process again as per FMCSA
        regulations.
      </p>
      <p style={pStyleCenter}>
        <u>Data Retention and Reactivation</u>
      </p>
      <p style={pStyle}>
        Retention of Activity History: Upon termination of services for a
        driver, Simplex Group may retain activity history data related to that
        driver. This includes any violations, inspections, or compliance-related
        issues during their tenure with the company.
      </p>
      <p style={pStyle}>
        Reactivation Charges: Should any violation or inspection activity be
        attributed to the company as a result of actions undertaken by the
        terminated driver, or if any compliance services are necessitated or
        provided due to the driver&rsquo;s post-termination activity, please
        note that Simplex Group reserves the right to reactivate the driver.
        Consequently, a Re-activation Fee will be applied to the subsequent
        Monthly Invoice.
      </p>
      <p style={pStyleCenter}>
        <u>Disclaimer</u>
      </p>
      <p style={pStyle}>
        Legal Compliance: This Disclosure is designed to ensure compliance with
        FMCSA regulations and other relevant laws governing driver management.
      </p>
      <p style={pStyle}>
        Reservation of Rights: Simplex Group reserves the right to modify,
        update, or interpret this Disclosure as necessary to ensure legal
        compliance and the smooth operation of our services.
      </p>
      <p style={pStyleCenter}>
        <u>Acknowledgment</u>
      </p>
      <p style={pStyle}>
        By proceeding with a driver termination request, you acknowledge that
        you have read, understood, and agreed to this Driver Termination and
        Rehire Disclosure. You further consent to abide by the terms outlined
        herein.
      </p>
    </Box>
  );
};

export default observer(DriverTerminationContract);
