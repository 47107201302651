import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCustomStore } from '../../hooks';
import { AdminUsersSearchFormInputs } from '../../types';
import { roleAssignListByUserType } from '../../utils/config';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import { AdminUsersSearchSchema } from '../../utils/ValidatorSchema';
import DialogMigrate from '../Dialog/DialogMigrate';
import { InputText } from '../Forms/InputText';
import { SelectInput } from '../Forms/selectInput';
import SortingIcon from '../SortingIcon/SortingIcon';
import search from './../../assets/images/h-search.svg';
import useStyles from './AdminDialogStyles';

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

const FallbackText = styled(Typography)((props) => ({
  color: '#DB2426',
  fontSize: 12,
  fontStyle: 'italic',
  fontWeight: 'bold',
  margin: '5px 0',
}));

const AdminDialog: React.FC<any> = (props: any) => {
  const { isOpen, closeDialog } = props;
  const headers = ['Full Name', 'Contact Type', 'Email Address'];
  const classes = useStyles();
  const { authStore, adminUsersStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const { AdminUsersShowLoader, AdminUsersList, AdminUsersHasMoreResults } =
    adminUsersStore;

  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [searchAdminUsers, setSearchAdminUsers] =
    useState<AdminUsersSearchFormInputs>({
      contactType: '',
      email: '',
      firstName: '',
      lastName: '',
    });
  const [selectList, setSelectList] = useState<Array<any>>([]);

  const sortBY = async (e: any) => {
    adminUsersStore.setAdminUsersApiReset();
    adminUsersStore.setSortBy(e);
    adminUsersStore.setSortDirection(
      adminUsersStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    adminUsersStore.setAdminUsersShowLoader(true);
    await adminUsersStore.fetchAdminUsers(searchAdminUsers);
    adminUsersStore.setAdminUsersShowLoader(false);
  };

  const {
    control,
    // handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<AdminUsersSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      contactType: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AdminUsersSearchSchema),
  });

  const getAllAdminUsers = useCallback(async () => {
    adminUsersStore.setAdminUsersShowLoader(true);
    const getAdminUsersResp = await adminUsersStore.fetchAdminUsers({
      contactType: '',
      email: '',
      firstName: '',
      lastName: '',
    });
    if (getAdminUsersResp.isErr()) {
      enqueueSnackbar(
        String(getAdminUsersResp.error.message),
        SnackBarConfig('e'),
      );
    }

    // const getAdminRolesResp = await adminUsersStore.fetchAdminRoles();
    // if (getAdminRolesResp.isErr()) {
    //   enqueueSnackbar(
    //     String(getAdminRolesResp.error.message),
    //     SnackBarConfig('e'),
    //   );
    // }
    adminUsersStore.setAdminUsersShowLoader(false);
  }, [adminUsersStore, enqueueSnackbar]);

  const getSearchBasedAdminUsers = useCallback(
    async (searchParams: AdminUsersSearchFormInputs) => {
      if (
        !searchParams.firstName &&
        !searchParams.lastName &&
        !searchParams.email &&
        !searchParams.contactType
      ) {
        adminUsersStore.setAdminUsersApiReset();
        getAllAdminUsers();
        return;
      }

      if (adminUsersStore.AdminList.length > 0) {
        setGetMoreLoader(true);
      } else {
        adminUsersStore.setAdminUsersShowLoader(true);
      }

      const getAdminUsersResp =
        await adminUsersStore.fetchAdminUsersBySearchBased(searchParams);
      if (getAdminUsersResp.isErr()) {
        enqueueSnackbar(
          String(getAdminUsersResp.error.message),
          SnackBarConfig('e'),
        );
      }

      if (adminUsersStore.AdminList.length > 0) {
        setGetMoreLoader(false);
      } else {
        adminUsersStore.setAdminUsersShowLoader(false);
      }
    },
    [adminUsersStore, enqueueSnackbar, getAllAdminUsers],
  );

  const getMoreAdminUsers = useCallback(async () => {
    if (
      searchAdminUsers.firstName ||
      searchAdminUsers.lastName ||
      searchAdminUsers.email ||
      searchAdminUsers.contactType
    ) {
      getSearchBasedAdminUsers(searchAdminUsers);
      return;
    }
    setGetMoreLoader(true);
    const getAdminUsersResp = await adminUsersStore.fetchAdminUsers(
      searchAdminUsers,
    );
    if (getAdminUsersResp.isErr()) {
      enqueueSnackbar(
        String(getAdminUsersResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [
    adminUsersStore,
    enqueueSnackbar,
    getSearchBasedAdminUsers,
    searchAdminUsers,
  ]);

  const adminUserSearchSubmit = useCallback(
    (data: AdminUsersSearchFormInputs) => {
      if (data.firstName || data.lastName || data.email || data.contactType) {
        adminUsersStore.setAdminUsersApiReset();
        setSearchAdminUsers((pre) => ({ ...pre, ...data }));
        getSearchBasedAdminUsers(data);
      }
    },
    [adminUsersStore, getSearchBasedAdminUsers],
  );

  const onCheck = (adminUser: any) => {
    let arr = selectList;
    const index = selectList.findIndex(
      (val: any) => val.contactId === adminUser.contactId,
    );
    if (index !== -1) {
      arr = arr.filter((val: any) => val.contactId !== adminUser.contactId);
    } else {
      arr.push({
        contactId: adminUser.contactId,
        email: adminUser.email,
        name: adminUser.fullName,
        role: '',
      });
    }
    setSelectList([...arr]);
  };

  const onSelect = (adminUser: any, role: string) => {
    const arr = selectList;

    const index = selectList.findIndex(
      (val: any) => val.contactId === adminUser.contactId,
    );
    if (index === -1) {
      arr.push({
        contactId: adminUser.contactId,
        email: adminUser.email,
        name: adminUser.fullName,
        role: role,
      });
    }

    selectList.map((val: any, index: any) => {
      if (val.contactId === adminUser.contactId) {
        arr[index].role = role;
      }
    });
    setSelectList([...arr]);
  };

  const closeDialogWindow = useCallback(() => {
    adminUsersStore.setAdminUsersApiReset();
    closeDialog && closeDialog();
  }, [adminUsersStore, closeDialog]);

  const onUserSubmit = useCallback(async () => {
    if (selectList.some((val) => val.role === '')) {
      return;
    }

    setBtnDisable(true);
    adminUsersStore.setAdminUsersShowLoader(true);
    const addAdminUsersResp = await adminUsersStore.addAdminUsers(selectList);
    if (addAdminUsersResp.isErr()) {
      enqueueSnackbar(
        String(addAdminUsersResp.error.message),
        SnackBarConfig('e'),
      );
    } else {
      adminUsersStore.setAdminUsersShowLoader(false);
      enqueueSnackbar(String(addAdminUsersResp.value), SnackBarConfig('s'));

      adminUsersStore.setShowLoader(true);
      adminUsersStore.setAdminApiReset(true);
      const getAdminsResp = await adminUsersStore.fetchAdmins();

      setBtnDisable(false);

      if (getAdminsResp.isErr()) {
        enqueueSnackbar(
          String(getAdminsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      adminUsersStore.setShowLoader(false);
      closeDialogWindow();
    }
    adminUsersStore.setAdminUsersShowLoader(false);
  }, [adminUsersStore, closeDialogWindow, enqueueSnackbar, selectList]);

  const clearForm = () => {
    reset();
    setSearchAdminUsers((pre) => ({
      ...pre,
      ...{
        contactType: '',
        email: '',
        firstName: '',
        lastName: '',
      },
    }));
    adminUsersStore.setAdminUsersApiReset();
    getAllAdminUsers();
  };

  const searchValue = () => {
    adminUserSearchSubmit(getValues());
  };

  useEffect(() => {
    setSelectList([]);
  }, []);

  useEffect(() => {
    getAllAdminUsers();
  }, [getAllAdminUsers]);

  return (
    <DialogMigrate
      aria-labelledby="admin-users-select"
      className={classes.dialog}
      maxWidth="lg"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={closeDialogWindow}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>Search & Select User(s)</div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialogWindow();
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>

      <form id={'admin-users-dialog'} className={classes.headerWrapper}>
        <Grid
          container
          spacing={1}
          ml={0}
          pl={'15px'}
          pr={'24px'}
          sx={{ paddingBottom: '9.5px', width: '100%' }}>
          <Grid item md={2} xs={2}>
            <FormControl fullWidth>
              <Controller
                control={control}
                defaultValue=""
                name="firstName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      inputRefObj={ref}
                      placeholder="First Name"
                      errors={errors.firstName?.message || ''}
                      isRefreshValue={true}
                      isEditable={false}
                      type={'text'}
                      variant="standard"
                      value={value}
                      initIcon={
                        <InputAdornment position="start">
                          <img className={classes.hIcon} src={search} />
                        </InputAdornment>
                      }
                      className={classes.selectClassName}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={2}>
            <FormControl fullWidth>
              <Controller
                control={control}
                defaultValue=""
                name="lastName"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      inputRefObj={ref}
                      placeholder="Last Name"
                      errors={errors.lastName?.message || ''}
                      isRefreshValue={true}
                      isEditable={false}
                      type={'text'}
                      variant="standard"
                      value={value}
                      initIcon={
                        <InputAdornment position="start">
                          <img className={classes.hIcon} src={search} />
                        </InputAdornment>
                      }
                      className={classes.selectClassName}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={2}>
            <FormControl>
              <Controller
                control={control}
                defaultValue=""
                name="contactType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      inputRefObj={ref}
                      placeholder="Contact Type"
                      isRefreshValue={true}
                      isEditable={false}
                      type={'text'}
                      variant="standard"
                      value={value}
                      initIcon={
                        <InputAdornment position="start">
                          <img className={classes.hIcon} src={search} />
                        </InputAdornment>
                      }
                      className={classes.selectClassName}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
              {/* <Grid className={classes.selectClassNameHeader}>
                <Grid container >
                    <Grid item md={2} className={searchClasses.dflexJA}>
                        <img className={classes.hIcon} src={search} alt="search" />
                    </Grid>
                    <Grid item md={10}>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="lastName"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectInput
                              name={'ContactType'}
                              value={''}
                              size={'medium'}
                              isEditable={false}
                              selectClassName={searchClasses.selectClassNameHeader}
                              placeHolder={'Select'}
                              defaultOptionLabel={'Contact Type'}
                              optionsList={
                                AdminUsersList.map((type) => type.contactType)
                                .filter(
                                  (value, index, current_value) => 
                                    current_value.indexOf(value) === index
                                    && value !== undefined
                                )
                                .map((type: any, index: number) => {
                                  return {
                                    label: type,
                                    optionClassName: classes.selectClassName,
                                    value: type
                                  };
                                })
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                </Grid>
              </Grid> */}
            </FormControl>
          </Grid>
          <Grid item md={4} xs={4}>
            <FormControl style={{ width: '100%' }}>
              <Controller
                control={control}
                defaultValue=""
                name="email"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      inputRefObj={ref}
                      placeholder="Email Address"
                      errors={errors.email?.message || ''}
                      isRefreshValue={true}
                      isEditable={false}
                      type={'text'}
                      variant="standard"
                      value={value}
                      initIcon={
                        <InputAdornment position="start">
                          <img className={classes.hIcon} src={search} />
                        </InputAdornment>
                      }
                      className={classes.selectClassName}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} xs={2}>
            <div className={classes.headerActionBtn}>
              <DarkColorButton
                type="button"
                onClick={() => searchValue()}
                variant="contained"
                sx={{
                  borderRadius: '4px',
                  padding: '0 10px !important',
                  width: '100%',
                }}>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontFamily: 'FiraSans-Medium',
                    fontSize: '0.9375rem',
                    fontWeight: 500,
                    height: '22px',
                    letterSpacing: '1px',
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  Search
                </Typography>
              </DarkColorButton>

              {searchAdminUsers.firstName ||
              searchAdminUsers.lastName ||
              searchAdminUsers.email ||
              searchAdminUsers.contactType ? (
                <Typography component={'span'} marginLeft={'12px'}>
                  <Button
                    variant={'text'}
                    className={'d-flex-ja '.concat(classes.cancelBtn)}
                    onClick={() => clearForm()}>
                    Clear
                  </Button>
                </Typography>
              ) : (
                ''
              )}
            </div>
          </Grid>
        </Grid>
      </form>

      <DialogContent id="scroll">
        <Box
          sx={{
            marginBottom: '15px',
          }}>
          <Grid container spacing={2}>
            <Divider className={classes.divider} />
            {headers.map((header: any, index: number) => (
              <Grid item xs={header === 'Contact Type' ? 2 : 4} key={index}>
                <Typography
                  component={'div'}
                  noWrap
                  style={{ alignItems: 'center', display: 'flex' }}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#A4A4A4',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '0.75rem',
                      height: '14px',
                      letterSpacing: 0,
                      lineHeight: '14px',
                      width: '89px',
                    }}>
                    {header}
                  </Typography>
                  <SortingIcon
                    {...{
                      sortBY,
                      sortByName: header,
                      store: adminUsersStore,
                    }}
                  />
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
        <InfiniteScroll
          dataLength={AdminUsersList.length}
          // height={windowHeight}
          style={{ overflow: 'unset' }}
          next={getMoreAdminUsers}
          hasMore={AdminUsersHasMoreResults}
          scrollableTarget={'scroll'}
          loader={
            getMoreLoader && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#DEC330', marginTop: 20 }}
                />
              </div>
            )
          }
          endMessage>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: '#FFFFFF',
              marginBottom: '10px',
            }}>
            {AdminUsersShowLoader ? (
              <div className={classes.loader}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : AdminUsersList.length > 0 ? (
              AdminUsersList.map((adminUser: any, index: number) => (
                <React.Fragment key={index}>
                  <Grid item xs={1}>
                    <Checkbox
                      className={classes.customCheck}
                      checked={selectList.some((val) =>
                        val.contactId === adminUser.contactId ? true : false,
                      )}
                      onChange={(event: any) => {
                        onCheck(adminUser);
                      }}
                      color="success"
                      tabIndex={0}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        letterSpacing: 0,
                        paddingTop: '10px !important',
                        textTransform: 'capitalize',
                      }}>
                      {adminUser.fullName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        letterSpacing: 0,
                        paddingTop: '10px !important',
                        textTransform: 'capitalize',
                      }}>
                      {adminUser.contactType || (
                        <FallbackText>missing</FallbackText>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.9375rem',
                        letterSpacing: 0,
                        paddingTop: '10px !important',
                        textTransform: 'capitalize',
                      }}>
                      {adminUser.email || <FallbackText>missing</FallbackText>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ paddingBottom: '10px !important' }}>
                    <SelectInput
                      name={'role'}
                      errors={
                        selectList.some(
                          (val) =>
                            val.contactId === adminUser.contactId &&
                            val.role === '',
                        )
                          ? 'Required'
                          : ''
                      }
                      isRefreshValue={true}
                      value={
                        selectList.find(
                          (val) => val.contactId === adminUser.contactId,
                        )?.role || ''
                      }
                      inputRefObj={null}
                      size={'medium'}
                      onChangeText={(e: any) => {
                        onSelect(adminUser, e);
                      }}
                      isEditable={!adminUser.email}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={roleAssignListByUserType
                        .find((ut: any) => ut.type === adminUser.userType)
                        ?.value.map((val: any, index: number) => {
                          return {
                            label: val,
                            optionClassName: classes.optionsClassName,
                            value: val,
                          };
                        })}
                    />
                  </Grid>
                  <Divider className={classes.divider} />
                </React.Fragment>
              ))
            ) : (
              <div className={classes.noRecordsFound}>No data available</div>
            )}
            <Divider className={classes.divider} />
          </Grid>
        </InfiniteScroll>
      </DialogContent>
      <DialogActions className={classes.jxy}>
        <BottomButton
          disabled={
            btnDisable ||
            (authStore.getTokenDetail && authStore.getTokenDetail?.adminId)
          }
          type={'button'}
          variant="contained"
          sx={{ width: '100%' }}
          {...(authStore.getTokenDetail && authStore.getTokenDetail?.adminId
            ? {}
            : { onClick: () => selectList.length && onUserSubmit() })}>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontFamily: 'FiraSans-Medium',
              fontSize: '0.9375rem',
              fontWeight: '500',
              height: '22px',
              letterSpacing: '1px',
              lineHeight: '22px',
              textAlign: 'center',
            }}>
            INVITE USERS
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(AdminDialog);
