import { PrivateRoutes } from '../../config/index';
import { DotTabsList } from '../../screens/private/ServiceRequest/components/NavTabs/DotTabsList';
import { NavTabsTPTS } from '../../screens/private/ServiceRequest/components/NavTabs/NavTabsConstent';
import { AccessType, AccessTypes, CustomAppPage } from '../../types';

const {
  ACCIDENTS,
  DASHBOARD,
  NOTIFICATIONS,
  TODOS,
  // TAXPERMITS,
  DRIVERS,
  FLEET,
  DOCUMENTS,
  REPORTS,
  SETTINGSROUTE,
  USERS,
  SERVICEREQUEST,
  DRIVERSERVICEREQUEST,
  INSPECTIONS_AND_VIOLATIONS,
  VIOLATIONS,
  TRAINING,
} = PrivateRoutes;

const {
  UCR,
  BASEROUTE,
  ANNUALREPORT,
  DOTUPDATE,
  FTQUARTERFILLING,
  IFTA,
  IRPSERVICE,
  ROADTAX,
  STATEPERMITS,
  MVR,
  PSP,
  CRIMINALBACKGROUND,
  DRUGTEST,
  NEWDRIVERSETUP,
  ADDTOFLEET,
  EQUIPMENTINSPECTION,
  DATAQCHALLENGE,
  TERMINATEDRIVER,
  TERMINATEEQUIPMENT,
} = SERVICEREQUEST;

const { PHYSICALEXAMLOCATION } = DRIVERSERVICEREQUEST;

const dotRoutes = (routeObj: any): string => {
  const startRoute =
    routeObj &&
    DotTabsList.find((e) => e.activeTabKey && routeObj[e.activeTabKey])?.route;

  return startRoute ? startRoute : '/';
};
const tptsRoute = (routeObj: any): string => {
  const startRoute =
    routeObj &&
    NavTabsTPTS.find((e) => e.activeTabKey && routeObj[e.activeTabKey])?.route;

  return startRoute ? startRoute : '/';
};
export const Pages = {
  AdminPages(navbarAccess: AccessType): CustomAppPage[] {
    if (navbarAccess) {
      return [
        {
          aicon: '/assets/images/a-dashboard.svg',
          caicon: '/assets/images/ca-dashboard.svg',
          haschildren: false,
          icon: '/assets/images/n-dashboard.svg',
          roles: [AccessTypes.CompanyAdmin, AccessTypes.OwnerOperator],
          route: DASHBOARD,
          show:
            navbarAccess.dashboard &&
            Object.values(navbarAccess.dashboard).includes(true),
          subRouteList: [
            `${DASHBOARD as string}/highlights`,
            `${DASHBOARD as string}/trends`,
          ],
          title: 'Dashboard',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-dashboard.svg',
          caicon: '/assets/images/ca-dashboard.svg',
          haschildren: false,
          icon: '/assets/images/n-dashboard.svg',
          roles: [AccessTypes.Driver],
          route: DASHBOARD,
          show:
            navbarAccess.dashboard &&
            Object.values(navbarAccess.dashboard).includes(true),
          subRouteList: [],
          title: 'Dashboard',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-notification.svg',
          caicon: '/assets/images/ca-notification.svg',
          haschildren: false,
          icon: '/assets/images/n-notification.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: NOTIFICATIONS,
          show:
            navbarAccess.notification &&
            Object.values(navbarAccess.notification).includes(true),
          title: 'Notifications',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-todo.svg',
          caicon: '/assets/images/ca-todo.svg',
          haschildren: false,
          icon: '/assets/images/n-todo.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: TODOS,
          show: true,
          title: 'To-Dos',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-serviceRequest.svg',
          caicon: '/assets/images/ca-service-request.svg',
          childrens: [
            {
              icon: '/assets/images/n-serviceRequest.svg',
              route: tptsRoute(navbarAccess.tpts),
              show:
                navbarAccess.tpts &&
                Object.values(navbarAccess.tpts).includes(true),
              subRouteList: [
                UCR,
                ROADTAX,
                ANNUALREPORT,
                DOTUPDATE,
                FTQUARTERFILLING,
                IFTA,
                IRPSERVICE,
                STATEPERMITS,
              ],
              title: 'Taxes & Permits',
              whiteIcon: '',
            },
            {
              icon: '/assets/images/n-serviceRequest.svg',
              route: dotRoutes(navbarAccess.dot),
              show:
                navbarAccess.dot &&
                Object.values(navbarAccess.dot).includes(true),
              subRouteList: [
                PHYSICALEXAMLOCATION,
                MVR,
                PSP,
                CRIMINALBACKGROUND,
                DRUGTEST,
                NEWDRIVERSETUP,
                ADDTOFLEET,
                EQUIPMENTINSPECTION,
                DATAQCHALLENGE,
                TERMINATEDRIVER,
                TERMINATEEQUIPMENT,
              ],
              title: 'Safety Compliance',
              whiteIcon: '',
            },
          ],
          haschildren: true,
          icon: '/assets/images/n-serviceRequest.svg',
          roles: [AccessTypes.CompanyAdmin, AccessTypes.OwnerOperator],
          route: BASEROUTE,
          routename: 'servicerequest',
          show:
            (navbarAccess.tpts &&
              Object.values(navbarAccess.tpts).includes(true)) ||
            (navbarAccess.dot &&
              Object.values(navbarAccess.dot).includes(true)),
          title: 'Service Request',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-serviceRequest.svg',
          caicon: '/assets/images/ca-service-request.svg',
          haschildren: false,
          icon: '/assets/images/n-serviceRequest.svg',
          roles: [AccessTypes.Driver],
          route: PHYSICALEXAMLOCATION,
          show:
            navbarAccess.dot && Object.values(navbarAccess.dot).includes(true),
          subRouteList: [PHYSICALEXAMLOCATION],
          title: 'Service Request',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-driver.svg',
          caicon: '/assets/images/ca-driver.svg',
          haschildren: false,
          icon: '/assets/images/n-driver.svg',
          roles: [AccessTypes.CompanyAdmin],
          route: DRIVERS,
          show: navbarAccess.drivers,
          title: 'Drivers',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-fleet.svg',
          caicon: '/assets/images/ca-fleet.svg',
          haschildren: false,
          icon: '/assets/images/n-fleet.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: FLEET,
          show: navbarAccess.fleet,
          title: 'Fleet',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-document.svg',
          caicon: '/assets/images/ca-document.svg',
          haschildren: false,
          icon: '/assets/images/n-document.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: DOCUMENTS,
          show: navbarAccess.documents,
          title: 'Documents',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/n-fleet.svg',
          caicon: '/assets/images/ca-fleet.svg',
          haschildren: false,
          icon: '/assets/images/n-document.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: INSPECTIONS_AND_VIOLATIONS,
          show: navbarAccess.inspectionsAndViolations,
          title: 'Inspections & Violations',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/n-fleet.svg',
          caicon: '/assets/images/ca-fleet.svg',
          haschildren: false,
          icon: '/assets/images/n-document.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: VIOLATIONS,
          show: navbarAccess.violations,
          title: 'Violations',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/n-fleet.svg',
          caicon: '/assets/images/ca-fleet.svg',
          haschildren: false,
          icon: '/assets/images/n-document.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: ACCIDENTS,
          show: navbarAccess.accidents,
          title: 'Accidents',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/n-fleet.svg',
          caicon: '/assets/images/ca-fleet.svg',
          haschildren: false,
          icon: '/assets/images/n-document.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: TRAINING.BASE_ROUTE,
          show: navbarAccess.training,
          subRouteList: [
            `${TRAINING.ASSIGNED as string}`,
            `${TRAINING.RECOMMENDED.INSPECTIONS as string}`,
            `${TRAINING.RECOMMENDED.ACCIDENTS as string}`,
            `${TRAINING.COMPLETED as string}`,
          ],
          title: 'Training',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-report.svg',
          caicon: '/assets/images/a-report.svg',
          haschildren: false,
          icon: '/assets/images/n-report.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: REPORTS,
          show: navbarAccess.reports,
          title: 'Reports',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-setting.svg',
          caicon: '/assets/images/ca-setting.svg',
          haschildren: false,
          icon: '/assets/images/n-setting.svg',
          roles: [
            AccessTypes.CompanyAdmin,
            AccessTypes.OwnerOperator,
            AccessTypes.Driver,
          ],
          route: SETTINGSROUTE.SETTINGS,
          show: true,
          title: 'Settings',
          whiteIcon: '',
        },
        {
          aicon: '/assets/images/a-admin.svg',
          caicon: '/assets/images/ca-admin.svg',
          haschildren: false,
          icon: '/assets/images/n-admin.svg',
          roles: [AccessTypes.CompanyAdmin],
          route: USERS,
          show: true,
          title: 'Users',
          whiteIcon: '',
        },
      ];
    } else {
      return [];
    }
  },
  // DriverRoutes(): CustomAppPage[] {
  //   return [
  //     {
  //       aicon: '/assets/images/a-dashboard.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-dashboard.svg',
  //       route: DASHBOARD,
  //       title: 'Dashboard',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-notification.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-notification.svg',
  //       route: NOTIFICATIONS,
  //       title: 'Notifications',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-todo.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-todo.svg',
  //       route: TODOS,
  //       title: 'To-Dos',
  //       whiteIcon: '',
  //     },
  //     // {
  //     //   aicon: '/assets/images/a-serviceRequest.svg',
  //     //   haschildren: false,
  //     //   icon: '/assets/images/n-serviceRequest.svg',
  //     //   route: BASEROUTE,
  //     //   title: 'Service Request',
  //     //   whiteIcon: '',
  //     // },
  //     {
  //       aicon: '/assets/images/a-serviceRequest.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-serviceRequest.svg',
  //       route: PHYSICALEXAMLOCATION,
  //       subRouteList: [PHYSICALEXAMLOCATION],
  //       title: 'Service Request',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-fleet.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-fleet.svg',
  //       route: FLEET,
  //       title: 'Fleet',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-document.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-document.svg',
  //       route: DOCUMENTS,
  //       title: 'Documents',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-report.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-report.svg',
  //       route: REPORTS,
  //       title: 'Reports',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-setting.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-setting.svg',
  //       route: SETTINGSROUTE.SETTINGS,
  //       title: 'Settings',
  //       whiteIcon: '',
  //     },
  //   ];
  // },
  // OwnerOperator(navbarAccess: AccessType): CustomAppPage[] {
  //   return [
  //     {
  //       aicon: '/assets/images/a-dashboard.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-dashboard.svg',
  //       route: DASHBOARD,
  //       title: 'Dashboard',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-notification.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-notification.svg',
  //       route: NOTIFICATIONS,
  //       title: 'Notifications',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-todo.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-todo.svg',
  //       route: TODOS,
  //       title: 'To-Dos',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-serviceRequest.svg',
  //       childrens: [
  //         {
  //           icon: '/assets/images/n-dashboard.svg',
  //           route: UCR,
  //           subRouteList: [
  //             UCR,
  //             ROADTAX,
  //             ANNUALREPORT,
  //             DOTUPDATE,
  //             FTQUARTERFILLING,
  //             IFTA,
  //             IRPSERVICE,
  //             STATEPERMITS,
  //           ],
  //           title: 'Taxes & Permits',
  //           whiteIcon: '',
  //         },
  //         {
  //           icon: '/assets/images/n-dashboard.svg',
  //           route: PHYSICALEXAMLOCATION,
  //           subRouteList: [
  //             PHYSICALEXAMLOCATION,
  //             ADDTOFLEET,
  //             EQUIPMENTINSPECTION,
  //             TERMINATEEQUIPMENT,
  //           ],
  //           title: 'DOT',
  //           whiteIcon: '',
  //         },
  //       ],
  //       haschildren: true,
  //       icon: '/assets/images/n-serviceRequest.svg',
  //       route: BASEROUTE,
  //       routename: 'servicerequest',
  //       title: 'Service Request',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-fleet.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-fleet.svg',
  //       route: FLEET,
  //       title: 'Fleet',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-document.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-document.svg',
  //       route: DOCUMENTS,
  //       title: 'Documents',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-report.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-report.svg',
  //       route: REPORTS,
  //       title: 'Reports',
  //       whiteIcon: '',
  //     },
  //     {
  //       aicon: '/assets/images/a-setting.svg',
  //       haschildren: false,
  //       icon: '/assets/images/n-setting.svg',
  //       route: SETTINGSROUTE.SETTINGS,
  //       title: 'Settings',
  //       whiteIcon: '',
  //     },
  //   ];
  // },
};
