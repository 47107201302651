import { Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useCustomStore } from '../../../../hooks';
import DateRange from './DateRange';
import SelectStates from './SelectStates';
import TextInput from './TextInput';
import useTrendDetailsForm from './useTrendDetailsForm';

const getFromControlUI: Record<string, (props: any) => JSX.Element> = {
  date: (props: any) => <DateRange {...props} />,
  stateCode: (props: any) => <SelectStates {...props} />,
  text: (props: any) => <TextInput {...props} />,
};

const processParams = (obj: Record<string, any>) => {
  const tempObj: Record<string, any> = {};
  for (const key in obj) {
    if (obj[key]) {
      if (key.includes('Date')) {
        tempObj[key] = moment(obj[key]).format('MM/DD/YYYY');
      } else if (key === 'primaryDriverName') {
        tempObj.driverName = obj[key];
      } else {
        tempObj[key] = obj[key];
      }
    }
  }
  return tempObj;
};

type Props = {
  classes: any;
  onClose: () => unknown;
};

const TrendDetailsSearchForm: React.FC<Props> = ({ classes, onClose }) => {
  const location = useLocation();
  const { trendsStore } = useCustomStore();

  const fromTrendsByDriver = location.search.includes('driver=');
  const fromTrendsByUnit = location.search.includes('vin=');

  const trendType = location.pathname.split('-')[0];

  const { formControls, control, formValue, reset, isFormEmpty } =
    useTrendDetailsForm(fromTrendsByDriver, fromTrendsByUnit, trendType);

  const sendRequests = () => {
    (async () => {
      if (fromTrendsByDriver) {
        await trendsStore.getDriverDataX();
      }
      if (fromTrendsByUnit) {
        await trendsStore.getEquipmentDataX();
      }

      if (!fromTrendsByDriver && !fromTrendsByUnit) {
        await trendsStore.getTop10TrendingDataX();
      }
      trendsStore.setShowLoader(false);
      onClose();
    })();
  };

  const handleFormSubmit = () => {
    trendsStore.resetParams();
    trendsStore.resetGridData();
    trendsStore.resetSortParams();
    trendsStore.setSearchParams(processParams(formValue));
    trendsStore.setShowLoader(true);
    sendRequests();
  };

  const handleReset = () => {
    trendsStore.resetParams();
    trendsStore.resetGridData();
    trendsStore.resetSortParams();
    reset();
    trendsStore.setShowLoader(true);
    sendRequests();
    onClose();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      {formControls.map(
        (formControl) =>
          formControl?.type &&
          getFromControlUI[formControl.type]({
            classes,
            control,
            ...formControl,
          }),
      )}
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              tabIndex={0}
              type="button"
              onClick={() => handleReset()}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            disabled={isFormEmpty}
            className={classes.filterBtn}
            style={{
              backgroundColor: isFormEmpty ? '#d3d3d3' : '#DEC330',
            }}
            onClick={() => handleFormSubmit()}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default TrendDetailsSearchForm;
