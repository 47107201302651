import { makeAutoObservable } from 'mobx';

import { API_LIMIT } from '../config';
import { DriverSearchFilters, DriverSearchFormInputs } from '../types';
import { RootStore } from '.';

export class DriverStore {
  private _limit = API_LIMIT;
  private _status = 'Active';
  private _driverList: Array<any> = [];
  private _count: number | undefined = 0;
  private _showLoader = false;
  private _hasMoreResults = false;
  private _nextLink = '';
  private _equipmentFromDriver: any = null;
  private _filter: DriverSearchFilters = {
    driverType: '',
    limit: API_LIMIT,
    name: '',
    nextLink: this.NextLink,
    simplexId: '',
  };
  private _sortBy = 'name';
  private _sortDirection = 'asc';

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setFilters(data: DriverSearchFilters) {
    this._filter = data;
  }

  setDriverList(data: Array<any>) {
    this._driverList = data;
  }

  setCount(count: number | undefined) {
    this._count = count;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setStatus(value: string) {
    this._status = value;
  }

  setDriverApiReset(hasMoreResult = false) {
    this.setNextLink('');
    this.setHasMoreResults(hasMoreResult);
    this.setDriverList([]);
    this.setCount(0);
    // this.setFilters({
    //   ...this.Filters,
    //   driverType: '',
    //   limit: API_LIMIT,
    //   name: '',
    //   nextLink: this.NextLink,
    //   simplexId: '',
    // });
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  get Count() {
    return this._count;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get Filters() {
    return this._filter;
  }

  get DriverList() {
    return this._driverList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Status() {
    return this._status;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get EquipmentFromDriver() {
    return this._equipmentFromDriver;
  }

  setEquipmentFromDriver(data: any = null) {
    this._equipmentFromDriver = data;
  }

  async fetchDriversBySorting() {
    this.setDriverApiReset(false);
    return await this.fetchDrivers();
  }

  async fetchDrivers() {
    const params: any = {};
    this.Filters.name != '' ? (params['name'] = this.Filters.name) : null;
    this.Filters.driverType != ''
      ? (params['driverType'] = this.Filters.driverType)
      : null;
    this.Filters.simplexId != ''
      ? (params['simplexId'] = this.Filters.simplexId)
      : null;

    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['status'] = this._status;
    params['sortBy'] = this.SortBy;
    params['sortDirection'] = this.SortDirection;

    const response = await this.rootStore.apiStore.driverApi.getDriverList(
      params,
    );

    if (response.isOk()) {
      const { response: results, nextLink, count } = response.value;
      const fullResult = this._driverList.concat(results);

      this.setDriverList(fullResult);
      this.setCount(count);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchDriversBySearchBased(searchVal: DriverSearchFormInputs) {
    this.setDriverApiReset(true);
    const params: {
      limit: number;
      nextLink: string;
      status: string;
      name: string;
      driverType: string;
      simplexId: string;
      sortBy: string;
      sortDirection: string;
    } = {
      limit: this._limit,
      nextLink: '',
      status: this._status,
      ...searchVal,
      sortBy: this.SortBy,
      sortDirection: this.SortDirection,
    };

    const response = await this.rootStore.apiStore.driverApi.getDriversBySearch(
      params,
    );
    if (response.isOk()) {
      const { response: results, count, nextLink } = response.value;
      this.setDriverList(results);
      this.setCount(count);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async exportDrivers(searchVal: DriverSearchFormInputs) {
    const params: {
      nextLink: string;
      status: string;
      name: string;
      driverType: string;
      simplexId: string;
      sortBy: string;
      sortDirection: string;
    } = {
      nextLink: '',
      status: this._status,
      ...searchVal,
      sortBy: this.SortBy || 'name',
      sortDirection: this.SortDirection,
    };

    const response: any = await this.rootStore.apiStore.driverApi.exportDrivers(
      params,
    );

    return response;
  }

  submitDriver = async (data: any) =>
    await this.rootStore.apiStore.driverApi.submitRequest(data);
  updateDriver = async (data: any, id: string) =>
    await this.rootStore.apiStore.driverApi.updateRequest(data, id);
  updateMedical = async (data: any, id: string) =>
    await this.rootStore.apiStore.driverApi.updateMedicalRequest(data, id);
}
