import React from 'react';

import { useCustomStore } from '../../../hooks';
import ComingSoon from '../ComingSoon';
import AdminNotifications from './Components/AdminNotification/AdminNotifications';

const Notifications: React.FC = () => {
  const { authStore }: any = useCustomStore();

  if (authStore.tokens) {
    return <AdminNotifications />;
  }

  return <ComingSoon />;
};

export default Notifications;
