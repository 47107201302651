import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';

import { useCustomStore } from '../../hooks';

type FormInputSelectProps = {
  errors?: string;
  defaultValue?: string;
  value: string;
  tabIndexVal?: number;
  onChangeText?: (src: string) => void;
  isEditable?: boolean;
  name: string;
  isRefreshValue?: boolean;
  isFormSubmittedOnce?: boolean;
  inputRefObj?: React.Ref<any>;
  selectClassName?: string;
  iconsClassName?: string;
  placeHolder?: string;
  size?: 'small' | 'medium';
  keyName?: 'id' | 'code';
};

export const SelectStateInput = (props: FormInputSelectProps): ReactElement => {
  const {
    errors,
    defaultValue = '',
    value,
    tabIndexVal = 0,
    onChangeText,
    isEditable = false,
    name,
    isRefreshValue = false,
    isFormSubmittedOnce,
    inputRefObj,
    selectClassName,
    iconsClassName,
    placeHolder,
    size = 'medium',
    keyName = 'code',
  } = props;

  const classes = useStyles();
  const { authStore } = useCustomStore();
  const { statesListOption } = authStore;
  const [inputVal, setInputVal] = React.useState(value);
  const [showLocalError, setShowLocalError] = React.useState(true);

  const onValueChange = React.useCallback(
    (val: string) => {
      setInputVal(val);
      setShowLocalError(!val);
      onChangeText && onChangeText(val);
    },
    [onChangeText],
  );

  const onBlur = () => {
    setShowLocalError(true);
  };

  React.useEffect(() => {
    if (isRefreshValue && inputVal !== value) {
      setInputVal(value);
      setShowLocalError(!value);
    }
  }, [inputVal, value, isRefreshValue]);

  const showError = isFormSubmittedOnce || showLocalError;

  return (
    <>
      <Select
        defaultValue={defaultValue}
        value={inputVal || defaultValue}
        size={size ? size : undefined}
        placeholder={placeHolder ? placeHolder : 'Select'}
        name={name}
        classes={iconsClassName ? { icon: iconsClassName } : {}}
        className={selectClassName}
        inputProps={{
          tabIndex: tabIndexVal,
        }}
        inputRef={inputRefObj}
        disabled={isEditable}
        error={showError ? (errors ? true : false) : false}
        fullWidth
        onBlur={() => {
          onBlur();
        }}
        onChange={(event: any) => {
          onValueChange(event.target.value);
        }}
        native>
        {
          <>
            <option value={''} disabled className={classes.optionsClassName}>
              Select
            </option>
            {statesListOption &&
              statesListOption.map((ele, index) => (
                <option
                  key={index}
                  value={keyName ? ele[keyName] : ele.code}
                  className={classes.optionsClassName}>
                  {ele.fullName}
                </option>
              ))}
          </>
        }
      </Select>
      {errors && (
        <FormHelperText className={classes.helperText}>{errors}</FormHelperText>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    helperText: {
      color: `${theme.palette.warning.main} !important`,
      marginLeft: 'unset !important',
      position: 'relative',
    },
    optionsClassName: {
      backgroundColor: theme.palette.common.white + '!important',
      paddingLeft: '10px !important',
      paddingRight: '27px !important',
      textTransform: 'capitalize',
    },
  }),
);
