import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
// import jwt_decode from 'jwt-decode';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import logoNav2 from '../../../assets/images/logo-nav2.svg';
import { InputText } from '../../../components/Forms/InputText';
import { SelectInput } from '../../../components/Forms/selectInput';
import Loader from '../../../components/Loader/Loader';
import { PublicRoute } from '../../../config/Constants';
import { useCustomStore } from '../../../hooks';
// import { GetContactsDTO } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { forgotPasswordSchema } from '../../../utils/ValidatorSchema';
import useStyles from '../PublicStyles';

const ForgotPassword: React.FC = (props) => {
  const classes = useStyles();
  const { apiStore, authStore } = useCustomStore();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const forgotPasswordForm = useForm<{ contactId: string; email: string }>({
    defaultValues: {
      email: authStore.GetEmail,
    },
    resolver: yupResolver(forgotPasswordSchema),
  });

  // const redirectToResetPassword = useCallback(
  //   (tokenData: any, token: any) => {
  //     navigate(PublicRoute.RESETPASSWORD, {
  //       state: {
  //         contactData: tokenData,
  //         token,
  //       },
  //     });
  //   },
  //   [navigate],
  // );

  useEffect(() => {
    if (!authStore.Contacts.length || !authStore.GetEmail)
      navigate(PublicRoute.LOGIN);
  }, [authStore.Contacts, authStore.GetEmail, navigate]);

  const submitForgotPassword = useCallback(async () => {
    setLoading(true);
    const { contactId, email } = forgotPasswordForm.getValues();
    const forgotPasswordResp = await apiStore.userApi.forgotPassword({
      contactId,
      email,
    });

    if (forgotPasswordResp.isOk()) {
      enqueueSnackbar(forgotPasswordResp.value, SnackBarConfig('s'));
      // const decodedResetToken = jwt_decode(forgotPasswordResp.value);
      // redirectToResetPassword(decodedResetToken, forgotPasswordResp.value);
      navigate(PublicRoute.LOGIN);
    } else {
      enqueueSnackbar(
        String(forgotPasswordResp.error.message),
        SnackBarConfig('e'),
      );
      setLoading(false);
    }
  }, [apiStore.userApi, enqueueSnackbar, forgotPasswordForm, navigate]);

  return (
    <Grid
      container
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      className={classes.sliderHome}>
      <Grid item margin={'auto'}>
        <div className={classes.rectangle}>
          <img src={logoNav2} className={classes.logoNav2} />
          <form
            style={{
              width: '100%',
            }}
            id="forgot-password"
            onSubmit={forgotPasswordForm.handleSubmit(submitForgotPassword)}>
            <Grid
              sx={{
                marginTop: '31px',
              }}>
              <Grid container>
                <Grid
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  item
                  md={12}
                  sm={12}
                  xs={12}>
                  <FormControl fullWidth>
                    <Box className={classes.labels}>Selected Company</Box>

                    <Controller
                      control={forgotPasswordForm.control}
                      defaultValue=""
                      name="contactId"
                      render={({ field: { onChange, value, name, ref } }) => (
                        <SelectInput
                          errors={
                            forgotPasswordForm?.formState?.errors?.contactId
                              ?.message
                          }
                          inputRefObj={ref}
                          name={name}
                          onChangeText={onChange}
                          value={value}
                          selectClassName={classes.selectInputStyles}
                          optionsList={authStore.Contacts.map((contact) => ({
                            label: contact.accountName,
                            value: contact.contactId,
                          }))}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  item
                  md={12}
                  sm={12}
                  xs={12}>
                  <FormControl fullWidth>
                    <Box className={classes.labels}>Email</Box>

                    <Controller
                      control={forgotPasswordForm.control}
                      defaultValue=""
                      name="email"
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputText
                          inputRefObj={ref}
                          onChangeText={onChange}
                          name={name}
                          value={value}
                          isEditable={true}
                          className={classes.inputStyles}
                          type="email"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Button
              disabled={loading}
              tabIndex={0}
              type="submit"
              form={'forgot-password'}
              variant="contained"
              fullWidth
              className={classes.buttonStyles}>
              {loading ? (
                <Loader
                  className={'flex-centering mt-1em mb-1em'}
                  style={{ color: '#DEC330', marginBottom: 16, marginTop: 16 }}
                />
              ) : (
                'Send Password Reset'
              )}
            </Button>
            <Link to="/login" tabIndex={0} className={classes.backtoLogin}>
              Back to Login
            </Link>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(ForgotPassword);
