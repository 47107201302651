import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import React, { KeyboardEvent, ReactElement } from 'react';

type FormInputTextProps = {
  errors?: string;
  defaultValue?: string;
  value?: string;
  tabIndexVal?: number;
  onChangeText?: (src: string, selectionStart: number) => void;
  onBlurText?: () => void;
  onClick?: () => unknown;
  isSecureTextEntry?: boolean;
  isEditable?: boolean;
  name: string;
  isRefreshValue?: boolean;
  isFormSubmittedOnce?: boolean;
  type: string;
  isTextArea?: boolean;
  inputRefObj?: React.Ref<any>;
  maxLengthVal?: string;
  placeholder?: string;
  className?: string;
  label?: string;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  initIcon?: any;
  autoComplete?: string;
  isDisableTransparent?: boolean;
};

export const InputText = observer((props: FormInputTextProps): ReactElement => {
  const {
    errors,
    defaultValue,
    value,
    tabIndexVal = 0,
    onChangeText,
    onBlurText,
    onClick,
    isSecureTextEntry = false,
    isEditable = true,
    name,
    isRefreshValue = false,
    isFormSubmittedOnce,
    type,
    isTextArea = false,
    inputRefObj,
    maxLengthVal = '',
    placeholder = '',
    className = '',
    label = '',
    variant = 'outlined',
    initIcon,
    autoComplete = 'on',
    isDisableTransparent = false,
  } = props;
  const [inputVal, setInputVal] = React.useState(value);
  const [showLocalError, setShowLocalError] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const maxLenVal = maxLengthVal || '';

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onValueChange = React.useCallback(
    (val: string, selectionStart: number) => {
      setInputVal(val);
      setShowLocalError(!val);
      onChangeText && onChangeText(val, selectionStart);
    },
    [onChangeText],
  );

  const onBlurChange = React.useCallback(() => {
    setShowLocalError(true);
    onBlurText && onBlurText();
  }, [onBlurText]);

  React.useEffect(() => {
    if (isRefreshValue && inputVal !== value) {
      setInputVal(value);
      setShowLocalError(!value);
    }
  }, [inputVal, value, isRefreshValue]);

  const showError = isFormSubmittedOnce || showLocalError || !!errors;

  const additionalInputProps = isSecureTextEntry && (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        edge="end"
        onClick={handleClickShowPassword}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      value={inputVal}
      InputProps={
        initIcon
          ? { startAdornment: initIcon }
          : { endAdornment: additionalInputProps }
      }
      className={className}
      defaultValue={defaultValue}
      disabled={isEditable || isDisableTransparent}
      error={showError ? (errors ? true : false) : false}
      helperText={showError ? errors || '' : ''}
      inputProps={{
        maxLength: maxLenVal,
        tabIndex: tabIndexVal,
      }}
      inputRef={inputRefObj}
      margin="none"
      multiline={isTextArea ? true : false}
      name={name}
      autoComplete={autoComplete}
      label={label}
      required={false}
      minRows={isTextArea ? 2 : 1}
      maxRows={isTextArea ? 2 : 1}
      type={isSecureTextEntry ? (showPassword ? 'text' : 'password') : type}
      variant={variant}
      placeholder={placeholder}
      fullWidth
      onBlur={() => {
        onBlurChange();
      }}
      onChange={(event: any) => {
        onValueChange(event.target.value, event.target.selectionStart);
      }}
      onKeyDown={(e: KeyboardEvent) => {
        e.stopPropagation();
      }}
      onClick={onClick}
    />
  );
});
