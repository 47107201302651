import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import CreatePassword from '../../screens/public/CreatePassword/CreatePassword';
import ForgotPassword from '../../screens/public/ForgotPassword/ForgotPassword';
import Login from '../../screens/public/Login/Login';
import LoginAsImpersonate from '../../screens/public/LoginAsImpersonate/LoginAsImpersonate';
import ResetPassword from '../../screens/public/ResetPassword/ResetPassword';
import { PublicRoute as PublicRoutesDirectives } from '../Constants';

const PublicRoute: React.FC = () => {
  const {
    LOGIN,
    FORGOTPASSWORDWITHID,
    FORGOTPASSWORD,
    RESETPASSWORD,
    LOGINASIMPERSONATE,
    CREATEPASSWORD,
  } = PublicRoutesDirectives;
  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={FORGOTPASSWORDWITHID} element={<ForgotPassword />} />
      <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={RESETPASSWORD} element={<ResetPassword />} />
      <Route path={CREATEPASSWORD} element={<CreatePassword />} />
      <Route path={LOGINASIMPERSONATE} element={<LoginAsImpersonate />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default PublicRoute;
