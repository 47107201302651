import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore } from '../../hooks';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import { SelectDatePicker } from '../Forms/datePicker';
import useStyles from './TrainingDueDateDialogStyles';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

type TrainingDueDateDialogProps = {
  isOpen: boolean;
  assignment: any;
  closeDialog: (reload: boolean) => void;
};

const TrainingDueDateDialog: React.FC<TrainingDueDateDialogProps> = (
  props: TrainingDueDateDialogProps,
) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { trainingStore } = useCustomStore();
  const { isOpen, closeDialog, assignment } = props;

  const [loader, showLoader] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    dueDate: string;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      dueDate: assignment?.dueDate || '',
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const onFormSubmit = async (values: any) => {
    if (!values.dueDate) {
      setError('dueDate', { message: 'Required' });
      return;
    }
    showLoader(true);
    const resp = await trainingStore.changeTrainingAssignmentDueDate({
      assignmentId: assignment.id,
      dueDate: moment(values.dueDate).format('MM/DD/YYYY'),
    });
    if (resp.isErr()) {
      enqueueSnackbar('Unable to update Due Date', SnackBarConfig('e'));
    }
    if (resp.isOk()) {
      enqueueSnackbar('Training Due Date updated', SnackBarConfig('s'));
    }
    showLoader(false);
    closeDialog(true);
  };

  return (
    <DialogMigrate
      aria-labelledby="assign-due-date-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onClose={() => closeDialog(false)}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            UPDATE TRAINING DUE DATE
          </div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialog(false);
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <form id="assign-due-date" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Due Date *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="dueDate"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      isFormSubmittedOnce={!!formState.submitCount}
                      errors={errors?.dueDate?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeDate={(e): any => {
                        onChange(e);
                      }}
                      disabled={loader}
                      minDate={new Date()}
                      maxDate={
                        assignment?.isSystemReason && assignment?.assignedDate
                          ? new Date(
                              moment(assignment.assignedDate)
                                .add(14, 'days')
                                .format('MM/DD/YYYY'),
                            )
                          : undefined
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <BottomButton
          type={'submit'}
          disabled={loader}
          form="assign-due-date"
          variant="contained"
          sx={{ width: '100%' }}>
          <Typography noWrap className={classes.submitBtnTxt}>
            {loader ? (
              <CircularProgress size={20} sx={{ color: '#DEC330' }} />
            ) : (
              'UPDATE DUE DATE'
            )}
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(TrainingDueDateDialog);
