import TabList from '@mui/lab/TabList';
import { Divider } from '@mui/material';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomStore } from '../../../../../hooks';
import { AccessTypes, customNavTabs } from '../../../../../types';
import { DotTabsList } from './DotTabsList';
import { Img, useStyles } from './NavTabsStyles';

type DOTPropsTypes = {
  setValue: any;
  value: string;
};
const DOTNavBarList: React.FC<DOTPropsTypes> = (props: DOTPropsTypes) => {
  const { authStore, globalStore, userStore }: any = useCustomStore();
  const classes = useStyles();
  const useNavigator = useNavigate();
  const { setValue, value } = props;
  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
    NavigationRoute(newValue);
  };

  let navTabs: customNavTabs[] = React.useMemo(() => [], []);

  if (authStore.tokens) {
    // if (!authStore.tokens) {
    const role = authStore.getTokenDetail?.accessType || '';
    if (role === AccessTypes.CompanyAdmin) {
      navTabs = DotTabsList;
    } else if (role === AccessTypes.OwnerOperator) {
      navTabs = DotTabsList;
    }
  }

  const NavigationRoute = (tabValue: string) => {
    let navigationRoute: string | undefined | any = '/';
    navigationRoute = navTabs
      .filter((tabs) => tabs.tab)
      .find((tab) => tab.index === tabValue)?.route;
    globalStore.setCurrentRoute(navigationRoute);
    useNavigator(navigationRoute);
  };
  const shouldDisable = (isDisabled: boolean, tabValue?: string) => {
    if (!isDisabled) return false;
    return tabValue === 'newDriverSetup' && !userStore.isTestAccount()
      ? isDisabled
      : false;
  };
  return (
    <>
      <TabList
        onChange={handleChange}
        aria-label="lab API tabs example"
        textColor="secondary"
        className={classes.tabs}
        variant="scrollable"
        scrollButtons={'auto'}
        allowScrollButtonsMobile={true}>
        {navTabs
          .filter((tabs) => tabs.tab)
          .filter(
            (tabs) =>
              tabs.activeTabKey &&
              authStore.NavbarAccess.dot &&
              authStore.NavbarAccess.dot[tabs.activeTabKey],
          )
          .map((tab, index) => (
            <Tab
              key={index}
              disabled={shouldDisable(tab.isDisabled, tab.activeTabKey)}
              icon={
                <div
                  className={`${classes.tabsImage} ${
                    value === tab.index ? classes.tabImageHighlight : ''
                  }`}>
                  <Img
                    alt={tab.title}
                    src={value === tab.index ? tab.activeIcon : tab.icon}
                  />
                </div>
              }
              label={
                <div className={classes.tabsHeader} style={{ height: '50px' }}>
                  {tab.title}
                </div>
              }
              value={tab.index}
            />
          ))}
      </TabList>
      <Divider />
    </>
  );
};

export default DOTNavBarList;
