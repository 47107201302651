import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import { useCustomStore } from '../../hooks';
import { InputText } from '../Forms/InputText';

const InfiniteSelect = styled('div')(() => ({
  position: 'relative',
}));

const Container = styled(Box)(() => ({
  backgroundColor: '#FFF',
  boxShadow: '2px 1px 5px 1px #cccccc',
  maxHeight: '150px',
  overflowY: 'scroll',
  position: 'absolute',
  width: '100%',
  zIndex: 10,
}));

const SelectAssignee: React.FC<{
  errors?: string;
  name?: string;
  onChange: (value: any) => unknown;
  disable?: boolean;
  disableNoneOption?: boolean;
  value: string;
  ref?: any;
  initialAssigneeName?: string;
  userType: 'backOfficePersonnel' | 'driver' | null;
}> = ({
  errors,
  name,
  value,
  onChange,
  disable = false,
  disableNoneOption = false,
  ref,
  initialAssigneeName,
  userType,
}) => {
  const { trainingStore } = useCustomStore();
  const [showList, setShowList] = useState<boolean>();
  const [assigneeName, setAssigneeName] = useState<string>('');
  const [assigneeList, setAssigneeList] = useState<any[]>([]);
  const [nextLink, setNextLink] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const [isPointerAway, setIsPointerAway] = useState<boolean>(false);

  const getAllUsersForTraining = useCallback(async () => {
    if (!userType) return;
    setShowLoader(true);
    setAssigneeList([]);
    const resp = await trainingStore.getAllUsersForTraining({
      searchText: '',
      userType,
    });
    if (resp.isOk()) {
      const { response, nextLink = '' } = resp.value;
      setAssigneeList(response);
      setNextLink(nextLink);
      setShowLoader(false);
      response.length < 0 && !!assigneeName && setAssigneeName('');
    }
    trainingStore.setTrainingAssignedLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingStore, userType]);

  useEffect(() => {
    getAllUsersForTraining();
  }, [getAllUsersForTraining]);

  const handleScroll = (event: any) => {
    if (!userType) return;
    const myDiv = event?.target as HTMLDivElement;
    if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 100) {
      if (nextLink && !showLoader) {
        setShowLoader(true);
        trainingStore
          .getAllUsersForTraining({
            nextLink,
            userType,
          })
          .then((resp) => {
            const { response, nextLink = '' } = resp.value;
            setAssigneeList([...assigneeList, ...response]);
            setNextLink(nextLink);
            setShowLoader(false);
          });
      }
    }
  };

  const handleTextChange = (text: string) => {
    if (!userType) return;
    onChange('');
    setAssigneeName(text);
    if (text.length === 0 || text.length > 2) {
      setShowLoader(true);
      setAssigneeList([]);
      trainingStore
        .getAllUsersForTraining({
          limit: '100',
          searchText: text,
          userType,
        })
        .then((resp) => {
          const { response, nextLink = '' } = resp.value;
          setAssigneeList(response);
          setNextLink(nextLink);
          setShowLoader(false);
          response.length < 0 && !!assigneeName && setAssigneeName('');
        });
    }
  };

  useEffect(() => {
    if (!value) {
      setAssigneeName('');
      return;
    }
    if (assigneeName) return;
    const selectedAssignee = assigneeList.find((el: any) => el?.id === value);

    setAssigneeName(selectedAssignee?.name || initialAssigneeName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <InfiniteSelect id="infinite-select-container">
      <InputText
        autoComplete="off"
        inputRefObj={ref}
        errors={errors}
        name={name as string}
        isRefreshValue={true}
        value={assigneeName}
        onClick={() => setShowList(true)}
        onChangeText={handleTextChange}
        isEditable={disable}
        type="text"
        variant="standard"
        placeholder="Search"
        onBlurText={() => {
          if (isPointerAway) {
            setShowList(false);
          }
        }}
      />

      {showList && !disable && (
        <Container
          onMouseEnter={() => setIsPointerAway(false)}
          onMouseLeave={() => setIsPointerAway(true)}
          onScroll={handleScroll}>
          <List>
            <ListItem
              disabled={disableNoneOption}
              disablePadding
              key={-1}
              onClick={() => {
                if (disableNoneOption) return;
                onChange('None');
                setAssigneeName('None');
                setShowList(false);
              }}>
              <ListItemButton>
                <ListItemText>None</ListItemText>
              </ListItemButton>
            </ListItem>
            {/* {!showLoader && assigneeList.length === 0 && (
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="-No Results-" />
                </ListItemButton>
              </ListItem>
            )} */}
            {assigneeList.map((assignee: any, index: number) => (
              <ListItem
                disablePadding
                key={index}
                onClick={() => {
                  onChange(assignee);
                  setAssigneeName(assignee.name);
                  setShowList(false);
                }}>
                <ListItemButton>
                  <ListItemText primary={`${assignee.name as string}`} />
                </ListItemButton>
              </ListItem>
            ))}
            {showLoader && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress size={20} sx={{ color: '#DEC330' }} />
              </div>
            )}
          </List>
        </Container>
      )}
    </InfiniteSelect>
  );
};

export default observer(SelectAssignee);
