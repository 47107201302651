import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomSortingIcon from '../../../../../components/SortingIcon/CustomSortingIcon';
import { TrainingStore } from '../../../../../store/training';
import useStyles from './InspectionsStyles';

const InspectionsHeader: React.FC<{
  trainingStore: TrainingStore;
  trainingInspectionsSortBy: (columnName: string) => void;
}> = ({ trainingStore, trainingInspectionsSortBy }) => {
  const classes = useStyles();
  // Grid Header
  const completedHeader: any = [
    { label: 'Driver Name', name: 'driverName' },
    { label: 'Driver Simplex ID', name: 'driverSimplexId' },
    { label: 'Report Number', name: 'reportNumber' },
    { label: 'Inspection Date', name: 'inspectionDate' },
    { label: 'Inspection State', name: 'inspectionState' },
    { label: '# of Violations', name: 'noOfViolations' },
    { label: 'Recommendation Created On', name: 'recommendationCreatedOn' },
    { label: 'Recommendation Expiration Date', name: 'dueDate' },
    { label: 'Action', name: '' },
  ];
  // Sorting enable columns
  const sortColumns = [
    'reportNumber',
    'inspectionDate',
    'inspectionState',
    'noOfViolations',
    'recommendationCreatedOn',
    'dueDate',
  ];

  return (
    <TableRow>
      {completedHeader.map((header: any, index: number) => (
        <TableCell
          key={index}
          className={classes.tableHeaderBg}
          sx={header.styles}>
          <Grid item xs={12}>
            <Typography
              component={'div'}
              style={{
                alignItems: 'center',
                display: 'flex',
                whiteSpace: 'unset',
              }}>
              <Typography
                component={'div'}
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                }}>
                {header.label}
              </Typography>

              {sortColumns.includes(header.name) && (
                <CustomSortingIcon
                  {...{
                    filterSortBy:
                      trainingStore.TrainingInspectionFilters.sortBy,
                    filterSortDirection:
                      trainingStore.TrainingInspectionFilters.sortDirection,
                    sortBY: trainingInspectionsSortBy,
                    sortByName: header.name,
                  }}
                />
              )}
            </Typography>
          </Grid>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default observer(InspectionsHeader);
