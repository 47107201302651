import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { formatPhoneNumber } from '../../../../../utils/helper';
import ListItem from '../ListItem/ListItem';
import { SpaceBetween } from '../ListItem/ListItemStyles';

type Props = {
  onClickEdit?: () => any;
  SelectedDriver?: any;
};

const DriverSelected: React.FC<Props> = (props) => {
  const { SelectedDriver } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const previousPath = pathname.slice(0, pathname.lastIndexOf('/'));

  const maskSSN = (ssn = '') => {
    if (!ssn) return;
    const last4Chars = ssn.substring(ssn.length - 4, ssn.length);
    return `*** ** ${last4Chars}`;
  };

  return (
    <>
      <Grid container>
        <Grid lg={12}>
          <SpaceBetween style={{ marginBottom: '1rem' }}>
            <Typography
              variant="h5"
              component="span"
              fontSize={'16px'}
              fontWeight={600}
              color={'#241a2e'}>
              Driver Selected
            </Typography>
            <div>
              {SelectedDriver && (
                <Typography
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  variant="h5"
                  component="span"
                  fontSize={'16px'}
                  fontWeight={600}
                  color={'#0061FF'}
                  mr={4}
                  onClick={props.onClickEdit}>
                  Edit Info
                </Typography>
              )}
              <Typography
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                variant="h5"
                component="span"
                fontSize={'16px'}
                fontWeight={600}
                color={'#0061FF'}
                onClick={() => navigate(previousPath)}>
                Change Driver
              </Typography>
            </div>
          </SpaceBetween>
        </Grid>
        {(SelectedDriver && (
          <>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <ListItem value={SelectedDriver.name} label="Driver Name" />
              <ListItem value={SelectedDriver.email} label="Email Address" />
              <ListItem
                value={
                  (SelectedDriver.phoneNumber &&
                    formatPhoneNumber(SelectedDriver.phoneNumber)) ||
                  ''
                }
                label="Phone Number"
              />
              <ListItem
                value={
                  (SelectedDriver.ssn && maskSSN(SelectedDriver.ssn)) || ''
                }
                label="SSN"
              />
              <ListItem value={SelectedDriver.dob} label="DOB" />
              <ListItem
                value={
                  <>
                    {SelectedDriver.address && <>{SelectedDriver.address}, </>}
                    {SelectedDriver.city && <>{SelectedDriver.city}, </>}
                    {SelectedDriver.state && <>{SelectedDriver.state}, </>}
                    {SelectedDriver.zip && <>{SelectedDriver.zip} </>}
                  </>
                }
                label="Address"
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <ListItem
                moreLabelWidth
                value={SelectedDriver.licenseNumber}
                label="Drivers License #"
              />
              <ListItem
                moreLabelWidth
                value={SelectedDriver.licenseState}
                label="Drivers License State"
              />
              <ListItem
                moreLabelWidth
                value={SelectedDriver.licenseType}
                label="Driver's License Type"
              />
              <ListItem
                moreLabelWidth
                value={
                  SelectedDriver.licenseEndorsements
                    ? SelectedDriver.licenseEndorsements
                    : 'NONE'
                }
                label="Drivers License Endorsement"
              />
              <ListItem
                moreLabelWidth
                value={SelectedDriver.licenseExpirationDate}
                label="Drivers License Expiration"
              />
              <ListItem
                moreLabelWidth
                value={SelectedDriver.driverType}
                label="Driver Type"
              />
              <ListItem
                moreLabelWidth
                value={SelectedDriver.licenseOnFile ? 'YES' : 'NO'}
                label="License on File"
              />
            </Grid>
          </>
        )) || (
          <h2>
            Unable to get information for selected driver. Try again by changing
            driver
          </h2>
        )}
      </Grid>
      <div style={{ borderBottom: '1px solid #E8E8E8', margin: '20px 0' }} />
    </>
  );
};

export default observer(DriverSelected);
