import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  '& .MuiGrid-root': {
    padding: '0 16px !important',
  },
  '& .MuiPaper-root': {
    '.MuiGrid-container': {
      padding: '0px 16px !important',
    },
    padding: '16px 0px !important',
  },
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },
  grid: {
    padding: '0 2rem',
    paddingTop: '2rem',
  },
  gridActions: {
    '& button': {
      border: '1px solid #CBCBCB',
      borderRadius: '0.5rem',
      color: '#241A2E',
      fontFamily: 'FiraSans-Bold',
      fontSize: '0.8rem',
      marginRight: '0.8rem',
      padding: '0.8rem 2.5rem',
    },
  },
  rowHeight: {
    height: '80px',
  },
  scroll: {
    overflow: 'hidden',
    padding: '2rem 2rem 0 3rem',
    position: 'relative',
  },
  table: {
    borderSpacing: '0px 5px',
    minWidth: 700,
  },
  tableCell: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Light',
    fontSize: '0.8rem !important',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  tableHeaderBg: {
    backgroundColor: '#f5f5f5 !important',
  },
}));

export default useStyles;
