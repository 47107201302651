import { styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useTrendDetails from './hooks/useTrendDetails';
import SearchTypes from './SearchTypes';
import TrendGrid from './TrendGrid';

type Props = {
  setAppBarTitle: (title: string) => unknown;
};

const Main = styled('main')<{ active: boolean }>((active) => ({
  height: '100%',
  overflowY: 'scroll',
}));

const Article = styled('article')<{ isSearchExpanded: boolean }>(
  (isSearchExpanded) => ({
    alignItems: 'flex-end',
    marginLeft: isSearchExpanded ? 260 : 0,
    padding: '10px 5px',
  }),
);

const SelectedTrendDetails: React.FC<Props> = ({ setAppBarTitle }) => {
  const [expandGrid, setExpandGrid] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const [selectedType, setSelectedType] = useState<string>('');
  const {
    gridData = { columns: [], rows: [] },
    title,
    types = [],
    isLoading = false,
    fromDriver,
    fromEquipment,
    fromTop10,
    store,
  } = useTrendDetails(selectedType);

  const isTodos = title.includes('ToDos');
  const searchInputTitle = title?.split(' ')[0];

  useEffect(() => {
    setAppBarTitle(title?.split(' -')?.[0] || '');
  }, [title, setAppBarTitle]);

  useEffect(() => {
    const typeID = searchParams.get('type') || '';
    if (typeID) {
      setSelectedType(typeID);
    } else {
      setSelectedType('All');
    }
  }, [searchParams]);

  const handleSelect = (newType = '') => {
    if (newType !== selectedType) {
      store.resetGridData();
      store.resetParams();
      store.resetSortParams();
    }
    setSelectedType(newType);
  };

  useEffect(() => {
    // Should remove this later
    const node = document.getElementById('main-content');
    if (node) {
      node.style.overflowY = 'hidden';

      return () => {
        node.style.overflowY = 'inherit';
      };
    }
  }, []);

  return (
    <Main active={expandGrid} className="flex-row" id="selected-trend-details">
      <SearchTypes
        data={types}
        showFilter={expandGrid}
        onToggle={() => setExpandGrid(!expandGrid)}
        onSelect={handleSelect}
        title={
          searchInputTitle === 'Accidents'
            ? 'States'
            : `${
                searchInputTitle === 'ToDos' ? 'To-Dos' : searchInputTitle
              } Types`
        }
        defaultSelectedType={searchParams.get('type') || ''}
      />
      <Article isSearchExpanded={expandGrid} className="flex-column">
        <TrendGrid
          comingFrom={{
            driver: fromDriver,
            equipment: fromEquipment,
            top10: fromTop10,
          }}
          isLoading={isLoading}
          title={searchInputTitle}
          isTodos={isTodos}
          data={gridData}
        />
      </Article>
    </Main>
  );
};

export default observer(SelectedTrendDetails);
