import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { toJS } from 'mobx';
// import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import DialogMigrate from '../../../../../components/Dialog/DialogMigrate';
import { InputText } from '../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../components/Forms/selectInput';
import { SelectStateInput } from '../../../../../components/Forms/selectStateDropdown';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore, UseFocus, useMountEffect } from '../../../../../hooks';
import { IFTAFormInputs, RequestType, TodoRequest } from '../../../../../types';
import { getTaxPermitsActivityList } from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { IFTAFormSchema } from '../../../../../utils/ValidatorSchema';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
// import FilesList from '../../components/FilesList/FilesList';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import TaxPermitsEquipmentCard from '../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
import TaxPermitsEquipmentHeader from '../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';
// import uploadIcon from './../../../../../assets/images/upload.svg';
import useStyles from './IFTAStyles';

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));

const TaxPermitsIFTA: React.FC<any> = () => {
  const location = useLocation();

  const [reminder, setReminder] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [btnDisableInitialLoader, setBtnDisableInitialLoader] =
    useState<boolean>(true);
  const [initalLoader, setInitalLoader] = useState<boolean>(true);
  const [initialIFTAdata, setInitialIFTAdata] = useState<{
    accountNo?: string;
    expirationDate?: string;
    iftaStateId?: string;
  }>({});
  const [disableState, setDisableState] = useState<any>({
    accountNumber: false,
  });
  // const [docType, setUploadDocType] = useState<boolean>(true);
  const [notification, setNotification] = useState(false);
  const [equipment, setEquipment] = useState<Array<any>>([]);
  // const [documents, setDocuments] = useState<Array<any>>([]);

  const { authStore, iftaStore, activityListStore, todoStore } =
    useCustomStore();

  const classes = useStyles();
  // const inputRef = React.createRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors },
    // reset,
    // getValues,
    setValue,
    watch,
  } = useForm<IFTAFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      ifta: '',
      iftaExpirationDate: '',
      iftaState: '',
      type: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(IFTAFormSchema),
  });

  const iftaStateFormValue = watch('iftaState');
  const iftaTypeFormValue = watch('type');

  // useEffect(() => {
  //   if (!docType) {
  //     setDocuments([]);
  //     iftaStore.setIftaDetail('fuelCardDetails', []);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [docType]);

  const getInitialData = useCallback(async () => {
    setBtnDisableInitialLoader(false);
    setInitalLoader(true);
    const responseinitial = await iftaStore.getInitialData();
    setBtnDisableInitialLoader(true);
    setInitialFormData(responseinitial);
    setInitalLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialFormData = (responseinitial: any) => {
    if (responseinitial.isOk()) {
      const {
        value: { accountNumber = '', iftaStateId = '', expirationDate = '' },
      } = responseinitial;

      // setDisableState({
      //   accountNumber: !!accountNumber,
      // });
      setInitialIFTAdata({
        accountNo: accountNumber,
        expirationDate,
        iftaStateId,
      });
      setValue('ifta', accountNumber);
      setValue('iftaState', iftaStateId);
      setValue(
        'iftaExpirationDate',
        expirationDate ? moment(expirationDate).format('MM/DD/YYYY') : '',
      );

      let data = todoStore.EquipmentFromTodo;
      if (data) {
        setValue('type', 'renewal');
        iftaStore.setIftaDetail('renewalOrNewState', 'renewal');
      } else {
        setValue('type', '');
      }
      // setUploadDocType(data ? false : true);
      if (data && data.unit) {
        data = toJS(data.unit);
        data = [data];
        onselect(data);
        todoStore.setEquipmentFromTodo();
      }

      setEquipment([]);
      // setDocuments([]);
    }

    if (responseinitial.isErr()) {
      const {
        error: { message = null },
      } = responseinitial;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        const messages: any = {};
        Object.keys(message).forEach((key) => {
          messages[key] = message[key].message;
        });

        Object.keys(messages).forEach((msg: string) => {
          const key: string = msg;
          const value: string = messages[msg];

          enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
        });
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
  };

  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  useEffect(() => {
    const selectedType = watch('type');
    if (selectedType === 'new') {
      setDisableState({
        accountNumber: true,
        expirationDate: true,
      });
    } else {
      setDisableState({
        accountNumber: !!initialIFTAdata.accountNo,
        expirationDate: !!initialIFTAdata.expirationDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIFTAdata, watch('type')]);

  useEffect(() => {
    if (
      iftaTypeFormValue === RequestType.New &&
      iftaStateFormValue &&
      initialIFTAdata.iftaStateId !== iftaStateFormValue
    ) {
      iftaStore.setIftaDetail('iftaAccount', '');
      iftaStore.setIftaDetail('expirationDate', '');
      setValue('ifta', '');
      setValue('iftaExpirationDate', '');
    } else {
      iftaStore.setIftaDetail('iftaAccount', initialIFTAdata.accountNo);
      iftaStore.setIftaDetail('expirationDate', initialIFTAdata.expirationDate);
      setValue('ifta', initialIFTAdata.accountNo || '');
      setValue('iftaExpirationDate', initialIFTAdata.expirationDate || '');
    }
  }, [
    iftaStore,
    initialIFTAdata,
    setValue,
    iftaStateFormValue,
    iftaTypeFormValue,
  ]);

  const onSubmit = useCallback(async (data: IFTAFormInputs) => {
    if (
      iftaStore.iftaDetails &&
      iftaStore.iftaDetails.equipments &&
      iftaStore.iftaDetails.equipments.length
    ) {
      if (
        data.type === RequestType.New &&
        iftaStore.getInitialApiReponseData &&
        iftaStore.getInitialApiReponseData.iftaStateId === data.iftaState
      ) {
        setReminder(true);
        return;
      }
      setBtnDisable(true);
      let todoID = '';
      if (location && location.state) {
        const { todoId } = location.state as TodoRequest;
        todoID = todoId;
      }
      const addres: any = await iftaStore.addIFTA(todoID);
      setBtnDisable(false);
      if (addres.status === 'success') {
        const initialApiResponseData: any = await addres.response;
        setInitialFormData(initialApiResponseData);
        setNotification(true);
        activityListRecall();
        enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
      }

      if (addres.status === 'error') {
        if (addres.response.isErr()) {
          const {
            error: { message = null },
          } = addres.response;
          if (typeof message === 'string') {
            enqueueSnackbar(
              message || 'Error while processing data',
              SnackBarConfig('e'),
            );
          } else if (typeof message === 'object') {
            enqueueSnackbar('Validation Error', SnackBarConfig('e'));
            // const messages: any = {};
            // Object.keys(message).forEach((key) => {
            //   messages[key] = message[key].message;
            // });

            // Object.keys(messages).forEach((msg: string) => {
            //   const key: string = msg;
            //   const value: string = messages[msg];

            //   enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
            // });
          } else {
            enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onselect = useCallback(
    (equipmentArr: any) => {
      setEquipment(equipmentArr);
      iftaStore.addEquipmentDetail(equipmentArr);
    },
    [iftaStore],
  );

  const remove = (equip: any) => {
    let arr: Array<any> = equipment;
    if (equip) {
      arr = arr.filter((ele) => ele.unitId !== equip.unitId);
    }
    setEquipment(arr);
    iftaStore.removeEquipmentDetail(equip);
  };

  // const onClearFile = (_: any, index: number) => {
  //   setDocuments((p) => p.filter((_: any, i: number) => index !== i));
  //   iftaStore.removeFuelCardDetail(index);
  // };

  // const onChangeFuelReport = (e: any) => {
  //   const fileArr: any[] = Array.from(e.target.files);
  //   const findExcededFile: any = fileArr.find(
  //     (ef) => Number((ef.size / 1024 / 1024).toFixed(2)) > 15,
  //   );
  //   if (findExcededFile) {
  //     enqueueSnackbar('File mus not exceed 15 mb', SnackBarConfig('e'));
  //     return;
  //   }
  //   setDocuments((p) => [...p, ...fileArr]);
  //   iftaStore.addFuelCardDetail(fileArr);
  //   e.target.value = '';
  // };

  // const modifyFileList = (e: any) => {
  //   const data = {
  //     // date: '',
  //     name: '',
  //     size: 0,
  //     type: 'File',
  //   };
  //   function sizeDefiner(size: any) {
  //     return Number((size / 1024 / 1024).toFixed(2));
  //   }
  //   // function datedefiner(file: any) {
  //   //   return format(file.lastModified, 'dd-MM-yyyy');
  //   // }
  //   if (e.name) {
  //     data.name = e.name;
  //   }
  //   if (e.size) {
  //     data.size = sizeDefiner(e.size);
  //   }
  //   // if (e.lastModified) {
  //   //   data.date = datedefiner(e);
  //   // }
  //   return data;
  // };

  const cancel = () => {
    iftaStore.resetIFTADetails();
    const data = iftaStore.getInitialApiReponseData;
    setValue('ifta', data.accountNumber);
    setValue('type', '');
    // setUploadDocType(true);
    setEquipment([]);
    // setDocuments([]);
  };

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (notification) {
    return (
      <SuccessNotification
        title={'IFTA'}
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return initalLoader ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <form
      style={{
        width: '100%',
      }}
      id="ifta-form"
      onSubmit={handleSubmit(onSubmit)}>
      <FormControl variant="outlined" fullWidth size="small">
        <Grid container rowSpacing={1} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
          <Grid item md={12} sm={12} xs={12}>
            <Box className={classes.labels}>New, Renewal or Additional *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="type"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    inputRefObj={input1Ref}
                    size={'small'}
                    errors={errors.type?.message || ''}
                    onChangeText={(value: string) => {
                      onChange(value);
                      iftaStore.setIftaDetail('renewalOrNewState', value);
                      setValue(
                        'iftaState',
                        value === 'new'
                          ? ''
                          : iftaStore.getInitialApiReponseData &&
                            iftaStore.getInitialApiReponseData.iftaStateId
                          ? iftaStore.getInitialApiReponseData.iftaStateId
                          : '',
                      );
                    }}
                    isEditable={false}
                    selectClassName={classes.selectClassName}
                    placeHolder={'Select'}
                    sortByOptionDisable={true}
                    optionsList={[
                      { label: 'New', value: 'new' },
                      { label: 'Renewal', value: 'renewal' },
                      { label: 'Additional', value: 'additional' },
                    ]}
                  />
                );
              }}
            />
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
          <Grid item md={12} sm={12} xs={12}>
            <TaxPermitsEquipmentHeader
              selecetedEquipment={equipment}
              onSelectEquipment={onselect}
              requestType="TPTS"
            />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            className={
              !equipment || !equipment.length ? classes.gridTextAlign : ''
            }>
            {equipment.map((ele, index) => (
              <TaxPermitsEquipmentCard
                key={index}
                ele={ele}
                removedCard={remove}
              />
            ))}
            {!equipment || !equipment.length ? (
              <div className={'error-msg'}>Please Select Equipment</div>
            ) : (
              ''
            )}
          </Grid>

          {watch('type') && (
            <>
              <Grid item md={4} sm={12} xs={12}>
                <Box className={classes.labels}>
                  IFTA Account {!disableState.accountNumber && <span>*</span>}
                </Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="ifta"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.ifta?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(value) => {
                          onChange(value);
                          iftaStore.setIftaDetail('iftaAccount', value);
                        }}
                        isEditable={disableState.accountNumber}
                        className={classes.selectClassName}
                        type={'text'}
                        variant="standard"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box className={classes.labels}>Expiration Date</Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="iftaExpirationDate"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.iftaExpirationDate?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(value) => {
                          onChange(value);
                          iftaStore.setIftaDetail('iftaExpirationDate', value);
                        }}
                        isEditable={disableState.expirationDate}
                        className={classes.selectClassName}
                        type={'text'}
                        variant="standard"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box className={classes.labels}>
                  State {watch('type') === 'new' && <span>*</span>}
                </Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="iftaState"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectStateInput
                        defaultValue={
                          watch('type') !== RequestType.New
                            ? iftaStore.getInitialApiReponseData.iftaStateId ||
                              ''
                            : ''
                        }
                        keyName="id"
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.iftaState?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(value) => {
                          onChange(value);
                          if (
                            watch('type') === RequestType.New &&
                            iftaStore.getInitialApiReponseData &&
                            iftaStore.getInitialApiReponseData.iftaStateId ===
                              value
                          ) {
                            setReminder(true);
                          }
                          const stateCode =
                            authStore?.statesListOption?.find(
                              (state) => state.id === value,
                            )?.code || '';

                          iftaStore.setIftaDetail('iftaState', stateCode);
                        }}
                        isEditable={
                          (watch('type') &&
                          watch('type') !== 'new' &&
                          iftaStore.getInitialApiReponseData &&
                          iftaStore.getInitialApiReponseData.iftaStateId
                            ? true
                            : false) || btnDisable
                        }
                        selectClassName={classes.selectClassName}
                      />
                    );
                  }}
                />
              </Grid>
            </>
          )}
          {/* {docType && ( */}
          {/* <React.Fragment>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.gridheadingFlex}>
              <Box className={classes.labels}>
                Upload{' '}
                {docType
                  ? 'IFTA Document (Optional)'
                  : 'Registration Letter (Optional)'}
              </Box>
              <input
                ref={inputRef}
                type="file"
                hidden
                multiple
                onChange={onChangeFuelReport}
                accept={AllowedUploadMimeTypes}
              />
              <Box
                className={classes.uploadDoc}
                onClick={() => inputRef.current?.click()}>
                <img src={uploadIcon} alt="icon" />
                <span> Upload </span>
              </Box>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FilesList
                files={[...documents.map(modifyFileList)]}
                onClearFile={onClearFile}
              />
            </Grid>
          </React.Fragment> */}
          {/* )} */}
          <Grid item md={12} sm={12} xs={12}>
            <DialogMigrate
              aria-labelledby="Reminder"
              maxWidth={'sm'}
              className={classes.dialog}
              open={reminder}
              disableBackdropClick={false}
              disableEscapeKeyDown={false}
              onClose={() => setReminder(false)}>
              <Typography component={'div'} sx={{ padding: '1.8rem' }}>
                <Typography component={'div'} sx={{ marginBottom: '1rem' }}>
                  Permit for selected state already exists, you can only submit
                  Renewal/Additional request type
                </Typography>
                <DarkColorButton
                  type="button"
                  onClick={() => setReminder(false)}
                  variant="contained"
                  sx={{ borderRadius: '4px', width: '100%' }}>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontFamily: 'FiraSans-Medium',
                      fontSize: '0.9375rem',
                      fontWeight: 500,
                      height: '22px',
                      letterSpacing: '1px',
                      lineHeight: '22px',
                      textAlign: 'center',
                    }}>
                    Ok
                  </Typography>
                </DarkColorButton>
              </Typography>
            </DialogMigrate>
          </Grid>
        </Grid>
      </FormControl>

      <Divider className={classes.divider} />
      <TaxPermitsButtons
        formId={'ifta-form'}
        disabled={btnDisable}
        formReset={cancel}
        disableLoader={btnDisableInitialLoader}
      />
    </form>
  );
};

export default observer(TaxPermitsIFTA);
