import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useCustomStore } from '../../../../hooks';
import { formatPhoneNumber } from '../../../../utils/helper';
import SettingsHeader from '../components/header';
import useStyles from './CompanyInfoStyles';

const CompanyInfo: React.FC = () => {
  const classes = useStyles();
  const { settingsStore } = useCustomStore();

  useEffect(() => {
    settingsStore.companyInfo();
  }, [settingsStore]);

  return (
    <div className={classes.companyInfoContainer}>
      <SettingsHeader />
      <Card className={classes.card}>
        {settingsStore.CompanyDetails ? (
          <CardContent sx={{ cursor: 'default', padding: '30px' }}>
            <Typography component={'h2'} className={classes.headingName}>
              Company Information
            </Typography>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
              <Grid item md={6} sm={12}>
                <Typography component={'div'} className={classes.infoTitle}>
                  Company Name
                </Typography>
                <Typography component={'div'} className={classes.infodis}>
                  {settingsStore.CompanyDetails
                    ? settingsStore.CompanyDetails?.companyName
                    : '-'}
                </Typography>
              </Grid>

              <Grid item md={6} sm={12}>
                <Typography component={'div'} className={classes.infoTitle}>
                  Company Phone Number
                </Typography>
                <Typography component={'div'} className={classes.infodis}>
                  {settingsStore.CompanyDetails
                    ? formatPhoneNumber(
                        settingsStore.CompanyDetails?.phoneNumber,
                      )
                    : '-'}
                </Typography>
              </Grid>

              <Grid item md={6} sm={12}>
                <Typography component={'div'} className={classes.infoTitle}>
                  DOT Number
                </Typography>
                <Typography component={'div'} className={classes.infodis}>
                  {settingsStore.CompanyDetails
                    ? settingsStore.CompanyDetails?.dotNumber
                    : '-'}
                </Typography>
              </Grid>

              <Grid item md={6} sm={12}>
                <Typography component={'div'} className={classes.infoTitle}>
                  MC Number
                </Typography>
                <Typography component={'div'} className={classes.infodis}>
                  {settingsStore.CompanyDetails
                    ? settingsStore.CompanyDetails?.mcNumber
                    : '-'}
                </Typography>
              </Grid>

              <Grid item md={6} sm={12}>
                <Typography component={'div'} className={classes.infoTitle}>
                  Business Address
                </Typography>
                <Typography component={'div'} className={classes.infodis}>
                  {settingsStore.CompanyDetails
                    ? `${settingsStore.CompanyDetails?.businessAddress} 
                    ${settingsStore.CompanyDetails?.businessCity} ${settingsStore.CompanyDetails?.businessState} 
                    ${settingsStore.CompanyDetails?.businessZip}`
                    : '-'}
                </Typography>
              </Grid>

              <Grid item md={6} sm={12}>
                <Typography component={'div'} className={classes.infoTitle}>
                  Mailing Address
                </Typography>
                <Typography component={'div'} className={classes.infodis}>
                  {settingsStore.CompanyDetails
                    ? `${settingsStore.CompanyDetails?.mailingAddress} 
                    ${settingsStore.CompanyDetails?.mailingCity} ${settingsStore.CompanyDetails?.mailingState} 
                    ${settingsStore.CompanyDetails?.mailingZip}`
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        ) : (
          <div className={classes.loader}>
            <CircularProgress size={30} sx={{ color: '#DEC330' }} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default observer(CompanyInfo);
