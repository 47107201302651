import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import DuplicateVinAlert from '../../../../components/DuplicateVinAlert';
import NavRoutes from '../../../../config/routes/NavRoutes';
import { useCustomStore } from '../../../../hooks';
import { UpdateFleetFormInputs } from '../../../../types';
import { handleGetUpdatedFleetData } from '../../../../utils/helper';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import { UpdateFleetFormSchema } from '../../../../utils/ValidatorSchema';
import useStyles from '../../ServiceRequest/components/Address/AddressStyles';
import LoadingModal from '../../ServiceRequest/components/LoadingModal/LoadingModal';
import AddToFleetForm from '../../ServiceRequest/Dot/AddToFleet/AddToFleetForm';
import cardStyles from '../../ServiceRequest/TaxPermits/DotUpdate/DotUpdateStyles';

const AddToFleet: React.FC = () => {
  const { addToFleetStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { fleetid } = useParams();
  const navigate = useNavigate();
  const styles = cardStyles();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [showDuplicateVinDialog, setShowDuplicateVinDialog] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [duplicateVINmsg, setDuplicateVINmsg] = useState('');

  const [vqFile, setVqFile] = useState<Array<any>>([]);
  const [registration, setRegistration] = useState<Array<any>>([]);
  const [fleetInfoById, setFleetInfoById] = useState<any>('');
  const [primaryDriverName, setPrimaryDriverName] = useState<string>();
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
    watch,
  } = useForm<UpdateFleetFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      dotFtBundleStatus: false,
      equipmentType: '',
      grossVehicleWeight: '',
      isPermanantRegistration: false,
      isSimplexCompletingFuelTaxes: '',
      lastAnnualInspectionDate: null,
      licensePlateNumber: '',
      licensePlateState: '',
      ownershipType: '',
      primaryDriverContactId: null,
      registrationExpDate: null,
      registrationType: '',
      unitNumber: '',
      unitType: '',
      vehicleMake: '',
      vehicleYear: '',
      vinNumber: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(UpdateFleetFormSchema),
  });
  const getFleetById = useCallback(async () => {
    const getResp = await addToFleetStore.getFleetById(fleetid);
    if (getResp.isOk()) {
      setFleetInfoById(getResp.value);
      const {
        equipmentType,
        grossVehicleWeight,
        isPermanantRegistration,
        lastAnnualInspectionDate,
        licensePlateNumber,
        licensePlateState,
        ownershipType,
        primaryDriverContactId,
        primaryDriverName,
        registrationExpDate,
        registrationType,
        unitNumber,
        unitType,
        vehicleMake,
        vehicleYear,
        vinNumber,
      } = getResp.value;
      const _registrationType =
        registrationType === 'IRP'
          ? 'IRP'
          : registrationType === 'local'
          ? 'local'
          : '';
      setPrimaryDriverName(primaryDriverName);
      setValue('equipmentType', equipmentType);
      setValue('grossVehicleWeight', grossVehicleWeight);
      setValue('isPermanantRegistration', isPermanantRegistration);
      setValue('lastAnnualInspectionDate', lastAnnualInspectionDate);
      setValue('licensePlateNumber', licensePlateNumber);
      setValue('licensePlateState', licensePlateState);
      setValue('ownershipType', ownershipType);
      setValue('primaryDriverContactId', primaryDriverContactId);
      setValue('registrationExpDate', registrationExpDate);
      setValue('registrationType', _registrationType);
      setValue('unitNumber', unitNumber);
      setValue('unitType', unitType);
      setValue('vehicleMake', vehicleMake);
      setValue('vehicleYear', vehicleYear);
      setValue('vinNumber', vinNumber);
    }
  }, [addToFleetStore, fleetid, setValue]);
  const getAddToFleetPrdefinedValues = useCallback(async () => {
    setLoading(true);
    await addToFleetStore.getPredefinedAddToFleetInfo();
    await getFleetById();
    setLoading(false);
  }, [addToFleetStore, setLoading, getFleetById]);

  useEffect(() => {
    getAddToFleetPrdefinedValues();
  }, [getAddToFleetPrdefinedValues]);

  const showDuplicateVinAlert = (data: UpdateFleetFormInputs) => {
    setShowDuplicateVinDialog(true);
  };

  const onSubmit = useCallback(
    async (data: UpdateFleetFormInputs) => {
      setBtnDisable(true);
      if (data.primaryDriverContactId === 'None') {
        data.primaryDriverContactId = '';
      }
      const bodyData = getBodyFormData(data);
      bodyData.equipment.registrationType =
        bodyData.equipment.equipmentType === 'trailer'
          ? 'local'
          : bodyData.equipment.registrationType;
      const { equipment } = bodyData;
      const getResp = await addToFleetStore.updateFleet(
        handleGetUpdatedFleetData(dirtyFields, equipment, fleetInfoById),
        fleetid,
      );
      setBtnDisable(false);
      if (getResp.isErr()) {
        setBtnDisable(false);
        enqueueSnackbar(getResp.error.message.toString(), SnackBarConfig('e'));
      } else {
        const { value } = getResp;
        if (value === 'Fleet data updated!') {
          enqueueSnackbar(getResp.value.toString(), SnackBarConfig('s'));
          navigate(`/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}`);
        } else {
          setDuplicateVINmsg(value);
        }
      }
    },
    [
      addToFleetStore,
      enqueueSnackbar,
      fleetid,
      navigate,
      dirtyFields,
      fleetInfoById,
    ],
  );

  const handleClickOnOkay = () => {
    setDuplicateVINmsg('');
    navigate(`/${NavRoutes.PRIVATE.LEFT_NAV.FLEET}`);
  };

  const getBodyFormData = (data: UpdateFleetFormInputs) => {
    const bodyObj: any = Object.assign({}, data);
    delete bodyObj.vqFile;
    delete bodyObj.registration;
    if (bodyObj.isPermanantRegistration) {
      delete bodyObj.registrationExpDate;
    }
    return {
      equipment: {
        ...bodyObj,
        isSimplexCompletingFuelTaxes: bodyObj.dotFtBundleStatus
          ? bodyObj.isSimplexCompletingFuelTaxes === 'true'
            ? true
            : false
          : false,
        lastAnnualInspectionDate: moment(
          bodyObj.lastAnnualInspectionDate,
        ).format('MM/DD/YYYY'),
        ...(!bodyObj.isPermanantRegistration
          ? {
              registrationExpDate: moment(bodyObj.registrationExpDate).format(
                'MM/DD/YYYY',
              ),
            }
          : {}),
      },
    };
  };

  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return loading ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress size={80} style={{ marginTop: '20px' }} />
    </div>
  ) : (
    <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
      <Grid lg={8} md={12}>
        <Card>
          <CardHeader
            title={<h3>Update Fleet</h3>}
            action={
              <Grid spacing={1}>
                <Button
                  onClick={() => navigate(-1)}
                  variant="text"
                  className={styles.primaryText}>
                  Cancel
                </Button>

                <Button
                  form="add-to-fleet"
                  type="submit"
                  disabled={btnDisable}
                  variant="text"
                  className={styles.primaryText}>
                  Save
                </Button>
              </Grid>
            }
            style={{ fontWeight: 'bolder' }}
          />

          <CardContent>
            <AddToFleetForm
              initialDriverName={primaryDriverName}
              {...{
                addToFleetInfo: addToFleetStore,
                btnDisable,
                classes,
                control,
                errors,
                getValues,
                handleSubmit,
                onSubmit: showDuplicateVinAlert,
                registration,
                setRegistration,
                setValue,
                setVqFile,
                vqFile,
                watch,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <DuplicateVinAlert
        duplicateVINmsg={duplicateVINmsg}
        handleSubmit={() => onSubmit(getValues())}
        onBackdropClick={() =>
          duplicateVINmsg
            ? handleClickOnOkay()
            : setShowDuplicateVinDialog(false)
        }
        open={showDuplicateVinDialog}
      />
    </Grid>
  );
};

export default observer(AddToFleet);
