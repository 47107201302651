import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';

import { useCustomStore } from '../../hooks';
import { InputText } from '../Forms/InputText';

const InfiniteSelect = styled('div')(() => ({
  position: 'relative',
}));

const Container = styled(Box)(() => ({
  backgroundColor: '#FFF',
  boxShadow: '2px 1px 5px 1px #cccccc',
  maxHeight: '150px',
  overflowY: 'scroll',
  position: 'absolute',
  width: '100%',
  zIndex: 10,
}));

const SelectDriverFromAll: React.FC<{
  errors?: string;
  name?: string;
  onChange: (value: string | null) => unknown;
  disable?: boolean;
  disableNoneOption?: boolean;
  value: string;
  _ref: any;
  initialDriverName?: string;
  _key?: string;
}> = ({
  errors,
  name,
  value,
  onChange,
  disable = false,
  disableNoneOption = false,
  _ref,
  initialDriverName,
  _key,
}) => {
  const { inspectionsAndViolationsStore } = useCustomStore();
  const [showList, setShowList] = useState<boolean>();
  const [driverName, setDriverName] = useState<string>();
  const [driverList, setDriverList] = useState<any>([]);
  const [nextLink, setNextLink] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>();

  const intervalRef = useRef<NodeJS.Timeout>();

  const [isPointerAway, setIsPointerAway] = useState<boolean>(false);

  React.useEffect(() => {
    if (driverList.length) return;
    setShowLoader(true);
    inspectionsAndViolationsStore.getAllDrivers().then((resp) => {
      setDriverList([...driverList, ...resp.list]);
      setNextLink(resp.nextLink);
      setShowLoader(false);
    });
  }, [inspectionsAndViolationsStore, driverList, driverList.length, value]);

  const handleScroll = (event: any) => {
    const myDiv = event?.target as HTMLDivElement;
    if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 100) {
      if (nextLink) {
        setShowLoader(true);
        inspectionsAndViolationsStore
          .getAllDrivers({
            nextLink,
          })
          .then((resp) => {
            setDriverList([...driverList, ...resp.list]);
            setNextLink(resp.nextLink);
            setShowLoader(false);
          });
      }
    }
  };

  const handleTextChange = (text: string) => {
    onChange('');
    setDriverName(text);
    if (intervalRef.current) clearTimeout(intervalRef.current);
    intervalRef.current = setTimeout(() => {
      setShowLoader(true);
      inspectionsAndViolationsStore
        .getAllDrivers({
          name: text,
        })
        .then((resp) => {
          setDriverList([...resp.list]);
          setNextLink(resp.nextLink);
          setShowLoader(false);
        });
    }, 500);
  };

  useEffect(() => {
    const fetch = async () => {
      if (!value) {
        setDriverName('');
        return;
      }
      if (driverName) return;

      let selectedDriver: any = {};

      if (driverList.length > 0) {
        selectedDriver = driverList.find((el: any) => el?.id === value);
        if (Object.keys(selectedDriver).length === 0) {
          selectedDriver = await getDriverFromAPI(value);
        }
      } else {
        selectedDriver = await getDriverFromAPI(value);
      }
      setDriverName(selectedDriver?.name || initialDriverName);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getDriverFromAPI = async (value: string) => {
    let drivers = [];
    const resp = await inspectionsAndViolationsStore.getAllDrivers({
      driverId: value,
    });
    const list = resp.list;
    drivers = [...list];
    const sd = drivers.find((el: any) => el?.id === value);
    return sd;
  };

  return (
    <InfiniteSelect id="infinite-select-container" key={_key}>
      <InputText
        autoComplete="off"
        inputRefObj={_ref}
        errors={errors}
        name={name as string}
        isRefreshValue={true}
        value={driverName}
        onClick={() => setShowList(true)}
        onChangeText={handleTextChange}
        isEditable={disable}
        type="text"
        variant="standard"
        placeholder="Search"
        onBlurText={() => {
          if (isPointerAway) {
            setShowList(false);
          }
        }}
      />

      {showList && !disable && (
        <Container
          onMouseEnter={() => setIsPointerAway(false)}
          onMouseLeave={() => setIsPointerAway(true)}
          onScroll={handleScroll}>
          <List>
            {!showLoader && driverList.length === 0 && (
              <ListItem>
                <ListItemButton>
                  <ListItemText primary="-No Results-" />
                </ListItemButton>
              </ListItem>
            )}
            {driverList.map((primaryDriver: any, index: number) => (
              <ListItem
                disablePadding
                key={index}
                onClick={() => {
                  onChange(primaryDriver.id);
                  setDriverName(primaryDriver.name);
                  setShowList(false);
                }}>
                <ListItemButton>
                  <ListItemText primary={primaryDriver.name} />
                </ListItemButton>
              </ListItem>
            ))}
            {showLoader && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#DEC330', marginTop: 20 }}
                />
              </div>
            )}
          </List>
        </Container>
      )}
    </InfiniteSelect>
  );
};

export default observer(SelectDriverFromAll);
