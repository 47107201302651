import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore } from '../../../hooks';
import { DATE_PICKER_DATE_FORMAT } from '../../../utils/helper';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { SelectDatePicker } from '../../Forms/datePicker';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type SearchFormProps = {
  classes: any;
  onClose: () => void;
};

type SearchFromType = {
  driverId: string;
  driverName: string;
  driverSimplexId: string;
  reportNumber: string;
  inspectionDateStart: string;
  inspectionDateEnd: string;
  inspectionState: string;
};

const TrainingInspectionsSearchForm: React.FC<any> = (
  props: SearchFormProps,
) => {
  const { classes, onClose } = props;
  const { trainingStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const [assignees, setAssignees] = useState<any[]>([]);

  const { control, handleSubmit, watch, setValue } = useForm<SearchFromType>({
    defaultValues: {
      driverId: trainingStore.TrainingInspectionFilters.driverId,
      driverName: trainingStore.TrainingInspectionFilters.driverName,
      driverSimplexId: trainingStore.TrainingInspectionFilters.driverSimplexId,
      inspectionDateEnd:
        trainingStore.TrainingInspectionFilters.inspectionDateEnd,
      inspectionDateStart:
        trainingStore.TrainingInspectionFilters.inspectionDateStart,
      inspectionState: trainingStore.TrainingInspectionFilters.inspectionState,
      reportNumber: trainingStore.TrainingInspectionFilters.reportNumber,
    },
  });

  const getAllTrainingAssignees = useCallback(async () => {
    if (trainingStore.TrainingAssigneesData.length > 0) {
      setAssignees(trainingStore.TrainingAssigneesData);
      setValue(
        'driverId',
        trainingStore.TrainingInspectionFilters.driverId || '',
      );
    } else {
      trainingStore.setTrainingAssigneesLoader(true);
      const resp = await trainingStore.getAllUsersForTraining({
        limit: '5000',
        searchText: '',
        userType: 'backOfficePersonnel',
      });
      if (resp.isOk()) {
        const { response } = resp.value;
        setAssignees(response);
        trainingStore.setTrainingAssigneesData(response);
      } else {
        enqueueSnackbar(
          'Unable to fetch Training Assignees',
          SnackBarConfig('e'),
        );
      }
    }
  }, [enqueueSnackbar, setValue, trainingStore]);

  useEffect(() => {
    getAllTrainingAssignees();
  }, [getAllTrainingAssignees, trainingStore]);

  const onFiltersSubmit = useCallback(
    async (values) => {
      trainingStore.setTrainingInspectionFilters(values);
      trainingStore.setTrainingInspectionsLoader(true);
      const inspectionsResp =
        await trainingStore.getInitialTrainingInspections();
      if (inspectionsResp.isErr()) {
        enqueueSnackbar(
          'Unable to fetch Training Inspections',
          SnackBarConfig('e'),
        );
      }
      trainingStore.setTrainingInspectionsLoader(false);
      onClose();
    },
    [enqueueSnackbar, onClose, trainingStore],
  );

  const resetFilters = useCallback(async () => {
    trainingStore.resetTrainingInspectionFilters();
    trainingStore.setTrainingInspectionsLoader(true);
    const inspectionsResp = await trainingStore.getInitialTrainingInspections();
    if (inspectionsResp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Inspections',
        SnackBarConfig('e'),
      );
    }
    trainingStore.setTrainingInspectionsLoader(false);
    onClose();
  }, [enqueueSnackbar, onClose, trainingStore]);

  return (
    <form
      id="training-inspections-search-form"
      onSubmit={handleSubmit(onFiltersSubmit)}>
      <FormControl error={false} fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Driver Name
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="driverId"
                render={({ field: { onChange, value, name, ref } }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={assignees.length === 0}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={assignees.map((r) => ({
                        label: r.name,
                        optionClassName: classes.optionsClassName,
                        value: r.id,
                      }))}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Driver Simplex ID
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="driverSimplexId"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Inspection Date
        </Typography>
        <div className={clsx(classes.borderBottom, classes.dateFilter)}>
          <FormControl className="date-control">
            <Typography component={'h6'} className="label">
              From:
            </Typography>
            <Grid>
              <Grid container className={classes.searcBox}>
                <Grid item md={10} className="date-picker">
                  <Controller
                    control={control}
                    name="inspectionDateStart"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectDatePicker
                          value={value}
                          isRefreshValue
                          inputRefObj={ref}
                          name={name}
                          onChangeDate={(d) => {
                            const formattedValue = moment(d).format(
                              DATE_PICKER_DATE_FORMAT,
                            );
                            onChange(formattedValue);
                          }}
                          className={classes.selectClassName}
                          maxDate={
                            watch('inspectionDateEnd')
                              ? new Date(watch('inspectionDateEnd'))
                              : undefined
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl className="date-control">
            <Typography component={'h6'} className="label">
              To:
            </Typography>
            <Grid>
              <Grid container className={classes.searcBox}>
                <Grid item md={10} className="date-picker">
                  <Controller
                    control={control}
                    name="inspectionDateEnd"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectDatePicker
                          value={value}
                          isRefreshValue
                          inputRefObj={ref}
                          name={name}
                          onChangeDate={(d) => {
                            const formattedValue = moment(d).format(
                              DATE_PICKER_DATE_FORMAT,
                            );
                            onChange(formattedValue);
                          }}
                          className={classes.selectClassName}
                          minDate={
                            watch('inspectionDateStart')
                              ? new Date(watch('inspectionDateStart'))
                              : undefined
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </div>
        <Typography component={'h3'} className={classes.filterLabel}>
          Report Number
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="reportNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              type="button"
              onClick={resetFilters}
              disabled={trainingStore.TrainingInspectionsLoader}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            className={classes.filterBtn}
            type="submit"
            disabled={trainingStore.TrainingInspectionsLoader}>
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(TrainingInspectionsSearchForm);
