import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  addflex: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  bigLabel: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '1rem !important',
    marginBottom: '0.5rem !important',
  },
  borderBox: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #D1D3D4',
    borderRadius: '8px',
    marginBottom: '0.5rem !important',
    minHeight: '75px',
    padding: '1.5rem 0px !important',
  },
  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
  },
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '0.75rem !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  container: {
    paddingRight: '4rem',
  },
  divider: {
    border: '1px solid #E8E8E8',
    marginTop: '31px !important',
  },
  dtitle: {
    color: '#979598 !important',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.85rem !important',
    paddingRight: '10px !important',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  gridheadingFlex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0 !important',
  },
  infobox: {
    backgroundColor: '#F8F8FA !important',
    borderRadius: '7px',
    color: '#7A777D !important',
    fontSize: '0.85rem !important',
    lineHeight: '15px',
    minHeight: '10px',
    padding: '1.5rem',
    position: 'relative',
  },
  infoicon: {
    left: '5px',
    position: 'absolute',
    top: '5px',
  },
  noText: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '15px',
    height: '18px',
    letterSpacing: 0,
    lineHeight: '18px',
    width: '20px',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  quarterClassName: {
    backgroundColor: '#f3f3f3 !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    marginTop: '1.5rem',
  },
  renewalFormCardDivider: {
    margin: '12px 0px !important',
  },
  selectClassName: {
    backgroundColor: '#f3f3f3 !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
  },
  smallLabel: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '0.75rem !important',
    marginBottom: '0.5rem !important',
  },
  stateStatus: {
    '& li': {
      padding: '0px !important',
    },
    backgroundColor: '#F8F8FA',
    borderRadius: '7px',
    minHeight: '100px',
    padding: '0.6rem 0 !important',
  },
  submited: {
    boxShadow: 'unset',
  },
  title: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '18px',
    fontWeight: 500,
    height: '26px',
    letterSpacing: 0,
    lineHeight: '26px',
    marginTop: '-0.3rem !important',
    paddingLeft: '1rem',
  },
}));

export default useStyles;
