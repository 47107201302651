import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import packageJson from '../../../package.json';
import { useCustomStore } from '../../hooks';
import { decryptFileUrl } from '../../utils/helper';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './VideoStreamDialogStyles';

type VideoStreamDialogProps = {
  showDialog: boolean;
  videoUrl: string;
  title: string;
  hasDocument: boolean;
  allowControls?: boolean;
  closeDialog: () => void;
};

const VideoStreamDialog: React.FC<VideoStreamDialogProps> = (
  props: VideoStreamDialogProps,
) => {
  const classes = useStyles();
  const { trainingStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const pdfJsVersion: string = packageJson.dependencies['pdfjs-dist'];
  const {
    showDialog,
    closeDialog,
    videoUrl,
    title,
    hasDocument,
    allowControls = true,
  } = props;

  const [videoLoader, setVideoLoader] = useState<boolean>(true);
  const [videoLink, setVideoLink] = useState<string | null>(null);

  // Getting video link
  const getTrainingFileLink = useCallback(async () => {
    if (!videoUrl) {
      return;
    }
    const resp = await trainingStore.getTrainingFileLink({
      filePath: videoUrl,
    });
    if (resp.isOk()) {
      setVideoLink(resp.value?.link ? decryptFileUrl(resp.value?.link) : null);
    } else {
      enqueueSnackbar('Unable to fetch Training Video', SnackBarConfig('e'));
    }
    setVideoLoader(false);
  }, [enqueueSnackbar, trainingStore, videoUrl]);

  useEffect(() => {
    if (videoUrl) {
      getTrainingFileLink();
    }
  }, [getTrainingFileLink, videoUrl]);

  return (
    <DialogMigrate
      aria-labelledby="video-stream-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={showDialog}
      disableBackdropClick={true}
      disableEscapeKeyDown={false}
      onClose={() => {
        closeDialog();
      }}>
      <DialogTitle className={classes.dialogHeader}>
        <div className="title">{title || 'Training Video'}</div>
        <IconButton
          size="small"
          aria-label="close"
          onClick={() => closeDialog()}>
          <CloseIcon className="close-icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {videoLoader && (
          <CircularProgress size={50} sx={{ color: '#DEC330' }} />
        )}
        {!videoLoader && videoLink && !hasDocument && (
          <div className="video">
            <video
              src={videoLink}
              controls={allowControls}
              controlsList={allowControls ? 'nodownload' : ''}
              autoPlay={true}
              width="100%"
              onContextMenu={(e: any) => {
                if (e && e.target && e.target instanceof HTMLVideoElement) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        )}
        {!videoLoader && videoLink && hasDocument && (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfJsVersion}/build/pdf.worker.min.js`}>
            <div className={classes.documentViewer}>
              <Viewer
                fileUrl={videoLink}
                defaultScale={SpecialZoomLevel.PageWidth}
              />
            </div>
          </Worker>
        )}
      </DialogContent>
    </DialogMigrate>
  );
};

export default observer(VideoStreamDialog);
