import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import DuplicateVinAlert from '../../../../../components/DuplicateVinAlert';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore } from '../../../../../hooks';
import {
  AddToFleetFormInputs,
  // ApiError,
  EquipmentType,
  OwnershipType,
} from '../../../../../types';
import {
  getFileNamingConvention,
  getStateNameByStateId,
} from '../../../../../utils/fileName.config';
import { refreshActivityListByStore } from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { AddToFleetFormSchema } from '../../../../../utils/ValidatorSchema';
import useStyles from '../../components//Address/AddressStyles';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import AddToFleetForm from './AddToFleetForm';

interface propState {
  from?: string;
  id?: string;
  details?: any;
}

const AddToFleet: React.FC = () => {
  const {
    addToFleetStore,
    authStore,
    dotActivityListStore,
    fileStore,
    userStore,
  } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState(false);
  const [resetFormRequestType, setResetFormRequestType] = useState<
    number | null
  >(null);
  const [isOwnerOperator, setIsOwnerOperator] = useState<boolean>(false);
  const [vqFile, setVqFile] = useState<Array<any>>([]);
  const [registration, setRegistration] = useState<Array<any>>([]);
  const [fileValidation, setFileValidation] = useState<boolean>(false);
  const [showDuplicateVinDialog, setShowDuplicateVinDialog] =
    useState<boolean>(false);
  const [duplicateVINmsg, setDuplicateVINmsg] = useState('');

  const [accidentId, setAccidentId] = useState<string>('');
  const [inspectionId, setInspectionId] = useState<string>('');
  const [matchUnitType, setMatchUnitType] = useState<string>('');

  const params = useLocation();

  const {
    from = '',
    id = '',
    details = '',
  } = params.state
    ? (params.state as propState)
    : { details: '', from: '', id: '' };

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm<AddToFleetFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      dotFtBundleStatus: false,
      equipmentType: '',
      grossVehicleWeight: undefined,
      isPermanantRegistration: false,
      isSimplexCompletingFuelTaxes: '',
      lastAnnualInspectionDate: null,
      licensePlateNumber: '',
      licensePlateState: '',
      ownershipType: '',
      primaryDriverContactId: '',
      registration: [],
      registrationExpDate: null,
      registrationType: '',
      unitNumber: '',
      unitType: '',
      vehicleMake: '',
      vehicleYear: '',
      vinNumber: '',
      vqFile: [],
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(AddToFleetFormSchema),
  });

  useEffect(() => {
    if (userStore?.UserData?.role === 'Owner Operator') {
      setValue('ownershipType', 'Owner Operator');
      setIsOwnerOperator(true);
    } else {
      setIsOwnerOperator(false);
    }
  }, [userStore?.UserData?.role, setValue]);

  useEffect(() => {
    if (from === 'accidents') {
      setAccidentId(id);
    } else {
      setInspectionId(id);
      setMatchUnitType(
        details.equipmentType === 'power unit' ? 'powerUnit' : 'trailer',
      );
    }
    if (details) {
      setValue('equipmentType', details.equipmentType);
      setValue('vinNumber', details.vinNumber);
    }
  }, [details, id, from, setValue]);

  const getAddToFleetPrdefinedValues = useCallback(async () => {
    setLoading(true);
    await addToFleetStore.getPredefinedAddToFleetInfo();
    setLoading(false);
  }, [addToFleetStore, setLoading]);

  useEffect(() => {
    getAddToFleetPrdefinedValues();
  }, [getAddToFleetPrdefinedValues]);

  const resetUcr = useCallback(() => {
    reset();
    setVqFile([]);
    setRegistration([]);
    setBtnDisable(false);
    setFileValidation(false);
  }, [reset]);

  const getAllActivityList = useCallback(() => {
    refreshActivityListByStore(dotActivityListStore);
  }, [dotActivityListStore]);

  const filesAttachAPi = useCallback(
    async (bodyData: {
      unitId: string;
      caseId: string;
      documents: Array<any>;
    }) => {
      const getResp = await addToFleetStore.updateAttachFile(bodyData);
      setBtnDisable(false);
      if (getResp.isErr()) {
        enqueueSnackbar(getResp.error.message.toString(), SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
        resetUcr();
        getAllActivityList();
        setResetFormRequestType(Date.now());
        setNotification(true);
      }
    },
    [addToFleetStore, enqueueSnackbar, getAllActivityList, resetUcr],
  );

  const fileUploadApi = useCallback(
    async (data: AddToFleetFormInputs, unitId: string, caseId: string) => {
      const fileUploadReqs: any[] = [];

      const formData1 = new FormData();
      const fileData1: Record<string, unknown>[] = [];

      const state = getStateNameByStateId(
        authStore.statesListOption,
        data.licensePlateState,
      );

      data.vqFile.map((val: any) => {
        const obj: Record<string, string> = {
          'power unit': 'Vehicle Annual Inspection',
          trailer: 'Trailer Annual Inspection',
        };
        const fileType = obj[data.equipmentType] as FileType;

        const fileName = getFileNamingConvention[fileType].name({
          inspectionDate: data.lastAnnualInspectionDate || '',
        });
        formData1.append('files', val);
        fileData1.push({
          fileName,
          fileType: 'Vehicle Annual Inspection',
          service: 'Add to Fleet',
          type: 'unit',
          unit: {
            unitId,
          },
        });
      });

      if (formData1.has('files')) {
        formData1.append('data', JSON.stringify(fileData1));
        fileUploadReqs.push(fileStore.postFile(formData1));
      }

      const formData2 = new FormData();
      const fileData2: Record<string, unknown>[] = [];

      data.registration.map((val: any) => {
        const obj: Record<string, string> = {
          'power unit': 'CAB Card',
          trailer: 'Trailer Registration',
        };
        const fileType = obj[data.equipmentType] as FileType;
        const fileName =
          getFileNamingConvention[fileType]?.name({
            expirationDate: moment(data.registrationExpDate).format(
              'MM/DD/YYYY',
            ),
            isPermanantRegistration: data.isPermanantRegistration.toString(),
            registrationType: data.registrationType,
            state,
            unitNumber: data.unitNumber,
          }) || `${fileType}`;
        formData2.append('files', val);
        fileData2.push({
          fileName,
          fileType:
            data.equipmentType === 'power unit'
              ? 'CAB Card'
              : 'Trailer Registration',
          service: 'Add to Fleet',
          type: 'unit',
          unit: {
            unitId,
          },
        });
      });

      if (formData2.has('files')) {
        formData2.append('data', JSON.stringify(fileData2));
        fileUploadReqs.push(fileStore.postFile(formData2));
      }

      if (fileUploadReqs.length) {
        const fileUploadResults = await Promise.allSettled(fileUploadReqs);
        const bodyData: {
          unitId: string;
          caseId: string;
          documents: Array<any>;
        } = {
          caseId,
          documents: [],
          unitId,
        };
        fileUploadResults.forEach((fileRes: any, index: number) => {
          if (fileRes.isErr && fileRes.isErr()) {
            setBtnDisable(false);
            enqueueSnackbar('File upload failed', SnackBarConfig('e'));
          } else {
            // fileRes.value.value[0].index = index;
            bodyData.documents = [
              ...bodyData.documents,
              ...fileRes.value.value,
            ];
          }
        });
        filesAttachAPi(bodyData);
      }
    },
    [authStore.statesListOption, enqueueSnackbar, fileStore, filesAttachAPi],
  );

  const showDuplicateVinAlert = () => {
    setShowDuplicateVinDialog(true);
  };

  const onSubmit = useCallback(
    async (data: AddToFleetFormInputs) => {
      setBtnDisable(true);
      if (data.primaryDriverContactId === 'None') {
        data.primaryDriverContactId = '';
      }
      const bodyData: {
        equipment: any;
        accidentId?: string;
        inspectionId?: string;
        matchUnitType?: string;
      } = getBodyFormData(data);
      if (from && from === 'accidents') {
        bodyData.accidentId = accidentId;
      }
      if (from && from === 'inspections') {
        bodyData.inspectionId = inspectionId;
        bodyData.matchUnitType = matchUnitType;
      }
      const getResp = await addToFleetStore.createAddToFleet(bodyData);
      if (getResp.isErr()) {
        setBtnDisable(false);
        enqueueSnackbar(getResp.error.message.toString(), SnackBarConfig('e'));
      } else {
        const { caseId = '', message = '', unitId = '' } = getResp.value;
        if (message !== 'Unit Created Successfully!') {
          setDuplicateVINmsg(message);
        }
        if (!data.vqFile || !data.vqFile?.length) {
          enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
          resetUcr();
          getAllActivityList();
          setResetFormRequestType(Date.now());
          setNotification(true);
        }
        fileUploadApi(data, unitId ? unitId : '', caseId ? caseId : '');
      }
    },
    [
      addToFleetStore,
      enqueueSnackbar,
      fileUploadApi,
      getAllActivityList,
      resetUcr,
      setNotification,
      setResetFormRequestType,
      accidentId,
      from,
      inspectionId,
      matchUnitType,
    ],
  );
  const getBodyFormData = (data: AddToFleetFormInputs) => {
    data.registrationType =
      data.equipmentType === 'trailer' ? 'local' : data.registrationType;
    const bodyObj: any = Object.assign({}, data);
    delete bodyObj.vqFile;
    delete bodyObj.registration;

    // remove unwanted key value in Object
    if (bodyObj.isPermanantRegistration) {
      delete bodyObj.registrationExpDate;
    }
    if (data.equipmentType === EquipmentType.Trailer) {
      delete bodyObj.grossVehicleWeight;
    }
    // if (data.equipmentType === EquipmentType.Trailer) {
    //   delete bodyObj.registrationType;
    // }
    if (
      data.ownershipType === OwnershipType.Client &&
      !data.primaryDriverContactId
    ) {
      delete bodyObj.primaryDriverContactId;
    }
    return {
      equipment: {
        ...bodyObj,
        isSimplexCompletingFuelTaxes: bodyObj.dotFtBundleStatus
          ? bodyObj.isSimplexCompletingFuelTaxes === 'true'
            ? true
            : false
          : false,
        lastAnnualInspectionDate: moment(
          bodyObj.lastAnnualInspectionDate,
        ).format('MM/DD/YYYY'),
        ...(!bodyObj.isPermanantRegistration
          ? {
              registrationExpDate: moment(bodyObj.registrationExpDate).format(
                'MM/DD/YYYY',
              ),
            }
          : {}),
      },
    };
  };
  if (notification) {
    return (
      <SuccessNotification
        postReqMessage={duplicateVINmsg}
        title="Add to Fleet"
        handleNotification={() => {
          setNotification(false);
          setShowDuplicateVinDialog(false);
          setDuplicateVINmsg('');
        }}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return loading ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <Grid
      className={classes.paddingRight}
      container
      rowSpacing={2}
      columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
      <Grid item md={12} sm={12} xs={12}>
        <AddToFleetForm
          {...{
            addToFleetInfo: addToFleetStore,
            btnDisable,
            classes,
            control,
            errors,
            fileValidation,
            getValues,
            handleSubmit,
            isOwnerOperator: isOwnerOperator,
            onSubmit: showDuplicateVinAlert,
            registration,
            setRegistration,
            setValue,
            setVqFile,
            updateMethod: resetFormRequestType,
            vqFile,
            watch,
          }}
        />

        <div style={{ borderBottom: '1px solid #E8E8E8', marginTop: '20px' }} />
        <TaxPermitsButtons
          formId={'add-to-fleet'}
          disabled={btnDisable}
          formReset={resetUcr}
        />
      </Grid>
      <DuplicateVinAlert
        handleSubmit={() => onSubmit(getValues())}
        onBackdropClick={() => setShowDuplicateVinDialog(false)}
        open={showDuplicateVinDialog}
      />
    </Grid>
  );
};

export default observer(AddToFleet);
