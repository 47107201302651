import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

export class PrimaryDriversApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getList = async (params: any) => {
    const {
      PrimaryDrivers: { Endpoint, Method },
    } = ApiRoutes.Driver;

    const res = await this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      url: Endpoint,
    });
    if (res.isOk()) {
      return res.value;
    }
    return {};
  };
}
