import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { PrivateRoutes } from '../../../config';
import NavRoutes from '../../../config/routes/NavRoutes';
import { useCustomStore } from '../../../hooks';
import { FleetSearchFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import FleetHeader from './FleetHeader';
import FleetLists from './FleetLists';
import useStyles from './fleetStyle';

const Fleets: React.FC = () => {
  const { authStore, fleetStore, globalStore, todoStore } = useCustomStore();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const sortInfo = fleetStore.SortInfo;

  const [searchFleets] = useState<FleetSearchFormInputs>({
    simplexId: fleetStore.Filters.simplexId,
    unitNumber: fleetStore.Filters.unitNumber,
    unitType: fleetStore.Filters.unitType || '',
    vinNumber: fleetStore.Filters.vinNumber,
  });

  useEffect(() => {
    return () => fleetStore.setFleetApiReset();
  }, [fleetStore]);

  const getFleetsLists = useCallback(async () => {
    fleetStore.setShowLoader(true);
    const getFleetsResp = await fleetStore.fetchFleetsBySorting(false);
    if (getFleetsResp.isOk()) {
      fleetStore.setShowLoader(false);
    }
    if (getFleetsResp.isErr()) {
      fleetStore.setShowLoader(false);
      enqueueSnackbar(String(getFleetsResp.error.message), SnackBarConfig('e'));
    }
  }, [fleetStore, enqueueSnackbar]);

  const getSearchBasedFleets = useCallback(
    async (searchParams: FleetSearchFormInputs) => {
      if (
        !searchParams.vinNumber &&
        !searchParams.simplexId &&
        !searchParams.unitNumber &&
        !searchParams.unitType
      ) {
        fleetStore.setFleetApiReset(false);
        getFleetsLists();
        return;
      }
      setGetMoreLoader(true);
      const getEquipmentsResp = await fleetStore.fetchFleetsBySearchBased(
        searchParams,
      );
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      setGetMoreLoader(false);
    },
    [fleetStore, getFleetsLists, enqueueSnackbar],
  );

  const getMoreFleets = useCallback(async () => {
    if (
      searchFleets.unitNumber ||
      searchFleets.simplexId ||
      searchFleets.vinNumber ||
      searchFleets.unitType
    ) {
      getSearchBasedFleets(searchFleets);
      return;
    }
    setGetMoreLoader(true);
    const getEquipmentsResp = await fleetStore.fetchFleets();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [fleetStore, enqueueSnackbar, getSearchBasedFleets, searchFleets]);

  useEffect(() => {
    getFleetsLists();
  }, [getFleetsLists]);

  const navigateToTerminate = (fleet: any) => {
    fleetStore.setEquipmentFromFleet(fleet);
    navigate(
      `${PrivateRoutes.SERVICEREQUEST.TERMINATEEQUIPMENT as string}/${
        fleet.unitId as string
      }`,
    );
    globalStore.setCurrentRoute(
      `${PrivateRoutes.SERVICEREQUEST.TERMINATEEQUIPMENT as string}/${
        fleet.unitId as string
      }`,
    );
  };

  const handleClickOnViewTodos = (unitId = '') => {
    todoStore.setFilters({
      categories: [],
      dueIn: '',
      firstName: '',
      lastName: '',
      limit: 20,
      make: '',
      nextLink: '',
      simplexId: '',
      type: '',
      unitId,
      unitNumber: '',
      vinNumber: '',
      year: '',
    });
    todoStore.setSearchType('Fleet');
    navigate(`/${NavRoutes.PRIVATE.LEFT_NAV.TODOS}`, {
      state: {
        showBackBtn: true,
        todoType: 'Fleet',
        unitId,
      },
    });
  };

  const fleetHeader: { label: string; name: string }[] = [
    { label: 'Unit Type', name: 'unitType' },
    { label: 'Unit #', name: 'unitNumber' },
    { label: 'Simplex Unit #', name: 'simplexId' },
    { label: 'VIN', name: 'vinNumber' },
    { label: 'Ownership Type', name: 'ownershipType' },
    { label: 'Primary Driver', name: 'primaryDriver' },
    {
      label: 'Registration Expiration Date',
      name: '',
    },
    {
      label: 'Annual Inspection Expiration Date',
      name: 'annualInspectionExpiryDate',
    },
    { label: 'VQ Status', name: '' },
    { label: 'Documents', name: '' },
    { label: '', name: '' },
  ];

  const getActiveUnits = () => {
    return (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          letterSpacing: 0,
          lineHeight: '19px',
          padding: '1rem 1.5rem 2.5rem 1.5rem',
        }}>
        {fleetStore.Count.countPowerUnit} {fleetStore.Status} Power Units /{' '}
        {fleetStore.Count.countTrailer} {fleetStore.Status} Trailers
      </Typography>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {fleetStore.Status === 'Terminated' && sortInfo && (
        <Alert severity="error">{sortInfo}</Alert>
      )}
      {!fleetStore.ShowLoader && <Grid>{getActiveUnits()}</Grid>}

      {fleetStore.ShowLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          style={{ padding: '0 2rem' }}
          justifyContent="space-between">
          <TableContainer
            id="fleet-table"
            sx={{ maxHeight: 'calc(100vh - 200px)' }}>
            <InfiniteScroll
              dataLength={
                fleetStore.FleetList && fleetStore.FleetList.length
                  ? fleetStore.FleetList.length
                  : 0
              }
              style={{ overflow: 'unset' }}
              next={getMoreFleets}
              hasMore={fleetStore.HasMoreResults}
              scrollableTarget={'fleet-table'}
              loader={
                getMoreLoader && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                className={classes.table}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <FleetHeader
                    {...{
                      fleetHeader,
                      fleetStore,
                    }}
                  />
                </TableHead>
                <TableBody>
                  {fleetStore.FleetList && fleetStore.FleetList.length > 0 ? (
                    <FleetLists
                      {...{
                        authStore,
                        fleetLists: fleetStore.FleetList,
                        fleetStore: fleetStore,
                        onTerminate: navigateToTerminate,
                      }}
                      onClickViewTodos={handleClickOnViewTodos}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={10}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
    </Box>
  );
};

export default observer(Fleets);
