// Uncomment and use below function if necesary
// export const createSearchString = (searchParam: Record<string, any>) => {
//     const paramObj: any = {};
//     for (const key in searchParam) {
//       if (searchParam[key]) {
//         paramObj[key] = searchParam[key];
//       }
//     }
//     const searchParams = new URLSearchParams(paramObj);
//     const searchString = searchParams.toString();
//     return searchString;
//   };

export const createSearchQueryObject = (
  searchString: string,
  keysWithArraysAsValues: string[] = [],
) => {
  const searchParams = new URLSearchParams(searchString);
  const entries = searchParams.entries();

  const obj: Record<string, any> = {};
  for (const [key, value] of entries) {
    if (keysWithArraysAsValues.includes(key)) {
      obj[key] = value.split(',');
    } else obj[key] = value;
  }
  return obj;
};
