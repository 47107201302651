import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      // height: 'auto',
    },
  },
  divider: {
    border: '1px solid #E8E8E8',
  },
  dividerMargin: {
    marginBottom: '15px !important',
    marginTop: '31px !important',
  },
  equipmentCardDivider: {
    margin: '12px 0px !important',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  },
  gridheadingFlex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
  },
  selectClassName: {
    backgroundColor: '#f3f3f3 !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    // opacity: '0.43 !important',
  },
  uploadDoc: {
    '& span': {
      marginLeft: '3px',
    },
    alignItems: 'end',
    color: '#0061FF',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-SemiBold',
    fontSize: '0.875rem',
    fontWeight: 600,
    textDecoration: 'underLine',
  },
}));

export default useStyles;
