import { makeAutoObservable } from 'mobx';

import { RoadTaxPermitsFormInputs } from '../types';
import { RootStore } from '.';

export class RoadTaxesStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  async createRoadTaxes(data: RoadTaxPermitsFormInputs) {
    const response =
      await this.rootStore.apiStore.roadTaxesApiStore.createRoadTaxes(data);
    return response;
  }
}
