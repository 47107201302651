import InfoIcon from '@mui/icons-material/Info';
import {
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { PrivateRoutes } from '../../../../../config';
import { useCustomStore } from '../../../../../hooks';
import { DataQChallenge } from '../../../../../types';
import { refreshActivityListByStore } from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import useStyles from './DataQChallengeStyles';

const DataQChallengeForm: React.FC = () => {
  const classes = useStyles();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [initialLoader, setInitialLoader] = useState<boolean>(false);
  const [initialOptions, setInitialOptions] = useState<Array<any>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { dataQchallengeStore, userStore, dotActivityListStore } =
    useCustomStore();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    reset: resetDataQ,
  } = useForm<DataQChallenge>({
    criteriaMode: 'all',
    defaultValues: {
      dataQChallenges: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    fields: optionfields,
    // append: optionappend,
    replace: optionreplace,
    update: optionUpdate,
  } = useFieldArray({
    control,
    name: 'dataQChallenges',
  });

  const getInitialData = useCallback(async () => {
    setInitialLoader(true);
    const initialData = await dataQchallengeStore.getInitialData();
    setInitialApiReponse(initialData);
    setInitialLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialApiReponse = (initialData: any) => {
    if (initialData.isOk()) {
      const dataQChallenges: any = initialData.value;
      if (dataQChallenges && Array.isArray(dataQChallenges)) {
        setInitialFormData(dataQChallenges);
        setInitialOptions(dataQChallenges);
      }
    }

    if (initialData.isErr()) {
      const {
        error: { message = null },
      } = initialData;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        const messages: any = {};
        Object.keys(message).forEach((key) => {
          messages[key] = message[key].message;
        });

        Object.keys(messages).forEach((msg: string) => {
          const key: string = msg;
          const value: string = messages[msg];
          enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
        });
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
  };

  const setInitialFormData = useCallback((dataQChallengeData: any) => {
    optionreplace(dataQChallengeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = useCallback(() => {
    resetDataQ();
    dataQchallengeStore.resetDataQchallengeStore();
    // driverListStore.setSelectedDriver({});
    navigate(PrivateRoutes.SERVICEREQUEST.DATAQCHALLENGE);
    setInitialFormData(initialOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllActivityList = useCallback(async () => {
    await refreshActivityListByStore(dotActivityListStore);
  }, [dotActivityListStore]);

  const submit = useCallback(async () => {
    setBtnDisable(true);
    const createResp = await dataQchallengeStore.addDataQChallenge(
      userStore.UserData && userStore.UserData,
    );

    if (createResp.isOk()) {
      enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
      getAllActivityList();
      resetForm();
    }
    if (createResp.isErr()) {
      const {
        error: { message = null },
      } = createResp;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
    setBtnDisable(false);
  }, [
    dataQchallengeStore,
    userStore.UserData,
    enqueueSnackbar,
    getAllActivityList,
    resetForm,
  ]);
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return initialLoader ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <Grid container spacing={0.5} minHeight={'100%'}>
      <Grid item md={12} sm={12} xs={12}>
        <form
          style={{
            width: '100%',
          }}
          id={'data-q-challenge'}
          onSubmit={handleSubmit(submit)}>
          <div>
            <Typography className={classes.bigLabel}>
              Select which option(s) you want to challenge.
            </Typography>
            <Grid container>
              <Grid item md={4} className={classes.addflex}>
                <Typography
                  className={classes.dtitle}
                  style={{ paddingLeft: '3.5rem' }}>
                  Basic
                </Typography>{' '}
              </Grid>
              <Grid item md={1} className={classes.addflex}>
                <Typography className={classes.dtitle}>
                  Out of Service
                </Typography>
              </Grid>
              <Grid item md={2} className={classes.addflex}>
                <Typography className={classes.dtitle}>
                  Convicted of a Different Charge
                </Typography>
              </Grid>
              <Grid item md={1} className={classes.addflex}>
                <Typography className={classes.dtitle}>
                  Violation Severity Weight
                </Typography>
              </Grid>
              <Grid item md={1} className={classes.addflex}>
                <Typography className={classes.dtitle}>Time Weight</Typography>
              </Grid>
              <Grid item md={2} className={classes.addflex}>
                <Typography className={classes.dtitle}>
                  Basic Violations per Inspection
                </Typography>
              </Grid>
              <Grid item md={1} className={classes.addflex}>
                <Typography className={classes.dtitle}>Unit</Typography>
              </Grid>
            </Grid>
            <List className={classes.stateStatus}>
              {optionfields.map((field: any, index: number) => {
                return (
                  <ListItem key={index}>
                    <Grid container className={classes.borderBox}>
                      <Grid item md={4}>
                        <Typography
                          component={'div'}
                          style={{
                            display: 'flex',
                            padding: '0px 10px 0px 15px',
                          }}>
                          <label className="main">
                            <Controller
                              control={control}
                              name={`dataQChallenges.${index}.selected`}
                              render={({ field: fieldchk }) => {
                                return (
                                  <input
                                    type="checkbox"
                                    {...fieldchk}
                                    onChange={(event: any) => {
                                      fieldchk.onChange(event.target.checked);
                                      dataQchallengeStore.setDataQChallengesCheckToggle(
                                        index,
                                        event.target.checked,
                                        field.id,
                                      );
                                      optionUpdate(index, {
                                        ...field,
                                        selected: event.target.checked,
                                      });
                                    }}
                                    checked={field.selected}
                                    disabled={false}
                                    tabIndex={0}
                                  />
                                );
                              }}
                            />
                            <span className="geekmark" />
                          </label>
                          <Typography component={'div'}>
                            <Typography
                              component={'div'}
                              className={classes.title}>
                              Driver Fitness{' '}
                              <Typography
                                component={'span'}
                                style={{
                                  color: '#979598',
                                  fontFamily: 'FiraSans-Regular',
                                  fontSize: '15px',
                                  letterSpacing: 0,
                                }}>
                                {getValues(`dataQChallenges.${index}.code`) ||
                                  ''}
                              </Typography>
                            </Typography>
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography
                          component={'div'}
                          className={classes.noText}>
                          No
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography
                          component={'div'}
                          className={classes.noText}>
                          No
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography
                          component={'div'}
                          className={classes.noText}>
                          4
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography
                          component={'div'}
                          className={classes.noText}>
                          2
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography
                          component={'div'}
                          className={classes.noText}>
                          1
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography
                          component={'div'}
                          className={classes.noText}>
                          D
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          component={'div'}
                          style={{
                            paddingLeft: '3.1rem',
                            paddingTop: '0.5rem',
                          }}>
                          General Driver Qualification
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Typography
                          component={'div'}
                          style={{ paddingLeft: '3.1rem' }}>
                          Description:{' '}
                          {getValues(`dataQChallenges.${index}.description`) ||
                            ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </form>
        <Typography component={'div'} className={classes.infobox}>
          <InfoIcon className={classes.infoicon} />
          Someone from Home Office will contact you about… Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industrys standard dummy text ever since the 1500s, when an
          unknown printer. Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industrys standard
          dummy text ever since the 1500s, when an unknown printer.
        </Typography>
        <Divider className={classes.divider} />
        <TaxPermitsButtons
          formId={'data-q-challenge'}
          disabled={btnDisable}
          formReset={resetForm}
        />
      </Grid>
    </Grid>
  );
};

export default observer(DataQChallengeForm);
