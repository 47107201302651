import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';

type FormInputTextProps = {
  errors?: string;
  defaultValue?: string;
  value: any;
  disabled?: boolean;
  tabIndexVal?: number;
  onChangeText?: (src: string) => void;
  onBlurFn?: (src: any) => void;
  isEditable?: boolean;
  name: string;
  isRefreshValue?: boolean;
  isFormSubmittedOnce?: boolean;
  inputRefObj?: React.Ref<any>;
  optionsList: Array<{
    label: string;
    value: string;
    disabled?: boolean;
    optionClassName?: any;
  }>;
  selectClassName?: string;
  iconsClassName?: string;
  placeHolder?: string;
  size?: 'small' | 'medium';
  sortByOptionDisable?: boolean;
  defaultOptionLabel?: string;
};

export const SelectInput = observer(
  (props: FormInputTextProps): ReactElement => {
    const {
      errors,
      defaultValue,
      value,
      tabIndexVal = 0,
      onChangeText,
      onBlurFn,
      isEditable = false,
      name,
      isRefreshValue = false,
      isFormSubmittedOnce,
      inputRefObj,
      optionsList,
      selectClassName,
      iconsClassName,
      placeHolder,
      size = 'medium',
      sortByOptionDisable = false,
      defaultOptionLabel,
      disabled,
    } = props;

    const classes = useStyles();
    const [inputVal, setInputVal] = React.useState(value);
    const [showLocalError, setShowLocalError] = React.useState(true);

    const onValueChange = React.useCallback(
      (val: string) => {
        setInputVal(val);
        setShowLocalError(!val);
        onChangeText && onChangeText(val);
      },
      [onChangeText],
    );

    const onBlur = (event: any) => {
      setShowLocalError(true);
      if (onBlurFn) {
        onBlurFn(event);
      }
    };

    React.useEffect(() => {
      if (isRefreshValue && inputVal !== value) {
        setInputVal(value);
        setShowLocalError(!value);
      }
    }, [inputVal, value, isRefreshValue]);

    const showError = isFormSubmittedOnce || showLocalError;

    return (
      <>
        <Select
          value={inputVal}
          size={size ? size : undefined}
          placeholder={placeHolder ? placeHolder : 'Select'}
          name={name}
          classes={iconsClassName ? { icon: iconsClassName } : {}}
          className={selectClassName}
          defaultValue={defaultValue}
          inputProps={{
            tabIndex: tabIndexVal,
          }}
          inputRef={inputRefObj}
          disabled={disabled != undefined ? disabled : isEditable}
          error={showError ? (errors ? true : false) : false}
          fullWidth
          onBlur={(event: any) => {
            onBlur(event);
          }}
          onChange={(event: any) => {
            onValueChange(event.target.value);
          }}
          native>
          {/* {optionsList} */}
          {
            <>
              <option
                value={''}
                disabled
                className={
                  optionsList &&
                  optionsList.length &&
                  optionsList[0].optionClassName
                    ? optionsList[0].optionClassName
                    : classes.optionsClassName
                }>
                {defaultOptionLabel ? defaultOptionLabel : 'Select'}
              </option>
              {optionsList &&
                optionsList.length &&
                (sortByOptionDisable
                  ? optionsList
                  : optionsList.sort((a, b) =>
                      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
                    )
                ).map((ele, index) => (
                  <option
                    key={ele.value}
                    value={ele.value}
                    className={
                      ele.optionClassName
                        ? ele.optionClassName
                        : classes.optionsClassName
                    }
                    disabled={ele.disabled}>
                    {ele.label}
                  </option>
                ))}
            </>
          }
        </Select>
        {errors && (
          <FormHelperText className={classes.helperText}>
            {errors}
          </FormHelperText>
        )}
      </>
    );
  },
);

const useStyles = makeStyles((theme) =>
  createStyles({
    helperText: {
      color: `${theme.palette.warning.main} !important`,
      marginLeft: 'unset !important',
      position: 'relative',
    },
    optionsClassName: {
      backgroundColor: `${theme.palette.common.white} !important`,
      paddingLeft: '10px !important',
      paddingRight: '27px !important',
      textTransform: 'capitalize',
    },
  }),
);
