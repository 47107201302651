import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

export class DriverOwnerNotificationApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  getInitialData() {
    const {
      Get: { Endpoint, Method },
    } = ApiRoutes.Notification;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint,
    });
  }
}
