import { styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCustomStore } from '../../../hooks';

const Article = styled('article')(() => ({
  borderLeft: '#c0c0c0 1px solid',
  justifyContent: 'center',
  marginLeft: 10,
  paddingLeft: 20,
}));

const List = styled('ul')(() => ({
  fontSize: 13,
  listStyle: 'none',
  margin: 0,
  marginTop: 5,
  padding: 0,
}));

const ListItem = styled('li')(() => ({
  marginRight: 25,
}));

const DataType = styled('b')(() => ({ textTransform: 'capitalize' }));

const unitKeys = [
  {
    key: 'type',
    label: 'Power Unit Type',
  },
  {
    key: 'year',
    label: 'Year',
  },
  {
    key: 'make',
    label: 'Make',
  },
  {
    key: 'licenseState',
    label: 'License State',
  },
  {
    key: 'licenseNumber',
    label: 'License',
  },
];

const driverKeys = [
  {
    key: 'dateOfBirth',
    label: 'DOB',
  },
  {
    key: 'licenseState',
    label: 'License State',
  },
  {
    key: 'licenseNumber',
    label: 'License',
  },
  {
    key: 'licenseExpirationDate',
    label: 'License Expiration',
  },
];

const TrendInfo: React.FC = () => {
  const {
    trendsStore: { trendInfo = {} },
  } = useCustomStore();

  const [searchParams] = useSearchParams();

  const dataType = searchParams.get('range');
  const vinNumber = searchParams.get('vin');
  const fullName = searchParams.get('driver');

  return (
    <Article className="flex-column">
      <section className="flex-row">
        {vinNumber && <h4>VIN {vinNumber}</h4>}
        {fullName && <h4>{fullName}</h4>}
        {dataType && (
          <h4>
            {vinNumber || fullName ? <>&nbsp; -</> : ''}{' '}
            <DataType>{dataType}</DataType>
          </h4>
        )}
      </section>
      {dataType === 'matched' && (
        <List className="flex-row">
          {vinNumber &&
            unitKeys.map((unit, index) => (
              <ListItem key={index}>
                <p>
                  <b>{unit.label}</b> : {trendInfo?.[unit.key] || '-'}
                </p>
              </ListItem>
            ))}
          {fullName &&
            driverKeys.map((unit, index) => (
              <ListItem key={index}>
                <p>
                  <b>{unit.label}</b> : {trendInfo?.[unit.key] || '-'}
                </p>
              </ListItem>
            ))}
        </List>
      )}
    </Article>
  );
};

export default observer(TrendInfo);
