import { CircularProgress, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import SimplexCard from '../../../components/SimplexCard';
import { useCustomStore } from '../../../hooks';
import { Div } from './styles';

type Details = {
  primaryDriver?: any;
  secondaryDriver?: any;
  truckInfo?: any;
  trailerInfo?: any;
};

type Props = {
  id?: string;
  data?: Record<string, Record<string, any>>;
  showDriverInfo: boolean;
  showEquipmentInfo: boolean;
};

const primaryDriverLabels = ['Full Name', 'License State', 'DOB', 'Age'];
const primaryDriverKeys: string[] = [
  'fullName',
  'licenseStateId',
  'dob',
  'age',
];
const truckInfoLabels = [
  'Power Unit Type',
  'Make',
  'License',
  'License State',
  'VIN',
];
const truckInfoKeys: string[] = [
  'unitType',
  'make',
  'licenseNumber',
  'licenseStateId',
  'vinNumber',
];
const trailerInfoLabels = [
  'Trailer Type',
  'Make',
  'License',
  'License State',
  'VIN',
];
const trailerInfoKeys: string[] = [
  'unitType',
  'make',
  'licenseNumber',
  'licenseStateId',
  'vinNumber',
];
const convertObjectToArray = (obj: any) => {
  const values: any[] = Object.values(obj);
  const keys = Object.keys(obj);
  return keys.map((key, index) => ({
    label: values[index].label,
    value: values[index].value || '-',
  }));
};

const MoreDetails: React.FC<Props> = ({
  id = '',
  data = {},
  showDriverInfo,
  showEquipmentInfo,
}) => {
  const {
    violationsStore: { getMoreDetailsByViolationId },
    authStore,
  } = useCustomStore();
  const { statesListOption } = authStore;

  const [details, setDetails] = useState<Details>();
  const [showLoader, setShowLoader] = useState<boolean>();

  const getValueByKeys = (keys: any[], labels: string[], data: any) =>
    keys.reduce(
      (acc: any, key_, index: number) => ({
        ...acc,
        [key_]: { label: labels[index], value: data[key_] || '-' },
      }),
      {},
    );
  useEffect(() => {
    (async () => {
      if (details) {
        return;
      }
      setShowLoader(true);
      const {
        primaryDriver = {},
        secondaryDriver = {},
        trailerInfo = {},
        truckInfo = {},
      } = (id && (await getMoreDetailsByViolationId(id))) || data;
      setShowLoader(false);

      const primaryDriver_ = getValueByKeys(
        primaryDriverKeys,
        primaryDriverLabels,
        primaryDriver,
      );
      const secondaryDriver_ = getValueByKeys(
        primaryDriverKeys,
        primaryDriverLabels,
        secondaryDriver,
      );

      const truckInfo_ = getValueByKeys(
        truckInfoKeys,
        truckInfoLabels,
        truckInfo,
      );
      const trailerInfo_ = getValueByKeys(
        trailerInfoKeys,
        trailerInfoLabels,
        trailerInfo,
      );
      setDetails({
        primaryDriver: primaryDriver_,
        secondaryDriver: secondaryDriver_,
        trailerInfo: trailerInfo_,
        truckInfo: truckInfo_,
      });
    })();
  }, [data, details, id, getMoreDetailsByViolationId, statesListOption]);

  return (
    <Stack spacing={0.5}>
      {!showLoader && showDriverInfo && (
        <>
          {details?.primaryDriver && (
            <SimplexCard
              title="Primary Driver"
              data={convertObjectToArray(details.primaryDriver)}
            />
          )}
          {details?.secondaryDriver && (
            <SimplexCard
              title="Secondary Driver"
              data={convertObjectToArray(details.secondaryDriver)}
            />
          )}
        </>
      )}
      {!showLoader && showEquipmentInfo && (
        <>
          {details?.truckInfo && (
            <SimplexCard
              title="Truck Info"
              data={convertObjectToArray(details.truckInfo)}
            />
          )}
          {details?.trailerInfo && (
            <SimplexCard
              title="Trailer Info"
              data={convertObjectToArray(details.trailerInfo)}
            />
          )}
        </>
      )}
      {showLoader && (
        <Div>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </Div>
      )}
    </Stack>
  );
};

export default observer(MoreDetails);
