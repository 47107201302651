import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { InputText } from '../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../components/Forms/selectInput';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore, UseFocus, useMountEffect } from '../../../../../hooks';
import { TodoRequest, UcrFormInputs, UnitOptions } from '../../../../../types';
import { getTaxPermitsActivityList } from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { UcrFormSchema } from '../../../../../utils/ValidatorSchema';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import useStyles from './UcrStyles';

const TaxPermitsUcr: React.FC = () => {
  const { ucrStore, activityListStore }: any = useCustomStore();
  const classes = useStyles();
  const location = useLocation();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [btnDisableInitialLoader, setBtnDisableInitialLoader] =
    useState<boolean>(true);
  const [initalLoader, setInitalLoader] = useState<boolean>(true);
  const [notification, setNotification] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const unitOptions: UnitOptions[] = [
    { disabled: false, title: '0-2 units', value: '0-2' },
    { disabled: false, title: '3-5 units', value: '3-5' },
    { disabled: false, title: '6-20 units', value: '6-20' },
    { disabled: false, title: '21-100 units', value: '21-100' },
  ];

  const currentYear: number = moment().year();
  const previousYear: number = moment().year() - 1;
  const nextYear: number = moment().year() + 1;

  const permitYearOptions: UnitOptions[] = [
    {
      disabled: false,
      title: String(previousYear),
      value: String(previousYear),
    },
    { disabled: false, title: String(currentYear), value: String(currentYear) },
    { disabled: false, title: String(nextYear), value: String(nextYear) },
  ];

  // validation
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<UcrFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      expirationDate: '',
      permitYear: '',
      type: ucrStore.getUcrUnit,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(UcrFormSchema),
  });

  const getInitialData = useCallback(async () => {
    setBtnDisableInitialLoader(false);
    setInitalLoader(true);
    const responseinitial = await ucrStore.getInitialData();
    setBtnDisableInitialLoader(true);
    setInitialFormData(responseinitial);
    setInitalLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialFormData = (responseinitial: any) => {
    if (responseinitial.isOk()) {
      const {
        value: { expirationDate = '' },
      } = responseinitial;

      setValue('expirationDate', expirationDate);
    }

    if (responseinitial.isErr()) {
      const {
        error: { message = null },
      } = responseinitial;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        const messages: any = {};
        Object.keys(message).forEach((key) => {
          messages[key] = message[key].message;
        });

        Object.keys(messages).forEach((msg: string) => {
          const key: string = msg;
          const value: string = messages[msg];

          enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
        });
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
  };

  const submit = useCallback(async (data: UcrFormInputs) => {
    setBtnDisable(true);
    let todoID = '';
    if (location && location.state) {
      const { todoId } = location.state as TodoRequest;
      todoID = todoId;
    }
    const res = await ucrStore.addUcrRecord(todoID);
    setBtnDisable(false);
    if (res.isOk()) {
      enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
      activityListRecall();
      reset();
      setNotification(true);
    } else {
      let message: any = 'Error while processing data';
      if (typeof res.error.message === 'string') {
        message = res.error.message;
      }
      if (typeof res.error.message === 'object') {
        message = 'Validation Error';
      }
      enqueueSnackbar(message, SnackBarConfig('e'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  const resetUcr = () => {
    reset();
    ucrStore.resetUcr();
  };

  const [input1Ref, setInput1Focus] = UseFocus();

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMountEffect(setInput1Focus);

  if (notification) {
    return (
      <SuccessNotification
        title="UCR"
        handleNotification={() => {
          getInitialData();
          setNotification(false);
        }}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return initalLoader ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <Grid container spacing={0.5} minHeight={'100%'}>
      <Grid item md={12} sm={12} xs={12}>
        <form
          style={{
            width: '100%',
          }}
          id={'ucr-form'}
          onSubmit={handleSubmit(submit)}>
          <FormControl variant="outlined" fullWidth size="small">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
              <Grid item md={12} sm={12} xs={12}>
                <Box className={classes.formLabelClass}>
                  Select the range of units *
                </Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="type"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={input1Ref}
                        errors={errors.type?.message || ''}
                        name={name}
                        size={'small'}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(val: string) => {
                          onChange(val);
                          ucrStore.setUcrDetail('units', val);
                          // ucrStore.setUcrUnit(val);
                        }}
                        isEditable={false}
                        selectClassName={classes.selectClassName}
                        sortByOptionDisable={true}
                        optionsList={unitOptions.map(
                          ({ value, disabled, title }) => ({
                            disabled,
                            label: title,
                            value,
                          }),
                        )}
                        // {
                        //   <>
                        //     {unitOptions.map(
                        //       ({ value, disabled, title }, index) => (
                        //         <option
                        //           value={value}
                        //           key={index}
                        //           className={classes.optionsClassName}
                        //           disabled={disabled}>
                        //           {title}
                        //         </option>
                        //       ),
                        //     )}
                        //   </>
                        // }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}
              style={{ paddingTop: 10 }}>
              <Grid item md={6} sm={12} xs={12}>
                <Box className={classes.labels}>
                  Previous permit expiration date
                </Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="expirationDate"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.expirationDate?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(value) => {
                          onChange(value);
                        }}
                        isEditable={true}
                        className={classes.selectClassName}
                        type={'text'}
                        variant="standard"
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Box className={classes.labels}>Permit year</Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="permitYear"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.permitYear?.message || ''}
                        name={name}
                        size={'small'}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(val: string) => {
                          onChange(val);
                          ucrStore.setUcrDetail('permitYear', val);
                        }}
                        isEditable={false}
                        selectClassName={classes.selectClassName}
                        sortByOptionDisable={true}
                        optionsList={permitYearOptions.map(
                          ({ value, disabled, title }) => ({
                            disabled,
                            label: title,
                            value,
                          }),
                        )}
                        // {
                        //   <>
                        //     {permitYearOptions.map(
                        //       ({ value, disabled, title }, index) => (
                        //         <option
                        //           value={value}
                        //           key={index}
                        //           className={classes.optionsClassName}
                        //           disabled={disabled}>
                        //           {title}
                        //         </option>
                        //       ),
                        //     )}
                        //   </>
                        // }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        </form>
        <Divider className={classes.divider} />
        <TaxPermitsButtons
          formId={'ucr-form'}
          disabled={btnDisable}
          formReset={resetUcr}
          disableLoader={btnDisableInitialLoader}
        />
      </Grid>
    </Grid>
  );
};

export default observer(TaxPermitsUcr);
