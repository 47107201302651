import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import moment from 'moment';
import React, { useState } from 'react';

import useStyles from '../IRPServiceStyles';

const Error = styled(Typography)(() => ({
  color: 'red',
  fontSize: 12,
  fontWeight: 'bold',
}));

const Schedule1Form: React.FC<{ onChange: (value: string) => void }> = ({
  onChange,
}) => {
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState<string>('-');

  const currentYear = moment().year();
  const years = [currentYear, currentYear + 1];
  return (
    <Grid container>
      <Grid item md={4} sm={4} xs={12}>
        <Box className={classes.formLabelClass}>
          Specify Road Tax Filing Period*
        </Box>
        <Select
          error={true}
          value={selectedYear}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '-') return;
            setSelectedYear(e.target.value);
            onChange(value);
          }}>
          <MenuItem selected key={-1} value="-">
            Select
          </MenuItem>
          {years.map((option: any, index: number) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {selectedYear === '-' && <Error> Required </Error>}
      </Grid>
    </Grid>
  );
};

export default Schedule1Form;
