import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import { format } from 'date-fns';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SelectInput } from '../../../../../components/Forms/selectInput';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { AllowedUploadMimeTypes } from '../../../../../config/Constants';
import { useCustomStore, UseFocus, useMountEffect } from '../../../../../hooks';
import { FTQuarterFilling, TodoRequest } from '../../../../../types';
import { getTaxPermitsActivityList } from '../../../../../utils/helper';
// import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
// import { useSnackbar } from 'notistack';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { FTQuarterFillingSchema } from '../../../../../utils/ValidatorSchema';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
// import FilesList from '../../components/FilesList/FilesList';
import FilesList from '../../components/FilesList/FilesList';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import TaxPermitsEquipmentHeader from '../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';
import FTQuarterFillingForms from './FTQuarterFillingForms/FTQuarterFillingForm';
import useStyles from './FTQuaterFillingStyles';

const FTQuarterFillingComp: React.FC = () => {
  const [intialStatePermits, setInitialStatePermits] = useState<Array<any>>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [btnDisableInitialLoader, setBtnDisableInitialLoader] =
    useState<boolean>(true);
  const [initialLoader, setInitialLoader] = useState<boolean>(false);
  const [notification, setNotification] = useState(false);
  const [fileType, setFileType] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [searchParams] = useSearchParams();

  // const [triggerInitialApi, setTriggerInitialApi] =
  // useState<boolean>(true);

  const [yearOption, setYearOption] = useState<Array<number>>([]);
  const { ftQuarterFillingStore, activityListStore, todoStore }: any =
    useCustomStore();
  const inputRef = React.createRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const location = useLocation();

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    // reset:newFormRest,
  } = useForm<FTQuarterFilling>({
    criteriaMode: 'all',
    defaultValues: {
      equipments: [],
      fuelCardReports: [],
      quarter: '',
      statePermits: [],
      year: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(FTQuarterFillingSchema),
  });

  const {
    fields: equimentfields,
    // append: equipmentappend,
    remove: equipmentremove,
    replace: equipmentreplace,
  } = useFieldArray({
    control: control,
    name: 'equipments',
  });

  // const {
  //   fields:statefields,
  //   append:stateappend,
  //   remove:stateremove} = useFieldArray({
  //   control:control,
  //   name:'statePermits'
  // });

  const {
    fields: statefields,
    // append: stateappend,
    replace: statereplace,
    update: stateUpdate,
  } = useFieldArray({
    control,
    name: 'statePermits',
  });

  useEffect(() => {
    const quarter = searchParams.get('quarter');
    const year = searchParams.get('year');
    if (quarter) setValue('quarter', quarter.toLocaleLowerCase());
    else setValue('quarter', '');
    if (year) setValue('year', year);
    else setValue('year', '');
  }, [searchParams, setValue]);

  useEffect(() => {
    const date: any = new Date();
    const years: any = [date.getFullYear() - 1, date.getFullYear()];
    setYearOption(years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = useCallback(async () => {
    setBtnDisableInitialLoader(false);
    setInitialLoader(true);
    const initialData = await ftQuarterFillingStore.getInitialData();

    setBtnDisableInitialLoader(true);
    setInitialLoader(false);

    setInitialApiReponse(initialData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialApiReponse = (initialData: any) => {
    if (initialData.isOk()) {
      let {
        value: { statePermits },
      } = initialData;
      if (statePermits && Array.isArray(statePermits)) {
        statePermits = statePermits.map((e) => ({
          ...e,
          disablestatus: e.selected,
        }));
        setInitialFormData(statePermits);
        setInitialStatePermits(statePermits);
      }
    }

    if (initialData.isErr()) {
      const {
        error: { message = null },
      } = initialData;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        const messages: any = {};
        Object.keys(message).forEach((key) => {
          messages[key] = message[key].message;
        });

        Object.keys(messages).forEach((msg: string) => {
          const key: string = msg;
          const value: string = messages[msg];
          enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
        });
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }
  };

  const setInitialFormData = useCallback((statePermitData: any) => {
    statereplace(statePermitData);

    setValue('equipments', []);
    setValue('fuelCardReports', []);
    // setValue('quarter', '');
    // setValue('year', '');

    let data = todoStore.EquipmentFromTodo;

    if (data && data.unit) {
      data = toJS(data.unit);
      data = [data];
      selectedEquipmentList(data);
      todoStore.setEquipmentFromTodo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuHandleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (selectFileType: string) => {
    if (selectFileType) {
      inputRef.current?.click();
    }
    setFileType(selectFileType);
    setAnchorEl(null);
  };

  const cancel = () => {
    setInitialFormData(intialStatePermits);
    ftQuarterFillingStore.resetFTQuarterFillingStore();
  };

  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  const submit = useCallback(async (data: FTQuarterFilling) => {
    if (
      ftQuarterFillingStore.ftQuarterFillingDetails &&
      ftQuarterFillingStore.ftQuarterFillingDetails.equipments &&
      ftQuarterFillingStore.ftQuarterFillingDetails.equipments.length
    ) {
      setBtnDisable(true);
      let todoID = '';
      if (location && location.state) {
        const { todoId } = location.state as TodoRequest;
        todoID = todoId;
      }
      const addResponse = await ftQuarterFillingStore.addFTQuarterFilling(
        todoID,
      );
      setBtnDisable(false);

      if (addResponse.isOk()) {
        enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
        activityListRecall();
        getInitialData();
        setNotification(true);
      }

      if (addResponse.isErr()) {
        const {
          error: { message = null },
        } = addResponse;
        if (typeof message === 'string') {
          enqueueSnackbar(
            message || 'Error while processing data',
            SnackBarConfig('e'),
          );
        } else if (typeof message === 'object') {
          // const messages: any = {};
          // Object.keys(message).forEach((key) => {
          //   messages[key] = message[key].message;
          // });

          // Object.keys(messages).forEach((msg: string) => {
          //   const key: string = msg;
          //   const value: string = messages[msg];
          //   enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
          // });

          enqueueSnackbar('Validation Error', SnackBarConfig('e'));
        } else {
          enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedEquipmentList = (equipmentList: any) => {
    equipmentList = equipmentList.map((e: any) => ({
      ...toJS(e),
      files: [],
    }));

    const equipmentNewDatas: any[] = [];
    const equipmentOldTerminateDatas: any[] = [...getValues('equipments')];

    equipmentList.forEach((equipment: any) => {
      const findedResult: any = equipmentOldTerminateDatas.findIndex(
        (e: any) =>
          e.unitId === equipment.unitId &&
          e.vinNumber === equipment.vinNumber &&
          e.simplexId === equipment.simplexId,
      );

      if (findedResult === -1) {
        equipmentNewDatas.push(equipment);
      } else {
        equipmentOldTerminateDatas.splice(findedResult, 1);
      }
    });

    const modifyEquipmentDetails: any = [...getValues('equipments')];
    if (equipmentOldTerminateDatas.length) {
      equipmentOldTerminateDatas.forEach((equipment: any) => {
        const findRemoveIndex: number = modifyEquipmentDetails.findIndex(
          (e: any) =>
            e.unitId === equipment.unitId &&
            e.vinNumber === equipment.vinNumber &&
            e.simplexId === equipment.simplexId,
        );
        if (findRemoveIndex !== -1) {
          modifyEquipmentDetails.splice(findRemoveIndex, 1);
        }
      });
    }

    if (equipmentNewDatas.length) {
      modifyEquipmentDetails.push(...equipmentNewDatas);
    }

    equipmentreplace(modifyEquipmentDetails);
    ftQuarterFillingStore.addEquipmentDetail(
      equipmentNewDatas,
      equipmentOldTerminateDatas,
    );
  };

  const onChangeFuelReport = (e: any) => {
    let fileArr: any[] = Array.from(e.target.files);

    const findExcededFile: any = fileArr.find(
      (ef) => Number((ef.size / 1024 / 1024).toFixed(2)) > 15,
    );
    if (findExcededFile) {
      enqueueSnackbar('File mus not exceed 15 mb', SnackBarConfig('e'));
      return;
    }

    fileArr = fileArr.map((file: any) => {
      return {
        file,
        type: fileType,
      };
    });

    const existedFuelReportData: any = getValues('fuelCardReports');
    existedFuelReportData.push(...fileArr);

    setValue('fuelCardReports', existedFuelReportData);
    ftQuarterFillingStore.addFuelCardDetail(fileArr);
    e.target.value = '';
  };

  const removeEquimentDetail = (equipment: any) => {
    const findIndexEquipment = getValues('equipments').findIndex(
      (e) => e.unitId === equipment.unitId,
    );
    if (findIndexEquipment !== -1) {
      equipmentremove(findIndexEquipment);
      ftQuarterFillingStore.removeEquipmentDetail(findIndexEquipment);
    }
  };

  const modifyFileList = (e: any) => {
    const data = {
      name: '',

      size: 0,
      // date: '',
      type: '',
    };

    function sizeDefiner(size: any) {
      return Number((size / 1024 / 1024).toFixed(2));
    }

    // function datedefiner(file: any) {
    //   return format(file.lastModified, 'dd-MM-yyyy');
    // }

    if (e.file.name) {
      data.name = e.file.name;
    }

    if (e.file.size) {
      data.size = sizeDefiner(e.file.size);
    }

    // if (e.lastModified) {
    //   data.date = datedefiner(e);
    // }

    if (e.type) {
      data.type = e.type;
    }

    return data;
  };

  const onClearFile = (file: any, index: number) => {
    const existedFuelReportData: any = getValues('fuelCardReports');
    if (existedFuelReportData[index]) {
      existedFuelReportData.splice(index, 1);
      setValue('fuelCardReports', existedFuelReportData);
      ftQuarterFillingStore.removeFuelCardDetail(index);
    }
  };

  const [input1Ref, setInput1Focus] = UseFocus();
  useMountEffect(setInput1Focus);

  if (notification) {
    return (
      <SuccessNotification
        title="FT Quarterly Filing"
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return initialLoader ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <Grid container spacing={0.5} minHeight={'100%'}>
      <Grid item md={12} sm={12} xs={12}>
        <form
          style={{
            width: '100%',
          }}
          id={'ftfilling-form'}
          onSubmit={handleSubmit(submit)}>
          <FormControl variant="outlined" fullWidth size="small">
            <Grid container spacing={3} minHeight={'100%'}>
              <Grid item md={6} sm={12} xs={12}>
                <Box className={classes.formLabelClass}>
                  What quarter are you reporting*
                </Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="year"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <FormControl className={classes.w100}>
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={input1Ref}
                          errors={errors.year?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(value: any) => {
                            onChange(value);
                            ftQuarterFillingStore.setReportingDetail(
                              'year',
                              value,
                            );
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          sortByOptionDisable={true}
                          optionsList={yearOption.map((e) => {
                            return { label: e.toString(), value: e.toString() };
                          })}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid>

              <Grid item md={6} sm={12} xs={12}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="quarter"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <FormControl className={classes.w100}>
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.quarter?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(value: any) => {
                            onChange(value);
                            ftQuarterFillingStore.setReportingDetail(
                              'quarter',
                              value,
                            );
                          }}
                          isEditable={false}
                          selectClassName={classes.quarterClassName}
                          optionsList={
                            [
                              { label: 'Q1', value: 'q1' },
                              { label: 'Q2', value: 'q2' },
                              { label: 'Q3', value: 'q3' },
                              { label: 'Q4', value: 'q4' },
                            ]
                            // <>
                            //   <option
                            //     value=""
                            //     className={classes.optionsClassName}>
                            //     Select
                            //   </option>
                            //   <option
                            //     value="q1"
                            //     className={classes.optionsClassName}>
                            //     Q1
                            //   </option>
                            //   <option
                            //     value="q2"
                            //     className={classes.optionsClassName}>
                            //     Q2
                            //   </option>
                            //   <option
                            //     value="q3"
                            //     className={classes.optionsClassName}>
                            //     Q3
                            //   </option>
                            //   <option
                            //     value="q4"
                            //     className={classes.optionsClassName}>
                            //     Q4
                            //   </option>
                            // </>
                          }
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        </form>

        <Grid container spacing={3} marginTop={'0.5rem'} marginBottom={'1rem'}>
          <Grid item md={12} sm={12} xs={12}>
            <TaxPermitsEquipmentHeader
              onSelectEquipment={selectedEquipmentList}
              selecetedEquipment={getValues('equipments')}
              requestType="TPTS"
            />
          </Grid>
          {equimentfields && equimentfields.length ? (
            equimentfields.map((field: any, index: number) => {
              return (
                <FTQuarterFillingForms
                  key={field.id}
                  index={index}
                  value={getValues(`equipments.${index}`)}
                  control={control}
                  classes={classes}
                  removeEquiment={removeEquimentDetail}
                />
              );
            })
          ) : (
            <div
              className={'error-msg'}
              style={{ padding: '1rem 0rem', textAlign: 'center' }}>
              Please Select Equipment
            </div>
          )}
        </Grid>

        <div>
          <Typography className={classes.smallLabel}>
            Do you have State permits?
          </Typography>
          <List className={classes.stateStatus}>
            {statefields.map((field: any, index: number) => {
              return (
                <ListItem key={field.id}>
                  <ListItemIcon>
                    <label className="main">
                      <Controller
                        control={control}
                        name={`statePermits.${index}.selected`}
                        render={({ field: fieldchk }) => {
                          return (
                            <input
                              type="checkbox"
                              {...fieldchk}
                              onChange={(event: any) => {
                                fieldchk.onChange(event.target.checked);
                                ftQuarterFillingStore.setStatePermitCheckToggle(
                                  index,
                                  event.target.checked,
                                );
                                stateUpdate(index, {
                                  ...field,
                                  selected: event.target.checked,
                                });
                              }}
                              checked={field.selected}
                              disabled={getValues(
                                `statePermits.${index}.disablestatus`,
                              )}
                              tabIndex={0}
                            />
                          );
                        }}
                      />
                      <span className="geekmark" />
                    </label>
                  </ListItemIcon>
                  <ListItemText>
                    <Grid container>
                      <Grid item md={5}>
                        <Typography>
                          {getValues(`statePermits.${index}.permitType`) || ''}
                        </Typography>
                      </Grid>
                      <Grid item md={5}>
                        <Typography>
                          {getValues(`statePermits.${index}.registrantName`) ||
                            ''}
                        </Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography>
                          {getValues(`statePermits.${index}.status`) || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </div>

        {/* <React.Fragment> */}
        <div className={classes.gridheadingFlex}>
          <Box className={classes.formLabelClass}>
            Fuel card report or ELD Report (Optional)
          </Box>
          <input
            ref={inputRef}
            type="file"
            hidden
            multiple
            onChange={onChangeFuelReport}
            accept={AllowedUploadMimeTypes}
          />
          <Box className={classes.uploadDoc} onClick={menuHandleClick}>
            Upload
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose('')}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={() => handleClose('Fuel Card Report')}>
              Fuel Card Report
            </MenuItem>
            <MenuItem onClick={() => handleClose('ELD Report')}>
              ELD Report
            </MenuItem>
          </Menu>
        </div>
        <div>
          {watch('fuelCardReports') && watch('fuelCardReports').length ? (
            <FilesList
              files={[...watch('fuelCardReports').map(modifyFileList)]}
              onClearFile={onClearFile}
            />
          ) : (
            <div className={'error-msg'} style={{ textAlign: 'center' }}>
              {/* Please select Files */}
            </div>
          )}
        </div>
        {/* </React.Fragment> */}

        <Divider className={classes.divider} />

        <Grid item md={12} sm={12} xs={12}>
          <TaxPermitsButtons
            formId={'ftfilling-form'}
            disabled={btnDisable}
            formReset={cancel}
            disableLoader={btnDisableInitialLoader}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(FTQuarterFillingComp);
