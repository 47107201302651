import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  activeRoute: {
    alignItems: 'center',
    borderBottom: '5px solid #DEC330',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Semibold !important',
    fontSize: '15px',
    fontWeight: 500,
    height: '50px',
    justifyContent: 'center',
    letterSpacing: '1px',
    lineHeight: '22px',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '2rem',
  },
  inactiveRoute: {
    alignItems: 'center',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Semibold',
    fontSize: '15px',
    fontWeight: 500,
    height: '80px',
    justifyContent: 'center',
    letterSpacing: '1px',
    lineHeight: '22px',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
