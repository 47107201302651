import axios, { AxiosInstance } from 'axios';

import { Config } from '../config';
import { AccidentsStore } from './accidents';
import { ActivityListStore } from './activityList';
import { AddToFleetStore } from './addtofleet';
import { AdminNotificationStore } from './adminNotification';
import { AdminUsersStore } from './adminUsers';
import { AnnualReportStore } from './annualreport';
import { ApiStore } from './api';
import { AuthStore } from './auth';
import { DashboardStore } from './dashboard';
import { DataQchallengeStore } from './dataQchallenge';
import { DisplayNotification } from './displayNotification';
import { DocumentsStore } from './documents';
import { DotActivityListStore } from './dotActivityList';
import { DotUpdateStore } from './dotUpdate';
import { DriverStore } from './driver';
import { DriverListStore } from './driverList';
import { DriverOwnerNotification } from './driverOwnerNotification';
import { EquipmentStore } from './equipment';
import { FileStore } from './file';
import { FleetStore } from './fleet';
import { FtQuarterFillingStore } from './ftQuarterFilling';
import { GlobalStore } from './global';
import { IFTAStore } from './ifta';
import { InspectionsAndViolationsStore } from './inspectionsAndViolations';
import { IrpServiceStore } from './irpService';
import { MvrStore } from './mvr';
import { NotificationStore } from './notification';
import { PrimaryDriversStore } from './primaryDrivers';
import { RoadTaxesStore } from './roadtaxes';
import { SettingsStore } from './settings';
import { StatePermitsStore } from './statePermits';
import { TerminateEquipmentStore } from './terminateEquipment';
import { TodosStore } from './todos';
import { TrainingStore } from './training';
import { TrendsStore } from './trends';
import { UcrStore } from './ucr';
import { UserStore } from './user';
import { ViolationsStore } from './violations';

export class RootStore {
  public accidentsStore: AccidentsStore;
  public apiStore: ApiStore;
  public authStore: AuthStore;
  public axiosInstance!: AxiosInstance;
  public globalStore: GlobalStore;
  public equipmentStore: EquipmentStore;
  public terminateEquipmentStore: TerminateEquipmentStore;
  public statePermitsStore: StatePermitsStore;
  public roadTaxesStore: RoadTaxesStore;
  public activityListStore: ActivityListStore;
  public dotActivityListStore: DotActivityListStore;
  public ucrStore: UcrStore;
  public annualReportStore: AnnualReportStore;
  public dotUpdateStore: DotUpdateStore;
  public ftQuarterFillingStore: FtQuarterFillingStore;
  public iftaStore: IFTAStore;
  public fileStore: FileStore;
  public mvrStore: MvrStore;
  public irpServiceStore: IrpServiceStore;
  public userStore: UserStore;
  public driverListStore: DriverListStore;
  public driverStore: DriverStore;
  public todoStore: TodosStore;
  public fleetStore: FleetStore;
  public adminUsersStore: AdminUsersStore;
  public addToFleetStore: AddToFleetStore;
  public dataQchallengeStore: DataQchallengeStore;
  public adminNotificationStore: AdminNotificationStore;
  public driverOwnerNotification: DriverOwnerNotification;
  public notificationStore: NotificationStore;
  public documentsStore: DocumentsStore;
  public displayNotification: DisplayNotification;
  public settingsStore: SettingsStore;
  public inspectionsAndViolationsStore: InspectionsAndViolationsStore;
  public violationsStore: ViolationsStore;
  public dashboardStore: DashboardStore;
  public primaryDriversStore: PrimaryDriversStore;
  public trendsStore: TrendsStore;
  public trainingStore: TrainingStore;

  constructor() {
    this.initialize();
    this.accidentsStore = new AccidentsStore(this);
    this.apiStore = new ApiStore(this);
    this.authStore = new AuthStore(this);
    this.globalStore = new GlobalStore();
    this.driverStore = new DriverStore(this);
    this.todoStore = new TodosStore(this);
    this.fleetStore = new FleetStore(this);
    this.adminUsersStore = new AdminUsersStore(this);
    this.equipmentStore = new EquipmentStore(this);
    this.statePermitsStore = new StatePermitsStore(this);
    this.fileStore = new FileStore(this);
    this.roadTaxesStore = new RoadTaxesStore(this);
    this.activityListStore = new ActivityListStore(this);
    this.ucrStore = new UcrStore(this);
    this.annualReportStore = new AnnualReportStore(this);
    this.dotUpdateStore = new DotUpdateStore(this);
    this.ftQuarterFillingStore = new FtQuarterFillingStore(this);
    this.iftaStore = new IFTAStore(this);
    this.mvrStore = new MvrStore(this);
    this.irpServiceStore = new IrpServiceStore(this);
    this.dotActivityListStore = new DotActivityListStore(this);
    this.userStore = new UserStore(this);
    this.terminateEquipmentStore = new TerminateEquipmentStore(this);
    this.driverListStore = new DriverListStore(this);
    this.addToFleetStore = new AddToFleetStore(this);
    this.dataQchallengeStore = new DataQchallengeStore(this);
    this.driverOwnerNotification = new DriverOwnerNotification(this);
    this.adminNotificationStore = new AdminNotificationStore(this);
    this.notificationStore = new NotificationStore(this);
    this.documentsStore = new DocumentsStore(this);
    this.displayNotification = new DisplayNotification(this);
    this.settingsStore = new SettingsStore(this);
    this.inspectionsAndViolationsStore = new InspectionsAndViolationsStore(
      this,
    );
    this.dashboardStore = new DashboardStore(this);
    this.violationsStore = new ViolationsStore(this);
    this.primaryDriversStore = new PrimaryDriversStore(this);
    this.trendsStore = new TrendsStore(this);
    this.trainingStore = new TrainingStore(this);
  }

  initialize = () => {
    this.axiosInstance = axios.create({
      baseURL: Config.API_URL,
    });
  };
}

export const rootStore = new RootStore();
