/* eslint-disable */
import { decrypt, encrypt } from './LocalStorage';
import { AuthTokenType } from '../types';

class Storage {
  static KEYS = {
    AUTH_TOKEN: 'AUTH_TOKEN',
  };

  static setItem = (key: string, value: any): void => {
    if (!value) {
      value = '';
    }

    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(encrypt(key), encrypt(value));
  };

  static getItem = (key: string, shouldParse?: boolean): string | null => {
    const keyVal = decrypt(localStorage.getItem(Storage.searchKey(key)));

    if (shouldParse && keyVal) {
      return JSON.parse(keyVal);
    }

    return keyVal;
  };

  static searchKey(key: string): string {
    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      try {
        if (decrypt(keys[i]) === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }

    return '';
  }

  static removeItem = (key: string): void => {
    localStorage.removeItem(Storage.searchKey(key));
  };

  static clear = (): void => {
    const lastSeenData: any = localStorage.getItem('usersNotificationData');
    localStorage.clear();
    localStorage.setItem('usersNotificationData', lastSeenData || '');
  };

  static getTokens = async () => {
    try {
      const appAuth = await Storage.getItem(Storage.KEYS.AUTH_TOKEN);
      return appAuth ? (JSON.parse(appAuth) as AuthTokenType) : null;
    } catch (e) {
      return null;
    }
  };
}

export default Storage;
