import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import DialogMigrate from '../../../components/Dialog/DialogMigrate';
import UploadFileBox from '../../../components/UploadFileBox';
import useStyles from './DataQChallengeStyles';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

type DataQChallengeDocUploadDialogPropsType = {
  isOpen: boolean;
  title: string;
  onSubmitSupportingDoc: (file: File) => void;
  onClose: (remove: boolean) => void;
};
const ALLOWED_FILE_TYPES = ['image/*', 'application/pdf'].join(',');

const DataQChallengeDocUploadDialog: React.FC<
  DataQChallengeDocUploadDialogPropsType
> = (props: DataQChallengeDocUploadDialogPropsType) => {
  const classes = useStyles();
  const { isOpen, title, onClose, onSubmitSupportingDoc } = props;

  const [attachment, setAttachment] = useState<File | null>();

  const handleFileUpload = (file: File | null) => {
    if (file) {
      setAttachment(file as File);
    }
  };

  const onSubmitFile = (): void => {
    if (attachment !== undefined && attachment !== null) {
      onSubmitSupportingDoc(attachment);
    }
  };

  return (
    <DialogMigrate
      aria-labelledby="data-q-challenge-doc-upload-dialog"
      className={classes.docUploadDialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onClose={() => onClose(false)}>
      <DialogTitle sx={{ padding: '12px 24px' }}>
        <div className="header-wrapper">
          <div className="header-title">UPLOAD SUPPORTING DOCUMENT</div>
          <Link
            className="cancel-link"
            color="inherit"
            underline="always"
            onClick={() => {
              onClose(false);
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>
      <Divider className={classes.divider} sx={{ margin: '0.5rem 0' }} />
      <DialogContent sx={{ padding: '8px 24px' }}>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Box className={classes.formLabelClass}>{title}</Box>
            <UploadFileBox
              allowedFileTypes={ALLOWED_FILE_TYPES}
              onRemove={() => {
                setAttachment(null);
              }}
              onUpload={handleFileUpload}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog-action">
        <BottomButton
          type={'submit'}
          form="assign-due-date"
          variant="contained"
          sx={{ width: '100%' }}
          disabled={!attachment}
          onClick={onSubmitFile}>
          <Typography noWrap className="submit-btn-txt">
            SUBMIT
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(DataQChallengeDocUploadDialog);
