import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { SettingsCompanyInfo, SettingsNotificationInfo } from '../../types';
import { RootStore } from '..';

export class SettingsApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  updateNotificationStatus = (data: {
    status: boolean;
    id?: string;
    subtypeId?: string;
  }) => {
    const {
      putNotificationStatus: { Endpoint, Method },
    } = ApiRoutes.Settings;
    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: Endpoint,
    });
  };
  getCompanyInfo = () => {
    const {
      getCompanyInfo: { Endpoint, Method },
    } = ApiRoutes.Settings;
    return this.rootStore.apiStore.call<SettingsCompanyInfo>({
      method: Method,
      url: Endpoint,
    });
  };
  getNotificationInfo = () => {
    const {
      getNotificationInfo: { Endpoint, Method },
    } = ApiRoutes.Settings;
    return this.rootStore.apiStore.call<Array<SettingsNotificationInfo>>({
      method: Method,
      url: Endpoint,
    });
  };
}
