export const servicesWithExpDate = [
  'Finance Agreement',
  'Equipment Lease',
  'Safety Lease',
  'CAB Card',
  'Certificate Of Insurance',
];

export const irpServicesKeys: Partial<Record<FileType, string>> = {
  'CAB Card': 'cabCardExpirationDate',
  'Certificate Of Insurance': 'coiExpirationDate',
  'Equipment Lease': 'equipmentLeaseAgreementExpirationDate',
  'Finance Agreement': 'financeAgreementExpirationDate',
  'Safety Lease': 'safetyLeaseAgreementExpirationDate',
  'Schedule 1': 'filePeriodYear',
};

export const services = [
  {
    key: 'Finance Agreement',
    label: 'Finance Agreement',
  },
  {
    key: 'Equipment Lease',
    label: 'Equipment Lease Agreement',
  },
  {
    key: 'Safety Lease',
    label: 'Safety Lease Agreement',
  },
  {
    key: 'CAB Card',
    label: 'Cab Card',
  },
  {
    key: 'Certificate Of Insurance',
    label: 'Certificate Of Insurance',
  },
];
const irpServicesTodoFormConfig: TodoFormConfig = {};

services.forEach(
  (service) =>
    (irpServicesTodoFormConfig[service.key] = {
      fields: [
        {
          config: {
            minDate: new Date(),
          },
          data_type: 'date',
          label: 'Expiration Date',
          name: 'expirationDate',
        },
        ...(service.key === 'CAB Card'
          ? [
              {
                data_type: 'list->string',
                label: 'State',
                name: 'cabCardState',
              },
            ]
          : []),
      ],
      hasFileUpload: true,
      title: service.label,
    }),
);

export default irpServicesTodoFormConfig;
