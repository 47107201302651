class NavRoutes {
  static PUBLIC = {
    CONTACT: {
      ID: ':contactId',
    },
    CREATEPASSWORD: 'create-password',
    FORGOTPASSWORD: 'forgot-password',
    IMPERSONATE: 'impersonate',
    LOGIN: 'login',
    RESETPASSWORD: 'reset-password',
  };

  static PRIVATE = {
    APP_NAV: {
      CRASHES_OF: 'crashes-of',
      INSPECTIONS_OF: 'inspections-of',
      TODOS_OF: 'todos-of',
      VIOLATIONS_OF: 'violations-of',
    },
    DATA_Q_CHALLENGE: 'data-q-challenge',
    DRIVERS: {
      PROFILE: 'profile',
    },
    DRIVERSERVICEREQUEST: {
      ADDTOFLEET: 'add-to-fleet',
      EQUIPMENTINSPECTION: 'equipment-inspection',
      PHYSICALEXAMLOCATION: 'physical-exam-location',
      TERMINATEQUIPMENT: 'terminate-equipment',
    },
    FLEET: {
      UPDATE: 'update',
    },
    HOME: 'home',
    LEFT_NAV: {
      ACCIDENTS: 'accidents',
      COMPANIES: 'companies',
      DASHBOARD: 'dashboard',
      DOCUMENTS: 'documents',
      DOT: 'dot',
      DRIVER: 'driver',
      DRIVERS: 'drivers',
      FLEET: 'fleet',
      HOME: 'home',
      INSPECTIONS_AND_VIOLATIONS: 'inspections-and-violations',
      NOTIFICATIONS: 'notifications',
      PROFILE: 'profile',
      REPORTS: 'reports',
      SERVICEREQUEST: 'service-request',
      TAXPERMITS: 'taxpermits',
      TODOS: 'todos',
      TRAINING: 'training',
      USERS: 'users',
      VIOLATIONS: 'violations',
    },
    SERVICEREQUEST: {
      ADDTOFLEET: 'add-to-fleet',
      ANNUALREPORT: 'annual-report',
      DATAQCHALLENGE: 'data-q-challenge',
      DOTUPDATE: 'dot-update',
      DRIVER: 'driver',
      DRUGTEST: 'drug-test',
      EQUIPMENTINSPECTION: 'equipment-inspection',
      FTQUARTERFILLING: 'ftquarterfilling',
      IFTA: 'ifta',
      IRPSERVICE: 'irp-service',
      MVR: 'mvr',
      NEWDRIVERSETUP: 'new-driver-setup',
      PSP: 'psp',
      ROADTAX: 'roadtaxes',
      STATEPERMITS: 'state-permits',
      TERMINATEDRIVER: 'terminate-driver',
      TERMINATEEQUIPMENT: 'terminate-equipment',
      UCR: 'ucr',
    },
    SETTINGSROUTE: {
      COMPANYINFO: 'company-info',
      NOTIFICATION: 'notification',
      SETTINGS: 'settings',
    },
    TRAINING: {
      ASSIGNED: 'assigned',
      COMPLETED: 'completed',
      RECOMMENDED: {
        ACCIDENTS: 'accidents',
        BASE_ROUTE: 'recommended',
        INSPECTIONS: 'inspections',
      },
    },
  };
}
export default NavRoutes;
