import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Footer from '../../../../../components/Footer';
import { useCustomStore } from '../../../../../hooks';
import { getFileNamingConvention } from '../../../../../utils/fileName.config';
import { refreshActivityListByStore } from '../../../../../utils/helper';
import { terminateDriver } from '../../../../../utils/options-for-select';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import DriverInfo from '../DriverInfo/DriverInfo';
import ScreenTypeForm from '../ScreenTypeForm';

// Form Configuration for MVR, PSP, CRIMINAL BG and DRUG TEST screens
const formConfig: { [key: string]: any } = {
  mvr: {
    attachmentLabel:
      'Upload signed MVR authorization or send MVR authorization to driver for signature',
    id: 0,
    options: [
      { label: 'A La Carte', value: 'A La Carte' },
      { label: 'Annual', value: 'annual' },
    ],
    selectLabel: 'Select MVR Type',
  },
  'terminate-driver': {
    attachmentLabel: '',
    id: 3,
    options: terminateDriver
      .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
      .map((option) => ({
        label: option,
        value: option,
      })),
    selectLabel: 'Reason For Termination',
  },
};

type Equipment = DriverEquipment & { error?: string };

const DriverActions: React.FC = () => {
  const [cache, setCache] = useState<Equipment[]>([]);
  const { driverListStore, dotActivityListStore } = useCustomStore();
  const { SelectedDriver } = driverListStore;
  const [equipmentsList, setEquipmentsList] = useState<Equipment[]>();
  // const [tempList, setTempList] = useState();
  const location = useLocation();
  const params = useParams();
  const [path, setPath] = useState('');
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [defaultValueForSelect, setDefaultValueForSelect] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  let formData: { [key: string]: any } = {};

  useEffect(() => {
    const tempPath = location.pathname.split('/')[2];
    setPath(tempPath);
    setShowError(false);
    const contactId = params.id || '';
    if (formConfig[tempPath].id === 3 && contactId) {
      (async () => {
        const list = await driverListStore.getEquipmentByDriverId(contactId);
        const tempList = list.map((el: any) => ({
          ...el,
          shouldTerminate: false,
          surrenderPermitsOrRegistrations: undefined,
        }));
        setCache(tempList);
        setEquipmentsList(tempList);
      })();
    }
  }, [location.pathname, driverListStore, params]);

  useEffect(() => {
    if (path === 'mvr' && searchParams.get('todoId')) {
      setDefaultValueForSelect('annual');
    }
  }, [path, searchParams]);

  const handleFormChange = (data: any) => {
    formData = data;
    setShouldReset(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const notifySubmitRequest = (isRequestSuccess: boolean) => {
    refreshActivityListByStore(dotActivityListStore);
    if (isRequestSuccess) {
      enqueueSnackbar(
        'Submission Successful, Simplex has your request. Please allow 24-48 for your request to be processed.',
        SnackBarConfig('success'),
      );
      setShouldReset(true);
      navigate(-1);
    } else {
      enqueueSnackbar(
        'Failed to submit request. Try again',
        SnackBarConfig('e'),
      );
    }
  };

  const handleSubmitForTerminateDriver = async () => {
    if (equipmentsList?.filter((el) => el.error).length) return;
    if (
      !formData.driverTerminationConsent! &&
      !formData.nameConsentDriver &&
      !formData.jobTitleConsentDriver
    )
      return;

    if (formData.acknowledgmentUnitConsent)
      if (
        !formData.unitTerminationConsent &&
        !formData.nameConsentUnits &&
        !formData.jobTitleConsentUnits
      )
        return;

    setIsSubmitting(true);

    const contactId = params.id || '';
    const modifiedList: Equipment[] =
      equipmentsList?.map((equipment: Equipment) => {
        const obj = { ...equipment };
        obj.surrenderPermitsOrRegistrations =
          !!obj.surrenderPermitsOrRegistrations;
        delete obj.error;
        return obj;
      }) || [];

    const payloadAcknowledgmentDriver: acknowledgmentFormPayload = {
      caseId: 'data',
      consent: formData.driverTerminationConsent,
      name: formData.nameConsentDriver,
      position: formData.jobTitleConsentDriver,
      terminationType: 'DriverTermination',
      todoId: '',
    };

    const payloadAcknowledgmentUnit: acknowledgmentFormPayload = {
      caseId: 'data',
      consent: formData.acknowledgmentUnitConsent ? true : false,
      name: formData.nameConsentUnits,
      position: formData.jobTitleConsentUnits,
      terminationType: 'UnitTermination',
      todoId: '',
    };

    const payload: TerminateDriverPayload = {
      acknowledgmentDriverFormRequest: payloadAcknowledgmentDriver,
      acknowledgmentUnitFormRequest: payloadAcknowledgmentUnit,
      contactId,
      equipment: modifiedList,
      expectedReturn: formData.selectedExpectedReturn,
      terminationDate: formData.selectedTerminationDate,
      terminationReason: formData.selectedType,
    };
    const response = await driverListStore.terminateDriver(payload);
    setEquipmentsList([...cache]);
    notifySubmitRequest(response);
    setIsSubmitting(false);
  };
  const getPayLoadForUpload = (contactId: string) => {
    // formData.attachment is instance of FormData
    const fData: FormData = formData.attachment;
    const { signedDate } = formData;
    const fileName = getFileNamingConvention['MVR Authorization Form'].name({
      signedDate: moment(signedDate).format('MM/DD/YYYY'),
    });
    const fileData = [
      {
        driver: { contactId },
        fileName,
        fileType: 'MVR Authorization Form',
        service: path,
        type: 'driver',
      },
    ];
    fData.set('data', JSON.stringify(fileData));
    return fData;
  };

  const handleSubmit = async () => {
    setShowError(!!formData.error);
    if (formData.error) return;
    if (formConfig[path].id === 3) return handleSubmitForTerminateDriver();
    let documents: any[] = [];
    const contactId = params.id || '';
    const screenId = formConfig[path].id;
    if (!formData.sendToDriver) {
      setIsSubmitting(true);
      const payload = getPayLoadForUpload(contactId);
      const response = await driverListStore.uploadFileForSignature(payload);
      const { value, error } = response || { error: '', value: '' };
      if (error || value.length < 0)
        return enqueueSnackbar('File upload failed.', SnackBarConfig('e'));
      documents = value;
      // enqueueSnackbar('File uploaded successfully', SnackBarConfig('success'));
    }
    if (formData.sendToDriver || documents.length > 0) {
      const { selectedType: type, signedDate } = formData;
      const payload: any = {
        contactId,
        documents,
        requestType: formData.sendToDriver ? 'send to driver' : 'upload',
        signedDate,
        type,
      };

      if (searchParams && searchParams.get('todoId')) {
        const todoId = searchParams.get('todoId');
        payload['todoId'] = todoId;
      }

      setIsSubmitting(true);
      const isRequestSuccess = await driverListStore.submitSignatureRequest(
        payload,
        screenId,
      );
      notifySubmitRequest(isRequestSuccess);
      setIsSubmitting(false);
    }
  };

  const handleEquipmentSelect = (
    isSelected: boolean,
    index: number,
    interactedControl: 'A' | 'B',
  ) => {
    const tempList = equipmentsList?.length ? [...equipmentsList] : [];
    const selectedEquipment = tempList[index];
    if (selectedEquipment.equipmentType === 'trailer') {
      tempList[index] = {
        ...tempList[index],
        shouldTerminate: isSelected,
        ...(isSelected ? { surrenderPermitsOrRegistrations: false } : {}),
      };
      setEquipmentsList([...tempList]);
      return;
    }
    if (interactedControl === 'A') {
      tempList[index] = {
        ...tempList[index],
        error: isSelected
          ? 'Select Yes or No to surrender state/registration permits'
          : '',
        shouldTerminate: isSelected,
      };
    }
    if (interactedControl === 'B') {
      tempList[index] = {
        ...tempList[index],
        error:
          isSelected === undefined
            ? 'Select Yes or No to surrender state/registration permits'
            : '',
        surrenderPermitsOrRegistrations: isSelected,
      };
    }
    setEquipmentsList([...tempList]);
  };
  if (isSubmitting) {
    return <LoadingModal isOpen={isSubmitting} />;
  }
  return (
    <main style={{ paddingRight: '4rem' }}>
      <DriverInfo />
      {SelectedDriver && (
        <ScreenTypeForm
          showError={showError}
          onChange={handleFormChange}
          {...formConfig[path]}
          shouldReset={shouldReset}
          // TCard is terminate driver card
          onInteractWithTCard={handleEquipmentSelect}
          {...(path && formConfig[path].id === 3 ? { equipmentsList } : {})}
          disableInteraction={isSubmitting}
          defaultvalue={defaultValueForSelect}
        />
      )}
      {SelectedDriver && (
        <Footer
          disabled={isSubmitting}
          disableLoader={isSubmitting}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </main>
  );
};

export default observer(DriverActions);
