import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';

import { getPlaceholderData } from './CSATooltip';

type props = {
  basicData: any;
  month: string;
};
const CSABasicsChart: React.FC<props> = ({ basicData, month }) => {
  const getColor = (data: any) => {
    if (data?.[month] >= data?.highThreshold) {
      return '#A70D0D';
    } else if (data?.[month] >= data?.lowThreshold) {
      return '#F7B500'; // Traffic yellow color
    } else {
      return '#0DA728';
    }
  };
  // function getData(index: number) {
  //   switch (index) {
  //     case 0:
  //       return basicData?.unsafeDriving?.[month];
  //     case 1:
  //       return basicData?.hos?.[month];
  //     case 2:
  //       return basicData?.driverFitness?.[month];
  //     case 3:
  //       return basicData?.controlledSubstance?.[month];
  //     case 4:
  //       return basicData?.vehicleMaintenance?.[month];
  //     case 5:
  //       return basicData?.crash?.[month];
  //     case 6:
  //       return basicData?.hazmat?.[month];
  //     default:
  //       return 0;
  //   }
  // }

  const data: {
    options: ApexOptions;
    series: any;
  } = {
    options: {
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        type: 'bar',
      },
      colors: [
        getColor(basicData?.unsafeDriving),
        getColor(basicData?.hos),
        getColor(basicData?.driverFitness),
        getColor(basicData?.controlledSubstance),
        getColor(basicData?.vehicleMaintenance),
        getColor(basicData?.crash),
        getColor(basicData?.hazmat),
      ],
      dataLabels: {
        enabled: true,
        formatter: (_val: number, data: { dataPointIndex: number }) => {
          // eslint-disable-next-line
          return `${_val === 3 ? 0 : _val}%`;
        },
        offsetX: 25,
        offsetY: 0,
        style: {
          colors: ['#304758'],
          fontSize: '12px',
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: false,
      },

      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
          },
          distributed: true,
          horizontal: true,
        },
      },
      tooltip: {
        custom: function (series: any) {
          if (getPlaceholderData(series.dataPointIndex)) {
            return `<div class="tooltip">
            ${getPlaceholderData(series.dataPointIndex)}
            </div>`;
          } else {
            return '';
          }
        },
        enabled: true,
        fixed: {
          enabled: true,
          offsetX: 0,
          offsetY: -20,
          position: 'topRight',
        },
        followCursor: true,
      },
      xaxis: {
        categories: [
          ['Unsafe Driving'],
          ['Hours-of-Service'],
          ['Driver Fitness'],
          ['Controlled Substances', '& Alcohol'],
          ['Vehicle Maintenance'],
          ['Crash Indicator'],
          ['Hazardous Materials', 'Compliance'],
        ],
        labels: {
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
        title: {
          style: {
            color: '#B1B4BE',
            fontFamily: 'FiraSans-Regular',
            fontSize: '14px',
          },
          text: '% Percentage',
        },
      },
      yaxis: {
        labels: {
          offsetX: 10,
          offsetY: 8,
        },
        max: 100,
        min: 0,
      },
    },
    series: [
      {
        // data: [21, 60, 10, 28, 50, 21, 45],
        data: [
          basicData?.unsafeDriving?.[month] || 3,
          basicData?.hos?.[month] || 3,
          basicData?.driverFitness?.[month] || 3,
          basicData?.controlledSubstance?.[month] || 3,
          basicData?.vehicleMaintenance?.[month] || 3,
          basicData?.crash?.[month] || 3,
          basicData?.hazmat?.[month] || 3,
        ],
      },
    ],
  };
  return (
    <div style={{ position: 'relative' }}>
      <Chart
        // @ts-ignore
        options={data.options}
        series={data.series}
        type="bar"
        height="400px"
      />
    </div>
  );
};

export default CSABasicsChart;
