import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useStyles from './PhysicalExamLocationStyles';

const PhysicalExamLocation: React.FC = () => {
  const classes = useStyles();
  const navigateRegistry = (event: any) => {
    window.open(event.target.innerText, '_blank');
  };

  return (
    <Grid container spacing={0.5} minHeight={'100%'}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          style={{
            color: '#241A2E !important',
            fontFamily: 'FiraSans-Medium !important',
            fontSize: '0.93rem !important',
          }}>
          Select and schedule your physical exam below
        </Typography>
        <div>
          <div onClick={navigateRegistry} className={classes.link}>
            https://nationalregistry.fmcsa.dot.gov/search-medical-examiners
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(PhysicalExamLocation);
