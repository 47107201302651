import { makeAutoObservable } from 'mobx';
import { Result } from 'neverthrow';

import { ApiRoutes } from '../../config/ApiRoutes';
import { ApiError, GetFileInput } from '../../types';
import { RootStore } from '..';

export type FileApiInstance = InstanceType<typeof FileApiStore>;

export class FileApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  uploadFile = (formData: FormData) => {
    const {
      Upload: { Endpoint, Method },
    } = ApiRoutes.File;
    return this.rootStore.apiStore.call<any>({
      data: formData,
      method: Method,
      url: Endpoint,
    });
  };

  getFile = (urlPath: string, params: GetFileInput) => {
    const {
      GetFile: { Endpoint, Method },
    } = ApiRoutes.File;
    return this.rootStore.apiStore.call<{ url: string }>({
      method: Method,
      params: params,
      url: Endpoint(urlPath),
    });
  };

  manipulateAndUpload = async (fileObj: any) => {
    const {
      Upload: { Endpoint, Method },
    } = ApiRoutes.File;

    const formData = new FormData();
    formData.append('files', fileObj);

    return this.rootStore.apiStore.call<any>({
      data: formData,
      method: Method,
      url: Endpoint,
    });
  };

  uploadMultipleFiles = async (fileList: any) => {
    const promiseArray: Promise<Result<any, ApiError>>[] = [];

    fileList.forEach((fileObj: any) => {
      promiseArray.push(this.manipulateAndUpload(fileObj));
    });

    return Promise.all(promiseArray);
  };
}
