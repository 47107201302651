import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useCustomStore } from '../../../hooks';
import { UpdateUserInput } from '../../../types';
import { handleGetUpdatedProfile, isExpired } from '../../../utils/helper';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { UpdateUserFormSchema } from '../../../utils/ValidatorSchema';
import PJSON from './../../../../package.json';
import { BUILD_TIME_STAMP } from './../../../simplex_time';
// import DriverFormInfo from './DriverInfo/DriverFormInfo';
import DriverInfo from './DriverInfo/DriverInfo';
import DriverMedicalInfo from './DriverInfo/DriverMedicalInfo';
import MedicalCardUpdateForm from './MedicalCardUpdateForm';
import OwnerFormInfo from './OwnerInfo/OwnerFormInfo';
import OwnerInfo from './OwnerInfo/OwnerInfo';
import useStyles from './ProfileStyles';
import ProfileUpdateForm from './ProfileUpdateForm';

const Profile: React.FC = () => {
  const date = useMemo(() => {
    if (BUILD_TIME_STAMP) {
      const date_ = new Date(BUILD_TIME_STAMP * 1000);
      return `${moment(date_).format('MM/DD/YYYY hh:mm A')}`;
    }
  }, []);
  const { authStore, userStore } = useCustomStore();
  const { UserData } = userStore;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [isProfileEdit, setIsProfileEdit] = useState<boolean>(false);
  const [isMedicalEdit, setIsMedicalEdit] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [showMedicalCard, setShowMedicalCard] = useState<boolean>(true);

  // const [medicalBtnDisable, setMedicalBtnDisable] = useState<boolean>(false);
  const clearSession = () => {
    setLoading(true);
    authStore.logout();
    const lastSeenData: any = localStorage.getItem('usersNotificationData');
    localStorage.clear();
    localStorage.setItem('usersNotificationData', lastSeenData || '');
  };

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    setValue,
  } = useForm<UpdateUserInput>({
    criteriaMode: 'all',
    defaultValues: {
      address: '',
      city: '',
      dob: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      state: '',
      zip: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(UpdateUserFormSchema),
  });
  const hanldeGetUsersProfilelInfo = async () => {
    setLoading(true);
    try {
      const res = await userStore.fetchUsersProfilelInfo();
      if (res.isOk()) {
        setLoading(false);
      }
    } catch (err) {
      return;
    }
  };
  const onSubmit = async (data: UpdateUserInput) => {
    setLoading(true);
    data['dob'] = moment(data['dob']).format('YYYY-MM-DD');
    setBtnDisable(true);
    const getResp = await userStore.updateProfileInfo(
      handleGetUpdatedProfile(dirtyFields, { ...UserData }, data),
    );

    if (getResp.isErr()) {
      enqueueSnackbar(getResp.error.message.toString(), SnackBarConfig('e'));
      setLoading(false);
    } else if (getResp.isOk()) {
      reset();
      enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
      hanldeGetUsersProfilelInfo();
      setLoading(false);
    }
    setBtnDisable(false);
    setIsProfileEdit(false);
  };
  useEffect(() => {
    UserData.licenseType === 'Non-CDL' && handleShowMedicalCard(false);
  }, [UserData?.licenseType]);
  const handleShowMedicalCard = (show: boolean) => {
    setShowMedicalCard(show);
  };
  return (
    <>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <>
          {!isProfileEdit ? (
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: '20px' }}>
              <Grid item lg={8} md={12}>
                <Card>
                  <CardContent>
                    <Grid container>
                      <Grid item md={8}>
                        <Typography
                          component={'div'}
                          className={classes.profileTitle}>
                          Profile Information
                        </Typography>
                      </Grid>

                      <Grid item md={4}>
                        <Typography
                          component={'div'}
                          className={classes.editBtn.concat(
                            authStore.getTokenDetail &&
                              authStore.getTokenDetail?.adminId
                              ? ' disable'
                              : '',
                          )}
                          {...(authStore.getTokenDetail &&
                          authStore.getTokenDetail?.adminId
                            ? {}
                            : { onClick: () => setIsProfileEdit(true) })}>
                          Edit
                        </Typography>
                      </Grid>
                    </Grid>
                    {['driver', 'ownerOperator'].includes(
                      UserData.accessType,
                    ) && <DriverInfo {...{ UserData }} />}

                    {UserData.accessType === 'companyAdmin' && (
                      <>
                        <OwnerInfo {...{ UserData }} />
                        <Grid item md={12}>
                          <Typography
                            component={'div'}
                            style={{ paddingLeft: '.2rem' }}
                            onClick={clearSession}
                            className={classes.logoutLink}>
                            Log Out
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <>
              {['driver', 'ownerOperator'].includes(UserData.accessType) && (
                <ProfileUpdateForm
                  driverList={UserData}
                  cancel={() => setIsProfileEdit(!isProfileEdit)}
                  hanldeGetUsersProfilelInfo={hanldeGetUsersProfilelInfo}
                  handleShowMedicalCard={handleShowMedicalCard}
                />
              )}

              {UserData.accessType === 'companyAdmin' && (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: '20px' }}>
                  <Grid item lg={8} md={12}>
                    <Card>
                      <CardContent>
                        <form
                          style={{
                            width: '100%',
                          }}
                          id="profile-information"
                          onSubmit={handleSubmit(onSubmit)}>
                          <Grid container>
                            <Grid item md={8}>
                              <Typography
                                component={'div'}
                                className={classes.profileTitle}>
                                Profile Information
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography
                                component={'div'}
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}>
                                <Typography
                                  component={'div'}
                                  className={classes.editBtn}
                                  onClick={() => setIsProfileEdit(false)}
                                  style={{ marginRight: '1rem' }}>
                                  Cancel
                                </Typography>
                                <Typography
                                  component={'button'}
                                  form={'profile-information'}
                                  disabled={
                                    btnDisable ||
                                    (authStore.getTokenDetail &&
                                      authStore.getTokenDetail?.adminId)
                                  }
                                  type="submit"
                                  className={classes.saveBtn}>
                                  Save
                                </Typography>
                              </Typography>
                            </Grid>
                          </Grid>
                          <OwnerFormInfo
                            {...{ UserData, control, errors, setValue }}
                          />
                        </form>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <>
            {UserData.accessType !== 'companyAdmin' && (
              <div style={{ marginBottom: '40px' }}>
                {!isMedicalEdit && showMedicalCard ? (
                  <Grid
                    container
                    justifyContent="center"
                    style={{ marginTop: '20px' }}>
                    <Grid item lg={8} md={12}>
                      <Card>
                        <CardContent>
                          <Grid container>
                            <Grid item md={8}>
                              <Typography
                                component={'div'}
                                className={classes.profileTitle}
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}>
                                Medical Card
                                <Typography
                                  component={'div'}
                                  style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}>
                                  {isExpired(UserData.medicalCardExpiryDate) ? (
                                    <InfoIcon className={classes.expiredIcon} />
                                  ) : null}
                                  <Typography
                                    component={'span'}
                                    className={classes.expired}>
                                    {isExpired(UserData.medicalCardExpiryDate)}
                                  </Typography>
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography
                                component={'div'}
                                className={classes.editBtn.concat(
                                  authStore.getTokenDetail &&
                                    authStore.getTokenDetail?.adminId
                                    ? ' disable'
                                    : '',
                                )}
                                {...(authStore.getTokenDetail &&
                                authStore.getTokenDetail?.adminId
                                  ? {}
                                  : { onClick: () => setIsMedicalEdit(true) })}>
                                Edit
                              </Typography>
                            </Grid>
                          </Grid>
                          <DriverMedicalInfo {...{ UserData }} />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ) : (
                  <div>
                    {showMedicalCard && (
                      <MedicalCardUpdateForm
                        driverList={UserData}
                        cancel={() => setIsMedicalEdit(!isMedicalEdit)}
                        hanldeGetUsersProfilelInfo={hanldeGetUsersProfilelInfo}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {(['Owner Operator', 'Driver'].includes(UserData.role) ||
              UserData.role === null) && (
              <Grid
                container
                justifyContent="center"
                style={{ marginBottom: '20px', marginTop: '20px' }}>
                <Grid lg={4} md={4} style={{ paddingRight: '10px' }}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            marginBottom: '20px',
                          }}>
                          <Typography className={classes.profileTitle}>
                            MVR
                          </Typography>
                          {isExpired(UserData.mvrExpirationDate) ? (
                            <InfoIcon
                              className={classes.expiredIcon}
                              style={{ paddingLeft: '10px' }}
                            />
                          ) : null}
                          <Typography
                            component={'span'}
                            className={classes.expired}>
                            {isExpired(UserData.mvrExpirationDate)}
                          </Typography>
                          {/* {expiredStatusLabel(UserData.mvrExpirationDate)} */}
                        </Grid>
                        <Grid item md={12}>
                          <Grid container>
                            <Grid item md={6} sm={12} xs={12}>
                              <Box className={classes.formLabelClass}>
                                Status
                              </Box>
                              {/* <Typography>
                            {UserData.mvrStatus ? UserData.mvrStatus : '-'}
                          </Typography> */}
                              {UserData.mvrStatus ? (
                                <Typography style={{ color: '#241A2E' }}>
                                  {UserData.mvrStatus}
                                </Typography>
                              ) : (
                                <Typography style={{ color: '#A70D0D' }}>
                                  MISSING
                                </Typography>
                              )}
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                              <Box className={classes.formLabelClass}>
                                Expiration Date
                              </Box>
                              {UserData.mvrExpirationDate ? (
                                <Typography style={{ color: '#241A2E' }}>
                                  {UserData.mvrExpirationDate}
                                </Typography>
                              ) : (
                                <Typography style={{ color: '#A70D0D' }}>
                                  MISSING
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid lg={4} md={4} style={{ paddingLeft: '10px' }}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            marginBottom: '20px',
                          }}>
                          <Typography className={classes.profileTitle}>
                            ClearingHouse
                          </Typography>
                          {isExpired(UserData.clearHouseExpDate) ? (
                            <InfoIcon
                              className={classes.expiredIcon}
                              style={{ paddingLeft: '10px' }}
                            />
                          ) : null}
                          <Typography
                            component={'span'}
                            className={classes.expired}>
                            {isExpired(UserData.clearHouseExpDate)}
                          </Typography>
                          {/* {expiredStatusLabel(UserData.clearHouseExpDate)} */}
                        </Grid>
                        <Grid item md={12}>
                          <Grid container>
                            <Grid item md={6} sm={12} xs={12}>
                              <Box className={classes.formLabelClass}>
                                Expiration Date
                              </Box>
                              {UserData.clearHouseExpDate ? (
                                <Typography style={{ color: '#241A2E' }}>
                                  {UserData.clearHouseExpDate}
                                </Typography>
                              ) : (
                                <Typography style={{ color: '#A70D0D' }}>
                                  MISSING
                                </Typography>
                              )}
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                              <Box className={classes.formLabelClass}>
                                Query Result
                              </Box>
                              <Typography>
                                {UserData.clearHouseQueryResult ? (
                                  <Typography style={{ color: '#241A2E' }}>
                                    {UserData.clearHouseQueryResult}
                                  </Typography>
                                ) : (
                                  <Typography style={{ color: '#A70D0D' }}>
                                    MISSING
                                  </Typography>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
            {['driver', 'ownerOperator'].includes(UserData.accessType) && (
              <Grid
                container
                justifyContent="center"
                style={{ marginTop: '20px' }}>
                <Grid item lg={8} md={12}>
                  <Typography
                    component={'div'}
                    style={{ paddingLeft: '.2rem' }}
                    onClick={clearSession}
                    className={classes.logoutLink}>
                    Log Out
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        </>
      )}

      <Grid
        container
        flexDirection="column"
        alignItems="center"
        style={{ marginTop: '20px' }}>
        <h4>VERSION: {PJSON.version}</h4>
        <br />
        <h5>BUILD MADE ON {date}</h5>
        <br />
        <br />
        <br />
      </Grid>
    </>
  );
};

export default observer(Profile);
