import { Box, Divider, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Controller } from 'react-hook-form';

import { SelectInput } from '../../../../../../components/Forms/selectInput';
import { AllowedUploadMimeTypes } from '../../../../../../config/Constants';
import { UseFocus, useMountEffect } from '../../../../../../hooks';
import { RequestType } from '../../../../../../types';
import FilesList from '../../../components/FilesList/FilesList';
import TaxPermitsEquipmentCard from '../../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
import TaxPermitsEquipmentHeader from '../../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';

type roadTaxesFormTypes = {
  btnDisable: any;
  classes: any;
  control: any;
  errors: any;
  onselect: (src: any) => void;
  remove: (src: any) => void;
  fields: any;
  fillingPeriod: Array<any>;
  fillingPeriodChange: (src: any, ele: any, index: number) => void;
  equipmentStore: any;
  // fileValidation: boolean | any;
};
const RoadTaxesForm: React.FC<roadTaxesFormTypes> = (
  props: roadTaxesFormTypes,
) => {
  const {
    btnDisable,
    classes,
    control,
    errors,
    // fileValidation,
    onselect,
    remove,
    fields,
    fillingPeriod,
    fillingPeriodChange,
    equipmentStore,
  } = props;

  // const [formType, setFormType] = useState<string>('');
  // const formTypeChange = (e: any) => {
  //   setFormType(e);
  // };

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  return (
    <Grid container rowSpacing={2} columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
      <Grid item md={12} sm={12} xs={12}>
        <Box className={classes.formLabelClass}>Select New or Renewal *</Box>
        <Controller
          control={control}
          defaultValue={''}
          name={'requestType'}
          render={({ field: { onChange, value, name, ref }, formState }) => {
            return (
              <SelectInput
                isFormSubmittedOnce={!!formState.submitCount}
                inputRefObj={input1Ref}
                errors={errors.requestType?.message || ''}
                name={name}
                isRefreshValue={true}
                value={value}
                size={'small'}
                onChangeText={(e) => {
                  onChange(e);
                }}
                isEditable={btnDisable}
                selectClassName={classes.selectClassName}
                placeHolder={'Select'}
                sortByOptionDisable={true}
                optionsList={
                  [
                    { label: 'New', value: RequestType.New },
                    { label: 'Renewal', value: RequestType.Renewal },
                  ]
                  // <>
                  //   <option
                  //     value=""
                  //     className={classes.optionsClassName}
                  //     disabled>
                  //     Select
                  //   </option>
                  //   <option
                  //     value={RequestType.New}
                  //     className={classes.optionsClassName}>
                  //     New
                  //   </option>
                  //   <option
                  //     value={RequestType.Renewal}
                  //     className={classes.optionsClassName}>
                  //     Renewal
                  //   </option>
                  // </>
                }
              />
            );
          }}
        />
        <Divider className={classes.divider} />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <TaxPermitsEquipmentHeader
          selecetedEquipment={equipmentStore.SelectedEquipments}
          onSelectEquipment={onselect}
          disabled={btnDisable}
          requestType="TPTS"
        />
      </Grid>
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        style={!fields || !fields.length ? { textAlign: 'center' } : {}}>
        {fields.map((ele: any, index: number) => (
          <TaxPermitsEquipmentCard key={index} ele={ele} removedCard={remove}>
            <Divider className={classes.renewalFormCardDivider} />
            <Grid
              container
              rowSpacing={2}
              alignItems="center"
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
              <Grid item md={4} sm={12} xs={12}>
                <Box className={classes.formLabelClass}>
                  Specify Road Tax Filing Period*
                </Box>
                <Controller
                  control={control}
                  defaultValue={''}
                  name={`years[${index}][filePeriodYear]`}
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={
                          errors.years?.[index]?.filePeriodYear?.message || ''
                        }
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(selectValue) => {
                          onChange(selectValue);
                          fillingPeriodChange(selectValue, ele, index);
                        }}
                        size={'small'}
                        isEditable={btnDisable}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        sortByOptionDisable={true}
                        optionsList={fillingPeriod.map((period: any) => ({
                          label: period.year,
                          value: period.year,
                        }))}
                        // {
                        //   <>
                        //     <option
                        //       value=""
                        //       className={classes.optionsClassName}
                        //       disabled>
                        //       Select Year
                        //     </option>

                        //     {fillingPeriod.map((period: any) => (
                        //       <option
                        //         key={period.id}
                        //         value={period.year}
                        //         className={classes.optionsClassName}>
                        //         {period.year}
                        //       </option>
                        //     ))}
                        //   </>
                        // }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box style={{ height: '24px' }}>{''}</Box>
                <div className={classes.gridheadingFlex}>
                  {ele.filePeriodDate !== '' && (
                    <div className={classes.dateInfo}>
                      <span> {ele.filePeriodDate} </span>
                    </div>
                  )}
                </div>
              </Grid>
              {/* {formType === RequestType.New ? ( */}
              <Grid item md={12} sm={12} xs={12}>
                <FilesList
                  acceptMimeType={AllowedUploadMimeTypes}
                  fileTypeOption={[
                    {
                      display: 'Registration (Cab Card)',
                      name: 'Registration (Cab Card)',
                      value: 'CAB Card',
                    },
                  ]}
                  unitId={ele.unitId}
                  headingTitle="Upload Registration (Cab Card) Document"
                  disabled={btnDisable}
                  files={
                    equipmentStore.SelectedEquipments &&
                    equipmentStore.SelectedEquipments.length &&
                    equipmentStore.SelectedEquipments.find(
                      (val: any) => val.unitId === ele.unitId,
                    ) &&
                    equipmentStore.SelectedEquipments.find(
                      (val: any) => val.unitId === ele.unitId,
                    ).files
                      ? equipmentStore.SelectedEquipments.find(
                          (val: any) => val.unitId === ele.unitId,
                        ).files
                      : []
                  }
                  uploadLabelName="Upload"
                  keyName="files"
                />
                {/* {fileValidation &&
                  equipmentStore.SelectedEquipments &&
                  equipmentStore.SelectedEquipments.length &&
                  equipmentStore.SelectedEquipments.find(
                    (val: any) => val.unitId === ele.unitId,
                  ) &&
                  (!equipmentStore.SelectedEquipments.find(
                    (val: any) => val.unitId === ele.unitId,
                  ).files ||
                    !equipmentStore.SelectedEquipments.find(
                      (val: any) => val.unitId === ele.unitId,
                    ).files.length) &&
                  formType === RequestType.New ? (
                    <div className="error-msg"> Required</div>
                  ) : (
                    ''
                  )} */}
              </Grid>
              {/* ) : (
                ''
              )} */}
            </Grid>
          </TaxPermitsEquipmentCard>
        ))}
        {!fields || !fields.length ? (
          <div className={'error-msg'}>Please Select Equipment</div>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default observer(RoadTaxesForm);
