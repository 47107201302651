import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, Grid, TextField, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  classes: any;
  control: Control;
  name: string;
  label: string;
};

const DateRange: React.FC<Props> = ({ classes, control, name, label }) => {
  const dateRef = useRef({ end: '', start: '' });
  return (
    <>
      {/* <FormControl fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Choose from Date
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name={name}
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => {
                          return (
                            <TextField
                              type="text"
                              className={classes.inputF}
                              {...params}
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl> */}
      <FormControl fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          {label}
        </Typography>
        <Grid container className={classes.borderBottom}>
          <Grid container width="48%" className={classes.searcBox}>
            <Grid item md={10}>
              <Controller
                control={control}
                name={`${name}StartDate`}
                defaultValue={null}
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        ref={ref}
                        value={value}
                        maxDate={
                          (dateRef.current.end && dateRef.current.end) ||
                          undefined
                        }
                        onChange={(e) => {
                          dateRef.current.start = e;
                          onChange(e);
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              type="text"
                              className={classes.inputF}
                              {...params}
                              placeholder="Start"
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid width="2%" />
          <Grid container width="48%" className={classes.searcBox}>
            <Grid item md={10}>
              <Controller
                control={control}
                name={`${name}EndDate`}
                defaultValue={null}
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        ref={ref}
                        value={value}
                        minDate={
                          (dateRef.current.start && dateRef.current.start) ||
                          undefined
                        }
                        onChange={(e) => {
                          dateRef.current.end = e;
                          onChange(e);
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              type="text"
                              className={classes.inputF}
                              {...params}
                              placeholder="End"
                            />
                          );
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default DateRange;
