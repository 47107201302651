import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  '& .MuiGrid-root': {
    padding: '0 16px !important',
  },
  '& .MuiPaper-root': {
    '.MuiGrid-container': {
      padding: '0px 16px !important',
    },
    padding: '16px 0px !important',
  },
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },
  combinedTooltip: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    height: '122.9px',
    width: '207px',
  },
  compliant: {
    alignItems: 'center',
    backgroundColor: 'rgba(92,174,91,0.2)',
    borderRadius: '17px',
    color: '#5CAE5B !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-SemiBold !important',
    fontSize: '0.75em !important',
    height: '24px',
    justifyContent: 'center',
    letterSpacing: '0.75px !important',
    paddingTop: '0.2rem',
    textTransform: 'uppercase',
    width: '90px',
  },
  compliantNot: {
    alignItems: 'center',
    backgroundColor: 'rgba(167,13,13,0.2)',
    borderRadius: '17px',
    color: '#A70D0D !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-SemiBold !important',
    fontSize: '0.75em !important',
    height: '24px',
    justifyContent: 'center',
    letterSpacing: '0.75px !important',
    minWidth: '118px',
    paddingTop: '0.2rem',
    textTransform: 'uppercase',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  loginAsAdmin: {
    color: '#2573BF',
    fontFamily: 'FiraSans-Bold',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'right',
  },

  rectangleTooltip: {
    backgroundColor: '#37414F',
    height: '32px',
    width: '207px',
  },
  rowHeight: {
    height: '80px',
  },
  scroll: {
    overflow: 'hidden',
    position: 'relative',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderBg: {
    backgroundColor: '#f5f5f5!important',
  },
  view: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '1em !important',
    textDecoration: 'underline solid #0061FF',
  },
}));

export default useStyles;
