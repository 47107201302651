import '@react-pdf-viewer/core/lib/styles/index.css';

import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import {
  PageChangeEvent,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import clsx from 'clsx';
// import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import packageJson from '../../../package.json';
import LoginBanner from '../../assets/images/login-banner.svg';
import { ApiRoutes } from '../../config/ApiRoutes';
import { Config } from '../../config/Constants';
import { useCustomStore } from '../../hooks';
import { decryptFileUrl } from '../../utils/helper';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './TrainingDialogStyles';

const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '48px',
  width: '170px',
}));

interface TrainingVideoDialogProps {
  showDialog: boolean;
  onOpenQuiz: (open: boolean) => void;
  closeDialog: (refresh: boolean) => void;
  onShowCertificateClick: (url: string) => void;
}

type FileType = 'thumbnail' | 'videoOrDoc';

const TrainingVideoDialog: React.FC<TrainingVideoDialogProps> = (
  props: TrainingVideoDialogProps,
) => {
  const classes = useStyles();
  const { authStore, trainingStore, todoStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const pdfJsVersion: string = packageJson.dependencies['pdfjs-dist'];
  const { showDialog, closeDialog, onOpenQuiz, onShowCertificateClick } = props;

  const [thumbnailUrlLoader, setThumbnailUrlLoader] = useState<boolean>(false);
  const [fileUrlLoader, setFileUrlLoader] = useState<boolean>(false);
  const [isStartView, setIsStartView] = useState<boolean>(true);
  const [isVideoShowing, setIsVideoShowing] = useState<boolean>(false);
  const [isDocShowing, setIsDocShowing] = useState<boolean>(false);
  const [enableDocFinishedBtn, setEnableDocFinishedBtn] =
    useState<boolean>(false);

  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [trainingStatus, setTrainingStatus] = useState<any>(null);
  const [showClosebtn, setShowClosebtn] = useState<boolean>(true);

  const { hasDocument, training, videoStatus } =
    todoStore.SelectedTrainingAssignment;
  const certificateAvailableCheckInterval = useRef<any>();
  const videoPlayerRef: any = useRef(null);
  const videoCurrentTimeRef = useRef<number>(0);

  const onDownloadCertificate = useCallback(() => {
    if (!trainingStatus || !trainingStatus?.assignmentId) {
      return;
    }
    trainingStore.setDownloadTrainingCertificate({
      id: trainingStatus.assignmentId,
      loader: true,
    });
    const tokens = authStore.tokens;
    const url = `${Config.API_URL}${
      ApiRoutes.Training.GetCertificateForAnAssignment.Endpoint
    }?spURL=${trainingStatus.spURL as string}&accessToken=${
      tokens?.accessToken ?? ''
    }`;
    onShowCertificateClick(url);
    trainingStore.setDownloadTrainingCertificate({
      id: '',
      loader: false,
    });
  }, [trainingStatus, onShowCertificateClick, authStore, trainingStore]);

  // Updating training todo details
  const getTodoDetailsForAnAssignment = useCallback(
    async () => {
      if (!todoStore.SelectedTrainingAssignmentTodoId) {
        return;
      }
      const resp = await todoStore.getTodoDetailsForAnAssignment(
        todoStore.SelectedTrainingAssignmentTodoId,
      );
      if (resp.isErr()) {
        enqueueSnackbar('Unable to fetch todo details', SnackBarConfig('e'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, trainingStore],
  );

  // Updating assignment status
  const updateTrainingAssignmentStatus = useCallback(
    async (status: string, refreshTodo = false) => {
      if (!todoStore.SelectedTrainingAssignmentId) {
        return;
      }
      const resp = await trainingStore.updateTrainingAssignmentStatus(
        todoStore.SelectedTrainingAssignmentId,
        { status },
      );
      if (resp.isErr()) {
        enqueueSnackbar('Unable to update status', SnackBarConfig('e'));
      } else {
        setTrainingStatus(resp.value);
        if (resp.value.isCertificateAvailable) {
          setShowClosebtn(false);
          trainingStore.setDownloadTrainingCertificate({
            id: todoStore.SelectedTrainingAssignmentId,
            loader: true,
          });
          certificateAvailableCheckInterval.current = setInterval(
            async (resp) => {
              try {
                const tokens = authStore.tokens;
                const url = `${Config.API_URL}${
                  ApiRoutes.Training.GetCertificateForAnAssignment.Endpoint
                }?spURL=${resp.value.spURL as string}&accessToken=${
                  tokens?.accessToken ?? ''
                }` as string;

                const res = await trainingStore.checkIfCertificateAvailable(
                  url,
                );
                if (!res.isErr()) {
                  trainingStore.setDownloadTrainingCertificate({
                    id: '',
                    loader: false,
                  });
                  clearInterval(certificateAvailableCheckInterval.current);
                  certificateAvailableCheckInterval.current = false;
                  setShowClosebtn(true);
                }
              } catch (err) {
                clearInterval(certificateAvailableCheckInterval.current);
                certificateAvailableCheckInterval.current = false;
                setShowClosebtn(true);
              }
            },
            3000,
            resp,
          );
        }
        refreshTodo && getTodoDetailsForAnAssignment();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, trainingStore],
  );

  // Getting video thumbnail link
  const getTrainingFileLink = useCallback(
    async (fileType: FileType, filePath: string) => {
      if (fileType === 'thumbnail') {
        setThumbnailUrl(null);
        setThumbnailUrlLoader(true);
      }
      if (fileType === 'videoOrDoc') {
        setFileUrlLoader(true);
        setVideoUrl(null);
      }
      const resp = await trainingStore.getTrainingFileLink({ filePath });
      if (resp.isOk()) {
        if (fileType === 'thumbnail') {
          setThumbnailUrl(
            resp.value?.link ? decryptFileUrl(resp.value?.link) : null,
          );
        }
        if (fileType === 'videoOrDoc') {
          setVideoUrl(
            resp.value?.link ? decryptFileUrl(resp.value?.link) : null,
          );
        }
      } else {
        enqueueSnackbar(
          `Unable to fetch training ${hasDocument ? 'document' : 'video'}`,
          SnackBarConfig('e'),
        );
      }
      setThumbnailUrlLoader(false);
      setFileUrlLoader(false);
    },
    [enqueueSnackbar, hasDocument, trainingStore],
  );

  useEffect(() => {
    if (training?.thumbnailUrl && !hasDocument) {
      getTrainingFileLink('thumbnail', training.thumbnailUrl);
    }
  }, [getTrainingFileLink, hasDocument, training?.thumbnailUrl]);

  useEffect(() => {
    return () => {
      if (certificateAvailableCheckInterval.current) {
        clearInterval(certificateAvailableCheckInterval.current);
        setShowClosebtn(true);
      }
    };
  }, [certificateAvailableCheckInterval]);

  useEffect(() => {
    if (
      todoStore.SelectedTrainingAssignment &&
      todoStore.SelectedTrainingAssignment.allowActions &&
      Array.isArray(todoStore.SelectedTrainingAssignment.actions) &&
      todoStore.SelectedTrainingAssignment.actions.includes('quiz')
    ) {
      setTrainingStatus({
        hasQuiz: true,
        isCertificateAvailable: false,
      });
    }
  }, [todoStore.SelectedTrainingAssignment]);

  // On video or document loaded
  const onVideoOrDocStart = (): void => {
    if (videoStatus && videoStatus === 'Completed') {
      return;
    }
    updateTrainingAssignmentStatus('startVideo');
  };

  // On Document page change
  const onDocumentPageChange = (e: PageChangeEvent) => {
    if (!enableDocFinishedBtn && e.doc.numPages === 1 + Number(e.currentPage)) {
      setEnableDocFinishedBtn(true);
    }
  };

  // On video or document finished
  const onVideoOrDocFinished = (): void => {
    hasDocument && setIsDocShowing(false);
    !hasDocument && setIsVideoShowing(false);
    if (videoStatus && videoStatus === 'Completed') {
      return;
    }
    updateTrainingAssignmentStatus('completedVideo', true);
  };

  // On video or document started
  const onStartVideoOrDocument = (): void => {
    if (training?.videoUrl) {
      getTrainingFileLink(
        'videoOrDoc',
        todoStore.SelectedTrainingAssignment.training.videoUrl,
      );
      hasDocument && setIsDocShowing(true);
      !hasDocument && setIsVideoShowing(true);
      setIsStartView(false);
    }
  };

  // Thumbnail or Document rules screen
  const renderThumbnailOrDocRules = (): ReactElement => {
    return (
      <div>
        {hasDocument ? (
          <div className="header">
            Are you ready to start reading this document?
          </div>
        ) : (
          <div>
            {thumbnailUrlLoader ? (
              <CircularProgress size={50} sx={{ color: '#DEC330' }} />
            ) : (
              <img
                src={thumbnailUrl || LoginBanner}
                alt={LoginBanner}
                className={classes.thumbnailImg}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  // Video or Document viewer screen
  const renderVideoOrDocViewer = (): ReactElement => {
    return (
      <>
        {fileUrlLoader && (
          <CircularProgress size={50} sx={{ color: '#DEC330' }} />
        )}
        {!fileUrlLoader && videoUrl && !hasDocument && (
          <video
            ref={videoPlayerRef}
            src={videoUrl}
            controls={true}
            autoPlay={true}
            loop={false}
            disablePictureInPicture={true}
            disableRemotePlayback={true}
            controlsList="nodownload noplaybackrate"
            onPlay={onVideoOrDocStart}
            onEnded={onVideoOrDocFinished}
            onContextMenu={(e: any) => {
              if (e && e.target && e.target instanceof HTMLVideoElement) {
                e.preventDefault();
              }
            }}
            onSeeking={(e: any) => {
              // Disabling seek
              const delta =
                videoPlayerRef.current.currentTime -
                videoCurrentTimeRef.current;
              if (Math.abs(delta) > 0.01) {
                // eslint-disable-next-line no-console
                console.log('Seeking is disabled');
                videoPlayerRef.current.currentTime =
                  videoCurrentTimeRef.current;
              }
            }}
            onSeeked={() => {
              // Disabling seek
              const delta =
                videoPlayerRef.current.currentTime -
                videoCurrentTimeRef.current;
              if (Math.abs(delta) > 0.01) {
                // eslint-disable-next-line no-console
                console.log('Seeking is disabled');
                videoPlayerRef.current.currentTime =
                  videoCurrentTimeRef.current;
              }
            }}
            onPause={() => {
              // Disabling pause
              if (videoPlayerRef?.current) {
                videoPlayerRef.current.play();
              }
            }}
            onTimeUpdate={() => {
              // To update current time of the video
              if (
                videoPlayerRef?.current &&
                !videoPlayerRef.current.paused &&
                !videoPlayerRef.current.seeking
              ) {
                videoCurrentTimeRef.current =
                  videoPlayerRef.current.currentTime;
              }
            }}
            width="100%"
          />
        )}
        {!fileUrlLoader && videoUrl && hasDocument && (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfJsVersion}/build/pdf.worker.min.js`}>
            <div className={classes.documentViewer}>
              <Viewer
                fileUrl={videoUrl}
                defaultScale={SpecialZoomLevel.PageWidth}
                onDocumentLoad={onVideoOrDocStart}
                onPageChange={onDocumentPageChange}
              />
            </div>
          </Worker>
        )}
      </>
    );
  };

  // Video or Document completed screen
  const renderVideoOrDocCompleted = (): ReactElement => {
    return (
      <div
        style={{
          alignItems: 'center',
          color: '#FFFFFF',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
        <div
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'FiraSans-SemiBold',
            paddingLeft: '10px',
          }}>
          {(!trainingStatus ||
            (trainingStatus && !trainingStatus?.isCertificateAvailable) ||
            (trainingStatus &&
              trainingStatus?.isCertificateAvailable &&
              trainingStore?.DownloadTrainingCertificate.loader)) && (
            <div>
              {hasDocument ? 'Document is finished.' : 'Video is over.'}
              {/* <p>'Please wait while we generate your certificate'</p> */}
              {!trainingStatus?.hasQuiz && (
                <p
                  style={{
                    fontFamily: 'FiraSans-SemiBold',
                    fontSize: '20px',
                    paddingTop: '10px',
                    textAlign: 'left',
                  }}>
                  {' Please wait while we generate your certificate.'}
                </p>
              )}
            </div>
          )}
          {trainingStatus &&
            trainingStatus?.isCertificateAvailable &&
            !trainingStore?.DownloadTrainingCertificate.loader &&
            !trainingStatus?.hasQuiz && (
              <>
                {
                  'Your certificate of completion has been successfully generated.'
                }
                {/* <p>'Please wait while we generate your certificate'</p> */}
                {!trainingStatus?.hasQuiz && (
                  <>
                    <p
                      style={{
                        fontFamily: 'FiraSans-SemiBold',
                        fontSize: '18px',
                        paddingTop: '10px',
                        textAlign: 'left',
                      }}>
                      {`To view it, click on the ${'Show Certificate'} button below.`}
                    </p>
                    <p
                      style={{
                        fontFamily: 'FiraSans-SemiBold',
                        fontSize: '18px',
                        paddingTop: '10px',
                        textAlign: 'left',
                      }}>
                      {
                        'To download it, click on the Training menu on the left, open the Completed tab and click on the download button next to the certificate.'
                      }
                    </p>
                  </>
                )}
              </>
            )}
          {trainingStatus && trainingStatus.hasQuiz && (
            <div style={{ marginTop: '0.5rem' }}>
              Next you must complete a quiz based off this&nbsp;
              {hasDocument ? 'document' : 'video'}.
              <br />
              Start Quiz or Take Quiz Later
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <DialogMigrate
      aria-labelledby="training-video-document-dialog"
      className={clsx({
        [classes.videoDialog]: !hasDocument,
        [classes.documentDialog]: hasDocument,
        [classes.dialogWidth80]: hasDocument && isDocShowing,
        [classes.dialogWidth60]: hasDocument && !isDocShowing && !isStartView,
      })}
      maxWidth="lg"
      open={showDialog}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {
        closeDialog(false);
      }}>
      <DialogContent
        className={clsx({
          [classes.videoOrDocDialogContent]: true,
          [classes.darkBackground]:
            !hasDocument || (hasDocument && !isDocShowing && !isStartView),
        })}>
        {!(isVideoShowing || isDocShowing) &&
          isStartView &&
          renderThumbnailOrDocRules()}
        {(isVideoShowing || isDocShowing) && renderVideoOrDocViewer()}
        {!(isVideoShowing || isDocShowing) &&
          !isStartView &&
          renderVideoOrDocCompleted()}
      </DialogContent>
      {!isDocShowing && isStartView && hasDocument && (
        <Divider className={classes.divider} />
      )}
      <DialogActions className={classes.dialogAction}>
        <div>
          {showClosebtn && (
            <Link
              className={classes.cancelLink}
              color="inherit"
              underline="always"
              onClick={() => {
                closeDialog(
                  trainingStatus && trainingStatus.isCertificateAvailable
                    ? true
                    : false,
                );
              }}>
              CLOSE
            </Link>
          )}
        </div>
        <div>
          {!(isVideoShowing || isDocShowing) && isStartView && (
            <ActionButton
              type={'button'}
              variant="contained"
              disabled={thumbnailUrlLoader}
              onClick={onStartVideoOrDocument}
              style={{ width: 'fit-content' }}>
              <Typography noWrap className={classes.actionBtn}>
                {hasDocument ? 'YES, SHOW DOCUMENT' : 'START VIDEO'}
              </Typography>
            </ActionButton>
          )}
          {isDocShowing && (
            <ActionButton
              type={'button'}
              variant="contained"
              style={{ width: 'fit-content' }}
              disabled={fileUrlLoader || !enableDocFinishedBtn}
              onClick={onVideoOrDocFinished}>
              <Typography noWrap className={classes.actionBtn}>
                FINISHED
              </Typography>
            </ActionButton>
          )}
          {!(isVideoShowing || isDocShowing) &&
            !isStartView &&
            trainingStatus &&
            trainingStatus.hasQuiz && (
              <>
                <ActionButton
                  type={'button'}
                  variant="contained"
                  className="take-quiz-later-btn"
                  onClick={() => closeDialog(false)}>
                  <Typography className={classes.actionBtn}>
                    TAKE QUIZ LATER
                  </Typography>
                </ActionButton>
                <ActionButton
                  type={'button'}
                  variant="contained"
                  onClick={() => onOpenQuiz(true)}>
                  <Typography className={classes.actionBtn}>
                    START QUIZ
                  </Typography>
                </ActionButton>
              </>
            )}
          {trainingStatus && trainingStatus.isCertificateAvailable && (
            <ActionButton
              type={'button'}
              variant="contained"
              disabled={trainingStore.DownloadTrainingCertificate.loader}
              onClick={onDownloadCertificate}
              style={{ width: 'fit-content' }}>
              <Typography noWrap className={classes.actionBtn}>
                {trainingStore.DownloadTrainingCertificate.loader ? (
                  <>
                    <span style={{ visibility: 'hidden' }}>
                      GENERATING CERTIFICATE
                    </span>
                    <CircularProgress
                      title="GENERATING CERTIFICATE.."
                      size={25}
                      sx={{ color: '#000' }}
                    />
                  </>
                ) : (
                  'SHOW CERTIFICATE'
                )}
              </Typography>
            </ActionButton>
          )}
        </div>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(TrainingVideoDialog);
