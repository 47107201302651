import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

const FleetTerminationContract: React.FC = () => {
  const pStyle = {
    fontFamily: 'Calibri,sans-serif',
    fontSize: '11.0pt',
    marginBottom: '8.0pt',
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
  };

  const pStyleCenter = {
    fontFamily: 'Calibri,sans-serif',
    fontSize: '11.0pt',
    marginBottom: '8.0pt',
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    textAlign: 'center' as const,
  };

  return (
    <Box>
      <p style={pStyleCenter}>
        <strong>Unit Termination Disclosure</strong>
      </p>{' '}
      <p style={pStyle}>
        As part of our commitment to providing transparent and efficient
        services, Simplex Transportation Management Services, Inc. d/b/a Simplex
        Group has established the following Unit Termination Policy. By
        proceeding with this Unit termination request, you acknowledge and agree
        to comply with the terms outlined in this Disclosure.
      </p>{' '}
      <p style={pStyleCenter}>
        <strong>Termination Terms</strong>
      </p>{' '}
      <p style={pStyle}>
        Billing and Payment: Termination of a Driver&rsquo;s services does not
        absolve the customer of any outstanding fees or charges accrued up to
        the termination date. All outstanding balances must be settled as per
        the terms of our agreement.
      </p>{' '}
      <p style={pStyle}>
        Permit Surrender: The customer is responsible for surrendering any
        permits or documentation associated with the terminated unit. If you
        require assistance with this process, please contact a Simplex agent
        from our Truck Permits and Tax Services (TPTS) department. Simplex is
        ready to assist you with anything needed during this process.
      </p>{' '}
      <p style={pStyle}>
        Disclaimer of Liability: Simplex Group shall not be held liable for any
        consequences arising from the termination of unit services, including
        but not limited to, loss of data, interruption of business operations,
        or financial losses incurred by the customer.
      </p>{' '}
      <p style={pStyleCenter}>
        <b>Data Retention and Reactivation</b>
      </p>{' '}
      <p style={pStyle}>
        Retention of Activity History: Upon termination of the Unit, Simplex
        Group may retain data related to the equipment. This includes any
        inspections, permits, agreements, reports, or Fuel Tax-related records
        issued during the tenure with the company.
      </p>{' '}
      <p style={pStyleCenter}>
        <strong>Disclaimer</strong>
      </p>{' '}
      <p style={pStyle}>
        Reservation of Rights: Simplex Group reserves the right to modify,
        update, or interpret this Disclosure as necessary to ensure legal
        compliance and the smooth operation of our services.
      </p>{' '}
      <p style={pStyleCenter}>
        <strong>Acknowledgment</strong>
      </p>{' '}
      <p style={pStyle}>
        By proceeding with the termination of unit services, you acknowledge
        that you have read, understood, and agreed to the terms outlined in this
        Unit Termination Disclaimer. You further consent to abide by these terms
        and understand the implications of unit termination as described herein.
      </p>
    </Box>
  );
};

export default observer(FleetTerminationContract);
