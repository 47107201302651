import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import DialogMigrate from '../../../components/Dialog/DialogMigrate';
import { SelectInput } from '../../../components/Forms/selectInput';
import { AuthStore } from '../../../store/auth';
import {
  roleUpdateListByRole,
  roleUpdateListByUserType,
} from '../../../utils/config';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import useStyles from './UsersStyle';

type ListPropsTypes = {
  usersLists: any;
  adminUsersStore: any;
  getAdminsListCall: () => void;
  authStore: AuthStore;
  loggedInUser: any;
};

const UsersLists = (props: ListPropsTypes): React.ReactElement => {
  const {
    authStore,
    usersLists,
    adminUsersStore,
    getAdminsListCall,
    loggedInUser,
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [editUser, setEditUser] = useState<any>({
    adminId: '',
    email: '',
    isContactDER: false,
    name: '',
    role: '',
    userContactGrantedId: '',
    userType: '',
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const closeDialog = () => {
    setIsOpen(false);
    setEditUser({
      adminId: '',
      email: '',
      isContactDER: false,
      name: '',
      role: '',
      userContactGrantedId: '',
      userType: '',
    });
  };

  const handleAdminOption = (event: any, id: string) => {
    const obj: any = {};

    if (tooltipIsOpen[id]) {
      obj[id] = false;
      setAnchorEl(null);
    } else {
      obj[id] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const deleteAdminOrRole = async (id: string, userType: string) => {
    if (userType === 'owner') {
      enqueueSnackbar(
        'Cannot remove user with `owner` contact type',
        SnackBarConfig('e'),
      );
      return;
    }
    adminUsersStore.setShowLoader(true);
    const deleteresponse: any = await adminUsersStore.deleteAdmin(id);
    if (deleteresponse && deleteresponse.isOk()) {
      getAdminsListCall();
      enqueueSnackbar(String(deleteresponse.value.value), SnackBarConfig('s'));
    }

    if (deleteresponse && deleteresponse.isErr()) {
      adminUsersStore.setShowLoader(false);
      enqueueSnackbar(
        String(deleteresponse.error.message),
        SnackBarConfig('e'),
      );
    }
  };

  const submitUpdateUser = async () => {
    adminUsersStore.setShowLoader(true);
    const updateResponse: any = await adminUsersStore.updateAdmin(editUser);
    if (updateResponse && updateResponse.isOk()) {
      closeDialog();
      getAdminsListCall();
      enqueueSnackbar(String(updateResponse.value), SnackBarConfig('s'));
    }

    if (updateResponse && updateResponse.isErr()) {
      adminUsersStore.setShowLoader(false);
      enqueueSnackbar(
        String(updateResponse.error.message),
        SnackBarConfig('e'),
      );
    }
  };

  const onSelectUser = (adminUser: any, roleName: string) => {
    setEditUser({
      ...editUser,
      email: adminUser.email,
      role: roleName,
      userContactGrantedId: loggedInUser?.contactId,
    });
  };

  const showDataOfContent = useCallback((text: string | null) => {
    return text ? (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          height: '19px',
          letterSpacing: 0,
          lineHeight: '19px',
        }}>
        {text}
      </Typography>
    ) : (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          height: '19px',
          letterSpacing: 0,
          lineHeight: '19px',
          textAlign: 'center',
        }}>
        -
      </Typography>
    );
  }, []);

  const resendInvite = async (e: any, user: any) => {
    adminUsersStore.setShowLoader(true);
    const sendInviteResp: any = await adminUsersStore.resendPortalInvitation({
      contactId: user.contactId,
      email: user.email,
      name: user.name,
    });
    if (sendInviteResp && sendInviteResp.isOk()) {
      getAdminsListCall();
      enqueueSnackbar(String(sendInviteResp.value), SnackBarConfig('s'));
    }
    if (sendInviteResp && sendInviteResp.isErr()) {
      adminUsersStore.setShowLoader(false);
      enqueueSnackbar(
        String(sendInviteResp.error.message),
        SnackBarConfig('e'),
      );
    }
  };

  const getRolesToAssign = (editUser: any) => {
    const userType = editUser.userType;
    const userRole = editUser.role;

    const rolesOptionsByUserRole =
      roleUpdateListByRole.find((rolesTypes) => rolesTypes.role === userRole)
        ?.value || [];

    let rolesOptionsByUserType =
      roleUpdateListByUserType.find(
        (userTypes) =>
          userTypes.type === userType && userTypes.role === userRole,
      )?.value || [];

    if (userType === 'der' && userRole === 'Super Admin') {
      if (editUser.isContactDER) {
        rolesOptionsByUserType = [...rolesOptionsByUserType, 'DER'];
      } else {
        rolesOptionsByUserType = [...rolesOptionsByUserType, 'Company Admin'];
      }
    }

    return [
      ...new Set([
        ...rolesOptionsByUserRole,
        ...rolesOptionsByUserType,
        editUser.role,
      ]),
    ];
  };

  return (
    <>
      {usersLists.map((user: any, index: number) => (
        <TableRow
          key={index}
          className={classes.rowHeight}
          style={{ minHeight: '80px' }}>
          {adminUsersStore.Status === 'Users' ? (
            <>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      letterSpacing: 0,
                      lineHeight: '23px',
                    }}>
                    {user.name}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      height: '19px',
                      letterSpacing: 0,
                      lineHeight: '19px',
                    }}>
                    {showDataOfContent(user.role)}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  {!user.hasPortalAccess ? (
                    <Button
                      color="secondary"
                      onClick={() => resendInvite(event, user)}>
                      Resend Portal Invitation
                    </Button>
                  ) : (
                    ''
                  )}
                </Grid>
              </TableCell>
              {authStore.UserRoleAcess === 'Super Admin' && (
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Grid item style={{ textAlign: 'right' }}>
                    {loggedInUser &&
                      loggedInUser?.contactId &&
                      loggedInUser?.contactId !== user.contactId && (
                        <IconButton
                          onClick={(event) =>
                            handleAdminOption(event, user.adminId)
                          }>
                          <MoreVertIcon />
                        </IconButton>
                      )}

                    <Popover
                      className="admin-popclass"
                      open={tooltipIsOpen[user.adminId]}
                      onClose={(event) =>
                        handleAdminOption(event, user.adminId)
                      }
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}>
                      <Box
                        sx={{
                          backgroundColor: '#241A2E',
                          borderRadius: '8px',
                          boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                          minHeight: '43px',
                          padding: '0.5rem 0',
                          position: 'relative',
                          width: '207px',
                        }}>
                        <div className="arrow">.</div>
                        {loggedInUser &&
                          loggedInUser?.contactId &&
                          loggedInUser?.contactId !== user.contactId && (
                            <Typography
                              component={'div'}
                              className={'n-pop-btn '.concat(
                                authStore.getTokenDetail &&
                                  authStore.getTokenDetail?.adminId
                                  ? 'disable'
                                  : '',
                              )}
                              {...(authStore.getTokenDetail &&
                              authStore.getTokenDetail?.adminId
                                ? {}
                                : {
                                    onClick: () => {
                                      setIsOpen(true);
                                      setEditUser({
                                        adminId: user.adminId,
                                        email: user.email,
                                        isContactDER: user.isContactDER,
                                        name: user.name,
                                        role: user.role,
                                        userContactGrantedId:
                                          loggedInUser?.contactId,
                                        userType: user.userType,
                                      });
                                    },
                                  })}>
                              Edit
                            </Typography>
                          )}
                      </Box>
                    </Popover>
                  </Grid>
                </TableCell>
              )}
            </>
          ) : (
            <>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      letterSpacing: 0,
                      lineHeight: '23px',
                    }}>
                    {user.name}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      height: '19px',
                      letterSpacing: 0,
                      lineHeight: '19px',
                    }}>
                    {showDataOfContent(user.access)}
                  </Typography>
                </Grid>
              </TableCell>
            </>
          )}
        </TableRow>
      ))}
      <Grid>
        {isOpen && (
          <DialogMigrate
            className={classes.dialog}
            maxWidth="sm"
            open={isOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={() => closeDialog()}>
            <DialogTitle>
              <Typography
                component={'div'}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                }}>
                <Typography variant="h4">
                  <b>Edit User</b>
                </Typography>

                <Grid>
                  <Button
                    type="button"
                    variant="text"
                    disabled={
                      authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                    }
                    {...(authStore.getTokenDetail &&
                    authStore.getTokenDetail?.adminId
                      ? {}
                      : {
                          onClick: () =>
                            deleteAdminOrRole(
                              editUser.adminId,
                              editUser.userType,
                            ),
                        })}
                    className={classes.primaryText}>
                    REMOVE USER
                  </Button>
                </Grid>
              </Typography>
            </DialogTitle>

            <DialogContent>
              {adminUsersStore.AdminUsersShowLoader ? (
                <div className={classes.loader}>
                  <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                </div>
              ) : (
                <form
                  style={{
                    width: '100%',
                  }}
                  id="user-update-form">
                  <Grid container className={classes.pxy}>
                    <Grid item md={6}>
                      <Typography className={classes.infoTitle}>
                        User Full Name
                      </Typography>
                      <Typography className={classes.infodis}>
                        {editUser.name}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        component={'div'}
                        className={classes.infoTitle}>
                        Email
                      </Typography>
                      <Typography component={'div'} className={classes.infodis}>
                        {editUser.email}
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Typography className={classes.infoTitle}>
                        Role
                      </Typography>
                      <SelectInput
                        inputRefObj={null}
                        // errors={errors.name?.message || ''}
                        name={'role'}
                        isRefreshValue={true}
                        value={editUser.role}
                        size={'medium'}
                        onChangeText={(e: any) => {
                          onSelectUser(editUser, e);
                        }}
                        isEditable={false}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        sortByOptionDisable={true}
                        optionsList={getRolesToAssign(editUser).map(
                          (val: any, index: number) => ({
                            disabled: !val,
                            label: val,
                            value: val,
                          }),
                        )}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </DialogContent>

            <DialogActions>
              <Typography
                component={'div'}
                className={classes.bottomAction}
                sx={{ padding: '0 15px !important' }}>
                <Typography component={'span'}>
                  <Button
                    variant={'text'}
                    className={'d-flex-ja '.concat(classes.cancelBtn)}
                    onClick={() => closeDialog()}>
                    Cancel
                  </Button>
                </Typography>
                <Typography component={'div'} className={classes.rightSidebtn}>
                  <Button
                    form={'user-update-form'}
                    type="submit"
                    disabled={
                      authStore.getTokenDetail &&
                      authStore.getTokenDetail?.adminId
                    }
                    variant="contained"
                    {...(authStore.getTokenDetail &&
                    authStore.getTokenDetail?.adminId
                      ? {}
                      : { onClick: () => submitUpdateUser() })}
                    className={'add-btn d-flex-ja '.concat(classes.submited)}>
                    Save
                  </Button>
                </Typography>
              </Typography>
            </DialogActions>
          </DialogMigrate>
        )}
      </Grid>
    </>
  );
};

export default observer(UsersLists);
