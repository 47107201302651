import { toJS } from 'mobx';
import { useEffect, useState } from 'react';

import { useCustomStore } from '../../../../hooks';

const useTrends = (
  trendName: TrendType,
  trendType: Top10Trending | undefined,
  range: string,
) => {
  const { trendsStore } = useCustomStore();
  // eslint-disable
  const { setTrendHistory, trendHistory } = trendsStore;
  const [totalCount, setTotalCount] = useState(0);
  const [topCount, setTopCount] = useState(0);
  const [data, setData] = useState<DataPoint[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    trendsStore.range = range;
    trendsStore.equipmentTrends = {};
  }, [range, trendsStore]);

  useEffect(() => {
    (async () => {
      setData([]);
      const connector = trendsStore.top10Trending[trendName];
      if (connector && trendType && range) {
        const trendType_ = trendType === 'Accidents' ? 'Crashes' : trendType;
        setIsLoading(true);
        const {
          data: dataPoints,
          totalCount,
          topCount,
        } = await connector(trendType_, range);
        setIsLoading(false);
        setData(dataPoints);
        setTopCount(topCount);
        setTotalCount(totalCount);
      }
    })();
  }, [range, trendType, trendsStore, trendName]);

  useEffect(() => {
    if (trendType) {
      setTrendHistory(trendName, {
        range,
        trendType,
      });
    }
  }, [trendName, range, trendType, setTrendHistory]);

  return {
    data,
    history: toJS(trendHistory)?.[trendName],
    isLoading,
    topCount,
    totalCount,
  };
};

export default useTrends;
