import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

// import { InputText } from '../../../../../components/Forms/InputText';
import SuccessNotification from '../../../../../components/SuccessNotification';
// import { SelectInput } from '../../../../../components/Forms/selectInput';
import { useCustomStore } from '../../../../../hooks';
import { AnnualReportFormInputs, TodoRequest } from '../../../../../types';
import { getTaxPermitsActivityList } from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { AnnualReportFormSchema } from '../../../../../utils/ValidatorSchema';
import ServiceRequestAddress from '../../components/Address/Address';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import useStyles from './AnnualReportStyles';

const TaxPermitsAnnualReport: React.FC<any> = () => {
  const { annualReportStore, authStore, activityListStore }: any =
    useCustomStore();
  const classes = useStyles();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [copyAddress, setCopyAddress] = useState(false);
  const [notification, setNotification] = useState(false);
  const [triggerApi, setTriggerApi] = useState(true);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [btnDisableInitialLoader, setBtnDisableInitialLoader] =
    useState<boolean>(true);
  const [initialLoader, setInitialLoader] = useState<boolean>(false);
  const [initialFormData, setInitialFormData] = useState<{
    formData: AnnualReportFormInputs;
    sameAddressStatus: boolean;
  }>({
    formData: {
      // confirmOwner: '',
      mailingAddress: '',
      mailingCity: '',
      mailingState: '',
      mailingZip: '',
      physicalAddress: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      // registeredAgent: '',
    },
    sameAddressStatus: false,
  });

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
    // reset,
    getValues,
    setValue,
    trigger,
  } = useForm<AnnualReportFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      // confirmOwner: '',
      mailingAddress: '',
      mailingCity: '',
      mailingState: '',
      mailingZip: '',
      physicalAddress: '',
      physicalCity: '',
      physicalState: '',
      physicalZip: '',
      // registeredAgent: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(AnnualReportFormSchema),
  });

  useEffect(() => {
    annualInitialApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerApi]);

  const annualInitialApi = useCallback(async () => {
    setBtnDisableInitialLoader(false);
    setInitialLoader(true);
    const initialDataToSet = await annualReportStore.setInitialData();
    setBtnDisableInitialLoader(true);
    setInitialLoader(false);
    if (initialDataToSet) {
      const { formData, sameAddressStatus } = initialDataToSet;
      setInitialFormData({
        formData,
        sameAddressStatus,
      });
      setFormInitialDataFn(formData, sameAddressStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerApi]);

  const setFormInitialDataFn = (formData: any, sameAddressStatus: boolean) => {
    Object.keys(formData).forEach((key: any) => {
      const value = formData[key];
      if (['physicalState', 'mailingState'].includes(key)) {
        const findedState: any = toJS(authStore.statesListOption).find(
          (e: any) => e.code === value,
        );
        if (findedState) {
          setValue(key, value);
          trigger(key);
        } else {
          setValue(key, '');
        }
      } else {
        setValue(key, value);
      }
    });
    setCopyAddress(sameAddressStatus);
  };

  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  const onSubmit = useCallback(async (data: AnnualReportFormInputs) => {
    setBtnDisable(true);
    let todoID = '';
    if (location && location.state) {
      const { todoId } = location.state as TodoRequest;
      todoID = todoId;
    }
    const response = await annualReportStore.addAnualReportData(todoID);
    setBtnDisable(false);
    if (response.isOk()) {
      enqueueSnackbar('Request submited successfully', SnackBarConfig('s'));
      cancel();
      setTriggerApi((p) => !p);
      activityListRecall();
      setNotification(true);
    }

    if (response.isErr()) {
      let message: any = 'Error while processing data';
      if (typeof response.error.message === 'string') {
        message = response.error.message;
      }
      if (typeof response.error.message === 'object') {
        message = 'Validation Error';
      }
      enqueueSnackbar(message, SnackBarConfig('e'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCopyAddress = (e: any) => {
    setCopyAddress(e.target.checked);

    annualReportStore.setAnnualDetail(
      'physicalMailAddressSameStatus',
      e.target.checked,
    );
    if (e.target.checked) {
      setValue('mailingAddress', getValues('physicalAddress'));
      setValue('mailingCity', getValues('physicalCity'));
      setValue('mailingState', getValues('physicalState'));
      setValue('mailingZip', getValues('physicalZip'));
      trigger('mailingAddress');
      trigger('mailingCity');
      trigger('mailingState');
      trigger('mailingZip');

      const {
        physicalAddress: address,
        physicalCity: city,
        physicalState: state,
        physicalZip: zipcode,
      } = getValues();
      annualReportStore.setMailAddressDetail({ address, city, state, zipcode });
    }
  };

  const cancel = () => {
    // reset();
    // setCopyAddress(false);
    annualReportStore.resetAnnualReport();
    const { formData, sameAddressStatus } = initialFormData;
    setFormInitialDataFn(formData, sameAddressStatus);
    // trigger();
  };

  const changeAddressFn = (
    addressKey: string,
    fieldKey: string,
    fieldValue: string,
  ) => {
    if (addressKey === 'mailAddress') {
      annualReportStore.setMailAddressDetail({ [fieldKey]: fieldValue });
    } else if (addressKey === 'physicalAddress') {
      let mailKey = '';
      if (fieldKey === 'address') {
        mailKey = 'mailingAddress';
      } else if (fieldKey === 'city') {
        mailKey = 'mailingCity';
      } else if (fieldKey === 'state') {
        mailKey = 'mailingState';
      } else if (fieldKey === 'zipcode') {
        mailKey = 'mailingZip';
      }
      changePhysicalAddressDetail(fieldKey, fieldValue, mailKey);
    }
  };

  const changePhysicalAddressDetail = (
    key: string,
    value: string,
    mailKey: any,
  ) => {
    annualReportStore.setPhysicalAddressDetail({ [key]: value });
    if (copyAddress) {
      annualReportStore.setMailAddressDetail({ [key]: value });
      setValue(mailKey, value);
      trigger(mailKey);
    }
  };

  if (notification) {
    return (
      <SuccessNotification
        title="Annual Report"
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return initialLoader ? (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <CircularProgress
        size={30}
        sx={{ color: '#DEC330', marginTop: '20px' }}
      />
    </div>
  ) : (
    <Grid container spacing={0.5} minHeight={'100%'}>
      <Grid item md={12} sm={12} xs={12}>
        <form
          style={{
            width: '100%',
          }}
          id="annual-report"
          onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant="outlined" fullWidth size="small">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}
              minHeight={'100%'}>
              <Grid item md={12} sm={12} xs={12}>
                <ServiceRequestAddress
                  control={control}
                  addressName="physicalAddress"
                  errors={errors}
                  disableStatus={copyAddress}
                  onChangeFn={changeAddressFn}
                  fields={{
                    address: 'physicalAddress',
                    city: 'physicalCity',
                    state: 'physicalState',
                    zipcode: 'physicalZip',
                  }}
                />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                display={'flex'}
                alignItems={'center'}>
                <Checkbox
                  color={'success'}
                  size={'medium'}
                  checked={copyAddress}
                  onChange={onChangeCopyAddress}
                />
                <Box className={classes.labels}>
                  Mailing Address is same as Physical Address
                </Box>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <ServiceRequestAddress
                  control={control}
                  addressName="mailAddress"
                  errors={errors}
                  disableStatus={copyAddress}
                  onChangeFn={changeAddressFn}
                  fields={{
                    address: 'mailingAddress',
                    city: 'mailingCity',
                    state: 'mailingState',
                    zipcode: 'mailingZip',
                  }}
                />
              </Grid>
              {/* <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>Confirm Owner *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="confirmOwner"
              render={({
                field: { onChange, value, name, ref, onBlur },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.confirmOwner?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      onChange(val);
                      annualReportStore.setAnnualDetail('confirmOwner', val);
                    }}
                    onBlurText={onBlur}
                    isEditable={false}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid> */}

              {/* <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>Confirm Registered Agent *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="registeredAgent"
              render={({
                field: { onChange, value, name, ref, onBlur },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.registeredAgent?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      onChange(val);
                      annualReportStore.setAnnualDetail('registeredAgent', val);
                    }}
                    onBlurText={onBlur}
                    isEditable={false}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid> */}
            </Grid>
          </FormControl>
        </form>
        <Typography component={'div'} className={classes.infobox}>
          <InfoIcon className={classes.infoicon} />
          Any changes such as but not limited to corporate officers and physical
          or mailing addresses, should be discussed with a Simplex
          representative to ensure your Annual Report is filed with the most
          current information.
        </Typography>

        <Divider className={classes.divider} />
        <TaxPermitsButtons
          formId={'annual-report'}
          disabled={btnDisable}
          formReset={cancel}
          disableLoader={btnDisableInitialLoader}
        />
      </Grid>
    </Grid>
  );
};
export default observer(TaxPermitsAnnualReport);
