// import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';

import { useCustomStore } from '../../../../hooks';
import { SettingsNotificationInfo } from '../../../../types';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import SettingsHeader from '../components/header';
import useStyles from './notificationSettingsStyles';

type NotificationProps = {
  headerName: string;
};
const NotifiationSettings: React.FC<NotificationProps> = (
  props: NotificationProps,
) => {
  const { headerName = '' } = props;

  const classes = useStyles();
  const { authStore, settingsStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState<string | null>(null);

  const getNotificationList = useCallback(async () => {
    const notificationResp = await settingsStore.notificationInfo();
    if (notificationResp.isErr()) {
      enqueueSnackbar(
        String(notificationResp.error.message),
        SnackBarConfig('e'),
      );
    }
  }, [enqueueSnackbar, settingsStore]);

  const updateNotificationStatus = async (
    bodyData: SettingsNotificationInfo | null,
  ) => {
    if (bodyData) {
      setOpen(bodyData?.id || null);
      const notificationResp = await settingsStore.updateNotificationStatus(
        bodyData,
      );
      setOpen(null);
      if (notificationResp.isErr()) {
        enqueueSnackbar(
          String(notificationResp.error.message),
          SnackBarConfig('e'),
        );
      } else {
        enqueueSnackbar('Preference updated', SnackBarConfig('s'));
        getNotificationList();
      }
    }
  };
  useEffect(() => {
    getNotificationList();
  }, [getNotificationList]);

  return (
    <div className={classes.notificationContainer}>
      <SettingsHeader
        {...{
          heading: headerName.concat(' Notifications'),
          subHeading: `Control what notifications the ${headerName.toLowerCase()} receives.`,
        }}
      />
      {settingsStore.NotificationDetails ? (
        <>
          {settingsStore.NotificationDetails.map((val, mainIndex) => (
            <div key={mainIndex.toString()} style={{ position: 'relative' }}>
              <Card className={classes.card}>
                <CardContent sx={{ padding: 'unset' }}>
                  <Typography component={'div'} className={classes.headingName}>
                    {val?.heading}
                  </Typography>
                  <Divider className={classes.divider} />
                  {val.subList &&
                    val.subList.map((ele, index, arr) => (
                      <div key={index.toString()}>
                        <Typography
                          component={'div'}
                          className={classes.listName}>
                          <div>{ele.subtype}</div>

                          <div className={classes.switchWrapper}>
                            <span>
                              {open === ele.id ? (
                                <CircularProgress
                                  size={30}
                                  sx={{ color: '#DEC330' }}
                                />
                              ) : null}
                            </span>
                            <Switch
                              className={classes.switch}
                              focusVisibleClassName=".Mui-focusVisible"
                              checked={ele.status}
                              disabled={
                                authStore.getTokenDetail &&
                                authStore.getTokenDetail?.adminId
                              }
                              onChange={(e) => {
                                const arr = settingsStore.NotificationDetails;
                                arr &&
                                  (arr[mainIndex].subList[index].status =
                                    e.target.checked);
                                updateNotificationStatus(
                                  settingsStore.NotificationDetails &&
                                    settingsStore.NotificationDetails[mainIndex]
                                      ?.subList &&
                                    settingsStore.NotificationDetails[mainIndex]
                                      .subList[index],
                                );
                              }}
                              disableRipple
                            />
                          </div>
                        </Typography>
                        {arr.length - 1 === index ? (
                          ''
                        ) : (
                          <Divider
                            className={classes.divider}
                            sx={{ margin: '0px 19px !important' }}
                          />
                        )}
                      </div>
                    ))}
                </CardContent>
              </Card>
              {/* <Backdrop
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </Backdrop> */}
            </div>
          ))}
        </>
      ) : (
        <Card className={classes.card} sx={{ minHeight: '200px' }}>
          <div className={classes.loader}>
            <CircularProgress size={30} sx={{ color: '#DEC330' }} />
          </div>
        </Card>
      )}
    </div>
  );
};
export default observer(NotifiationSettings);
