import InfoIcon from '@mui/icons-material/Info';
import {
  Card,
  CircularProgress,
  Grid,
  Skeleton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Driver from '../../../assets/images/Drivers.svg';
import ReportLogo from '../../../assets/images/TPTS/annual-report-active.svg';
import UCRLogo from '../../../assets/images/TPTS/ucr-active.svg';
import Trailers from '../../../assets/images/Trailers.svg';
import Trucks from '../../../assets/images/Trucks.svg';
import { PrivateRoutes } from '../../../config';
import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import Credentials from './Credentials';
import CSABasicsChart from './CSABasics';
import useStyles from './DashboardStyles';
import InspectionTooltip from './inspectionTooltip';
import TodosGraph from './TodosGraphs';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    zIndex: 999,
  },
}));

const Circle = styled('div')<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  height: 10,
  width: 10,
}));

const Separator = styled('div')(() => ({
  backgroundColor: '#e2e2e2',
  height: '100%',
  width: 1.5,
}));

const DriverImg = styled('img')(() => ({
  transform: 'scale(1.5)',
}));

const Highlights: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { ACCIDENTS, VIOLATIONS, INSPECTIONS_AND_VIOLATIONS } = PrivateRoutes;
  const {
    authStore: { NavbarAccess: access },
    userStore,
    dashboardStore,
    todoStore,
  } = useCustomStore();
  const { ShowLoader, Todos, Data, DataLoader } = dashboardStore;
  const { DOTPin, FMCSA, setShowDOTForm, setShowFMCSAForm } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = useState('current');
  const tempShowDOT = false;

  const handleCSAData = (data: string) => {
    setActive(data);
  };
  const getTodos = useCallback(async () => {
    dashboardStore.setShowLoader(true);
    const getFleetsResp = await dashboardStore.fetchDashboardTodos();
    dashboardStore.setShowLoader(false);

    if (getFleetsResp.isErr()) {
      enqueueSnackbar(String(getFleetsResp.error.message), SnackBarConfig('e'));
    }
  }, [dashboardStore, enqueueSnackbar]);
  const getData = useCallback(async () => {
    dashboardStore.setDataLoader(true);
    const getFleetsResp = await dashboardStore.fetchDashboardData();
    dashboardStore.setDataLoader(false);

    if (getFleetsResp.isErr()) {
      enqueueSnackbar(String(getFleetsResp.error.message), SnackBarConfig('e'));
    }
  }, [dashboardStore, enqueueSnackbar]);

  const getInspectionData = useCallback(async () => {
    const getInspectionResp =
      await dashboardStore.fetchDashboardInspectionCount();
    if (getInspectionResp.isErr()) {
      enqueueSnackbar(
        String(getInspectionResp.error.message),
        SnackBarConfig('e'),
      );
    }
  }, [dashboardStore, enqueueSnackbar]);

  const getBasicCategories = useCallback(async () => {
    const getInspectionResp =
      await dashboardStore.fetchDashboardBasicCategoriesCount();
    if (getInspectionResp.isErr()) {
      enqueueSnackbar(
        String(getInspectionResp.error.message),
        SnackBarConfig('e'),
      );
    }
  }, [dashboardStore, enqueueSnackbar]);

  const getTrendingItems = useCallback(async () => {
    const getInspectionResp = await dashboardStore.fetchTrendingItemsCount();
    if (getInspectionResp.isErr()) {
      enqueueSnackbar(
        String(getInspectionResp.error.message),
        SnackBarConfig('e'),
      );
    }
  }, [dashboardStore, enqueueSnackbar]);

  useEffect(() => {
    dashboardStore.resetDashboardData();
    getTodos();
    getData();
    getInspectionData();
    getBasicCategories();
    getTrendingItems();
  }, [
    getInspectionData,
    getTodos,
    getData,
    dashboardStore,
    getBasicCategories,
    getTrendingItems,
  ]);

  if (DataLoader || ShowLoader) {
    return (
      <div style={{ height: '80vh', textAlign: 'center' }}>
        <CircularProgress size={80} sx={{ color: '#DEC330', marginTop: 20 }} />
      </div>
    );
  }

  const getColor = (issScore: {
    score: number;
    lowThreshold: number;
    highThreshold: number;
  }) => {
    if (issScore?.score < issScore?.lowThreshold) {
      // GREEN
      return '#0DA728';
    } else if (issScore?.score >= issScore?.highThreshold) {
      // RED
      return '#DB2426';
    } else {
      // YELLOW
      return '#D8BF57';
    }
  };

  // Navigate to Todo UI when click on Todos View All
  const navigateToTodos = (todoType: string): void => {
    todoStore.resetFilters();
    navigate(PrivateRoutes.TODOS, {
      state: { todoType },
    });
  };

  const getRowPermission = (rowNumber: string): boolean | undefined => {
    switch (rowNumber) {
      case 'first':
        return (
          access?.dashboard?.fmcsa ||
          access?.dashboard?.dotPin ||
          access?.dashboard?.activeDriversCount ||
          access?.dashboard?.fleetCount
        );
      case 'second':
        return (
          access?.dashboard?.dailyUpdates ||
          access?.dashboard?.monthlyUpdates ||
          access?.dashboard?.issScore ||
          access?.dashboard?.last24MonthsInspectionCount
        );
      case 'third':
        return (
          access?.dashboard?.basicCategories ||
          access?.dashboard?.companyTodo ||
          access?.dashboard?.driverTodo ||
          access?.dashboard?.fleetTodo ||
          access?.dashboard?.trainingTodo
        );
      default:
        return false;
    }
  };

  return (
    <div>
      <div className={classes.highlightsDashboard}>
        {/* First row */}
        {getRowPermission('first') && (
          <Grid container columnSpacing={2}>
            {/* FMCSA Credentials Details */}
            {access?.dashboard?.fmcsa && (
              <Grid item xs={3.5}>
                <Credentials
                  type="FMCSA Crdentials"
                  title="FMCSA Portal Credentials"
                  data={FMCSA as FMCSA}
                  img={ReportLogo}
                  onClickUpdate={() => setShowFMCSAForm(true)}
                />
              </Grid>
            )}
            {/* DOT Pin Details */}
            {access?.dashboard?.dotPin && tempShowDOT && (
              <Grid item xs={3.5}>
                <Credentials
                  type="DOT PIN"
                  title="DOT PIN"
                  data={DOTPin as DOTPin}
                  img={UCRLogo}
                  onClickUpdate={() => setShowDOTForm(true)}
                />
              </Grid>
            )}
            {/* Active Driver, Trucks and Trailers Counts */}
            {(access?.dashboard?.activeDriversCount ||
              access?.dashboard?.fleetCount) && (
              <Grid item xs={5}>
                <Card className={classes.card}>
                  <div className={classes.activeDriversInfo}>
                    {/* Active Drivers Count */}
                    {access?.dashboard?.activeDriversCount && (
                      <>
                        <div
                          className="icon-info-container"
                          onClick={() =>
                            navigate(PrivateRoutes.DRIVERS, {
                              state: { showBackBtn: true },
                            })
                          }>
                          <div className="icon">
                            <DriverImg src={Driver} />
                          </div>
                          <div className="power-unit-count-container">
                            <div className="title">Drivers</div>
                            <div className="value">
                              {DataLoader
                                ? '-'
                                : Data?.value?.unitDriverCount?.activeDrivers}
                            </div>
                          </div>
                        </div>

                        <div className="flex-row-center separator">
                          <Separator />
                        </div>
                      </>
                    )}
                    {/* Trucks and Trailers Count */}
                    {access?.dashboard?.fleetCount && (
                      <>
                        <div
                          className="icon-info-container"
                          onClick={() =>
                            navigate(PrivateRoutes.FLEET, {
                              state: { showBackBtn: true },
                            })
                          }>
                          <div className="icon">
                            <img src={Trucks} />
                          </div>
                          <div className="power-unit-count-container">
                            <div className="title">Power Units</div>
                            <div className="value">
                              {DataLoader
                                ? '-'
                                : Data?.value?.unitDriverCount?.trucks}
                            </div>
                          </div>
                        </div>
                        <div className="flex-row-center separator">
                          <Separator />
                        </div>
                        <div
                          className="icon-info-container"
                          onClick={() =>
                            navigate(PrivateRoutes.FLEET, {
                              state: { showBackBtn: true },
                            })
                          }>
                          <div className="icon">
                            <img src={Trailers} />
                          </div>
                          <div>
                            <div className="title">Trailers</div>
                            <div className="value">
                              {DataLoader
                                ? '-'
                                : Data?.value?.unitDriverCount?.trailers}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
        {/* Second row */}
        {getRowPermission('second') && (
          <Grid container columnSpacing={2} mt={2}>
            {/* Daily Updates */}
            {access?.dashboard?.dailyUpdates && (
              <Grid item xs={3.5}>
                <Card className={classes.card}>
                  <div className={classes.updatesInfo}>
                    <div className="header">
                      <img src={ReportLogo} />
                      <span className="title">Daily Updates</span>
                    </div>
                    <div className="sub-title">
                      <span className="label">Last updated on&nbsp;</span>
                      <span className="date">
                        {DataLoader
                          ? '-'
                          : Data?.value?.dailyUpdate?.lastUpdatedDate || 'N/A'}
                      </span>
                    </div>
                    <div
                      className="updates-list-item"
                      onClick={() => navigate(INSPECTIONS_AND_VIOLATIONS)}>
                      <Circle
                        color={
                          Data?.value?.dailyUpdate?.inspectionsCount
                            ? '#DB2426'
                            : '#0DA728'
                        }
                      />
                      <div className="flex-grow-1">
                        <div className="margin-h-10 label">Inspections</div>
                      </div>
                      <div className="label">
                        {DataLoader
                          ? '-'
                          : Data?.value?.dailyUpdate?.inspectionsCount || '-'}
                      </div>
                    </div>
                    <div
                      className="updates-list-item"
                      onClick={() => navigate(VIOLATIONS)}>
                      <Circle
                        color={
                          Data?.value?.dailyUpdate?.violationsCount
                            ? '#DB2426'
                            : '#0DA728'
                        }
                      />
                      <div className="flex-grow-1">
                        <div className="margin-h-10 label">Violations</div>
                      </div>
                      <div className="label">
                        {DataLoader
                          ? '-'
                          : Data?.value?.dailyUpdate?.violationsCount || '-'}
                      </div>
                    </div>
                    <div
                      className="updates-list-item"
                      onClick={() => navigate(ACCIDENTS)}>
                      <Circle
                        color={
                          Data?.value?.dailyUpdate?.accidentsCount
                            ? '#DB2426'
                            : '#0DA728'
                        }
                      />
                      <div className="flex-grow-1">
                        <div className="margin-h-10 label">Accidents</div>
                      </div>
                      <div className="label">
                        {DataLoader
                          ? '-'
                          : Data?.value?.dailyUpdate?.accidentsCount || '-'}
                      </div>
                    </div>
                    <div className="note">
                      *To receive Daily Updates, the FMCSA Portal Credentials
                      must be valid.
                    </div>
                  </div>
                </Card>
              </Grid>
            )}
            {/* Monthly Updates, ISS Score and Total # of Inspections */}
            {(access?.dashboard?.monthlyUpdates ||
              access?.dashboard?.issScore ||
              access?.dashboard?.last24MonthsInspectionCount) && (
              <Grid item xs={8.5}>
                <Card className={classes.card}>
                  <Grid container columnSpacing={2} style={{ height: '100%' }}>
                    {/* Monthly Updates */}
                    {access?.dashboard?.monthlyUpdates && (
                      <Grid item xs={4}>
                        <div className={classes.updatesInfo}>
                          <div className="header">
                            <img src={ReportLogo} />
                            <span className="title">Monthly Updates</span>
                          </div>
                          <div className="sub-title">
                            <span className="label">Last updated on&nbsp;</span>
                            <span className="date">
                              {DataLoader
                                ? '-'
                                : Data?.value?.monthlyUpdate?.lastUpdatedDate ||
                                  'N/A'}
                            </span>
                          </div>
                          <div
                            className="updates-list-item"
                            onClick={() =>
                              navigate(INSPECTIONS_AND_VIOLATIONS)
                            }>
                            <Circle
                              color={
                                Data?.value?.monthlyUpdate?.inspectionsCount
                                  ? '#DB2426'
                                  : '#0DA728'
                              }
                            />
                            <div className="flex-grow-1">
                              <div className="margin-h-10 label">
                                Inspections
                              </div>
                            </div>
                            <div className="label">
                              {DataLoader
                                ? '-'
                                : Data?.value?.monthlyUpdate
                                    ?.inspectionsCount || '-'}
                            </div>
                          </div>
                          <div
                            className="updates-list-item"
                            onClick={() => navigate(VIOLATIONS)}>
                            <Circle
                              color={
                                Data?.value?.monthlyUpdate?.violationsCount
                                  ? '#DB2426'
                                  : '#0DA728'
                              }
                            />
                            <div className="flex-grow-1">
                              <div className="margin-h-10 label">
                                Violations
                              </div>
                            </div>
                            <div className="label">
                              {DataLoader
                                ? '-'
                                : Data?.value?.monthlyUpdate?.violationsCount ||
                                  '-'}
                            </div>
                          </div>
                          <div
                            className="updates-list-item"
                            onClick={() => navigate(ACCIDENTS)}>
                            <Circle
                              color={
                                Data?.value?.monthlyUpdate?.accidentsCount
                                  ? '#DB2426'
                                  : '#0DA728'
                              }
                            />
                            <div className="flex-grow-1">
                              <div className="margin-h-10 label">Accidents</div>
                            </div>
                            <div className="label">
                              {DataLoader
                                ? '-'
                                : Data?.value?.monthlyUpdate?.accidentsCount ||
                                  '-'}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    )}
                    {/* ISS Score Details */}
                    {access?.dashboard?.issScore && (
                      <Grid item xs={4}>
                        <div
                          className={classes.issScoreInfo}
                          style={{
                            backgroundColor: getColor(
                              Data?.value && Data?.value?.issScore
                                ? Data?.value?.issScore
                                : null,
                            ),
                          }}>
                          <p className="label">ISS Score</p>
                          <p className="value">
                            {Data?.value && Data?.value?.issScore
                              ? Data?.value?.issScore.score
                              : 0}
                          </p>
                          <p className="last-updated">
                            Last updated on&nbsp;
                            {dashboardStore.BasicCategoriesData &&
                            dashboardStore.BasicCategoriesData?.lastUpdatedDate
                              ? moment(
                                  dashboardStore.BasicCategoriesData
                                    .lastUpdatedDate,
                                ).format('MM/DD/YYYY')
                              : '- N/A'}
                          </p>
                        </div>
                      </Grid>
                    )}
                    {/* Total # of Inspections Details */}
                    {access?.dashboard?.last24MonthsInspectionCount && (
                      <Grid item xs={4}>
                        <LightTooltip
                          title={<InspectionTooltip />}
                          placement="right-start">
                          <div
                            className={classes.inspectionsInfo}
                            style={{
                              backgroundColor: getColor(Data?.value?.issScore),
                            }}>
                            <p className="label">Total # of Inspections</p>
                            <p className="value">
                              {dashboardStore.InspectionData
                                ? dashboardStore.InspectionData?.total
                                : 0}
                            </p>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}>
                                <p className="label">
                                  {dashboardStore.InspectionData
                                    ? dashboardStore.InspectionData
                                        ?.withViolations
                                    : 0}
                                </p>
                                <p className="last-updated">w/ Violations</p>
                              </div>
                              <div>
                                <p className="label">
                                  {dashboardStore.InspectionData
                                    ? dashboardStore.InspectionData
                                        ?.withoutViolations
                                    : 0}
                                </p>
                                <p className="last-updated">
                                  Without Violations
                                </p>
                              </div>
                            </div>
                            <p className="last-updated mt">
                              Last 24 months as of{' '}
                              {dashboardStore.BasicCategoriesData &&
                              dashboardStore.BasicCategoriesData
                                ?.lastUpdatedDate
                                ? moment(
                                    dashboardStore.BasicCategoriesData
                                      .lastUpdatedDate,
                                  ).format('MM/DD/YYYY')
                                : '- N/A'}
                            </p>
                          </div>
                        </LightTooltip>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
        {/* Third row */}
        {getRowPermission('third') && (
          <Grid container columnSpacing={2} mt={2}>
            {/* BASIC Categories chart */}
            {access?.dashboard?.basicCategories && (
              <Grid item xs={7} style={{ height: 'fit-content' }}>
                <Card className={classes.card} style={{ paddingLeft: '0' }}>
                  <div className={classes.basicCategoryChart}>
                    <div className="header">
                      <p className="title">BASIC Categories</p>
                      <div className="btn-group">
                        <button
                          className={
                            active === 'current'
                              ? 'active-btn left-btn'
                              : 'left-btn'
                          }
                          onClick={() => handleCSAData('current')}>
                          Current
                        </button>
                        <button
                          className={active === '1m' ? 'active-btn' : ''}
                          onClick={() => handleCSAData('1m')}>
                          1m
                        </button>
                        <button
                          className={active === '2m' ? 'active-btn' : ''}
                          onClick={() => handleCSAData('2m')}>
                          2m
                        </button>
                        <button
                          className={active === '3m' ? 'active-btn' : ''}
                          onClick={() => handleCSAData('3m')}>
                          3m
                        </button>
                        <button
                          className={active === '4m' ? 'active-btn' : ''}
                          onClick={() => handleCSAData('4m')}>
                          4m
                        </button>
                        <button
                          className={active === '5m' ? 'active-btn' : ''}
                          onClick={() => handleCSAData('5m')}>
                          5m
                        </button>
                        <button
                          className={
                            active === '6m'
                              ? 'active-btn right-btn'
                              : 'right-btn'
                          }
                          onClick={() => handleCSAData('6m')}>
                          6m
                        </button>
                        {active != 'current' ? (
                          <div
                            style={{
                              justifyContent: 'flex-end',
                            }}>
                            <Typography align="right" fontSize={12}>
                              FORECAST
                              <sup>*</sup>
                            </Typography>
                          </div>
                        ) : (
                          <div
                            style={{
                              opacity: 0,
                            }}>
                            <Typography fontSize={12}>
                              THIS DOES NOT SHOW
                              <sup>*</sup>
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                    {access?.dashboard?.basicCategories &&
                    dashboardStore.BasicCategoriesData ? (
                      <CSABasicsChart
                        basicData={
                          dashboardStore.BasicCategoriesData
                            ? dashboardStore.BasicCategoriesData.basicCategories
                            : []
                        }
                        month={active}
                      />
                    ) : (
                      <Skeleton
                        sx={{ bgcolor: '#eeeeee' }}
                        variant="rectangular"
                        width="98%"
                        height="430px"
                      />
                    )}
                    {active != 'current' ? (
                      <div className="disclaimer-wrapper">
                        <InfoIcon
                          className="info-icon"
                          sx={{ color: '#dA5A30' }}
                        />
                        <Typography
                          fontSize={12}
                          fontFamily={'FiraSans-Regular'}
                          align="left">
                          <sup>*</sup>Simplex Group provides such information
                          which may be useful to some customers, however, there
                          are uncertainties inherent in attempting to make such
                          projections and forecasts. Please use this information
                          as a guide when speaking with your Simplex
                          representative only.
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </Card>
              </Grid>
            )}
            {/* To-Do Cards */}
            {(access?.dashboard?.companyTodo ||
              access?.dashboard?.driverTodo ||
              access?.dashboard?.fleetTodo ||
              access?.dashboard?.trainingTodo) && (
              <Grid item xs={5}>
                <Grid container spacing={2}>
                  {access?.dashboard?.companyTodo && (
                    <Grid item xs={12}>
                      <Card className={classes.card} style={{ padding: '0' }}>
                        <div className={classes.todoInfo}>
                          <div className="header">
                            <p className="title">Company To-Dos</p>
                            <p
                              className="link"
                              onClick={() => navigateToTodos('Company')}>
                              View All
                            </p>
                          </div>

                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            paddingBottom="10px">
                            <TodosGraph
                              key={'company'}
                              todosData={Todos?.value?.company}
                            />
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                  )}
                  {access?.dashboard?.driverTodo && (
                    <Grid item xs={12}>
                      <Card className={classes.card} style={{ padding: '0' }}>
                        <div className={classes.todoInfo}>
                          <div className="header">
                            <p className="title">Driver To-Dos</p>
                            <p
                              className="link"
                              onClick={() => navigateToTodos('Driver')}>
                              View All
                            </p>
                          </div>

                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            paddingBottom="10px">
                            <TodosGraph
                              key={'driver'}
                              todosData={Todos?.value?.driver}
                            />
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                  )}
                  {access?.dashboard?.fleetTodo && (
                    <Grid item xs={12}>
                      <Card className={classes.card} style={{ padding: '0' }}>
                        <div className={classes.todoInfo}>
                          <div className="header">
                            <p className="title">Fleet To-Dos</p>
                            <p
                              className="link"
                              onClick={() => navigateToTodos('Fleet')}>
                              View All
                            </p>
                          </div>

                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            paddingBottom="10px">
                            <TodosGraph
                              key={'fleet'}
                              todosData={Todos?.value?.fleet}
                            />
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                  )}
                  {access?.dashboard?.trainingTodo && (
                    <Grid item xs={12}>
                      <Card className={classes.card} style={{ padding: '0' }}>
                        <div className={classes.todoInfo}>
                          <div className="header">
                            <p className="title">Training To-Dos</p>
                            <p
                              className="link"
                              onClick={() => navigateToTodos('Training')}>
                              View All
                            </p>
                          </div>

                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            paddingBottom="10px">
                            <TodosGraph
                              key={'training'}
                              todosData={Todos?.value?.training}
                            />
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default observer(Highlights);
