import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import NavRoutes from '../../../config/routes/NavRoutes';
import { useCustomStore } from '../../../hooks';
import Assigned from './Assigned/Assigned';
import Completed from './Completed/Completed';
import Recommended from './Recommended/Recommended';

const Training: React.FC = () => {
  const { authStore } = useCustomStore();
  return (
    <Routes>
      <Route
        path=""
        element={<Navigate to={NavRoutes.PRIVATE.TRAINING.ASSIGNED} />}
      />
      <Route
        path={NavRoutes.PRIVATE.TRAINING.ASSIGNED}
        element={<Assigned />}
      />
      {!['Driver', 'Owner Operator'].includes(authStore.UserRoleAcess) ? (
        <Route
          path={`${NavRoutes.PRIVATE.TRAINING.RECOMMENDED.BASE_ROUTE}/*`}
          element={<Recommended />}
        />
      ) : null}

      <Route
        path={NavRoutes.PRIVATE.TRAINING.COMPLETED}
        element={<Completed />}
      />
    </Routes>
  );
};

export default observer(Training);
