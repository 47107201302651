import { Box, Divider, Grid } from '@mui/material';
// import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React from 'react';

import { AllowedUploadMimeTypes } from '../../../../../../config/Constants';
import { useCustomStore } from '../../../../../../hooks';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { useWatch } from 'react-hook-form';
// import { useFieldArray } from 'react-hook-form';
import { SnackBarConfig } from '../../../../../../utils/SnackBarConfig';
import FilesList from '../../../components/FilesList/FilesList';
import TaxPermitsEquipmentCard from '../../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
// import TaxPermitsEquipmentHeader from '../../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';

type FtQuarterFillingFormProps = {
  index: number;
  value: any;
  control: any;
  classes: any;
  removeEquiment: (equipment: any) => void;
};

const FTQuarterFillingForms: React.FC<any> = (
  props: FtQuarterFillingFormProps,
) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();
  const { ftQuarterFillingStore }: any = useCustomStore();
  const {
    //   formType,
    //   renewalFormSubmit,
    //   renewalOnSubmit,
    classes,
    //   renewalFormControl,
    //   renewalFormErrors,
    value,
    removeEquiment,
  } = props;

  const appendFileDetails = (e: any) => {
    const fileArr: any[] = Array.from(e.target.files);
    const findExcededFile: any = fileArr.find(
      (ef) => Number((ef.size / 1024 / 1024).toFixed(2)) > 15,
    );
    if (findExcededFile) {
      enqueueSnackbar('File must not exceed 15 mb', SnackBarConfig('e'));
      return;
    }
    ftQuarterFillingStore.addFileToEquipment(fileArr, props.index);
    e.target.value = '';
  };

  const removeFileDetail = (fileIndex: any) => {
    ftQuarterFillingStore.removeFileFromEquipment(props.index, fileIndex);
  };

  const modifyFileList = (e: any) => {
    const data = {
      // date: '',
      name: '',
      size: 0,
      type: 'File',
    };

    function sizeDefiner(size: any) {
      return Number((size / 1024 / 1024).toFixed(2));
    }

    // function datedefiner(file: any) {
    //   return format(file.lastModified, 'dd-MM-yyyy');
    // }

    if (e.name) {
      data.name = e.name;
    }
    if (e.size) {
      data.size = sizeDefiner(e.size);
    }
    // if (e.lastModified) {
    //   data.date = datedefiner(e);
    // }
    return data;
  };

  return (
    <Grid item md={12} sm={12} xs={12}>
      <TaxPermitsEquipmentCard ele={value} removedCard={removeEquiment}>
        <Divider className={classes.renewalFormCardDivider} />
        <Grid container spacing={1}>
          <Grid item md={12} sm={12} xs={12}>
            <div className={classes.gridheadingFlex}>
              <Box className={classes.formLabelClass}>
                Upload Fuel Receipts/Trip Sheets (Optional)
              </Box>
              <input
                ref={inputRef}
                type="file"
                hidden
                multiple
                onChange={(e: any) => {
                  appendFileDetails(e);
                }}
                accept={AllowedUploadMimeTypes}
              />
              <Box
                className={classes.uploadDoc}
                onClick={() => inputRef.current?.click()}>
                Upload
              </Box>
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FilesList
              files={[
                ...(
                  ftQuarterFillingStore.getFileFroEquipmentIndex(props.index) ||
                  []
                ).map(modifyFileList),
              ]}
              onClearFile={(file: any, fileIndex: number) => {
                removeFileDetail(fileIndex);
              }}
            />
          </Grid>
        </Grid>
      </TaxPermitsEquipmentCard>
    </Grid>
  );
};

export default observer(FTQuarterFillingForms);
