export const processEndorsementsSelect = (value: string[]) => {
  if (value.includes('None')) {
    if (value.indexOf('None') === 0 && value.length !== 1)
      return {
        target: {
          value: (value as Array<string>).filter((el) => el !== 'None'),
        },
      };
    else
      return {
        target: {
          value: ['None'],
        },
      };
  } else
    return {
      target: {
        value,
      },
    };
};
