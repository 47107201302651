import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import logoNav2 from '../../../assets/images/logo-nav2.svg';
import { InputText } from '../../../components/Forms/InputText';
import { SelectInput } from '../../../components/Forms/selectInput';
import Loader from '../../../components/Loader/Loader';
import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { LoginFormCompanyInputs, LoginFormEmailInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import {
  loginCompanySchema,
  loginEmailSchema,
} from '../../../utils/ValidatorSchema';
import useStyles from '../PublicStyles';

const Login: React.FC = () => {
  const classes = useStyles();
  const { authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [isEmail, setEmail] = useState('');
  const [onSelectContactId, setonSelectContactId] = useState('');
  const [loading, setLoading] = useState(false);
  const [input1Ref, setInput1Focus] = UseFocus();
  const [input2Ref, setInput2Focus] = UseFocus();

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormEmailInputs>({
    defaultValues: {
      email: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginEmailSchema),
  });

  const {
    control: contactControl,
    handleSubmit: handleCompanySubmit,
    formState: { errors: contactErrors },
    setValue,
  } = useForm<LoginFormCompanyInputs>({
    criteriaMode: 'all',
    defaultValues: {
      contactId: '',
      password: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginCompanySchema),
  });

  const goBack = () => {
    setEmail('');
  };

  const submitLogin = useCallback(
    async (data: LoginFormCompanyInputs) => {
      setLoading(true);
      const loginResp = await authStore.companyLogin(data);
      if (loginResp.isErr()) {
        setLoading(false);
        enqueueSnackbar(String(loginResp.error.message), SnackBarConfig('e'));
      }
    },
    [authStore, enqueueSnackbar],
  );

  const submitEmail = useCallback(
    async (data: LoginFormEmailInputs) => {
      setLoading(true);
      const contactResp = await authStore.checkCompanyEmail(data);
      if (contactResp.isOk() && Array.isArray(contactResp.value)) {
        if (!contactResp.value.length) {
          enqueueSnackbar('No account available!', SnackBarConfig('e'));
        } else {
          setEmail(data.email);
          setInput2Focus();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      if (contactResp.isErr()) {
        enqueueSnackbar(String(contactResp.error.message), SnackBarConfig('e'));
        setLoading(false);
      }
    },
    [authStore, enqueueSnackbar, setInput2Focus],
  );

  useMountEffect(setInput1Focus);

  useEffect(() => {
    setValue(
      'contactId',
      authStore.ContactData && authStore.ContactData.length === 1
        ? authStore.ContactData[0].contactId
        : '',
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
  }, [authStore.ContactData, setValue]);

  return (
    <Grid
      container
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      className={classes.sliderHome}>
      <Grid item margin={'auto'}>
        <div className={classes.rectangle}>
          {isEmail ? (
            <IconButton
              className={classes.backtoLoginArrow}
              onClick={() => {
                goBack();
              }}>
              <ArrowBack />
            </IconButton>
          ) : null}
          <img src={logoNav2} className={classes.logoNav2} />
          {isEmail === '' ? (
            <form
              key={1}
              style={{
                width: '100%',
              }}
              id={'login-form'}
              onSubmit={handleSubmit(submitEmail)}>
              <Grid
                sx={{
                  marginTop: '31px',
                }}>
                <FormControl error={false} fullWidth>
                  <Grid container>
                    <Box className={classes.labels}>Email</Box>
                    <Grid item md={12} sm={12} xs={12}>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="email"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={input1Ref}
                              errors={errors.email?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={onChange}
                              isEditable={false}
                              className={classes.inputStyles}
                              type={'email'}
                              variant="filled"
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Button
                disabled={loading}
                tabIndex={0}
                type="submit"
                variant="contained"
                fullWidth
                className={classes.buttonStyles}>
                {loading ? (
                  <Loader
                    className={'flex-centering mt-1em mb-1em'}
                    style={{
                      color: '#DEC330',
                      marginBottom: 16,
                      marginTop: 16,
                    }}
                  />
                ) : (
                  'Continue'
                )}
              </Button>
              <div className={classes.lineCopy2} />
              <Typography component={'div'} align="center">
                <div className={classes.loginSimplexFooter}>
                  ©2021 Simplex Group, Inc. All rights reserved.
                </div>
              </Typography>
            </form>
          ) : (
            <form
              key={2}
              style={{
                width: '100%',
              }}
              id={'contact-form'}
              onSubmit={handleCompanySubmit(submitLogin)}>
              <Grid
                sx={{
                  marginTop: '31px',
                }}>
                <FormControl error={false} fullWidth>
                  <Grid container>
                    <Box className={classes.labels}>Select Company</Box>
                    <Grid item md={12} sm={12} xs={12}>
                      <Controller
                        control={contactControl}
                        defaultValue={
                          authStore.ContactData &&
                          authStore.ContactData.length === 1
                            ? authStore.ContactData[0].contactId
                            : ''
                        }
                        name="contactId"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectInput
                              isEditable={
                                authStore.ContactData.length === 1
                                  ? true
                                  : false
                              }
                              errors={contactErrors.contactId?.message || ''}
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              name={name}
                              isRefreshValue={false}
                              value={value}
                              onChangeText={(selectValue) => {
                                onChange(selectValue);
                                setonSelectContactId(selectValue);
                              }}
                              selectClassName={classes.selectInputStyles}
                              optionsList={
                                authStore.ContactData &&
                                authStore.ContactData.length > 1
                                  ? authStore.ContactData.map(
                                      (contact: any, index: number) => {
                                        return {
                                          label: contact.accountName,
                                          value: contact.contactId,
                                        };
                                      },
                                    )
                                  : authStore.ContactData.length
                                  ? [
                                      {
                                        label:
                                          authStore.ContactData[0].accountName,
                                        value:
                                          authStore.ContactData[0].contactId,
                                      },
                                    ]
                                  : []
                              }
                              // {
                              //   <>
                              //     <option
                              //       value=""
                              //       className={classes.optionsClassName}>
                              //       Select Company
                              //     </option>
                              //     {authStore.ContactData &&
                              //     authStore.ContactData.length > 1 ? (
                              //       authStore.ContactData.map(
                              //         (contact: any, index: number) => {
                              //           return (
                              //             <option
                              //               key={index}
                              //               value={contact.contactId}
                              //               className={
                              //                 classes.optionsClassName
                              //               }>
                              //               {contact.accountName}
                              //             </option>
                              //           );
                              //         },
                              //       )
                              //     ) : (
                              //       <option
                              //         value={authStore.ContactData[0].contactId}
                              //         className={classes.optionsClassName}>
                              //         {authStore.ContactData[0].accountName}
                              //       </option>
                              //     )}
                              //   </>
                              // }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Box
                      className={classes.labels}
                      sx={{
                        marginTop: '12px',
                      }}>
                      Password
                    </Box>
                    <Grid item md={12} sm={12} xs={12}>
                      <Controller
                        control={contactControl}
                        defaultValue=""
                        name="password"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={input2Ref}
                              errors={contactErrors.password?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={onChange}
                              isSecureTextEntry={true}
                              isEditable={false}
                              className={classes.inputStyles}
                              type={'password'}
                              variant="filled"
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Button
                disabled={loading}
                tabIndex={0}
                type="submit"
                variant="contained"
                fullWidth
                className={classes.buttonStyles}>
                {loading ? (
                  <Loader
                    className={'flex-centering mt-1em mb-1em'}
                    style={{
                      color: '#DEC330',
                      marginBottom: 16,
                      marginTop: 16,
                    }}
                  />
                ) : (
                  'Login'
                )}
              </Button>
              <Link
                to={`/forgot-password/${
                  authStore.ContactData && authStore.ContactData.length === 1
                    ? authStore.ContactData[0].contactId
                    : onSelectContactId
                }`}
                tabIndex={0}
                className={classes.forgotPassword}>
                Forgot Password?
              </Link>
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(Login);
