import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { API_LIMIT, PrivateRoutes } from '../../config';
import { useCustomStore } from '../../hooks';
import {
  AdminNotificationSearchParam,
  DriverSearchFormInputs,
  FleetSearchFormInputs,
  TodoSearchFormInputs,
  UsersSearchFormInputs,
} from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import useStyles from './SearchFilterStyles';
import AccidentsSearchForm from './SearchForm/AccidentsSearchForm';
import AdminSearchForm from './SearchForm/AdminSearchForm';
import DriverSearchForm from './SearchForm/DriverSearchForm';
import FleetSearchForm from './SearchForm/FleetSearchForm';
import InspectionsSearchForm from './SearchForm/InspectionsSearchForm';
import NotificationSearchForm from './SearchForm/NotificationSearchForm';
import TodoSearchForm from './SearchForm/TodoSearchForm';
import TrainingAccidentsSearchForm from './SearchForm/TrainingAccidentsSearchForm';
import TrainingAssignedSearchForm from './SearchForm/TrainingAssignedSearchForm';
import TrainingCompletedSearchForm from './SearchForm/TrainingCompletedSearchForm';
import TrainingInspectionsSearchForm from './SearchForm/TrainingInspectionsSearchForm';
import TrendDetailsSearchForm from './SearchForm/TrendDetails';
import TrendsTodoSearch from './SearchForm/TrendsTodoSearch';
import ViolationsSearchForm from './SearchForm/ViolationsSearchForm';

const isTrendDetailsPage = (path = '') => {
  const trends = [
    '/Inspections-of',
    '/Violations-of',
    // '/ToDos-of',
    '/Crashes-of',
  ];
  return trends.findIndex((trend) => path.includes(trend)) !== -1;
};

type MenuProps = {
  anchorEl: any;
  filter: any;
  handleClose: () => void;
  open: any;
  searchPage: any;
  setFilters: (src: any) => void;
};

const SearchFilter = (props: MenuProps): React.ReactElement => {
  const { open, anchorEl, filter, handleClose, searchPage, setFilters } = props;
  const location = useLocation();

  const classes = useStyles();
  const {
    driverStore,
    fleetStore,
    todoStore,
    userStore,
    authStore,
    notificationStore,
    adminUsersStore,
  } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  // Training Routes
  const TrainingRoute = {
    ACCIDENTS: `${PrivateRoutes.TRAINING.RECOMMENDED.ACCIDENTS as string}`,
    ASSIGNED: `${PrivateRoutes.TRAINING.ASSIGNED as string}`,
    COMPLETED: `${PrivateRoutes.TRAINING.COMPLETED as string}`,
    INSPECTIONS: `${PrivateRoutes.TRAINING.RECOMMENDED.INSPECTIONS as string}`,
  };

  const {
    control: driverController,
    handleSubmit: driverSubmit,
    reset: driverReset,
  } = useForm<DriverSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      driverType: '',
      name: '',
      simplexId: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    control: fleetController,
    handleSubmit: fleetSubmit,
    reset: fleetReset,
  } = useForm<FleetSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      expirationEndDate: null,
      expirationStartDate: null,
      simplexId: '',
      unitNumber: '',
      unitType: '',
      vinNumber: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    control: todoController,
    handleSubmit: todoSubmit,
    reset: todoReset,
    setValue: setTodoControlValue,
    watch: todoFormWatch,
  } = useForm<TodoSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      dueIn: '',
      firstName: '',
      lastName: '',
      make: '',
      searchType: '',
      simplexId: '',
      trainingAssignedTo: '',
      trainingReason: '',
      type: '',
      unitNumber: '',
      vinNumber: '',
      year: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const searchTypeOfTodoForm = todoFormWatch('searchType') || '';

  const {
    control: notificationController,
    handleSubmit: notificationSubmit,
    reset: notificationReset,
  } = useForm<AdminNotificationSearchParam>({
    criteriaMode: 'all',
    defaultValues: {
      description: '',
      // nextLink: '',
      title: '',
      type: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    control: adminController,
    handleSubmit: adminSubmit,
    reset: adminReset,
  } = useForm<UsersSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const resetFilter = async () => {
    if (searchPage === 'Drivers') {
      driverStore.setShowLoader(true);
      setFilters((pre: any) => ({
        ...pre,
        driverType: '',
        limit: API_LIMIT,
        name: '',
        nextLink: '',
        simplexId: '',
      }));

      driverStore.setFilters({
        driverType: '',
        limit: API_LIMIT,
        name: '',
        nextLink: '',
        simplexId: '',
      });
      driverReset();
      driverStore.setDriverApiReset(false);
      await driverStore.fetchDrivers();
      driverStore.setShowLoader(false);
    }

    if (searchPage === 'Fleet') {
      fleetStore.setShowLoader(true);
      setFilters((pre: any) => ({
        ...pre,
        expirationEndDate: null,
        expirationStartDate: null,
        limit: API_LIMIT,
        nextLink: '',
        simplexId: '',
        unitNumber: '',
        unitType: '',
        vinNumber: '',
      }));

      fleetStore.setFilters({
        expirationEndDate: '',
        expirationStartDate: '',
        limit: API_LIMIT,
        nextLink: '',
        simplexId: '',
        unitNumber: '',
        unitType: '',
        vinNumber: '',
      });
      fleetReset();
      fleetStore.setFleetApiReset(false);
      await fleetStore.fetchFleets();
      fleetStore.setShowLoader(false);
    }

    if (searchPage === 'To-Dos' || searchPage === 'ToDos') {
      todoStore.setShowLoader(true);
      setFilters((pre: any) => ({
        ...pre,
        categories: [],
        dueIn: '',
        firstName: '',
        lastName: '',
        limit: API_LIMIT,
        make: '',
        nextLink: '',
        simplexId: '',
        type: '',
        unitNumber: '',
        vinNumber: '',
        year: '',
      }));

      todoStore.setFilters({
        categories: [],
        dueIn: '',
        firstName: '',
        lastName: '',
        limit: API_LIMIT,
        make: '',
        nextLink: '',
        simplexId: '',
        trainingAssignedTo: '',
        trainingReason: '',
        type: '',
        unitNumber: '',
        vinNumber: '',
        year: '',
      });

      todoReset();
      todoStore.setTodoApiReset(false);
      await todoStore.fetchTodos();
      todoStore.setShowLoader(false);
    }

    if (searchPage === 'Notifications') {
      notificationStore.setNotificationHeaderSearchParams({
        description: '',
        // nextLink: '',
        title: '',
        type: '',
      });
      notificationReset();
      setFilters({
        description: '',
        // nextLink: '',
        title: '',
        type: '',
      });
    }

    if (searchPage === 'Users') {
      adminUsersStore.setShowLoader(true);
      setFilters((pre: any) => ({
        ...pre,
        limit: API_LIMIT,
        name: '',
        nextLink: '',
      }));

      adminUsersStore.setFilters({
        limit: API_LIMIT,
        name: '',
        nextLink: '',
      });
      adminReset();
      adminUsersStore.setAdminApiReset();
      await adminUsersStore.fetchAdmins();
      adminUsersStore.setShowLoader(false);
    }

    // handleClose();
  };

  const submitDriverFilter = useCallback(
    async (data: DriverSearchFormInputs) => {
      driverStore.setShowLoader(true);
      driverStore.setFilters(filter);
      const params: any = {};
      data.name != '' ? (params['name'] = data.name) : null;
      data.simplexId != '' ? (params['simplexId'] = data.simplexId) : null;
      data.driverType != '' ? (params['driverType'] = data.driverType) : null;

      const searchRes = await driverStore.fetchDriversBySearchBased(params);
      handleClose();

      if (searchRes.isErr()) {
        enqueueSnackbar(String(searchRes.error.message), SnackBarConfig('e'));
      }
      driverStore.setShowLoader(false);
    },
    [driverStore, enqueueSnackbar, filter, handleClose],
  );

  const submitFleetFilter = useCallback(
    async (data: FleetSearchFormInputs) => {
      fleetStore.setShowLoader(true);
      fleetStore.setFleetApiReset(false);
      fleetStore.setFilters(filter);
      const params: any = {};
      data.unitNumber != ''
        ? (params['unitNumber'] = `${data.unitNumber}`)
        : null;
      data.simplexId != '' ? (params['simplexId'] = `${data.simplexId}`) : null;
      data.vinNumber != '' ? (params['vinNumber'] = `${data.vinNumber}`) : null;
      data.unitType != '' ? (params['unitType'] = data.unitType) : null;
      if (data.expirationEndDate) {
        params.expirationEndDate = data.expirationEndDate;
        params.expirationStartDate = data.expirationStartDate;
      }

      const searchRes = await fleetStore.fetchFleetsBySearchBased(params);
      handleClose();

      if (searchRes.isErr()) {
        enqueueSnackbar(String(searchRes.error.message), SnackBarConfig('e'));
      }
      fleetStore.setShowLoader(false);
    },
    [fleetStore, enqueueSnackbar, filter, handleClose],
  );

  const submitTodoFilter = useCallback(
    async (data: TodoSearchFormInputs) => {
      todoStore.setShowLoader(true);
      if (data.searchType === 'Unit') {
        todoStore.setFilters({
          ...filter,
          firstName: '',
          lastName: '',
          simplexId: '',
        });
      } else {
        todoStore.setFilters({
          ...filter,
          make: '',
          unitNumber: '',
          vinNumber: '',
          year: '',
        });
      }

      if (todoStore.Filters.categories.length > 1) {
        todoStore.setFilters({
          ...filter,
          firstName: '',
          lastName: '',
          make: '',
          simplexId: '',
          unitNumber: '',
          vinNumber: '',
          year: '',
        });
      }

      // todoStore.setFilters(filter);

      const params: any = {};
      if (data.searchType === 'Driver') {
        data.firstName != '' && todoStore.Filters.firstName != ''
          ? (params['firstName'] = data.firstName)
          : null;
        data.lastName != '' && todoStore.Filters.lastName != ''
          ? (params['lastName'] = data.lastName)
          : null;
        data.simplexId != '' && todoStore.Filters.simplexId != ''
          ? (params['simplexId'] = data.simplexId)
          : null;
      }

      if (data.searchType === 'Unit') {
        data.year != '' && todoStore.Filters.year != ''
          ? (params['year'] = data.year)
          : null;
        data.make != '' && todoStore.Filters.make != ''
          ? (params['make'] = data.make)
          : null;
        data.vinNumber != '' && todoStore.Filters.vinNumber != ''
          ? (params['vinNumber'] = data.vinNumber)
          : null;
        data.unitNumber != '' && todoStore.Filters.unitNumber != ''
          ? (params['unitNumber'] = data.unitNumber)
          : null;
      }
      if (data.searchType === 'Training') {
        params['trainingAssignedTo'] = data.trainingAssignedTo || '';
        params['trainingReason'] = data.trainingReason || '';
      }
      data.dueIn != '' && todoStore.Filters.dueIn != ''
        ? (params['dueIn'] = data.dueIn)
        : null;
      data.type != '' && todoStore.Filters.type != ''
        ? (params['type'] = data.type)
        : null;

      const searchRes = await todoStore.fetchTodosBySearchBased(params);
      handleClose();

      if (searchRes.isErr()) {
        enqueueSnackbar(String(searchRes.error.message), SnackBarConfig('e'));
      }
      todoStore.setShowLoader(false);
    },
    [todoStore, enqueueSnackbar, filter, handleClose],
  );

  const submitAdminFilter = useCallback(
    async (data: UsersSearchFormInputs) => {
      adminUsersStore.setShowLoader(true);
      adminUsersStore.setFilters(filter);
      const params: any = {};
      data.name != '' ? (params['name'] = data.name) : null;

      const searchRes = await adminUsersStore.fetchAdminsBySearchBased(params);
      handleClose();

      if (searchRes.isErr()) {
        enqueueSnackbar(String(searchRes.error.message), SnackBarConfig('e'));
      }
      adminUsersStore.setShowLoader(false);
    },
    [adminUsersStore, enqueueSnackbar, filter, handleClose],
  );

  const getSearchType = (categories: string[]): string => {
    if (categories.length !== 1) return '';
    if (categories.includes('Driver')) return 'Driver';
    if (categories.includes('Fleet')) return 'Unit';
    if (categories.includes('Company')) return '';
    if (categories.includes('Training')) return 'Training';
    return '';
  };

  const handleChangeInCategories = (categories: string[]) => {
    const searchType = getSearchType(categories);
    setTodoControlValue('searchType', searchType);
  };

  return (
    <Menu
      id={searchPage}
      tabIndex={1}
      className="filter"
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        handleClose();
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      <Grid style={{ padding: '1rem' }}>
        {searchPage === 'Drivers' && (
          <DriverSearchForm
            {...{
              classes,
              driverController,
              driverStore,
              driverSubmit,
              filter,
              resetFilter,
              searchPage,
              setFilters,
              submitDriverFilter,
            }}
          />
        )}
        {searchPage === 'Fleet' && (
          <FleetSearchForm
            {...{
              classes,
              filter,
              fleetController,
              fleetStore,
              fleetSubmit,
              resetFilter,
              searchPage,
              setFilters,
              submitFleetFilter,
            }}
          />
        )}
        {searchPage === 'To-Dos' && (
          <TodoSearchForm
            onCategoryChange={handleChangeInCategories}
            searchTypeOfTodoForm={searchTypeOfTodoForm}
            {...{
              authStore,
              classes,
              filter,
              resetFilter,
              searchPage,
              setFilters,
              setTodoControlValue,
              submitTodoFilter,
              todoController,
              todoReset,
              todoStore,
              todoSubmit,
              userStore,
            }}
          />
        )}

        {location.pathname.includes('/ToDos-of') && (
          <TrendsTodoSearch
            onCategoryChange={handleChangeInCategories}
            searchTypeOfTodoForm={searchTypeOfTodoForm}
            onClose={handleClose}
            {...{
              authStore,
              classes,
              filter,
              resetFilter,
              searchPage,
              setFilters,
              submitTodoFilter,
              todoController,
              todoReset,
              todoStore,
              todoSubmit,
              userStore,
            }}
          />
        )}

        {searchPage === 'Notifications' && (
          <NotificationSearchForm
            {...{
              classes,
              filter,
              handleClose,
              notificationController,
              notificationSubmit,
              resetFilter,
              searchPage,
              setFilters,
            }}
          />
        )}

        {searchPage === 'Users' && (
          <AdminSearchForm
            {...{
              adminController,
              adminSubmit,
              adminUsersStore,
              classes,
              filter,
              resetFilter,
              searchPage,
              setFilters,
              submitAdminFilter,
            }}
          />
        )}
        {searchPage === 'Inspections & Violations' && (
          <InspectionsSearchForm {...{ classes }} onClose={handleClose} />
        )}
        {searchPage === 'Violations' &&
          !location.pathname.includes('/Violations-of') && (
            <ViolationsSearchForm {...{ classes }} onClose={handleClose} />
          )}
        {searchPage === 'Accidents' &&
          !location.pathname.includes('/Crashes-of') && (
            <AccidentsSearchForm {...{ classes }} onClose={handleClose} />
          )}

        {isTrendDetailsPage(location.pathname) && (
          <TrendDetailsSearchForm classes={classes} onClose={handleClose} />
        )}

        {searchPage === 'Training' &&
          location.pathname === TrainingRoute.INSPECTIONS && (
            <TrainingInspectionsSearchForm
              classes={classes}
              onClose={handleClose}
            />
          )}

        {searchPage === 'Training' &&
          location.pathname === TrainingRoute.ACCIDENTS && (
            <TrainingAccidentsSearchForm
              classes={classes}
              onClose={handleClose}
            />
          )}

        {searchPage === 'Training' &&
          location.pathname === TrainingRoute.ASSIGNED && (
            <TrainingAssignedSearchForm
              classes={classes}
              onClose={handleClose}
            />
          )}

        {searchPage === 'Training' &&
          location.pathname === TrainingRoute.COMPLETED && (
            <TrainingCompletedSearchForm
              classes={classes}
              onClose={handleClose}
            />
          )}
      </Grid>
    </Menu>
  );
};

export default SearchFilter;
