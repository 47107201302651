import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import SortingIcon from '../../../components/SortingIcon/SortingIcon';
import useStyles from './todoStyle';

const TodoHeader: React.FC<{
  todoHeader: any;
  todoStore: any;
}> = ({ todoHeader, todoStore }) => {
  const classes = useStyles();
  const sortBY = async (e: any) => {
    todoStore.setSortBy(e);
    todoStore.setSortDirection(
      todoStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    todoStore.setShowLoader(true);
    await todoStore.fetchTodosBySorting(true);
    todoStore.setShowLoader(false);
  };

  return (
    <TableRow>
      {todoHeader.map(
        (header: any, index: number) =>
          header.show && (
            <TableCell key={index} className={classes.tableHeaderBg}>
              <Grid item xs={12}>
                <Typography
                  component={'div'}
                  noWrap
                  style={{ alignItems: 'center', display: 'flex' }}>
                  <Typography
                    component={'div'}
                    sx={{
                      color:
                        todoStore.SortBy === header.name
                          ? todoStore.Status === 'Open'
                            ? '#241A2E'
                            : todoStore.IfClickedSortIcon
                            ? '#241A2E'
                            : '#979598'
                          : '#979598',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '12px',
                      letterSpacing: 0,
                      lineHeight: '14px',
                    }}>
                    {header.lable}
                  </Typography>

                  {header !== '' && header.name !== '' && (
                    <SortingIcon
                      {...{ sortBY, sortByName: header.name, store: todoStore }}
                    />
                  )}
                </Typography>
              </Grid>
            </TableCell>
          ),
      )}
    </TableRow>
  );
};

export default observer(TodoHeader);
