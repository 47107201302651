import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { ContactData, GetContactsDTO, LoginDTO } from '../../types';
import { RootStore } from '..';

export class AuthApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  emailCheck = (params: { email: string }) => {
    const { email } = params;
    const {
      Login: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<GetContactsDTO>({
      data: {
        email,
      },
      method: Method,
      url: Endpoint,
    });
  };

  companyLogin = (values: ContactData) => {
    const {
      Contact: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<LoginDTO>({
      data: {
        password: values.password,
      },
      method: Method,
      url: Endpoint(values.contactId),
    });
  };

  stateList = () => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.States;

    return this.rootStore.apiStore.call<
      Array<{ index: number; fullName: string; code: string; id: string }>
    >({
      method: Method,
      url: Endpoint,
    });
  };

  getProfileAccess = (accessToken: string, isPortal: string) => {
    const {
      ProfileAccess: { Endpoint, Method },
    } = ApiRoutes.User;

    return this.rootStore.apiStore.call<any>({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-simplex-platform': isPortal,
      },
      method: Method,
      url: Endpoint,
    });
  };

  directCompanyLogin = (values: { accessToken: string | null }) => {
    const {
      DirectLogin: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<LoginDTO>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  exchangeToken(data: { id: string; accessToken: string }) {
    const {
      TokenXchange: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<LoginDTO>({
      data,
      method: Method,
      url: Endpoint,
    });
  }
}
