import { makeAutoObservable, toJS } from 'mobx';

import { EquipmentInspection } from '../types';
import { RootStore } from '.';

const InitialData: EquipmentInspection = {
  equipments: [],
};

export class TerminateEquipmentStore {
  private _terminateEquipment: EquipmentInspection = { ...InitialData };
  // private _initialApiRecord: EquipmentInspection = { ...InitialData };
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  get terminateEquipmentDetails(): EquipmentInspection {
    return { ...toJS(this._terminateEquipment) };
  }

  resetEquipmentInspection() {
    this._terminateEquipment = { equipments: [] };
    this._terminateEquipment.acknowledgmentForm = undefined;
  }

  addEquipmentDetails(equipmentArr: any) {
    // this._terminateEquipment.equipments.push(...equipmentArr);
    this._terminateEquipment.equipments = [...equipmentArr];
  }

  removeEquipmentDetail(index: number) {
    this._terminateEquipment.equipments.splice(index, 1);
  }

  setIndexEquipmentDetails(index: number, key: any, value: any) {
    const oldindexedObj: any = {
      ...this._terminateEquipment.equipments[index],
      [key]: value,
    };
    this._terminateEquipment.equipments[index] = oldindexedObj;
  }

  setAcknowledgmentData(data: acknowledgmentFormPayload) {
    this._terminateEquipment.acknowledgmentForm = data;
  }

  getAcknowledgmentVaidation() {
    if (
      !this._terminateEquipment.acknowledgmentForm?.consent &&
      !this._terminateEquipment.acknowledgmentForm?.name &&
      !this._terminateEquipment.acknowledgmentForm?.position
    )
      return true;
    else return false;
  }

  async addEquipmentInspection() {
    const { equipments } = this._terminateEquipment;
    const apiData = {
      acknowledgmentUnitFormRequest:
        this._terminateEquipment.acknowledgmentForm,
      equipment: equipments.map((e: any) => {
        const returnData: any = {
          dotFtBundle: e.dotFtBundle,
          equipmentType: e.equipmentType,
          grossVehicleWeight: e.grossVehicleWeight,
          safetyCarrierId: e.safetyCarrierId,
          shouldTerminate: true,
          status: 'Active',
          surrenderPermitsOrRegistrations: e.terminateStatus === 'yes',
          unitId: e.unitId,
          unitNumber: e.unitNumber,
          unitType: e.unitType,
          vinNumber: e.vinNumber,
        };
        if (e.simplexId) {
          returnData.simplexId = e.simplexId;
        }
        if (e.primaryDriverContactId) {
          returnData.primaryDriverContactId = e.primaryDriverContactId;
        }
        return {
          ...returnData,
        };
      }),
    };

    const response: any =
      await this.rootStore.apiStore.terminateEquipmentApiStore.addTerminateEquipment(
        apiData,
      );

    return response;
  }
}
