import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Controller } from 'react-hook-form';

import { useCustomStore } from '../../../hooks';
import { AdminNotificationSearchParam } from '../../../types';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type fleetFormTypes = {
  classes: any;
  filter: any;
  resetFilter: any;
  searchPage: any;
  setFilters: (src: any) => void;
  notificationController: any;
  notificationSubmit: any;
  handleClose: () => void;
};

const NotificationSearchForm: React.FC<fleetFormTypes> = (
  props: fleetFormTypes,
) => {
  const { notificationStore } = useCustomStore();
  const {
    classes,
    filter,
    resetFilter,
    searchPage,
    setFilters,
    notificationController,
    notificationSubmit,
    handleClose,
  } = props;

  const handleSubmit = (data: AdminNotificationSearchParam) => {
    notificationStore.setNotificationHeaderSearchParams({
      ...data,
    });
    handleClose();
  };

  return (
    <form onSubmit={notificationSubmit(handleSubmit)} id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Title
        </Typography>

        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={notificationController}
                defaultValue=""
                name="title"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      defaultValue={filter.title}
                      value={filter.title}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          title: e,
                        }));
                        // notificationStore.setNotificationHeaderSearchParams({
                        //   title: e,
                        // });
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Description
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={notificationController}
                defaultValue=""
                name="description"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      defaultValue={filter.description}
                      value={filter.description}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          description: e,
                        }));
                        // notificationStore.setNotificationHeaderSearchParams({
                        //   description: e,
                        // });
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Type
        </Typography>
        <Grid className={classes.dflexA} style={{ padding: '0.5rem 0' }}>
          <Controller
            control={notificationController}
            defaultValue=""
            name="type"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <SelectInput
                  name={name}
                  isRefreshValue={true}
                  inputRefObj={ref}
                  defaultValue=""
                  value={filter.type}
                  size={'small'}
                  onChangeText={(e: any) => {
                    onChange(e);
                    setFilters((pre: any) => ({
                      ...pre,
                      type: e,
                    }));
                  }}
                  isEditable={false}
                  selectClassName={classes.selectWhiteBg}
                  optionsList={[
                    {
                      label: 'Simplex Notice',
                      optionClassName: classes.optionsClassName,
                      value: 'ad-hoc',
                    },
                    {
                      label: 'New To-Do',
                      optionClassName: classes.optionsClassName,
                      value: 'new',
                    },
                    {
                      label: 'Weekly Summary',
                      optionClassName: classes.optionsClassName,
                      value: 'weekly',
                    },
                  ]}
                />
              );
            }}
          />
        </Grid>

        {/* <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Next Link
        </Typography>
        <Grid className={classes.borderBottom}>
          Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={notificationController}
                defaultValue=""
                name="nextLink"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      defaultValue={filter.nextLink}
                      value={filter.nextLink}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          nextLink: e,
                        }));
                        // notificationStore.setNotificationHeaderSearchParams({
                        //   nextLink: e,
                        // });
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid> */}
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              disabled={false}
              tabIndex={0}
              type="button"
              onClick={resetFilter}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            disabled={false}
            className={classes.filterBtn}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(NotificationSearchForm);
