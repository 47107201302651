import makeStyles from '@mui/styles/makeStyles';

const useFormStyles = makeStyles(() => ({
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '88%',
    position: 'absolute',
    width: '92%',
    zIndex: 2,
  },
}));

export default useFormStyles;
