import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore } from '../../../hooks';
import { TrainingType } from '../../../types';
import { DATE_PICKER_DATE_FORMAT } from '../../../utils/helper';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { SelectDatePicker } from '../../Forms/datePicker';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type SearchFormProps = {
  classes: any;
  onClose: () => void;
};

type SearchFormType = {
  assignedBy: string;
  assignedById: string;
  assignedDateEnd: string;
  assignedDateStart: string;
  contactId: string;
  contactName: string;
  dueDateEnd: string;
  dueDateStart: string;
  videoAssigned: string;
  reason: string;
  status: string;
  trainingType?: TrainingType;
  inspectionReportNumber?: string;
  accidentNumber?: string;
};

const TrainingAssignedSearchForm: React.FC<any> = (props: SearchFormProps) => {
  const { classes, onClose } = props;
  const { trainingStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const [statuses, setStatuses] = useState<any[]>([]);
  const [reasons, setReasons] = useState<any[]>([]);
  const [assignees, setAssignees] = useState<any[]>([]);

  // Training drop down options list
  const trainingTypeOptions = [
    {
      label: 'All',
      optionClassName: classes.optionsClassName,
      value: TrainingType.ALL,
    },
    {
      label: 'Document-based',
      optionClassName: classes.optionsClassName,
      value: TrainingType.DOCUMENT,
    },
    {
      label: 'Video-based',
      optionClassName: classes.optionsClassName,
      value: TrainingType.VIDEO,
    },
  ];

  const { control, handleSubmit, setValue, watch } = useForm<SearchFormType>({
    defaultValues: {
      accidentNumber: trainingStore.TrainingAssignedFilters.accidentNumber,
      assignedBy: trainingStore.TrainingAssignedFilters.assignedBy,
      assignedById: trainingStore.TrainingAssignedFilters.assignedById,
      assignedDateEnd: trainingStore.TrainingAssignedFilters.assignedDateEnd,
      assignedDateStart:
        trainingStore.TrainingAssignedFilters.assignedDateStart,
      contactId: trainingStore.TrainingAssignedFilters.contactId,
      contactName: trainingStore.TrainingAssignedFilters.contactName,
      dueDateEnd: trainingStore.TrainingAssignedFilters.dueDateEnd,
      dueDateStart: trainingStore.TrainingAssignedFilters.dueDateStart,
      inspectionReportNumber:
        trainingStore.TrainingAssignedFilters.inspectionReportNumber,
      reason: '',
      status: '',
      trainingType: trainingStore.TrainingAssignedFilters.trainingType,
      videoAssigned: trainingStore.TrainingAssignedFilters.videoAssigned,
    },
  });

  const getTrainingAssignmentStatuses = useCallback(async () => {
    if (trainingStore.TrainingAssignmentStatusesData.length > 0) {
      setStatuses(trainingStore.TrainingAssignedAssignmentStatusesData);
      setValue('status', trainingStore.TrainingAssignedFilters.status || '');
    } else {
      trainingStore.setTrainingAssignmentStatusesLoader(true);
      const resp = await trainingStore.getTrainingAssignmentStatuses();
      if (resp.isErr()) {
        enqueueSnackbar(
          'Unable to fetch Training Assignment Statuses',
          SnackBarConfig('e'),
        );
      } else {
        setStatuses(trainingStore.TrainingAssignedAssignmentStatusesData);
        setValue('status', trainingStore.TrainingAssignedFilters.status || '');
      }
      trainingStore.setTrainingAssignmentStatusesLoader(false);
    }
  }, [enqueueSnackbar, setValue, trainingStore]);

  const getTrainingAssignmentReasons = useCallback(async () => {
    trainingStore.setTrainingAssignmentReasonsLoader(true);
    const resp = await trainingStore.getTrainingAssignmentReasons({
      showSystemReason: true,
    });
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Assignment Reasons',
        SnackBarConfig('e'),
      );
    } else {
      setReasons(trainingStore.TrainingAssignmentReasonsData);
      setValue('reason', trainingStore.TrainingAssignedFilters.reason || '');
    }
    trainingStore.setTrainingAssignmentReasonsLoader(false);
  }, [enqueueSnackbar, setValue, trainingStore]);

  const getAllTrainingAssignees = useCallback(async () => {
    if (!authStore.IsCompanyAdminLogin) {
      return;
    }
    if (trainingStore.TrainingAssigneesData.length > 0) {
      setAssignees(trainingStore.TrainingAssigneesData);
      setValue(
        'contactId',
        trainingStore.TrainingAssignedFilters.contactId || '',
      );
      setValue(
        'assignedById',
        trainingStore.TrainingAssignedFilters.assignedById || '',
      );
    } else {
      trainingStore.setTrainingAssigneesLoader(true);
      const resp = await trainingStore.getAllUsersForTraining({
        limit: '5000',
        searchText: '',
        userType: 'backOfficePersonnel',
      });
      if (resp.isOk()) {
        const { response } = resp.value;
        setAssignees(response);
        trainingStore.setTrainingAssigneesData(response);
      } else {
        enqueueSnackbar(
          'Unable to fetch Training Assignees',
          SnackBarConfig('e'),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, setValue, trainingStore]);

  useEffect(() => {
    getTrainingAssignmentStatuses();
    getTrainingAssignmentReasons();
    getAllTrainingAssignees();
  }, [
    getTrainingAssignmentStatuses,
    getTrainingAssignmentReasons,
    getAllTrainingAssignees,
    trainingStore,
  ]);

  const onFiltersSubmit = useCallback(
    async (values) => {
      trainingStore.setTrainingAssignedFilters(values);
      trainingStore.setTrainingAssignedLoader(true);
      const resp = await trainingStore.getInitialTrainingAssigned();
      if (resp.isErr()) {
        enqueueSnackbar(
          'Unable to fetch Training Assigned Assignments',
          SnackBarConfig('e'),
        );
      }
      trainingStore.setTrainingAssignedLoader(false);
      onClose();
    },
    [enqueueSnackbar, onClose, trainingStore],
  );

  const resetFilters = useCallback(async () => {
    trainingStore.resetTrainingAssignedFilters();
    trainingStore.setTrainingAssignedLoader(true);
    const resp = await trainingStore.getInitialTrainingAssigned();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Training Assigned Assignments',
        SnackBarConfig('e'),
      );
    }
    trainingStore.setTrainingAssignedLoader(false);
    onClose();
  }, [enqueueSnackbar, onClose, trainingStore]);

  return (
    <form
      id="training-assigned-search-form"
      onSubmit={handleSubmit(onFiltersSubmit)}>
      {authStore.IsCompanyAdminLogin && (
        <FormControl fullWidth>
          <Typography component={'h3'} className={classes.filterLabel}>
            Assignee Name
          </Typography>
          <Grid className={classes.borderBottom}>
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="contactId"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        size={'small'}
                        onChangeText={onChange}
                        isEditable={assignees.length === 0}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        optionsList={assignees.map((r) => ({
                          label: r.name,
                          optionClassName: classes.optionsClassName,
                          value: r.id,
                        }))}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
      <FormControl fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Training Assigned
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="videoAssigned"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Reason
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="reason"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={reasons.length === 0}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={reasons.map((r) => ({
                        label: r.name,
                        optionClassName: classes.optionsClassName,
                        value: r.id,
                      }))}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Status
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="status"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={statuses.length === 0}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={statuses.map((s) => ({
                        label: s.name,
                        optionClassName: classes.optionsClassName,
                        value: s.id,
                      }))}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Type
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                name="trainingType"
                defaultValue={TrainingType.ALL}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={trainingTypeOptions}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      {authStore.IsCompanyAdminLogin && (
        <FormControl fullWidth>
          <Typography component={'h3'} className={classes.filterLabel}>
            Assigned By
          </Typography>
          <Grid className={classes.borderBottom}>
            <Grid container className={classes.searcBox}>
              <Grid item md={2} className={classes.dflexJA}>
                <img className={classes.filterIcon} src={search} alt="search" />
              </Grid>
              <Grid item md={10}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="assignedById"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        size={'small'}
                        onChangeText={onChange}
                        isEditable={assignees.length === 0}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        optionsList={assignees.map((r) => ({
                          label: r.name,
                          optionClassName: classes.optionsClassName,
                          value: r.id,
                        }))}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
      <Typography component={'h3'} className={classes.filterLabel}>
        Assigned Date
      </Typography>
      <div className={clsx(classes.borderBottom, classes.dateFilter)}>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            From:
          </Typography>
          <Grid>
            <Grid container className={classes.searcBox}>
              <Grid item md={10} className="date-picker">
                <Controller
                  control={control}
                  name="assignedDateStart"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectDatePicker
                        value={value}
                        isRefreshValue
                        inputRefObj={ref}
                        name={name}
                        onChangeDate={(d) => {
                          const formattedValue = moment(d).format(
                            DATE_PICKER_DATE_FORMAT,
                          );
                          onChange(formattedValue);
                        }}
                        className={classes.selectClassName}
                        maxDate={
                          watch('assignedDateEnd')
                            ? new Date(watch('assignedDateEnd'))
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            To:
          </Typography>
          <Grid>
            <Grid container className={classes.searcBox}>
              <Grid item md={10} className="date-picker">
                <Controller
                  control={control}
                  name="assignedDateEnd"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectDatePicker
                        value={value}
                        isRefreshValue
                        inputRefObj={ref}
                        name={name}
                        onChangeDate={(d) => {
                          const formattedValue = moment(d).format(
                            DATE_PICKER_DATE_FORMAT,
                          );
                          onChange(formattedValue);
                        }}
                        className={classes.selectClassName}
                        minDate={
                          watch('assignedDateStart')
                            ? new Date(watch('assignedDateStart'))
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </div>
      <Typography component={'h3'} className={classes.filterLabel}>
        Due Date
      </Typography>
      <div className={clsx(classes.borderBottom, classes.dateFilter)}>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            From:
          </Typography>
          <Grid>
            <Grid container className={classes.searcBox}>
              <Grid item md={10} className="date-picker">
                <Controller
                  control={control}
                  name="dueDateStart"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectDatePicker
                        value={value}
                        isRefreshValue
                        inputRefObj={ref}
                        name={name}
                        onChangeDate={(d) => {
                          const formattedValue = moment(d).format(
                            DATE_PICKER_DATE_FORMAT,
                          );
                          onChange(formattedValue);
                        }}
                        className={classes.selectClassName}
                        maxDate={
                          watch('dueDateEnd')
                            ? new Date(watch('dueDateEnd'))
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
        <FormControl className="date-control">
          <Typography component={'h6'} className="label">
            To:
          </Typography>
          <Grid>
            <Grid container className={classes.searcBox}>
              <Grid item md={10} className="date-picker">
                <Controller
                  control={control}
                  name="dueDateEnd"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectDatePicker
                        value={value}
                        isRefreshValue
                        inputRefObj={ref}
                        name={name}
                        onChangeDate={(d) => {
                          const formattedValue = moment(d).format(
                            DATE_PICKER_DATE_FORMAT,
                          );
                          onChange(formattedValue);
                        }}
                        className={classes.selectClassName}
                        minDate={
                          watch('dueDateStart')
                            ? new Date(watch('dueDateStart'))
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </div>
      <FormControl fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Report Number
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="inspectionReportNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <FormControl fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Accident Number
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="accidentNumber"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              type="button"
              onClick={resetFilters}
              disabled={trainingStore.TrainingAssignedLoader}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            className={classes.filterBtn}
            type="submit"
            disabled={trainingStore.TrainingAssignedLoader}>
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(TrainingAssignedSearchForm);
