import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  '.MuiFormHelperText-root': {
    '&.Mui-error': {
      marginTop: '0 !important',
    },
  },
  actFilter: {
    padding: '1.5rem 0 1rem 0',
  },
  activeBox: {
    alignItems: 'center',
    backgroundColor: '#E8932E !important',
    borderRadius: '7px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.93px',
    opacity: '1 !important',
    width: '85px',
  },
  asend: {
    alignItems: 'center',
    color: '#979598 !important',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '0.75rem !important ',
    paddingRight: '1rem',
  },
  borderBottom: {
    borderBottom: '1px solid #403849',
    marginBottom: '1rem !important',
  },
  cancelLink: {
    color: '#979797',
    cursor: 'pointer',
    fontSize: '0.9rem',
    letterSpacing: '0',
  },
  cardHeading: {
    alignItems: 'center',
    display: 'flex',
    paddingRight: '12px',
  },
  cardMainHeading: {
    alignSelf: 'center',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1.125rem !important',
    padding: '14px',
  },
  close: {
    color: '#D1D1D1 !important',
    fontSize: '0.875rem !important',
    marginLeft: '0.3rem !important',
  },
  dataQChallenge: {
    '& .data-q-details': {
      height: 'calc(100vh - 150px)',
      overflowX: 'unset',
      overflowY: 'auto',
      paddingRight: '3rem',
    },
    '& .data-q-loader': {
      alignItems: 'center',
      display: 'flex',
      height: 'calc(100vh - 150px)',
      justifyContent: 'center',
      overflowX: 'unset',
      overflowY: 'auto',
      paddingRight: '3rem',
    },
    background: '#FFFFFF',
    paddingLeft: '3rem',
    width: '100%',
  },
  dataQChallengeDetails: {
    background: '#FFFFFF',
    paddingTop: '2rem',
    width: '100%',
  },
  dataQChallengeForm: {
    background: '#FFFFFF',
    paddingBottom: '2rem',
    width: '100%',
  },
  dayBox: {
    alignItems: 'center',
    backgroundColor: '#403849',
    borderRadius: '7px',
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    height: '43px',
    justifyContent: 'center',
    letterSpacing: '0.93px',
    opacity: '0.43',
    width: '85px',
  },
  dflexA: {
    alignItems: 'center',
    display: 'flex',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
    margin: '1rem 0',
    marginLeft: 'unset !important',
  },
  docHelperText: {
    color: '#DA5A30',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.7rem',
    fontStyle: 'italic',
    marginTop: '1rem',
    paddingLeft: '0.3rem',
  },
  docUploadDialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      padding: '0.5rem 1rem 1rem 1rem !important',
      width: '40%',
    },
    '& .cancel-link': {
      color: '#979797',
      cursor: 'pointer',
      fontSize: '1rem',
      letterSpacing: '0',
    },
    '& .dialog-action': {
      padding: '0 1.5rem !important',
    },
    '& .header-title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    '& .header-wrapper': {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .submit-btn-txt': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.9375rem',
      fontWeight: '500',
      height: '22px',
      letterSpacing: '1px',
      lineHeight: '22px',
      textAlign: 'center',
    },
  },
  fIcon: {
    alignItems: 'center',
    border: '2px solid #E7E7E7',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
  },
  filteIcon: {
    width: '18px',
  },
  filterBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#fff !important',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  filterItem: {
    alignItems: 'center',
    backgroundColor: '#E8E8E8',
    borderRadius: '24px',
    color: '#979598 !important',
    cursor: 'pointer !important',
    display: 'flex',
    fontSize: '0.875rem !important',
    height: '34px',
    justifyContent: 'space-between',
    margin: '0 0.5rem 0.5rem 0!important',
    minWidth: '72px',
    padding: '0 0.7rem',
  },
  filterList: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 1rem',
    width: '100%',
  },
  filterText: {
    color: '#979598 !important',
    cursor: 'pointer !important',
    fontSize: '0.875rem !important',
  },
  fimg: {
    width: '28px',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  formTitle: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '1.2rem',
    paddingBottom: '1rem',
  },
  formTypeLabel: {
    color: '#979598',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.85rem',
    fontStyle: 'italic',
    marginTop: '0.8rem',
  },
  gridItem: {
    '& .support-doc-actions': {
      '& .icon': {
        color: '#979598',
        cursor: 'pointer',
        marginLeft: '2rem',
      },
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'FiraSans-Regular',
    },
    '& .support-doc-title': {
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.85rem',
    },
    '& .title': {
      fontFamily: 'FiraSans-Regular',
    },
    '& .value': {
      fontFamily: 'FiraSans-Medium',
    },
    '&.bg': {
      background: '#E8E8E8',
    },
    alignItems: 'center',
    color: '#241A2E',
    display: 'flex',
    fontSize: '0.8rem',
    justifyContent: 'space-between',
    padding: '0.3rem 0.3rem',
  },
  gutterPadding: {
    marginBottom: '0.5rem',
  },
  hIcon: {
    cursor: 'pointer',
    paddingRight: '0.5rem',
  },
  infoHeader: {
    '& .title': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '1rem',
    },
  },
  infoShowHide: {
    '& .icon': {
      cursor: 'pointer',
    },
    '& .title': {
      color: '#979598',
      cursor: 'pointer',
      fontFamily: 'FiraSans-Regular',
      fontSize: '0.8rem',
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  infoSubHeader: {
    color: '#D8BF57',
    fontFamily: 'FiraSans-Medium',
    fontSize: '1rem',
    marginTop: '0.5rem',
    padding: '0.3rem 0',
  },
  inputF: {
    '&::placeholder': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      opacity: '0.43',
    },
    '&:focusVisible': {
      outline: 'unset !important',
    },
    background: 'unset !important',
    border: 'unset !important',
    color: '#ffff !important',
    width: '100%',
  },
  loader: {
    alignItems: 'center',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    minHeight: 'calc(100vh - 200px)',
  },
  padcss: {
    paddingLeft: '0px !important',
    paddingTop: '0px !important',
  },
  resetF: {
    alignItems: 'center',
    color: '#777777 !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Bold',
    fontSize: '0.75rem !important',
    justifyContent: 'center !important',
    letterSpacing: '0.8px',
    textDecoration: 'underline solid #777777',
  },
  searcBox: {
    alignItems: 'center',
    backgroundColor: '#403849',
    borderRadius: '7px',
    display: 'flex',
    height: '43px',
    marginBottom: '1rem !important',
  },
  selectClassName: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },
  submitBtn: {
    '&:hover': {
      backgroundColor: '#DEC330',
    },
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#FFFFFF',
    height: '44px',
    padding: '0.5rem 3rem',
    width: 'fit-content',
  },
  uploadBtn: {
    '& .upload-btn': {
      border: '1px solid #CBCBCB',
      borderRadius: '0.5rem',
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: '0.8rem',
      padding: '0.4rem 1rem',
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  violationsGird: {
    '& .item': {
      '& .title': {
        color: '#979598 !important',
        fontFamily: 'FiraSans-Regular',
        fontSize: '0.85rem !important',
        paddingRight: '10px !important',
      },
      alignItems: 'flex-end',
      display: 'flex',
    },
    '& .violations-list': {
      '& .list-container': {
        '& .default-value': {
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '0.85rem',
          letterSpacing: 0,
          lineHeight: '18px',
        },
        '& .violation-basic': {
          color: '#241A2E',
          fontFamily: 'FiraSans-Medium',
          fontSize: '1.05rem',
          letterSpacing: 0,
          marginTop: '-0.3rem !important',
          paddingLeft: '1rem',
        },
        '& .violation-code': {
          color: '#979598',
          fontFamily: 'FiraSans-Regular',
          fontSize: '0.85rem',
          letterSpacing: 0,
          paddingLeft: '0.4rem',
        },
        border: '2px solid #D1D3D4',
        borderRadius: '8px',
        marginBottom: '0.5rem !important',
        minHeight: '75px',
        padding: '1rem 0px 0px 0px !important',
      },
      '& .list-container:last-of-type': {
        marginBottom: '0 !important',
      },
      '& li': {
        padding: '0px !important',
      },
    },
  },
}));

export default useStyles;
