import { makeAutoObservable } from 'mobx';

import {
  CreateKentuckyPermitsInput,
  CreateNYAndNMStatePermitsInput,
  CreateOregonPermitsInput,
  NYAndNMStateDto,
  RequestType,
  StatesPermits,
} from '../types';
import { RootStore } from '.';

export class StatePermitsStore {
  private _NYAndNMStateFetchValues: NYAndNMStateDto | null = null;
  private _kentuckyStateFetchValues: {
    dotNumber: string;
    ucrExpDate: string;
  } | null = null;
  private _oregonStateFetchValues: { dotNumber: string } | null = null;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setNyAndNMStateValues(data: any | null) {
    this._NYAndNMStateFetchValues = data;
  }

  setKentuckyStateValues(
    data: {
      dotNumber: string;
      ucrExpDate: string;
    } | null,
  ) {
    this._kentuckyStateFetchValues = data;
  }

  setOregonValues(data: { dotNumber: string } | null) {
    this._oregonStateFetchValues = data;
  }
  setStatesValuesToEmpty() {
    this._NYAndNMStateFetchValues = null;
    this._kentuckyStateFetchValues = null;
    this._oregonStateFetchValues = null;
  }

  get NyAndNMStateInfo() {
    return this._NYAndNMStateFetchValues;
  }

  get KentuckyStateInfo() {
    return this._kentuckyStateFetchValues;
  }

  get OregonStateInfo() {
    return this._oregonStateFetchValues;
  }

  async getPredefinedStatesInfo(state: StatesPermits) {
    this.setStatesValuesToEmpty();
    const response =
      await this.rootStore.apiStore.statePermitsApiStore.getStatePermits(state);
    if (response.isOk()) {
      switch (state) {
        case StatesPermits.NewYork:
        case StatesPermits.NewMexico:
          this.setNyAndNMStateValues(response.value);
          break;
        case StatesPermits.Kentucky:
          this.setKentuckyStateValues(response.value);
          break;
        case StatesPermits.Oregon:
          this.setOregonValues(response.value);
          break;
        default:
          break;
      }
    }
    return response;
  }
  async createNYAndNMStatePermits(data: CreateNYAndNMStatePermitsInput) {
    const response =
      await this.rootStore.apiStore.statePermitsApiStore.createNYAndNMStatePermits(
        data,
      );
    return response;
  }

  async createKentuckyStatePermits(data: CreateKentuckyPermitsInput) {
    const response =
      await this.rootStore.apiStore.statePermitsApiStore.createKentuckyStatePermits(
        data,
      );
    return response;
  }

  async createOregonStatePermits(
    data:
      | CreateOregonPermitsInput
      | { requestType: RequestType; state: StatesPermits },
  ) {
    const response =
      await this.rootStore.apiStore.statePermitsApiStore.createOregonStatePermits(
        data,
      );
    return response;
  }
}
