import Button, { ButtonProps } from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

// import search from '../../../assets/images/h-search.svg';
import DialogMigrate from '../../../components/Dialog/DialogMigrate';
import { useStyles } from './styles';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

const SelectEquipment: React.FC<any> = (props: any) => {
  const { isOpen, closeDialog, powerUnitVin = '', trailerVin = '' } = props;
  const { control, getValues, setValue } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      equipmentType: '',
    },
    reValidateMode: 'onChange',
  });

  const classes = useStyles();

  const closeDialogWindow = useCallback(
    ({ equipmentType }) => {
      closeDialog && closeDialog({ equipmentType });
    },
    [closeDialog],
  );

  const handleRadioChange = (event: any) => {
    setValue('equipmentType', event.currentTarget.defaultValue);
  };

  const confirmSelection = () => {
    closeDialog({ equipmentType: getValues('equipmentType') });
  };

  return (
    <DialogMigrate
      aria-labelledby="select-equipment"
      className={classes.dialogSingle}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {
        closeDialogWindow({ equipmentType: '' });
      }}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Medium',
          fontSize: '18px',
          letterSpacing: 0,
          lineHeight: '26px',
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>Select Equipment</div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialogWindow({ reload: false });
            }}>
            CLOSE
          </Link>
        </div>
      </DialogTitle>
      <form>
        <DialogContent id="scroll" className={classes.bodyWrapper}>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: '#FFFFFF',
              marginBottom: '10px',
            }}>
            <Grid
              container
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                paddingBottom: '15px',
                paddingTop: '15px',
              }}>
              <Grid item xs={12} md={12} lg={12}>
                <p
                  style={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '14px',
                    lineHeight: '17px',
                    opacity: '43%',
                    paddingLeft: '14px',
                  }}>
                  Please select equipment to add to fleet
                </p>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  component={'div'}
                  sx={{
                    color: '#241A2E',
                    display: 'flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    paddingBottom: '4px',
                    paddingLeft: '30px',
                  }}>
                  Unit Type
                </Typography>
                <FormControl style={{ width: '100%' }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="primaryDriverName"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <RadioGroup
                          name="equipmentType"
                          onChange={handleRadioChange}
                          style={{
                            paddingLeft: '30px',
                          }}>
                          <FormControlLabel
                            value="power unit"
                            control={<Radio />}
                            label={
                              `Power Unit (${powerUnitVin as string})` as string
                            }
                          />
                          <FormControlLabel
                            value="trailer"
                            control={<Radio />}
                            label={
                              `Trailer (${trailerVin as string})` as string
                            }
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.jxy}>
          <BottomButton
            type={'button'}
            variant="contained"
            sx={{ width: '100%' }}
            onClick={confirmSelection}>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontFamily: 'FiraSans-Medium',
                fontSize: '0.9375rem',
                fontWeight: '500',
                height: '22px',
                letterSpacing: '1px',
                lineHeight: '22px',
                textAlign: 'center',
              }}>
              CONFIRM
            </Typography>
          </BottomButton>
        </DialogActions>
      </form>
    </DialogMigrate>
  );
};

export default observer(SelectEquipment);
