import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore } from '../../../../../hooks';
import {
  // RequestType,
  RoadTaxNewFormInputs,
  TodoRequest,
} from '../../../../../types';
import {
  getFileNamingConvention,
  getStateNameByStateId,
} from '../../../../../utils/fileName.config';
import {
  fillingPeriod,
  getTaxPermitsActivityList,
} from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { RoadTaxNewFormSchema } from '../../../../../utils/ValidatorSchema';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import RoadTaxesForm from './RoadTaxesForms/RoadTaxesForm';
import useStyles from './RoasTaxesStyles';

const TaxPermitsRoadTaxes: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const {
    authStore,
    roadTaxesStore,
    equipmentStore,
    activityListStore,
    fileStore,
    todoStore,
  } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  // const [equipment, setEquipment] = useState<Array<any>>([]);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  // const [fileValidation, setFileValidation] = useState<boolean>(false);
  const [notification, setNotification] = useState(false);

  // validation
  const {
    control: RTRenewControl,
    handleSubmit: RTRenewHandleSubmit,
    formState: { errors: RTRenewErrors },
    reset: RTRenewReset,
    getValues,
  } = useForm<RoadTaxNewFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      requestType: '',
      years: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(RoadTaxNewFormSchema),
  });

  const {
    fields,
    append,
    remove: arrayFieldRemove,
    update,
  } = useFieldArray({ control: RTRenewControl, name: 'years' });

  const cancelForm = useCallback(() => {
    RTRenewReset();
    equipmentStore.restSlecetedEquipment();
    const removedIndexArr: Array<number> = [];
    fields.map((e: any, index: number) => {
      removedIndexArr.push(index);
    });
    arrayFieldRemove(removedIndexArr);
    // setFileValidation(false);
  }, [RTRenewReset, arrayFieldRemove, equipmentStore, fields]);

  useEffect(() => {
    equipmentStore.restSlecetedEquipment();
  }, [equipmentStore]);

  const activityListRecall = useCallback(async () => {
    await getTaxPermitsActivityList(activityListStore);
  }, [activityListStore]);

  const fileUploadApi = useCallback(
    async (data: RoadTaxNewFormInputs) => {
      const filesRefArr: Array<{ unitId: string; fileIndex: number }> = [];
      const formData = new FormData();
      const fileData: Record<string, unknown>[] = [];
      let count = 0;
      data.years.map((ele: any) => {
        const filesDetails = equipmentStore.SelectedEquipments.find((val) => {
          return val.unitId === ele.unitId;
        });
        const { unitNumber = '', plateStateId = '' } = ele;

        const state = getStateNameByStateId(
          authStore.statesListOption,
          plateStateId,
        );

        if (filesDetails && filesDetails.files && filesDetails.files.length) {
          filesDetails.files.map((val: any) => {
            let name = val.name;
            let namePropOfFile;
            filesRefArr.push({ fileIndex: count, unitId: filesDetails.unitId });

            try {
              namePropOfFile = JSON.parse(name);
              name = namePropOfFile.name;
            } catch (error) {
              //
            }
            formData.append('files', val, name);
            fileData.push({
              fileName: getFileNamingConvention['CAB Card'].name({
                expirationDate: moment(namePropOfFile.expirationDate).format(
                  'MM/DD/YYYY',
                ),
                state,
                unitNumber,
              }),
              fileType: 'CAB Card',
              service: 'roadTaxes',
              type: 'unit',
              unit: {
                unitId: filesDetails.unitId,
              },
            });
            count = count + 1;
          });
        }
      });
      formData.append('data', JSON.stringify(fileData));

      const fileRes = await fileStore.postFile(formData);

      if (fileRes.isErr()) {
        enqueueSnackbar('File upload failed', SnackBarConfig('e'));
        return false;
      } else {
        const fileAPiResponse = fileRes.value;
        if (fileAPiResponse && fileAPiResponse.length) {
          fileAPiResponse.map((val: any) => {
            const unitId = filesRefArr.find(
              (ele) => ele.fileIndex === val.index,
            )?.unitId;
            const ind = data.years.findIndex((ele) => ele.unitId === unitId);
            if (ind !== -1) {
              data.years[ind].documents =
                data.years[ind].documents && data.years[ind].documents.length
                  ? data.years[ind].documents.concat([val])
                  : [val];
            }
          });
        }
        return data;
      }
    },
    [authStore, enqueueSnackbar, equipmentStore.SelectedEquipments, fileStore],
  );

  const reNewalFormOnSubmit = useCallback(
    async (data: RoadTaxNewFormInputs) => {
      if (data && data.years && data.years.length) {
        // if (
        //   data.requestType === RequestType.New &&
        //   equipmentStore.SelectedEquipments.some(
        //     (ele) => !ele.files || !ele.files.length,
        //   )
        // ) {
        //   setFileValidation(true);
        //   return;
        // }
        setBtnDisable(true);
        const bodyData: any = {
          equipment: data.years,
          requestType: data.requestType,
        };

        let isFileSuccess = true;
        if (
          equipmentStore.SelectedEquipments.some(
            (ele) => ele.files && ele.files.length,
          )
        ) {
          const waitingFileRes = await fileUploadApi(data);
          if (!waitingFileRes) {
            isFileSuccess = false;
          }
        }

        if (isFileSuccess) {
          if (location && location.state) {
            const { todoId } = location.state as TodoRequest;
            bodyData['todoId'] = todoId;
          }
          const getResp = await roadTaxesStore.createRoadTaxes(bodyData);
          // setFileValidation(false);
          setBtnDisable(false);
          if (getResp.isErr()) {
            enqueueSnackbar(
              getResp.error.message.toString(),
              SnackBarConfig('e'),
            );
          } else if (getResp.isOk()) {
            activityListRecall();
            cancelForm();
            enqueueSnackbar(
              'Request submited successfully',
              SnackBarConfig('s'),
            );
            setNotification(true);
          }
        } else {
          setBtnDisable(false);
          // enqueueSnackbar('File upload failed', SnackBarConfig('e'));
        }
      }
    },
    [
      activityListRecall,
      cancelForm,
      enqueueSnackbar,
      equipmentStore.SelectedEquipments,
      fileUploadApi,
      location,
      roadTaxesStore,
    ],
  );

  const onselect = useCallback(
    (equipmentArr: any) => {
      equipmentStore.setSelectedEquipmentList(
        [
          ...equipmentArr.map((val: any) => {
            return { ...val, filePeriodYear: '', files: [] };
          }),
        ],
        getValues('years'),
        append,
        arrayFieldRemove,
        { filePeriodYear: '', files: [] },
      );
    },
    [append, arrayFieldRemove, equipmentStore, getValues],
  );

  const remove = (equip: any) => {
    if (!btnDisable) {
      let arr: Array<any> = equipmentStore.SelectedEquipments;
      if (equip) {
        arr = arr.filter((ele) => ele.unitId !== equip.unitId);
      }
      equipmentStore.setSelectedEquipmentList(arr);
      // Removed field
      if (equip && equip.unitId) {
        const unitsArr = getValues('years');
        const index = unitsArr.findIndex((val) => val.unitId === equip.unitId);
        if (index !== -1) {
          arrayFieldRemove(index);
        }
      }
    }
  };

  const fillingPeriodChange = (value: string, ele: any, index: number) => {
    const fromDate = moment(`07-01-${value}`, 'MM-DD-YYYY');
    const startDate = moment(fromDate).format('M/D/YY');

    const endDate = moment(startDate)
      .add(1, 'year')
      .subtract(1, 'day')
      .format('M/D/YY');
    const unitObj: any = fields[index];
    unitObj.filePeriodDate = `${startDate} - ${endDate}`;
    unitObj.filePeriodYear = value;
    update(index, unitObj);
  };

  useEffect(() => {
    let data = todoStore.EquipmentFromTodo;
    if (data && data.unit) {
      data = toJS(data.unit);
      data = [data];
      onselect(data);
      todoStore.setEquipmentFromTodo();
    }
  }, [todoStore, onselect]);

  if (notification) {
    return (
      <SuccessNotification
        title="Road Taxes / 2290"
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return (
    <form
      style={{
        width: '100%',
      }}
      id={'road-tax'}
      onSubmit={RTRenewHandleSubmit(reNewalFormOnSubmit)}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ md: 3, sm: 2, xs: 1 }}
        minHeight={'100%'}>
        <Grid item md={12} sm={12} xs={12}>
          <RoadTaxesForm
            {...{
              btnDisable,
              classes,
              control: RTRenewControl,
              equipmentStore,
              errors: RTRenewErrors,
              fields,
              // fileValidation,
              fillingPeriod,
              fillingPeriodChange,
              onselect,
              remove,
            }}
          />
          <TaxPermitsButtons
            formId={'road-tax'}
            disabled={btnDisable}
            formReset={cancelForm}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(TaxPermitsRoadTaxes);
