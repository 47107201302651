import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { observer } from 'mobx-react-lite';
import React from 'react';

import useStyles from './TaxPermitsEquipmentCardStyles';

type TaxPermitsEquipmentCard = {
  removedCard?: (src: any) => void;
  ele?: any;
  grossWeightShow?: any;
  children?: any;
};
const TaxPermitsEquipmentCard: React.FC<TaxPermitsEquipmentCard> = (
  props: TaxPermitsEquipmentCard,
) => {
  const { removedCard, ele, grossWeightShow } = props;
  const classes = useStyles();
  const removeCard = (equipment: any) => {
    removedCard && removedCard(equipment);
  };
  return (
    <Card className={classes.renewalFormCardDesign}>
      <CardContent>
        <div className={classes.renewalFormCardBlock}>
          <div className={classes.renewalFormCardContent}>
            <span className={classes.renewalFormHeadingTitle}>
              Equipment Type
            </span>
            <span className={classes.renewalFormCardValue}>
              {ele?.equipmentType}
            </span>
          </div>
          <div className={classes.renewalFormCardContent}>
            <span className={classes.renewalFormHeadingTitle}>Unit Type</span>
            <span className={classes.renewalFormCardValue}>
              {ele?.unitType}
            </span>
          </div>
          <div className={classes.renewalFormCardContent}>
            <span className={classes.renewalFormHeadingTitle}>Unit #</span>
            <span className={classes.renewalFormCardValue}>
              {ele?.unitNumber}
            </span>
          </div>
          <div className={classes.renewalFormCardContent}>
            <span className={classes.renewalFormHeadingTitle}>
              Simplex Unit #
            </span>
            <span className={classes.renewalFormCardValue}>
              {ele?.simplexId}
            </span>
          </div>
          <div className={classes.renewalFormCardContent}>
            <span className={classes.renewalFormHeadingTitle}>VIN</span>
            <span className={classes.renewalFormCardValue}>
              {ele?.vinNumber}
            </span>
          </div>
          {grossWeightShow ? (
            <div className={classes.renewalFormCardContent}>
              <span className={classes.renewalFormHeadingTitle}>
                Gross Vehicle Weight
              </span>
              <span className={classes.renewalFormCardValue}>
                {ele?.grossVehicleWeight}
              </span>
            </div>
          ) : (
            ''
          )}
          <div
            className={classes.renewalFormRemoveBtn}
            onClick={() => removeCard(props.ele)}>
            <span>Remove</span>
          </div>
        </div>
        {props?.children}
      </CardContent>
    </Card>
  );
};

export default observer(TaxPermitsEquipmentCard);
