import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { CreateIrpServiceInput } from '../../types';
import { RootStore } from '..';

export class IrpServiceApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getIrpService = () => {
    const {
      GetIRPService: { Endpoint, Method },
    } = ApiRoutes.IRPService;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: `${Endpoint}`,
    });
  };

  create = (data: CreateIrpServiceInput) => {
    const {
      CreateIRPService: { Endpoint, Method },
    } = ApiRoutes.IRPService;

    return this.rootStore.apiStore.call<any>({
      data,
      method: Method,
      url: `${Endpoint}`,
    });
  };
}
