import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

type Props = DialogProps & {
  handleSubmit: () => unknown;
  duplicateVINmsg?: string;
};

const DuplicateVinAlert: React.FC<Props> = ({
  open,
  handleSubmit,
  onBackdropClick,
  duplicateVINmsg,
}) => {
  return (
    <Dialog onBackdropClick={onBackdropClick} open={open}>
      <DialogTitle id="alert-dialog-title">
        {!duplicateVINmsg ? (
          <h4>VIN Accuracy Alert</h4>
        ) : (
          <h4 style={{ color: '#DB2426' }}>Duplicate VIN found!</h4>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {duplicateVINmsg ? (
            <Typography style={{ color: '#DB2426' }}>
              {duplicateVINmsg}
            </Typography>
          ) : (
            <Typography>
              Please confirm the VIN for accuracy before submitting your
              request. If you are certain this VIN is correct, select{' '}
              <b>Submit</b>. If you are not certain this VIN is correct, select{' '}
              <b>Cancel</b>
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!duplicateVINmsg && (
          <>
            <Button onClick={onBackdropClick}>CANCEL</Button>
            <Button autoFocus onClick={handleSubmit}>
              SUBMIT
            </Button>
          </>
        )}

        {duplicateVINmsg && <Button onClick={onBackdropClick}>OK</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateVinAlert;
